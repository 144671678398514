const React             = require('react');
const PropTypes         = require('prop-types');
const constants         = require('wp-constants').spacerFree;
const FormModal         = require('../containers/modals/FormModal.jsx');
const ConfirmationModal = require('./ConfirmationModal.jsx');

const MODAL_TYPE_TO_COMPONENT_MAP = {
  [constants.modal.TYPES.FORM]: FormModal,
  [constants.modal.TYPES.CONFIRMATION]: ConfirmationModal
};

function closeModalForType(props) {
  props.closeModal();
  if (props.afterClose[props.modalType]) props.afterClose[props.modalType]();
}

function Modal(props) {
  const closeModal = () => closeModalForType(props);

  if (props.modalType === constants.modal.TYPES.NONE) return null;

  const ComponentToRender = MODAL_TYPE_TO_COMPONENT_MAP[props.modalType];

  let modalContentClasses = 'modal-content';
  if (props.modalType === constants.modal.TYPES.FORM) modalContentClasses += ' is-wide';

  return (
    <div className="modal is-active">
      <div className="modal-background" role="button" tabIndex={0} onClick={closeModal} />
      <div className={modalContentClasses}>
        <button className="modal-close is-large" onClick={closeModal} />
        <ComponentToRender {...props.modalProps} closeModal={closeModal} />
      </div>
    </div>
  );
}

Modal.propTypes = {
  modalType: PropTypes.string.isRequired,
  modalProps: PropTypes.object, // if it exists, this object can take multiple shapes, depending on the modal it is passed to
  closeModal: PropTypes.func.isRequired,
  afterClose: PropTypes.objectOf(PropTypes.func)
};

Modal.defaultProps = {
  modalProps: null,
  afterClose: null
};

module.exports = Modal;
