const React              = require('react');
const PropTypes          = require('prop-types');
const { withTranslation } = require('react-i18next');

function ErrorPage(props) {
  const ERROR_MESSAGES = {
    404: props.t('error404Text'),
    500: props.t('error500Text')
  };

  if (!ERROR_MESSAGES[props.errorCode]) return null;

  return (
    <div className="is-flex is-fullheight is-grey-lighter has-padding-4-mobile">

      <div className="columns is-centered level">
        <div className="column is-4">
          <div className="subtitle has-text-grey-darker">{props.errorCode} {props.t('errorText')}</div>
          <div className="title has-text-grey-darker">{props.t('errorOopsText')} {ERROR_MESSAGES[props.errorCode]}</div>
          <a className="button is-primary" href="/home-spacer-by-cbre">{props.t('errorReturnText')}</a>
        </div>

        <div className="column is-5">
          <figure className="image">
            <img className="has-padding-top-1" src="/svg/illustrations/page-error-spilled-coffee-spacer.by-cbre.svg" alt={props.t('errorAltText')} />
          </figure>
        </div>
      </div>

    </div>
  );
}

ErrorPage.propTypes = {
  errorCode: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('common')(ErrorPage);
