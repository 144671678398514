const React              = require('react');
const PropTypes          = require('prop-types');
const sharedPropTypes    = require('../../../shared/components/sharedPropTypes');
const FormInput          = require('./FormInput.jsx');
const CheckboxInput      = require('./CheckboxInput.jsx');
const { withTranslation } = require('react-i18next');


class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    props.fields.forEach((field) => {
      this.state[field.name] = field.defaultValue;
    });

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(evt) {
    evt.preventDefault();
    this.props.onSubmit(this.state);
  }

  render() {
    let disableSubmit = this.props.isSubmittingForm;
    for (let i = 0, len = this.props.fields.length; i < len; i += 1) {
      if (this.props.fields[i].required && !this.state[this.props.fields[i].name]) {
        disableSubmit = true;
        break;
      }
    }

    let FieldComponent;
    const fieldComponents = this.props.fields.map((field) => {
      if (field.hidden) return null;

      FieldComponent = (field.type === 'checkbox') ? CheckboxInput : FormInput;
      return (
        <FieldComponent
          key={field.name}
          type={field.type}
          label={field.label}
          required={field.required}
          value={field.customValueFromState ? field.customValueFromState(this.state) : this.state[field.name]}
          onChange={val => this.setState({ [field.name]: val })}

          // the following props aren't required on all field components, but including on all instead of some out of convenience
          checked={this.state[field.name]}
          autoComplete={field.autoComplete}
          multipleAllowed={field.multipleAllowed}
        />
      );
    });

    let errorTextClasses = 'has-text-danger is-size-7 has-margin-top-6';
    if (!this.props.submitFormError) errorTextClasses += ' is-invisible';

    return (
      <form className="form" onSubmit={this.onSubmit}>

        { fieldComponents }

        {
          this.props.additionalInfoText &&
            <p className="is-size-7 has-margin-top-6">
              {this.props.additionalInfoText}
            </p>
        }

        <div className={errorTextClasses}>
          {this.props.t('formErrorText')}
        </div>

        <button
          className="button is-primary-filled has-margin-top-6 submitBtn"
          type="submit"
          disabled={disableSubmit}
        >
          {this.props.submitText}
        </button>


      </form>
    );
  }
}

Form.propTypes = {
  fields: sharedPropTypes.formFields.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  additionalInfoText: PropTypes.string,
  isSubmittingForm: PropTypes.bool.isRequired,
  submitFormError: PropTypes.object,
  t: PropTypes.func.isRequired
};

Form.defaultProps = {
  additionalInfoText: '',
  submitFormError: null
};

module.exports = withTranslation('resultsPage')(Form);

