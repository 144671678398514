const React                    = require('react');
const PropTypes                = require('prop-types');
const { withTranslation }       = require('react-i18next');
const DocumentData             = require('../common/DocumentData.jsx');
const Footer                   = require('../common/Footer.jsx');
const BasicNavbar              = require('../common/BasicNavbar.jsx');

function BrokerReferralWrapper(props) {
  return (
    <div className="broker-referral-page">
      <DocumentData
        siteLocation={props.siteLocationConstant}
      />

      <BasicNavbar
        navBarStartElements={[(<li key="referral" className="referrals-navbar">{props.t('referrals')}</li>)]}
      />
      <main>
        {props.children}
      </main>
      <Footer />
    </div>
  );
}

BrokerReferralWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  siteLocationConstant: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

module.exports = withTranslation('common')(BrokerReferralWrapper);
