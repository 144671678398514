const React               = require('react');
const PropTypes           = require('prop-types');
const constants           = require('wp-constants').spacerFree;
const spacerFreePropTypes = require('../../../spacerFreePropTypes');
const numericDisplay      = require('../../../../util/numericDisplay');
const unitsUtil           = require('../../../../../shared/util/units');
const DeltaLabel          = require('../DeltaLabel.jsx');
const NumberInput         = require('../../../form/NumberInput.jsx');
const Tooltip             = require('../../../common/Tooltip.jsx');
const SpaceUnitRowMobile  = require('./SpaceUnitRowMobile.jsx');
const domUtil             = require('../../../../../shared/util/dom');
const { withTranslation }  = require('react-i18next');

class SpaceUnitRow extends React.Component {
  constructor(props) {
    super(props);

    this.confirmDeletion = this.confirmDeletion.bind(this);
    this.onAreaUpdate = this.onAreaUpdate.bind(this);
    this.onQuantityUpdate = this.onQuantityUpdate.bind(this);
  }

  onAreaUpdate(value) {
    const valueInSqFt = unitsUtil.getSqFeetForMeasurementSystem(value, this.props.measurementSystem);

    this.props.onAreaUpdate(this.props.spaceUnitId, valueInSqFt);
  }

  onQuantityUpdate(value) {
    this.props.onQuantityUpdate(this.props.spaceUnitId, value, this.props.officeLocation);
  }

  confirmDeletion() {
    if (!this.props.openModal) return this.props.deleteFunction(this.props.spaceUnitId);
    return this.props.openModal(constants.modal.TYPES.CONFIRMATION, {
      title: this.props.t('spaceUnitRowDeleteTitle'),
      text: this.props.t('spaceUnitRowDeleteText'),
      confirm: {
        text: this.props.t('spaceUnitRowConfirmDeleteText'),
        onClick: () => {
          this.props.deleteFunction(this.props.spaceUnitId);
          this.props.closeModal();
        }
      },
      cancel: {
        text: this.props.t('spaceUnitRowCancelDeleteText'),
        onClick: () => {
          this.props.closeModal();
        }
      }
    });
  }

  render() {
    const areaNumberInput = (
      <NumberInput
        min={1}
        value={unitsUtil.getNumberForMeasurementSystem(this.props.area, this.props.measurementSystem, { precisionForMeter: 1, precisionForTsubo: 1 })}
        onChange={this.onAreaUpdate}
        disabled={!this.props.areaIsEditable}
        hideNumberArrows={!this.props.areaIsEditable}
      />
    );

    const quantityNumberInput = (
      <NumberInput
        min={1}
        value={this.props.quantity}
        onChange={this.onQuantityUpdate}
        disabled={!this.props.quantityIsEditable}
        hideNumberArrows={!this.props.quantityIsEditable}
      />
    );

    const totalArea = unitsUtil.getNumberForMeasurementSystem(this.props.totalArea, this.props.measurementSystem, { precisionForMeter: 1, precisionForTsubo: 1 });
    const totalAreaWithCommas = numericDisplay.numberWithLocaleFormat(totalArea);
    const measurementLabel = this.props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('SF', this.props.measurementSystem)}`);

    let confirmButtonClasses = 'add-icon is-selectable no-outline';
    if (!this.props.confirmFunction) {
      confirmButtonClasses += ' is-invisible';
    } else if (!this.props.confirmIsActive) {
      confirmButtonClasses += ' is-inactive';
    }

    return (
      <div>
        {
          domUtil.isScreenMobileSize() ?
            <SpaceUnitRowMobile
              description={this.props.description}
              name={this.props.name}
              area={this.props.area}
              measurementSystem={this.props.measurementSystem}
              quantity={this.props.quantity}
              displayUnit={this.props.displayUnit}
              totalArea={this.props.totalArea}
              showDeltaPercentage={this.props.showDeltaPercentage}
              delta={this.props.delta}
              spaceUnitCategory={this.props.spaceUnitCategory}
            />
            :
            <div className={this.props.isAmenityUnitAdder ? 'amenity-unit-adder is-hidden-mobile' : 'space-unit-row'}>
              <div className="columns is-mobile is-multiline has-margin-0-auto space-category-breakdown-row">
                <div className="column is-3-desktop is-3-tablet is-full-mobile has-no-padding-bottom-mobile space-category-breakdown-row-name">
                  <span className="name">{this.props.name}</span>
                  {this.props.description && <Tooltip text={this.props.description} />}
                </div>
                <div className="column is-2-desktop is-5-mobile space-category-breakdown-input-container">
                  <span className="space-category-area">{areaNumberInput}</span>
                  <span className="space-category-area-label">{measurementLabel}</span>
                </div>
                <div className="column is-1-desktop is-2-mobile has-text-light has-text-centered">
                  X
                </div>
                <div className="column is-2-desktop is-5-mobile space-category-breakdown-input-container">
                  <div className="columns is-gapless space-category-units-container">
                    <div className="column is-7 space-category-quantity">
                      {quantityNumberInput}
                    </div>
                    <div className="column is-7 space-category-unit">
                      {this.props.displayUnit}
                    </div>
                  </div>
                </div>
                <div className="column is-1-desktop is-3-mobile has-text-light has-text-centered">
                  =
                </div>
                <div className="column is-2-desktop is-9-mobile">
                  <div className="grand-total">{totalAreaWithCommas} {measurementLabel}</div>
                  <DeltaLabel
                    showDeltaPercentage={this.props.showDeltaPercentage}
                    delta={this.props.delta}
                    measurementSystem={this.props.measurementSystem}
                  />
                </div>
                <div className="flex is-hidden-mobile">
                  <div // still include invisible ones for alignment
                    role="button"
                    tabIndex="0"
                    className={confirmButtonClasses}
                    onClick={this.props.confirmFunction}
                  />
                  <button className="delete-unit-row is-medium" aria-label={`Delete ${this.props.name}`} onClick={this.confirmDeletion} />
                </div>
              </div>
              <hr />
            </div>
        }
      </div>
    );
  }
}

SpaceUnitRow.propTypes = {
  spaceUnitId: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  area: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  totalArea: PropTypes.number.isRequired,
  displayUnit: PropTypes.string.isRequired,
  description: PropTypes.string,

  isAmenityUnitAdder: PropTypes.bool,
  areaIsEditable: PropTypes.bool,
  quantityIsEditable: PropTypes.bool,

  onAreaUpdate: PropTypes.func.isRequired,
  onQuantityUpdate: PropTypes.func.isRequired,

  measurementSystem: PropTypes.string.isRequired,
  delta: spacerFreePropTypes.deltaShape,
  showDeltaPercentage: PropTypes.bool,
  confirmFunction: PropTypes.func,
  confirmIsActive: PropTypes.bool,
  deleteFunction: PropTypes.func,

  openModal: PropTypes.func,
  closeModal: PropTypes.func,

  spaceUnitCategory: PropTypes.string,

  t: PropTypes.func.isRequired
};

SpaceUnitRow.defaultProps = {
  spaceUnitId: '',
  description: '',
  isAmenityUnitAdder: false,
  areaIsEditable: false,
  quantityIsEditable: false,
  showDeltaLabel: false,
  delta: null,
  showDeltaPercentage: true,
  confirmFunction: null,
  confirmIsActive: false,
  deleteFunction: null,
  openModal: null,
  closeModal: null,
  spaceUnitCategory: ''
};

module.exports = withTranslation('resultsPage')(SpaceUnitRow);
