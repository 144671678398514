module.exports = {

  backOrLandingPage() {
    // if a page is accessed through a link, document.referrer is the referring page
    // if a page is navigated to directly, document.referrer is an empty string
    if (document.referrer) {
      window.history.back();
    } else {
      window.location = '/';
    }
  }

};
