const React = require('react');
const PropTypes = require('prop-types');
const spacerFreePropTypes = require('../../spacerFreePropTypes');
const constants = require('wp-constants').spacerFree;
const CollaborationDropdown = require('../../formSpecific/CollaborationDropdown.jsx');
const CirculationDropdown = require('../../formSpecific/CirculationDropdown.jsx');
const DaysInOfficeDropdown = require('../../formSpecific/DaysInOfficeDropdown.jsx');
const HeadcountInput = require('../../formSpecific/HeadcountInput.jsx');
const unitsUtil = require('../../../../shared/util/units');
const { dashOrValue } = require('../../../../shared/util/display');
const numericDisplay = require('../../../util/numericDisplay');
const ReactRouterLink = require('react-router-dom').Link;
const profileMap = require('wp-data').profileMap;
const { withTranslation } = require('react-i18next');

const SF_TYPE = constants.calculator.SF_TYPE;


class ProgramAssumptions extends React.Component {
  constructor(props) {
    super(props);

    this.updateKeyAssumptionState = this.updateKeyAssumptionState.bind(this);
    this.headcountInputOnValueChange = this.headcountInputOnValueChange.bind(this);
    this.collaborationDropdownOnValueChange = this.collaborationDropdownOnValueChange.bind(this);
    this.daysInOfficeDropdownOnValueChange = this.daysInOfficeDropdownOnValueChange.bind(this);
    this.circulationDropdownOnValueChange = this.circulationDropdownOnValueChange.bind(this);
  }


  updateKeyAssumptionState(assumptionType, value) {
    value = parseFloat(value);
    if (isNaN(value)) value = 0;
    this.props.updateKeyAssumption(assumptionType, value);
    this.props.detectChangeToSaveOnResults();
  }

  headcountInputOnValueChange(value) {
    this.updateKeyAssumptionState(constants.questionUnit.ASSUMPTION_TYPE.HEADCOUNT, value);
  }

  collaborationDropdownOnValueChange(value) {
    this.updateKeyAssumptionState(constants.questionUnit.ASSUMPTION_TYPE.COLLABORATION, value);

  }
  circulationDropdownOnValueChange(value) {
    this.updateKeyAssumptionState(constants.questionUnit.ASSUMPTION_TYPE.DENSITY, value);
  }

  daysInOfficeDropdownOnValueChange(value) {
    this.updateKeyAssumptionState(constants.questionUnit.ASSUMPTION_TYPE.DAYS_IN_OFFICE, value);
  }

  render() {
    const isNIA = this.props.sfType === SF_TYPE.NIA;
    let formattedTotalRSF = unitsUtil.getNumberForMeasurementSystem(this.props.totalRSF, this.props.measurementSystem);
    formattedTotalRSF = dashOrValue(!this.props.totalRSF, numericDisplay.numberWithLocaleFormat(formattedTotalRSF));

    let profileName = profileMap[this.props.currentProfileId].displayNameToken;
    const nameSplit = this.props.t(profileName).split(' ');
    profileName = nameSplit[1] ? nameSplit[1] : nameSplit[0]; // Removes the "the" in languages where that is a separate word vs a conjunction

    return (
      <div className="assumptions-controls">
        <div className="columns assumptions-controls-container">
          <div className="column is-3-desktop is-full-tablet assumption-container recommended-office-size-section">
            <div className="flex flex-col flex-align-items-start has-margin-left-6 recommended-office-size-content">
              <div className="subtitle is-6 no-margin-bottom">{this.props.t('programAssumptionsRecommendedOfficeSizeText')}</div>
              <div className="subtitle is-3">{formattedTotalRSF} {this.props.t(`common:${isNIA ? 'NIA' : unitsUtil.getTokenKeyForMeasurementSystem('RSF', this.props.measurementSystem)}`)}</div>
            </div>
          </div>
          <div className="mobile-assumption-separator is-mobile-input" />
          <div className="column assumption-container">
            <div className="assumption-separator has-margin-right-6 is-hidden-mobile" />
            <div>
              <div className="assumption-title is-hidden-mobile">{this.props.t('programAssumptionsProfileText')}</div>
              <div className="profile-and-headcount-assumption-style is-hidden-mobile">
                <ReactRouterLink
                  to={{ pathname: '/profile-comparison-spacer-by-cbre', programId: this.props.programId }}
                  className="underline-input profile-type-name is-hidden-mobile"
                >
                  {profileName}
                </ReactRouterLink>
                <div className="underline-input profile-type-name is-mobile-input">
                  {profileName}
                </div>
              </div>
            </div>
            <div className="is-mobile-input flex flex-row mobile-assumption-container">
              <div className="assumption-title pull-start mobile-assumption-title">{this.props.t('programAssumptionsProfileText')}</div>
              <div className="mobile-assumption">
                <div className="underline-input profile-type-name is-mobile-input">
                  {profileName}
                </div>
              </div>
            </div>
          </div>


        </div>
        <div className="columns assumptions-controls-container">

          <div className="column assumption-container">




            <div className="headcount-container">


              <div className="assumption-title is-hidden-mobile">{this.props.t('programAssumptionsHeadcountText')}</div>
              <div className="profile-and-headcount-assumption-style is-hidden-mobile flex flex-row">
                <div className="headCount">
                  <HeadcountInput
                    value={this.props.assumptions.headcount}
                    onValueChange={this.headcountInputOnValueChange}
                    hasXlargeArrows
                  />
                </div>
                <div className="assumption-title is-hidden-mobile headcount-following-text">{this.props.t('programAssumptionsHeadcountFollowingText')}</div>
              </div>
            </div>
            <div className="is-mobile-input flex flex-row mobile-assumption-container">
              <div className="assumption-title pull-start mobile-assumption-title">{this.props.t('programAssumptionsHeadcountText')}</div>
              <div className="mobile-assumption">
                <HeadcountInput
                  value={this.props.assumptions.headcount}
                  onValueChange={this.headcountInputOnValueChange}
                  hasXlargeArrows
                />
              </div>
            </div>
          </div>

          <div className="mobile-assumption-separator is-mobile-input" />

          <div className="column assumption-container">
            <div className="assumption-separator collaboration-assumption-separator has-margin-right-6 is-hidden-mobile" />
            <div>
              <div className="assumption-title is-hidden-mobile">{this.props.t('programAssumptionsCollabText')}</div>
              <div className="collaboration-assumption-style is-hidden-mobile">
                <CollaborationDropdown
                  value={this.props.assumptions.collaboration}
                  onValueChange={this.collaborationDropdownOnValueChange}
                />
              </div>
            </div>
            <div className="is-mobile-input flex flex-row mobile-assumption-container">
              <div className="assumption-title pull-start mobile-assumption-title">{this.props.t('programAssumptionsCollabText')}</div>
              <div className="mobile-assumption">
                <CollaborationDropdown
                  value={this.props.assumptions.collaboration}
                  onValueChange={this.collaborationDropdownOnValueChange}
                />
              </div>
            </div>
          </div>

          <div className="mobile-assumption-separator is-mobile-input" />

          <div className="column assumption-container">
            <div className="assumption-separator collaboration-assumption-separator has-margin-right-6 is-hidden-mobile" />
            <div>
              <div className="assumption-title is-hidden-mobile">{this.props.t('programAssumptionsCirculationText')}</div>
              <div className="circulation-assumption-style is-hidden-mobile">
                <div className="circulation-dropdown">
                  <div className="circulation-dropdown-input">
                    <CirculationDropdown
                      value={this.props.assumptions.officeDensity}
                      onValueChange={this.circulationDropdownOnValueChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="is-mobile-input flex flex-row mobile-assumption-container">
              <div className="assumption-title pull-start mobile-assumption-title">{this.props.t('programAssumptionsCirculationText')}</div>
              <div className="mobile-assumption ">

                <CirculationDropdown
                  value={this.props.assumptions.officeDensity}
                  onValueChange={this.circulationDropdownOnValueChange}
                />

              </div>
            </div>
          </div>

          <div className="mobile-assumption-separator is-mobile-input" />

          <div className="column assumption-container">
            <div className="assumption-separator is-hidden-mobile days-per-week-assumption-separator" />
            <div>
              <div className="assumption-title is-hidden-mobile days-per-week-assumption-text">{this.props.t('programAssumptionsAvgDaysText')}</div>
              <div className="days-in-office-assumption-style is-hidden-mobile flex flex-row">
                <DaysInOfficeDropdown
                  value={this.props.assumptions.daysPerWeekInOffice}
                  onValueChange={this.daysInOfficeDropdownOnValueChange}
                />
                <div className="assumption-title is-hidden-mobile days-per-week-assumption-text per-week-text">{this.props.t('programAssumptionsAvgDaysFollowingText')}</div>
              </div>
            </div>
            <div className="is-mobile-input flex flex-row mobile-assumption-container">
              <div className="assumption-title pull-start mobile-assumption-title">{this.props.t('programAssumptionsAvgDaysPerWeekText')}</div>
              <div className="mobile-assumption">
                <DaysInOfficeDropdown
                  value={this.props.assumptions.daysPerWeekInOffice}
                  onValueChange={this.daysInOfficeDropdownOnValueChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProgramAssumptions.propTypes = {
  assumptions: spacerFreePropTypes.assumptionsShape.isRequired,
  updateKeyAssumption: PropTypes.func.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  totalRSF: PropTypes.number.isRequired,
  sfType: PropTypes.number.isRequired,
  profileType: PropTypes.string.isRequired,
  currentProfileId: PropTypes.string.isRequired,
  detectChangeToSaveOnResults: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(ProgramAssumptions);
