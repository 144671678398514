const React                = require('react');
const Link                 = require('react-router-dom').Link;
const _                    = require('lodash');
const PropTypes            = require('prop-types');
const { withTranslation }   = require('react-i18next');
const sharedPropTypes      = require('../../../shared/components/sharedPropTypes');
const ErrorPage            = require('../common/ErrorPage.jsx');
const BasicNavbarWithLinks = require('../common/BasicNavbarWithLinks.jsx');
const HeroSection          = require('../common/HeroSection.jsx');
const CallToActionBanner   = require('../infoPages/common/CallToActionBanner.jsx');
const Footer               = require('../common/Footer.jsx');
const DocumentData         = require('../common/DocumentData.jsx');
const blogCopy             = require('../../../../../copy/blogCopy');
const { withRouter } = require('../../../shared/components/common/withRouter.jsx');

function BlogArticle(props) {
  const blog = _.find(blogCopy, ['slug', props.params.slug]);
  if (!blog) return <ErrorPage errorCode={404} />; // go to error page if blog slug isn't valid
  const { date, image, author, namespace } = blog;

  // blogtodo: dry this with blogArticlePreview.jsx
  // blogtodo: format date differently in different countries / prep for internationalization
  const dateString = (new Date(date)).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

  return (
    <div>
      <DocumentData siteLocation={namespace} />
      <BasicNavbarWithLinks />
      <main>
        <HeroSection hasPrimaryBackground classes="blog-hero blog-hero-small">
          <Link to="/insights-spacer-by-cbre" className="blog-home-link is-sans-medium has-margin-left-3">{props.t('blogHomeTitle')}</Link>
        </HeroSection>
        <div className="blog-article">
          <div className="container blog-article-container section">
            <figure className="image">
              <img src={`/jpg/blogArticles/${image}`} alt="" />
            </figure>
            <div className="content is-small has-text-grey is-sans-medium is-inline-block">{`${dateString} by ${author}`}</div>
            <div className="blog-content">
              <h1 className="title">{props.t(`${namespace}:title`)}</h1>
              <div dangerouslySetInnerHTML={{ __html: props.t(`${namespace}:content`) }} />
            </div>
          </div>
          <CallToActionBanner />
        </div>
      </main>
      <Footer />
    </div>
  );
}

BlogArticle.propTypes = {
  t: PropTypes.func.isRequired,
  match: sharedPropTypes.routeMatchShape.isRequired
};

module.exports = withTranslation('blogPages')(withRouter(BlogArticle));
