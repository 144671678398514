const { connect }                 = require('react-redux');
const { bindActionCreators }      = require('redux');
// const globalActions              = require('../../../redux/actions/global');
const Results                     = require('../../results/Results.jsx');
const _                           = require('lodash');
const modalActions                = require('../../../redux/actions/modal');
import globalActions from "../../../redux/actions/global";


// Map state to props
function mapStateToProps(state) {
  const fetchProgramErrorStatus = state.requests.fetchProgramError && state.requests.fetchProgramError.statusCode;
  return {
    fetchProgramErrorStatus,
    isProgramEmpty: _.isEmpty(state.spaceData.program),
    isFetchingProgram: state.requests.isFetchingProgram,
    showWarningOnPageUnload: !fetchProgramErrorStatus && state.changeDetected.changesMadeToResultsDetails
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    fetchProgramIfNeeded: bindActionCreators(globalActions.fetchProgramIfNeeded, dispatch),
    onPageUnload: bindActionCreators(globalActions.removeProgramSavedStatus, dispatch),
    openModal: bindActionCreators(modalActions.openModal, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(Results);
