const tags = require('../assets/js/spacerFree/components/constants/blogTags');

module.exports = [
  // articles for the blog
  {
    author: 'Lauren Popish',
    date: '2019-06-21',
    tags: [tags.OFFICE_CULTURE],
    image: 'blog-2019-06-21.jpg',
    slug: 'why-defining-your-perfect-office-matters',
    namespace: 'blogArticle20190621'
  },
  {
    author: 'Lauren Popish',
    date: '2019-06-25',
    tags: [],
    image: 'blog-2019-06-25.jpg',
    slug: 'how-does-spacer-work',
    namespace: 'blogArticle20190625'
  },
  {
    author: 'Lauren Popish',
    date: '2019-06-27',
    tags: [tags.SIZE_AND_FIT],
    image: 'blog-2019-06-27.jpg',
    slug: 'difference-between-usable-rentable-square-feet',
    namespace: 'blogArticle20190627'
  }
  // when adding blog posts with other tags, make sure to uncomment those tabs in BlogHome.jsx
];
