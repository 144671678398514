const React              = require('react');
const PropTypes          = require('prop-types');
const profileTraitMap    = require('wp-data').profileTraitMap;
const { withTranslation } = require('react-i18next');

function ProfileTrait(props) {
  const profileTrait = profileTraitMap.getProfileTraitById(props.traitId);
  const icon = (props.isDark) ? profileTrait.icon.light : profileTrait.icon.dark;

  const nameClasses = `title2 is-6 has-margin-bottom-7 ${props.isDark ? '' : 'has-text-primary'}`;
  const descriptionClasses = `profile-trait-description ${props.isDark ? '' : 'has-text-primary'}`;

  return (
    <div className="profile-trait has-text-centered">
      <img src={icon} alt={profileTrait.name} />
      <h3 className={nameClasses}>{props.t(profileTrait.name)}</h3>
      <div className={descriptionClasses}>{props.t(profileTrait.description)}</div>
    </div>
  );
}

ProfileTrait.propTypes = {
  traitId: PropTypes.string.isRequired,
  isDark: PropTypes.bool,
  t: PropTypes.func.isRequired
};

ProfileTrait.defaultProps = {
  isDark: false
};

module.exports = withTranslation('resultsPage')(ProfileTrait);

