module.exports = {

  // LandingPageHero
  landingPageHeroTitle: 'Een toekomstbestendige hybride werkplek ontwikkelen? Maak een vliegende start met Spacer.',
  landingPageHeroSubtitle: 'Binnen een paar minuten breng je de ruimtebehoeften van je organisatie in kaart – geheel op maat én kosteloos.',
  landingPageHeroImgAlt: "Voorbeeld van de resultaten van CBRE's Spacer",
  landingPageImage: '/png/homepage-results-page-in-browser-spacer-by-cbre.png',
  // LandingPageContent
  landingPageContentTitle: 'Zo werkt Spacer',

  // StepsSummary
  stepsSummarySubtitle1: 'Stap 1',
  stepsSummaryTitle1: 'Vertel ons over je bedrijf',
  stepsSummaryText1: 'Hoe meer we weten over je organisatie, hoe beter onze aanbevelingen bij jullie passen.',
  stepsSummaryImgAlt1: 'Illustratie van mensen die op een reusachtige laptop staan op de pagina Home van Spacer by CBRE',

  stepsSummarySubtitle2: 'Stap 2',
  stepsSummaryTitle2: 'Neem je resultaten goed door',
  stepsSummaryText2: 'Op basis van je antwoorden stellen we een ruimteprogramma op: hierin staat precies wat voor kantoorprofiel het beste bij je organisatie, mensen en doelen past.',
  stepsSummaryImgAlt2: 'Illustratie van een huizenblok op de pagina Home van Spacer by CBRE',

  stepsSummarySubtitle3: 'Stap 3',
  stepsSummaryTitle3: 'Ga op zoek naar de beste werkplek',
  stepsSummaryText3: 'Of je nu een nieuw kantoor of een effectieve hybride werkplekstrategie nodig hebt: je Spacer-resultaten zijn het ideale vertrekpunt. De deskundige adviseurs van CBRE Workplace helpen je aan een passende oplossing.',
  stepsSummaryImgAlt3: 'Illustratie van een jonge vrouw die werkt aan een bureau op de pagina Home van Spacer by CBRE',

  // LandingPageSecondaryHero
  landingPageSecondaryHeroImgAlt: 'Illustratie van mensen die ruimteaanbevelingen samenstellen op de pagina Home van Spacer by CBRE',
  landingPageSecondaryHeroTitle: 'Laten we beginnen: wat voor sfeer wil je op kantoor?'

};
