const React               = require('react');
const _                   = require('lodash');
const PropTypes           = require('prop-types');
const classnames          = require('classnames');
const sharedPropTypes     = require('../../../shared/components/sharedPropTypes');
const spacerFreePropTypes = require('../spacerFreePropTypes');
const domUtil             = require('../../../shared/util/dom');
const KEY_CODES           = require('../../../shared/constants/keyCodes');

const DROPDOWN_PADDING = 25;

class DropdownInput extends React.Component {
  constructor(props) {
    super(props);

    // highlightedOptionId is what's currently highlighted and would be selected on hitting enter
    // that might either be what's hovered, or what has been selected via keyboard up + down arrows
    this.state = {
      highlightedOptionId: null, // option that would be selected on enter
      dropdownActive: false
    };

    this.optionElements = {};
    this.registerKeyPress = this.registerKeyPress.bind(this);
    this.mouseMoveOnOptionField = this.mouseMoveOnOptionField.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) { // in case the dropdown input is an autocomplete and the user types the whole option
    for (let i = 0, len = nextProps.optionOrder.length; i < len; i += 1) {
      if (_.lowerCase(nextProps.options[nextProps.optionOrder[i]].text) === _.lowerCase(nextProps.value)) {
        this.setState({ highlightedOptionId: nextProps.optionOrder[i] });
        break;
      }
    }
  }

  setValue(value) {
    return (evt) => {
      evt.preventDefault();
      this.props.onValueChange(value);
      this.toggleDropdown(false);
    };
  }

  scrollOptionIntoView() {
    if (!this.props.isInQuiz) return;
    if (this.state.highlightedOptionId && this.optionElements[this.state.highlightedOptionId]) {
      this.optionElements[this.state.highlightedOptionId].scrollIntoView({ block: 'nearest' });
    }
  }

  shiftHighlightedOptionDown() {
    this.setState((prevState) => {
      const oldIndex = this.props.optionOrder.indexOf(prevState.highlightedOptionId);
      if (oldIndex === this.props.optionOrder.length - 1) return null;
      return { highlightedOptionId: this.props.optionOrder[oldIndex + 1] };
    },
    this.scrollOptionIntoView
    );
  }

  shiftHighlightedOptionUp() {
    this.setState((prevState) => {
      const oldIndex = this.props.optionOrder.indexOf(prevState.highlightedOptionId);
      if (oldIndex === 0) return null;
      // from nothing selected, go to bottom option
      const newIndex = (oldIndex === -1) ? (this.props.optionOrder.length - 1) : (oldIndex - 1);
      return { highlightedOptionId: this.props.optionOrder[newIndex] };
    },
    this.scrollOptionIntoView
    );
  }

  registerKeyPress(evt) {
    switch (evt.which) {
      case KEY_CODES.ENTER:
        if (!this.state.dropdownActive) return null;
        evt.target.blur(); // so that the next "enter" can move you to the next slide
        evt.stopPropagation(); // so that, if this dropdown is in the quiz, this enter doesn't take you to the next slide yet
        if (this.state.highlightedOptionId) {
          this.setValue(this.state.highlightedOptionId)(evt);
        } else {
          this.toggleDropdown(false);
        }
        return null;
      case KEY_CODES.DOWN_ARROW:
        evt.preventDefault(); // to prevent scrolling on main page
        return this.shiftHighlightedOptionDown();
      case KEY_CODES.UP_ARROW:
        evt.preventDefault(); // to prevent scrolling on main page
        return this.shiftHighlightedOptionUp(true);
      default:
        this.setState({ dropdownActive: true });
        return null;
    }
  }

  // mousemove instead of mouseenter, to not trigger when things scroll due to arrow keypresses
  mouseMoveOnOptionField(value) {
    return () => {
      if (this.state.highlightedOptionId !== value) {
        this.setState({ highlightedOptionId: value });
      }
    };
  }

  toggleDropdown(showDropdown) {
    this.setState({
      dropdownActive: showDropdown,
      highlightedOptionId: this.props.value
    },
    () => { if (showDropdown) this.scrollOptionIntoView(); }
    );
    this.props.onClick();
  }

  render() {
    let dropdownMenuStyles;
    if (this.props.isInQuiz && this.dropdownInput) {
      dropdownMenuStyles = {
        maxHeight: domUtil.getSpaceBelow(this.dropdownInput) - DROPDOWN_PADDING,
        overflow: 'auto'
      };
    }

    let dropdownWrapperClasses = 'dropdown';
    if (this.state.dropdownActive) dropdownWrapperClasses += ' is-active';

    const dropdownSelectionClasses = classnames({
      'is-space-category-dropdown': this.props.isSpaceCategoryDropdown,
      'underline-input': !this.props.isSpaceCategoryDropdown,
      'has-placeholder': !this.props.value,
      'is-primary': this.props.isDark,
      'is-large': this.props.isLarge,
      'is-inactive': this.props.isInactive
    });

    const dropdownElements = this.props.optionOrder.map((optionId) => {
      const key = `option-${optionId}`;
      const selected = (this.props.value === optionId);

      const dropdownItemClasses = classnames('dropdown-item-quiz', {
        'is-active': selected,
        'hover': this.state.highlightedOptionId === optionId,
        'space-category-dropdown-item': this.props.isSpaceCategoryDropdown
      });


      return (
        <div
          role="option"
          aria-selected={selected}
          key={key}
          tabIndex={optionId}
          className={dropdownItemClasses}
          onMouseDown={this.setValue(optionId)}
          onMouseMove={this.mouseMoveOnOptionField(optionId)}
          ref={(option) => { this.optionElements[optionId] = option; }}
          title={this.props.options[optionId].text}
        >
          {this.props.options[optionId].text}
        </div>
      );
    });

    const dropdownContentClasses = classnames('dropdown-content', {
      'space-category-dropdown-content': this.props.isSpaceCategoryDropdown
    });

    return (
      <div
        className={dropdownWrapperClasses}
        role="menu"
        tabIndex="0"
        onBlur={() => this.toggleDropdown(false)}
        onKeyDown={this.registerKeyPress}
      >
        <div
          className={dropdownSelectionClasses}
          role="button"
          tabIndex="0"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onFocus={() => this.toggleDropdown(true)} // for dropdowns that get autofocus (e.g., LocationAutocompleteInput)
          onMouseDown={() => this.toggleDropdown(true)} // so we can toggle the dropdown open more than once after initial focus
          ref={(node) => { this.dropdownInput = node; }}
        >
          {this.props.inputField}
        </div>

        <div
          className="dropdown-menu is-primary"
          role="button"
          tabIndex="0"
          id="dropdown-menu"
          onMouseDown={evt => evt.preventDefault()} // so that clicking the scroll bar doesn't cause onblur
          onMouseLeave={() => this.setState({ highlightedOptionId: null })}
          style={dropdownMenuStyles}
        >
          <div
            className={dropdownContentClasses}
            role="listbox"
          >
            {dropdownElements}
          </div>
        </div>

      </div>
    );
  }
}

DropdownInput.propTypes = {
  value: sharedPropTypes.stringOrNumber.isRequired,
  onValueChange: PropTypes.func.isRequired,
  options: spacerFreePropTypes.questionOptionsTypes.isRequired,
  optionOrder: PropTypes.arrayOf(sharedPropTypes.stringOrNumber).isRequired,
  inputField: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  isDark: PropTypes.bool,
  isLarge: PropTypes.bool,
  isInactive: PropTypes.bool,
  isInQuiz: PropTypes.bool,
  isSpaceCategoryDropdown: PropTypes.bool,
  onClick: PropTypes.func
};

DropdownInput.defaultProps = {
  emptyAnswerPlaceholder: '',
  isDark: false,
  isLarge: false,
  isInactive: false,
  isInQuiz: false,
  isSpaceCategoryDropdown: false,
  onClick: _.noop
};

module.exports = DropdownInput;

