const React                     = require('react');
const PropTypes                 = require('prop-types');
const HeroSection               = require('../../common/HeroSection.jsx');
const HeroProfileQuestion       = require('../../containers/landingPage/HeroProfileQuestion.jsx');
const TakeOfficeSpaceQuizButton = require('../common/TakeOfficeSpaceQuizButton.jsx');
const { withTranslation }        = require('react-i18next');

function LandingPageSecondaryHero(props) {
  return (
    <HeroSection sizeClass="is-small" isLightColor>
      <div className="columns level">
        <div className="column is-two-fifths has-padding-bottom-1 has-padding-top-1 is-hidden-mobile">
          <figure className="image is-4by3 is-hidden-mobile">
            <img src="/svg/illustrations/landing-page/homepage-building-results-spacer-by-cbre.svg" alt={props.t('landingPageSecondaryHeroImgAlt')} />
          </figure>
        </div>
        <div className="column">
          <div className="title is-3 is-5-mobile has-text-primary has-padding-left-3 has-no-padding-mobile">{props.t('landingPageSecondaryHeroTitle')}</div>
          <HeroProfileQuestion />
          <div className="has-padding-left-3 has-padding-top-1 has-padding-top-3-mobile has-padding-bottom-3-mobile has-no-padding-left-mobile">
            <TakeOfficeSpaceQuizButton />
          </div>
        </div>
      </div>
    </HeroSection>
  );
}

LandingPageSecondaryHero.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('landingPage')(LandingPageSecondaryHero);
