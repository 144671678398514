module.exports = {

  // Quiz
  exitQuizButtonTitle: 'Exit Quiz',

  // QuizProgressLabel
  progressLabelText: 'complete',

  // QuizNavigationButton
  quizNavBackButtonShortenedText: 'Previous',
  quizNavBackButtonFullLengthText: 'Previous Question',
  quizNavNextButtonShortenedText: 'Next',
  quizNavNextButtonFullLengthText: 'Next Question',

  // QuizExitSlide
  quizExitSubtitle: 'We are now using your answers to define the perfect workplace for your company.',
  quizExitImgAlt: 'Illustration of two people high fiving on the Quiz page of Spacer by CBRE',

  // Question Unit Text (questionUnitMap.js)
  question1Text: 'To start — How would you describe the purpose of your future office?',
  question2Text: 'What about your organization do you think is most attractive to candidates?',
  question3Text: 'What are the most important things you look for when hiring new team members?',
  question4Text: 'How do you see the size of your organization changing in the next five years?',
  question5Text: 'What’s the work schedule like for your employees?',
  question6Text: 'What do you think about individual desks?',
  question7Text: 'While in the office, where will people spend their time working?',
  question8Text: 'What will people wear in your office?',
  question9Text: 'Where will your work happen?',
  question10Text: 'What should your office atmosphere be like?',
  question11Text: 'Besides space for work, what would you like to provide in your workspace?',
  question12Text: 'Which of these best describes you?',

  // Quiz Answers (questionUnitMap.js)
  pickOnePlaceholder: 'pick one...',
  selectFromListPlaceholder: 'select from list...',
  firstResponseTooltipText: 'Select your first response',
  locatedSomewhereElseLabel: 'I’m currently located somewhere else.',

  q1AnswerText1: 'We think of our office first as',
  q1AnswerText2: 'second as',
  q1AnswerText3: 'and third as',
  q1Option1Text: 'a second home for our people',
  q1Option2Text: 'a driver of an organizational change',
  q1Option3Text: 'a place for focus work',
  q1Option4Text: 'an incubator for the next big idea',
  q1Option5Text: 'a tool to acquire great talent',
  q1Option6Text: 'a showcase for our brand',
  q1Option7Text: 'a space for growth and learning',
  q1Option8Text: 'a place for people to meet and come together',
  q1Option9Text: 'a necessary cost we try to minimize',

  q2AnswerText1: 'People join us first because of our',
  q2AnswerText2: 'second because of our',
  q2AnswerText3: 'and third because of our',
  q2Option1Text: 'company culture',
  q2Option2Text: 'competitive salaries',
  q2Option3Text: 'company reputation',
  q2Option4Text: 'benefits and perks',
  q2Option5Text: 'flexible work schedules',
  q2Option6Text: 'people and leadership',
  q2Option7Text: 'office environment',
  q2Option8Text: 'purpose and mission',

  q3AnswerText1: 'We hire employees first for',
  q3AnswerText2: 'second for',
  q3AnswerText3: 'and third for',
  q3Option1Text: 'skills',
  q3Option2Text: 'experience',
  q3Option3Text: 'cultural fit',
  q3Option4Text: 'credentials',
  q3Option5Text: 'relationships',
  q3Option6Text: 'curiosity / inquisitiveness',

  q4AnswerText: 'We will',
  q4Option1Text: 'grow slightly each year',
  q4Option2Text: 'grow significantly each year',
  q4Option3Text: 'stay about the same',
  q4Option4Text: 'shrink slightly each year',
  q4Option5Text: 'hard to say - it’s unpredictable',

  q5AnswerText: 'We’re usually working',
  q5Option1Text: 'from 9-5',
  q5Option2Text: 'from morning until...late',
  q5Option3Text: 'whenever they want, as long as it’s a certain amount',
  q5Option4Text: 'whenever they want, as often as they want',

  q6AnswerText: 'We think',
  q6Option1Text: 'everyone should have their own',
  q6Option2Text: 'it can be better to share unassigned desks',

  q7Option1Text: 'Always from an individual desk',
  q7Option2Text: 'Primarily from an individual desk with some meetings or other activities',
  q7Option3Text: 'Even split between a desk and somewhere else in the office',
  q7Option4Text: 'Primarily moving from space to space with occasional touchdowns at desks',
  q7Option5Text: 'Always moving from space to space',

  q8Option1Text: 'Business professional',
  q8Option2Text: 'Mostly professional except for special occasions',
  q8Option3Text: 'Business casual, with some days dressier and some days not',
  q8Option4Text: 'Mostly casual except for special occasions',
  q8Option5Text: 'Casual',

  q9Option1Text: 'Always from the office',
  q9Option2Text: 'Mostly from the office',
  q9Option3Text: 'Split our time between the office and remote',
  q9Option4Text: 'Mostly work remote',
  q9Option5Text: 'Always work remote',

  q10Option1Text: 'Dynamic and energetic',
  q10Option2Text: 'Energetic, with pockets of quiet',
  q10Option3Text: 'It depends - a mix of both',
  q10Option4Text: 'Quiet, with some dynamic times',
  q10Option5Text: 'Quiet and tranquil',

  q11Option1Text: 'Just the basics, enough to keep people going',
  q11Option2Text: 'The basics plus a few upgrades',
  q11Option3Text: 'The basics and a place where people can gather socially',
  q11Option4Text: 'Something really nice, a destination within the office',
  q11Option5Text: 'Something cool and differentiating - the first thing you show a visitor',

  q12AnswerText: 'I’m',
  q12OptionClientText: 'an existing CBRE client',
  q12OptionProfessionalText: 'a CBRE professional',
  q12OptionLeadText: 'someone else',

  q13AnswerText1: 'We will have',
  q13AnswerText2: 'employees in our office.',
  q13AnswerHelperText: '*Hint: This is usually the midpoint of your lease or about 2-5 years from now.',

  q14AnswerText1: 'We will spend',
  q14AnswerText2: 'of our time working in teams.',

  q15AnswerText1: 'On average, we plan to spend',
  q15AnswerText2: 'days per week in the office.',

  q16AnswerText1: 'Our office will be in the city of',
  q16AnswerText2: '.',

  q17AnswerText1: 'We work primarily in the',
  q17AnswerText2: 'sector.',

  q18AnswerText1: 'I’m currently located in',
  q18AnswerText2: '.',

  q19AnswerText1: 'We imagine our office density (SF per person) to be',
  q19AnswerText2: 'in the future.',

  // Quiz Responses (questionUnitMap.js)
  q1ResponseText: 'We’ll define an office that matches your vision.',
  q2ResponseText: 'Attractive mix!',
  q3ResponseText: 'Sounds nice - where do we apply?',
  q4ResponseText: 'Great! We will make sure the office fits.',
  q5Response1Text: 'Sounds good!',
  q5Response2Text: 'We tend to work overtime, too.',
  q5Response3Text: 'Sounds great!',
  q5Response4Text: 'Sounds awesome!',
  q6Response1Text: 'We’ll define a space that prioritizes personal space.',
  q6Response2Text: 'We’ll define a space that’s flexible.',
  q12ResponseLeadText: 'You came to the right place.',
  q12ResponseClientText: 'Perfect!',
  q12ResponseProfessionalText: 'Thanks!',

  // Industry Codes (industryCodes.js)
  animalProd: 'Animal production and aquaculture',
  forestry: 'Forestry and logging',
  fishingHunting: 'Fishing, hunting and trapping',
  agForestSupport: 'Agriculture and forestry support activities',
  oilGas: 'Oil and gas extraction',
  energy: 'Energy (Gas, Oil, etc.)',
  mining: 'Mining, except oil and gas',
  supportMining: 'Support activities for mining',
  utilities: 'Utilities',
  buildingConstruct: 'Construction of buildings',
  heavyCivilConstruct: 'Heavy and civil engineering construction',
  specialtyContract: 'Specialty trade contractors',
  foodManufac: 'Food Manufacturing',
  beverageTobaccoManufac: 'Beverage and tobacco product manufacturing',
  textile: 'Textile mills',
  textileProduct: 'Textile product mills',
  apparelManufac: 'Apparel manufacturing',
  leatherManufac: 'Leather and allied product manufacturing',
  woodManufac: 'Wood product manufacturing',
  paperManufac: 'Paper manufacturing',
  printingSupport: 'Printing and related support activities',
  printingReproduc: 'Printing/Reproductions',
  petroleumCoal: 'Petroleum and coal products manufacturing',
  chemicalManufac: 'Chemical manufacturing',
  chemicals: 'Chemicals',
  pharma: 'Pharmaceuticals',
  cleaningManufac: 'Cleaning Manufacturing',
  plasticsRubberManufac: 'Plastics and rubber products manufacturing',
  nonmetallicManufac: 'Nonmetallic mineral product manufacturing',
  primaryMetalManufac: 'Primary metal manufacturing',
  fabricatedMetalManufac: 'Fabricated metal product manufacturing',
  machineryManufac: 'Machinery manufacturing',
  manufac: 'Manufacturing',
  computerElectronicManufac: 'Computer and electronic product manufacturing',
  electricalApplianceManufac: 'Electrical equipment and appliance mfg.',
  transportEquipManufac: 'Transportation equipment manufacturing',
  aerospaceDefense: 'Aerospace/Defense',
  furnitureManufac: 'Furniture and related product manufacturing',
  miscManufac: 'Miscellaneous manufacturing',
  merchantWholesaleDurable: 'Merchant wholesalers, durable goods',
  merchantWholesaleNondurable: 'Merchant wholesalers, nondurable goods',
  electronicMarket: 'Electronic markets and agents and brokers',
  motorVehiclePartsDealers: 'Motor vehicle and parts dealers',
  furnitureFurnishingStores: 'Furniture and home furnishings stores',
  electronicApplianceStores: 'Electronics and appliance stores',
  electronics: 'Electronics',
  buildingGardenSupplyStores: 'Building material and garden supply stores',
  foodBeverageStores: 'Food and beverage stores',
  groceryStores: 'Grocery Stores',
  beerWineLiquorStores: 'Beer, Wine, and Liquor Stores',
  healthCareStores: 'Health and personal care stores',
  gasStations: 'Gasoline stations',
  clothingAccessoriesStores: 'Clothing and clothing accessories stores',
  clothingTextiles: 'Clothing/Textiles',
  jewelryFurs: 'Jewelry & Furs',
  sportsHobbyMusicBookStores: 'Sports, hobby, music instrument, book stores',
  genMerchandiseStores: 'General merchandise stores',
  miscStoreRetailers: 'Miscellaneous store retailers',
  officeProducts: 'Office Products',
  retailSales: 'Retail Sales',
  nonstoreRetails: 'Nonstore retailers',
  airTransport: 'Air transportation',
  travelRelated: 'Travel Related (Airlines, Agents, Mass Transit)',
  railTransport: 'Rail transportation',
  waterTransport: 'Water transportation',
  truckTransport: 'Truck transportation',
  transitTransport: 'Transit and ground passenger transportation',
  pipelineTransport: 'Pipeline transportation',
  scenicTransport: 'Scenic and sightseeing transportation',
  supportTransport: 'Support activities for transportation',
  postalService: 'Postal service',
  courierMessenger: 'Couriers and messengers',
  shippingDelivery: 'Shipping/Delivery',
  warehousingStorage: 'Warehousing and storage',
  warehouseDistrib: 'Warehouse/Distribution',
  publishingNoInternet: 'Publishing industries, except Internet',
  publishing: 'Publishing',
  motionPicSoundRecordingIndustries: 'Motion picture and sound recording industries',
  entertainment: 'Entertainment (Film, TV, Music, etc.)',
  infoServicesMarketResearch: 'Information Services/Market Research',
  broadcastingNoInternet: 'Broadcasting, except Internet',
  telecom: 'Telecommunications',
  tech: 'Technology',
  dataProcessingHostingServices: 'Data processing, hosting and related services',
  otherInfoServices: 'Other information services',
  financeInsurance: 'Finance and Insurance',
  monetaryAuthorities: 'Monetary authorities-central bank',
  creditIntermediation: 'Credit intermediation and related activities',
  banking: 'Banking',
  securitiesContractsInvestments: 'Securities, commodity contracts, investments',
  financialServicesNoBanking: 'Financial Services (Non-banking)',
  insuranceCarriersRelated: 'Insurance carriers and related activities',
  insurance: 'Insurance',
  fundsTrustsFinancialVehicles: 'Funds, trusts, and other financial vehicles',
  realEstate: 'Real Estate',
  rentalLeasingServices: 'Rental and leasing services',
  automotive: 'Automotive',
  consumerGoods: 'Consumer Goods',
  lessorsNonfinancialIntangible: 'Lessors of nonfinancial intangible assets',
  professionalTechnicalServices: 'Professional and technical services',
  legalServices: 'Legal Services',
  accounting: 'Accounting',
  architectureEngineering: 'Architecture/Engineering',
  specializedDesignServices: 'Specialized Design Services',
  compRelatedServices: 'Computer Related Services',
  mgmtConsulting: 'Management Consulting',
  scientificResearch: 'Scientific Research',
  adsMarketingPR: 'Advertising/Marketing/Public Relations',
  otherProfSciTechServices: 'Other Professional, Scientific, and Technical Services',
  companyEnterpriseMgmt: 'Management of companies and enterprises',
  adminSupportServices: 'Administrative and support services',
  employmentAgencyRecruiters: 'Employment Agency/Recruiters',
  securityPrivateInvestigation: 'Security/Private Investigation',
  wasteMgmtRemediationServices: 'Waste management and remediation services',
  educationServices: 'Educational services',
  education: 'Education',
  ambulatoryHealthCareServices: 'Ambulatory health care services',
  healthCareRelated: 'Health Care Related',
  hospitals: 'Hospitals',
  nursingResidentialCareFacilities: 'Nursing and residential care facilities',
  socialAssistance: 'Social assistance',
  performingArtsSpectatorSports: 'Performing arts and spectator sports',
  museumsHistoricalSitesZoosParks: 'Museums, historical sites, zoos, and parks',
  fineArtsGalleriesMuseums: 'Fine Arts (Galleries, Museums)',
  amusementsGamblingRec: 'Amusements, gambling, and recreation',
  accommodation: 'Accommodation',
  foodDrinkingServices: 'Food services and drinking places',
  restaurantHotel: 'Restaurant/Hotel',
  repairMaintenance: 'Repair and maintenance',
  industrialProductsHeavy: 'Industrial Products (Heavy Industry)',
  personalLaundryServices: 'Personal and laundry services',
  membershipAssocOrgs: 'Membership associations and organizations',
  nonprofit: 'Non-profit',
  privateHouseholds: 'Private households',
  execLegisGenGvt: 'Executive, legislative and general government',
  gvt: 'Government',
  justicePublicOrderSafetyActivities: 'Justice, public order, and safety activities',
  hrAdminPrograms: 'Administration of human resource programs',
  environmentAdminPrograms: 'Administration of environmental programs',
  environmental: 'Environmental',
  communityHousingProgramAdmin: 'Community and housing program administration',
  econProgramAdmin: 'Administration of economic programs',
  spaceResearchTech: 'Space research and technology',
  natlSecurityIntlAffairs: 'National security and international affairs'

};
