const React              = require('react');
const PropTypes          = require('prop-types');
const { withTranslation } = require('react-i18next');

function SuccessModal(props) {
  return (
    <div className="success-modal">
      <div className="title is-4 has-text-primary">{props.t('successModalTitle')}</div>

      <div className="has-margin-top-4 has-margin-bottom-4">{props.text}</div>
    </div>
  );
}

SuccessModal.propTypes = {
  text: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(SuccessModal);
