const { connect }            = require('react-redux');
const locationUtil           = require('../../../../../shared/util/location.js');
const userUtil               = require('../../../../util/user');
// const globalActions          = require('../../../../redux/actions/global');
import globalActions from '../../../../redux/actions/global.js';
const userActions            = require('../../../../redux/actions/userData');
const { bindActionCreators } = require('redux');

const EmailWallSection = require('../../../results/program/EmailWallSection.jsx');

// Map state to props
function mapStateToProps(state) {
  const location = locationUtil.getCityStateCountry(state.userData.currentPersonalLocation);

  return {
    isUserInEEA: locationUtil.isEuropeanEconomicArea(location.country),
    isUserInCalifornia: locationUtil.isCalifornia(location.state, location.country),
    isCBREEmployee: userUtil.isCBREEmployee(state.userData.role),
    isCBREClient: userUtil.isCBREClient(state.userData.role),
    cbreContact: state.userData.cbreContact,
    sourceId: state.userData.sourceId
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    saveProgram: bindActionCreators(globalActions.saveProgram, dispatch),
    addBulkUserData: bindActionCreators(userActions.addBulkUserData, dispatch),
    sendHeapEventWithoutProps: bindActionCreators(globalActions.sendHeapEventWithoutProps, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(EmailWallSection);

