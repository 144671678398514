module.exports = {

  // Quiz
  exitQuizButtonTitle: 'Wyjdź z kwestionariusza',

  // QuizProgressLabel
  progressLabelText: 'ukończono',

  // QuizNavigationButton
  quizNavBackButtonShortenedText: 'Wróć',
  quizNavBackButtonFullLengthText: 'Poprzednie pytanie',
  quizNavNextButtonShortenedText: 'Dalej',
  quizNavNextButtonFullLengthText: 'Następne pytanie',

  // QuizExitSlide
  quizExitSubtitle: 'Analizujemy Twoje odpowiedzi, aby móc przedstawić Ci zalecenia co do idealnego miejsca pracy dla Twojej organizacji.',
  quizExitImgAlt: 'Grafika przedstawiająca dwie osoby przybijające piątkę znajdująca się na stronie Kwestionariusz aplikacji CBRE Spacer',

  // Question Unit Text (questionUnitMap.js)
  question1Text: 'Zaczynamy… Jak opisałbyś przeznaczenie swojego przyszłego biura?',
  question2Text: 'Jak myślisz, co w Twojej organizacji jest najbardziej atrakcyjne dla kandydatów starających się o pracę?',
  question3Text: 'Jakie są najważniejsze rzeczy, na które zwracasz uwagę przy zatrudnianiu nowych członków zespołu?',
  question4Text: 'Jak w ciągu najbliższych pięciu lat będzie zmieniać się wielkość Twojej organizacji?',
  question5Text: 'Jak wygląda typowy dzień pracy Twoich pracowników?',
  question6Text: 'Co myślisz o własnych biurkach?',
  question7Text: 'Gdzie faktycznie pracownicy będą wykonywać swoją pracę podczas pracy w biurze?',
  question8Text: 'Jaki strój obowiązuje Twoich pracowników podczas pracy w biurze?',
  question9Text: 'Gdzie będzie wykonywana praca?',
  question10Text: 'Jaką atmosferę chciałbyś stworzyć w Twoim biurze?',
  question11Text: 'Co, poza samym „miejscem do pracy”, chciałbyś zapewnić swoim pracownikom?',
  question12Text: 'Które z poniższych określeń najlepiej do Ciebie pasuje?',

  // Quiz Answers (questionUnitMap.js)
  pickOnePlaceholder: 'Wybierz jedno...',
  selectFromListPlaceholder: 'Wybierz z listy...',
  firstResponseTooltipText: 'Wybierz swoją pierwszą odpowiedź',
  locatedSomewhereElseLabel: 'Obecnie nasze biuro mieści się w innej lokalizacji.',

  q1AnswerText1: 'Uważamy, że nasze biuro to po pierwsze',
  q1AnswerText2: 'po drugie',
  q1AnswerText3: 'i po trzecie',
  q1Option1Text: 'drugi dom dla naszych pracowników',
  q1Option2Text: 'motor zmian organizacyjnych',
  q1Option3Text: 'miejsce pozwalające na skupienie się',
  q1Option4Text: 'inkubator przełomowych pomysłów',
  q1Option5Text: 'narzędzie przyciągające największe talenty',
  q1Option6Text: 'wizytówka naszej marki',
  q1Option7Text: 'przestrzeń umożliwiająca rozwój i naukę',
  q1Option8Text: 'miejsce spotkań i przebywania z innymi ludźmi',
  q1Option9Text: 'niezbędny koszt, który staramy się zredukować do koniecznego minimum',

  q2AnswerText1: 'Powody, dla których ludzie dołączają do naszej organizacji, to po pierwsze',
  q2AnswerText2: 'po drugie',
  q2AnswerText3: 'i po trzecie',
  q2Option1Text: 'kultura organizacyjna',
  q2Option2Text: 'konkurencyjne wynagrodzenie',
  q2Option3Text: 'reputacja firmy',
  q2Option4Text: 'atrakcyjne świadczenia pracownicze i dodatki',
  q2Option5Text: 'elastyczne godziny pracy',
  q2Option6Text: 'pracujący w niej ludzie i kadra zarządzająca',
  q2Option7Text: 'atmosfera panująca w biurze',
  q2Option8Text: 'nasze cele i misja',

  q3AnswerText1: 'Cechy, których poszukujemy u zatrudnianych pracowników, to po pierwsze',
  q3AnswerText2: 'po drugie',
  q3AnswerText3: 'i po trzecie',
  q3Option1Text: 'umiejętności',
  q3Option2Text: 'doświadczenie',
  q3Option3Text: 'dopasowanie kulturowe',
  q3Option4Text: 'kwalifikacje',
  q3Option5Text: 'sieć kontaktów',
  q3Option6Text: 'ciekawość / dociekliwość',

  q4AnswerText: 'Będziemy',
  q4Option1Text: 'nieznacznie rosnąć każdego roku',
  q4Option2Text: 'znacznie rosnąć każdego roku',
  q4Option3Text: 'utrzymywać wielkość naszej organizacji mniej więcej na tym samym poziomie',
  q4Option4Text: 'redukować nieznacznie liczbę pracowników każdego roku',
  q4Option5Text: 'trudno powiedzieć – tego nie da się przewidzieć',

  q5AnswerText: 'Zwykle pracujemy',
  q5Option1Text: 'od 9.00 do 17.00',
  q5Option2Text: 'od rana do...późna',
  q5Option3Text: 'w dowolnych godzinach – pod warunkiem, że zostaje przepracowana odpowiednia liczba godzin',
  q5Option4Text: 'w dowolnych godzinach, a liczba przepracowanych godzin nie ma znaczenia',

  q6AnswerText: 'Uważamy, że',
  q6Option1Text: 'każdy pracownik powinien mieć własne biurko',
  q6Option2Text: 'lepszym rozwiązaniem może być praca przy różnych biurkach, nieprzypisanych konkretnym pracownikom',

  q7Option1Text: 'Zawsze przy własnym biurku',
  q7Option2Text: 'Przede wszystkim przy własnym biurku. Częściowo na spotkaniach lub podczas innych czynności',
  q7Option3Text: 'Czas będzie równo podzielony pomiędzy pracę przy biurku a pracę w innym miejscu w biurze',
  q7Option4Text: 'Przede wszystkim przemieszczając się pomiędzy różnymi strefami biura, okazjonalnie przy biurku',
  q7Option5Text: 'Zawsze przemieszczając się pomiędzy różnymi strefami biura',

  q8Option1Text: 'Elegancki strój biznesowy',
  q8Option2Text: 'Najczęściej strój biznesowy, z wyjątkiem specjalnych okazji',
  q8Option3Text: 'Swobodny strój biznesowy, czasami z większym naciskiem na elegancję',
  q8Option4Text: 'Najczęściej swobodny, z wyjątkiem specjalnych okazji',
  q8Option5Text: 'Swobodny',

  q9Option1Text: 'Zawsze w biurze',
  q9Option2Text: 'Najczęściej w biurze',
  q9Option3Text: 'Czas będzie podzielony pomiędzy pracę w biurze a zdalną',
  q9Option4Text: 'Najczęściej zdalnie',
  q9Option5Text: 'Zawsze zdalnie',

  q10Option1Text: 'Dynamiczną i energiczną',
  q10Option2Text: 'Energiczną z cichymi strefami',
  q10Option3Text: 'To zależy – mieszankę obydwu',
  q10Option4Text: 'Cichą, z dynamicznymi elementami',
  q10Option5Text: 'Cichą i spokojną',

  q11Option1Text: 'Tylko miejsce do pracy, umożliwiające pracownikom wykonywanie zadań',
  q11Option2Text: 'Miejsce do pracy z paroma uatrakcyjnieniami',
  q11Option3Text: 'Miejsce do pracy i przestrzeń, w której pracownicy mogą się spotykać, tworząc relacje społeczne',
  q11Option4Text: 'Coś naprawdę atrakcyjnego, biuro będące celem samym w sobie',
  q11Option5Text: 'Coś atrakcyjnego i wyróżniającego naszą organizację – biuro, które z dumą będziemy pokazywać osobom odwiedzającym',

  q12AnswerText: 'Jestem',
  q12OptionClientText: 'obecnym klientem CBRE',
  q12OptionProfessionalText: 'pracownikiem CBRE',
  q12OptionLeadText: 'żadne z powyższych',

  q13AnswerText1: 'Licza osób, które będą pracować w naszym biurze:',
  q13AnswerText2: '',
  q13AnswerHelperText: '*Wskazówka: zazwyczaj jest to połowa okresu najmu lub około 2-5 lat od teraz.',

  q14AnswerText1: 'Na pracy zespołowej spędzamy',
  q14AnswerText2: 'naszego czasu.',

  q15AnswerText1: 'Średnia liczba dni w tygodniu, którą zamierzamy spędzać w biurze:',
  q15AnswerText2: '',

  q16AnswerText1: 'Nasze biuro będzie zlokalizowane w',
  q16AnswerText2: '.',

  q17AnswerText1: 'Sektor, na którym przede wszystkim skupia się nasza praca to:',
  q17AnswerText2: '',

  q18AnswerText1: 'Obecnie nasze biuro mieści się w',
  q18AnswerText2: '.',

  q19AnswerText1: 'Zakładamy, że w przyszłości zagęszczenie pracowników (liczba mkw./os.) w naszym biurze będzie',
  q19AnswerText2: '',

  // Quiz Responses (questionUnitMap.js)
  q1ResponseText: 'Przedstawimy Ci pomysł na biuro pasujące do Twojej wizji.',
  q2ResponseText: 'Interesujące!',
  q3ResponseText: 'Brzmi nieźle – gdzie możemy przesłać nasze CV?',
  q4ResponseText: 'Wspaniale! A teraz my zadbamy o to, żeby biuro spełniało Twoje potrzeby.',
  q5Response1Text: 'Brzmi dobrze!',
  q5Response2Text: 'Nam też czasami zdarza się pracować w godzinach nadliczbowych.',
  q5Response3Text: 'Brzmi wspaniale!',
  q5Response4Text: 'Brzmi świetnie!',
  q6Response1Text: 'Przedstawimy Ci pomysł na biuro, w którym przestrzeń osobista będzie najważniejsza.',
  q6Response2Text: 'Przedstawimy Ci pomysł na elastyczną przestrzeń biurową.',
  q12ResponseLeadText: 'Trafiłeś we właściwe miejsce.',
  q12ResponseClientText: 'Idealnie!',
  q12ResponseProfessionalText: 'Dziękujemy!',

  // Industry Codes (industryCodes.js)
  animalProd: 'Chów i hodowla zwierząt, w tym ryb oraz pozostałych organizmów wodnych ',
  forestry: 'Leśnictwo i pozyskiwanie drewna',
  fishingHunting: 'Rybołówstwo, łowiectwo i pozyskiwanie zwierząt łownych',
  agForestSupport: 'Działalność usługowa wspomagająca rolnictwo i leśnictwo',
  oilGas: 'Górnictwo ropy naftowej i gazu ziemnego',
  energy: 'Energetyka (gaz ziemny, ropa naftowa itp.)',
  mining: 'Górnictwo, z wyjątkiem ropy naftowej i gazu ziemnego',
  supportMining: 'Działalność usługowa wspomagająca górnictwo',
  utilities: 'Usługi komunalne',
  buildingConstruct: 'Roboty budowlane związane ze wznoszeniem budynków',
  heavyCivilConstruct: 'Roboty związane z budową obiektów inżynierii lądowej i wodnej',
  specialtyContract: 'Specjalistyczne usługi budowlane',
  foodManufac: 'Produkcja artykułów spożywczych',
  beverageTobaccoManufac: 'Produkcja napojów i wyrobów tytoniowych',
  textile: 'Zakłady włókiennicze',
  textileProduct: 'Produkcja wyrobów tekstylnych',
  apparelManufac: 'Produkcja odzieży',
  leatherManufac: 'Produkcja wyrobów skórzanych i pokrewnych',
  woodManufac: 'Produkcja wyrobów z drewna',
  paperManufac: 'Produkcja papieru',
  printingSupport: 'Drukowanie i działalność usługowa związana z poligrafią',
  printingReproduc: 'Poligrafia/Reprodukcja',
  petroleumCoal: 'Wytwarzanie i przetwarzanie produkcji rafinacji ropy naftowej i węgla kamiennego',
  chemicalManufac: 'Produkcja chemikaliów i wyrobów chemicznych',
  chemicals: 'Chemikalia i wyroby chemiczne',
  pharma: 'Substancje farmaceutyczne',
  cleaningManufac: 'Produkcja środków myjących i czyszczących',
  plasticsRubberManufac: 'Produkcja wyrobów z gumy i tworzyw sztucznych',
  nonmetallicManufac: 'Produkcja wyrobów z mineralnych surowców niemetalicznych',
  primaryMetalManufac: 'Produkcja metali',
  fabricatedMetalManufac: 'Produkcja metalowych wyrobów gotowych',
  machineryManufac: 'Produkcja maszyn',
  manufac: 'Przetwórstwo przemysłowe',
  computerElectronicManufac: 'Produkcja komputerów i wyrobów elektronicznych',
  electricalApplianceManufac: 'Produkcja urządzeń elektrycznych',
  transportEquipManufac: 'Produkcja sprzętu transportowego',
  aerospaceDefense: 'Lotnictwo i kosmonautyka / Obronność',
  furnitureManufac: 'Produkcja mebli i produktów powiązanych',
  miscManufac: 'Pozostała produkcja wyrobów',
  merchantWholesaleDurable: 'Handel hurtowy, towary trwałego użytku',
  merchantWholesaleNondurable: 'Handel hurtowy, towary nietrwałego użytku',
  electronicMarket: 'Rynki elektroniczne oraz agenci i brokerzy',
  motorVehiclePartsDealers: 'Obrót pojazdami samochodowymi i ich częściami',
  furnitureFurnishingStores: 'Sprzedaż mebli i artykułów wyposażenia wnętrz',
  electronicApplianceStores: 'Sprzedaż artykułów RTV i AGD',
  electronics: 'Artykuły elektroniczne',
  buildingGardenSupplyStores: 'Sprzedaż materiałów budowlanych i artykułów ogrodniczych',
  foodBeverageStores: 'Sprzedaż żywności i napojów',
  groceryStores: 'Sprzedaż artykułów spożywczych',
  beerWineLiquorStores: 'Sprzedaż piwa, wina i alkoholi wysokoprocentowych',
  healthCareStores: 'Sprzedaż artykułów ochrony zdrowia i higieny osobistej',
  gasStations: 'Sprzedaż paliw do pojazdów silnikowych na stacjach paliw',
  clothingAccessoriesStores: 'Sprzedaż odzieży i akcesoriów odzieżowych',
  clothingTextiles: 'Odzież / Wyroby tekstylne',
  jewelryFurs: 'Biżuteria i wyroby futrzarskie',
  sportsHobbyMusicBookStores: 'Sprzedaż artykułów sportowych, hobbystycznych, instrumentów muzycznych oraz książek',
  genMerchandiseStores: 'Sprzedaż wielobranżowa / wyrobów ogólnego użytku',
  miscStoreRetailers: 'Pozostała sprzedaż',
  officeProducts: 'Sprzedaż artykułów biurowych',
  retailSales: 'Sprzedaż detaliczna',
  nonstoreRetails: 'Sprzedaż prowadzona poza siecią sklepową',
  airTransport: 'Transport lotniczy',
  travelRelated: 'Działalność związana z turystyką (linie lotnicze, agenci, transport masowy)',
  railTransport: 'Transport kolejowy',
  waterTransport: 'Transport wodny',
  truckTransport: 'Transport ciężarowy',
  transitTransport: 'Transport lądowy pasażerski, miejski i podmiejski',
  pipelineTransport: 'Transport rurociągowy',
  scenicTransport: 'Transport turystyczny',
  supportTransport: 'Działalność usługowa wspomagająca transport',
  postalService: 'Działalność pocztowa',
  courierMessenger: 'Kurierzy i dostarczyciele przesyłek',
  shippingDelivery: 'Wysyłka i doręczanie towarów',
  warehousingStorage: 'Magazynowanie i przechowywanie towarów',
  warehouseDistrib: 'Magazynowanie / Dystrybucja towarów',
  publishingNoInternet: 'Branże wydawnicze, poza Internetem',
  publishing: 'Działalność wydawnicza',
  motionPicSoundRecordingIndustries: 'Działalność związana z produkcją filmów i nagrań dźwiękowych',
  entertainment: 'Działalność rozrywkowa (film, telewizja, muzyka itp.)',
  infoServicesMarketResearch: 'Działalność usługowa w zakresie informacji / badań rynku',
  broadcastingNoInternet: 'Nadawanie programów ogólnodostępnych i abonamentowych, poza Internetem',
  telecom: 'Telekomunikacja',
  tech: 'Technologia',
  dataProcessingHostingServices: 'Przetwarzanie danych, zarządzanie stronami internetowymi i podobna działalność',
  otherInfoServices: 'Pozostała działalność usługowa w zakresie informacji',
  financeInsurance: 'Działalność finansowa i ubezpieczeniowa',
  monetaryAuthorities: 'Władze monetarne – działalność banku centralnego',
  creditIntermediation: 'Pośrednictwo kredytowe i działalność powiązana',
  banking: 'Bankowość',
  securitiesContractsInvestments: 'Działalność związana z rynkiem papierów wartościowych, towarów giełdowych i inwestycjami',
  financialServicesNoBanking: 'Usługi finansowe (pozabankowe)',
  insuranceCarriersRelated: 'Działalność ubezpieczeniowa i z nią powiązana',
  insurance: 'Ubezpieczenia',
  fundsTrustsFinancialVehicles: 'Fundusze, fundusze powiernicze i inne narzędzia finansowe',
  realEstate: 'Działalność związana z obsługą rynku nieruchomości',
  rentalLeasingServices: 'Wynajem nieruchomości',
  automotive: 'Rynek motoryzacyjny',
  consumerGoods: 'Dobra konsumpcyjne',
  lessorsNonfinancialIntangible: 'Leasingodawcy niefinansowych wartości niematerialnych i prawnych',
  professionalTechnicalServices: 'Działalność profesjonalna i techniczna',
  legalServices: 'Działalność prawnicza',
  accounting: 'Działalność rachunkowo-księgowa',
  architectureEngineering: 'Działalność w zakresie architektury i inżynierii',
  specializedDesignServices: 'Działalność w zakresie specjalistycznego projektowania',
  compRelatedServices: 'Działalność usługowa w zakresie technologii komputerowych ',
  mgmtConsulting: 'Doradztwo związane z zarządzaniem',
  scientificResearch: 'Badania naukowe',
  adsMarketingPR: 'Działalność związana z reklamą / marketingiem / stosunkami międzyludzkimi',
  otherProfSciTechServices: 'Pozostała działalność profesjonalna, naukowa i techniczna',
  companyEnterpriseMgmt: 'Zarządzanie firmami i przedsiębiorstwami',
  adminSupportServices: 'Działalność w zakresie usług administrowania i działalność wspierająca',
  employmentAgencyRecruiters: 'Działalność związana z wyszukiwaniem miejsc pracy i pozyskiwaniem pracowników',
  securityPrivateInvestigation: 'Działalność detektywistyczna i ochroniarska',
  wasteMgmtRemediationServices: 'Gospodarowanie ściekami i odpadami oraz działalność związana z rekultywacją',
  educationServices: 'Działalność związana z edukacją',
  education: 'Edukacja',
  ambulatoryHealthCareServices: 'Ambulatoryjna opieka zdrowotna',
  healthCareRelated: 'Działalność związana z opieką zdrowotną',
  hospitals: 'Działalność szpitali',
  nursingResidentialCareFacilities: 'Pomoc społeczna z zakwaterowaniem zapewniająca opiekę pielęgniarską',
  socialAssistance: 'Pomoc społeczna',
  performingArtsSpectatorSports: 'Działalność związana z wystawianiem przedstawień artystycznych i sportami widowiskowymi ',
  museumsHistoricalSitesZoosParks: 'Działalność muzeów, miejsc historycznych, ogrodów zoologicznych i parków',
  fineArtsGalleriesMuseums: 'Działalność związana ze sztukami pięknymi (galerie, muzea)',
  amusementsGamblingRec: 'Działalność rozrywkowa i rekreacyjna oraz związana z grami losowymi',
  accommodation: 'Zakwaterowanie',
  foodDrinkingServices: 'Działalność usługowa związana z wyżywieniem',
  restaurantHotel: 'Restauracje / Hotele',
  repairMaintenance: 'Naprawa i konserwacja',
  industrialProductsHeavy: 'Wyroby przemysłowe (przemysł ciężki)',
  personalLaundryServices: 'Indywidualna działalność usługowa i usługi związane z praniem i czyszczeniem',
  membershipAssocOrgs: 'Działalność organizacji i stowarzyszeń członkowskich',
  nonprofit: 'Działalność organizacji non-profit',
  privateHouseholds: 'Prywatne gospodarstwa domowe',
  execLegisGenGvt: 'Działalność organów wykonawczych, ustawodawczych oraz instytucji rządowych i samorządowych',
  gvt: 'Działalność rządu',
  justicePublicOrderSafetyActivities: 'Wymiar sprawiedliwości, porządek i bezpieczeństwo publiczne',
  hrAdminPrograms: 'Administrowanie programami kadrowymi',
  environmentAdminPrograms: 'Administrowanie programami środowiskowymi',
  environmental: 'Działalność związana ze środowiskiem',
  communityHousingProgramAdmin: 'Administracja wspólnotowa i mieszkaniowa',
  econProgramAdmin: 'Administrowanie programami ekonomicznymi',
  spaceResearchTech: 'Badania i rozwój technologiczny w zakresie przestrzeni kosmicznej',
  natlSecurityIntlAffairs: 'Bezpieczeństwo państwa i sprawy zagraniczne'

};
