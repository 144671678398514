module.exports = {
  blogHomeTitle: 'Perspectivas y proyecciones de la oficina',

  // blog tags
  blogTagAllArticles: 'Todos los artículos ',
  blogTagSizeAndFit: 'Tamaño y ajuste ',
  blogTagOfficeCulture: 'Cultura de la oficina / Cultura organizacional ',
  blogTagSpaceAndDesign: 'Espacio y Diseño ',
  blogTagWorkplaceTrends: 'Tendencias en el lugar de trabajo '
};
