module.exports = {

  // Results
  resultsWarningMessage: 'Biztos, hogy elakarod hagyni ezt az oldalt? Azok az adatok elvesznek amiket nem mentettél el.',

  // ResultsHeader
  resultsHeaderExportPDFButtonText: 'PDF exportálása',
  resultsHeaderSendButtonText: 'Eredmények elküldése',
  resultsHeaderClipboardBoxDescription: 'Bárki a link birtokában megnézheti és szerkesztheti az eredményeket',
  resultsHeaderContactButtonText: 'Lépj kapcsolatba a tanácsadónkkal',

  // EmailWallSection
  emailWallTitle: 'Még több minden vár rád',
  emailWallSubtitle: 'Add meg a neved és az email címed, hogy megtekintsd a teljes értékelést a javaslatainkkal. Itt szerkeszteni is tudod majd az eredményeidet.',
  emailWallFirstNamePlaceholder: 'Keresztnév',
  emailWallLastNamePlaceholder: 'Vezetéknév',
  emailWallEmailPlaceholder: 'Email cím',
  emailWallCBREEmailPlaceholder: 'A CBRE-s email címed (opcionális)',
  emailWallLeasingProfessionalPlaceholder: 'A CBRE-s lízingszakértőd',
  emailWallConnectMeText: 'Kössön össze egy személyes ingatlan szakértővel. Ígérjük, hogy nem küldünk hírlevelet vagy spam üzenetet',
  emailWallContactNoticeText: 'A Spacer csapat megkereshet téged, hogy az eredményeidből a legtöbbet kihozhassátok. Ígérjük, hogy nem spamelünk felesleges emailekkel.',
  emailWallResultsButtonText: 'Tekintsd meg a teljes eredményt',
  emailWallImgAltText: 'Az eredmények oldal előnézete a Spacer a CBRE-tól e-mail falán',
  emailWallFirstNameInvalid: 'Kérlek add meg a keresztneved.',
  emailWallLastNameInvalid: 'Kérlek add meg a vezetékneved.',
  emailWallEmailInvalid: 'Kérlek adj meg egy létező email címet.',

  // CopyToClipboardBox
  clipboardBoxCopyText: 'Másolás',
  clipboardBoxCopiedText: 'Másolva',

  // FormModal
  formModalFindSpaceHeader: 'Kezd el az iroda keresést',
  formModalFindSpaceBody: 'A Spacer egy gyors és kézenfekvő eszköz az irodai tér elemzés készítéséhez, azonban ha személyreszabotabb eredményre van szükséged akkor hagyd, hogy a CBRE Workplace segítsen. Töltsd ki ezt az űrlapot és a Workplace szakemberei felveszik veled a kapcsolatot 48 órán belül.',
  formModalFindSpaceSuccessMessage: 'A kérésedet sikeresen elküldted.',

  // SuccessModal
  successModalTitle: 'Siker!',

  // ContactForm
  contactFormFirstNameLabel: 'Keresztnév',
  contactFormLastNameLabel: 'Vezetéknév',
  contactFormEmailLabel: 'Céges email cím',
  contactFormPhoneLabel: 'Mobil',
  contactFormMessageLabel: 'Árulj el többet arról, hogy mi érdekel téged',
  contactFormSubmitText: 'Beküldés',

  // AcceptTermsText
  acceptTermsText: 'Elfogadom az Általános Szerződési Feltételeket. További információért az adatainak felhasználásáról olvassa el a CBRE adatvédelmi irányelveket.',
  privacyPolicyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Form
  formErrorText: 'Hoppá, sajnos valami félrement. Kérlek próbáld meg újra!',
  formEmailInvalid: 'Kérlek adj meg egy létező email címet.',

  // FormInput
  formInputOptionalText: ' (opcionális)',

  // LegalForm
  legalFormCheckboxText: 'CBRE megkereshet engem ajánlatokkal és a Spacer-hez kapcsolódó informácival.',
  legalFormContactWarningText: 'A Spacer csapat megkereshet téged, hogy az eredményeidből a legtöbbet kihozhassátok. Ígérjük, hogy nem spamelünk felesleges emailekkel.',

  // NextStepsSection
  nextStepsSectionSubtitle: 'Készen állsz a következő lépésre?',
  nextStepsSectionTitle: 'Találd meg a tökéletes irodád.',
  nextStepsSectionButtonText: 'Lépj kapcsolatba a tanácsadónkkal',
  nextStepsSectionImgAlt: 'Illusztráció beszélő emberekről a Space a CBRE-tól Eredmények oldalán',

  // Profile
  resultsProfileSummaryTitle: 'A te irodád —',
  resultsProfileTooltipText: 'Minden profil valódi szervezetekből és munkahelyekből áll, és a részleteket az iparági irányelvek és a CBRE Workplace egyedi piaci ismeretei alapján ellenőrizték.',

  // Program
  programSaveConfirmationBoxText: 'Minden készen áll! Elküdtük emailben az eredményeidet.',
  programExamplePlansTitle: 'Minta Tervek',
  programExamplePlansSubtitle: 'Kísérletezz interaktív alaprajzokkal, amelyek megfelelnek az igényeidnek. Akár 3D-ben is felfedezheted őket!',
  programTooMuchTinkerWarning: 'Ajjaj! Úgy tűnik, hogy a profilod alapján a javasolt sávon kívül vagy. Fontold meg hogy <1>újrakezded a kérdőívet</1> vagy segítséget kérsz ezen az e-mail címen: <3>{{email}}</3>.',
  programDisclaimer: 'A CBRE Spacer funkciójához nem kapcsolódik jótállás. Az elkészült eredmények az általad megadott adatokból következnek csupán bemutató jelleggel és nem tükröznek valós térjavaslatot vagy tartalmaznak javasolt bérleti feltételeket. A valódi javaslatok nagyban eltérhetnek a kérdőív eredményeitől, hiszen azok számos olyan tényezőn múlnak, amelyet a Spacer nem tud számításba venni. A Spacer használatával vagy az abból generált bármely riporttal elfogadod a Felhasználási Feltételeinket és az Adatvédelmi Szabályzatot, amelyek a következő oldalakon találhatóak https://workplace.cbre.com/termsOfService, https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy. 2021 CBRE, Inc. All rights reserved.',
 
  programMetricsTitle: 'Program metrikák',
  programMetricsSubtitle: 'Ezek a metrikák határozzák meg a munkakörnyezeted',
  programDetailsSectionTitle: 'Szabd saját igényeidre a programod',
  programDetailsSectionSubtitle: 'Állítsd be az alábbi mezőket az irodai helyigényed finomhangolásához.',
  programRevertButtonText: 'Visszaállítás',
  programSaveButtonText: 'Módosítások mentése',
  programSavedButtonText: 'Mentve',

  // ProgramAssumptions
  programAssumptionsRecommendedOfficeSizeText: 'Javasolt irodaméret',
  programAssumptionsProfileText: 'Profil',
  programAssumptionsHeadcountText: 'Alkalmazottak száma',
  programAssumptionsHeadcountFollowingText: 'Emberek',
  programAssumptionsCollabText: 'Kollaboráció',
  programAssumptionsCirculationText: 'Forgási sebesség',
  programAssumptionsAvgDaysText: 'Átlagban az irodában töltött napok száma',
  programAssumptionsAvgDaysFollowingText: 'hetente',
  programAssumptionsAvgDaysPerWeekText: 'Hetente az irodában töltött napok száma (átlagban)',

  // ProgramHeroForPrint
  printNIAText: 'Nettó belső terület',
  printUSFText: 'Felhasználható nettó négyzetláb',
  printRSFText: 'Bérelhető négyzetláb',
  printDescText: 'A megadott részletek alapján, és a {{lossFactorPercentage}}% {{factorType}} {{city}}en, összesen ennyi irodaterek javasolunk-',
  printFitFactorText: 'egyezési tényező',
  printLossFactorText: 'veszteségi tényező',

  // BarChart
  barChartIndivSubtitle: 'Egyéni tér',
  barChartGroupSubtitle: 'Csoportos tér',
  barChartAmenitySubtitle: 'Kényelmi tér',

  // TabbedProgramBarChart
  tabbedBarChartMeSubtitle: 'Egyén',
  tabbedBarChartGroupSubtitle: 'Csoport',
  tabbedBarChartAmenitySubtitle: 'Szolgáltatások',

  // SpaceCategoryBreakdown
  spaceCatBreakdownSubtitle: 'Válassz egy iroda típust a testreszabáshoz',

  // SpaceCategoryBreakdownMobile
  spaceCatBreakdownMobileMeTitle: 'Egyéni tér',
  spaceCatBreakdownMobileWeTitle: 'Közösségi tér',
  spaceCatBreakdownMobileAmenityTitle: 'Kényelmi tér',
  spaceCatBreakdownMobileMeSubtitle: 'egyéni munkához',
  spaceCatBreakdownMobileWeSubtitle: 'kollaborációs munkához',
  spaceCatBreakdownMobileAmenitySubtitle: 'minden máshoz',
  spaceCatBreakdownMobileNIATotalTitle: 'Teljes nettó belső terület',
  spaceCatBreakdownMobileTotalTitle: 'Teljes {{sfType}} négyzet {{unitCapitalized}}',
  spaceCatBreakdownMobileCirculationText: 'Forgási sebesség',

  // SpaceCategoryDropdown
  spaceCatDropdownMeText: 'Egyéni tér',
  spaceCatDropdownWeText: 'Közösségi tér',
  spaceCatDropdownAmenityText: 'Kényelmi tér',

  // SidebarInfo
  sidebarInfoMeTitle: 'Egyén',
  sidebarInfoWeTitle: 'Csoport',
  sidebarInfoAmenityTitle: 'Szolgáltatások',

  // Space Category Descriptions (spaceCategoryDescription.js)
  spaceCatMeDesc: 'Irodatér az egyéni munkához. A munkavégzés helyének és működésének választási lehetősége nagyobb munkavállalói elégedettséghez vezet.',
  spaceCatWeDesc: 'Irodatér a hatékony együttműködéshez. Egy olyan korszakban, amikor a munka bárhol történhet, fontos, hogy sokféle helyet biztosítsunk a különböző feladatok elvégzéséhez.',
  spaceCatAmenityDesc: 'A munkát támogató irodatér. A nagyszerű kényelmi szolgáltatások miatt az irodád egy olyan hellyé válhat ahol a munkavállalók szívesen töltik az idejüket.',

  // Suggested Custom Amenities (suggestedCustomAmenityUnitMap.js)
  audioVisualCustom: 'Audio / Vizuális',
  dataCenterCustom: 'Adatközpont',
  buildingSupportCustom: 'Épület-támogató szolgáltatások',
  foodServiceCustom: 'Teljeskörű étkezési szolgáltatás',
  kitchenCustom: 'Konyha',
  serveryCustom: 'Szolgálat',
  execDiningCustom: 'Felsővezető étkező',
  grabNGoCustom: 'Étel és ital automata',
  juiceCoffeeBarCustom: 'Kávézó',
  childCareCustom: 'Gyermekgondozás',
  fitnessCenterCustom: 'Fitneszközpont',
  healthCenterCustom: 'Egészségügyi központ',
  mailCenterCustom: 'Posta / Postaközpont',
  commercialPrintCustom: 'Kereskedelmi nyomtató üzlet',
  centralCafeCustom: 'Központi kávézó',
  securityCtrlCustom: 'Biztonsági szoba',
  auditoriumCustom: 'Előadóterem',
  dryCleaningLaundryCustom: 'Vegytisztítás / Mosoda',
  confCenterCustom: 'Konferencia központ',
  multiPurpTrainingCustom: 'Több-célú / Képzés',
  informalCollabCustom: 'Informális együttműködés',
  touchdownTeamCustom: 'Touchdown / Csapat',

  // Suggested Custom Amenity Units (spaceUnit.js)
  seats: 'ülés',
  seats_plural: 'ülések',
  rooms: 'tárgyal',
  rooms_plural: 'tárgyalók',
  areas: 'terület',
  areas_plural: 'területek',
  units: 'mértékegység',
  units_plural: 'mértékegységek',

  // Predefined Amenities (spaceUnitMap.js) - Shared Space Units
  focusRoomDefined: 'Fókusz tárgyaló',
  fileRoomDefined: 'Iratszoba',
  centralReceptionHubDefined: 'Központi recepció',
  smOfficeDefined: 'Kis iroda',
  regOfficeDefined: 'Normál iroda',
  benchDesk5Defined: '5’ íróasztal',
  benchDesk6Defined: '6’ íróasztal',
  meetingRoom4Defined: 'Tárgyaló (4 fő)',
  meetingRoom6Defined: 'Tárgyaló (6 fő)',
  meetingRoom8Defined: 'Tárgyaló (8 fő)',
  meetingRoom12Defined: 'Tárgyaló (12 fő)',
  meetingRoom15Defined: 'Tárgyaló (15 fő)',
  semiEnclosedMeetingBooth4Defined: 'Félig zárt tárgyaló (4 fő)',
  openCollabSpace4to6Defined: 'Nyitott kollaborációs tér (4-6 fő)',
  contemplationSpaceDefined: 'Csendes tárgyaló',
  mainITEquipRoomDefined: 'Informatikai szerver szoba',
  receptionAreaDefined: 'Recepció',
  cafeFoodServiceDefined: 'Kávézó / Étkezési szolgáltatás',
  teamStorageDefined: 'Csapat tárolóhelysége',
  coatClosetsDefined: 'Kabát tároló',
  genStorageDefined: 'Raktár',
  centralisedCopyPrintMailDefined: 'Központosított Nyomtatás, másolás és irattárolás',
  specialResourceAreaDefined: 'Különleges eszközök helysége',
  personalStorageDefined: 'Személyes tároló (szekrény)',
  storageITSpaceDefined: 'IT eszközök tárolója',
  secondaryServerRoomDefined: 'Másodlagos szerver / telekommunikációs szoba',
  mothersRoomDefined: 'Kismama szoba',
  buildITSpaceDefined: 'IT Épített terület',
  workerCouncilOffice2Defined: 'Munkaügyi tanácsadó iroda (2 fő)',
  securityCtrlRoomDefined: 'Biztonsági szolgálati helyiség',
  centralisedMailDefined: 'Központosított postaelosztó',
  visitorCoatLuggageRoomDefined: 'Csomagmegőrző',
  wellnessRoomDefined: 'Wellnes szoba',
  internalStairDefined: 'Belső lépcső',
  commercialPrintRoomDefined: 'Kereskedelmi nyomtatószoba',
  buildingSupportServicesAreaDefined: 'Épület-támogatási szolgáltatások',

  // Predefined Amenities (spaceUnitMap.js) - US_SMALL and US_REGULAR
  officeDefined: 'Iroda',
  lgOfficeDefined: 'Iroda (nagy méretű)',
  smWorkstationDefined: 'Munkaállomás (kicsi)',
  mdWorkstationDefined: 'Munkaállomás (normál)',
  lgWorkstationDefined: 'Munkaállomás (nagy)',
  huddleRoomDefined: 'Kis tárgyalószoba',
  dispersedCopyPrintAreaDefined: 'Nyomtatási és másolási elosztó helyiség',
  smMeetingRoomDefined: 'Kistárgyaló',
  mdMeetingRoomDefined: 'Normál tárgyaló',
  lgMeetingRoomDefined: 'Nagytárgyaló',
  xlMeetingRoomDefined: 'Extra Nagytárgyaló / Tanácsterem',
  multiPurpTrainingDefined: 'Több célú / Oktatás',
  informalCollabDefined: 'Informális kollaborációs terület',
  touchdownTeamDefined: 'Touchdown / Csapat terület',
  pantryVendingCoffeeAreaDefined: 'Kamra / Kávézó',
  workplaceStorageRoomDefined: 'Munkahelyi tároló',
  employeeCoatAreaDefined: 'Kabát tároló',
  dispersedMailAreaDefined: 'Posta elosztó',
  lockerUnitDefined: 'Szekrény',
  serverRoomDefined: 'Szerver szoba',
  frontDeskAreaDefined: 'Recepció',
  centralCafeAreaDefined: 'Közösségi terül',
  mailCenterAreaDefined: 'Irattároló terület',
  kitchenAreaDefined: 'Konyha',
  drawerLateralFileUnitDefined: '3 fiókos kis irattároló',
  serveryAreaDefined: 'Kiszolgáló terület',
  juiceBarCoffeeShopAreaDefined: 'Kávézó',
  diningSupportAreaDefined: 'Hinterland',
  diningAreaDefined: 'Étkező',
  grabNGoAreaDefined: 'Étel és ital automata',
  focusRoomSharedFocus: 'Fókusz tárgyaló',

  // Predefined Amenities (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDefined: 'Teljeskörű melegétel szolgáltatás',

  // Predefined Amenities (spaceUnitMap.js) - APAC_REGULAR
  officeWorkMeetDefined: 'Iroda (Munkatevékenység/Kollaboráció)',
  lgOfficeWorkMeetDefined: 'Nagy iroda (Munkatevékenység/Kollaboráció)',
  focusDefined: 'Fókusz',
  bench1pt6Defined: '1.6 m-es asztal',
  bench1pt8Defined: '1.8 m-es asztal',
  bench1pt8LDefined: '1.8 m-es  L-alakú munkaállomás',
  banquetWorkMeetDefined: 'Banket asztal - Munka/tárgyaló (2 fős)',
  cafeTable4Defined: 'Kávézó asztal (8-10 fő)',
  kitchenTable8to10Defined: 'Konyhai asztal (8-10 fő)',
  standingWhiteboardDefined: 'Álló tábla',
  huddleRoom3Defined: 'Kis tárgyalószoba',
  smMeetingRoom5Defined: 'kis tárgyaló (5 fő)',
  mdMeetingRoom8Defined: 'közepes tárgyaló (8 fő)',
  lgMeetingRoom20Defined: 'nagy tárgyaló (20 fő)',
  xlBoardroom: 'extra nagy tárgyaló / vezetői tárgyaló',
  wellnessRoomsDefined: 'Wellness szobák',
  employeeCoatsGymLockersDefined: 'Kabát tárolók / Öltöző szekrények',
  lockersDefined: 'Szekrények',
  dispersedCopyPrintScanAreaDefined: 'Másolás/ Nyomtatás / Scannelés elosztó helyiség',
  hydrationPointDefined: 'Hidratációs terület (Víz, tea, kávé)',
  centralHighDensityStorageDefined: 'Központi nagy sűrűségű tároló',
  wellnessMothersRoomDefined: 'Wellness / Kismama szoba',
  serverHubRoomsDefined: 'Szerver szobák',
  buildITStoreRoomDefined: 'IT tárhely terem',
  simpleReceptionWaitDefined: 'Recepció és várakozó',
  centralCafeSocialHubDefined: 'Közösségi központ',
  mailCentreDefined: 'Postaközpont',
  utilityStoreRoomDefined: 'Háztartási helyiség',
  drawerLateralFileDefined: '3 fiókos kis irattároló',
  juiceBarCoffeeShopDefined: 'Kávézó',
  buildingSupportServicesDefined: 'Épület-támogató szolgáltatások',

  // Space Unit Map Descriptions (spaceUnitMap.js) - Shared Descriptions
  focusRoomDesc: 'A Fókusz Szobák olyan elzárt helyiségek, melyek a fókuszált, és/vagy bizalmas munka elvégzésének támogatására hivatottak tipikusan egy, vagy két fő részére. Az akusztikus elszigetelés a legfőbb ezekben a szobákban. A Fókusz Szobák általában nincsenek megjelölve vagy elnevezve, és szükség szerint ideiglenesen foglalhatók a releváns feladatok elvégzésére.',
  fileRoomDesc: 'Ez egy dokumentum tároló egyének és csoportok számára. A legtöbb iroda digitalizációs törekvéseinek köszönhetően az egyéni és a csoportos dokumentum tárolóra egyaránt jelentősen csökken az igény. Mégis, ez a központi hely a jogi dokumentumok másolatainak, vagy a hosszútávú projektek anyagainak tárolására, különösen azért, mert a legtöbb nyitott irodatér asztalai kevés hellyel rendelkeznek. ',
  centralReceptionHubDesc: 'A recepciós terület a céged elsőszámú lehetősége, hogy benyomást adjon a dolgozóknak, ügyfeleknek, és a többi látogatónak. Folyamatosan a cég brandjét kell közvetítenie, és védeni a kívánt megjelenést. A recepciós pultnak komfortos helyet kell biztosítania a recepciós számára, és kényelmes ülőbutor kell hogy vártja a látogatókat.',
  officeDesc: 'Az irodák privát, elzárt terek a munkavállalók számára. Kiválóan csökkenti a zavaró tényezőket, így a fókuszáltan végezhető a munka. Az irodák általában asztallal és munkahelyi székkel felszereltek, továbbá vendég székekkel a látogatók számára. Az irattároló és személyes tárolóhelyek mérete azonban eltérő. A helyiség mérete, a munkavégzéstől, vagy a státusztól/hierarchiától függően változó lehet.',
  benchDeskDesc: 'A munkaasztalok egyénileg szabadon elfoglalható helyek a munkavégzéshez. A kisebb munkaasztalok a teljes munkaidőben dolgozók számára elfoglalhatók, ideiglenesen (szabad munkaállomás), vagy állandó használatra. Ezek az asztalok egyaránt kiválóak a látogató alkalmazottak, vagy a kivitelezők számára, akik nem állandó jelleggel tartózkodnak az irodában. A nyitott irodatér előnyei közé tartozik az iroda átláthatósága, és a vele járó gördülékeny együttműködés. Hátrány lehet a figyelmet megzavaró zaj, amiért ajánlott a nyitott irodaterek mellé több fókusz és privát tárgyaló kialakítása. Az asztalnál történő irattárolás korlátozott, a közös irattárolók a folyosón helyezkednek el.',
  meetingRoomDesc: 'A tárgyalók elzárt helyiségek, melyek kisebb csoportmegbeszélések (2-4 fő) támogatására hivatottak. A/V felszereléssel és fehér táblákkal kell rendelkezniük, hogy megkönnyítsék a tudásmegosztást, valamint akusztikusan elszigeteltnek kell lenniük (a tárgyalón kívül tartózkodó személyeknek nem szabad hallaniuk a bent elhangzottakat).',
  meetingRoomDesc6: 'A tárgyalók elzárt helyiségek, melyek kisebb csoportmegbeszélések (2-4 fő) támogatására hivatottak. A/V felszereléssel és fehér táblákkal kell rendelkezniük, hogy megkönnyítsék a tudásmegosztást, valamint akusztikusan elszigeteltnek kell lenniük (a tárgyalón kívül tartózkodó személyeknek nem szabad hallaniuk a bent elhangzottakat).',
  meetingRoomDesc8: 'A tárgyalók elzárt helyiségek, melyek kisebb csoportmegbeszélések (2-4 fő) támogatására hivatottak. A/V felszereléssel és fehér táblákkal kell rendelkezniük, hogy megkönnyítsék a tudásmegosztást, valamint akusztikusan elszigeteltnek kell lenniük (a tárgyalón kívül tartózkodó személyeknek nem szabad hallaniuk a bent elhangzottakat).',
  meetingRoomDesc12: 'A tárgyalók elzárt helyiségek, melyek kisebb csoportmegbeszélések (2-4 fő) támogatására hivatottak. A/V felszereléssel és fehér táblákkal kell rendelkezniük, hogy megkönnyítsék a tudásmegosztást, valamint akusztikusan elszigeteltnek kell lenniük (a tárgyalón kívül tartózkodó személyeknek nem szabad hallaniuk a bent elhangzottakat).',
  meetingRoomDesc15: 'A tárgyalók elzárt helyiségek, melyek kisebb csoportmegbeszélések (2-4 fő) támogatására hivatottak. A/V felszereléssel és fehér táblákkal kell rendelkezniük, hogy megkönnyítsék a tudásmegosztást, valamint akusztikusan elszigeteltnek kell lenniük (a tárgyalón kívül tartózkodó személyeknek nem szabad hallaniuk a bent elhangzottakat).',
  openCollabSpace4to6Desc: 'Ezek a területek a kisebb csoportok (4-6 fő) támogatására hivatottak, mint például csoportos ötletelés, és informális beszélgetés kollegák és ügyfelek között.',
  cafeFoodServiceDesc: 'A központi kávézó az étkezés, szociális interakciók, egyéni munka és kisebb tárgyalások lebonyolítására hivatottak. A helyiség funkcionális étkezőbútorait és a brandnek megfelelő színpalettát a helyisgég tervezésekor figyelembe kell venni.',
  coatClosetsDesc: 'Az alkalmazottaknak a kabátok és a nagyobb ruhadarabok tárolására szükségük van egy erre kijelölt helyre, főként a nyitott irodatér kialakításban, hiszen az asztalokon a tárolási lehetőségek limitáltak. Ezeknek szétszórtan kell elhelyezkedniük az iroda egész területén, a kijáratoknál és a fő folyosóknál. Általában van egy polc az akasztós résznél a kalapoknak és egyéb felszereléseknek, tárolókkal vagy bőröndökkel a szekrény belsejében.',
  copyPrintDesc: 'Az irodában szétszórtan másoló/nyomtató lehetőségek, továbbá minden irányból gyors elérés a multifunkciós eszközökhöz. A másoló/nyomtató környékén kukák az újrahasznosításhoz, valamint kis szekrények a plusz papír és egyéb eszközök tárolására. Ezeket a területeket kizárólag az eszközök használata erejéig lehet használni, nem a szociális/munkatér bővítését szolgálják.',
  specialResourceBuildingSupportAreaDesc: 'Ebbe beletartozik minden plusz terület, ami egy irodához tartozhat. Az egyik lehet a biztonsági asztal, vagy a felszerelések, de akár tároló tér is lehet. Ez szervezetektől függően változhat, de ahogy az iroda növekszik, ezek a kiegészítő helyiségek egyre gyakoribbá és fontosabbá válnak.',
  secondaryServerRoomDesc: 'Másodlagos szerver (technológia) szoba az IT felszerelés tárolására és védelmére, továbbá a gyengeáramú feszültség elhatárolása. A szobáknak 24 órás szellőzés mellett folyamatos 22 Celsius fokon, 50%-os relatív páratartalomban kell lenniük.',
  mothersRoomDesc: 'A kismama szobák a rendkívüli privátszféra támogatását szolgálják, akár szoptató anyáknak, akár csak az irodából rövid időre nyugalomra vágyóknak. A kismama szobák az új irodák többségében nagyságtól és létszámtól függetlenül feltételnek számítanak.',
  visitorCoatLuggageRoomDesc: 'A látogatóknak a kabátok és a nagyobb ruhadarabok tárolására szükségük van egy erre kijelölt helyre. Ezeknek szétszórtan kell elhelyezkedniük az iroda egész területén, a kijáratoknál és a fő folyosóknál. Általában van egy polc az akasztós résznél a kalapoknak és egyéb felszereléseknek, tárolókkal vagy bőröndökkel a szekrény belsejében.',

  wellnessRoomDesc: 'A wellness szobák (korábban kismama szobák) a rendkívüli privátszféra támogatását szolgálják, akár szoptató anyáknak, akár csak az irodából rövid időre nyugalomra vágyóknak. A wellness szobák az új irodák többségében nagyságtól és létszámtól függetlenül feltételnek számítanak, és tartalmazniuk kell egy pihenőfotelt, hűtőt és ideális esetben egy mosdókagylót.',

  internalStairDesc: 'A belső lépcsők a lépcsőház részeként aktív szerepet töltenek be a emeletek összeköttetésében, vagy kiegészítő elemként szolgálhatnak az iroda belső részében. Sokszor ez az irodában az összekötő hely, vagy a kávézó, recepció és a tárgyalók központi helyeként szolgál.',
  commercialPrintRoomDesc: 'Sokszor ez a postázóval egybekötött helyiség, a cégek biztosítják a vállalaton belüli nyomtatást, összefűzést és prezentáció összeállítást a nyomtató szobán belül. Sok nyomtató találhotó itt, és nagyobb formátumú nyomtatókat is tartalmazhatnak. Ezekben a helyiségekben tároló is található a felszereléseknek és pult a kisebb eszközöknek, továbbá hely a tervrajzok számára.',
  pantryVendingCoffeeDesc: 'A közpotni kávézóval ellentétben a kamra/automata/kávézó terület az alkalmazottaknak lett kialakítva, hogy gyorsan be tudják szerezni a szükséges ételt, vagy italt. A nyüzsgés és a beszélgetések természetesen az étel mellett történik, de ezeken a helyeken nincsenek kihelyzve székek, hogy minél gyorsabban végezhessenek, és ne legyen elnyújtott a társalgás.',
  storageDesc: 'A tároló szobák különféle dolgok, mint a nagyobb felszerelések, plusz bútor, nagyobb ruházat, nyaraláshoz szükséges holmik tárolását szolgálják. Ezek egyaránt vendég kabátok és bőröndök tárolására is alkalmasak. A kényelem kedvéért egy kisebb tárolónak a recepció mellett is kell lennie.',
  centralisedDispersedMailAreaDesc: 'Nagyobb cégek esetében szükséges postázó központ a kimenő és bejövő levelek rendszerezésére. Ezeken a helyeken különleges gyártási és levelezési felszerelések találhatók. Ezen felül nagyobb terület van az iratok és egyéb anyagok iktatására. Erre a feladatra általában egy személy van kijelölve teljes munkaidőben.',
  lockerDesc: 'A szekrények is pakolási lehetőséget biztosítanak az egyének számára. A dolgozók ide zárhatják be az értékeiket, a személyes tárgyaikat, és a szükséges fájlokat. A szekrények lehetnek személyenként kijelölve, vagy érkezési sorrendben.',
  mainITandServerRoomDesc: 'Elsődleges szerver (technológia) szoba az IT felszerelés tárolására és védelmére, továbbá a gyengeáramú feszültség elhatárolása. A szobáknak 24 órás szellőzés mellett folyamatos 22 Celsius fokon, 50%-os relatív páratartalomban kell lenniük.',
  drawerLateralFileDesc: 'Személyes tárolók az egyének számára az asztalokon, vagy az iroda egyéb területein. Ezek tartalma általában személyes, vagy egy futó projekthez kapcsolódó anyagok.',
  juiceBarCoffeeShopDesc: 'Egy cégnek lehet hogy nincs egy teljesen felszerelt konyhája, de rendelkezhet kávézóval, vagy juice bárral. Egy szervezetnek akkor is lehetnek ilyen alkalmatosságai, ha van fő étkező szolgáltatása, főként, ha az adott iroda nagyobb méretű. Ez a terület valószínűleg egy Starbucks-ra is hasonlíthat munkaállomással az ott dolgozók számára, elkülönítve a felszolgáló pult mellett.',
  grabNGoDesc: 'A kisebb pihenőtérben található több hűtő, polcok a chipsnek és rágcsálnivalóknak, és egy pénztár. Ezek az önkiszolgálók általában kamerával felszerelt helyiségek. Kiegészíthetők egy kisebb kamrával, mely felszerelt hűtővel, fagyasztóval, és étel előkészítésére alkalmas területtel.',
  smMeetingConferenceRoomDesc: 'A kisebb konferencia szoba egy elkülönülő tárgyalótér, melyben kényelmesen 2-6 ember is elférhet az asztalnál. Ezek a szobák egyaránt belsős, és ügyfélmegbeszélésekre is alkalmasak, ezért kifejezetten felhasználó barát kialakítással kell rendelkeznie az áram és A/V csatlakozók tekintetében.',
  mdMeetingConferenceRoomDesc: 'A közepes konferencia szoba egy elkülönülő tárgyalótér, melyben kényelmesen 6-10 ember is elférhet az asztalnál. Ezek a szobák egyaránt belsős, és ügyfélmegbeszélésekre is alkalmasak, ezért kifejezetten felhasználó barát kialakítással kell rendelkeznie az áram és A/V csatlakozók tekintetében.',
  lgMeetingConferenceRoomDesc: 'A nagy konferencia szoba egy elkülönülő tárgyalótér, melyben kényelmesen 12-14 ember is elférhet az asztalnál, igény szerint elnyúló ülőhelyekkel. Ezek a szobák egyaránt belsős, és ügyfélmegbeszélésekre is alkalmasak, továbbá prezentálásra. A bútoroknak mozgathatónak kell lenniük, hogy a helyiséget más célokra is használni lehessen, mint például jóga órák a munkavállalók számára.',
  xlMeetingRoomDesc: 'A tanácsteremben 14-18 fő is elférhet az asztalnál, legalább 10 túlnyúló ülőhellyel. Ezek a termek leginkább nagy méretű formális belsős értekezletekre, és ügyfélértekezletekre használhatók. Felszereltségüket tekintve terjedelmes A/V technológiával és ügyfélbarát megjelenéssel kell rendelkezniük.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - US_SMALL and US_REGULAR
  lgOfficeDesc: 'Az irodák privát, elzárt terek a munkavállalók számára. Kiválóan csökkenti a zavaró tényezőket, így a fókuszáltan végezhető a munka. Az irodák általában felszereltek asztallal és munkahelyi székkel, továbbá vendég székekkel a látogatók számára. Az irattároló és személyes tárolóhelyek mérete azonban eltérő. A helyiség mérete, a munkavégzéstől (például rendszeres értekezletek), vagy a státusztól/hierarchiától függően változó lehet. A kissé nagyobb iroda partnerként, vagy privát tárgyalóként működhet.',
  smWorkstationDesc: 'A munkaállomások nyitott, egyéneknek kialakított munkaterek. A kisebb munkaállomások a teljes munkaidőben dolgozók számára elfoglalhatók, ideiglenesen (szabad munkaállomás), vagy állandó használatra. Ezek az asztalok egyaránt kiválóak a látogató alkalmazottak, vagy a kivitelezők számára, akik nem állandó jelleggel tartózkodnak az irodában. A nyitott irodatér előnyei közé tartozik az iroda átláthatósága, és a vele járó gördülékeny együttműködés. Hátrány lehet a figyelmet megzavaró zaj, amiért ajánlott a nyitott irodaterek mellé több fókusz és privát tárgyaló kialakítása. Az asztalnál történő iktatás korlátozott, tekintve, hogy a közösségi iktatás a folyosón található.',
  mdWorkstationDesc: 'A munkaállomások nyitott, egyéneknek kialakított munkaterek. Az előnyei ezeknek a kissé nagyobb tereknek, a nagyobb asztali férőhely, és a magánszféra. Ez nagy valószínűséggel azt jelenti, hogy ezeket a tereket előnyben részesítik a többi, szabadon elfoglalható állomáshoz képest. A nyitott irodatér előnyei közé tartozik az iroda átláthatósága, és a vele járó gördülékeny együttműködés. Hátrány lehet a figyelmet megzavaró zaj, amiért ajánlott a nyitott irodaterek mellé több fókusz és privát tárgyaló kialakítása. Nagy eséllyel találsz kiegészítő közösségi tárolást, és iktatást a folyosón.',
  lgWorkstationDesc: 'A munkaállomások nyitott, egyéneknek kialakított munkaterek. Az előnyei ezeknek a kissé nagyobb tereknek, a nagyobb asztali férőhely, és a magánszféra. Az ilyen méretű asztalokat nagyobb eséllyel foglalják el, mint a többi munkaállomást. A nyitott irodatér előnyei közé tartozik az iroda átláthatósága, és a vele járó gördülékeny együttműködés. Hátrány lehet a figyelmet megzavaró zaj, amiért ajánlott a nyitott irodaterek mellé több fókusz és privát tárgyaló kialakítása. Nagy eséllyel találsz kiegészítő közösségi tárolást, és iktatást a folyosón.',
  huddleRoomDesc: 'A privát tárgyalók körülzárt terek, melyek 2-4 fős csoportos tárgyalások támogatására lettek tervezve. A/V felszereléssel és fehér táblákkal kell rendelkezniük, hogy megkönnyítsék a tudásmegosztást, valamint akusztikusan privátnak kell lenniük (a tárgyalón kívül tartózkodó személyeknek nem szabad hallaniuk a bent elhangzottakat).',
  multiPurpTrainingDesc: 'A többfunkciós szobák 20, vagy annál több fő befogadására lettek tervezve. A nevéből adódik, hogy több különböző tevékenység, mint továbbképzések, céges események, illetve nagyobb prezentációk lebonyolítására lett kialakítva. A terem kialakítása funkcionális számos vendég befogadására, és ügyfélmegbeszélésekre is alkalmasak. A teret megfelelő A/V felszereltséggel kell ellátni - nagyobb számban, mint egy átlagos tárgyalótermet.',
  informalCollabDesc: 'Ezek a helyek a kisebb csoportok (4-6 fő) kollaborációs tevékenységeit látja el, mint a közös ötletelés és informális beszélgetések a kollegák és az ügyfél között.',
  touchdownTeamDesc: 'A "touchdown" területek az egyének számára, vagy kisebb belsős csapatmunkák elvégzésére alkalmas helyek, melyek szétszórtan találhatóak az irodatérben, az alkalmazottak és vendégek számára. Számos méretben és formában találkozhatunk velük, és a hagyományos munkaállomásoktól eltérő, egyedi helyet biztosítanak rövidebb időre a munka elvégzésére. Ezeknek használatra kész területeknek kell lenniük.',
  frontDeskAreaDesc: 'Ez az első állomás az alkalmazottak és a vendégek irodába érkezését követően. Az asztalnak egy főre elég munkaállomás, és tároló lehetőséget kell biztosítania. A vendégeknek valószínűleg a közelben lesz tárolási lehetőségük. Az elülső asztal a nagyobb recepciós térben helyezkedik el, ahol valószínűleg egyéb ülőhelyek is találhatóak.',
  mailCenterAreaDesc: 'Nagyobb cégek esetében szükséges postázó központ a levél szolgáltatások összesítésére. Ide tartozik a nagyobb szortírozás, és rendszerezés, valamint a fehér táblák (flipchart) és az eszközök tárolása. Ez a helyiség szervezetenként jelentősen eltérő lehet, mivel más helyiség típusok között helyezkedik el.',
  kitchenAreaDesc: 'Azok a helyek, melyek rendelkeznek irodán belüli étkezési szolgáltatással, kötelező a konyhai támogatás megléte. A konyhára irányuló előírások szervezetenként eltérőek, de biztosan található bennük főzési lehetőség, valamint hűtő. Ezen felül száraz étel- és eszköztároló. A konyha szomszédos az kiszolgáló hellyel, és valószínű, hogy a a konferencia/ügyfél centerrel.',
  serveryAreaDesc: 'Az kiszolgáló helynél vehetik át az emberek az ételeiket. Ez kapcsolódik a konyhához, valamint az étkező részhez, ahol székek és asztalok találhatók. A kiszolgáló kialakításakor precízen meg kell tervezni, hogy a sort és torlódást hogyan kezelhetjük. A helyiség mérete és kialakítása szervezetenként változó lehet.',
  diningSupportAreaDesc: 'A hatalmas étkezővel rendelkező szervezeteknek nagy valószínűséggel vannak további támogató terei az épületben, mint a tárolók, melyeket  az átfogó programban fel kell tüntetni.A terület paraméterei szervezetenként változó lehet.',
  diningAreaDesc: 'Az étkezőnek a konyhához közel, széles választékkal kell rendelkeznie, és biztosítani kell ülőhelyet az étkezésre. A számos, és különböző ülőhely biztosítása az étkezésen felül lehetővé teszi a tárgyalásokat és kollaborációkat. A bár mellett a puha és kemény anyagból készült székek, valamint az ülések állíthatóságának biztosítása lehetőségeket kínál a használója számára.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDesc: 'Amikor biztosított az irodán belüli étkezési szolgáltatás, több funkció összessége támogatja a kényelmet, mint a konyha, kiszolgáló, étkező, és étkezést kisegítő helyiség (tároló). A konyha és a kiszolgálás együttműködik az ételek elkészítésében, valamint a helyben készült ételek felszolgálásában.  Az étkező az a helyiség, ahol a munkavállalók és a vendégek ülhetnek és étkezhetnek, de legtöbbször ez a helyiség egy szociális és kollaborációs csomópontként szolgál. Csakúgy mint otthon, itt is több eszköz, és étel raktározási hely szükséges az étkezési szolgáltatások biztosításához, ezért a teljeskörű étkezési szolgáltatás részét képezi.',

  // SpaceUnitRow
  spaceUnitRowDeleteTitle: 'Irodatér típus törlése',
  spaceUnitRowDeleteText: 'Biztos vagy benne, hogy kitörlöd ezt az irodatér típust?',
  spaceUnitRowConfirmDeleteText: 'Igen, törlöm',
  spaceUnitRowCancelDeleteText: 'Mégsem, inkább megtartom',

  // CustomAmenityAdder
  customAmenityAddText: 'Szolgáltatás hozzáadása',
  customAmenityPlaceholder: 'Szolgáltatás típusa',

  // SpaceUnitTotals
  spaceUnitTotalsTitleNIA: 'Teljes nettó belső terület',
  spaceUnitTotalsTitle: 'Teljes {{sfTypeAdj}} négyzet {{unitCapitalized}}',
  spaceUnitTotalsCirculation: 'Forgási sebesség',
  spaceUnitTotalsCirculationTooltip: 'A "tér a terek között". Folyosók, a széked mögött hely, a szekrények és ajtók körüli hely and a fordulók. Ezek nélkül a helyek nélkül lehetetlen a közlekedés. Általában 35%-át teszi ki a teljes Felhasználnató Négyzet {{unitCapitalized}, de változhat a profilod, illetve a választott épület alaprajza alapján is.',
  spaceUnitTotalsREBNY: 'REBNY Factor',
  spaceUnitTotalsNYCText: 'NYC ',
  spaceUnitTotalsREBNYTooltip: 'For programs in NYC, we increase {{sfType}} by 15% to account for the REBNY measurement standard. Space measured per the REBNY measurement standard includes bathrooms, elevator lobbies, and the thickness of exterior walls (among other things).',
  spaceUnitTotalsLossFactor: 'Veszteségi tényező ',
  spaceUnitTotalsLossFactorTooltip: 'A felhasználható négyzet {{unit}} az amennyi helyed lesz az irodában. A bérelt négyzet {{unit}} pedig az amennyi után fizetni fogsz, ez tartalmazhat oszlopokat, előtereket és egyéb közös területeket. A felhasználható és a bérelt irodatér közötti különbséget veszteségi tényezőnek hívják és a számítása helyszínenként eltér.',

  // CollaborationDropdown
  collabDropdownLevelLowText: '0-25%',
  collabDropdownLevelMedText: '26-50%',
  collabDropdownLevelHighText: '51-100%',

  //DensityDropdown
  sameDensityText: 'Hasonló telítettség mint a Covid-19 előtt',
  lowerDensityText: 'Alacsonyabb telítettség',
  notSureDensityText: 'Nem vagyok benne biztos',

  //CirculationDropdown
  preCovidText: 'Covid-19 előtt (45% nyitott / 25% zárt)',
  higherText: 'Gyakran cserélődő (50% nyitott / 30% zárt)',
  notSureText: 'Nem vagyok benne biztos (45% nyitott / 25% zárt)',

  // ExamplePlans
  examplePlanButtonText: 'Mintaterv megtekintése',
  examplePlanFullFloorText: 'teljes emelet',
  examplePlanPartialFloorText: 'részleges emelet',
  examplePlanFullImgAlt: '{{size}} négyzet {{unit}} alaprajz, amely {{profileName}} profilú elrendezést mutat a Spacer-től',
  examplePlanPartialImgAlt: '{{size}} négyzet {{unit}} részleges alaprajz mutat egy {{profileName}} elrendezést a Spacer-től',
  examplePlanTooltipText: 'Amennyiben az irodatér igényed nagyobb, mint  {{size}} {{unit}}, valószínűleg több szintre lesz szükséged.',

  // ProgramStats
  programStatsDensitySubtitleTsubo: 'beköltözésig hátralévő idő',
  programStatsDensitySubtitle: '{{adjective}} Négyzet {{unitUppercase}}',
  programStatsIndivTitle: 'Egyéni ülőhelyek',
  programStatsWorkstationsSubtitle: 'Munkaállomások',
  programStatsOfficesSubtitle: 'Irodák',
  programStatsCollabTitle: 'Kollaboráció',
  programStatsEnclosedSubtitle: 'Zárt, csoportos ülőhelyek',
  programStatsIndivSeatSubtitle: 'Egyéni ülőhely',
  programStatsSeatsTitle: 'Személyenkénti ülőhelyek',
  programStatsIndivSeatsSubtitle: 'Egyéni ülőhelyek',
  programStatsPersonSubtitle: 'Személyenkénti ülőhelyek',
  programStatsDensityTitle: 'Telítettség',
  programStatsFullDensitySubtitle: '{{density}} egyéni ülőhelyenként',

  // Profile Display Names (profileNames.js)
  efficientName: 'Az Optimalizáló',
  whiteCollarName: 'Az Alkalmazkodó',
  whiteGloveName: 'A Teljesítményfokozó',
  openOfficeName: 'A Produktív',
  workplace360Name: 'A Kapcsolatteremtő',
  campusName: 'A Megkülönböztető',
  startupName: 'Az Inkubátor',
  engCreativeName: 'A Kreatív',

  // Profile Descriptions (profileDescriptions.js)
  efficientDesc: 'Az Optimalizáló iroda a céltudatos munkakörnyezetet támogatja. Biztosítja az összes szükséges eszközt az alkalmazottak íróasztalánál mivel ebben a környezetben kevésbé fordul elő a csapat-orientált munka. Az íróasztalok szabványosítottak és a hatékony munkához az alapvető tárgyalók és terek adottak. Nevéhez híven ezek a irodaterek szinte mindig nyitottak a hatékonyság maximalizálása érdekében.',
  whiteCollarDesc: 'Az Alkalmazkodó irodában soha sem fogod egyedül érezni magad - ez az egyik legnépszerűbb irodatér profil a nagyvállalatok körében. Az Alkalmazkodó irodák mellőzik a radikális dizájnt de a produktivitás és elkötelezettség javítás érdekében gyakran invesztálnak az elérhető technológiába és bútorokba. A kialakítást tekintve, egyensúlyt biztosít az egyéni és a közös munka környezet között, az alkalmazottak legtöbbször az íróasztaluknál töltik az idejüket amit néhány meeting szakít meg. Tökéletes választás a 9-től délután 5-ig végzendő munkakörnyezethez.',
  whiteGloveDesc: 'Amikor belépsz az irodába, a Teljesítményfokozó irodatér egyből ámulatba ejt. Ez a munkahely kielégíti azoknak az alkalmazottaknak, valamint az ügyfeleknek igényeit, akik gyakran látogatják a munkahelyet. Minden más típusú irodatérnél több zárt teret és egyéb helységet tartalmaz. A legtöbb alkalmazott, teljesítmény vagy megbízatás révén szerzi meg a területét és így a hierarchia valószínűleg fontos szerepet játszik a szervezet működésében. Mászd meg stílusosan a vállalati létrát!',
  openOfficeDesc: 'A Produktív iroda tudja, hogy mire van szükséged a sikerhez. Biztosítja az emberek számára a munkatevékenységüktől függő alapvető szükségleteket. A különféle, diverz tereket pedig tapasztalataink alapján ki is használják a munkavállalók annak érdekében, hogy hatékonyak maradjanak. Egy egészséges egyensúlyt biztosít az egyéni és a csapat fókuszú feladatok elvégzéséhez, így tökéletes azok számára akik sokat forognak az irodában egy nap.',
  workplace360Desc: 'Csak mint a kedvenc jóga pózod, A Kapcsolatteremtő irodatér az egyensúlyra összpontosít. A produktivít orientált térre és kapcsolatépítésre fókuszálva egy egészséget mixet biztosít. Minden adott a hatékony munkavégzéshez miközben az alkalmazottak különböző terek közül választhatnak a mindennapok során. Nevéhez híven, A Kapcsolatépítő iroda központi helyeket azonosít a közösségi terekben, mint a konyhában és nyitott tárgyalókban. Így segít összehozni az embereket és megteremti a közösség érzetét.',
  campusDesc: 'A Megkülönböztető irodát nehéz otthagyni a nap végén. Miért? Azért mert olyan élményeket nyújt munka közben amivel nem könnyű felvenni a versenyt. Az ételeken és a wellnesen kívül továbbfejlesztett kényelmi szolgáltatásokat kínál amelyek segítenek abban, hogy az alkalmazottak jól érezzék magukat miközben időt is spórolhatnak.',
  startupDesc: 'A gyorsaság és az ötletek bölcsője az Inkubátor irodatér ahol a munkavállalók szorosan együttműködő csapatokban dolgoznak. Ez az irodai körnezet folyamatosan változik, ebből adódóan legtöbb tere rugalmasan változtatható - gyakran előfordul a bútorok átmozgatása. Egészséges egyensúlyt biztosít a szórakoztató közösségi tevékenységek és az intenzívebb munkavégzés között. Például a hackathon a ping-pongal felváltva alkalmazható, hogy növeljük és építsük a csapat bajtársiasság érzetét.',
  engCreativeDesc: 'A Kreatív irodateret a specialistákra szabták. Ebben a környezetben gyakori a közös munka és az együttműködés a kreatív energiák megfelelő kiaknázásához. A speciális felszerelések vagy fizikai eszközök a mindennapi munka oszlopos részét képezik. Az alkalmazottak a hatékony munkavégzéshez megkapják a szükséges teret, és bár nem gyakran fordul elő ez az irodatér típus, az itt elvégzendő speciális feladatok különleges munkahelyért kiáltanak.',


  // Profile Key Traits: Shared Names (profileTraitMap.js)
  social: 'Szociális',
  efficient: 'Hatékony',
  flexible: 'Rugalmas',
  enabled: 'Felszerelt',
  productive: 'Produktív',
  inclusive: 'Inkluzív',
  clientFocused: 'Ügyfélközpontú',
  standardized: 'Standardizált',

  // Profile Key Traits (profileTraitMap.js)
  social1Desc: 'Fontosak a kapcsolatok az irodán belül és kívül is',
  social2Desc: 'Sok közösségépítő rendezvény',
  social3Desc: 'Az alkalmazottak szeretnek időt együtt tölteni',
  social4Desc: 'Nyitotabb és közösségbarátabb',
  efficient1Desc: 'A vállalat költségtudatosabb marad',
  efficient2Desc: 'A vállalat hatékony marad',
  flexible1Desc: 'Az emberek választhatnak, hogy honnan dolgoznak',
  flexible2Desc: 'Az alkalmazottak számára biztosított a rugalmas munkavégzés',
  flexible3Desc: 'Tervezd meg a saját irodatered',
  enabled1Desc: 'Az alkalmazottak az íróasztaluknál megtalálják azt amire szükségük van a munkájuk során',
  enabled2Desc: 'Az alkalmazottak számos szolgáltatás közül választhatnak',
  enabled3Desc: 'Az emberek megkaphatják a munkájuk megfelelő elvégzéséhez szükséges eszközöket',
  enabled4Desc: 'Hozzáférhet a megfelelő technikához és felszereléshez',
  enabled5Desc: 'Speciális felszerelés rendelkezésre áll',
  productive1Desc: 'Funkcionális és intuitív irodai tervezés',
  productive2Desc: 'Üzletközpontú és pénzügyileg tudatos',
  productive3Desc: 'Öszpontosítva az egyedi termék/szolgáltatás kínálatára',
  inclusive1Desc: 'Többféle típusú irodatér áll rendelkezésre',
  inclusive2Desc: 'Megtalálsz mindent az irodában amire szükséged lehet',
  inclusive3Desc: 'Sokféle munkavégzés elfogadott',
  clientFocused1Desc: 'Az ügyfelek első benyomása a legfontosabb dolog',
  clientFocused2Desc: 'Az iroda hivogató az ügyfelek és új tehetségek számára ',
  clientFocused3Desc: ' Az iroda elegáns benyomást kelt az ügyfelek és új tehetségek számára ',
  standardized1Desc: 'A folyamatok és rendszerek összhangban vannak',
  standardized2Desc: 'A szervezeti normák a kialakított irodatérre épülnek',
  standardized3Desc: 'Modulális méretek a rugalmassághoz',
  standardized4Desc: 'Összehangolt folyamatok és teljesítések',

  // SpaceSummary
  spaceSummaryBarHeader: 'Ajánlott irodai méret',
  spaceSummaryMeasurementTypeText: 'mérés típusa',
  spaceSummaryUnitTypeText: 'egységek',
  spaceSummaryLossFactorSectionLabel: '{{factor}} részletek',
  spaceSummaryFitFactorText: 'egyezési tényező',
  spaceSummaryLossFactorText: 'veszteségi tényező',
  spaceSummaryRSFLossFactorHelperText: 'A veszteségi faktor {{percentage}}% és az ingatlan elhelyezkedése {{place}} alapján, olyan irodát kellene keresned amely {{min}} és {{max}} bérelhető négyzet {{units}} között van.',
  spaceSummaryNIAFitFactorHelperText: 'A egyezési faktor {{percentage}}% és az ingatlan elhelyezkedése {{place}} alapján, {{amt}} NIA méretű irodát kellene keresned.',
  spaceSummaryNIATooltipText: 'A Nettó Belső Terület az épület azon területe amit a belső falak.',

  // SFTypeRadioInput
  sfRadioInputUsableAreaTooltipText: 'Ez a mérés magába foglalja az összes tárgyalót, bútorokat, bejárható területet. Egyes esetekben kárpitozott területnek is nevezik.',
  sfRadioInputRentableAreaTooltipText: 'Általában ez a Felhasználható négyzet {{unitUppercase}}, plusz az épület közös tereinek egy része. Habár nem lehetséges a tényleges teljes terület kihasználása, ez az a négyzet {{unit}} amiért bérléskor fizetsz.',
  sfRadioInputUsableSquareText: 'Felhasználható négyzet {{unitUppercase}}',
  sfRadioInputRentableSquareText: 'Bérelhető négyzet {{unitUppercase}}',

  //floored plan request block
  flooredTitle: 'Még ne hagyj itt minket!',
  flooredSubTitle: 'Fogalmazd át, hogy az ügyfelek hogyan fedezik fel a belső irodai teret',
  flooredSubText: 'Fedezd fel a Spacer eredményeidet egy 3D-s alaprajz bebarangolásán keresztül',
  flooredParagraphText1: 'A élethű élmény elérhetővé teszi a bérlők számára, hogy vizuálisan megjelenítsék ötleteiket, kísérletezzenek a különböző tervekkel és megvalósítsák a koncepcióikat.',
  flooredParagraphText2: 'Kézzel fogható, személyre szabott koncepció minden irodatér típusra',
  flooredVideoHeading: 'Tekints meg egy mintát',
  flooredFreeText: 'Mi több, ingyenes!',
  flooredIconText1: 'Elérhető alaprajzok megosztása',
  flooredIconSubText1: 'AutoCAD fájlok PDF-ekhez',
  flooredIconText2: 'Feldolgozási idő engedélyezése',
  flooredIconSubText2: '1-2 hét',
  flooredIconText3: 'Kapj kézhez egy másokkal is megosztható élményt',
  flooredIconSubText3: 'Egyedi 2D és 3D koncepciók',


  // MeasurementSystemRadioInput
  measurementSystemSqFeetText: 'négyzetláb',
  measurementSystemSqMetersText: 'négyzetméter',
  measurementSystemTsuboText: 'beköltözésig hátralévő idő',

  // ProfileHero
  resultsProfileHeroAnnouncementTitle: 'Az irodád',
  resultsProfileHeroImgAlt: '3D alaprajz példa amely {{profileName}} profilú irodateret mutat a Spacer a CBRE-tól',

  // ProfileComparison
  profileCompModalTitle: 'Módosítod a profilod?',
  profileCompModalText: 'A mentés nélküli módosítások elvesznek a jelenlegi tervezésben',
  profileCompModalConfirmText: 'Igen, profil módosítása',
  profileCompModalCancelText: 'Nem, profil megtartása',
  profileCompNavbarCancelButtonText: 'Mégsem',
  profileCompNavbarUseButtonText: 'Használd ezt a profilt',
  profileCompCompareTitle: 'Hasonlítsd össze a legaktuálisabb profilegyezéseket',

  // ProfileComparisonColumn
  profileCompColumnSelectedText: 'Kiválasztott profil',
  profileCompColumnSelectText: 'Kiválasztás',
  profileCompColumnRecommendedText: ' (Javasolt)',
  profileCompColumnImgAltText: '3D floor plan showing the office layout of the {{profileName}} profile from Spacer by CBRE',

  // Test Fit Form
  testFitTitle: 'Nézd meg a szinti\nterv eredményedet és járd be 3D-ben',
  testFitSubtitle: 'Kíváncsi vagy arra, hogyan néz majd ki az irodatered? Az itt kapott eredményeidet testreszabott alaprajzzá változtatjuk amit felfedezhetsz és megoszthatsz.',
  requestTestFitButtonText: 'Kérj egyedi alaprajzot',
  company: 'Cégnév',
  emptyCompanyError: 'Kérlek add meg a cég nevet',
  radioQ2yes: 'Kiváló! E-mailen kapcsolatba lépünk veled és bekérjük a fájlt.',
  radioQ2no: 'Nem probléma, hamarosan jelentkezünk, hogy átbeszéljük a lehetőségeket.',
  testFitFormTitle: 'Kérj egyedi alaprajzot',
  testFitFormSubtitle: 'Ossz meg velünk néhány információt és már kezdhetjük is. A csapatunk általában 3 munkanap alatt készít el a végleges ültetési terv.',
  testFitFormCompleted: 'A kérésed sikeresen megérkezett hozzánk. Kollgégánk hamarosan emailben felveszi veled a kapcsolatot.',
  testFitFormYes: 'igen',
  testFitFormNo: 'nem',
  testFitFormQ1: 'Van alaprajzod amivel dolgozhatunk?',
  testFitFormQ1No: 'Nem probléma! Biztosítunk neked egyet amit használhatsz.',
  testFitFormQ2: 'Van szerkeszthető alaprajzod (PDF, AutoCAD)?',
  testFitTermsConditions: 'Ennek a kis doboznak a kipipálásával a beta programunkban való részvételre egyezel bele. Egy ingyenes ültetési terv a jutalmad azért, hogy válaszolsz néhány kérdésre a tapasztalataidról.',
  testFitSubmitButtonText: 'Alaprajz kérelem benyújtása',
  backendError: 'Hoppá! Valami félre ment a folyamat során - kérlek próbáld meg újra.'
};
