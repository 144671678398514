module.exports = {

  // LandingPageHero
  landingPageHeroTitle: 'Pomożemy Ci zaplanować miejsce pracy przyszłości i znaleźć odpowiednią powierzchnię na Twoje nowe biuro.',
  landingPageHeroSubtitle: 'Niedawna pandemia sprawiła, że miejsca pracy zmieniają się – skorzystaj z naszego nieodpłatnego narzędzia i wygeneruj zalecenia dopasowane do Twoich potrzeb w zakresie planowania przestrzeni biurowej.',
  landingPageHeroImgAlt: 'Podgląd wyników kwestionariusza dotyczącego projektu przestrzeni znajdującego się na stronie głównej aplikacji CBRE Spacer',
  landingPageImage: '/png/homepage-results-page-in-browser-spacer-by-cbre.png',
  // LandingPageContent
  landingPageContentTitle: 'Jak to działa',

  // StepsSummary
  stepsSummarySubtitle1: 'Krok 1 —',
  stepsSummaryTitle1: 'Opowiedz nam o swojej organizacji',
  stepsSummaryText1: 'Pomóż nam poznać cele i zadania Twojej organizacji, tak abyśmy mogli przedstawić Ci dostosowane do Twoich potrzeb zalecenia.',
  stepsSummaryImgAlt1: 'Grafika przedstawiająca ludzi stojących na ogromnym laptopie znajdująca się na stronie głównej aplikacji CBRE Spacer',

  stepsSummarySubtitle2: 'Krok 2 —',
  stepsSummaryTitle2: 'Przejdź do wyników',
  stepsSummaryText2: 'Na podstawie udzielonych odpowiedzi otrzymasz zalecenia co do wielkości potrzebnego Ci biura wraz z liczbowym wyszczególnieniem poszczególnych rodzajów powierzchni, które powinny znaleźć się w Twojej przestrzeni biurowej. Będziesz mieć również możliwość przyjrzenia się przykładowym rzutom Twojego biura.',
  stepsSummaryImgAlt2: 'Grafika przedstawiająca miejską dzielnicę znajdująca się na stronie głównej aplikacji CBRE Spacer',

  stepsSummarySubtitle3: 'Krok 3 —',
  stepsSummaryTitle3: 'Znajdź swoje idealne biuro',
  stepsSummaryText3: 'Zapisz swoje wyniki, tak aby móc wrócić do nich podczas poszukiwań biura, lub skontaktuj się z zespołem Spacer i porozmawiaj z jednym z naszych ekspertów ds. najmu, który wesprze Cię w procesie poszukiwania Twojego idealnego biura.',
  stepsSummaryImgAlt3: 'Grafika przedstawiająca kobietę pracującą przy biurku znajdująca się na stronie głównej aplikacji CBRE Spacer',

  // LandingPageSecondaryHero
  landingPageSecondaryHeroImgAlt: 'Grafika przedstawiająca ludzi tworzących zalecenia co do przestrzeni biurowej znajdująca się na stronie głównej aplikacji CBRE Spacer',
  landingPageSecondaryHeroTitle: 'Opowiedz nam trochę o swojej organizacji. A my powiemy Ci, jak dużej powierzchni biurowej potrzebujesz.'

};
