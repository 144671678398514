const React                 = require('react');
const HeroSection           = require('../common/HeroSection.jsx');
const RetrieveLinkForm      = require('./RetrieveLinkForm.jsx');
const GetReferralLink       = require('./GetReferralLink.jsx');
const constants             = require('wp-constants').spacerFree;
const BrokerReferralWrapper = require('./BrokerReferralWrapper.jsx');

function BrokerRetrievePage() {
  const [link, setLink] = React.useState(false);

  let currentView;
  if (link) {
    currentView = (<GetReferralLink link={link} />);
  } else {
    currentView = (<RetrieveLinkForm setLink={setLink} />);
  }

  return (
    <BrokerReferralWrapper siteLocationConstant={constants.siteLocation.PAGE.REFERRAL_RETRIEVE}>
      <div className="referral-sign-up">
        <HeroSection isLightColor>
          <div className="flex flex-justify-center">
            <div className="referral-link-box">
              {currentView}
            </div>
          </div>
        </HeroSection>
      </div>
    </BrokerReferralWrapper>
  );
}

module.exports = BrokerRetrievePage;
