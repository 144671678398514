const { connect } = require('react-redux');
const userUtil    = require('../../../util/user');


const LegalForm    = require('../../form/LegalForm.jsx');

// Map state to props
function mapStateToProps(state) {
  return {
    isCBREEmployee: userUtil.isCBREEmployee(state.userData.role)
  };
}

module.exports = connect(mapStateToProps)(LegalForm);
