const React               = require('react');
const PropTypes           = require('prop-types');
const spacerFreePropTypes = require('../../spacerFreePropTypes');


class SpectrumAnswer extends React.Component {
  constructor(props) {
    super(props);

    this.onAnswerChange = this.onAnswerChange.bind(this);
  }

  onAnswerChange(optionId) {
    return () => {
      this.props.onAnswerChange(optionId);
    };
  }

  render() {
    let inputId;
    let classNames;
    let selected;
    const optionIds = Object.keys(this.props.questionUnit.options);

    const spectrumOptions = optionIds.map((optionId) => {
      inputId = `question-option-${optionId}`;
      selected = this.props.questionAnswer === optionId;
      classNames = 'radio-spectrum';
      if (selected) classNames += ' is-active';

      return (
        <label
          className={classNames}
          htmlFor={inputId}
          key={optionId}
        >
          <input
            type="radio"
            name={`question-${this.props.questionUnit.id}`}
            id={inputId}
            onChange={this.onAnswerChange(optionId)}
            value={optionId}
            checked={selected}
          />
          {this.props.questionUnit.options[optionId].text}
        </label>
      );
    });

    return (
      <div className="radio-spectrum-container">
        {spectrumOptions}
      </div>
    );
  }
}

SpectrumAnswer.propTypes = {
  questionUnit: spacerFreePropTypes.questionUnitShape.isRequired,
  questionAnswer: PropTypes.string.isRequired,
  onAnswerChange: PropTypes.func.isRequired
};

module.exports = SpectrumAnswer;

