const React                        = require('react');
const PropTypes                    = require('prop-types');
const spacerFreePropTypes          = require('../spacerFreePropTypes');

const _                            = require('lodash');
const constants                    = require('wp-constants').spacerFree;
const util                         = require('wp-util');

const QuestionText                 = require('./QuestionText.jsx');
const QuestionAnswer               = require('./QuestionAnswer.jsx');
const SentenceCompletionAnswer     = require('./answerFormats/SentenceCompletionAnswer.jsx');
const RankSentenceCompletionAnswer = require('./answerFormats/RankSentenceCompletionAnswer.jsx');
const SpectrumAnswer               = require('./answerFormats/SpectrumAnswer.jsx');
const QuestionResponse             = require('./QuestionResponse.jsx');
const CheckboxInput                = require('../form/CheckboxInput.jsx');
const { withTranslation }           = require('react-i18next');

class Question extends React.Component {
  constructor(props) {
    super(props);

    this.onAnswerChange = this.onAnswerChange.bind(this);
  }

  onAnswerChange(questionAnswer) {
    this.props.onAnswerChange(questionAnswer);
  }

  render() {
    const tokenizedQuestionUnit = util.questionUnit.tokenizeQuestionUnit(this.props.questionUnit, this.props.t);

    const questionAnswerComponentProps = {
      questionUnit: tokenizedQuestionUnit,
      questionAnswer: this.props.questionAnswer,
      onAnswerChange: this.onAnswerChange,
      isInactive: this.props.isInactive
    };

    let classes = 'quiz-question';
    if (this.props.isInactive) classes += ' inactive-quiz-question';

    let questionAnswerComponent = null;

    switch (this.props.questionUnit.answerFormat) {
      case constants.questionUnit.ANSWER_FORMAT.SENTENCE_COMPLETION: {
        if (util.questionUnit.isRankQuestion(this.props.questionUnit)) {
          questionAnswerComponent = <RankSentenceCompletionAnswer {...questionAnswerComponentProps} />;
        } else {
          classes += ' sentence-completion-question';
          questionAnswerComponent = <SentenceCompletionAnswer {...questionAnswerComponentProps} />;
        }
        break;
      }
      case constants.questionUnit.ANSWER_FORMAT.SPECTRUM: {
        classes += ' spectrum-question';
        questionAnswerComponent = <SpectrumAnswer {...questionAnswerComponentProps} />;
        break;
      }
      default: {
        break;
      }
    }

    const hasQuestionResponse = util.questionUnit.hasQuestionResponse(this.props.questionUnit);
    return (
      <div className={classes}>

        {this.props.questionUnit.text &&
          <QuestionText text={this.props.t(this.props.questionUnit.text)} />
        }

        <QuestionAnswer component={questionAnswerComponent} />

        {hasQuestionResponse &&
          <QuestionResponse
            showResponse={this.props.showResponse}
            questionUnit={tokenizedQuestionUnit}
            questionAnswer={this.props.questionAnswer}
          />}

        {this.props.questionUnit.id === constants.quiz.officeLocationQuestionId && !this.props.isInactive &&
          <CheckboxInput
            label={this.props.t('locatedSomewhereElseLabel')}
            onChange={this.props.toggleSameOfficeAndPersonalLocation}
            inQuiz
            checked={!this.props.isSameOfficeAndPersonalLocation}
          />}
      </div>
    );
  }
}

Question.propTypes = {
  questionUnit: spacerFreePropTypes.questionUnitShape.isRequired,
  questionAnswer: spacerFreePropTypes.questionAnswerTypes,
  onAnswerChange: PropTypes.func,
  showResponse: PropTypes.bool,
  isInactive: PropTypes.bool,
  isSameOfficeAndPersonalLocation: PropTypes.bool,
  toggleSameOfficeAndPersonalLocation: PropTypes.func,
  t: PropTypes.func.isRequired
};

Question.defaultProps = {
  onAnswerChange: _.noop,
  questionAnswer: '',
  showResponse: false,
  isInactive: false,
  isSameOfficeAndPersonalLocation: true,
  toggleSameOfficeAndPersonalLocation: _.noop
};

module.exports = withTranslation('quizPage')(Question);

