import React from 'react';

function Video(props) {
    return (
        <div className="Flooredvideo">
            <video controls poster="/svg/Floored.svg" src="/videos/FlooredPlanVideo.mp4" type="video/mp4" >
            </video>
        </div>
    );
}

module.exports = Video;