const React              = require('react');
const PropTypes          = require('prop-types');
const BasicNavbar        = require('../common/BasicNavbar.jsx');
const Footer             = require('../common/Footer.jsx');
const DocumentData       = require('../common/DocumentData.jsx');
const constants          = require('wp-constants').spacerFree;
const { Trans }          = require('react-i18next');
const { withTranslation } = require('react-i18next');


function TermsOfService(props) {
  return (
    <div>
      <DocumentData
        siteLocation={constants.siteLocation.PAGE.TERMS}
      />
      <BasicNavbar />

      <main className="has-navbar-fixed-top container terms-of-service">
        <h1 className="title is-3 has-text-centered has-text-primary has-padding-top-4 is-sans-semibold">{props.t('TermsTitle')}</h1>

        <div>{props.t('TermsIntroText')}</div>

        <ol className="main-list">
          <li>
            {props.t('TermsLimitationsTitle')}
            <ol type="a">
              <li>{props.t('TermsLimitationsA')}</li>
              <li>{props.t('TermsLimitationsB')}</li>
              <li>{props.t('TermsLimitationsC')}</li>
              <li>{props.t('TermsLimitationsD')}</li>
              <li>{props.t('TermsLimitationsE')}</li>
              <li>{props.t('TermsLimitationsF')}</li>
            </ol>
          </li>

          <li>
            {props.t('TermsOwnershipTitle')}
            <div>{props.t('TermsOwnershipText')}</div>
          </li>

          <li>
            {props.t('TermsTerminationTitle')}
            <ol type="a">
              <li>{props.t('TermsTerminationA')}</li>
              <li>{props.t('TermsTerminationB')}</li>
            </ol>
          </li>

          <li>
            {props.t('TermsDisclaimerTitle')}
            <ol type="a">
              <li>{props.t('TermsDisclaimerA')}</li>
              <li>{props.t('TermsDisclaimerB')}</li>
              <li>{props.t('TermsDisclaimerC')}</li>
            </ol>
          </li>

          <li>
            {props.t('TermsIndemnificationTitle')}
            <div>{props.t('TermsIndemnificationText')}</div>
          </li>

          <li>
            {props.t('TermsGeneralTitle')}
            <ol type="a">
              {/* See Trans usage note in assets/js/spacerFree. */ }
              <li><Trans i18nKey="termsOfServicePage:TermsGeneralA"><u>0</u>1</Trans></li>
              <li><Trans i18nKey="termsOfServicePage:TermsGeneralB"><u>0</u>1</Trans></li>
              <li><Trans i18nKey="termsOfServicePage:TermsGeneralC"><u>0</u>1</Trans></li>
              <li><Trans i18nKey="termsOfServicePage:TermsGeneralD"><u>0</u>1</Trans></li>
              <li><Trans i18nKey="termsOfServicePage:TermsGeneralE"><u>0</u>1</Trans></li>
              <li><Trans i18nKey="termsOfServicePage:TermsGeneralF"><u>0</u>1</Trans></li>
              <li><Trans i18nKey="termsOfServicePage:TermsGeneralG"><u>0</u>1</Trans></li>
              <li><Trans i18nKey="termsOfServicePage:TermsGeneralH"><u>0</u>1</Trans></li>
            </ol>
          </li>
        </ol>
      </main>

      <Footer />
    </div>
  );
}

TermsOfService.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('termsOfServicePage')(TermsOfService);
