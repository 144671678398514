const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');

const profileQuizActions     = require('../../../redux/actions/profileQuiz');
const questionUnitUtil       = require('wp-util').questionUnit;
const quizOrder              = require('wp-data').quizOrderInfo.quizOrder;

const HeroProfileQuestion    = require('../../infoPages/landing/HeroProfileQuestion.jsx');

const initialQuestionId      = '10';
const initialQuestionIndex   = quizOrder.indexOf(initialQuestionId);

// Map state to props
function mapStateToProps(state) {
  return {
    // for determining previous answers
    questionUnit: questionUnitUtil.getQuestionUnit(initialQuestionIndex),
    questionAnswer: state.profileQuiz.userQuizAnswers[initialQuestionId]
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  const answerProfileQuestion = bindActionCreators(profileQuizActions.answerProfileQuestion, dispatch);
  return {
    // answer question functions
    onAnswerChange: updatedQuestionAnswer => answerProfileQuestion(initialQuestionId, updatedQuestionAnswer)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(HeroProfileQuestion);

