module.exports = {

  // TermsOfService
  TermsTitle: 'Podmienky užívania',
  TermsIntroText: 'Toto je právna dohoda medzi vami a spoločnosťou CBRE, Inc. („CBRE“), ktorá sa týka prístupu k aplikácii známej ako Spacer® a jej používania. Používaním alebo prístupom k niektorej z informácií alebo služieb v tejto webovej aplikácii potvrdzujete, že ste si prečítali, porozumeli a súhlasíte s podmienkami stanovenými v týchto podmienkach používania („zmluva“ alebo „TOU“). Pojmy „vy“, „váš“ alebo „používateľ“ sa spoločne vzťahujú na osobu a akýkoľvek subjekt, v mene ktorého môže táto osoba konať.',

  TermsLimitationsTitle: 'Obmedzenia.',
  TermsLimitationsA: 'Používateľ rozumie a súhlasí s tým, že prístup používateľa k produktu Spacer® je nevýhradný, nesublicencovateľný, neprenosný, kedykoľvek odvolateľný a spoločnosť CBRE ho môže z akéhokoľvek dôvodu kedykoľvek pozastaviť alebo ukončiť.',
  TermsLimitationsB: 'Užívateľ ďalej rozumie a súhlasí s tým, že udelenie prístupu k produktu Spacer® mu nebude udeľovať žiadne vlastnícke práva produktu Spacer® ani k akejkoľvek súvisiacej dokumentácii (vrátane akýchkoľvek nových vydaní, úprav alebo vylepšení), pričom všetky tieto práva musia byť a zostanú výhradným majetok spoločnosti CBRE, okrem iného aj autorské práva k nim, súvisiace patenty, patenty na dizajny, ochranné známky, obchodné úpravy, obchodné tajomstvá a ďalšie vlastnícke práva vyplývajúce z platných právnych predpisov alebo medzinárodných dohovorov (súhrnne „vlastnícke práva“). Pozri oddiel 2 (Vlastníctvo) nižšie. CBRE si týmto výslovne vyhradzuje všetky práva na produkt Spacer®, ktoré nie sú užívateľovi výslovne udelené podľa týchto ustanovení.',
  TermsLimitationsC: 'Pokiaľ nie je zo strany CBRE v tomto dokumente písomne povolené, používateľ nebude: (i) upravovať, prekladať, spätne analyzovať, dekompilovať, rozoberať, pokúšať sa rekonštruovať, identifikovať alebo hľadať akýkoľvek zdrojový kód, zdrojové nápady, techniku zákazníckeho panelu alebo algoritmy, alebo vytvárať odvodené diela a kopírovať Spacer®; (ii) odstraňovať, upravovať alebo zakrývať akékoľvek oznámenia o autorských právach alebo ochranných známkach alebo iné oznámenia o vlastníckych právach obsiahnuté v Spacer®; (iii) postúpiť, sublicencovať alebo inak previesť svoj prístup, použitie alebo iné práva alebo delegovať svoje povinnosti podľa tejto dohody; alebo (iv) bez obmedzenia ktoréhokoľvek z vyššie uvedeného, ​​používať Spacer® na účely budovania konkurenčného produktu alebo služby alebo na akýkoľvek iný účel, okrem legitímnych obchodných účelov súvisiace s podnikaním používateľa.',
  TermsLimitationsD: 'Užívateľ súhlasí s tým, že bezodkladne písomne ​​informuje spoločnosť CBRE, ak mu bude oznámené akékoľvek neoprávnené použitie produktu Spacer® alebo akékoľvek možné porušenie vlastníckych práv.',
  TermsLimitationsE: 'Užívateľ nikdy počas trvania alebo po skončení platnosti tejto dohody nebude žiadať o registráciu akýchkoľvek autorských práv, ochranných značiek alebo iných označení, ktoré by ovplyvňovali vlastníctvo, ani odosielať dokumenty, ktoré by mali vplyv na vlastníctvo Spacer® spoločnosťou CBRE, značku Spacer® a ďalších komponentov aplikácie. Spacer® je ochranná známka a servisná značka spoločnosti CBRE.',
  TermsLimitationsF: 'Užívateľ prehlasuje a súhlasí s tým, že má právo poskytnúť všetky materiály alebo informácie použité v súvislosti so Spacer®, a týmto udeľuje spoločnosti CBRE nevýhradné, bezlicenčné, univerzálne, neodvolateľné, trvalé právo a licenciu na použitie , reprodukovanie, upravovanie, distribuovanie, vytváranie odvodeného diela a zobrazovanie týchto materiálov a informácií podľa uváženia spoločnosti CBRE. Užívateľ berie na vedomie, že je zodpovedný za akýkoľvek materiál a informácie, ktoré pošle, vrátane ich zákonnosti, spoľahlivosti, vhodnosti, originality a autorských práv. Licencie udelené používateľom spoločnosti CBRE podľa tejto zmluvy zostanú v platnosti aj po ukončení používania produktu Spacer® používateľom. Akýkoľvek materiál a informácie predložené používateľom budú považované za nedôverné a nechránené a tak s nimi bude nakladané zo strany CBRE, a môžu byť použité spoločnosťou CBRE v súlade s touto dohodou bez upozornenia používateľa a bez akejkoľvek nárokovateľnosti voči CBRE.',

  TermsOwnershipTitle: 'Vlastníctvo.',
  TermsOwnershipText: 'Spacer®, programovací jazyk, softvér a dokumentácia a všetok ďalší materiál a obsah sprístupnený na serveri Spacer®, alebo akékoľvek vylepšenia, úpravy, odvodené diela, vylepšenia alebo zmeny programu Spacer® so všetkými kópiami, sú vlastníctvom spoločnosti CBRE a jeho názov zostáva výhradne v spoločnosti CBRE za každých okolností vrátane, bez obmedzenia, všetky práva na patenty, autorské práva, ochranné známky a obchodné tajomstvá v aplikácii Spacer®, ako aj vylepšenia, úpravy, diela odvodené od úprav a zmien. Užívateľ berie na vedomie, že vyššie uvedené zahŕňa obchodné tajomstvá a informácie, ktoré sú dôverné a vo vlastníctve spoločnosti CBRE, a zaväzuje sa, že podnikne všetky potrebné kroky na ochranu dôvernosti týchto informácií. Užívateľ nesmie predávať, prevádzať, publikovať, zverejňovať, zobrazovať alebo inak sprístupňovať Spacer® ani vytvárať ani sprístupňovať žiadne vylepšenia, úpravy, vylepšenia, odvodené diela alebo ich zmeny a kópie. Ukončenie tejto dohody bude doplnkom a nie namiesto akýchkoľvek opravných prostriedkov, ktoré má CBRE k dispozícii zákonne ani v spravodlivosti. Záväzky z tejto dohody zostávajú v platnosti aj po ukončení platnosti tejto dohody.',

  TermsTerminationTitle: 'Výpoveď; Účinok ukončenia; Oprava',
  TermsTerminationA: 'Bez obmedzenia akýchkoľvek ďalších nápravných opatrení môže spoločnosť CBRE s okamžitou platnosťou, automaticky, s upozornením alebo bez neho a bez možnosti obrátiť sa na súd, upraviť alebo obmedziť, pozastaviť, alebo ukončiť  túto dohodu a / alebo užívateľovo používanie Spacer®, alebo akejkoľvek jeho časti s okamžitou platnosťou, automaticky, s upozornením alebo bez neho. Užívateľ súhlasí s tým, že spoločnosť CBRE nie je povinná poskytovať Spacer® a že CBRE nenesie zodpovednosť voči používateľovi ani voči akejkoľvek inej strane za akékoľvek obmedzenie, pozastavenie, prerušenie, ukončenie alebo úpravu Spacer®.',
  TermsTerminationB: 'Používateľ berie na vedomie, že povinnosti vyplývajúce z tejto zmluvy voči CBRE majú jedinečný a nenahraditeľný charakter, ktorého porušenie nenávratne poškodí CBRE a ktorý nemožno nahradiť samotnou peňažnou sumou, takže CBRE bude mať nárok na zdržanie sa konania alebo inú spravodlivú nápravu (bez záväzkov vystavenia akejkoľvek zmluvy alebo záruky) v prípade akéhokoľvek porušenia alebo predpokladaného porušenia zo strany Užívateľa. Používateľ sa neodvolateľne vzdáva všetkých práv na domáhanie sa súdneho príkazu alebo inej spravodlivej pomoci.',

  TermsDisclaimerTitle: 'Zrieknutie sa zodpovednosti a obmedzenie zodpovednosti.',
  TermsDisclaimerA: 'Spacer® je poskytovaný používateľovi „TAK, AKO JE“ a „AKO JE K DISPOZÍCII“, bez akýchkoľvek záruk. CBRE ODMIETA VŠETKY ZÁRUKY, VÝSLOVNÉ ALEBO PREDPOKLADANÉ, VRÁTANE, ALE NIE OBMEDZENÉ NA PREDPOKLADANÉ ZÁRUKY PREDAJNOSTI, VHODNOSTI NA KONKRÉTNY ÚČEL A PORUŠENIE PRÁV. Spoločnosť CBRE NERUČÍ ŽIADNYM SPÔSOBOM ZA TO, ŽE SPACER® SPLNÍ POŽIADAVKY UŽÍVATEĽA ALEBO ŠPECIFICKÝ ŠTANDARD. NIEKTORÉ ŠTÁTY NEPOVOĽUJÚ VYLÚČENIE PREDPOKLADANÝCH ZÁRUK, TAKŽE SA VYŠŠIE UVEDENÉ VYLÚČENIE NEMUSÍ VZŤAHOVAŤ NA POUŽÍVATEĽA. V takom prípade bude zodpovednosť spoločnosti CBRE obmedzená v maximálnom možnom rozsahu podľa platných právnych predpisov. ŽIADNE ÚSTNE ALEBO PÍSOMNÉ INFORMÁCIE ALEBO PORADENSTVO, KTORÉ POSKYTUJE CBRE, JEJ AGENTI ALEBO ZAMESTNANCI, NEVYTVÁRAJÚ AKÚKOĽVEK ZÁRUKU . CBRE NEPOSKYTUJE ŽIADNU ZÁRUKU, ŽE SPACER® ALEBO AKÉKOĽVEK INFORMÁCIE POSKYTNUTÉ UŽÍVATEĽOVI SÚ BEZCHYBNÉ, ALEBO BUDE POUŽÍVANIE SPACERU NEPRERUŠOVANÉ, AKTUÁLNE, KOMPLETNÉ ALEBO BEZ VÍRUSOV ALEBO INÝCH ŠKODLIVÝCH KOMPONENTOV; ŽIADNA ZÁRUKA SA NEPOSKYTUJE NA OPRAVU VYŚŠIE UVEDENÝCH INŠTANCIÍ. Spoločnosť CBRE NEZARUČUJE A NEVYDÁVA ŽIADNE VYHLÁSENIA TÝKAJÚCE SA POUŽÍVANIA Spacer® ALEBO VÝSLEDKOV Z POUŽITIA SPACER. CBRE NENESIE ZODPOVEDNOSŤ ZA ŽIADNE NEPRIAME, ŠPECIÁLNE, NÁHODNÉ, NÁSLEDNÉ, TRESTNÉ ALEBO PRÍKLADNÉ ŠKODY (VRÁTANE BEZ ŠKÔD ZA STRATU PODNIKANIA, STRATU ZISKOV, STRATY PRÍJMOV, STRATU DOBREJ Vôle, STRATY OBRATOV, STRATY ÚSPOR, STRATY DÁT), ČI UŽ KVÔLI PORUŠENIU ZMLUVY, TRESTU (VRÁTANE ZANEDBANIA), ZODPOVEDNOSTI ZA VÝROBKY ALEBO INAK, AJ KEĎ BOLI ZÁSTUPCOVIA CBRE VOPRED UPOZORNENÍ O MOŽNOSTI ŠKODY, AJ V PRÍPADE, ŽE SÚ OPRAVNÉ OPATRENIA NEÚČINNÉ ““',
  TermsDisclaimerB: 'CELKOVÁ ZODPOVEDNOSŤ SPOLOČNOSTI CBRE ZA ŠKODY ZA AKÚKOľVEK PRÍČINU NIKDY NEPREKROČÍ POPLATOK UPLATNENÝ UŽÍVATEĽOM ZA Spacer® POČAS DVANÁSŤ (12) MESAČNÉHO OBDOBIA PRED AKCIOU, KTORÁ ZAPRÍČINILA TOTO POŠKODENIE. UŽÍVATEĽ SÚHLASÍ S TÝM, ŽE AKÁKOĽVEK PRÍČINA ČINNOSTI VYPLÝVAJÚCA ZO Spacer® ALEBO SÚVISIACA S TOUTO DOHODOU, MUSÍ ZAČAŤ DO JEDNÉHO (1) ROKU PO PRÍČINE. INAK TAKÁTO PRÍČINA ČINNOSTI OSTÁVA NAVŽDY BEZPREDMETNOU.',
  TermsDisclaimerC: 'NIEKTORÉ PRÁVNE ÚPRAVY NEMUSIA POVOLIŤ OBMEDZENIE ZODPOVEDNOSTI ALEBO VYLÚČENIA ZODPOVEDNOSTI UVEDENÉ VYŠŠIE, TAKŽE SA VYŠŠIE UVEDENÉ OBMEDZENIE ALEBO VYLÚČENIE NEMUSIA VZŤAHOVAŤ NA POUŽÍVATEĽA. V takom prípade bude zodpovednosť spoločnosti CBRE obmedzená v maximálnom možnom rozsahu podľa platných právnych predpisov. Zrieknutie sa záruky, výhradné opravné prostriedky a obmedzená zodpovednosť uvedené vyššie sú základnými prvkami tejto dohody medzi CBRE a UŽÍVATEĽOM. Spoločnosť CBRE by nebola schopná poskytnúť Spacer® na ekonomickom základe a bez týchto obmedzení by neuzavrela túto dohodu. Tieto obmedzenia zostanú v platnosti aj po ukončení tejto dohody.',

  TermsIndemnificationTitle: 'Odškodnenie používateľa.',
  TermsIndemnificationText: 'Používateľ, podľa uváženia a na žiadosť CBRE, na svoje vlastné náklady, odškodní a zaistí nezraniteľnosť CBRE a jej dcérskych spoločností, pridružených spoločností, úradníkov, zamestnancov, agentov, pridružených značiek, asociácií a ďalších partnerov, aby boli plne nerizikové voči stratám, nákladom, škodám , záväzkom, vyrovnaniam a výdavkom(vrátane (ale aj nákladom a poplatkom za právne zastúpenie), ktoré vznikajú alebo súvisia s akýmkoľvek nárokom, konaním alebo tvrdením tretej strany súvisiacim alebo vyplývajúcim z: (a) akejkoľvek transakcie alebo sporu medzi používateľom a treťou stranou; (b) skutočnosti alebo údajnej skutočnosti, ktoré by predstavovali porušenie akýchkoľvek vyhlásení, záruk alebo záväzkov používateľa podľa tejto dohody alebo akýchkoľvek platných zákonov, pravidiel alebo nariadení, či už sú tu uvedené alebo na ne nie je odkazované; (c) akýchkoľvek materiálov, informácií alebo obsahu používateľa predložených prostredníctvom Spacer®; (d) porušenia akýchkoľvek práv tretích strán používateľom, (e) použitia alebo zneužitia Spaceru® používateľom; alebo (f) nedbanlivosti alebo úmyselného zneužitia úradnej moci Používateľom. CBRE má právo výhradne riadiť a ovládať svoju obranu a najímať právnych zástupcov podľa svojho výberu na náklady používateľa.',

  TermsGeneralTitle: 'Všeobecné.',
  TermsGeneralA: 'Táto zmluva predstavuje plnú dohodu medzi CBRE a používateľom, vo vzťahu k Spacer®, a všetkým predmetom tejto dohody, a nahrádza všetky predchádzajúce dohody medzi CBRE a používateľom týkajúce sa Spacer®, Ak nejaké existovali. Neschopnosť spoločnosti CBRE presadiť ktorékoľvek ustanovenie tejto dohody sa nebude vykladať ako vzdanie sa svojich práv.',
  TermsGeneralB: '<0> Oznámenia. </0> Oznámenia, ktoré budete dostávať, musia mať písomnú formu a môžu byť zaslané e-mailom alebo zverejnením týchto oznámení na serveri Spacer® alebo na adrese www.cbre.com/spacer. Vy nám budete oznámenia  posielať e-mailom na adresu spacersupport@cbre.com a kópia bude odoslaná certifikovanou poštou cez USA 400 S. Hope Street, 25. poschodie, Los Angeles, CA 90071. Attention: General Counsel´s Office. Oznámenia sa budú považovať za poskytnuté jeden (1) pracovný deň po odoslaní e-mailom (pokiaľ odosielateľ nedostane odpoveď naznačujúcu, že správa nebola doručiteľná), alebo tri pracovné dni po zverejnení na webovej stránke CBRE (v prípade CBRE), s alebo bez obdŕžania.',
  TermsGeneralC: '<0>Štátne právo.</0> Táto dohoda je riadená a konštruovaná v súlade s právnymi predpismi štátu Kalifornia bez ohľadu na ustanovenia o konflikte zákonov. CBRE a používateľ sa týmto podrobujú výlučne inštitúciám v štáte Kalifornia. Dohovor OSN o zmluvách o medzinárodnej kúpe tovaru (1980) je týmto v celom rozsahu vylúčený z uplatňovania tejto dohody. Užívateľ týmto súhlasí, že Spacer® je výlučne so sídlom spoločnosti v Kalifornii a za pasívnu službu, ktorá nevyvolá samostatné konania v rámci jurisdikcie nad rámec CBRE, či už konkrétne alebo všeobecné, v jurisdikcii inej ako v štáte Kalifornia. “',
  TermsGeneralD: '<0> Riešenie sporov. </0> Akýkoľvek spor, nárok alebo kontroverzia vyplývajúce z tejto dohody alebo súvisiaca s touto dohodou, s porušením, vynútením, interpretáciou alebo platnosťou tejto dohody vrátane určenia rozsahu alebo použiteľnosti tejto dohody bude adresovaný arbitrážou. Arbitráž bude spravovať Súdna arbitrážna a mediačná služba (JAMS) a arbitráž sa bude viesť s jedným arbitrom. Užívateľ ďalej súhlasí s tým, že ani používateľ, ani CBRE sa v arbitráži neprihlásia k žiadnemu nároku akejkoľvek inej osoby alebo subjektu; že žiadny nárok nebude vyriešený na úrovni kategórie; a že Používateľ ani CBRE neuplatní žiadny nárok v zastúpení v mene nikoho iného. Bez ohľadu na vyššie uvedené, používateľ súhlasí s tým, že CBRE bude môcť v akejkoľvek jurisdikcii požiadať o príkazné opravné prostriedky (alebo rovnocenný typ naliehavej právnej pomoci). Ak Používateľ podá arbitrážnu sťažnosť proti CBRE, CBRE sa zaväzuje zaplatiť za akúkoľvek časť počiatočného poplatku presahujúceho 250 dolárov; po počiatočných poplatkoch za podanie si strany rovnomerne rozdelia náklady na arbitráž. Užívateľ sa zaväzuje, že proti CBRE nezačne ani nebude stíhať žiadne iné kroky ako podanie arbitrážnej sťažnosti v súlade s týmto odsekom. Predovšetkým, SÚHLASOM K ZAVIAZANIU SA K TEJTO ZMLUVE, SA UŽÍVATEĽ VZDÁVA PRÁVA OBRÁTIT SA NA SÚD A AKEHOKOĽVEK PRÁVA VYPLÝVAJÚCEHO ZO SÚDU POROTOU:',
  TermsGeneralE: '<0> Prerušenie. </0> V prípade, že sa časť tejto dohody bude považovať za nevymáhateľnú, nevymáhateľná časť sa upraví iba v rozsahu nevyhnutnom na zabezpečenie jej vykonateľnosti a zvyšok ustanovení zostane v plnej platnosti a účinku.',
  TermsGeneralF: '<0> Postúpenie. </0> Túto dohodu nemôže používateľ postúpiť bez predchádzajúceho písomného súhlasu spoločnosti CBRE. Akýkoľvek pokus o postúpenie bez súhlasu CBRE bude neplatný. CBRE si vyhradzuje právo postúpiť túto dohodu ktorejkoľvek tretej strane. Táto dohoda a práva a povinnosti každého z nás podľa nej budú záväzné pre našich nástupcov a postupníkov.',
  TermsGeneralG: '<0> Úpravy. </0> Spoločnosť CBRE môže kedykoľvek a na základe výlučného a výhradného uváženia spoločnosti CBRE upraviť ktorékoľvek z podmienok uvedených v tejto zmluve zverejnením oznámenia o zmene alebo novej dohody na stránkach Spacer®, na adrese adresa www.cbre.com/spacer alebo poskytnutím upozornenia alebo podobného oznámenia v aplikácii Spacer®. Ak je pre používateľa akákoľvek úprava neprijateľná, môže používateľ ukončiť túto dohodu skončením používania  Spacer®.',
  TermsGeneralH: '<0> Konflikty. </0> V prípade konfliktu medzi podmienkami tejto zmluvy a akejkoľvek plne vykonanej licenčnej zmluvy medzi používateľom a CBRE vo vzťahu k Spacer®, budú podmienky takejto licenčnej zmluvy určujúce vo vzťahu k takýmto protichodným podmienkam. ““'

};
