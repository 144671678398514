const React               = require('react');
const PropTypes           = require('prop-types');
const spacerFreePropTypes = require('../../spacerFreePropTypes');
const numericDisplay      = require('../../../util/numericDisplay');
const unitsUtil           = require('../../../../shared/util/units');
const displayUtil         = require('../../../../shared/util/display');
const { withTranslation }  = require('react-i18next');

function DeltaLabel(props) {
  if (!props.delta) return null;

  const numericDelta = unitsUtil.getNumberForMeasurementSystem(props.delta.numericDelta, props.measurementSystem, { precisionForMeter: 1, precisionForTsubo: 1 });
  if (!numericDelta) return null;

  const isPositive = props.delta.numericDelta > 0;
  const signSymbol = isPositive ? '+' : '';
  const numericDeltaWithComma = displayUtil.dashOrValue(!numericDelta, numericDisplay.numberWithLocaleFormat(numericDelta));
  const measurementLabel = props.t(unitsUtil.getTokenKeyForMeasurementSystem('sqft.', props.measurementSystem));

  let deltaDisplay = `${signSymbol}${numericDeltaWithComma} ${measurementLabel}`;
  if (props.showDeltaPercentage) deltaDisplay += ` (${signSymbol}${props.delta.displayPercentDelta})`;

  return (
    <div className="program-delta-label subtitle is-6">
      {deltaDisplay}
    </div>
  );
}

DeltaLabel.propTypes = {
  delta: spacerFreePropTypes.deltaShape,
  showDeltaPercentage: PropTypes.bool,
  measurementSystem: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

DeltaLabel.defaultProps = {
  delta: null,
  showDeltaPercentage: true
};

module.exports = withTranslation('common')(DeltaLabel);
