const React     = require('react');
const PropTypes = require('prop-types');
const _         = require('lodash');

class NumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
  }

  onChange(value) {
    this.props.onChange(parseFloat(value));
  }

  onBlur(evt) {
    this.props.onBlur(evt.target.value);
  }

  // floor and ceil to jump in full number increments cause that feels smoother (eg. 4.3 -> 5 instead of 4.3 -> 5.3)
  // NOTE: this will cause unexpected behavior if this.props.step is less than 1
  increment() {
    let value = !_.isNull(this.props.value)
      ? Math.floor(parseFloat(this.props.value)) + this.props.step
      : this.props.min;
    if (value > this.props.max) value = this.props.max;
    this.props.onChange(value);
  }

  decrement() {
    let value = !_.isNull(this.props.value)
      ? Math.ceil(parseFloat(this.props.value)) - this.props.step
      : this.props.min;
    if (value < this.props.min) value = this.props.min;
    this.props.onChange(value);
  }

  render() {
    let inputWrapperClasses = 'underline-input';
    if (this.props.isDark) inputWrapperClasses += ' is-primary';
    if (this.props.disabled) inputWrapperClasses += ' is-disabled';
    if (this.props.isInactive) inputWrapperClasses += ' is-inactive';
    if (this.props.isLarge) inputWrapperClasses += ' is-large';
    if (this.props.isLossFactor) inputWrapperClasses += ' has-text-light';
    if (this.props.hasXlargeArrows) inputWrapperClasses += ' large-arrow-underline';
    if (this.props.hasShortUnderline) inputWrapperClasses += ' shorter-underline-width';
    if (this.props.hasSpaceTypeColor) inputWrapperClasses += ' space-type-color-input';
    if (this.props.isLossFactor) inputWrapperClasses += ' is-loss-factor';

    let dropdownTriangleClass = 'dropdown-triangle';
    let dropdownTriangleUpClass = 'dropdown-triangle-up';
    if (this.props.hasXlargeArrows) {
      dropdownTriangleClass = 'dropdown-triangle-xlarge';
      dropdownTriangleUpClass = 'dropdown-triangle-up-xlarge';
    }
    const input = (
      <input
        className="input"
        type="number"
        min={this.props.min}
        max={this.props.max}
        value={this.props.value}
        step={this.props.step}
        // onChange={this.onChange}
        onChange={ (event) => {
          const newValue = event.target.value;
          if (newValue > this.props.max) {
           this.onChange(this.props.max)
          }else if (newValue < 0) {
            this.onChange(Math.abs(newValue))
           }else{
           this.onChange(newValue)
          }
        } }
        onBlur={this.onBlur}
        autoFocus={this.props.autoFocus}
        disabled={this.props.disabled ? 'disabled' : ''}
      />
    );
    const dropdownArrows = (
      <div className="number-arrow-icons">
        <div
          className={`${dropdownTriangleUpClass} fs-ignore-rage-clicks`}
          aria-hidden="true"
          onClick={this.increment}
        />
        <div className={`${dropdownTriangleClass} fs-ignore-rage-clicks`} aria-hidden="true" onClick={this.decrement} />
      </div>
    );

    return (
      <div className={inputWrapperClasses}>
        {input}
        {!this.props.hideNumberArrows && dropdownArrows}
        {this.props.isLossFactor && (
          <div className="is-loss-factor-styles loss-factor-percent space-type-color-text">%</div>
        )}
      </div>
    );
  }
}

NumberInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  step: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  hideNumberArrows: PropTypes.bool,
  isInactive: PropTypes.bool,
  isDark: PropTypes.bool,
  isLarge: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isLossFactor: PropTypes.bool,
  hasXlargeArrows: PropTypes.bool,
  hasShortUnderline: PropTypes.bool,
  hasSpaceTypeColor: PropTypes.bool
};

NumberInput.defaultProps = {
  min: 0,
  max: Infinity,
  step: 1,
  value: '',
  onBlur: _.noop,
  onChange: _.noop,
  disabled: false,
  hideNumberArrows: false,
  isInactive: false,
  isDark: false,
  isLarge: false,
  autoFocus: false,
  isLossFactor: false,
  hasXlargeArrows: false,
  hasShortUnderline: false,
  hasInputTypeText: false,
  hasSpaceTypeColor: false
};

module.exports = NumberInput;
