const React           = require('react');
const PropTypes       = require('prop-types');
const _               = require('lodash');
const sharedPropTypes = require('../../../shared/components/sharedPropTypes');
const DropdownInput   = require('../form/DropdownInput.jsx');
const KEY_CODES       = require('../../../shared/constants/keyCodes.js');

class AutocompleteInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      dropdownOptions: {},
      optionOrder: []
    };

    this.setValue = this.setValue.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.updateDropdownOptions = this.updateDropdownOptions.bind(this);
  }

  onKeyDown(evt) { // to allow seeing the entire list of suggestions on down arrow press on initial empty field
    if (this.state.value === '' && evt.which === KEY_CODES.DOWN_ARROW) {
      this.updateSearch('');
    } else if ((evt.which === KEY_CODES.ENTER) && !this.props.allowValueNotInDropdown && this.state.value !== this.props.value) {
      // if a user hits enter AND are only allowed values that exist in the dropdown AND has changed their answer since last selecting a permitted value,
      // then reset to last permitted value they selected
      this.setState({ value: this.props.value });
      this.updateSearch(this.props.value); // to update dropdown suggestions as well
    }
  }

  setValue(value) {
    this.setState({ value });
    this.updateSearch(value); // to update dropdown suggestions as well
    this.props.onValueChange(value);
  }

  updateDropdownOptions(newOptions) {
    this.setState(newOptions);
  }

  updateSearch(evtOrValue) {
    const value = _.isString(evtOrValue) ? evtOrValue : evtOrValue.target.value;
    this.setState({ value });

    if (value.length >= this.props.minSearchLength) {
      this.props.getAutocompleteOptions(value, this.updateDropdownOptions);
    } else {
      // if value is below this.props.minSearchLength but there are leftover suggestions from before, remove those suggestions
      if (this.state.optionOrder.length > 0) this.setState({ dropdownOptions: {}, optionOrder: [] });

      // if value becomes empty, propagate changes to parent component in case it has conditions for empty values (e.g., disabling buttons)
      if (value === '') this.props.onValueChange(value);
    }
  }


  render() {
    const inputField = (
      <input
        type="text"
        className="input"
        role="combobox"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        aria-expanded={this.state.optionOrder.length > 0}
        value={this.state.value}
        placeholder={this.props.placeholder}
        title={this.state.value}
        onChange={this.updateSearch}
        autoFocus={!this.props.isInactive}
        onKeyDown={this.onKeyDown}
      />
    );

    return (
      <DropdownInput
        {...this.props}
        value={this.state.value}
        onValueChange={this.setValue}
        options={this.state.dropdownOptions}
        optionOrder={this.state.optionOrder}
        inputField={inputField}
      />
    );
  }
}

AutocompleteInput.propTypes = {
  value: sharedPropTypes.stringOrNumber.isRequired,
  onValueChange: PropTypes.func.isRequired,
  isInactive: PropTypes.bool.isRequired,
  getAutocompleteOptions: PropTypes.func.isRequired,
  minSearchLength: PropTypes.number,
  allowValueNotInDropdown: PropTypes.bool,
  placeholder: PropTypes.string
};

AutocompleteInput.defaultProps = {
  minSearchLength: 0,
  allowValueNotInDropdown: false,
  placeholder: ''
};

module.exports = AutocompleteInput;
