const React                = require('react');
const { connect }          = require('react-redux');
const PropTypes            = require('prop-types');
const programDataRetriever = require('../../../../util/programDataRetriever');
const regionSFCalculator   = require('../../../../../shared/calculators/regionSFCalculator');
const SPACE_UNIT_CATEGORY  = require('wp-constants').shared.spaceUnit.CATEGORY;
const numericDisplay       = require('../../../../util/numericDisplay');

const BarChart              = require('../../../results/program/BarChart.jsx');
const TabbedProgramBarChart = require('../../../results/program/TabbedProgramBarChart.jsx');

function ProgramBarChart(props) { // both of those components share the same props
  if (props.isTabbedBarChart) return <TabbedProgramBarChart {...props} />;
  return <BarChart {...props} />;
}

// Map state to props
function mapStateToProps(state) {
  const location = state.userData.officeLocation;
  const sfType = state.spaceData.sfType;
  const lossFactor = state.spaceData.lossFactor;
  const areaTotals = state.spaceData.program.areaTotals;
  const areaPercentage = state.spaceData.program.areaPercentage;
  const meSF = regionSFCalculator.getAreaForContext(programDataRetriever.getTotalSFForCategory(areaTotals, SPACE_UNIT_CATEGORY.ME), sfType, location, lossFactor);
  const weSF = regionSFCalculator.getAreaForContext(programDataRetriever.getTotalSFForCategory(areaTotals, SPACE_UNIT_CATEGORY.WE), sfType, location, lossFactor);
  const amenitySF = regionSFCalculator.getAreaForContext(programDataRetriever.getTotalSFForCategory(areaTotals, SPACE_UNIT_CATEGORY.AMENITY), sfType, location, lossFactor);
  
  const mePercentage = areaPercentage.me;
  const wePercentage = areaPercentage.we;
  const amenityPercentage = areaPercentage.amenity;

  return {
    meSF,
    weSF,
    amenitySF,
    mePercentage,
    wePercentage,
    amenityPercentage,
    measurementSystem: state.spaceData.measurementSystem
  };
}

ProgramBarChart.propTypes = {
  isTabbedBarChart: PropTypes.bool
};

ProgramBarChart.defaultProps = {
  isTabbedBarChart: false
};

module.exports = connect(mapStateToProps)(ProgramBarChart);
