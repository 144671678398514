const actions            = require('../actions/spaceData');
// const globalActions      = require('../actions/global');
import globalActions from '../actions/global';

const userDataActions    = require('../actions/userData');
const constants          = require('wp-constants').spacerFree;
const { v4: uuidv4 }             = require('uuid');
const programCalculator  = require('../../calculators/programCalculator');
const regionSFCalculator = require('../../../shared/calculators/regionSFCalculator');
const locationUtil       = require('../../../shared/util/location');
const _                  = require('lodash');

module.exports = function spaceData(state = {}, action) {
  let assumptions;
  let stateCopy;
  let customAmenity;

  switch (action.type) {
    case actions.ADD_KEY_ASSUMPTION: {
      assumptions = _.assign({}, state.assumptions, { [action.assumptionType]: action.value });

      return _.assign({}, state, {
        assumptions
      });
    }

    case userDataActions.ADD_USER_DATA: {
      if (action.dataType === 'officeLocation') {
        return _.assign({}, state, {
          measurementSystem: locationUtil.getMeasurementSystemForLocation(action.value),
          lossFactor: regionSFCalculator.getDefaultLossFactor(action.value),
          sfType: locationUtil.getSFTypeForLocation(action.value)
        });
      }
      return state;
    }

    case actions.UPDATE_MEASUREMENT_SYSTEM: {
      return _.assign({}, state, {
        measurementSystem: action.measurementSystem
      });
    }

    case actions.UPDATE_SF_TYPE: {
      return _.assign({}, state, {
        sfType: action.sfType
      });
    }

    case actions.UPDATE_LOSS_FACTOR: {
      let lossFactor = action.lossFactor;
      if (action.lossFactor > constants.calculator.MAX_LOSS_FACTOR / 100) {
        lossFactor = constants.calculator.MAX_LOSS_FACTOR / 100;
      } else if (!lossFactor || lossFactor < 0) {
        lossFactor = 0;
      }

      return _.assign({}, state, {
        lossFactor
      });
    }

    case actions.UPDATE_SPACE_UNIT_QUANTITY: {
      stateCopy = _.cloneDeep(state);
      programCalculator.updateProgramForMeWeOrSupportCustomQuantity(stateCopy, action.spaceUnitId, action.value, action.officeLocation);
      return stateCopy;
    }

    case actions.UPDATE_SPACE_UNIT_AMENITY_SF: {
      stateCopy = _.cloneDeep(state);
      programCalculator.updateProgramForAmenityCustomSF(stateCopy, action.spaceUnitId, action.value);
      return stateCopy;
    }

    case actions.ADD_CUSTOM_AMENITY: {
      stateCopy = _.cloneDeep(state);
      const id = `${action.displayName.toLowerCase()}_${uuidv4()}`; // create random id for custom amenities

      // use 'customXXX' properties since all custom amentities are deltas off the normal program
      customAmenity = {
        id,
        dataKey: action.dataKey,
        displayName: action.displayName,
        usf: 0,
        nsf: 0,
        quantity: 0,
        customUSF: 0,
        customNSF: 0,
        customSF: action.customSF,
        customQuantity: action.customQuantity
      };

      programCalculator.addCustomAmenity(stateCopy, customAmenity);

      return stateCopy;
    }

    case actions.UPDATE_CUSTOM_AMENITY_QUANTITY: {
      stateCopy = _.cloneDeep(state);
      programCalculator.updateProgramForCustomAmenity(stateCopy, action.id, { customQuantity: action.quantity });
      return stateCopy;
    }

    case actions.UPDATE_CUSTOM_AMENITY_SF: {
      stateCopy = _.cloneDeep(state);
      programCalculator.updateProgramForCustomAmenity(stateCopy, action.id, { customSF: action.customSF });
      return stateCopy;
    }

    case actions.DELETE_SPACE_UNIT: {
      stateCopy = _.cloneDeep(state);
      programCalculator.removeProgramPart(stateCopy, action.id);
      return stateCopy;
    }

    case globalActions.RECEIVE_PROGRAM: {
      return action.program.spaceData;
    }

    case actions.SET_PROGRAM_ID: {
      return _.assign({}, state, {
        _id: action.id
      });
    }

    default: {
      return state;
    }
  }
};
