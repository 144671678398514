const React       = require('react');
const HeroSection = require('../../common/HeroSection.jsx');
const PropTypes   = require('prop-types');
const { withTranslation } = require('react-i18next');


function AboutPageHero(props) {
  return (
    <HeroSection sizeClass="is-small">
      <div className="columns level">
        <div className="column is-5 level-item">
          <div>
            <figure className="image is-4by3">
              <img src="/svg/illustrations/about-page/about-page-office-move-spacer-by-cbre.svg" alt={props.t('aboutPageHeroImgAlt')} />
            </figure>
          </div>
        </div>
        <div className="column is-7">
          <h1 className="title has-text-primary is-financier-regular">{props.t('aboutPageHeroTitle')}</h1>
        </div>
      </div>
    </HeroSection>
  );
}

AboutPageHero.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('aboutPage')(AboutPageHero);
