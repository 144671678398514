const React      = require('react');
const PropTypes  = require('prop-types');
const classnames = require('classnames');

function HeroSection(props) {
  const colorClass = classnames({
    'is-primary': props.hasPrimaryBackground,
    'is-light-pale-green': !props.hasPrimaryBackground && props.isLightColor,
    // 'is-pale-green': !props.hasPrimaryBackground && !props.isLightColor,
    'is-pale-green-new': !props.hasPrimaryBackground && props.isPaleGreenNew,
    'is-light-gray': !props.hasPrimaryBackground && !props.isLightColor
  });

  const heroClasses = classnames(`hero-section hero ${props.classes} ${props.sizeClass} ${colorClass}`, {
    'has-navbar-fixed-top': props.addTopNavbarSpace
  });

  return (
    <div className={heroClasses}>
      <div className="hero-body">
        <div className="container">
          {props.children}
        </div>
      </div>
    </div>
  );
}

HeroSection.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  addTopNavbarSpace: PropTypes.bool,
  isLightColor: PropTypes.bool,
  sizeClass: PropTypes.string,
  hasPrimaryBackground: PropTypes.bool
};

HeroSection.defaultProps = {
  classes: '',
  addTopNavbarSpace: false,
  isLightColor: false,
  sizeClass: 'is-medium',
  hasPrimaryBackground: false
};

module.exports = HeroSection;

