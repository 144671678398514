module.exports = {
  blogHomeTitle: 'Aktualności z obszaru planowania przestrzeni biurowej',

  // blog tags
  blogTagAllArticles: 'Wszystkie artykuły',
  blogTagSizeAndFit: 'Wielkość i dopasowanie',
  blogTagOfficeCulture: 'Kultura organizacyjna',
  blogTagSpaceAndDesign: 'Przestrzeń & Projekt',
  blogTagWorkplaceTrends: 'Trendy w zakresie Środowiska Pracy'
};
