const React     = require('react');
const PropTypes = require('prop-types');

function QuestionAnswer(props) {
  return (
    <div className="question-answer">
      {props.component}
    </div>
  );
}

QuestionAnswer.propTypes = {
  component: PropTypes.node.isRequired
};

module.exports = QuestionAnswer;
