const BrowserLngDetector       = require('i18next-browser-languagedetector').default;
const i18nextMiddleware        = require('i18next-http-middleware');
const { updateUrlForLanguage } = require('./i18nLanguages');
const { parseDomain } = require('parse-domain')

/* Custom domain detector. Reads the ccTLD from the URL and returns a country code.
   Both the server side and the client side use this domain detector, although the client side is
   only supposed to use this as a failsafe, in case something goes wrong with cookie detection.
   The server side uses this to determine what language the app should initially display in,
   based on the URL's ccTLD (and the browser language, for British English ".com".  Later, the user
   can edit this language by choosing a different language with the LanguageSelector dropdown. */
  function getLanguageCode (url) {
  const domainObj = parseDomain(url);

  if (domainObj === null) return 'en';
  switch (domainObj.tld) {
    case 'co.uk': return 'en-GB';
    case 'com': return 'en';
    case 'de': return 'de';
    case 'fr': return 'fr';
    case 'at': return 'de';
    case 'sk': return 'svk';
    case 'hu': return 'hun';
    case 'pl': return 'pol';
    default: return 'en';
  }
}

function initDomainDetector(lngDetector, type) {
  lngDetector.addDetector({
    name: 'domainDetectorCustom',

    lookup: (req) => {
      let url;
      if (type === 'server') {
        url = req.get('host'); // server gets URL from req
      } else {
        url = window.location.href; // client gets URL from window
      }

      return getLanguageCode(url);
    }
  });
}

function getBrowserLanguageDetector() {
  // TODO: uncomment this when we have the appropriate domains
  // let languageFromBrowser = navigator.language;
  // if (languageFromBrowser === 'en-US') languageFromBrowser = 'en';
  // const languageFromUrl = getLanguageCode(window.location.href);
  // if (languageFromUrl !== languageFromBrowser) {
  //   updateUrlForLanguage(languageFromBrowser);
  // }

  const lngDetector = new BrowserLngDetector();
  initDomainDetector(lngDetector, 'browser');

  return lngDetector;
}

function getServerLanguageDetector() {
  const lngDetector = new i18nextMiddleware.LanguageDetector();
  initDomainDetector(lngDetector, 'server');

  return lngDetector;
}

const detectionOptions = {
  // The order in which language detection should look for a language; cookie first, for user preference prioritization
  order: ['cookie', 'domainDetectorCustom'],
  // Cache the cookie so that it persists (necessary for persisting user language)
  caches: ['cookie'],
  // The cookie to store and lookup the language from
  lookupCookie: 'i18next'
};

module.exports = {
  getBrowserLanguageDetector,
  getServerLanguageDetector,
  detectionOptions,
  updateUrlForLanguage
};
