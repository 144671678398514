const React     = require('react');
const PropTypes = require('prop-types');

function SectionTitle(props) {
  return (
    <div className="section-title-container">
      <h2 className="title is-4 section-title has-margin-bottom-7 is-calibre">
        {props.title}
      </h2>
      {
        props.subtitle &&
        <div className="section-subtitle">
          {props.subtitle}
        </div>
      }
    </div>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

SectionTitle.defaultProps = {
  subtitle: ''
};

module.exports = SectionTitle;
