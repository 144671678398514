/*
    The Program Calculator takes a kit of parts with multipliers, spaceUnit types required by that KOPwM, and
    user defined metrics to compute the finalized space program. Calculations found below are derived from this
    google sheet:
      https://docs.google.com/spreadsheets/d/1jdQB5GfQxfLmHhok5DuHv6k5eFAJdtQ_ht-1TwbVaQo/edit#gid=2081962167

    APAC-specific logic (all space units have a circulation of 30%) came from:
    https://docs.google.com/document/d/1ipkQpO9e_vvFNuF7iFAtUcOk2-r2LCEscN8NnKH47Ug/edit

    Here are some definitions of terms that are found in the calculator:

      SpaceUnit - A set of metadata (including size and seats) surrounding a specific type of space, such as office, workstation, or copy room.

      Kit Of Parts with Multipliers - A collection of space units, each having one of various multipliers that are used to
      determine the quantity and area of the space type in the final program.

      NSF (Net Square Feet) - The area of each identified space unit

      USF (Usable Square Feet) - Area of a floor occupiable by a tenant where personnel or furniture are normally housed (both in total and per space unit)

      Circulation Area = USF - NSF
*/

const jStat = require('jstat').jStat;
const _ = require('lodash');
const {
  profileMap,
  spaceUnitMap,
  suggestedCustomAmenityUnitMap
} = require('wp-data');

const {
  CATEGORY,
  CIRCULATION_TYPE,
  CATEGORY: SPACE_UNIT_CATEGORY,
  STANDARD_CIRCULATION_MAP,
  HIGH_CIRCULATION_MAP,
  COUNTRIES_CIRCULATION_MAP
} = require('wp-constants').shared.spaceUnit;
const PROFILE_TYPE = require('wp-constants').spacerFree.calculator;
const locationUtil = require('../../shared/util/location');

const sharedProgramCalculator = require('../../shared/calculators/programCalculator');

// *************************************
//  Local Constants
// *************************************

// Maps spaceUnitIds to alternate functions to calculate quantity of units
// Currently only alternates are for support spaces
// NOTE: spaceUnits 21, 22, and 23 have duplicate versions for APAC KOPWMs; the duplicates don't use alternate quantity functions
const alternateQuantityFunction = {
  21: function fileRoomQuantity({ expectedDailyHeadcount, multiplier }) {
    return Math.round((expectedDailyHeadcount) / multiplier);
  },
  22: function threeDrawerLateralFileQuantity({ headcount, multiplier }) {
    return Math.round(headcount * multiplier);
  },
  23: function lockersQuantity({ headcount, sharingPopulation, multiplier }) {
    return Math.round(sharingPopulation * headcount * multiplier);
  },
  30: function internalStairQuantity({ headcount }) {
    const estimatedSfPerPerson = 150;
    const estimatedSfPerFloor = 20000;
    return Math.floor((headcount * estimatedSfPerPerson) / estimatedSfPerFloor);
  }
};
// *************************************


// *************************************
//  Metrics Calculations
// *************************************

// "Planning Population" is the portion of the headcount we expect to be in the office on a given day 95% of the time.
function calculatePlanningPopulation(daysPerWeekInOffice) {
  const showupRate = daysPerWeekInOffice / 5;
  if (showupRate === 1) return 1;
  // *************************************************
  const standardDeviation = 0.08; // TODO -- Marked in spread sheet as an input, where does this come from
  const variance = standardDeviation ** 2;

  const alpha = (((1 - showupRate) / variance) - (1 / showupRate)) * (showupRate ** 2);
  const beta = alpha * ((1 / showupRate) - 1);

  return jStat.beta.inv(0.95, alpha, beta);
}

// "Sharing Population" is the portion of the headcount not assigned an individual seat
function calculateSharingPopulation(daysPerWeekInOffice, profileType) {
  let threshold;
  if (profileType === PROFILE_TYPE.UK_REGULAR ||
    profileType === PROFILE_TYPE.GERMANY_REGULAR ||
    profileType === PROFILE_TYPE.APAC_REGULAR ||
    profileType === PROFILE_TYPE.SPAIN_REGULAR ||
    profileType === PROFILE_TYPE.FRANCE_REGULAR ||
    profileType === PROFILE_TYPE.SLOVAKIA_REGULAR ||
    profileType === PROFILE_TYPE.POLAND_REGULAR ||
    profileType === PROFILE_TYPE.HUNGARY_REGULAR ||
    profileType === PROFILE_TYPE.COLOMBIA_REGULAR ||
    profileType === PROFILE_TYPE.NETHERLAND_REGULAR){
    threshold = 4;
  } else {
    threshold = 3.5;
  }
  return daysPerWeekInOffice > threshold ? 0 : 1;
}

// "Workshare" is the number of work seats ("me seats") per member of the planning population.
// Currently always 1, but could be more complex in the future.
function calculateWorkshare() {
  return 1;
}
// *************************************


// *************************************
//  Seat Count Calculations
// *************************************

// Workseats are used to determine me space quantities
// Non-sharing headcount gets one seat + 5% vacancy for moves, adds, and changes (for regular_US/UK)
function calculateWorkSeats(headcount, expectedDailyHeadcount, sharingPopulation, workshare, profileType) {
  const nonSharingMultiplier = profileType !== PROFILE_TYPE.US_SMALL ? 1.05 : 1;
  const nonSharingHeadcountSeats = (1 - sharingPopulation) * headcount * nonSharingMultiplier;
  const sharingHeadcountSeats = workshare * sharingPopulation * expectedDailyHeadcount;

  const typeBasedHeadcountBump = profileType === PROFILE_TYPE.US_SMALL ? Math.ceil(headcount * 0.05) : 0;

  return Math.round(nonSharingHeadcountSeats + sharingHeadcountSeats + typeBasedHeadcountBump);
}

function calculateGroupSeats(expectedDailyHeadcount, groupshare) {
  return Math.round(expectedDailyHeadcount * groupshare);
}
// *************************************


// *************************************
//  Square Foot Calculations
// *************************************


function calculateCustomAmenitySF(customAmenity) {
  // NOTE: although we call these custom "amenities", we don't calculate NSF and USF as such, we use the "standard program part" calculation with an "amenity" type

  const { nsf, usf } = sharedProgramCalculator.calculateMeSharedFocusWeSupportProgramPartNSFAndUSF({
    // the quantity and SF are always "custom"
    quantity: customAmenity.customQuantity,
    unitSF: customAmenity.customSF,

    // since customAmentities don't have a corresponding space unit, just pass in AMENITY circulation type
    unitCircType: CIRCULATION_TYPE.AMENITY
  });

  customAmenity.customNSF = nsf;
  customAmenity.customUSF = usf;
}

function getLargestNumInArrayIndex(array) {
  return array.indexOf(Math.max.apply(Math, array));
}

function roundPercentageTotals(numArr) {
  var total = numArr[0] + numArr[1] + numArr[2];
  var num1Percent = Math.round((numArr[0] / total) * 100);
  var num2Percent = Math.round((numArr[1] / total) * 100);
  var num3Percent = Math.round((numArr[2] / total) * 100);
  var totalPercentage = num1Percent + num2Percent + num3Percent;
  if (totalPercentage != 100) {
      var index = getLargestNumInArrayIndex(numArr);
      numArr[index] = numArr[index] - (totalPercentage - 100);
      return roundPercentageTotals(numArr);
  }
  return {
    me : num1Percent+ '%',
    we : num2Percent + '%',
    amenity : num3Percent + '%'
  }
}

// *************************************


// *************************************
//  Program Part Calculations
// *************************************

function getCirculationTypeFromProgramPart(part) {
  return spaceUnitMap[part.spaceUnitId].circulationType;
}

// for non-APAC programs and programs without customCirculationMap, the circulation percentage for BLENDED program parts is based on area
// totals of ENCLOSED and OPEN program parts
// note that ME and WE program parts have circulation types of either ENCLOSED or OPEN, and SUPPORT program parts may or may not have BLENDED circulation
// in order for BLENDED program parts to include circulation area, the following function needs to be called
//   - when an existing program is loaded
//   - during initial program calculation, after ME and WE program parts are calculated but before SUPPORT program parts are calculated
//   - after a ME or WE program parts get updated
function calculateAndSetBlendedCirculationPercentage(program, updateBlendedSpaces, officeDensity, officeLocation) {
  let blendedCircPercentage = 0;

  // Calculate blended circulation percentage
  if (program) {
    const meAndWeProgramSpaces = [...program[SPACE_UNIT_CATEGORY.ME], ...program[SPACE_UNIT_CATEGORY.WE]];

    if (meAndWeProgramSpaces.length) {
      const nsfTotals = {
        [CIRCULATION_TYPE.ENCLOSED]: 0,
        [CIRCULATION_TYPE.OPEN]: 0
      };

      meAndWeProgramSpaces.forEach((part) => {
        const circulationType = getCirculationTypeFromProgramPart(part);
        nsfTotals[circulationType] += part.customNSF || part.nsf;
      });

      let enclosed = null;
      let open = null;
 
      const { country } = locationUtil.getCityStateCountry(officeLocation);
      let high_circulation_map, standard_circulation_map;
      const circulation_map = COUNTRIES_CIRCULATION_MAP[country];
      if (circulation_map) {
        high_circulation_map = circulation_map['HIGH_CIRCULATION_MAP'];
        standard_circulation_map = circulation_map['STANDARD_CIRCULATION_MAP'];
      } else {
        high_circulation_map = HIGH_CIRCULATION_MAP;
        standard_circulation_map = STANDARD_CIRCULATION_MAP;
      }
      if (officeDensity === 2) {
        enclosed = nsfTotals[CIRCULATION_TYPE.ENCLOSED] * high_circulation_map[CIRCULATION_TYPE.ENCLOSED];
        open = nsfTotals[CIRCULATION_TYPE.OPEN] * high_circulation_map[CIRCULATION_TYPE.OPEN];
      }
      else {
        enclosed = nsfTotals[CIRCULATION_TYPE.ENCLOSED] * standard_circulation_map[CIRCULATION_TYPE.ENCLOSED];
        open = nsfTotals[CIRCULATION_TYPE.OPEN] * standard_circulation_map[CIRCULATION_TYPE.OPEN];
      }

      const totalNSF = nsfTotals[CIRCULATION_TYPE.ENCLOSED] + nsfTotals[CIRCULATION_TYPE.OPEN];

      blendedCircPercentage = (enclosed + open) / totalNSF;


    }
  }



  // Set blended circulation percentage on local programMetrics
  sharedProgramCalculator.updateProgramMetrics({ blendedCircPercentage });

  // If requested, update existing BLENDED spaces
  // NOTE: all BLENDED spaces are in the SUPPORT category, but not all SUPPORT spaces are BLENDED
  if (updateBlendedSpaces) {
    const supportSpaces = program[SPACE_UNIT_CATEGORY.SUPPORT];
    if (supportSpaces && supportSpaces.length) {
      supportSpaces.forEach((progPart) => {
        const circulationType = getCirculationTypeFromProgramPart(progPart);
        if (circulationType === CIRCULATION_TYPE.BLENDED) {
          const nsfToUseForUSFCalc = progPart.customNSF || progPart.nsf;
          const updatedUSF = sharedProgramCalculator.calculateUSF(nsfToUseForUSFCalc, circulationType);
          if (progPart.customNSF) {
            progPart.customUSF = updatedUSF;
          } else {
            progPart.usf = updatedUSF;
          }
        }
      });
    }
  }
}

function updateBlendedCircAfterProgramPartUpdate(partCategory, program, officeDensity, officeLocation) {
  if (partCategory === SPACE_UNIT_CATEGORY.ME || partCategory === SPACE_UNIT_CATEGORY.WE) {
    calculateAndSetBlendedCirculationPercentage(program, true, officeDensity, officeLocation);

  }
}

function calculateMeProgramPart(partWithMultiplier) {
  const spaceUnit = spaceUnitMap[partWithMultiplier.spaceUnitId];
  const programPart = sharedProgramCalculator.calculateMeProgramPart({
    multiplier: partWithMultiplier.multiplier,
    unitSF: spaceUnit.sf,
    unitCircType: spaceUnit.circulationType
  });
  programPart.spaceUnitId = partWithMultiplier.spaceUnitId;
  return programPart;
}

function calculateWeProgramPart(partWithMultiplier, groupSeats, profileType) {
  const spaceUnit = spaceUnitMap[partWithMultiplier.spaceUnitId];
  const roundUpUnitIds = {
    [PROFILE_TYPE.US_SMALL]: ['6', '7', '8', '9', '13', '14']
  };
  let programPart;
  if (roundUpUnitIds[profileType] && _.includes(roundUpUnitIds[profileType], partWithMultiplier.spaceUnitId)) {
    const quantity = Math.ceil((partWithMultiplier.multiplier * groupSeats) / spaceUnit.seats);
    const { nsf, usf } = sharedProgramCalculator.calculateMeSharedFocusWeSupportProgramPartNSFAndUSF({
      quantity,
      unitSF: spaceUnit.sf,
      unitCircType: spaceUnit.circulationType
    });
    programPart = { quantity, nsf, usf };
  } else {
    programPart = sharedProgramCalculator.calculateWeProgramPart({
      multiplier: partWithMultiplier.multiplier,
      unitSeats: spaceUnit.seats,
      unitSF: spaceUnit.sf,
      unitCircType: spaceUnit.circulationType
    });
  }

  programPart.spaceUnitId = partWithMultiplier.spaceUnitId;
  return programPart;
}

function calculateAmenityProgramPart(partWithMultiplier) {
  const spaceUnit = spaceUnitMap[partWithMultiplier.spaceUnitId];
  const programPart = sharedProgramCalculator.calculateAmenityProgramPart({
    multiplier: partWithMultiplier.multiplier,
    unitSF: spaceUnit.sf,
    unitCircType: spaceUnit.circulationType,
    unitMinSF: spaceUnit.minSF,
    unitMaxSF: spaceUnit.maxSF
  });
  programPart.spaceUnitId = partWithMultiplier.spaceUnitId;
  return programPart;
}

function calculateSupportProgramPart(partWithMultiplier, headcount, expectedDailyHeadcount, sharingPopulation) {
  const spaceUnit = spaceUnitMap[partWithMultiplier.spaceUnitId];
  let programPart;
  if (alternateQuantityFunction[partWithMultiplier.spaceUnitId]) {
    const quantity = alternateQuantityFunction[partWithMultiplier.spaceUnitId]({
      headcount,
      expectedDailyHeadcount,
      sharingPopulation,
      multiplier: partWithMultiplier.multiplier
    });
    const { usf, nsf } = sharedProgramCalculator.calculateMeSharedFocusWeSupportProgramPartNSFAndUSF({
      quantity,
      unitSF: spaceUnit.sf,
      unitCircType: spaceUnit.circulationType
    });
    programPart = { quantity, nsf, usf };
  } else {
    programPart = sharedProgramCalculator.calculateSupportProgramPart({
      multiplier: partWithMultiplier.multiplier,
      unitSF: spaceUnit.sf,
      unitCircType: spaceUnit.circulationType
    });
  }
  programPart.spaceUnitId = partWithMultiplier.spaceUnitId;
  return programPart;
}

function calculateCustomAmenityProgramPart(dataKey) {
  const suggestedAmenitySpaceUnit = _.find(suggestedCustomAmenityUnitMap, ['dataKey', dataKey]);
  if (!suggestedAmenitySpaceUnit) return null;

  const { quantity, nsf, usf } = sharedProgramCalculator.calculateAmenityProgramPart({
    multiplier: suggestedAmenitySpaceUnit.multiplier,
    unitSF: suggestedAmenitySpaceUnit.sf,
    unitCircType: suggestedAmenitySpaceUnit.circulationType,
    unitMinSF: suggestedAmenitySpaceUnit.minSF,
    unitMaxSF: suggestedAmenitySpaceUnit.maxSF
  });

  return {
    dataKey,
    displayName: suggestedAmenitySpaceUnit.displayName,
    quantity: 0,
    nsf: 0,
    usf: 0,
    customQuantity: quantity,
    customSF: nsf,
    customNSF: nsf,
    customUSF: usf,
    displayUnit: suggestedAmenitySpaceUnit.displayUnit
  };
}
// *************************************


// *************************************
//  Program Space Totals Calculations
// *************************************

function updateProgramWithSFTotals(program) {
  program.areaTotals = sharedProgramCalculator.calculateCategoryUSFTotals({
    meSpaces: program[CATEGORY.ME],
    weSpaces: program[CATEGORY.WE],
    amenitySpaces: [...program[CATEGORY.SUPPORT], ...program[CATEGORY.AMENITY], ...program[CATEGORY.CUSTOM_AMENITY]]
  });
  let areaTotals = program.areaTotals;
  let obj={
  meSF: areaTotals.totalCustomMeSF? areaTotals.totalCustomMeSF : areaTotals.totalMeSF, 
  weSF: areaTotals.totalCustomWeSF? areaTotals.totalCustomWeSF : areaTotals.totalWeSF, 
  amenitySF: areaTotals.totalCustomAmenitySF? areaTotals.totalCustomAmenitySF : areaTotals.totalAmenitySF
  }
  program.areaPercentage = roundPercentageTotals([obj.meSF, obj.weSF, 
    obj.amenitySF]);
}
// *************************************


// *************************************
//  Program Final Stats Calculations
// *************************************

function updateProgramWithFinalStats(program, headcount) {
  let spaceUnit;

  // Calculate actualWorkSeatTotal by summing total OPEN and ENCLOSED quantities
  // actualWorkSeatTotal might differ from program.metrics.workSeats (the original, recommended number of work seats)
  // this discepency might be due to
  //   - rounding errors
  //   - user customization
  let openTotal = 0;
  let enclosedTotal = 0;
  program[CATEGORY.ME].forEach((space) => {
    spaceUnit = spaceUnitMap[space.spaceUnitId];
    if (spaceUnit.circulationType === CIRCULATION_TYPE.OPEN) {
      openTotal += (space.customQuantity || space.quantity);
    } else if (spaceUnit.circulationType === CIRCULATION_TYPE.ENCLOSED) {
      enclosedTotal += (space.customQuantity || space.quantity);
    }
  });
  const actualWorkSeatTotal = openTotal + enclosedTotal;

  // Count validCollabSeats (total seats in enclosed WE spaces with max 18 seats)
  let validCollabSeats = 0;
  program[CATEGORY.WE].forEach((space) => {
    spaceUnit = spaceUnitMap[space.spaceUnitId];
    if (spaceUnit.circulationType === CIRCULATION_TYPE.ENCLOSED && spaceUnit.seats <= 18) {
      validCollabSeats += (space.customQuantity || space.quantity) * spaceUnit.seats;
    }
  });

  program.finalStats = {
    collabSeatRatio: validCollabSeats / actualWorkSeatTotal,
    openPercentage: openTotal / actualWorkSeatTotal,
    sharingRatio: actualWorkSeatTotal / headcount,
    usfPerSeat: (program.areaTotals.totalCustomSF || program.areaTotals.totalSF) / actualWorkSeatTotal
  };
}
// *************************************


// *************************************
//  Shared Program Calculator Init
// *************************************
function initSharedProgramCalculator(program, headcount, officeLocation, officeDensity) {
  // Initialize sharedProgramCalculator module
  sharedProgramCalculator.init({
    officeLocation,
    getCirculationTypeFromProgramPart: part => getCirculationTypeFromProgramPart(part),
    headcount: headcount * program.metrics.planningPopulation, // expected daily headcount
    workSeats: program.metrics.workSeats,
    groupSeats: program.metrics.groupSeats,
    officeDensity: officeDensity
  });
}
// *************************************


// *************************************
//  Final Program Calculation
// *************************************

function calculateProgram(
  profileId,
  profileType,
  { headcount, collaboration, daysPerWeekInOffice, officeDensity }, // The "3 key assumptions" for a program that the user can edit
  customAmenitiesAdded = [], // if there are custom amenities added and the program is recalculated, keep them around
  officeLocation
) {
  // Define program structure
  const program = {
    [CATEGORY.ME]: [],
    [CATEGORY.WE]: [],
    [CATEGORY.SUPPORT]: [],
    [CATEGORY.AMENITY]: [],
    [CATEGORY.CUSTOM_AMENITY]: [],
    finalStats: {},
    metrics: {}
  };


  // Get profile-related information
  const profile = profileMap[profileId];
  const { me, we, support, amenity } = profile.kitOfPartsWithMultipliers[profileType];

  // Calculate metrics
  const planningPopulation = calculatePlanningPopulation(daysPerWeekInOffice);
  const sharingPopulation = calculateSharingPopulation(daysPerWeekInOffice, profileType);
  const expectedDailyHeadcount = headcount * planningPopulation;

  const workshare = calculateWorkshare();
  const groupshare = profile.collaborationLevels[profileType][collaboration]; // "we seats" per member of planning population

  const workSeats = calculateWorkSeats(headcount, expectedDailyHeadcount, sharingPopulation, workshare, profileType);
  const groupSeats = calculateGroupSeats(expectedDailyHeadcount, groupshare);

  program.metrics = { planningPopulation, sharingPopulation, workshare, groupshare, workSeats, groupSeats };

  // Initialize sharedProgramCalculator module
  initSharedProgramCalculator(program, headcount, officeLocation, officeDensity);

  // Iterate through each cateogry in KOPWM to calculate and add program part
  let programPart;
  me.forEach((partWithMultiplier) => {
    programPart = calculateMeProgramPart(partWithMultiplier);
    program[CATEGORY.ME].push(programPart);
  });

  we.forEach((partWithMultiplier) => {
    programPart = calculateWeProgramPart(partWithMultiplier, groupSeats, profileType);
    program[CATEGORY.WE].push(programPart);
  });
  calculateAndSetBlendedCirculationPercentage(program, null, officeDensity, officeLocation);

  amenity.forEach((partWithMultiplier) => {
    programPart = calculateAmenityProgramPart(partWithMultiplier);
    program[CATEGORY.AMENITY].push(programPart);
  });

  support.forEach((partWithMultiplier) => {
    programPart = calculateSupportProgramPart(partWithMultiplier, headcount, expectedDailyHeadcount, sharingPopulation);
    program[CATEGORY.SUPPORT].push(programPart);
  });

  customAmenitiesAdded.forEach((custom) => {
    programPart = calculateCustomAmenityProgramPart(custom.dataKey);

    // update initial states for the custom ones that are added and come from the suggestion list
    if (programPart) {
      programPart.id = custom.id;
      program[CATEGORY.CUSTOM_AMENITY].push(programPart);
      // just keep the ones the user made up exactly as they are
    } else {
      program[CATEGORY.CUSTOM_AMENITY].push(custom);
    }
  });

  updateProgramWithSFTotals(program);

  updateProgramWithFinalStats(program, headcount);
  let areaTotals = program.areaTotals;
  program.areaPercentage = roundPercentageTotals([areaTotals.totalMeSF, areaTotals.totalWeSF, areaTotals.totalAmenitySF]);
  return program;
}
// *************************************


// *************************************
//  updateProgramCalculations
// *************************************

function updateSFTotalsAndStats(spaceData) {
  updateProgramWithSFTotals(spaceData.program);
  updateProgramWithFinalStats(spaceData.program, spaceData.assumptions.headcount);
}

function updateProgramForMeWeOrSupportCustomQuantity(spaceData, spaceUnitId, customQuantity, officeLocation) {
  const program = spaceData.program;
  const spaceUnit = spaceUnitMap[spaceUnitId];
  const programPart = _.find(program[spaceUnit.category], { spaceUnitId });

  // Update the program part with new quantity, NSF, and USF
  const { nsf, usf } = sharedProgramCalculator.calculateMeSharedFocusWeSupportProgramPartNSFAndUSF({
    quantity: customQuantity,
    unitSF: spaceUnit.sf,
    unitCircType: spaceUnit.circulationType
  });
  programPart.customQuantity = customQuantity;
  programPart.customNSF = nsf;
  programPart.customUSF = usf;

  updateBlendedCircAfterProgramPartUpdate(spaceUnit.category, program, spaceData.assumptions.officeDensity, officeLocation);

  updateSFTotalsAndStats(spaceData);
}

function updateProgramForAmenityCustomSF(spaceData, spaceUnitId, customSF) {
  const spaceUnit = spaceUnitMap[spaceUnitId];
  const category = spaceUnit.category;

  // Update the program part with the custom SF
  // NOTE: we allow users to set a customSF that is outside of the spaceUnit's minSF/maxSF
  const programPart = _.find(spaceData.program[category], { spaceUnitId });
  programPart.customSF = customSF;
  programPart.customNSF = customSF;
  programPart.customUSF = sharedProgramCalculator.calculateUSF(customSF, spaceUnit.circulationType);

  updateSFTotalsAndStats(spaceData);
}

function addCustomAmenity(spaceData, customAmenity) {
  // Update custom amenity with USF and NSF
  calculateCustomAmenitySF(customAmenity);

  spaceData.program[CATEGORY.CUSTOM_AMENITY].push(customAmenity);

  updateSFTotalsAndStats(spaceData);
}

function updateProgramForCustomAmenity(spaceData, id, updateProperties) {
  // Find the custom amenity
  const customAmenity = _.find(spaceData.program[CATEGORY.CUSTOM_AMENITY], ['id', id]);

  // Update with new properties
  Object.assign(customAmenity, updateProperties);

  // Update custom amenity with USF and NSF
  calculateCustomAmenitySF(customAmenity);

  updateSFTotalsAndStats(spaceData);
}

function removeProgramPart(spaceData, idToRemove) {
  const spaceUnit = spaceUnitMap[idToRemove];
  if (spaceUnit) {
    _.remove(spaceData.program[spaceUnit.category], ['spaceUnitId', idToRemove]);
  } else {
    _.remove(spaceData.program[CATEGORY.CUSTOM_AMENITY], ['id', idToRemove]);
  }

  updateSFTotalsAndStats(spaceData);
}

module.exports = {
  calculateProgram,
  calculateCustomAmenityProgramPart,
  updateProgramForMeWeOrSupportCustomQuantity,
  updateProgramForAmenityCustomSF,
  updateProgramForCustomAmenity,
  addCustomAmenity,
  removeProgramPart,
  initSharedProgramCalculator,
  roundPercentageTotals
};

