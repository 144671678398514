module.exports = {

  isCBREEmployee(userRole) {
    // everything but 'cbreProfessional' is deprecated
    return (userRole === 'cbreProfessional' || userRole === 'cbreWorkplace' || userRole === 'cbreOther' || userRole === 'cbreLeasingProfessional');
  },

  isCBREClient(userRole) {
    return userRole === 'cbreClient';
  }

};
