module.exports = {

  // AboutPageHero
  aboutPageHeroImgAlt: 'Ilustración de personas moviéndose a una oficina en la página de Acerca de Spacer de CBRE',
  aboutPageHeroTitle: '¿Qué puede hacer Spacer por usted?',

  // AboutPageContent
  aboutPageContentEndSectionTitle: '¿Listo para empezar?',
  aboutPageProgramPreviewImgAlt: 'Previsualización de resultados de la página del programa de espacios desde la página de inicio de Spacer de CBRE',
  aboutPageVideoTitle: 'Cómo funciona',


  // About Page Content (aboutPageContent.js)
  aboutPageContent1Title: 'Defina la oficina perfeta',
  aboutPageContent1Text: 'El espacio de oficinas perfecto le puede ayudar a contratar talento competitivo, mantener los empleados saludables, reducir costos operativos o crear condiciones para la siguiente gran idea. Spacer es el lugar perfecto para iniciar cualquier decisión inmobiliaria porque le ayuda a priorizar sus metas como organización y a ver recomendaciones de espacio basadas en esas metas. Spacer fue creado con la experiencia del equipo de trabajo de CBRE, un grupo con más de veinte años de experiencia ayudando compañías a planear la oficina perfecta, combinado con una herramienta tecnológica gratuita al alcance de todos.',
  aboutPageContent1ImgAlt: 'Ilustración de personas hablando en la página acerca de Spacer, creada por CBRE',
  aboutPageSpacerSupportLink: 'spacersupport@cbre.com',

  aboutPageContent2Title: 'Spacer es fácil de usar',
  aboutPageContent2List1: 'Comience respondiendo un cuestionario de 17 preguntas sobre los objetivos y valores de su organización. Estas respuestas nos informarán el perfil de su oficina donde lo categorizaremos.',
  aboutPageContent2List2: 'Reciba una lista recomendada de espacio según tipo y tamaño que satisfagan sus necesidades específicas, también conocido como programa espacial.',
  aboutPageContent2List3: 'Visualice un espacio similar al suyo en 2D y 3D con Planos del Piso',
  aboutPageContent2List4: 'Guarde su programa para usarlo mientras busca la oficina perfecta en el mercado',
  aboutPageContent2List5: 'Deje que un profesional de CBRE busque un espacio de oficina que coincida con su programa. ¡Un servicio gratuito!',
  aboutPageContent2Text: 'Si tiene más preguntas sobre cómo Spacer puede impulsar su proceso de búsqueda de oficinas, contáctenos en ',
  aboutPageContent2ImgAlt: 'Ilustración de una mujer trabajando en un escritorio en la página Acerca de Spacer creador por CBRE'
};
