module.exports = {

  // ResultsHeader
  resultsHeaderExportPDFButtonText: 'Export PDF',
  resultsHeaderSendButtonText: 'Send results',
  resultsHeaderClipboardBoxDescription: 'Anyone with a link can view and edit results',
  resultsHeaderContactButtonText: 'Connect with an adviser',

  // EmailWallSection
  emailWallTitle: 'There’s more waiting for you',
  emailWallSubtitle: 'Enter your name and email below to view a complete space recommendation and customise your results.',
  emailWallFirstNamePlaceholder: 'First Name',
  emailWallLastNamePlaceholder: 'Last Name',
  emailWallEmailPlaceholder: 'Your email',
  emailWallCBREEmailPlaceholder: 'Your CBRE professional’s email (optional)',
  emailWallLeasingProfessionalPlaceholder: 'Your CBRE leasing professional',
  emailWallConnectMeText: 'Connect me with a personal real estate adviser. No email newsletters or spam, we promise!',
  emailWallContactNoticeText: 'The Spacer team might contact you to help you get the most out of your programme. No spam, we promise!',
  emailWallResultsButtonText: 'View complete results',
  emailWallImgAltText: 'Preview of a results page space programme from the email wall of Spacer by CBRE.',
  emailWallFirstNameInvalid: 'Please enter your first name.',
  emailWallLastNameInvalid: 'Please enter your last name.',
  emailWallEmailInvalid: 'Please enter a valid email address.',

  // CopyToClipboardBox
  clipboardBoxCopyText: 'Copy',
  clipboardBoxCopiedText: 'Copied',

  // FormModal
  formModalFindSpaceHeader: 'Start the office search',
  formModalFindSpaceBody: 'Spacer is a quick and easy tool for workplace analysis, but if you need something more customised, let CBRE Workplace help. Fill out this form, and a Workplace professional will reach out to you within 48 hours to schedule a time to speak.',
  formModalFindSpaceSuccessMessage: 'Your request was sent.',

  // SuccessModal
  successModalTitle: 'Success!',

  // ContactForm
  contactFormFirstNameLabel: 'First Name',
  contactFormLastNameLabel: 'Last Name',
  contactFormEmailLabel: 'Work Email',
  contactFormPhoneLabel: 'Phone Number',
  contactFormMessageLabel: 'Tell us more about what you’re looking for',
  contactFormSubmitText: 'Submit',

  // AcceptTermsText
  acceptTermsText: 'I accept the <1>Terms and Conditions</1>. For more information on how your data will be used, please refer to the <3>CBRE Privacy Policy</3>.',
  privacyPolicyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Form
  formErrorText: 'Oops, that didn’t go through. Please try again!',
  formEmailInvalid: 'Please enter a valid email address.',

  // NextStepsSection
  nextStepsSectionSubtitle: 'Ready to take the next step?',
  nextStepsSectionTitle: 'Find the perfect office.',
  nextStepsSectionButtonText: 'Connect with an adviser',

  // Profile
  resultsProfileSummaryTitle: 'Your office —',
  resultsProfileTooltipText: 'Each profile is a composite of real organisations and workplaces, and the details were checked against industry guidelines and market knowledge unique to CBRE Workplace.',

  // Program
  programSaveConfirmationBoxText: 'You’re all set. We emailed a link to your results. Enjoy!',
  programExamplePlansTitle: 'Example Plans',
  programExamplePlansSubtitle: 'Play with interactive floor plans that match your profile. You can even explore them in 3D!',
  programTooMuchTinkerWarning: 'Whoa there! Looks like you’re out of the recommended range based on your profile. Consider <1>retaking the quiz</1>, or contacting support at <3>{{email}}</3>.',
  programDisclaimer: 'Spacer by CBRE is provided “as is“ and “as available“ with no warranties. All results generated from your use of Spacer are for illustrative purposes only, and do not reflect actual space recommendations or suggested lease terms. Actual space recommendations may vary greatly and will depend on many factors which Spacer does not take into account. By using Spacer or any report generated by the use of Spacer, you accept our Terms of Service and Privacy Policy located respectively at the urls https://workplace.cbre.com/termsOfService and https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy. © 2018 CBRE, Inc. All rights reserved.',
  programMetricsTitle: 'Programme Metrics',
  programMetricsSubtitle: 'These numbers summarise your work environment',
  programDetailsSectionTitle: 'Customise Your Programme',
  programDetailsSectionSubtitle: 'Adjust the fields below to fine-tune your space needs.',
  programRevertButtonText: 'Revert',
  programSaveButtonText: 'Save Changes',
  programSavedButtonText: 'Saved',

  // ProgramAssumptions
  programAssumptionsRecommendedOfficeSizeText: 'Recommended Office Size',
  programAssumptionsProfileText: 'Profile',
  programAssumptionsHeadcountText: 'Headcount',
  programAssumptionsHeadcountFollowingText: 'people',
  programAssumptionsCollabText: 'Collaboration',
  programAssumptionsAvgDaysText: 'Average days in office',
  programAssumptionsAvgDaysFollowingText: 'per week',
  programAssumptionsAvgDaysPerWeekText: 'Average days in office per week',

  // ProgramHeroForPrint
  printNIAText: 'Net Internal Area',
  printUSFText: 'Usable Square Feet',
  printRSFText: 'Rentable Square Feet',
  printDescText: 'Based on your details, and a {{lossFactorPercentage}}% {{factorType}} in {{city}}, we recommend a total of-',
  printFitFactorText: 'fit factor',
  printLossFactorText: 'loss factor',

  // BarChart
  barChartIndivSubtitle: 'Individual Space',
  barChartGroupSubtitle: 'Group Space',
  barChartAmenitySubtitle: 'Amenity Space',

  // SpaceCategoryBreakdown
  spaceCatBreakdownSubtitle: 'Select a space type to customise',

  // SpaceCategoryDropdown
  spaceCatDropdownMeText: 'Individual Space',
  spaceCatDropdownWeText: 'Group Space',
  spaceCatDropdownAmenityText: 'Amenity Space',

  // SidebarInfo
  sidebarInfoMeTitle: 'Individual',
  sidebarInfoWeTitle: 'Group',
  sidebarInfoAmenityTitle: 'Amenities',

  // Space Category Descriptions (spaceCategoryDescription.js)
  spaceCatMeDesc: 'Space for individual work. Offering choice in where and how your employees work leads to greater employee satisfaction.',
  spaceCatWeDesc: 'Space for collaboration and teaming up. In an era when work can happen anywhere, it’s important to provide a variety of spaces to suit a variety of tasks.',
  spaceCatAmenityDesc: 'Space that supports work. Great amenities will make your office somewhere your employees — and recruits — look forward to spending their time.',

  // Suggested Custom Amenities (suggestedCustomAmenityUnitMap.js)
  audioVisualCustom: 'Audio / Visual',
  dataCenterCustom: 'Data Center',
  buildingSupportCustom: 'Building Support Services',
  foodServiceCustom: 'Full Food Service',
  kitchenCustom: 'Kitchen',
  serveryCustom: 'Servery',
  execDiningCustom: 'Executive Dining',
  grabNGoCustom: 'Grab-N-Go',
  juiceCoffeeBarCustom: 'Juice Bar / Coffee Bar',
  childCareCustom: 'Child Care',
  fitnessCenterCustom: 'Fitness Center',
  healthCenterCustom: 'Health Center',
  mailCenterCustom: 'Mail Center',
  commercialPrintCustom: 'Commercial Print Room',
  centralCafeCustom: 'Central Café',
  securityCtrlCustom: 'Security Control Room',
  auditoriumCustom: 'Auditorium',
  dryCleaningLaundryCustom: 'Dry Cleaning / Laundry',
  confCenterCustom: 'Conference Center',
  multiPurpTrainingCustom: 'Multi-Purpose / Training',
  informalCollabCustom: 'Informal Collaboration',
  touchdownTeamCustom: 'Touchdown / Team',

  // Suggested Custom Amenity Units (spaceUnit.js)
  seats: 'seat',
  seats_plural: 'seats',
  rooms: 'room',
  rooms_plural: 'rooms',
  areas: 'area',
  areas_plural: 'areas',
  units: 'unit',
  units_plural: 'units',

  // Predefined Amenities (spaceUnitMap.js) - Shared Space Units
  focusRoomDefined: 'Focus Room',
  fileRoomDefined: 'File Room',
  centralReceptionHubDefined: 'Central Reception Hub',
  smOfficeDefined: 'Small Office',
  regOfficeDefined: 'Regular Office',
  benchDesk5Defined: '5’ Bench Desk',
  benchDesk6Defined: '6’ Bench Desk',
  meetingRoom4Defined: 'Meeting Room (4 people)',
  meetingRoom6Defined: 'Meeting Room (6 people)',
  meetingRoom8Defined: 'Meeting Room (8 people)',
  meetingRoom12Defined: 'Meeting Room (12 people)',
  meetingRoom15Defined: 'Meeting Room (15 people)',
  semiEnclosedMeetingBooth4Defined: 'Semi-Enclosed Meeting Booth (4 people)',
  openCollabSpace4to6Defined: 'Open Collaboration Space (4-6 people)',
  contemplationSpaceDefined: 'Contemplation Space',
  mainITEquipRoomDefined: 'Main IT Server / Equipment Room',
  receptionAreaDefined: 'Reception Area',
  cafeFoodServiceDefined: 'Café / Food Service',
  teamStorageDefined: 'Team Storage',
  coatClosetsDefined: 'Coat Closets',
  genStorageDefined: 'General Storage (stores and goods in/out)',
  centralisedCopyPrintMailDefined: 'Centralised Copy, Print & Mail',
  specialResourceAreaDefined: 'Special Resource Area',
  personalStorageDefined: 'Personal Storage (lockers)',
  storageITSpaceDefined: 'IT Storage Space',
  secondaryServerRoomDefined: 'Secondary Server / Telecom Room',
  mothersRoomDefined: 'Mothers’ Room',
  buildITSpaceDefined: 'IT Build Space',
  workerCouncilOffice2Defined: 'Worker Council Office (2 people)',
  securityCtrlRoomDefined: 'Security Control Room',
  centralisedMailDefined: 'Centralised Mail',
  visitorCoatLuggageRoomDefined: 'Visitor Coat / Luggage Room',
  wellnessRoomDefined: 'Wellness Room',
  internalStairDefined: 'Internal Stair',
  commercialPrintRoomDefined: 'Commercial Print Room',
  buildingSupportServicesAreaDefined: 'Building Support Services Area',

  // Predefined Amenities (spaceUnitMap.js) - US_SMALL and US_REGULAR
  officeDefined: 'Office',
  lgOfficeDefined: 'Office (large)',
  smWorkstationDefined: 'Workstation (small)',
  mdWorkstationDefined: 'Workstation (medium)',
  lgWorkstationDefined: 'Workstation (large)',
  huddleRoomDefined: 'Huddle Room',
  dispersedCopyPrintAreaDefined: 'Dispersed Copy / Print Area',
  smMeetingRoomDefined: 'Small Meeting Room',
  mdMeetingRoomDefined: 'Medium Meeting Room',
  lgMeetingRoomDefined: 'Large Meeting Room',
  xlMeetingRoomDefined: 'X-Large Meeting Room / Boardroom',
  multiPurpTrainingDefined: 'Multi-Purpose / Training',
  informalCollabDefined: 'Informal Collaboration Area',
  touchdownTeamDefined: 'Touchdown / Team Area',
  pantryVendingCoffeeAreaDefined: 'Pantry / Vending / Coffee Area',
  workplaceStorageRoomDefined: 'Workplace Storage Room',
  employeeCoatAreaDefined: 'Employee Coat Area',
  dispersedMailAreaDefined: 'Dispersed Mail Area',
  lockerUnitDefined: 'Locker Unit',
  serverRoomDefined: 'Server Room',
  frontDeskAreaDefined: 'Front Desk Area',
  centralCafeAreaDefined: 'Central Café Area',
  mailCenterAreaDefined: 'Mail Center Area',
  kitchenAreaDefined: 'Kitchen Area',
  drawerLateralFileUnitDefined: '3 Drawer Lateral File Unit',
  serveryAreaDefined: 'Servery Area',
  juiceBarCoffeeShopAreaDefined: 'Juice Bar / Coffee Shop Area',
  diningSupportAreaDefined: 'Dining Support Space Area',
  diningAreaDefined: 'Dining Area',
  grabNGoAreaDefined: 'Grab-N-Go Area',
  focusRoomSharedFocus: 'Focus Room',

  // Predefined Amenities (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDefined: 'Full Food Service',

  // Predefined Amenities (spaceUnitMap.js) - APAC_REGULAR
  officeWorkMeetDefined: 'Office (Work/Meet)',
  lgOfficeWorkMeetDefined: 'Large Office (Work/Meet)',
  focusDefined: 'Focus',
  bench1pt6Defined: '1.6 Bench',
  bench1pt8Defined: '1.8 Bench',
  bench1pt8LDefined: '1.8 ‘L’ Workstation',
  banquetWorkMeetDefined: 'Banquet - Work/Meet (2p)',
  cafeTable4Defined: 'Café Table (4p)',
  kitchenTable8to10Defined: 'Kitchen Table (8-10p)',
  standingWhiteboardDefined: 'Standing Whiteboard',
  huddleRoom3Defined: 'Huddle Room (3p)',
  smMeetingRoom5Defined: 'Sm Meeting Room (5p)',
  mdMeetingRoom8Defined: 'M Meeting Room (8p)',
  lgMeetingRoom20Defined: 'L Meeting Room (20p)',
  xlBoardroom: 'X-Large / Boardroom',
  wellnessRoomsDefined: 'Wellness Rooms',
  employeeCoatsGymLockersDefined: 'Employee Coats / Gym Lockers',
  lockersDefined: 'Lockers',
  dispersedCopyPrintScanAreaDefined: 'Dispersed Copy / Print / Scan Area',
  hydrationPointDefined: 'Hydration Point (Water / Tea / Coffee)',
  centralHighDensityStorageDefined: 'Central High Density Storage',
  wellnessMothersRoomDefined: 'Wellness / Mothers’ Room',
  serverHubRoomsDefined: 'Server / Hub Rooms',
  buildITStoreRoomDefined: 'IT Build / Store Room',
  simpleReceptionWaitDefined: 'Simple Reception + Wait',
  centralCafeSocialHubDefined: 'Central Café Social Hub',
  mailCentreDefined: 'Mail Centre',
  utilityStoreRoomDefined: 'Utility Store Room',
  drawerLateralFileDefined: '3 Drawer Lateral File',
  juiceBarCoffeeShopDefined: 'Juice Bar / Coffee Shop',
  buildingSupportServicesDefined: 'Building Support Services',

  // Space Unit Map Descriptions (spaceUnitMap.js) - Shared Descriptions
  focusRoomDesc: 'Focus Rooms are enclosed spaces intended to support focused and/or confidential work for typically one or two people. Acoustic privacy is paramount in these spaces. Focus Rooms are usually unassigned and can be booked temporarily on an as-needed basis for relevant work.',
  fileRoomDesc: 'This is paper file storage for individuals and teams. As a result of most offices digitization efforts, the need for both individual and team file storage is diminishing greatly. Still, this is a central space for legally required document copies or long term project materials, especially with most open office desks containing little storage.',
  centralReceptionHubDesc: 'The reception area is your company’s first opportunity to make an impression on employees, clients and other visitors. It should consistently convey the company’s brand and project the desired appearance. The concierge desk should comfortably house a concierge; soft-seating should be inviting to visitors.',
  officeDesc: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy.',
  benchDeskDesc: 'Bench desks are open individual work seats. Smaller bench desks can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open bench desks are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open bench desks have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
  meetingRoomDesc: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
  meetingRoomDesc6: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
  meetingRoomDesc8: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
  meetingRoomDesc12: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
  meetingRoomDesc15: 'Meeting rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
  openCollabSpace4to6Desc: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients. ',
  cafeFoodServiceDesc: 'The central cafe is intended to be an area for eating, social interaction, individual work and small meetings. Functional eating furniture and a branded color palette should be considered in the design of this space.',
  coatClosetsDesc: 'Employees need a place to store their coats and other bulky items, especially when an office has an open layout with limited personal storage at the desk. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
  copyPrintDesc: 'Spread across the floor in open workspace, copy/print areas give employees quick access to multi-function devices from all neighborhoods. Bins for recycling should always be collocated with copy/print areas, as well as small cabinets to house extra paper and supplies. These spaces are for drop-in purposes only and are not intended to double as social/work areas.',
  specialResourceBuildingSupportAreaDesc: 'This is a catch all for additional support spaces that might occur in an office. One could be a security desk or facilities while another could be a utilities space. This varies across organisations, but as the size of the office increases, ancillary spaces like this become more likely and necessary.',
  secondaryServerRoomDesc: 'Secondary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
  mothersRoomDesc: 'Mothers rooms are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Mothers rooms are a usually a requirement for all new offices, regardless of size or headcount.',
  visitorCoatLuggageRoomDesc: 'Visitors need a place to store their coats and other bulky items. These should be dispersed throughout the office, perhaps located by exits and along main corridors. There is usually a shelf about the hanging portion for hats and other equipment with storage or suitcases at the base of the closet.',
  wellnessRoomDesc: 'Wellness rooms (formerly referred to as Mother’s Rooms) are intended to support extremely private individual time—whether for nursing mothers or any individuals seeking respite from the office for a short interval. Wellness rooms are a usually a requirement for all new offices, regardless of size or headcount, and should contain a lounge chair, a refrigerator, and ideally a sink.',
  internalStairDesc: 'Internal stairs can be activated stairwells in the core that provide access between floors, or it can be additional feature elements that connect floors of an office. Can often be a gather space within the office and can be centralised for cafe, reception, or meeting spaces.',
  commercialPrintRoomDesc: 'Often combined with the mailroom, companies provide in house capabilities to print, bind, and compile presentations in the print room. There will be several printers, and might include large format printers. Spaces will include storage space for supplies, and will also include counter space for smaller equipment but also space to layout prints.',
  pantryVendingCoffeeDesc: 'Unlike the central cafe, the pantry/vending/coffee area is meant for employees to grab the quick food and drink essentials need. Buzz and conversation is naturally found in areas with food, but these spaces usually do not contain seating causing people to come and go quickly within spending an extending amount of time socializing.',
  storageDesc: 'Storage rooms are intended to hold miscellaneous items such as large equipment, extra furniture, bulk storage, holiday supplies, etc. They can also be used for visitor coat and luggage storage. There should be small storage located near the concierge for convenience.',
  centralisedDispersedMailAreaDesc: 'For larger companies, a mail center is required for organizing incoming and outgoing mail. These space hold specialty production and mail equipment. There are also many surfaces for organizing paper and other materials. Usually manned by a full time staff member.',
  lockerDesc: 'Lockers are provided as another form of individual storage. They give employees a space to lock their valuables, personal effects and working files. Lockers may be assigned to individuals or used on a first come, first served basis.',
  mainITandServerRoomDesc: 'Primary Server (technology) room to house and secure IT equipment and to terminate low voltage cabling. The rooms need to have 24 hour air and needs to be at a constant 72 Degrees Fahrenheit with 50% relative humidity.',
  drawerLateralFileDesc: 'Personal storage for individuals at desks or offices. Contents are usually personal or active project materials.',
  juiceBarCoffeeShopDesc: 'While a company might not have a full kitchen and servery, it may provide a coffee shop or juice bar as part of the food/drink amenities in the space. An organisation might also have this kind of space in addition to its main dining services, especially if the office is large. This space will likely look like your local Starbucks with a working area for a staffer person separated by a serving counter.',
  grabNGoDesc: 'A small convenience area with several fridges, shelves for chips and snacks, and a checkout machine. These Grab-N-Go spaces are typically monitored for security by camera. These spaces are often combined with a small pantry with fridges and freezers and prep area for food.',
  smMeetingConferenceRoomDesc: 'A small conference room is an enclosed meeting space intended to comfortably accommodate 2-6 people at the table. These rooms can be used for both internal and client-facing meetings and should therefore be very user friendly with easy access to power and A/V capabilities.',
  mdMeetingConferenceRoomDesc: 'A medium conference room is an enclosed meeting space intended to comfortably accommodate 6-10 people at the table. These rooms are used for both internal and client-facing meetings and should therefore be very user friendly with easy access to power and A/V capabilities.',
  lgMeetingConferenceRoomDesc: 'A large conference room is an enclosed meeting space intended to comfortably accommodate 12-14 people at the table, overflow seating along the perimeter of the room is desirable. These rooms can be used for both internal and client-facing meetings and are likely to be used for presentations. Furniture should be movable to allow the space to be used for other purposes, such as yoga classes for employees.',
  xlMeetingRoomDesc: 'A boardroom conference room is intended to comfortably accommodate 14-18 people at the table, with overflow seating along the perimeter of the room for at least 10 more. These rooms will most likely be used for large internal formal meetings and large client-facing meetings. As such, they should be equipped with robust A/V technology and should be branded as client facing-space.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - US_SMALL and US_REGULAR
  lgOfficeDesc: 'Offices are private enclosed individual work spaces for employees. They are great for reducing distraction and where focus work can take place. Offices are typically equipped with a desk and work seat along with guest seats for visitors. You will find varying amounts of personal storage and filing in offices. The size of the space can also vary depending on activities that take place in the room (e.g. regular meetings) or employee status/hierarchy. This slightly larger office might be a partner office or might double as a huddle room.',
  smWorkstationDesc: 'Workstations are open individual work seats. Smaller workstations can be used by full time employees either temporarily (in a free address space) or permanently. These desks are also great for visiting employees or contractors who are not at the office all the time. Some advantages of open workstations are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open workstations have plenty of focus and huddle rooms. Filing at the desk is limited with communal filing likely located throughout the floor.',
  mdWorkstationDesc: 'Workstations are open individual work seats. The benefits of a slightly larger space includes more desk storage and privacy. This likely means the larger a desk, the most likely it is to assigned instead of free address. Some advantages of open workstations are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open workstations have plenty of focus and huddle rooms. You will likely find additional communal storage and filing located throughout the floor.',
  lgWorkstationDesc: 'Workstations are open individual work seats. The benefits of a slightly larger space includes more desk storage and privacy. Desks this size are most likely assigned instead of free address. Some advantages of open workstations are the office visibility and inherent collaboration. One disadvantage can be the noise distraction, which is why it is recommended that offices with open workstations have plenty of focus and huddle rooms. You will likely find additional communal storage and filing located throughout the floor.',
  huddleRoomDesc: 'Huddle Rooms are enclosed spaces intended to support small group meetings of 2-4 people. They should be equipped with A/V equipment and white boards to facilitate knowledge sharing and should be acoustically private (people sitting immediately outside of the room should not be able to hear conversations going on inside).',
  multiPurpTrainingDesc: 'Multi-purpose rooms are meant to accommodate a large number of people (20+). This space is appropriately named since many different activities can happen here including training, company events, and large presentations. The fit and finish of this space is functional/durable with many people coming and going but client facing as well. This space must have the appropriate A/V support - typically much more than the average conference room.',
  informalCollabDesc: 'These areas are meant to support small groups (4-6 person) collaborative activities such as brainstorming and informal conversations between colleagues and with clients. ',
  touchdownTeamDesc: 'Touchdowns are individual or small internal team work spaces that are spread throughout the office for employee and visitor use. Touchdowns can be a variety of heights, shapes and sizes but are meant to stand out from individual workstations as a unique place to work for a short period of time. These should be plug-and-play spaces: easy to find and use.',
  frontDeskAreaDesc: 'This is first stop when employees and visitors come to the office. The desk should be able to accommodate at least one person with person workspace and storage. There will likely be vistor storage nearby as well. The front desk is placed within the larger reception hub where there will likely be seating and other accommodations.',
  mailCenterAreaDesc: 'For larger organisations, a mail center is required to centralise all mail services. This will include large sorting and organisation space, along with space for charts and equipment. This space likely looks very different for each organisation since it is often fit between other programme items.',
  kitchenAreaDesc: 'For spaces that will have an in-office dining service, a kitchen is required to support this amenity. The requirements of a kitchen will vary from organisation to organisation, but you can expect to find cooking appliances and refrigerator storage. There will also be dry goods and equipment storage. The kitchen will be adjacent to the servery and likely the conferencing/client amenities center.',
  serveryAreaDesc: 'The servery is the area where people get their food. It is likely connected to the kitchen and a dining area with tables and seating. The planning of a servery is very precise to manage queues and traffic. This size and shape of this space will likely vary between organisations.',
  diningSupportAreaDesc: 'An organisation that has robust dining services will likely have additional support spaces like storage in other parts of the building that need to be accounted for in the overall programme. The space will vary between organisations.',
  diningAreaDesc: 'Dining near kitchen should provide a variety of choice for people to sit and eat lunch. Providing several different types of seating also allows the space to be utilised for meetings and collaboration outside of lunch time. Providing soft and hard seating along with bar and seated height settings gives users choice.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDesc: 'When providing an in-office dining service, there are multiple functions that come together to support this amenity including a kitchen, servery, dining room (seating), and dining support (storage). The kitchen and servery work together to prepare and serve the food made in-house. Dining is the place where employees and guests can sit and eat but often this space acts as a social and collaboration hub. Just like at home, there is a lot of equipment and food storage required to provide dining services, which is why dining support is included in the Full Food Service space type.',

  // SpaceUnitRow
  spaceUnitRowDeleteTitle: 'Delete Space Type',
  spaceUnitRowDeleteText: 'Are you sure you want to delete this space type?',
  spaceUnitRowConfirmDeleteText: 'Yes, delete',
  spaceUnitRowCancelDeleteText: 'No, keep it',

  // CustomAmenityAdder
  customAmenityAddText: 'Add an amenity',
  customAmenityPlaceholder: 'Amenity type',

  // SpaceUnitTotals
  spaceUnitTotalsTitleNIA: 'Total Net Internal Area',
  spaceUnitTotalsTitle: 'Total {{sfTypeAdj}} Square {{unitCapitalized}}',
  spaceUnitTotalsCirculation: 'Circulation',
  spaceUnitTotalsCirculationTooltip: 'The "space between spaces." Corridors, the space behind your chair, the space around cabinets and doors, and around corners. Without circulation, you would have nowhere to walk! Usually comprises around 35% of the total Usable Square {{unitCapitalized}} in your programme, but this varies depending on your profile and ultimate building selection.',
  spaceUnitTotalsREBNY: 'REBNY Factor',
  spaceUnitTotalsREBNYTooltip: 'For programmes in NYC, we increase {{sfType}} by 15% to account for the REBNY measurement standard. Space measured per the REBNY measurement standard includes bathrooms, elevator lobbies, and the thickness of exterior walls (among other things).',
  spaceUnitTotalsLossFactor: 'Loss Factor ',
  spaceUnitTotalsLossFactorTooltip: 'Usable square {{unit}} is how much space you have in your office. Rentable square {{unit}} is the space you pay for, which can include columns, lobbies, and other common areas. The difference between usable and rentable is called the loss factor, and how you calculate it varies by location.',

  // CollaborationDropdown
  collabDropdownLevelLowText: 'a little (0-25%)',
  collabDropdownLevelMedText: 'some (26-50%)',
  collabDropdownLevelHighText: 'most (51-100%)',

  // ExamplePlans
  examplePlanButtonText: 'View Example Plan',
  examplePlanFullFloorText: 'full floor',
  examplePlanPartialFloorText: 'partial floor',
  examplePlanFullImgAlt: '{{size}} square {{unit}} floor plan showing a {{profileName}} profile layout from Spacer by CBRE',
  examplePlanPartialImgAlt: '{{size}} square {{unit}} partial floor plan showing a {{profileName}} profile layout from Spacer by CBRE',
  examplePlanTooltipText: 'If you are larger than {{size}} {{unit}}, you might need more than one floor.',

  // ProgramStats
  programStatsDensitySubtitleTsubo: 'Net Tsubo',
  programStatsDensitySubtitle: '{{adjective}} Square {{unitUppercase}}',
  programStatsIndivTitle: 'Individual Seats',
  programStatsWorkstationsSubtitle: 'Workstations',
  programStatsOfficesSubtitle: 'Offices',
  programStatsCollabTitle: 'Collaboration',
  programStatsEnclosedSubtitle: 'Enclosed Group Seats',
  programStatsIndivSeatSubtitle: 'Individual Seat',
  programStatsSeatsTitle: 'Seats Per Person',
  programStatsIndivSeatsSubtitle: 'Individual Seats',
  programStatsPersonSubtitle: 'Person',
  programStatsDensityTitle: 'Density',
  programStatsFullDensitySubtitle: '{{density}} per Individual Seat',

  // Profile Display Names (profileNames.js)
  efficientName: 'The Optimiser',
  whiteCollarName: 'The Accommodator',
  whiteGloveName: 'The Enhancer',
  openOfficeName: 'The Producer',
  workplace360Name: 'The Connector',
  campusName: 'The Differentiator',
  startupName: 'The Incubator',
  engCreativeName: 'The Creator',

  // Profile Descriptions (profileDescriptions.js)
  efficientDesc: 'The Optimiser office is a no-nonsense, no-frills environment with a "get the work done" mindset. Open work environments are focused on enabling individuals with all the required tools at their desk. Basic support spaces are provided and collaboration spaces are more traditional in nature - by way of enclosed rooms. True to its name, these environments are all about maximizing efficiency.',
  whiteCollarDesc: 'The Accommodator office provides all the essentials while incorporating flexibility for future change. Space offerings tend to be standardised with dual functionality built in. In this environment, meeting rooms and private office footprints are interchangeable, larger meeting spaces are built with adaptability and cafe-style spaces designed with collaboration in mind. As the name suggests, this profile is all about change with minimal impact.',
  whiteGloveDesc: 'The Enhancer office caters deeply to the needs of employees, as well as clients, who are frequent visitors of the workplace. The space offering provides a range of foundational work settings to suit activity based working. Most staff and spaces are technology enabled allowing ease of movement in and out of the office. Whether a formal agile programme is in place or not, this office environment sets the stage for flexible working now and in the future.',
  openOfficeDesc: 'The Producer office is all about giving people the foundational spaces required based on the type of activity happening in the office. A variety of spaces are expected in this environment, including individual, group and support spaces which are geared towards both focused and collaborative workmodes. As it allows for the most efficient use of space, flexible working and desk sharing are commonly seen accompanying this style of office.',
  workplace360Desc: 'As the name states, the Connector office is designed to support meaningful connections. Planning with a neighbourhood concept in mind, all support spaces and amenities are close at hand. We see a higher population of alternative worksettings and spaces to collaborate, which are strategically located to support serendipitous connections. Employees are empowered to work where and how they need in order to perform at their best.',
  campusDesc: 'The Differentiator office makes it hard to leave at the end of the day. That’s because it thrives on providing a work experience that is above and beyond the rest. You will find enhanced amenities around wellness or food to help save employees time and feel comfortable. Employees are encouraged to take breaks and relax while in the workplace.',
  startupDesc: 'Speed and ideation are the name of the game when it comes to the Incubator office. Employees work in highly collaborative teams and think like owners. This office environment is constantly changing, which means most of the spaces have flexibility built-in. Moving furniture and make-shift solutions are common. A mixture of fun social activities and intense work sessions are used to build camaraderie as a team.',


  // Profile Key Traits: Shared Names (profileTraitMap.js)
  social: 'Social',
  efficient: 'Efficient',
  flexible: 'Flexible',
  enabled: 'Enabled',
  productive: 'Productive',
  inclusive: 'Inclusive',
  clientFocused: 'Client-Focused',
  standardized: 'Standardised',

  // Profile Key Traits (profileTraitMap.js)
  social1Desc: 'Relationships in and out of the office are important',
  social2Desc: 'Many community building events',
  social3Desc: 'Employees like to spend time together',
  social4Desc: 'More open and community driven',
  efficient1Desc: 'Company stays lean and budget conscious',
  efficient2Desc: 'Company stays lean and productive',
  flexible1Desc: 'People have choice in how they work',
  flexible2Desc: 'Employees have a lot of freedom',
  flexible3Desc: 'Build the space you need',
  enabled1Desc: 'Employees have what they need at their desks',
  enabled2Desc: 'Employees have robust services and amenities',
  enabled3Desc: 'People can get what they need to do their jobs',
  enabled4Desc: 'Have access to the right tech and equipment',
  enabled5Desc: 'Specialised equipment is available',
  productive1Desc: 'Functional and intuitive office design',
  productive2Desc: 'Business oriented and financially conscious',
  productive3Desc: 'Centered around unique product/service offering',
  inclusive1Desc: 'Multiple types of space available',
  inclusive2Desc: 'You can find everything you need at the office',
  inclusive3Desc: 'Many different styles of working are acceptable',
  clientFocused1Desc: 'First impressions with clients mean everything',
  clientFocused2Desc: 'Office is welcoming for clients and new recruits',
  clientFocused3Desc: 'Office is polished for clients and new recruits',
  standardized1Desc: 'Processes and systems are streamlined',
  standardized2Desc: 'Organisational norms around space allocation',
  standardized3Desc: 'Modular sizes for flexibility',
  standardized4Desc: 'Streamlined processes and deliverables',

  // SpaceSummary
  spaceSummaryBarHeader: 'Recommended Office Size',
  spaceSummaryMeasurementTypeText: 'measurement type',
  spaceSummaryUnitTypeText: 'units',
  spaceSummaryLossFactorSectionLabel: '{{factor}} Details',
  spaceSummaryFitFactorText: 'Fit Factor',
  spaceSummaryLossFactorText: 'Loss Factor',
  spaceSummaryRSFLossFactorHelperText: 'Based on a {{percentage}}% loss factor for real estate in {{place}}, you should search for spaces between {{min}} and {{max}} rentable square {{units}}.',
  spaceSummaryNIAFitFactorHelperText: 'Based on a {{percentage}}% fit factor for real estate in {{place}}, you should search for spaces with {{amt}} NIA of space.',
  spaceSummaryNIATooltipText: 'Net Internal Area (NIA) is the usable area within a building measured to the internal face of the perimeter walls at each floor level with certain specified areas excluded.',

  // SFTypeRadioInput
  sfRadioInputUsableAreaTooltipText: 'This measurement includes all rooms, furniture and circulation space. It’s sometimes referred to as carpetable area.',
  sfRadioInputRentableAreaTooltipText: 'This is typically the Usable Square {{unitUppercase}} plus a portion of the building’s common spaces. Though you can’t use all of it, it’s the square {{unit}} that you pay for when you rent.',
  sfRadioInputUsableSquareText: 'Usable Square {{unitUppercase}}',
  sfRadioInputRentableSquareText: 'Rentable Square {{unitUppercase}}',

  //floored plan request block
  flooredTitle: 'Don\'t leave us just yet!',
  flooredSubTitle: 'Redefine how clients explore space',
  flooredSubText: 'See your Spacer results in a floor plan and tour them in 3d!',
  flooredParagraphText1: 'Immersive experience allows tenants to imagine possibilities, experiment with space, and turn ideas into reality.',
  flooredParagraphText2: 'Tangible, customised vision for every possible space',
  flooredVideoHeading: 'Watch a sample experience',
  flooredFreeText: 'What\'s more, it is free!',
  flooredIconText1: 'Share available floor plans',
  flooredIconSubText1: 'AutoCAD files for pdfs',
  flooredIconText2: 'Allow processing time',
  flooredIconSubText2: 'One to two weeks',
  flooredIconText3: 'Receive sharable experience',
  flooredIconSubText3: 'Custom 2D and 3D experiences',


  // MeasurementSystemRadioInput
  measurementSystemSqFeetText: 'Sq feet',
  measurementSystemSqMetersText: 'Sq meters',
  measurementSystemTsuboText: 'Tsubo',

  // ProfileHero
  resultsProfileHeroAnnouncementTitle: 'Your office is',
  resultsProfileHeroImgAlt: '3D floor plan showing the office layout of the {{profileName}} profile from Spacer by CBRE',

  // ProfileComparison
  profileCompModalTitle: 'Change Your Profile?',
  profileCompModalText: 'You will lose any unsaved changes on your current space programme.',
  profileCompModalConfirmText: 'Yes, change profile',
  profileCompModalCancelText: 'No, keep current profile',
  profileCompNavbarCancelButtonText: 'Cancel',
  profileCompNavbarUseButtonText: 'Use This Profile',
  profileCompCompareTitle: 'Compare Your Closest Profile Matches',

  // ProfileComparisonColumn
  profileCompColumnSelectedText: 'Selected Profile',
  profileCompColumnSelectText: 'Select',
  profileCompColumnRecommendedText: ' (Recommended)',
  profileCompColumnImgAltText: '3D floor plan showing the office layout of the {{profileName}} profile from Spacer by CBRE'

};
