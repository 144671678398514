module.exports = {
  // blogHomeTitle: no translation yet,

  // blog tags
  // blogTagAllArticles: no translation yet,
  // blogTagSizeAndFit: no translation yet,
  // blogTagOfficeCulture: no translation yet,
  // blogTagSpaceAndDesign: no translation yet,
  // blogTagWorkplaceTrends: no translation yet,
};
