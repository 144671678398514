const React     = require('react');
const PropTypes = require('prop-types');

function QuestionText(props) {
  return (
    <h2 className="title question-text">
      {props.text}
    </h2>
  );
}

QuestionText.propTypes = {
  text: PropTypes.string.isRequired
};

module.exports = QuestionText;
