const React = require('react');
const PropTypes = require('prop-types');
const sharedPropTypes = require('../../../shared/components/sharedPropTypes');
const constants = require('wp-constants').spacerFree;
const DropdownSelectionInput = require('../form/DropdownSelectionInput.jsx');
const { withTranslation } = require('react-i18next');

const optionOrder = [
    constants.calculator.DENSITY_LEVEL.SAME,
    constants.calculator.DENSITY_LEVEL.LOW,
    constants.calculator.DENSITY_LEVEL.NOT_SURE
];

function DensityDropdown(props) {
    const dropdownOptions = {
        [constants.calculator.DENSITY_LEVEL.SAME]: { text: props.t('sameDensityText') },
        [constants.calculator.DENSITY_LEVEL.LOW]: { text: props.t('lowerDensityText') },
        [constants.calculator.DENSITY_LEVEL.NOT_SURE]: { text: props.t('notSureDensityText') }
    };

    return (
        <DropdownSelectionInput
            options={dropdownOptions}
            optionOrder={optionOrder}
            {...props}
        />
    );
}

DensityDropdown.propTypes = {
    value: sharedPropTypes.stringOrNumber.isRequired,
    onValueChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isLarge: PropTypes.bool,
    t: PropTypes.func.isRequired
};

DensityDropdown.defaultProps = {
    disabled: false,
    isLarge: false
};

module.exports = withTranslation('resultsPage')(DensityDropdown);

