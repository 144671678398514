const React              = require('react');
const PropTypes          = require('prop-types');
const sharedPropTypes    = require('../../../shared/components/sharedPropTypes');
const { Navigate }       = require('react-router-dom');
const ErrorPage          = require('../../../spacerFree/components/common/ErrorPage.jsx');
const LoadingPage        = require('../../../shared/components/common/LoadingPage.jsx');
const ResultsHeader      = require('./ResultsHeader.jsx');
const Footer             = require('../common/Footer.jsx');
const Profile            = require('../containers/results/profile/Profile.jsx');
const Program            = require('../containers/results/program/Program.jsx');
const DocumentData       = require('../common/DocumentData.jsx');
const constants          = require('wp-constants').spacerFree;
const { withTranslation } = require('react-i18next');
const { withRouter } = require('../../../shared/components/common/withRouter.jsx');

class Results extends React.Component {
  constructor(props) {
    super(props);
    // If we have an id, we need to fetch the completed program from the server
    if (props.params.id) {
      props.fetchProgramIfNeeded(props.params.id);
    }

    this.onUnload = this.onUnload.bind(this);
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
    this.props.onPageUnload();
  }

  onUnload(event = {}) {
    const WARNING_MESSAGE = this.props.t('resultsWarningMessage');
    if (this.props.showWarningOnPageUnload) {
      event.returnValue = WARNING_MESSAGE;
      return WARNING_MESSAGE;
    }
    return undefined;
  }

  render() {
    if (this.props.fetchProgramErrorStatus) return <ErrorPage errorCode={this.props.fetchProgramErrorStatus} />;

    const shouldRedirect = this.props.isProgramEmpty && !this.props.params.id;
    const readyForRender = !this.props.params.id || (this.props.params.id && !this.props.isFetchingProgram && !this.props.isProgramEmpty);

    if (shouldRedirect) return <Navigate to="/" />;
    if (!readyForRender) return <LoadingPage />;

    return (
      <div className="results">
        <DocumentData
          siteLocation={constants.siteLocation.PAGE.RESULTS}
        />
        <ResultsHeader
          programId={this.props.params.id}
          openModal={this.props.openModal}
        />

        <main>
          <Profile t={this.props.t} />
          <Program
            showEmailWall={!this.props.params.id}
          />
        </main>

        <Footer />
      </div>
    );
  }
}

Results.propTypes = {
  isProgramEmpty: PropTypes.bool.isRequired,
  fetchProgramIfNeeded: PropTypes.func.isRequired,
  params: sharedPropTypes.routeMatchShape.isRequired,
  isFetchingProgram: PropTypes.bool.isRequired,
  showWarningOnPageUnload: PropTypes.bool.isRequired,
  onPageUnload: PropTypes.func.isRequired,
  fetchProgramErrorStatus: PropTypes.number,
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

Results.defaultProps = {
  fetchProgramErrorStatus: null
};

module.exports = withTranslation('resultsPage')(withRouter(Results));
