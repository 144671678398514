const { combineReducers } = require('redux');
const profileQuiz         = require('./profileQuiz');
const spaceData           = require('./spaceData');
const userData            = require('./userData');
const requests            = require('./requests');
const modal               = require('./modal');
const changeDetected      = require('./changeDetected');
const analytics           = require('../../util/analytics');


// Special Case Reducers
const fullStateReducer            = require('./specialCaseReducers/fullStateReducer');
const spaceDataActions            = require('../actions/spaceData');
// const globalActions               = require('../actions/global');
import globalActions from '../actions/global';

const combinedReducers = combineReducers({
  profileQuiz,
  spaceData,
  userData,
  requests,
  modal,
  changeDetected
});

// Here we define which actions need to utilize special case reducers for
// specific branches of the state tree
function crossSliceReducer(state, action) {
  switch (action.type) {
    case spaceDataActions.SET_PROFILE_AND_CALCULATE_PROGRAM:
    case spaceDataActions.UPDATE_KEY_ASSUMPTION: {
      return fullStateReducer(state, action);
    }

    case globalActions.SEND_HEAP_EVENT: {
      analytics.sendHeapEvent(action.eventName, state);
      return state;
    }

    case globalActions.SEND_HEAP_EVENT_WITHOUT_PROPS: {
      analytics.sendHeapEventWithoutProps(action.eventName);
      return state;
    }

    default: return state;
  }
}

module.exports = (state, action) => {
  const intermediateState = combinedReducers(state, action);
  const finalState = crossSliceReducer(intermediateState, action);
  return finalState;
};
