const React              = require('react');
const PropTypes          = require('prop-types');
const { withTranslation } = require('react-i18next');

function QuizExitSlide(props) {
  return (
    <div className="quiz-exit-slide">
      <div className="columns is-centered">
        <div className="column is-half">
          <figure className="image is-4by3">
            <img src="/svg/illustrations/quiz/quiz-two-people-high-five-spacer-by-cbre.svg" alt={props.t('quizExitImgAlt')} />
          </figure>
        </div>
      </div>
      <div className="loader light" />
      <h1 className="subtitle is-4 has-margin-bottom-3 has-margin-top-3 has-margin-bottom-3-mobile has-text-primary-invert has-text-centered">
        {props.t('quizExitSubtitle')}
      </h1>
    </div>
  );
}

QuizExitSlide.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('quizPage')(QuizExitSlide);
