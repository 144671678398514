/*
   Use Notes: The Trans component
   The Trans component is somewhat confusingly formatted. (Reading the i18next v9 documentation for the component is strongly suggested.)
   Using the Trans component necessitates two steps: making a key-value pair (the translation) in a namespace file, and using the Trans component on the string that will be tokenized.
   The Trans component is to be preferred to props.t when the translated string has multiple components or formatting: ex. components within the string, <strong> or <u> tags, and so forth.
   When enclosed by the Trans component, the string is essentially delimited by inner components or formatting, starting with index 0:
   For example, "Well, <strong>hello</strong>, world!" is "split into" three segments: 0: "Well, ", 1: "hello", 2: ", world!"
   In the namespace file, you would have a key-value pair something similar to: key: "Well, <1>hello</1>, world!"
   In the Trans component, all that is important is that the structure of the string maintains the delimiting structure.
   So you could write: <Trans i18nKey="key">Text0<strong>Text1</strong>Text2</Trans>"
   And then i18next will make substitutions based on the locale. So, for the English namespace, we will have:
   "Text0" is substituted with "Well, ", "Text1" with "hello", and "Text2" with ", world!"
   What is most important to remember is that the text itself in the Trans component is not important. Our Trans component could also be
   <Trans i18nKey="key">Unicorn<strong>Rainbow</strong>Flowers</Trans>" and the tokenization would still work, as the essential "delimiting structure" is still maintained.
   The convention used here is to make the Trans component text numbers, for ease of reading;
   For example, following our example above, the Trans component would look like this:
   <Trans i18nKey="key">0<strong>1</strong>2</Trans>
   For a working example of this, see TermsOfService.jsx.
   Alternately, find a copy of the documentation located at (at the time of this writing) https://react.i18next.com/legacy-v9/trans-component.
*/

/* Use Notes: Pluralization
   i18next has this really nifty feature that handles pluralization. Here's how you do it:
   1. Pass the t function a "count" variable
   2. In the namespace file, include both "token" and "token_plural"
   i18next will automatically decide whether or not to use "token" or "token_plural" depending on the value of quantity!
   For example, let's say you have the plaintext "cat", which you might want to be "cats" sometimes.
   You'd have something like this:
   t('catText', { count }) // where "count" is a variable that tracks the quantity [of cats]
   catText: 'cat', catText_plural: 'cats'
   And then pluralization will be set up! When quantity is 1, it will say "cat"; otherwise, it will say "cats".
   See a woirking example of this in CustomAmenityUnitRow.jsx.
   For more information, also consult the documentation at https://www.i18next.com/translation-function/plurals.
*/

/* Use Notes: withTranslation
   withTranslation is a Higher-Order Component HOC that passes the t function into a component. It wraps module.exports and in so doing, designates which namespace file the component should refer to.
   For example, if LandingPageHero's exports line says "module.exports = withTranslation('landingPage')(LandingPageHero)", this means that by default, LandingPageHero will look for translations in the
   "landingPage" namespace.
   If you want to refer to multiple namespaces within a component, you can specify which namespace a given token should be found in. So, continuing our example above, if LandingPageHero also has a token
   whose translation is found in the "common" namespace, you can use the following notation to designate this:
      props.t('common:someToken');
   A good example of this can be seen at SpaceCategoryBreakdownMobile, line 66.
*/

const i18n                   = require('i18next').default;
const { initReactI18next } = require('react-i18next');
const domainDetector         = require('./util/domainDetector.js');

// English namespace files
const landingPageEN   = require('../../../public/locales/en/landing/landingPage.js');
const commonEN        = require('../../../public/locales/en/common/common.js');
const resultsPageEN   = require('../../../public/locales/en/results/resultsPage.js');
const termsPageEN     = require('../../../public/locales/en/terms/termsOfServicePage.js');
const quizEN          = require('../../../public/locales/en/quiz/quizPage.js');
const aboutPageEN     = require('../../../public/locales/en/about/aboutPage.js');
const referralsPageEN = require('../../../public/locales/en/referrals/referrals.js');
const blogPagesEN     = require('../../../public/locales/en/blog/blogPages.js');
const blogArticle20190621EN = require('../../../public/locales/en/blog/blogArticle20190621.js');
const blogArticle20190625EN = require('../../../public/locales/en/blog/blogArticle20190625.js');
const blogArticle20190627EN = require('../../../public/locales/en/blog/blogArticle20190627.js');


// British English namespace files
const landingPageEnGB   = require('../../../public/locales/en-GB/landing/landingPage.js');
const commonEnGB        = require('../../../public/locales/en-GB/common/common.js');
const resultsPageEnGB   = require('../../../public/locales/en-GB/results/resultsPage.js');
const termsPageEnGB     = require('../../../public/locales/en-GB/terms/termsOfServicePage.js');
const quizEnGB          = require('../../../public/locales/en-GB/quiz/quizPage.js');
const aboutPageEnGB     = require('../../../public/locales/en-GB/about/aboutPage.js');
const referralsPageEnGB = require('../../../public/locales/en-GB/referrals/referrals.js');
const blogPagesEnGB     = require('../../../public/locales/en-GB/blog/blogPages.js');


// German Namespace files
const landingPageDE   = require('../../../public/locales/de/landing/landingPage.js');
const commonDE        = require('../../../public/locales/de/common/common.js');
const resultsPageDE   = require('../../../public/locales/de/results/resultsPage.js');
const termsPageDE     = require('../../../public/locales/de/terms/termsOfServicePage.js');
const quizDE          = require('../../../public/locales/de/quiz/quizPage.js');
const aboutPageDE     = require('../../../public/locales/de/about/aboutPage.js');
const referralsPageDE = require('../../../public/locales/de/referrals/referrals.js');
const blogPagesDE     = require('../../../public/locales/de/blog/blogPages.js');

// French Namespace files
const landingPageFR   = require('../../../public/locales/fr/landing/landingPage.js');
const commonFR        = require('../../../public/locales/fr/common/common.js');
const resultsPageFR   = require('../../../public/locales/fr/results/resultsPage.js');
const termsPageFR     = require('../../../public/locales/fr/terms/termsOfServicePage.js');
const quizFR          = require('../../../public/locales/fr/quiz/quizPage.js');
const aboutPageFR     = require('../../../public/locales/fr/about/aboutPage.js');
const referralsPageFR = require('../../../public/locales/fr/referrals/referrals.js');
const blogPagesFR     = require('../../../public/locales/fr/blog/blogPages.js');

// Slovak Namespace files
const landingPageSVK  = require('../../../public/locales/svk/landing/landingPage.js');
const commonSVK       = require('../../../public/locales/svk/common/common.js');
const resultsPageSVK  = require('../../../public/locales/svk/results/resultsPage.js');
const termsPageSVK    = require('../../../public/locales/svk/terms/termsOfServicePage.js');
const quizSVK         = require('../../../public/locales/svk/quiz/quizPage.js');
const aboutPageSVK    = require('../../../public/locales/svk/about/aboutPage.js');
const referralsPageSVK= require('../../../public/locales/svk/referrals/referrals.js');
const blogPagesSVK    = require('../../../public/locales/svk/blog/blogPages.js');

// Poland Namespace files
const landingPagePOL  = require('../../../public/locales/pol/landing/landingPage.js');
const commonPOL       = require('../../../public/locales/pol/common/common.js');
const resultsPagePOL  = require('../../../public/locales/pol/results/resultsPage.js');
const termsPagePOL    = require('../../../public/locales/pol/terms/termsOfServicePage.js');
const quizPOL         = require('../../../public/locales/pol/quiz/quizPage.js');
const aboutPagePOL    = require('../../../public/locales/pol/about/aboutPage.js');
const referralsPagePOL= require('../../../public/locales/pol/referrals/referrals.js');
const blogPagesPOL    = require('../../../public/locales/pol/blog/blogPages.js');

// Hungary Namespace files
const landingPageHUN  = require('../../../public/locales/hun/landing/landingPage.js');
const commonHUN       = require('../../../public/locales/hun/common/common.js');
const resultsPageHUN  = require('../../../public/locales/hun/results/resultsPage.js');
const termsPageHUN    = require('../../../public/locales/hun/terms/termsOfServicePage.js');
const quizHUN         = require('../../../public/locales/hun/quiz/quizPage.js');
const aboutPageHUN    = require('../../../public/locales/hun/about/aboutPage.js');
const referralsPageHUN = require('../../../public/locales/hun/referrals/referrals.js');
const blogPagesHUN    = require('../../../public/locales/hun/blog/blogPages.js');

// Netherlands namespace files
const landingPageNLD   = require('../../../public/locales/nld/landing/landingPage.js');
const commonNLD        = require('../../../public/locales/nld/common/common.js');
const resultsPageNLD   = require('../../../public/locales/nld/results/resultsPage.js');
const termsPageNLD     = require('../../../public/locales/nld/terms/termsOfServicePage.js');
const quizNLD          = require('../../../public/locales/nld/quiz/quizPage.js');
const aboutPageNLD     = require('../../../public/locales/nld/about/aboutPage.js');
const referralsPageNLD = require('../../../public/locales/nld/referrals/referrals.js');
const blogPagesNLD     = require('../../../public/locales/nld/blog/blogPages.js');

// colombia namespace files
const landingPagecCOL   = require('../../../public/locales/col/landing/landingPage.js');
const commonCOL       = require('../../../public/locales/col/common/common.js');
const resultsPageCOL   = require('../../../public/locales/col/results/resultsPage.js');
const termsPageCOL     = require('../../../public/locales/col/terms/termsOfServicePage.js');
const quizCOL          = require('../../../public/locales/col/quiz/quizPage.js');
const aboutPageCOL     = require('../../../public/locales/col/about/aboutPage.js');
const referralsPageCOL = require('../../../public/locales/col/referrals/referrals.js');
const blogPagesCOL     = require('../../../public/locales/col/blog/blogPages.js');

// Links namespace names to their files
const resources = {
  en: {
    landingPage: landingPageEN,
    common: commonEN,
    resultsPage: resultsPageEN,
    termsOfServicePage: termsPageEN,
    quizPage: quizEN,
    aboutPage: aboutPageEN,
    blogPages: blogPagesEN,
    referrals: referralsPageEN,
    blogArticle20190621: blogArticle20190621EN,
    blogArticle20190625: blogArticle20190625EN,
    blogArticle20190627: blogArticle20190627EN
  },
  'en-GB': {
    landingPage: landingPageEnGB,
    common: commonEnGB,
    resultsPage: resultsPageEnGB,
    termsOfServicePage: termsPageEnGB,
    quizPage: quizEnGB,
    aboutPage: aboutPageEnGB,
    blogPages: blogPagesEnGB,

    // todo: add GB versions of blog posts
    // blogArticle20190621: no translation yet,
    // blogArticle20190625: no translation yet,
    // blogArticle20190627: no translation yet

    referrals: referralsPageEnGB
  },
  de: {
    landingPage: landingPageDE,
    common: commonDE,
    resultsPage: resultsPageDE,
    termsOfServicePage: termsPageDE,
    quizPage: quizDE,
    aboutPage: aboutPageDE,
    blogPages: blogPagesDE,

    // todo: add German versions of blog posts
    // blogArticle20190621: no translation yet,
    // blogArticle20190625: no translation yet,
    // blogArticle20190627: no translation yet

    referrals: referralsPageDE
  },
  fr: {
    landingPage: landingPageFR,
    common: commonFR,
    resultsPage: resultsPageFR,
    termsOfServicePage: termsPageFR,
    quizPage: quizFR,
    aboutPage: aboutPageFR,
    blogPages: blogPagesFR,
    referrals: referralsPageFR
  },
  svk: {
    landingPage: landingPageSVK,
    common: commonSVK,
    resultsPage: resultsPageSVK,
    termsOfServicePage: termsPageSVK,
    quizPage: quizSVK,
    aboutPage: aboutPageSVK,
    blogPages: blogPagesSVK,
    referrals: referralsPageSVK
  },
  pol: {
    landingPage: landingPagePOL,
    common: commonPOL,
    resultsPage: resultsPagePOL,
    termsOfServicePage: termsPagePOL,
    quizPage: quizPOL,
    aboutPage: aboutPagePOL,
    blogPages: blogPagesPOL,
    referrals: referralsPagePOL
  },
  hun: {
    landingPage: landingPageHUN,
    common: commonHUN,
    resultsPage: resultsPageHUN,
    termsOfServicePage: termsPageHUN,
    quizPage: quizHUN,
    aboutPage: aboutPageHUN,
    blogPages: blogPagesHUN,
    referrals: referralsPageHUN
  },
  nld: {
    landingPage: landingPageNLD,
    common: commonNLD,
    resultsPage: resultsPageNLD,
    termsOfServicePage: termsPageNLD,
    quizPage: quizNLD,
    aboutPage: aboutPageNLD,
    blogPages: blogPagesNLD,
    referrals: referralsPageNLD
  },
  col: {
    landingPage: landingPagecCOL,
    common: commonCOL,
    resultsPage: resultsPageCOL,
    termsOfServicePage: termsPageCOL,
    quizPage: quizCOL,
    aboutPage: aboutPageCOL,
    blogPages: blogPagesCOL,
    referrals: referralsPageCOL
  },
};

// Custom language detector for backup client-side language (based on URL's ccTLD)
const lngDetector = domainDetector.getBrowserLanguageDetector();

i18n
  .use(initReactI18next)
  .use(lngDetector)
  .init({
    // Resources: Connects i18n instance to the namespaces
    resources,
    // Detection: Specifies detectionOptions (which specifies things like detection order, cache, cookie expiration, etc.)
    detection: domainDetector.detectionOptions,
    // Fallbacklng: language to use in case detector fails
    fallbackLng: 'en',
    // Keyseparator: from documentation - "We do not use keys in form namespace.token"
    keySeparator: false,
    interpolation: {
      // From documentation: "React already safes from xss"
      escapeValue: false
    }
  }, (err) => {
    if (err) console.log('error loading i18n module', err);
  });

module.exports = i18n;
