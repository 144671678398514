const React              = require('react');
const PropTypes          = require('prop-types');
const StepsSummary       = require('../common/StepsSummary.jsx');
const { withTranslation } = require('react-i18next');

function LandingPageContent(props) {
  return (
    <div className="has-padding-3 has-no-padding-mobile container">
      <div className="columns has-no-padding-left-mobile has-no-padding-right-mobile has-no-margin-right-mobile">
        <div className="column">
          <section className="section">
            <div className="title is-4-mobile has-text-primary">{props.t('landingPageContentTitle')}</div>
          </section>
          <StepsSummary />
          {/* TODO: FAQs go here */}
        </div>
      </div>
    </div>
  );
}

LandingPageContent.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('landingPage')(LandingPageContent);
