module.exports = {

  // AboutPageHero
  aboutPageHeroImgAlt: 'Illustratie van mensen die in een kantoor trekken op de pagina Over ons van Spacer by CBRE',
  aboutPageHeroTitle: 'De kracht van Spacer',

  // AboutPageContent
  aboutPageContentEndSectionTitle: 'Klaar om te beginnen?',
  aboutPageProgramPreviewImgAlt: 'Voorbeeld van resultatenpagina ruimteprogramma van de homepage van Spacer by CBRE',
  aboutPageVideoTitle: 'Zo werkt Spacer',


  // About Page Content (aboutPageContent.js)
  aboutPageContent1Title: 'De eerste stap naar hét kantoor – en hybride werken',
  aboutPageContent1Text: "Gewild talent binnenhalen, collega's gezond houden, kosten verlagen en productiviteit verhogen: het perfecte kantoor doet alles voor je. Er hangt veel af van een huisvestingsbeslissing. Gelukkig helpt Spacer je aan een vliegende start. De toegankelijke en kosteloze online tool – ontworpen door het deskundige Workplace-team van CBRE – helpt je de ruimtebehoefte van je organisatie inventariseren, en geeft je kantoor een profiel met uitgebreide data-inzichten, nuttige benchmarks én praktische aanbevelingen. Hier kun je direct mee aan de slag. Bovendien is het de eerste stap op weg naar een toekomstbestendige hybride werkplekstrategie: inzicht over je vastgoed (bricks) is het ideale vertrekpunt om ook de behoeften rond technologie (bytes) en mensen (behavior) in kaart te brengen.",
  aboutPageContent1ImgAlt: 'Illustratie van pratende mensen op de pagina Over ons van Spacer by CBRE',
  aboutPageSpacerSupportLink: 'spacersupport@cbre.com',

  aboutPageContent2Title: 'Zo gebruik je Spacer',
  aboutPageContent2List1: 'Beantwoord 17 vragen over de doelen en waarden van je organisatie. Op basis van je antwoorden, krijgt je kantoor een profiel.',
  aboutPageContent2List2: 'Je krijgt van ons een ruimteprogramma: een lijst met kantoorkenmerken die passen bij je organisatie en behoeften.',
  aboutPageContent2List3: 'Visualiseer je ideale ruimte met 2D- én 3D-plattegronden.',
  aboutPageContent2List4: 'Sla je programma op: zo kun je het gebruiken bij je zoektocht naar een ideaal kantoor en passende hybride werkplekstrategie.',
  aboutPageContent2List5: 'Schakel de expertise in van een professional van CBRE. Deze kan je helpen aan een passende kantoorruimte of je begeleiden naar een toekomstbestendige en breedgedragen hybride werkplekstrategie.',
  aboutPageContent2Text: 'Meer weten over de rol die Spacer kan spelen voor jouw perfecte kantoor? Neem contact met ons op via ',
  aboutPageContent2ImgAlt: 'Illustratie van een jonge vrouw die werkt aan een bureau op de pagina Over ons van Spacer by CBRE'
};
