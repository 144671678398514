const React              = require('react');
const PropTypes          = require('prop-types');
const Clipboard          = require('react-clipboard.js').default;
const { withTranslation } = require('react-i18next');

function CopyToClipboardBox(props) {
  const [copied, setCopied] = React.useState(false);

  let clipboardClasses = 'copy-to-clipboard-box-clipboard';
  let clipboardText = props.t('clipboardBoxCopyText');
  if (copied) {
    clipboardClasses += ' copied';
    clipboardText = props.t('clipboardBoxCopiedText');
  }

  return (
    <div>
      <div className="copy-to-clipboard-box-background" role="button" tabIndex={0} onClick={props.closeCopyToClipboardBox} />
      <div className="copy-to-clipboard-box">
        <label className="copy-to-clipboard-box-label">
          <div className="copy-to-clipboard-box-text" title={props.clipboardBoxDescription}>
            {props.clipboardBoxDescription}
          </div>
          <input
            className="copy-to-clipboard-box-input"
            type="text"
            readOnly
            value={props.textToCopy}
          />
          {
            copied &&
            <div className="copy-to-clipboard-box-checkmark" />
          }
          <Clipboard
            className={clipboardClasses}
            data-clipboard-text={props.textToCopy}
            onClick={
              () => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
              }
            }
          >
            {clipboardText}
          </Clipboard>
        </label>
      </div>
    </div>
  );
}

CopyToClipboardBox.propTypes = {
  closeCopyToClipboardBox: PropTypes.func.isRequired,
  textToCopy: PropTypes.string.isRequired,
  clipboardBoxDescription: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(CopyToClipboardBox);

