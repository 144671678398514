const PropTypes       = require('prop-types');
const sharedPropTypes = require('../../shared/components/sharedPropTypes');

// ******************************************
// QUIZ & PROFILE RESULTS
// ******************************************


// TODO: write custom validator for questionOptionsObject?
const questionOptionsTypes = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.array
]);

const questionUnitShape = PropTypes.shape({
  // required
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,

  // optional
  text: PropTypes.string,
  answerType: PropTypes.string,
  answerFormat: PropTypes.string,
  response: PropTypes.string,
  assumptionType: PropTypes.string,
  userDataProperty: PropTypes.string,
  options: questionOptionsTypes,
  optionOrder: PropTypes.arrayOf(sharedPropTypes.stringOrNumber)
});

const questionAnswerTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.string)
]);

const userQuizAnswersObject = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string)
  ])
);

const userDataForQuizShape = PropTypes.shape({
  role: PropTypes.string,
  industryCode: PropTypes.string
});

const assumptionsForQuizShape = PropTypes.shape({
  collaboration: PropTypes.number,
  daysPerWeekInOffice: PropTypes.number,
  headcount: PropTypes.number
});


// ******************************************
// PROGRAM RESULTS
// ******************************************

const assumptionsShape = PropTypes.shape({
  collaboration: PropTypes.number,
  daysPerWeekInOffice: PropTypes.number,
  headcount: PropTypes.number
});

const areaTotalsShape = PropTypes.shape({
  totalMeSF: PropTypes.number,
  totalWeSF: PropTypes.number,
  totalAmenitySF: PropTypes.number,
  totalSF: PropTypes.number
});

const spaceUnitShape = PropTypes.shape({
  nsf: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  spaceUnitId: PropTypes.string.isRequired,
  usf: PropTypes.number.isRequired
});

const customAmenityUnitShape = PropTypes.shape({
  customNSF: PropTypes.number,
  customQuantity: PropTypes.number,
  customSF: PropTypes.number,
  customUSF: PropTypes.number,
  displayName: PropTypes.string,
  nsf: PropTypes.number,
  quantity: PropTypes.number,
  id: PropTypes.string,
  usf: PropTypes.number
});

const programShape = PropTypes.shape({
  me: PropTypes.array,
  we: PropTypes.array,
  amenity: PropTypes.array,
  support: PropTypes.array,
  finalStats: PropTypes.object,
  metrics: PropTypes.object,
  areaTotalsShape
});

const deltaShape = PropTypes.shape({
  numericDelta: PropTypes.number,
  displayPercentDelta: PropTypes.string
});


module.exports = {
  // quiz & profile results
  userQuizAnswersObject,
  userDataForQuizShape,
  assumptionsForQuizShape,
  questionOptionsTypes,
  questionUnitShape,
  questionAnswerTypes,

  // program results
  assumptionsShape,
  areaTotalsShape,
  spaceUnitShape,
  customAmenityUnitShape,
  programShape,
  deltaShape
};

