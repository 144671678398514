module.exports = {
  getPageSections() {
    return [
      {
        title: 'aboutPageContent1Title',
        text: 'aboutPageContent1Text',
        image: '/svg/illustrations/about-page/about-page-people-talking-spacer-by-cbre.png',
        imageAlt: 'aboutPageContent1ImgAlt'
      },
      {
        title: 'aboutPageContent2Title',
        list: [
          'aboutPageContent2List1',
          'aboutPageContent2List2',
          'aboutPageContent2List3',
          'aboutPageContent2List4',
          'aboutPageContent2List5'
        ],
        text: 'aboutPageContent2Text',
        image: '/svg/illustrations/about-page/about-page-girl-working-spacer-by-cbre.png',
        imageAlt: 'aboutPageContent2ImgAlt',
        addEmailLink: true
      }
    ];
  }
};
