const DETECT_CHANGE_ON_RESULTS = 'DETECT_CHANGE_ON_RESULTS';
const CHANGE_TO_RESULTS_SAVED  = 'CHANGE_TO_RESULTS_SAVED';

module.exports = {

  // Action types

  DETECT_CHANGE_ON_RESULTS,
  CHANGE_TO_RESULTS_SAVED,

  // Action creators

  detectChangeToSaveOnResults() {
    return { type: DETECT_CHANGE_ON_RESULTS };
  },

  changeToResultsSaved() {
    return { type: CHANGE_TO_RESULTS_SAVED };
  }
};
