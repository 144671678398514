module.exports = {

  // TermsOfService
  TermsTitle: 'Szolgáltatási feltételek',
  TermsIntroText: 'Ez egy jogi megállapodás közted és a CBRE Kft. ("CBRE") között a Spacer®-ként ismert alkalmazáshoz való hozzáférés és használata kapcsán. Ezen web alapú alkalmazás információnak vagy szolgáltatásainak a használatával vagy hozzáféréssel azokhoz, elismered, hogy megértetted és beleegyeztél az Általános Szolgáltatási Feltételekbe ("ÁSZF") amelyek itt rögzítésre kerülnek. A "Te", "Tied" vagy "Felhasználó" kifejezések kollektíven arra a személyre vagy jogi entitásra utalnak akinek a nevében az adott személy eljár.',

  TermsLimitationsTitle: 'Korlátozások.',
  TermsLimitationsA: 'A felhasználó megérti és beleegyezik abba, hogy a Felhasználó hozzáférése a Spacer®-hez nem exklúzív, nem allicenszelhető és nem átruházható, szabadon visszavonható és felfüggeszthető vagy megszüntethető bármikor bármely okból a CBRE által.',
  TermsLimitationsB: 'A Felhasználó megérti és beleegyezik, hogy a Spacer®-hez való hozzáférés nem ruház át semmilyen tulajdonjogot a Spacer®  vagy bármely vonatkozó dokumentum kapcsán (beleértve sajtóközlemények, módosítások vagy javítások), minden esetben a CBRE eklúzív tulajdonában marad, beleértve korlátozások nélkül a szerzői jogot azonkívül a csatlakozó szabadalmakat, dizájn szabadalmakat, védjegyeket, termék megjelenést, kereskedelmi titkokat és más tulajdonhoz kapcsolódó jogokat amelyek a vonatkozó vagy nemzetközi konvenciók (együttesen, "Szellemi tulajdonjogok") szabályoznak. Lásd 2. Szekció (Tulajdonjog) lentebb. A CBRE ez úton kifejezetten minden jogot fenntart a Spacer® kapcsán és kifejezetten nem biztosít a Felhasználónak a továbbiakban.',
  TermsLimitationsC: 'Kivéve az itt meghatározottakon kívül vagy a CBRE a Felhasználónak írásban adott kifejezett engedélyével, a felhasználó nem fog (i) módosítani, fordítnai, visszafejteni, visszafordítani, szétszedni, megpróbálni újra összerakni, meghatározni vagy kidertíteni bármilyen forráskódot, alapötletet, mögöttes felhasználó felület technikát vagy algoritmust, vagy a Spacer®-nek bármilyen kivonatát vagy másolatát elkészíteni; (ii) törölni, módosít vagy másol bármilyen szerzői jog, védjegyjog vagy más szellemi tulajdon alá eső részt a Spacer® kapcsán, (iii) hozzárendel, allicenszel, vagy más módon átruház hozzáférést, használati vagy egyéb jogot vagy delegál egyéb kötelezettséget ezen Megállapodás hatálya alatt; vagy (iv) a továbbiak korlátozása nélkül használja a Spacer®-t egy versenytárs termék vagy szolgáltatás megépítésére, vagy más célra azon a legitim használaton üzleti használaton kívül amely a Felhasználó üzletéhez szükséges.',
  TermsLimitationsD: 'A Felhasználó beleegyezik abba, hogy írásben azonnal a CBRE tudomására hozza, ha aSpacer®-t értintő bármely jogosultatlan felhasználás vagy a Szellemi jog bármely megsértése a tudomására jut.',
  TermsLimitationsE: 'Ezen Megállapodás hatálya alatt a Felhasználó beleegyezik abba, hogy folyamodik semmilyen szerzői jogi, védjegy vagy más megjelölés bejegyzésére amely hatással lenne a Szellemi Jogokra és nem nyújt be semmilyen dokumentumot egyetlen kormány számára sem amely hatással lenne a Szellemi Jogokra, beleértve a CBRE tulajdonát a Spacer® kapcsán, a Spacer® védjegyet és az alkalmazás más elemére sem. Spacer® a CBRE kizárólagos védjegye és szolgáltatás jegye.',
  TermsLimitationsF: 'A Felhasználó bemutat és beleegyezik abba, hogy a Felhasználó által megadott adatok vagy anyagok a Spacer®-el kapcsolatban a CBRE számára nem eklúzív, jogdíjmentes, világszerte, visszavonhatatlan, örökké tartó jogokat és felhasználási, lemásolási, módosítási, terjesztési, kivonatolási engedélyt biztosít és a CBRE megítélése szerint megjelenítheti ezt az információt vagy anyagokat. A Felhasználó beleegyezik abba, hogy a Felhasználó felelős a Felhasználó által benyújtott bármely anyagért, beleértve a jogszerűségét, helyességét, eredetiségét vagy szerzői jogát. A Felhasználó által a CBRE-nak adott engedély meghaladja a Felhasználó Spacer®-hez köthető használatát. Bármely a Felhasználó által megadott anyag és információ nem tekinthető bizalmasnak és ily módon is kezelendő a CBRE által és felhasználható a CBRE által a Felhasználó engedélye nélkül minden kötelezettség nélkül.',

  TermsOwnershipTitle: 'Tulajdonlás.',
  TermsOwnershipText: 'A Spacer®, a programozási nyelv, a szoftware és a dokumentáció és minden más anyag vagy tartalom amely a Spacer® vagy egyéb javítás, módosítás, kivonatolás vagy változatás amely a Spacer® kapcsán jön létre és annak minden másolata a CBRE jogtulajdona és csakis a CBRE jogtulajdona marad minden körülmények között, beleértve korlátozások nélkül a szabadalmi, szerzői jogi, védjegy vagy kereskedelmi titkokat a Spacer® kapcsán és javításai, módosításai, kivonatolásai vagy változtatásai kapcsán. A Felhasználó tudomásul veszi, hogy az előbbiekben értendőek a kereskedelmi titkok és információk amelyek bizalmasak és kizárólagosak a CBRE számára és beleegyezik, hogy minden szükségeset megtesz hogy megvédje ezen információk bizalmasságát. A Felhasználó nem adja el, ruházza át, teszi közzé, fedi fel, mutat be vagy más módon elérhetővé teszi a Spacer®-t vagy bármely kapcsolód javítását, módosítását, kivonatát vagy egyéb változatát és annak másolatait sem. Ezen Megállapodás megszűnése nem ad jogalapot arra, hogy bármilyen jogi vagy anyagi orvoslatot nyújtson be a CBRE fele. A kötelezettségek meghaladják ezen Megállapodás megszűnését.',

  TermsTerminationTitle: 'Felmondás; A felmondás hatása; Jogorvoslatok.',
  TermsTerminationA: 'Bármilyen jogorvoslat nélkül a CBRE módosíthajat, korlátozhatja, felfüggesztheti, megszakíthatja vagy megszűntetheti ez a Megállapodást és/vagy a Felhasználó a Spacer®-hez kapcsolódó minden vagy részleges használati jogát, azonnal hatállyal, automatikusa, értesítés vagy értesítés nélkül bármilyen bírósági vagy egyéb folyamodvány nélkül, bármely okból vagy ok nélkül is. A Felhasználó beleegyezik, hogy a CBRE kötelezettség nélkül szolgáltatja a Spacer®-t és a CBRE nem felelős a Felhasználó vagy bármely más fél számára a Spacer®-ből adódó korlátozásokért, felfüggesztésekért, megszakításokért, megszüntetésekért vagy módosításokért.',
  TermsTerminationB: 'A Felhasználó tudomásul veszi, hogy a CBRE számára a következőkben adott kötelezettségei egyediek és nem helyettesíthető természetűek, a jóvátehetetlen veszteség amely a CBRE-t éri nem helyettesíthetőek csupán anyagi kárként és ily módon a CBRE jogosult kártérítésre vagy más anyagi természetű jogorvoslatra (kötvény vagy egyéb biztosíték kibocsátásának kötelezettsége nélkül) abban az esetben, ha a Felhasználó bármilyen szerződésszegést vagy várható szerződésszegést követ el. A Felhasználó visszavonhatatlanul lemond minden jogról hogy kártérítési vagy más anyagi természetű kártérítést követeljen.',

  TermsDisclaimerTitle: 'Felelősség kizárása és a felelősség korlátozása.',
  TermsDisclaimerA: 'Spacer® is provided to User “AS IS” and “AS AVAILABLE” with no warranties.  CBRE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. CBRE DOES NOT WARRANT IN ANY WAY THAT Spacer® WILL MEET USER’S REQUIREMENTS OR ANY PARTICULAR STANDARD. SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO USER. In such event the liability of cbre will be limited to the maximum extent possible under applicable law. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY CBRE, ITS AGENTS OR EMPLOYEES, SHALL CREATE A WARRANTY IN ANY WAY WHATSOEVER. NO WARRANTY IS GIVEN THAT Spacer® OR ANY INFORMATION PROVIDED TO USER ARE ERROR FREE OR THAT THE USE OF Spacer® WILL BE UNINTERRUPTED, UP-TO-DATE, COMPLETE OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, AND NO WARRANTY IS GIVEN THAT ANY OF THE FOREGOING WILL BE CORRECTED. CBRE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OF, OR THE RESULTS FROM THE USE OF, Spacer®.  CBRE SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LOST REVENUE, LOST SAVINGS, COMPUTER INTERRUPTION, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES), WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF CBRE OR ITS REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.',
  TermsDisclaimerB: 'CBRE’S TOTAL LIABILITY FOR DAMAGES FOR ANY CAUSE WHATSOEVER SHALL NOT EXCEED THE FEE PAID BY USER FOR Spacer® DURING THE TWELVE (12) MONTH PERIOD PRIOR TO THE EVENT THAT ALLEGEDLY CAUSED SUCH DAMAGE.  USER AGREES THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO Spacer® OR OTHERWISE RELATED TO THIS AGREEMENT MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.',
  TermsDisclaimerC: 'SOME JURISDICTIONS MAY NOT ALLOW THE LIMITATION ON LIABILITY OR EXCLUSIONS OF LIABILITY SET FORTH ABOVE, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO USER. In such event the liability of CBRE will be limited to the maximum extent possible under applicable law.  The waiver of warranty, exclusive remedies and limited liability set forth above are fundamental elements of the basis for this Agreement between CBRE and USER. CBRE would not be able to provide Spacer® on an economic basis, and would not have entered into this Agreement, without such limitations. These limitations shall survive any termination of this Agreement.',

  TermsIndemnificationTitle: 'Felhasználó azonosítás.',
  TermsIndemnificationText: 'At CBRE’s option and request, User shall, at its own expense, indemnify and hold harmless CBRE and its subsidiaries, affiliates, officers, employees, agents, co-branders, associations, and other partners harmless from and against any losses, costs, damages, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees), arising out of or related to any third-party claim, action or allegation related to or arising from: (a) any transaction or dispute between User and any third party; (b) facts or alleged facts that would constitute a breach of any of User’s representations, warranties, or covenants under this Agreement or of any applicable law, rule or regulation, whether or not referenced herein; (c) any User materials, information or content submitted in or on Spacer®; (d) User’s violation of any rights of any third party, (e) User’s use or misuse of Spacer®; or (f) the negligence or willful misconduct of User.  CBRE shall have the right to exclusively direct and control its defense and hire counsel of its choice, at User’s expense.',

  TermsGeneralTitle: 'Általános.',
  TermsGeneralA: '<0>Teljes Megállapodást/Nincs Önkéntelen lemondás.</0> Ez a Megállapodás alkotja a teljes megállapodást a CBRE és a Felhasználó között amely a Spacer® illeti és mindent ami ennek a Megállapodásnak a tárgya, és hatálytalanít minden korábbi megállapodást a CBRE és Felhasználó között amely Spacer®-re vonatkozik, ha volt is ilyen. A CBRE mulasztása, hogy érvényét szerezzen a Megállapodás bármely rendelkezésének nem minősül lemondásnak arról a jogról.',
  TermsGeneralB: '<0>Közlések.</0> A Közlések írásos formában kell, hogy megszülessenek és esetleg e-mail-ben vagy postai úton, illetve a Spacer® honlapján www.cbre.com/spacer. A Felhasználó közléseket a Spacer® számára a spacersupport@cbre.com e-mail címen tehet meg, másolattal ajánlott levélküldeményként a CBRE számára a 400 S. Hope Street, 25th Floor, Los Angeles, CA 90071, Címzés: Jogtanácsosi Iroda. Közlések amennyiben e-mailben érkeznek egy (1) munka napos határidővel kezelendőek (kivéve ha a küldő értesítést kap arról, hogy az levél nem került kézbesítéstre) vagy 3 munkanapos határidővel amennyiben a CBRE website-on kerül közlésre (a CBRE esetében).',
  TermsGeneralC: '<0>Alkalmazandó jog és joghatóság.</0> Ezen megállapodást Kalifornia Állam törvényeinek szellemében kell értelmezni és annak joga irányadó minden más jog rendelkezéseinek kizárásával. A CBRE és a felhasználó kizárólagosan aláveti magát Kalifornia Állam joghatóságának. Az Egyesült Nemzetek Nemzetközi Az áruk nemzetközi kereskedelméről szóló konvenciójának (1980) szerződésekre vonatkozó része teljes mértékben kizárandó ezen megállapodás alkalmazása során.  A Felhasználó ezúton beleegyezik, hogy a Spacer® kizárólagos Kaliforniához köthető és passzív szolgáltatása nem enged teret egyéni jogorvoslatra a CBRE-n túl, legyen az specifikus vagy általános, Kalifornia Állam joghatóságán kívül.',
  TermsGeneralD: '<0>Dispute Resolution.</0> Any dispute, claim or controversy arising out of or relating to this Agreement, or the breach, enforcement, interpretation or validity thereof, including the determination of the scope or applicability of arbitration with respect to this Agreement, shall be determined by arbitration.  The arbitration shall be administered by the Judicial Arbitration and Mediation Service (JAMS), and the arbitration shall be conducted with a single arbitrator.  Furthermore, User agrees that neither User nor CBRE will join any claim with the claim of any other person or entity in arbitration; that no claim will be resolved on a class-wide basis; and that neither User nor CBRE will assert any claim in a representative capacity on behalf of anyone else.  Notwithstanding the foregoing, User agrees that CBRE shall be allowed to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.  If User files an arbitration complaint against CBRE, CBRE agrees to pay for any portion of the initial filing fee that exceeds $250; after the initial filing fees, the parties will share the costs of arbitration equally.  User agrees not to commence or prosecute any action against CBRE other than by filing an arbitration complaint in accordance with this paragraph.  IMPORTANTLY, BY AGREEING TO BE BOUND BY THIS AGREEMENT, USER IS WAIVING ANY RIGHT USER HAS TO SEEK RELIEF IN A COURT OF LAW AND ANY RIGHT USER HAS TO A TRIAL BY JURY.',
  TermsGeneralE: '<0>Elkülöníthetőség.</0> Abban az esetben ha ezen megállapodás bármely részlete érvénytelen, az elkülöníthető rész módosítandó a szükséges mértékig, annak érdekében, hogy érvényessé váljon míg a többi rendelkezése teljes jogerőben és hatályban marad.',
  TermsGeneralF: '<0>Átruházás.</0> Ez a Megállapodsá nem átruházható a Felhasználó átlal a CBRE megelőző írásos engedélye nélkül. A CBRE beleegyezése néküli bármilyen megkísérelt átruházás érvénytelen. A CBRE fenntartja a jogát a szerződés átruházására bármely harmadik személy részére. Ezen megállapodás és a jogok és kötelezettségek kötelező hatályúak örököseink és megbízottjaink számára.',
  TermsGeneralG: '<0>Módosítások.</0> A CBRE-nak bármikor jogában áll módosítani ezen megállapodás feltételeit és ez a CBRE egyedülálló és el nem idegeníthető joga, amelyet egy változatási jegyzet vagy egy új megállapodás közlésével thet meg a Spacer®’s weboldalán a következő oldalon www.cbre.com/spacer vagy egy ezzel egyen egyenértékű jelzés megtételével a Spacer® alkalmazásban. Abban az esetben, ha bármely módosítás nem elfogadható a Felhasználó számára a Felhasználó egyetlen visszkereseti joga a  Spacer® használatának megszűntetése.',
  TermsGeneralH: '<0>Konfliktusok.</0> Ezen Megállapodás kapcsán létrejövő konfliktus esetén és bámely a Felhasználó és a CBRE között létrejött teljesen végrehajtott engedély esetében Spacer® kapcsán, ennek a engedélynek a részletei szabályozzák a konfliktusos eseteket.'

};
