const React         = require('react');
const SharedTooltip = require('../../../shared/components/common/Tooltip.jsx');

function Tooltip(props) {
  return (
    <SharedTooltip
      {...props}
      img="/svg/icons/info-dark-grey-icon-spacer-by-cbre.svg"
    />
  );
}

module.exports = Tooltip;

