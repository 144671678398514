const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const modalActions           = require('../../../redux/actions/modal');
const FormModal              = require('../../modals/FormModal.jsx');

// Map state to props
function mapStateToProps(state) {
  return {
    isSubmittingForm: state.requests.isSubmittingForm,
    submitFormSuccess: state.requests.submitFormSuccess,
    submitFormError: state.requests.submitFormError
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    closeModal: bindActionCreators(modalActions.closeModal, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(FormModal);
