const React                    = require('react');
const PropTypes                = require('prop-types');
const SPACE_UNIT_CATEGORY      = require('wp-constants').shared.spaceUnit.CATEGORY;
const constants                = require('wp-constants').spacerFree;
const numericDisplay           = require('../../../../util/numericDisplay');
const unitsUtil                = require('../../../../../shared/util/units');
const { dashOrValue }          = require('../../../../../shared/util/display');
const spaceCategoryDescription = require('../../../../../../../copy/spaceCategoryDescription');
const { withTranslation }       = require('react-i18next');

function SidebarInfo(props) {
  const sectionTitle = {
    [SPACE_UNIT_CATEGORY.ME]: props.t('sidebarInfoMeTitle'),
    [SPACE_UNIT_CATEGORY.WE]: props.t('sidebarInfoWeTitle'),
    [SPACE_UNIT_CATEGORY.AMENITY]: props.t('sidebarInfoAmenityTitle')
  };

  const sidebarContent = {
    [SPACE_UNIT_CATEGORY.ME]: props.t(spaceCategoryDescription.ME),
    [SPACE_UNIT_CATEGORY.WE]: props.t(spaceCategoryDescription.WE),
    [SPACE_UNIT_CATEGORY.AMENITY]: props.t(spaceCategoryDescription.AMENITY)
  };

  const sectionTitleClasses = `title is-4 is-sans-semibold is-half-spaced has-text-${props.spaceUnitCategory} has-margin-top-3 category-title-print`;
    const areaPercentage = props.areaPercentage[props.spaceUnitCategory];

  const categoryArea = unitsUtil.getNumberForMeasurementSystem(props.categoryAreaForContext, props.measurementSystem);
  const totalAreaForContext = unitsUtil.getNumberForMeasurementSystem(props.totalAreaForContext, props.measurementSystem);

  const categoryAreaWithCommas = dashOrValue(!categoryArea, numericDisplay.numberWithLocaleFormat(categoryArea));
  const totalAreaForContextWithCommas = dashOrValue(!totalAreaForContext, numericDisplay.numberWithLocaleFormat(totalAreaForContext));

  const unitCopyBasis = constants.calculator.SF_TYPE_TEXT[props.sfType];
  const unitCopy = props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem(unitCopyBasis, props.measurementSystem)}`);

  return (
    <div className="space-category-description-container">
      <div className={sectionTitleClasses}>{sectionTitle[props.spaceUnitCategory]} — {areaPercentage}</div>
      <div className="subtitle is-6 is-sans-semibold">{categoryAreaWithCommas} / {totalAreaForContextWithCommas} {unitCopy}</div>
      <p className="sidebar-description">{sidebarContent[props.spaceUnitCategory]}</p>
    </div>
  );
}

SidebarInfo.propTypes = {
  categoryAreaForContext: PropTypes.number.isRequired,
  totalAreaForContext: PropTypes.number.isRequired,
  spaceUnitCategory: PropTypes.string.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  sfType: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(SidebarInfo);
