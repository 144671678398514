// Resource: https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage

const _ = require('lodash');

const DEFAULT_EXPIRATION = 86400000; // One day in milliseconds.
const SANITATION_KEYS = ['modal'];

function load(key) {
  try {
    const serializedState = window.localStorage.getItem(key);
    if (serializedState === null) return undefined;
    const parsedState = JSON.parse(serializedState);

    // If our item met its expiration, remove from local storage,
    const now = new Date();
    if (parsedState.expiration && (now - (new Date(parsedState.timestamp))) > parsedState.expiration) {
      window.localStorage.removeItem(key);
      return undefined;
    }

    return parsedState.value;
  } catch (err) {
    return undefined;
  }
}

// Shortcut for Redux State
function loadState() {
  return load('reduxState');
}

function save(key, value, expiration = DEFAULT_EXPIRATION) {
  const saveObject = {
    value: _.cloneDeep(_.omit(value, SANITATION_KEYS)),
    timestamp: new Date(),
    expiration
  };

  try {
    const serializedState = JSON.stringify(saveObject);
    window.localStorage.setItem(key, serializedState);
  } catch (err) {
    console.log(err);
  }
}

// Shortcut for ReduxState
function saveState(state, expiration = DEFAULT_EXPIRATION) {
  save('reduxState', state, expiration);
}

function clear() {
  const didClearState = !!window.localStorage.length;
  window.localStorage.clear();
  return didClearState;
}

module.exports = {
  loadState,
  saveState,
  load,
  save,
  clear
};
