const React               = require('react');
const PropTypes           = require('prop-types');
const sharedPropTypes     = require('../../../shared/components/sharedPropTypes');
const spacerFreePropTypes = require('../spacerFreePropTypes');
const DropdownInput       = require('./DropdownInput.jsx');

function DropdownSelectionInput(props) {
  const dropdownSelectionText = props.value ? props.options[props.value].text : props.emptyAnswerPlaceholder;

  let inputFieldClasses = 'input';
  if (props.isSpaceCategoryDropdown) inputFieldClasses += ' space-category-dropdown-input space-dropdown-width is-hidden-print is-hidden-mobile';

  const inputField = (
    <React.Fragment>
      <div className={inputFieldClasses}>
        {dropdownSelectionText}
      </div>
      <div className="dropdown-triangle" />
    </React.Fragment>
  );

  return (
    <DropdownInput
      {...props}
      inputField={inputField}
    />
  );
}

DropdownSelectionInput.propTypes = {
  value: sharedPropTypes.stringOrNumber.isRequired,
  options: spacerFreePropTypes.questionOptionsTypes.isRequired,
  emptyAnswerPlaceholder: PropTypes.string,
  isSpaceCategoryDropdown: PropTypes.bool
};

DropdownSelectionInput.defaultProps = {
  emptyAnswerPlaceholder: '',
  isSpaceCategoryDropdown: false
};

module.exports = DropdownSelectionInput;

