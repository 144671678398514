const { parseDomain } = require('parse-domain')
const featureFlags = require('../../../../util/featureFlags');

//TODO add fr if we get the french domain
function getSubdomainFromLanguage(lang) {
  switch (lang) {
    case 'en-GB':
      return 'co.uk';
    case 'de':
      return 'de';
    default:
      return 'com';
  }
}

function updateUrlForLanguage(languageFromBrowser) {
  const currentUrl = window.location.href;
  const currentSubdomain = parseDomain(currentUrl).tld;
  const subdomain = getSubdomainFromLanguage(languageFromBrowser);
  window.location.href = currentUrl.replace(currentSubdomain, subdomain);
}

const supportedLanguages = {
  de: 'Deutsch',
  en: 'English (US)',
  'en-GB': 'English (UK)',
  svk: 'Slovak',
  pol: 'Polish',
   nld: 'Dutch',
  col:'Spanish',
  // hun: 'Magyar (HU)',
  // nld: 'Dutch'
};

let frenchLanguageSupportEnabled;

if (typeof window !== 'undefined') {
  frenchLanguageSupportEnabled = window.SERVER_DATA.featureFlags.frenchLanguageSupportEnabled;
} else {
  frenchLanguageSupportEnabled = featureFlags.isFrenchLanguageSupportEnabled();
}

if (frenchLanguageSupportEnabled) {
  supportedLanguages.fr = 'Français (France)';
}

module.exports = {
  supportedLanguages,
  getSubdomainFromLanguage,
  updateUrlForLanguage
};
