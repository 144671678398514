module.exports = {
  referralStep: '{{step}}. lépés a 4-ből',
  forgotReferralLink: 'Elfelejtetted az ajánlói linkedet?',
  howReferralLinksWorkTitle: 'Hogyan működnek az ajánlói linkek',
  enterYourInformation: 'Kérjük add meg a szükséges információt',
  confirmYourInformation: 'Erősítsd meg az adataid',
  referralLinkStep1: 'Az ajánlói link készítéshez szükségünk lesz:',
  referralLinkReq1: 'A nevedre',
  referralLinkReq2: 'A CBRE-s email címedre',
  referralLinkStep2: 'Miután egy potenciális ügyfél kitöltötte a kvízt a hivatkozási linked segítségével, elküldjük az eredményeik másolatát a beérkező leveleid mappájáb.',
  next: 'Következő',
  firstName: 'Keresztnév',
  lastName: 'Vezetéknév',
  emptyNameError: 'Kérlek add meg a neved',
  email: 'A CBRE-s email címed',
  emptyEmailError: 'Kérlek add meg a CBRE-s email címed',
  emailTakenError: 'Ez az email cím már hozzá lett kapcsolva {{takenLink}}',
  emailExample: 'pl: jane.smith@cbre.com',
  editReferralInfo: 'Név és email cím szerkesztése',
  noLaterChanges: 'Később nem fogod tudni ezt módosítani.',
  somethingWrong: 'Hoppá, valami félrement a rendszerünkben. Kérlek próbáld meg újra.',
  contentLoading: 'Betöltés',
  linkReady: 'A linked elkészült',
  storeLinkInstructions: 'Őrízd meg ezt a linket egy biztonságos helyen, hogy a Spacer-t a saját ajánlói linkeddel tudd megosztani az ügyfelekkel. Ezt a linket elküldtük a megadott email címre is.',
  yourLink: 'Az ajánlói linked',
  copyLink: 'Másold ki az ajánlói linket',
  copiedLink: 'Vágólapra másolva',
  linkNeverExpires: 'Emlékeztető: ez a link soha nem jár le',
  linkFound: 'Megtaláltuk a linkedet',
  emailNotAssociated: 'Sajnos ehhez az email címhez nem találtunk meglévő ajánlói linket',
  createLink: 'Készítenél egy ajánlói linket?'
};
