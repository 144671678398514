const React = require('react');
const PropTypes = require('prop-types');
const sharedPropTypes = require('../../../../shared/components/sharedPropTypes');
const spacerFreePropTypes = require('../../spacerFreePropTypes');
const constants = require('wp-constants').spacerFree;
const util = require('wp-util');
const DropdownSelectionInput = require('../../form/DropdownSelectionInput.jsx');
const HeadcountInput = require('../../formSpecific/HeadcountInput.jsx');
const CollaborationDropdown = require('../../formSpecific/CollaborationDropdown.jsx');
const DaysInOfficeDropdown = require('../../formSpecific/DaysInOfficeDropdown.jsx');
const LocationAutocompleteInput = require('../../formSpecific/LocationAutocompleteInput.jsx');
const DensityDropdown = require('../../formSpecific/DensityDropdown.jsx');
const IndustryAutocompleteInput = require('../../formSpecific/IndustryAutocompleteInput.jsx');
const Slider = require('../../../../shared/components/common/Slider.jsx');

class SentenceCompletionAnswer extends React.Component {
  constructor(props) {
    super(props);

    this.onAnswerChange = this.onAnswerChange.bind(this);
  }

  onAnswerChange(questionAnswer) {
    if (this.props.questionUnit.type === constants.questionUnit.QUESTION_TYPE.ASSUMPTION) questionAnswer = parseFloat(questionAnswer);
    this.props.onAnswerChange(questionAnswer);
  }

  render() {
    const isGroupedQuestion = util.questionUnit.isGroupedQuestion(this.props.questionUnit);
    const isInputLarge = isGroupedQuestion && !this.props.isInactive;

    const answerInputProps = {
      isDark: true,
      isInQuiz: true,
      value: this.props.questionAnswer,
      onValueChange: this.onAnswerChange,
      isInactive: this.props.isInactive,
      isLarge: isInputLarge
    };

    let answerInput;

    if (this.props.questionUnit.type === constants.questionUnit.QUESTION_TYPE.ASSUMPTION) {
      switch (this.props.questionUnit.assumptionType) {
        case constants.questionUnit.ASSUMPTION_TYPE.HEADCOUNT: {
          answerInput = <HeadcountInput {...answerInputProps} />;
          break;
        }

        case constants.questionUnit.ASSUMPTION_TYPE.COLLABORATION: {
          answerInput = <CollaborationDropdown {...answerInputProps} />;
          break;
        }

        case constants.questionUnit.ASSUMPTION_TYPE.DAYS_IN_OFFICE: {
          answerInput = <DaysInOfficeDropdown {...answerInputProps} />;
          break;
        }

        case constants.questionUnit.ASSUMPTION_TYPE.DENSITY: {
          answerInput = <DensityDropdown {...answerInputProps} />;
          break;
        }

        default: {
          break;
        }
      }
    } else {
      switch (this.props.questionUnit.answerInputType) {
        case constants.questionUnit.ANSWER_INPUT_TYPE.DROPDOWN: {
          answerInput = (
            <DropdownSelectionInput
              {...answerInputProps}
              options={this.props.questionUnit.options}
              optionOrder={this.props.questionUnit.optionOrder}
              emptyAnswerPlaceholder={this.props.questionUnit.emptyAnswerPlaceholder}
            />
          );
          break;
        }

        case constants.questionUnit.ANSWER_INPUT_TYPE.LOCATION_AUTOCOMPLETE: {
          answerInput = <LocationAutocompleteInput {...answerInputProps} />;
          break;
        }

        case constants.questionUnit.ANSWER_INPUT_TYPE.INDUSTRY_AUTOCOMPLETE: {
          answerInput = (<IndustryAutocompleteInput {...answerInputProps} />);
          break;
        }

        default: {
          break;
        }
      }
    }

    let classes = 'sentence-complete-answer';
    if (isGroupedQuestion) classes += ' title question-text';
    if (this.props.isInactive) classes += ' is-inactive';

    let wordsClasses = 'sentence-complete-words';
    if (this.props.questionUnit.assumptionType === constants.questionUnit.ASSUMPTION_TYPE.HEADCOUNT) wordsClasses += ' headcount-question';
    return (
      <div>
        <div className={classes}>
          {this.props.questionUnit.answerText.map(componentText => (
            <div key={componentText}>
              {componentText === constants.questionUnit.INPUT_PLACEHOLDER ?
                answerInput :
                <span className={wordsClasses}>
                  {componentText}
                </span>
              }
            </div>
          ))}
        </div>
        {
          this.props.questionUnit.assumptionType === constants.questionUnit.ASSUMPTION_TYPE.HEADCOUNT && !this.props.isInactive &&
          <Slider
            min={HeadcountInput.MIN_HEADCOUNT}
            max={HeadcountInput.MAX_HEADCOUNT}
            onChange={this.onAnswerChange}
            {...answerInputProps}
          />
        }
        {
          this.props.questionUnit.answerHelperText && !this.props.isInactive &&
          <div className="has-text-primary-invert has-padding-top-7">
            {this.props.questionUnit.answerHelperText}
          </div>
        }
      </div>
    );
  }
}

SentenceCompletionAnswer.propTypes = {
  questionUnit: spacerFreePropTypes.questionUnitShape.isRequired,
  questionAnswer: sharedPropTypes.stringOrNumber,
  onAnswerChange: PropTypes.func.isRequired,
  isInactive: PropTypes.bool
};

SentenceCompletionAnswer.defaultProps = {
  questionAnswer: '',
  isInactive: false
};

module.exports = SentenceCompletionAnswer;

