const React = require('react');
const PropTypes = require('prop-types');
const FixedNavbar = require('../common/FixedNavbar.jsx');
const CopyToClipboardBox = require('../common/CopyToClipboardBox.jsx');
const constants = require('wp-constants').spacerFree;
const DownloadIcon = require('../icons/Download.jsx');
const SendIcon = require('../icons/Send.jsx');
const { withTranslation } = require('react-i18next');
const GALabels = require('../../optionsConfig/googleAnalytics.labels.json');
const ga = require('../../../shared/tracking');
const LanguageSelector = require('../common/LanguageSelector.jsx');

class ResultsHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copyToClipboardBoxActive: false
    };

    this.closeCopyToClipboardBox = this.closeCopyToClipboardBox.bind(this);
  }

  closeCopyToClipboardBox() {
    this.setState({ copyToClipboardBoxActive: false });
  }

  render() {
    const classesForItemWithIcon = 'navbar-item no-outline has-margin-right-4';

    const exportPDFButton = (
      <button
        className={classesForItemWithIcon}
        onClick={() => {
          window.print();
          ga.GAEvent(GALabels.categories.result, GALabels.actions.exportPDFBtn, GALabels.labels.exportPDFBtn);
        }}
      >
        {this.props.t('resultsHeaderExportPDFButtonText')}
        <DownloadIcon />
      </button>
    );

    const sendButtonProps = {
      text: this.props.t('resultsHeaderSendButtonText'),
      onClick: () => {
        this.setState(prevState => ({
          copyToClipboardBoxActive: !prevState.copyLinkBoxActive
        }));
        ga.GAEvent(GALabels.categories.result, GALabels.actions.sendResultBtn, GALabels.labels.sendResultBtn);
      }
    };

    const sendButton = (
      <div className={'copy-to-clipboard-box-container'}>
        <div>
          <button
            className={`${classesForItemWithIcon} send-results-button is-mobile`}
            onClick={sendButtonProps.onClick}
          >
            {sendButtonProps.text}
            <SendIcon />
          </button>
          {
            this.state.copyToClipboardBoxActive &&
            <CopyToClipboardBox
              closeCopyToClipboardBox={this.closeCopyToClipboardBox}
              textToCopy={window.location.href}
              clipboardBoxDescription={this.props.t('resultsHeaderClipboardBoxDescription')}
            />
          }
        </div>
      </div>
    );

    const contactButton = (
      <button
        className="button is-primary-filled is-small find-space-button navbar-find-space-button has-margin-left-8"
        onClick={() => this.props.openModal(constants.modal.TYPES.FORM, { formType: constants.modal.FORM_TYPES.FIND_SPACE })}
      >
        <span>{this.props.t('resultsHeaderContactButtonText')}</span>
      </button>
    );

    let navItems = null;
    if (this.props.programId !== '') {
      navItems = (
        <React.Fragment>
          <div className={`language-dropdown-top-result ${classesForItemWithIcon}`}>
            <LanguageSelector />
          </div>
          {exportPDFButton}
          {sendButton}
          {contactButton}
        </React.Fragment>
      );
    }

    return (
      <FixedNavbar
        hasTransparentNavbarAtTop
        navItems={navItems}
      />
    );
  }
}

ResultsHeader.propTypes = {
  programId: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

ResultsHeader.defaultProps = {
  programId: ''
};

module.exports = withTranslation('resultsPage')(ResultsHeader);
