module.exports = {

  // Navbar
  spacerLogoAlt: 'Spacer a CBRE-tól',
  linkToAboutPageText: 'Rólunk',
  linkToBlogPageText: 'Tippek',
  referrals: 'Javaslatok',

  // TakeOfficeSpaceQuizButton
  sizeMyOfficeButtonDefaultLinkText: 'Felmérés elkezdése',
  flooredPlanRequestLinkText: 'Terv igénylése',

  // Footer
  footerCBRELogoAlt: 'CBRE logo a Spacer a CBRE-tól számára',
  footerFacebookIconAlt: 'Facebook közösségi média ikon a Spacer a CBRE-tól számára',
  footerTwitterIconAlt: 'Twitter közösségi média ikon a Spacer a CBRE-tól számára',
  footerLinkedinIconAlt: 'LinkenIn közösségi média ikon a Spacer a CBRE-tól számára',
  footerLinkedinLink: 'https://www.linkedin.com/company/cbre-hungary',
  footerHeaderQuestionsText: 'Kérdésed van?',
  footerHeaderAboutText: 'Hasznos linkek, források',
  footerHeaderMoreText: 'Adatvédelem',
  footerHeaderBrokersText: 'Tanácsadó részére',
  footerLinkWhatIsSpacerText: 'Rólunk',
  footerLinkBlogText: 'Tippek',
  footerLinkWorkplaceText: 'A CBRE munkakörnyezeti tanácsadó csapatáról',
  footerLinkTermsText: 'Szolgáltatási Feltételek',
  footerLinkPrivacyText: 'Adatvédelmi Szabályzat',
  footerLinkReferralText: 'Javaslatok',
  footerWorkplaceLink: 'http://www.cbre.us/real-estate-services/occupier/workplace',
  footerPrivacyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Contact (contact.js)
  spacerSupportEmail: 'spacersupport@cbre.com',

  // ErrorPage
  errorOopsText: 'Hoppá!',
  error404Text: 'Nem találjuk azt az oldalt amit keresel.',
  error500Text: 'Valami nem működik a mi oldalunkon.',
  errorText: 'Hiba',
  errorReturnText: 'Vissza a Spacer-hez',
  errorAltText: 'Illusztrácó egy kiömlött kávéscsészéről a Spacer a CBRE-tól számára',

  // Measurement Units (units.js)
  SF: 'NL',
  SM: 'NM',
  USF: 'HNL',
  USM: 'HNM',
  RSF: 'KNL',
  RSM: 'KNM',
  NSF: 'NNL',
  NSM: 'NNM',
  NIA: 'NBT',
  foot: 'láb',
  feet: 'láb',
  footage: 'terjedelem',
  'sqft.': 'négyzetláb',
  meter: 'méter',
  meters: 'méter',
  'sqm.': 'nm',
  Tsubo: 'beköltözésig hátralévő idő',

  // SF Types Adjectives (calculator.js)
  Usable: 'Használható',
  Rentable: 'Kiadható',
  Net: 'Nettő',

  // Page Titles (documentDataCopy.js)
  landingPageTitle: 'Tervezd meg a tökéletes irodád 15 perc alatt - Spacer - CBRE',
  aboutPageTitle: 'Tanuld meg, hogyan működik az irodatér tervező eszközünk - Spacer - CBRE',
  termsPageTitle: 'Szolgáltatási Feltételek - Spacer - CBRE',
  quizPageTitle: 'Ideális Irodaméret 15 perc alatt - Spacer - CBRE',
  resultsPageTitle: 'Nézd meg az egyéni iroda javaslatodat - Spacer - CBRE',
  profileComparisonPageTitle: 'Hasonlítsd össze az irodaigényeidet - Spacer - CBRE',
  blogHomePageTitle: 'Iroda Tervezési Tippek - Spacer - CBRE',
  referralPageTitle: 'Javaslat link CBRE tanácsadók számára - Spacer - CBRE',
  referralRetrievePageTitle: 'Javaslat link beszerzése CBRE tanácsadók számára - Spacer - CBRE',

  // Meta Tags (documentDataCopy.js)
  landingPageMetaTagText: 'Új irodát tervezel? A Spacer a CBRE-tól segít gyorsan és könnyedén meghatározni a megfelelő iroda tér igényedet, amely segíti az üzleti céljaidat. Csak 15 percet vesz igénybe!',
  aboutPageMetaTagText: 'A irodád mérete segíthet a tehetségek bevonzásában, a költségek csökkentésében sőt még a dolgozók egészségének megőrzésében is. Tudj meg többet arról, hogyan tud a Spacer gyorsan megfelelő méretű irodát javasolni az üzleti céljaid eléréséhez.',
  termsPageMetaTagText: 'A Spacer-ben, több mint egy évtizednyi iparági tapsztalat és szakértelem csúcsosodik ki, így tud javasolni percek alatt iroda méretet.',
  quizPageMetaTagText: 'A Spacer 15 perces kvíze segít meghatározni a céged céljait és prioritásait támogató irodatér iránti igényedet. A igényre szabott igényfelmérés még sosem volt ilyen gyors.',
  resultsPageMetaTagText: 'A Spacer igényre szabott irodatér eredményeivel időt takaríthatsz meg irodakeresésed során, megadja a céged számára ideális méretet. Mindezt irodabútor és elrendezés javasttal együtt.',
  profileComparisonPageMetaTagText: 'Hasonlítsd össze hogyan viszonyul az egyedi iroda igényed másokéhoz a Spacer a CBRE-tól segítségével',
  blogHomePageMetaTagText: 'Az irodatér iránti igény számítása nem exakt tudomány, de ezek a tippek és javaslatok segíthetnek. Tudj meg többet itt.',
  referralPageMetaTagText: 'Használd ezt a linket, hogy a Spacer az ügyfeledtől közvetlenül neked jutassa el az eredményeket. Tudj meg többet itt.',
  referralRetrieveMetaTagText: 'Szerezd meg egyéni tanácsadói Spacer linkedet.',

  // Form title
  formLabel: 'Infromáció Rólad'
};
