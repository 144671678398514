const { connect }                           = require('react-redux');
const SPACE_UNIT_CATEGORY                   = require('wp-constants').shared.spaceUnit.CATEGORY;
const programDataRetriever                  = require('../../../../util/programDataRetriever');
const regionSFCalculator                    = require('../../../../../shared/calculators/regionSFCalculator');
const SpaceCategoryBreakdownMobileContainer = require('../../../results/program/SpaceCategoryBreakdown/SpaceCategoryBreakdownMobileContainer.jsx');
const _                                     = require('lodash');

function mapStateToProps(state) {
  const location = state.userData.officeLocation;
  const sfType = state.spaceData.sfType;
  const lossFactor = state.spaceData.lossFactor;
  const areaTotals = state.spaceData.program.areaTotals;

  // ME
  const meCategoryArea = programDataRetriever.getTotalSFForCategory(areaTotals, SPACE_UNIT_CATEGORY.ME);
  const meProgramForCategory = state.spaceData.program[SPACE_UNIT_CATEGORY.ME];
  const meCategoryAreaDelta = programDataRetriever.getTotalDeltaForCategoryAndContext(areaTotals, SPACE_UNIT_CATEGORY.ME, sfType, location, lossFactor);

  // WE
  const weCategoryArea = programDataRetriever.getTotalSFForCategory(areaTotals, SPACE_UNIT_CATEGORY.WE);
  const weProgramForCategory = state.spaceData.program[SPACE_UNIT_CATEGORY.WE];
  const weCategoryAreaDelta = programDataRetriever.getTotalDeltaForCategoryAndContext(areaTotals, SPACE_UNIT_CATEGORY.WE, sfType, location, lossFactor);

  // AMENITY
  const amenityCategoryArea = programDataRetriever.getTotalSFForCategory(areaTotals, SPACE_UNIT_CATEGORY.AMENITY);
  const amenityProgramForCategory = [
    ...state.spaceData.program[SPACE_UNIT_CATEGORY.AMENITY],
    ...state.spaceData.program[SPACE_UNIT_CATEGORY.SUPPORT],
    ...(_.map(state.spaceData.program[SPACE_UNIT_CATEGORY.CUSTOM_AMENITY], (amenity) => {
      const amenityWithCustomFlag = _.cloneDeep(amenity);
      amenityWithCustomFlag.isCustomAmenity = true;
      return amenityWithCustomFlag;
    }))
  ];
  const amenityCategoryAreaDelta = programDataRetriever.getTotalDeltaForCategoryAndContext(areaTotals, SPACE_UNIT_CATEGORY.AMENITY, sfType, location, lossFactor);

  return {
    meProgramForCategory,
    weProgramForCategory,
    amenityProgramForCategory,
    meCategoryAreaForContext: regionSFCalculator.getAreaForContext(meCategoryArea, sfType, location, lossFactor),
    weCategoryAreaForContext: regionSFCalculator.getAreaForContext(weCategoryArea, sfType, location, lossFactor),
    amenityCategoryAreaForContext: regionSFCalculator.getAreaForContext(amenityCategoryArea, sfType, location, lossFactor),
    meCategoryAreaDelta,
    weCategoryAreaDelta,
    amenityCategoryAreaDelta
  };
}

module.exports = connect(mapStateToProps)(SpaceCategoryBreakdownMobileContainer);

