/* global heap */
const _ = require('lodash');
const constants = require('wp-constants').spacerFree;
const SPACE_UNIT_CATEGORY = require('wp-constants').shared.spaceUnit.CATEGORY;
const profileMap = require('../../../../data/profileMap');
const spaceUnitMap = require('../../../../data/spaceUnitMap');

function getSpaceDataForAnalytics(spaceData) {
  const spaceDataForAnalytics = _.assign({},
    spaceData.program.areaTotals,
    spaceData.assumptions,
    spaceData.program.metrics,
    spaceData.program.finalStats
  );
  spaceDataForAnalytics.collaboration = _.findKey(constants.calculator.COLLABORATION_LEVEL, level => level === spaceDataForAnalytics.collaboration); // translate to 'LOW', ...
  spaceDataForAnalytics.profileName = profileMap[spaceData.profileId].displayName;


  // flatten program
  _.values(SPACE_UNIT_CATEGORY).forEach((category) => { // go through 'me', 'we', ...
    const section = spaceData.program[category]; // spacer free doesn't have sharedFocus sections in programs
    if (section) {
      section.forEach((unit) => {
        let unitName;
        if (category === SPACE_UNIT_CATEGORY.CUSTOM_AMENITY) {
          unitName = unit.displayName;
        } else {
          unitName = spaceUnitMap[unit.spaceUnitId].displayName;
        }
        Object.keys(unit).forEach((property) => {
          if (property !== 'spaceUnitId' && property !== 'id' && property !== 'displayName') {
            spaceDataForAnalytics[`program_${category}_${unitName}_${property}`] = unit[property];
          }
        });
      });
    }
  });

  return spaceDataForAnalytics;
}

// NOTE: we `could` send this not in number form, but in text form (instead of answer is '2', send 'Mostly from our office')
// that might be easier to read, but it might not be. Reconsider if John complains
function getProfileQuizForAnalytics(profileQuiz) {
  // flattening, but instead of just using 1, 2, 3 as keys, changing it to question_1 and so on instead
  const analyticsAnswers = {};
  Object.keys(profileQuiz.userQuizAnswers).forEach((questionId) => {
    const answer = profileQuiz.userQuizAnswers[questionId];
    if (_.isArray(answer)) {
      answer.forEach((ans, i) => {
        analyticsAnswers[`question_${questionId}_${i}`] = ans;
      });
    } else {
      analyticsAnswers[`question_${questionId}`] = answer;
    }
  });
  return analyticsAnswers;
}

// Heap doesn't accept nested properties, so flatten everything!
function formatHeapEventProps(state) {
  return _.assign({}, state.userData, getSpaceDataForAnalytics(state.spaceData), getProfileQuizForAnalytics(state.profileQuiz));
}

function sendHeapEvent(eventName, state) {
  if (window.SERVER_DATA.featureFlags.isHeapAnalyticsEnabled == "true") {
    const eventProps = formatHeapEventProps(state);
    return heap.track(eventName, eventProps);
  } else {
    return null;
  }
}

function sendHeapEventWithoutProps(eventName) {
  if (window.SERVER_DATA.featureFlags.isHeapAnalyticsEnabled == "true") {
    return heap.track(eventName);
  } else {
    return null;
  }
}

module.exports = {
  sendHeapEvent,
  sendHeapEventWithoutProps
};
