const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const spaceDataActions       = require('../../../../redux/actions/spaceData');
const modalActions           = require('../../../../redux/actions/modal');
// const globalActions          = require('../../../../redux/actions/global');
import globalActions from '../../../../redux/actions/global';
const ProfileComparison      = require('../../../results/profile/ProfileComparison.jsx');

// Map state to props
function mapStateToProps(state) {
  const profileScores = state.profileQuiz.profileScores;
  const sortedProfileIds = Object.keys(profileScores).sort((a, b) => profileScores[b] - profileScores[a]);
  const programId = state.spaceData._id

  return {
    profileType: state.spaceData.profileType,
    currentProfileId: state.spaceData.profileId,
    profileIds: sortedProfileIds.slice(0, 3),
    programId
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(modalActions.openModal, dispatch),
    closeModal: bindActionCreators(modalActions.closeModal, dispatch),
    setProfileAndCalculateProgram: bindActionCreators(spaceDataActions.setProfileAndCalculateProgram, dispatch),
    updateProgram: bindActionCreators(globalActions.updateProgram, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(ProfileComparison);

