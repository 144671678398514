const React                                 = require('react');
const PropTypes                             = require('prop-types');
const _                                     = require('lodash');
const SPACE_UNIT_CATEGORY                   = require('wp-constants').shared.spaceUnit.CATEGORY;
const spacerFreePropTypes                   = require('../../../spacerFreePropTypes');
const sharedPropTypes                       = require('../../../../../shared/components/sharedPropTypes');
const SidebarInfo                           = require('./SidebarInfo.jsx');
const SpaceUnits                            = require('./SpaceUnits.jsx');
const CustomAmenityAdder                    = require('../../../containers/results/program/CustomAmenityAdder.jsx');
const SpaceUnitTotals                       = require('./SpaceUnitTotals.jsx');
const programDataRetriever                  = require('../../../../util/programDataRetriever');
const SpaceCategoryDropdown                 = require('./SpaceCategoryDropdown.jsx');
const SpaceCategoryBreakdownMobileContainer = require('../../../containers/results/program/SpaceCategoryBreakdownMobileContainer.jsx');
const domUtil                               = require('../../../../../shared/util/dom');
const { withTranslation }                    = require('react-i18next');


function SpaceCategoryBreakdown(props) {
  const categoryNSF = _.reduce(props.programForCategory, (runningTotal, unit) => runningTotal + (programDataRetriever.getNSF(unit)), 0);
  const circulationTotal = props.categoryArea - categoryNSF;

  const containerClasses = `column is-full-desktop is-full-tablet space-unit-breakdown-container accent-text-${props.spaceUnitCategory}`;

  return (
    <div>
      {
        domUtil.isScreenMobileSize() ?
          <div>
            <SpaceCategoryBreakdownMobileContainer
              totalAreaForContext={props.totalAreaForContext}
              measurementSystem={props.measurementSystem}
              totalAreaDelta={props.categoryAreaDelta}
              sfType={props.sfType}
              lossFactor={props.lossFactor}
              areaTotals={props.areaTotals}
              location={props.location}
              updateLossFactor={props.updateLossFactor}
            />
          </div>
          :
          <div className="columns topDiv-column space-category-breakdown-container results-breakdown-padding">
            <div className="column is-full-desktop is-full-tablet space-category-sidebar-column">
              <div>
                <div className="subtitle is-6 has-margin-bottom-7 is-hidden-print is-hidden-mobile space-category-dropdown-title">{props.t('spaceCatBreakdownSubtitle')}</div>
                <SpaceCategoryDropdown
                  value={props.spaceUnitCategory}
                  onValueChange={props.updateCategory}
                  isSpaceCategoryDropdown
                />
              </div>
              <SidebarInfo
                totalAreaForContext={props.totalAreaForContext}
                sfType={props.sfType}
                categoryAreaForContext={props.categoryAreaForContext}
                spaceUnitCategory={props.spaceUnitCategory}
                measurementSystem={props.measurementSystem}
                areaPercentage={props.areaPercentage}
              />
            </div>
            <div className={containerClasses}>
              <SpaceUnits
                units={props.programForCategory}
              />
              {props.spaceUnitCategory === SPACE_UNIT_CATEGORY.AMENITY &&
                <CustomAmenityAdder />
              }
              <SpaceUnitTotals
                totalAreaForContext={props.categoryAreaForContext}
                totalAreaDelta={props.categoryAreaDelta}
                circulationTotal={circulationTotal}
                measurementSystem={props.measurementSystem}
                sfType={props.sfType}
                lossFactor={props.lossFactor}
                location={props.location}
                updateLossFactor={props.updateLossFactor}
              />
            </div>
          </div>
      }
    </div>
  );
}

SpaceCategoryBreakdown.propTypes = {
  totalAreaForContext: PropTypes.number.isRequired,
  categoryAreaForContext: PropTypes.number.isRequired,

  categoryArea: PropTypes.number.isRequired,
  categoryAreaDelta: spacerFreePropTypes.deltaShape,

  spaceUnitCategory: PropTypes.string.isRequired,
  programForCategory: PropTypes.arrayOf(PropTypes.object).isRequired,

  lossFactor: PropTypes.number.isRequired,
  areaTotals: sharedPropTypes.areaTotalsShape.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  sfType: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,

  updateCategory: PropTypes.func.isRequired,

  updateLossFactor: PropTypes.func.isRequired,

  t: PropTypes.func.isRequired
};

SpaceCategoryBreakdown.defaultProps = {
  categoryAreaDelta: null
};

module.exports = withTranslation('resultsPage')(SpaceCategoryBreakdown);
