module.exports = {

  // Navbar
  spacerLogoAlt: 'Spacer by CBRE',
  linkToAboutPageText: 'About',
  linkToBlogPageText: 'Insights',
  referrals: 'Referrals',

  // TakeOfficeSpaceQuizButton
  sizeMyOfficeButtonDefaultLinkText: 'Take office space quiz',
  flooredPlanRequestLinkText: 'Request a Plan',

  // Footer
  footerCBRELogoAlt: 'CBRE logo for Spacer by CBRE',
  footerFacebookIconAlt: 'Facebook’s social icon for Spacer by CBRE',
  footerTwitterIconAlt: 'Twitter’s social icon for Spacer by CBRE',
  footerLinkedinIconAlt: 'Linkedin’s social icon for Spacer by CBRE',
  footerHeaderQuestionsText: 'Questions?',
  footerHeaderAboutText: 'Resources',
  footerHeaderMoreText: 'More',
  footerHeaderBrokersText: 'For Brokers',
  footerLinkWhatIsSpacerText: 'About',
  footerLinkBlogText: 'Insights',
  footerLinkWorkplaceText: 'Who is CBRE Workplace?',
  footerLinkTermsText: 'Terms of Service',
  footerLinkPrivacyText: 'Privacy Policy',
  footerLinkedinLink: 'https://www.linkedin.com/showcase/cbre-workplace-strategy/',
  footerLinkReferralText: 'Referrals',
  footerWorkplaceLink: 'http://www.cbre.us/real-estate-services/occupier/workplace',
  footerPrivacyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Contact (contact.js)
  spacerSupportEmail: 'spacersupport@cbre.com',

  // ErrorPage
  errorOopsText: 'Oops!',
  error404Text: 'We can’t find the page you’re looking for.',
  error500Text: 'Something went wrong on our end.',
  errorText: 'Error',
  errorReturnText: 'Return to Spacer',
  errorAltText: 'Illustration of a spilled coffee cup for Spacer by CBRE',

  // Measurement Units (units.js)
  SF: 'SF',
  SM: 'SM',
  USF: 'USF',
  USM: 'USM',
  RSF: 'RSF',
  RSM: 'RSM',
  NSF: 'NSF',
  NSM: 'NSM',
  NIA: 'NIA',
  foot: 'foot',
  feet: 'feet',
  footage: 'footage',
  'sqft.': 'sqft.',
  meter: 'meter',
  meters: 'meters',
  'sqm.': 'sqm.',
  Tsubo: 'tsubo',

  // SF Types Adjectives (calculator.js)
  Usable: 'Usable',
  Rentable: 'Rentable',
  Net: 'Net',

  // Page Titles (documentDataCopy.js)
  landingPageTitle: 'Plan Your Perfect Office in 15 Minutes - Spacer by CBRE',
  aboutPageTitle: 'Learn How Our Office Sizing Tool Works - Spacer by CBRE',
  termsPageTitle: 'Terms of Service - Spacer by CBRE',
  quizPageTitle: 'Size Your Office in 15 Minutes - Spacer by CBRE',
  resultsPageTitle: 'View Your Custom Office Recommendation - Spacer by CBRE',
  profileComparisonPageTitle: 'Compare Your Office Needs - Spacer by CBRE',
  blogHomePageTitle: 'Office Planning Insights - Spacer By CBRE',
  referralPageTitle: 'Referral Link for CBRE Brokers - Spacer By CBRE',
  referralRetrievePageTitle: 'Retrieve Referral Link for CBRE Brokers - Spacer By CBRE',

  // Meta Tags (documentDataCopy.js)
  landingPageMetaTagText: 'Planning your next office? Let Spacer by CBRE help you quickly and easily determine the right amount of office space needed to support your business goals. It only takes 15 minutes!',
  aboutPageMetaTagText: 'The size of your office can help you attract talent, reduce costs, and even keep your employees healthy. Learn more about how Spacer can quickly recommend the right office size to support your company’s goals.',
  termsPageMetaTagText: 'More than a decade of industry expertise and experience has gone into Spacer by CBRE, which makes informed office size recommendations in minutes.',
  quizPageMetaTagText: 'Take Spacer’s 15-minute quiz to determine how much office space you need to support your company’s goals and priorities. Customized programming has never been this fast.',
  resultsPageMetaTagText: 'Spacer’s custom space results save you time searching for offices by giving you the exact office size your company needs, along with suggested furniture types and floor plan layouts.',
  profileComparisonPageMetaTagText: 'Understand how your unique office needs compare to others using Spacer by CBRE',
  blogHomePageMetaTagText: 'Calculating how much office space a business needs is not a perfect science, but these office planning insights and resources can help. Learn more here.',
  referralPageMetaTagText: 'Use this referral link to send Spacer to clients and ensure their space results are sent directly to your email. Learn more here.',
  referralRetrieveMetaTagText: 'Retrieve your referral link for Spacer.',

  // Form title
  formLabel: 'Your information'
};
