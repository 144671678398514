const OPEN_MODAL  = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';


module.exports = {

  // Action Types

  OPEN_MODAL,
  CLOSE_MODAL,

  // Action Creators

  openModal(modalType, modalProps) {
    return { type: OPEN_MODAL, modalType, modalProps };
  },

  closeModal() {
    return { type: CLOSE_MODAL };
  }

};
