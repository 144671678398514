const React                           = require('react');
const PropTypes                       = require('prop-types');
const _                               = require('lodash');
const industryCodes                   = require('../../../../../data/industryCodes');
const AlphabetizedAutocompleteInput   = require('../formSpecific/AlphabetizedAutocompleteInput.jsx');
const { withTranslation }              = require('react-i18next');

class IndustryCodeAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.industryCodeTokens = industryCodes.industryCodeTokens;

    this.state = {
      industryInput: this.props.t(this.industryCodeTokens[props.value]) || ''
    };

    this.onIndustryInputChange = this.onIndustryInputChange.bind(this);
  }

  onIndustryInputChange(value) {
    this.setState({
      industryInput: value
    });
    const industryCode = _.findKey(this.industryCodeTokens, industry => this.props.t(industry) === value);
    this.props.onValueChange(industryCode || '');
  }

  render() {
    const industryOptionsTokenized = Object.values(this.industryCodeTokens).map(this.props.t);

    return (
      <AlphabetizedAutocompleteInput
        {...this.props}
        value={this.state.industryInput}
        onValueChange={this.onIndustryInputChange}
        dropdownOptions={industryOptionsTokenized}
      />
    );
  }
}

IndustryCodeAutocomplete.propTypes = {
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('quizPage')(IndustryCodeAutocomplete);
