module.exports = {
  // Results
  resultsWarningMessage:
    "Weet je zeker dat je deze pagina wilt verlaten? Niet opgeslagen gegevens gaan mogelijk verloren.",

  // ResultsHeader
  resultsHeaderExportPDFButtonText: "PDF exporteren",
  resultsHeaderSendButtonText: "Resultaten verzenden",
  resultsHeaderClipboardBoxDescription:
    "Iedereen met een link kan de resultaten zien en bewerken",
  resultsHeaderContactButtonText: "Kom in contact met een adviseur",

  // EmailWallSection
  emailWallTitle: "Er staat nog meer voor je klaar",
  emailWallSubtitle:
    "Je volledige werkplekadvies kosteloos ontvangen? Vul hieronder je gegevens in.",
  emailWallFirstNamePlaceholder: "Voornaam",
  emailWallLastNamePlaceholder: "Achternaam",
  emailWallEmailPlaceholder: "Je e-mailadres",
  emailWallCBREEmailPlaceholder:
    "Je e-mailadres als CBRE-deskundige (optioneel)",
  emailWallLeasingProfessionalPlaceholder: "Je CBRE-verhuurspecialist",
  emailWallConnectMeText:
    "Ik ontvang graag de resultaten (in pdf) per e-mail.",
  emailWallContactNoticeText:
    "Het team van Spacer kan contact met je opnemen om je te helpen het programma optimaal te benutten. Geen spam, dat beloven we!",
  emailWallResultsButtonText: "Volledige resultaten bekijken",
  emailWallImgAltText:
    "Voorbeeld van resultatenpagina ruimteprogramma van de e-mailmuur van Spacer by CBRE",
  emailWallFirstNameInvalid: "Vul je voornaam in.",
  emailWallLastNameInvalid: "Vul je achternaam in.",
  emailWallEmailInvalid: "Vul je mailadres in.",

  // CopyToClipboardBox
  clipboardBoxCopyText: "Kopiëren",
  clipboardBoxCopiedText: "Gekopieerd",

  // FormModal
  formModalFindSpaceHeader: "Begin de zoektocht naar je ideale werkplek",
  formModalFindSpaceBody:
    "Spacer is een snel en gratis hulpmiddel om je ruimtebehoeften in kaart te brengen. Wil je meer advies op maat? De adviseurs van CBRE Workplace helpen je graag. Vul dit formulier in, dan nemen we binnen twee werkdagen contact met je op.",
  formModalFindSpaceSuccessMessage: "Je verzoek is verzonden.",

  // SuccessModal
  successModalTitle: "Gelukt!",

  // ContactForm
  contactFormFirstNameLabel: "Voornaam",
  contactFormLastNameLabel: "Achternaam",
  contactFormEmailLabel: "e-mailadres",
  contactFormPhoneLabel: "Telefoonnummer",
  contactFormMessageLabel: "Vertel meer over wat je precies zoekt",
  contactFormSubmitText: "Versturen",

  // AcceptTermsText
  acceptTermsText:
    "Ik ga akkoord met de <1>Algemene voorwaarden</1>. Raadpleeg voor meer informatie over het gebruik van je gegevens het <3>Privacybeleid van CBRE</3>.",
  privacyPolicyLink: "https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy",

  // Form
  formErrorText: "Oeps, dat ging niet goed. Probeer het nog eens.",
  formEmailInvalid: "Voer een geldig e-mailadres in.",

  // FormInput
  formInputOptionalText: " (optioneel)",

  // LegalForm
  legalFormCheckboxText:
    "CBRE mag contact met mij opnemen met aanbiedingen en informatie met betrekking tot mijn gebruik van Spacer.",
  legalFormContactWarningText:
    "Het team van Spacer kan contact met je opnemen om je te helpen het programma optimaal te benutten. Geen spam, dat beloven we!",

  // NextStepsSection
  nextStepsSectionSubtitle: "Klaar voor de volgende stap?",
  nextStepsSectionTitle: "Zet de zoektocht naar het ideale kantoor voort.",
  nextStepsSectionButtonText: "Kom in contact met een adviseur",
  nextStepsSectionImgAlt:
    "Illustratie van pratende mensen op de pagina Resultaten van Spacer by CBRE",

  // Profile
  resultsProfileSummaryTitle: "Jouw kantoor",
  resultsProfileTooltipText:
    "Alle 8 profielen zijn gebaseerd op echte organisaties en werkruimten. We hebben de gegevens vergeleken met brancherichtlijnen en de diepgaande marktkennis van CBRE Workplace.",

  // Program
  programSaveConfirmationBoxText:
    "Je bent helemaal klaar. We hebben een link naar je resultaten naar je e-mailadres gestuurd. Veel plezier!",
  programExamplePlansTitle: "Voorbeeldplattegronden",
  programExamplePlansSubtitle:
    "Bekijk de interactieve plattegronden die bij je profiel passen, in 2D óf 3D!",
  programTooMuchTinkerWarning:
    "Let op: je zit nu buiten de ruimte die bij je profiel past. Overweeg <1>de vragenlijst opnieuw in te vullen</1> of contact op te nemen met de klantenservice via <3>{{email}}</3>.",
  programDisclaimer:
    "Spacer by CBRE wordt geleverd ’zoals het is’ en ’op voorwaarde van beschikbaarheid’, zonder garanties. Alle resultaten die worden gegenereerd op basis van je gebruik van Spacer dienen uitsluitend ter illustratie en vormen geen daadwerkelijke ruimteaanbevelingen of voorgestelde huurvoorwaarden. De daadwerkelijke ruimteaanbevelingen kunnen aanzienlijk afwijken en zijn afhankelijk van een groot aantal factoren waarmee Spacer geen rekening houdt. Door Spacer of enig verslag dat wordt gegenereerd door het gebruik van Spacer te gebruiken, ga je akkoord met onze Servicevoorwaarden en ons Privacybeleid, respectievelijk te vinden op https://workplace.cbre.com/termsOfService en https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy. Â© 2018 CBRE, Inc. Alle rechten voorbehouden.",
  programMetricsTitle: "Je kantoorprofiel in cijfers",
  programMetricsSubtitle:
    "Deze getallen geven een overzicht van je werkomgeving",
  programDetailsSectionTitle: "Je ruimteprogramma aanpassen",
  programDetailsSectionSubtitle:
    "Pas de velden hieronder aan om je ruimtebehoeften te verfijnen.",
  programRevertButtonText: "Ongedaan maken",
  programSaveButtonText: "Wijzigingen opslaan",
  programSavedButtonText: "Opgeslagen",

  // ProgramAssumptions
  programAssumptionsRecommendedOfficeSizeText: "Aanbevolen kantoorgrootte",
  programAssumptionsProfileText: "Profiel",
  programAssumptionsHeadcountText: "Bezetting",
  programAssumptionsHeadcountFollowingText: "mensen",
  programAssumptionsCollabText: "Samenwerking",
  programAssumptionsCirculationText: "Circulatie",
  programAssumptionsAvgDaysText: "Gemiddeld aantal dagen op kantoor",
  programAssumptionsAvgDaysFollowingText: "per week",
  programAssumptionsAvgDaysPerWeekText:
    "Gemiddeld aantal dagen op kantoor per week",

  // ProgramHeroForPrint
  printNIAText: "Netto binnenoppervlakte",
  printUSFText: "Functioneel Nuttig Oppervlak ",
  printRSFText: "Huurbare vierkante meters",
  printDescText:
    "Op basis van je gegevens en een {{factorType}} van {{lossFactorPercentage}}% in {{city}}, adviseren we in totaal-",
  printFitFactorText: "pasfactor",
  printLossFactorText: "verliesfactor",

  // BarChart
  barChartIndivSubtitle: "Individuele ruimte",
  barChartGroupSubtitle: "Groepsruimte",
  barChartAmenitySubtitle: "Voorzieningenruimte",

  // TabbedProgramBarChart
  tabbedBarChartMeSubtitle: "Individueel",
  tabbedBarChartGroupSubtitle: "Groep",
  tabbedBarChartAmenitySubtitle: "Voorzieningen",

  // SpaceCategoryBreakdown
  spaceCatBreakdownSubtitle: "Selecteer een ruimte om aan te passen",

  // SpaceCategoryBreakdownMobile
  spaceCatBreakdownMobileMeTitle: "Individuele ruimte",
  spaceCatBreakdownMobileWeTitle: "Groepsruimte",
  spaceCatBreakdownMobileAmenityTitle: "Voorzieningenruimte",
  spaceCatBreakdownMobileMeSubtitle: "om alleen te werken",
  spaceCatBreakdownMobileWeSubtitle: "om samen te werken",
  spaceCatBreakdownMobileAmenitySubtitle: "voor al het andere",
  spaceCatBreakdownMobileNIATotalTitle: "Totale netto binnenoppervlakte",
  spaceCatBreakdownMobileTotalTitle:
    "Totaal {{sfType}} vierkante {{unitCapitalized}}",
  spaceCatBreakdownMobileCirculationText: "Circulatie",

  // SpaceCategoryDropdown
  spaceCatDropdownMeText: "Individuele ruimte",
  spaceCatDropdownWeText: "Groepsruimte",
  spaceCatDropdownAmenityText: "Voorzieningenruimte",

  // SidebarInfo
  sidebarInfoMeTitle: "Individueel",
  sidebarInfoWeTitle: "Groep",
  sidebarInfoAmenityTitle: "Voorzieningen",

  // Space Category Descriptions (spaceCategoryDescription.js)
  spaceCatMeDesc:
    "Ruimte voor individueel werk. Als je je medewerkers de keuze geeft waar en hoe ze werken, leidt dat tot meer tevredenheid.",
  spaceCatWeDesc:
    "Ruimte voor samenwerking en teamvorming. In een tijd waarin werken overal kan, is het belangrijk om verschillende ruimten aan te bieden die passen bij uiteenlopende taken.",
  spaceCatAmenityDesc:
    "Ruimte die het werk ondersteunt. Goede voorzieningen maken je kantoor tot een plek waar (toekomstige) werknemers graag hun tijd doorbrengen.",

  // Suggested Custom Amenities (suggestedCustomAmenityUnitMap.js)
  audioVisualCustom: "Audio / Visueel",
  dataCenterCustom: "Datacenter",
  buildingSupportCustom: "Ondersteuningsdiensten",
  foodServiceCustom: "restaurant",
  kitchenCustom: "Keuken",
  serveryCustom: "Buffet",
  execDiningCustom: "Directie-eetruimte",
  grabNGoCustom: "Snelle snacks",
  juiceCoffeeBarCustom: "pantry",
  childCareCustom: "Kinderopvang",
  fitnessCenterCustom: "Fitnesscentrum",
  healthCenterCustom: "EHBO-ruimte",
  mailCenterCustom: "Postkamer",
  commercialPrintCustom: "Commerciële printvoorziening",
  centralCafeCustom: "cafe",
  securityCtrlCustom: "Bewakingsruimte",
  auditoriumCustom: "auditorium",
  dryCleaningLaundryCustom: "Stomerij/wasserij",
  confCenterCustom: "Congrescentrum",
  multiPurpTrainingCustom: "Multifunctionele ruimte/cursusruimte",
  informalCollabCustom: "Informele samenwerking",
  touchdownTeamCustom: "aanlandtafel",

  // Suggested Custom Amenity Units (spaceUnit.js)
  seats: "zitplaats",
  seats_plural: "zitplaatsen",
  rooms: "ruimte",
  rooms_plural: "kamers",
  areas: "ruimte",
  areas_plural: "ruimten",
  units: "eenheid",
  units_plural: "eenheden",

  // Predefined Amenities (spaceUnitMap.js) - Shared Space Units
  focusRoomDefined: "Focusruimte",
  fileRoomDefined: "Archiefruimte",
  centralReceptionHubDefined: "Centrale receptie",
  smOfficeDefined: "Klein kantoor",
  regOfficeDefined: "Normaal kantoor",
  benchDesk5Defined: "Open Werkplek",
  benchDesk6Defined: "Open Werkplek",
  meetingRoom4Defined: "Vergaderruimte (4 personen)",
  meetingRoom6Defined: "Vergaderruimte (6 personen)",
  meetingRoom8Defined: "Vergaderruimte (8 personen)",
  meetingRoom12Defined: "Vergaderruimte (12 personen)",
  meetingRoom15Defined: "Vergaderruimte (15 personen)",
  semiEnclosedMeetingBooth4Defined: "Half open informeel overleg (4 personen)",
  openCollabSpace4to6Defined: "Open samenwerkingsruimte (4-6 mensen)",
  contemplationSpaceDefined: "Bezinningsruimte",
  mainITEquipRoomDefined: "Hoofdserverruimte(MER) ",
  receptionAreaDefined: "Receptie",
  cafeFoodServiceDefined: "Restaurant/cafe",
  teamStorageDefined: "Opslagruimte team",
  coatClosetsDefined: "Garderobe",
  genStorageDefined: "Algemene opslagruimte (voorraden en goederen in/uit)",
  centralisedCopyPrintMailDefined:
    "Gecentraliseerde kopieer-, print- en postfaciliteiten",
  specialResourceAreaDefined: "Speciale bergruimte",
  personalStorageDefined: "Persoonlijke bergruimte (kluisjes)",
  storageITSpaceDefined: "Bergruimte IT",
  secondaryServerRoomDefined: "Secundaire server-/telecomruimte",
  mothersRoomDefined: "Kolfruimte",
  buildITSpaceDefined: "Installatieruimte IT(SER) ",
  workerCouncilOffice2Defined: "Werkoverlegruimte (2 personen)",
  securityCtrlRoomDefined: "Bewakingsruimte",
  centralisedMailDefined: "Gecentraliseerde postruimte",
  visitorCoatLuggageRoomDefined: "Bezoekersgarderobe",
  wellnessRoomDefined: "Welzijnsruimte",
  internalStairDefined: "Interne trap",
  commercialPrintRoomDefined: "Commerciële printvoorziening",
  buildingSupportServicesAreaDefined: "Gebied voor ondersteuningsdiensten",

  // Predefined Amenities (spaceUnitMap.js) - US_SMALL and US_REGULAR
  officeDefined: "Kantoor",
  lgOfficeDefined: "Kantoor (groot)",
  smWorkstationDefined: "Werkstation (klein)",
  mdWorkstationDefined: "Werkstation (gemiddeld)",
  lgWorkstationDefined: "Werkstation (groot)",
  huddleRoomDefined: "Huddle room",
  dispersedCopyPrintAreaDefined: "Verspreid kopieer-/printgebied",
  smMeetingRoomDefined: "Kleine vergaderruimte",
  mdMeetingRoomDefined: "Middelgrote vergaderruimte",
  lgMeetingRoomDefined: "Grote vergaderruimte",
  xlMeetingRoomDefined: "Extra grote vergaderruimte/directiekamer",
  multiPurpTrainingDefined: "Multifunctionele ruimte/cursusruimte",
  informalCollabDefined: "Informele samenwerkingsruimte",
  touchdownTeamDefined: "Touchdown-/teamruimte",
  pantryVendingCoffeeAreaDefined: "Pantry/automaten",
  workplaceStorageRoomDefined: "Bergruimte werkplekken",
  employeeCoatAreaDefined: "Personeelsgarderobe",
  dispersedMailAreaDefined: "Verspreid postgebied",
  lockerUnitDefined: "Kluisjes",
  serverRoomDefined: "Serverruimte",
  frontDeskAreaDefined: "Gebied ontvangstbalie",
  centralCafeAreaDefined: "Gebied centrale koffiehoek",
  mailCenterAreaDefined: "Postkamergebied",
  kitchenAreaDefined: "Keukengebied",
  drawerLateralFileUnitDefined: "Dossierkast met drie laden",
  serveryAreaDefined: "Buffetruimte",
  juiceBarCoffeeShopAreaDefined: "Gebied sapbar/koffiebar",
  diningSupportAreaDefined: "Ondersteuningsruimte kantine",
  diningAreaDefined: "Kantine",
  grabNGoAreaDefined: "Gebied snelle snacks",
  focusRoomSharedFocus: "Focusruimte",

  // Predefined Amenities (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDefined: "Volledige maaltijdservice",

  // Predefined Amenities (spaceUnitMap.js) - APAC_REGULAR
  officeWorkMeetDefined: "Kantoor (werken/vergaderen)",
  lgOfficeWorkMeetDefined: "Groot kantoor (werken/vergaderen)",
  focusDefined: "Focus",
  bench1pt6Defined: "1.6 bench",
  bench1pt8Defined: "1.8 bench",
  bench1pt8LDefined: "1.8 werkstation ‘L’",
  banquetWorkMeetDefined: "Muurbank - werken/vergaderen (2 p)",
  cafeTable4Defined: "Cafétafel (4 p)",
  kitchenTable8to10Defined: "Keukentafel (8-10 p)",
  standingWhiteboardDefined: "Staand whiteboard",
  huddleRoom3Defined: "Huddle room (3 p)",
  smMeetingRoom5Defined: "Vergaderruimte S (5 p)",
  mdMeetingRoom8Defined: "Vergaderruimte M (8 p)",
  lgMeetingRoom20Defined: "Vergaderruimte L (20 p)",
  xlBoardroom: "Extra groot/directiekamer",
  wellnessRoomsDefined: "Welzijnsruimten",
  employeeCoatsGymLockersDefined: "Personeelsgarderobe/sportkluisjes",
  lockersDefined: "Kluisjes",
  dispersedCopyPrintScanAreaDefined: "Verspreid kopieer-/print-/scangebied",
  hydrationPointDefined: "Hydratatiepunt (water/thee/koffie)",
  centralHighDensityStorageDefined: "Centrale opslag hoge dichtheid",
  wellnessMothersRoomDefined: "Welzijnsruimte",
  serverHubRoomsDefined: "Server-/hubruimten",
  buildITStoreRoomDefined: "Opberg- en installatieruimte IT",
  simpleReceptionWaitDefined: "Eenvoudige receptie + wachtruimte",
  centralCafeSocialHubDefined: "Centrale koffie- en ontmoetingsplek",
  mailCentreDefined: "Postkamer",
  utilityStoreRoomDefined: "Bergruimte voorzieningen",
  drawerLateralFileDefined: "Dossierkast met drie laden",
  juiceBarCoffeeShopDefined: "Sapbar/koffiehoek",
  buildingSupportServicesDefined: "Ondersteuningsdiensten",

  // Space Unit Map Descriptions (spaceUnitMap.js) - Shared Descriptions
  focusRoomDesc:
    "Focusruimten zijn afgesloten ruimten die bedoeld zijn voor geconcentreerd en/of vertrouwelijk werk, meestal door één of twee personen. Akoestische privacy is essentieel in zulke ruimten. Focusruimten zijn doorgaans niet aan iemand toegewezen en kunnen tijdelijk worden geboekt als dat nodig is voor relevante werkzaamheden.",
  fileRoomDesc:
    "Dit is een bergruimte voor papieren archieven voor individuen en teams. Als gevolg van de digitaliseringsinspanningen neemt bij de meeste kantoren de vraag naar individuele en teambergruimte sterk af. Toch hebben sommige bedrijven behoefte aan een centrale ruimte voor wettelijk vereiste documenten of materialen voor langlopende projecten. Deze ruimte is vooral nuttig omdat de meeste open werkplekken weinig bergruimte hebben.",
  centralReceptionHubDesc:
    "Het receptiegebied is belangrijk voor de eerste indruk die werknemers, klanten en andere bezoekers van je bedrijf krijgen. Het moet het merk van het bedrijf op een consistente manier presenteren en de gewenste uitstraling hebben. De balie van de bewaker moet comfortabel zijn; zachte zitplaatsen moeten uitnodigend zijn voor bezoekers.",
  officeDesc:
    "Kantoren zijn afgesloten Individuele Werkplekken voor medewerkers. Ze zijn heel geschikt om afleiding te beperken en geconcentreerd te kunnen werken. Kantoren zijn doorgaans voorzien van een bureau met bureaustoel en bezoekersstoelen. De hoeveelheid persoonlijke bergruimte en archiefkastruimte in kantoren varieert. De grootte van de ruimte kan ook variëren afhankelijk van de activiteiten die in de ruimte plaatsvinden (bijv. regelmatige vergaderingen) of de status/hiërarchie van de medewerker.",
  benchDeskDesc:
    "Benches zijn open individuele werkplekken. Kleinere benches kunnen tijdelijk (in een ruimte zonder eigen werkplekken) of permanent door fulltimemedewerkers worden gebruikt. Deze werkplekken zijn ook heel geschikt voor bezoekende werknemers of ingehuurde krachten die niet vast op kantoor werken. Enkele voordelen van open benches zijn de zichtbaarheid in het kantoor en de samenwerking die daarbij hoort. Een nadeel kan de afleiding door geluiden zijn. Daarom wordt aangeraden in kantoren met open benches voldoende focusruimten en huddle rooms te creëren. De hoeveelheid bergruimte voor documenten op de werkplek is beperkt omdat er op de verdieping waarschijnlijk gemeenschappelijke bergruimte is.",
  meetingRoomDesc:
    "Vergaderruimten zijn afgesloten ruimten bedoeld voor vergaderingen van kleine groepjes van 2-4 personen. Ze moeten voorzien zijn van audio- en videoapparatuur en whiteboards om kennisoverdracht te ondersteunen en ze moeten akoestische privacy bieden: mensen die direct buiten de kamer zitten, mogen niet horen wat er binnen wordt gezegd.",
  meetingRoomDesc6:
    "Vergaderruimten zijn afgesloten ruimten bedoeld voor vergaderingen van kleine groepjes van 6 personen. Ze moeten voorzien zijn van audio- en videoapparatuur en whiteboards om kennisoverdracht te ondersteunen en ze moeten akoestische privacy bieden (d.w.z. mensen die direct buiten de kamer zitten mogen niet horen wat er in de kamer wordt gezegd).",
  meetingRoomDesc8:
    "Vergaderruimten zijn afgesloten ruimten bedoeld voor vergaderingen van kleine groepjes van 8 personen. Ze moeten voorzien zijn van audio- en videoapparatuur en whiteboards om kennisoverdracht te ondersteunen en ze moeten akoestische privacy bieden (d.w.z. mensen die direct buiten de kamer zitten mogen niet horen wat er in de kamer wordt gezegd).",
  meetingRoomDesc12:
    "Vergaderruimten zijn afgesloten ruimten bedoeld voor vergaderingen van kleine groepjes van 12 personen. Ze moeten voorzien zijn van audio- en videoapparatuur en whiteboards om kennisoverdracht te ondersteunen en ze moeten akoestische privacy bieden (d.w.z. mensen die direct buiten de kamer zitten mogen niet horen wat er in de kamer wordt gezegd).",
  meetingRoomDesc15:
    "Vergaderruimten zijn afgesloten ruimten bedoeld voor vergaderingen van kleine groepjes van 15 personen. Ze moeten voorzien zijn van audio- en videoapparatuur en whiteboards om kennisoverdracht te ondersteunen en ze moeten akoestische privacy bieden (d.w.z. mensen die direct buiten de kamer zitten mogen niet horen wat er in de kamer wordt gezegd).",

  openCollabSpace4to6Desc:
    "Deze ruimten zijn bestemd voor gezamenlijke activiteiten van kleine groepen (4-6 personen), zoals brainstormen en informele gesprekken met collega's en klanten.",
  cafeFoodServiceDesc:
    "De centrale koffiehoek is bedoeld als een plek om te eten, elkaar te ontmoeten, individueel te werken en kleine besprekingen te houden. Voor de inrichting van deze ruimte moet worden gedacht aan functionele eettafels en een kleurpalet dat past bij het merk.",
  coatClosetsDesc:
    "Werknemers hebben een plek nodig om hun jas op te hangen en andere grote spullen op te bergen, vooral als een kantoor een open indeling heeft met beperkte persoonlijke bergruimte bij de werkplek. Deze kunnen verspreid door het kantoor worden gecreëerd, bijvoorbeeld bij uitgangen en in de hoofdgangen. Er is meestal een plank boven het hanggedeelte voor hoeden en andere accessoires en bergruimte voor koffers onder in de kast.",
  copyPrintDesc:
    "Kopieer- en printgebieden verspreid over verdiepingen met open werkruimten geven werknemers snel toegang tot multifunctionele apparaten. Kopieer- en printgebieden moeten altijd worden voorzien van papierbakken voor recycling en kleine kasten om extra papier en voorraden op te bergen. Deze ruimten zijn uitsluitend bedoeld om binnen te lopen als dat nodig is, niet als ontmoetings- of werkplek.",
  specialResourceBuildingSupportAreaDesc:
    "Dit is een verzamelnaam voor aanvullende ondersteuningsruimten die in een kantoor aanwezig kunnen zijn. Voorbeelden hiervan zijn een beveiligingsbalie of een ruimte met bepaalde voorzieningen. Dit varieert per organisatie, maar naarmate het kantoor groter wordt, zullen vaker dit soort aanvullende ruimten nodig zijn.",
  secondaryServerRoomDesc:
    "Secundaire serverruimte (technologieruimte) waar IT-apparatuur veilig is opgesteld en waar laagspanningskabels uitkomen. De ruimte moet 24 uur per dag worden geventileerd en op een constante temperatuur van 22 °C worden gehouden, met een relatieve luchtvochtigheid van 50%.",
  mothersRoomDesc:
    "Kolfruimtes zijn bedoeld voor individuele momenten met absolute privacy, voor moeders die borstvoeding geven. Kolfruimtes zijn meestal verplicht voor alle nieuwe kantoren, ongeacht de grootte of het aantal mensen dat er werkt.",
  visitorCoatLuggageRoomDesc:
    "Bezoekers hebben een plek nodig om hun jas op te hangen en andere grote spullen op te bergen. Deze kunnen verspreid door het kantoor worden gecreëerd, bijvoorbeeld bij uitgangen en in de hoofdgangen. Er is meestal een plank boven het hanggedeelte voor hoeden en andere accessoires en bergruimte voor koffers onder in de kast.",

  wellnessRoomDesc:
    "Welzijnsruimten zijn bedoeld voor individuele momenten met absolute privacy, voor mensen die een korte adempauze nodig hebben. ",

  internalStairDesc:
    "Interne trappen kunnen centrale trappenhuizen zijn die toegang geven tot de verschillende verdiepingen, of aanvullende designelementen die de verdiepingen van een kantoor met elkaar verbinden. Het zijn vaak verzamelplekken binnen het kantoor en kunnen worden gecentraliseerd voor de koffiehoek, receptie of vergaderruimten.",
  commercialPrintRoomDesc:
    "Veel bedrijven bieden mogelijkheden om intern presentaties af te drukken en in te binden en te bundelen in de printruimte, die vaak wordt gecombineerd met de postkamer. Er staan meerdere printers, waaronder mogelijk grootformaatprinters. Er is opbergruimte aanwezig voor voorraden en een werkblad voor kleinere apparatuur, maar ook ruimte om het drukwerk uit te spreiden.",
  pantryVendingCoffeeDesc:
    "In tegenstelling tot de centrale koffiehoek is de pantry/automatenruimte bedoeld voor medewerkers die snel even iets te eten of te drinken willen pakken. Op plekken waar eten is, wordt meestal ook gepraat, maar er is doorgaans geen zitgelegenheid in deze ruimten, dus mensen zullen binnenkomen, snel weer weggaan en niet lang blijven praten.",
  storageDesc:
    "Opslagruimten zijn bedoeld voor het opbergen van allerlei spullen, zoals grote apparatuur, extra meubels, bulkvoorraden, feestversiering e.d. Ze kunnen ook worden gebruikt om jassen en tassen van bezoekers op te bergen. Het is handig om een kleine opbergruimte in de buurt van de huismeester te hebben.",
  centralisedDispersedMailAreaDesc:
    "Grotere bedrijven hebben een postkamer nodig om de binnenkomende en uitgaande post te organiseren. In deze ruimte is speciale apparatuur voor postverwerking aanwezig. Er zijn ook oppervlakken voor het sorteren van papier en andere materialen. Meestal is hier één medewerker fulltime werkzaam.",
  lockerDesc:
    "Kluisjes worden aangeboden als een vorm van persoonlijke opbergruimte. Ze geven medewerkers een plek om hun kostbaarheden, persoonlijke eigendommen en werkdocumenten te bewaren. Kluisjes kunnen vast worden toegewezen of worden gebruikt op basis van 'wie het eerst komt, het eerst maalt'.",
  mainITandServerRoomDesc:
    "Primaire serverruimte (technologieruimte) waar IT-apparatuur veilig is opgesteld en waar laagspanningskabels uitkomen. De ruimte moet 24 uur per dag worden geventileerd en op een constante temperatuur van 22 °C worden gehouden, met een relatieve luchtvochtigheid van 50%.",
  drawerLateralFileDesc:
    "Persoonlijke individuele bergruimte bij werkplekken of in kantoren. Meestal gebruikt voor persoonlijke spullen of materialen van het project waaraan wordt gewerkt.",
  juiceBarCoffeeShopDesc:
    "Niet elk bedrijf heeft een volledige keuken en een buffet, maar er kan een koffiehoek of sapbar worden aangeboden als onderdeel van de eet- en drinkvoorzieningen in de ruimte. Zo’n ruimte kan ook een aanvulling zijn op de hoofdkantine, vooral als het kantoor groot is. Deze ruimte ziet er waarschijnlijk uit zoals een filiaal van Starbucks, met werkruimte voor een medewerker achter een toonbank.",
  grabNGoDesc:
    "Een kleine gemaksruimte met een aantal koelkasten, planken voor chips en snacks en een betaalautomaat. Deze ruimten zijn meestal voorzien van camerabewaking. Ze worden vaak gecombineerd met een kleine pantry met koelkasten, vriezers en een aanrecht.",
  smMeetingConferenceRoomDesc:
    "Een kleine overlegruimte is een afgesloten ruimte waar 2-6 mensen comfortabel aan een tafel kunnen zitten. Deze ruimten kunnen zowel voor intern overleg als voor gesprekken met klanten worden gebruikt en moeten daarom zeer gebruiksvriendelijk zijn, met eenvoudige toegang tot stopcontacten en audio- en videoapparatuur.",
  mdMeetingConferenceRoomDesc:
    "Een middelgrote overlegruimte is een afgesloten ruimte waar 6-10 mensen comfortabel aan een tafel kunnen zitten. Deze ruimten worden zowel voor intern overleg als voor gesprekken met klanten gebruikt en moeten daarom zeer gebruiksvriendelijk zijn, met eenvoudige toegang tot stopcontacten en audio- en videoapparatuur.",
  lgMeetingConferenceRoomDesc:
    "Een grote overlegruimte is een afgesloten ruimte waar 12-14 mensen comfortabel aan een tafel kunnen zitten. Extra stoelen langs de wanden zijn aan te bevelen. Deze ruimten kunnen zowel voor intern overleg als voor gesprekken met klanten worden gebruikt en zullen waarschijnlijk worden gebruikt voor presentaties. De meubels moeten verplaatsbaar zijn om de ruimte ook voor andere doeleinden te kunnen gebruiken, zoals yogasessies voor werknemers.",
  xlMeetingRoomDesc:
    "Een bestuurskamer is een ruimte waar 14-18 mensen comfortabel aan een tafel kunnen zitten, met extra stoelen langs de wanden voor minstens nog 10 mensen. Deze ruimten worden waarschijnlijk gebruikt voor grote interne formele vergaderingen en grote besprekingen met klanten. Ze moeten daarom voorzien zijn van hoogwaardige audio- en videotechnologie en worden ingericht met de klant in gedachten.",

  // Space Unit Map Descriptions (spaceUnitMap.js) - US_SMALL and US_REGULAR
  lgOfficeDesc:
    "Kantoren zijn afgesloten individuele werkplekken voor medewerkers. Ze zijn heel geschikt om afleiding te beperken en geconcentreerd te kunnen werken. Kantoren zijn doorgaans voorzien van een bureau met bureaustoel en bezoekersstoelen. De hoeveelheid persoonlijke bergruimte en archiefkastruimte in kantoren varieert. De grootte van de ruimte kan ook variëren, afhankelijk van de activiteiten die in de ruimte plaatsvinden (bijv. regelmatige vergaderingen) of de status/hiërarchie van de medewerker. Dit wat grotere kantoor kan het kantoor van een partner zijn of dienstdoen als huddle room.",
  smWorkstationDesc:
    "Werkstations zijn open individuele werkplekken. Kleinere werkstations kunnen tijdelijk (in een ruimte zonder eigen werkplekken) of permanent door fulltimemedewerkers worden gebruikt. Deze werkplekken zijn ook heel geschikt voor bezoekende werknemers of ingehuurde krachten die niet vast op kantoor werken. Enkele voordelen van open werkstations zijn de zichtbaarheid in het kantoor en de samenwerking die daarbij hoort. Een nadeel kan de afleiding door geluiden zijn. Daarom wordt aangeraden in kantoren met open werkstations voldoende focusruimten en huddle rooms te creëren. De hoeveelheid bergruimte voor documenten op de werkplek is beperkt omdat er op de verdieping waarschijnlijk gemeenschappelijke bergruimte is.",
  mdWorkstationDesc:
    "Werkstations zijn open individuele werkplekken. De voordelen van deze wat grotere ruimte zijn onder meer extra bergruimte en privacy. Meestal geldt: hoe groter het bureau, hoe waarschijnlijker het is dat het vast aan iemand is toegewezen en niet voor algemeen gebruik is. Enkele voordelen van open werkstations zijn de zichtbaarheid in het kantoor en de samenwerking die daarbij hoort. Een nadeel kan de afleiding door geluiden zijn. Daarom wordt aangeraden in kantoren met open werkstations voldoende focusruimten en huddle rooms te creëren. Op de verdieping zullen waarschijnlijk aanvullende gezamenlijke bergruimten en archiefkasten zijn.",
  lgWorkstationDesc:
    "Werkstations zijn open individuele werkplekken. De voordelen van deze wat grotere ruimte zijn onder meer extra bergruimte en privacy. Bureaus van dit formaat zijn meestal aan iemand toegewezen en niet voor algemeen gebruik. Enkele voordelen van open werkstations zijn de zichtbaarheid in het kantoor en de samenwerking die daarbij hoort. Een nadeel kan de afleiding door geluiden zijn. Daarom wordt aangeraden in kantoren met open werkstations voldoende focusruimten en huddle rooms te creëren. Op de verdieping zullen waarschijnlijk aanvullende gezamenlijke bergruimten en archiefkasten zijn.",
  huddleRoomDesc:
    "Huddle rooms zijn afgesloten ruimten bedoeld voor vergaderingen van kleine groepjes van 2-4 personen. Ze moeten voorzien zijn van audio- en videoapparatuur en whiteboards om kennisoverdracht te ondersteunen en ze moeten akoestische privacy bieden: mensen die direct buiten de kamer zitten, mogen niet horen wat er binnen wordt gezegd.",
  multiPurpTrainingDesc:
    "Multifunctionele ruimten zijn bedoeld om plaats te bieden aan een groot aantal mensen (meer dan 20). De benaming van deze ruimte is passend, aangezien hier veel verschillende activiteiten kunnen plaatsvinden, waaronder workshops, bedrijfsevenementen en grote presentaties. De afwerking van deze ruimte is functioneel/duurzaam, want er komen veel mensen, maar er kunnen ook klanten worden ontvangen. Deze ruimte moet passende audio- en videofaciliteiten hebben – doorgaans veel meer dan de gemiddelde overlegruimte.",
  informalCollabDesc:
    "Deze ruimten zijn bestemd voor gezamenlijke activiteiten van kleine groepen (4-6 personen), zoals brainstormen en informele gesprekken tussen collega’s en met klanten.",
  touchdownTeamDesc:
    "Touchdowns zijn individuele werkplekken of kleine interne teamwerkplekken die verspreid zijn door het kantoor en door medewerkers en bezoekers kunnen worden gebruikt. Touchdowns kunnen verschillende hoogtes, vormen en maten hebben, maar ze onderscheiden zich van individuele werkstations als unieke plekken om gedurende korte tijd te werken. Deze ruimten moeten plug-and-play zijn.",
  frontDeskAreaDesc:
    "Dit is het eerste wat werknemers en bezoekers zien als ze het kantoor binnenkomen. De balie moet plaats bieden aan ten minste één persoon met persoonlijke werkruimte en opbergruimte. Waarschijnlijk is er ook opbergruimte voor bezoekers in de buurt. De ontvangstbalie bevindt zich in het grotere receptiegebied waar waarschijnlijk ook zitplaatsen en andere voorzieningen zijn.",
  mailCenterAreaDesc:
    "Grotere organisaties hebben een postkamer nodig om alle postdiensten te centraliseren. Hier moet veel ruimte zijn om te sorteren en daarnaast ruimte voor tabellen en apparatuur. Deze ruimte ziet er waarschijnlijk bij elke organisatie anders uit, want hij wordt vaak ingepast tussen andere elementen.",
  kitchenAreaDesc:
    "Als er in het kantoor een kantine aanwezig is, is er een keuken nodig om deze te ondersteunen. De vereisten voor zo’n keuken variëren per organisatie, maar er zullen in elk geval keukenapparaten en koelkasten zijn. Er is ook bergruimte nodig voor houdbare producten en kookgerei. De keuken wordt aangrenzend aan het restaurant en waarschijnlijk de overlegruimten/ruimten voor klantenontvangst gesitueerd.",
  serveryAreaDesc:
    "Het buffet is de plek waar de mensen hun eten halen. Het is waarschijnlijk verbonden met de keuken en een eetruimte met tafels en stoelen. Bij de plaatsing van het buffet moet goed rekening worden gehouden met rijen en looproutes. De vorm en grootte van deze ruimte varieert waarschijnlijk per organisatie.",
  diningSupportAreaDesc:
    "Een organisatie met een uitgebreide kantine heeft waarschijnlijk ook aanvullende ruimten zoals opslag in andere delen van het gebouw, waarmee rekening moet worden gehouden bij de algehele planning. De ruimte varieert per organisatie.",
  diningAreaDesc:
    "Een eetruimte bij de keuken moet mensen verschillende opties bieden om te gaan zitten en hun lunch te eten. Als er verschillende soorten stoelen zijn, maakt dat de ruimte ook geschikt om buiten de lunchtijden om te worden gebruikt voor vergaderingen en samenwerking. Als er harde en zachte stoelen van verschillende hoogtes worden aangeboden, kunnen de gebruikers kiezen.",

  // Space Unit Map Descriptions (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDesc:
    "Als er een kantine aanwezig is in het kantoor, zijn er diverse functies nodig om deze faciliteit te ondersteunen, waaronder een keuken, buffet, eetruimte (met tafels en stoelen) en ondersteunende ruimte (opslag). De keuken en het buffet werken samen voor het bereiden en serveren van huisgemaakte etenswaren. De kantine is de plek waar werknemers en gasten kunnen eten, maar vaak is dit ook een ontmoetingsplek voor sociale contacten en samenwerking. Net als thuis is er veel keukengerei en opslagruimte nodig om maaltijden te kunnen aanbieden en daarom bevat het ruimtetype Volledige maaltijdservice ook ondersteunende ruimte.",

  // SpaceUnitRow
  spaceUnitRowDeleteTitle: "Ruimtetype verwijderen",
  spaceUnitRowDeleteText: "Weet je zeker dat je dit ruimtetype wilt verwijderen?",
  spaceUnitRowConfirmDeleteText: "Ja, ik weet het zeker",
  spaceUnitRowCancelDeleteText: "Nee, ik wil het ruimtetype toch houden",

  // CustomAmenityAdder
  customAmenityAddText: "Een faciliteit toevoegen",
  customAmenityPlaceholder: "Type faciliteit",

  // SpaceUnitTotals
  spaceUnitTotalsTitleNIA: "Totale netto binnenoppervlakte",
  spaceUnitTotalsTitle: "Totaal {{sfTypeAdj}} vierkante {{unitCapitalized}}",
  spaceUnitTotalsCirculation: "Circulatie",
  spaceUnitTotalsCirculationTooltip:
    "De ruimte tussen ruimten: denk aan gangen, de ruimte achter je stoel en de ruimte rondom kasten, deuren en hoeken. Zonder deze ruimte kun je nergens lopen. Beslaat meestal ongeveer 35% van het totale aantal bruikbare {{unitCapitalized}} in je programma, maar dat varieert afhankelijk van je profiel en het uiteindelijk gekozen gebouw.",
  spaceUnitTotalsREBNY: "REBNY-factor",
  spaceUnitTotalsNYCText: "New York City ",
  spaceUnitTotalsREBNYTooltip:
    "Voor programma’s in New York City verhogen we {{sfType}} met 15% om rekening te houden met de meetnorm REBNY. Ruimten die worden gemeten volgens de REBNY-norm omvatten onder andere toiletten, liftlobby’s en de dikte van de buitenmuren.",
  spaceUnitTotalsLossFactor: "Verliesfactor ",
  spaceUnitTotalsLossFactorTooltip:
    "De bruikbare vierkante {{unit}} geven aan hoeveel ruimte je in je kantoor hebt. De verhuurbare vierkante {{unit}} zijn de ruimte waarvoor je betaalt, inclusief eventuele pilaren, lobby’s en andere gebruikelijke gedeelten. Het verschil tussen bruikbaar en verhuurbaar noemen we de verliesfactor en hoe die wordt berekent, varieert per locatie.",

  // CollaborationDropdown
  collabDropdownLevelLowText: "een beetje (0-25%)",
  collabDropdownLevelMedText: "een deel (26-50%)",
  collabDropdownLevelHighText: "het meeste (51-100%)",

  //DensityDropdown
  sameDensityText: "Dezelfde dichtheid als vóór corona",
  lowerDensityText: "Lagere dichtheid (meer m2 per persoon)",
  notSureDensityText: "Weet niet zeker",

  //CirculationDropdown
  preCovidText: "Vóór corona",
  higherText: "Grotere circulatie (50% open/30% afgesloten)",
  notSureText: "Weet niet zeker (45% open/25% afgesloten)",

  // ExamplePlans
  examplePlanButtonText: "Voorbeeldplattegrond bekijken",
  examplePlanFullFloorText: "volledige verdieping",
  examplePlanPartialFloorText: "deel verdieping",
  examplePlanFullImgAlt:
    "Plattegrond {{size}} vierkante {{unit}} met een {{profileName}}-profiel van Spacer by CBRE",
  examplePlanPartialImgAlt:
    "Gedeeltelijke plattegrond {{size}} vierkante {{unit}} met een {{profileName}}-profiel van Spacer by CBRE",
  examplePlanTooltipText:
    "Als je bedrijf groter is dan {{size}} {{unit}}, heb je misschien meer dan één verdieping nodig.",

  // ProgramStats
  programStatsDensitySubtitleTsubo: "Netto tsubo",
  programStatsDensitySubtitle: "{{adjective}} vierkante {{unitUppercase}}",
  programStatsIndivTitle: "Individuele Werkplekken",
  programStatsWorkstationsSubtitle: "Open Werkplekken",
  programStatsOfficesSubtitle: "Gesloten Werkplekken (kantoor)",
  programStatsCollabTitle: "Samenwerking",
  programStatsEnclosedSubtitle: "Vergaderstoel (In afgesloten vergaderruimte)",
  programStatsIndivSeatSubtitle: "Individuele werkplek",
  programStatsSeatsTitle: "Werkplekken per persoon",
  programStatsIndivSeatsSubtitle: "Individuele Werkplekken",
  programStatsPersonSubtitle: "Persoon",
  programStatsDensityTitle: "Dichtheid",
  programStatsFullDensitySubtitle:
    "Vierkante meter VVO per individuele werkplek",

  // Profile Display Names (profileNames.js)
  efficientName: "De Optimalisator",
  whiteCollarName: "De Facilitator",
  whiteGloveName: "De Uitblinker",
  openOfficeName: "De Producent",
  workplace360Name: "De Verbinder",
  campusName: "De Onderscheider",
  startupName: "De Broedplaats",
  engCreativeName: "De Maker",

  // Profile Descriptions (profileDescriptions.js)
  efficientDesc:
    "Het Optimalisator-kantoor is een no-nonsenseomgeving: zonder toeters en bellen, mét alle benodigdheden om de klus te klaren. Dit kantoor is in basis een plek om geconcentreerd en zelfstandig te werken. Om de beschikbare ruimte optimaal te benutten, is er een open vloerplan. Samenwerking en teammeetings komen weinig voor; collega’s werken het liefst individueel, aan hun eigen bureau. Werkplekken zijn gestandaardiseerd en bieden precies wat medewerkers nodig hebben, zonder overbodige en afleidende functionaliteiten. Werkzaamheden vinden voornamelijk plaats op kantoor.",
  whiteCollarDesc:
    "Als je een Facilitator-kantoor zoekt, ben je niet de enige: dit is een van de populairste profielen. Het valt op – niet vanwege radicaal ruimtedesign, maar door technische snufjes en moderne meubels. Zo stimuleert het kantoor productiviteit en is het uitnodigend, als een soort tweede thuis. Zo is het niet alleen een plek om te werken, maar ook om de bedrijfsidentiteit uit te dragen, talent aan te trekken, en te leren en ontwikkelen. Collega’s werken veel aan hun eigen bureau en nemen af en toe deel aan teamsessies en andere groepsactiviteiten. Hybride werken is mogelijk, maar medewerkers zijn vooral op kantoor te vinden.",
  whiteGloveDesc:
    "Zodra je een Uitblinker-kantoor binnenstapt, voel je het meteen: the sky is the limit. Dit kantoortype is helemaal afgestemd op de behoeften van werknemers én klanten, die vaak langskomen. Hoofddoelen? Gefocust werken, talent aantrekken en de bedrijfsidentiteit uitdragen. Meer dan bij alle andere profielen ligt de nadruk op afgesloten vergaderruimtes en werkplekken. Veel medewerkers hebben hun kantoorruimte verdiend dankzij prestaties of aantal arbeidsjaren. Onder collega’s speelt hiërarchie meestal een belangrijke rol. Ze zijn dan ook vooral op kantoor te vinden – remote werken is minder gebruikelijk.",
  openOfficeDesc:
    "Een Producent-kantoor biedt alles wat medewerkers nodig hebben om hun werk zo goed mogelijk te doen. De verschillende ruimtes en functionaliteiten worden intensief gebruikt en dragen bij aan een efficiënt proces. Collega’s werken ongeveer even vaak alleen als samen, en verplaatsen zich tijdens een werkdag regelmatig door het kantoor. Medewerkers zijn dus veel in beweging, wat een dynamische werksfeer oplevert. Die past perfect bij de rol van het kantoor: verandering aanjagen binnen de organisatie. Remote werken kán, en komt dus soms voor, maar de meeste werkzaamheden vinden plaats op locatie.",
  workplace360Desc:
    "Een Verbinder-kantoor staat evenwicht centraal: het biedt precies de juiste mix voor concentratie en connectie. Er is genoeg plek om in alle rust te werken, maar er zijn ook voldoende opties om met collega’s te overleggen. Gemeenschappelijke ruimtes als keukens en open vergaderzalen nemen een centrale plek in. Dit brengt medewerkers samen en zorgt voor een gemeenschapsgevoel. Werkplekken zijn vooral gedeeld – mensen zitten regelmatig ergens anders of werken remote. Al deze elementen samen maken dit kantoor tot een broedplaats voor nieuwe ideeën, waar je graag naartoe gaat om je collega’s te ontmoeten.",
  campusDesc:
    "Medewerkers hebben moeite om een Onderscheider-kantoor te verlaten aan het eind van de werkdag. Het biedt namelijk een werkervaring waar geen enkele andere werkplek aan kan tippen. Zo zijn er uitgebreide faciliteiten die collega’s helpen om productief te zijn, tijd te besparen en op te laden. Snacks, spelletjes en comfortabele zitplekken: hier wordt iedereen aangemoedigd om regelmatig pauzes te nemen, sociaal te zijn en te ontspannen. Dit zorgt ervoor dat collega’s elkaar graag op kantoor ontmoeten, ook als ze vaak remote werken.",
  startupDesc:
    "Dynamiek en creativiteit, daar gaat het om in een Broedplaats. Collega’s werken nauw met elkaar samen en voelen zich sterk verantwoordelijk. Omdat deze kantooromgeving constant verandert, zijn de meeste ruimtes flexibel opgezet. Met meubels schuiven en ad hoc-oplossingen verzinnen, komt daarom regelmatig voor. Om een saamhorigheidsgevoel binnen het team te versterken, is er genoeg ruimte om intensieve werksessies en leuke sociale activiteiten te organiseren – denk aan pingpongwedstrijden en hackathons. Het kantoor voelt als een tweede thuis, ook voor collega’s die vaak remote werken.",
  engCreativeDesc:
    "Elk Maker-kantoor is uniek in z’n soort. Je vindt hier voornamelijk specialisten die met elkaar aan uitdagende, creatieve projecten werken. Op dit kantoor vinden ze alles wat ze nodig hebben om hun werk goed uit te voeren, zoals gespecialiseerde apparatuur of fysieke materialen. Collega’s schieten elkaar bij vragen makkelijk even aan, maar kunnen zich ook terugtrekken om geconcentreerd te werken. Het kantoor is een plek om ideeën uit te wisselen, talent aan te trekken, en te leren en ontwikkelen.",

  // Profile Key Traits: Shared Names (profileTraitMap.js)
  social: "Sociaal",
  efficient: "Efficiënt",
  flexible: "Flexibel",
  enabled: "Goed uitgerust",
  productive: "Productief",
  inclusive: "Inclusief",
  clientFocused: "Klantgericht",
  standardized: "Gestandaardiseerd",

  // Profile Key Traits (profileTraitMap.js)
  social1Desc: "Relaties binnen en buiten het kantoor zijn belangrijk",
  social2Desc: "Veel gemeenschapsversterkende evenementen",
  social3Desc: "Medewerkers brengen graag tijd met elkaar door",
  social4Desc: "Meer open en gericht op de gemeenschap",
  efficient1Desc: "Bedrijf blijft slank en geldbewust",
  efficient2Desc: "Bedrijf blijft slank en productief",
  flexible1Desc: "Mensen kunnen kiezen hoe ze willen werken",
  flexible2Desc: "Werknemers hebben veel vrijheid",
  flexible3Desc: "Bouw de ruimte die je nodig hebt",
  enabled1Desc: "Werknemers hebben wat ze nodig hebben op hun werkplek",
  enabled2Desc:
    "Werknemers hebben uitgebreide diensten en faciliteiten tot hun beschikking",
  enabled3Desc: "Mensen kunnen krijgen wat ze nodig hebben om hun werk te doen",
  enabled4Desc: "Toegang tot de juiste technologie en apparatuur",
  enabled5Desc: "Gespecialiseerde apparatuur is beschikbaar",
  productive1Desc: "Functioneel en intuïtief kantoorontwerp",
  productive2Desc: "Bedrijfsgericht en geldbewust",
  productive3Desc: "Draait om een uniek product-/dienstenaanbod",
  inclusive1Desc: "Meerdere typen ruimten beschikbaar",
  inclusive2Desc: "Je vindt alles wat je nodig hebt in dit kantoor",
  inclusive3Desc: "Veel verschillende werkstijlen zijn acceptabel",
  clientFocused1Desc: "Eerste indrukken van klanten zijn alles",
  clientFocused2Desc:
    "Het kantoor is uitnodigend voor klanten en nieuwe medewerkers",
  clientFocused3Desc:
    "Het kantoor is piekfijn in orde voor klanten en nieuwe medewerkers",
  standardized1Desc: "Processen en systemen zijn gestroomlijnd",
  standardized2Desc: "Organisatienormen rondom ruimteverdeling",
  standardized3Desc: "Modulaire afmetingen voor flexibiliteit",
  standardized4Desc: "Gestroomlijnde processen en deliverables",

  // SpaceSummary
  spaceSummaryBarHeader: "Aanbevolen kantoorgrootte",
  spaceSummaryMeasurementTypeText: "type meting",
  spaceSummaryUnitTypeText: "eenheden",
  spaceSummaryLossFactorSectionLabel: "Details {{factor}}",
  spaceSummaryFitFactorText: "Pasfactor",
  spaceSummaryLossFactorText: "Verliesfactor",
  spaceSummaryRSFLossFactorHelperText:
    "Op basis van een verliesfactor van {{percentage}}% voor vastgoed in {{place}} moet je zoeken naar ruimten van {{min}} tot {{max}} verhuurbare vierkante {{units}}.",
  spaceSummaryNIAFitFactorHelperText:
    "Op basis van een pasfactor van {{percentage}}% voor vastgoed in {{place}} moet je zoeken naar ruimten met {{amt}} netto binnenoppervlakte.",
  spaceSummaryNIATooltipText:
    "Netto binnenoppervlakte is het bruikbare oppervlak binnen een gebouw, gemeten vanaf de binnenkant van de buitenmuren op elke verdieping, waarbij bepaalde gedeelten niet worden meegeteld.",

  // SFTypeRadioInput
  sfRadioInputUsableAreaTooltipText:
    "Deze meting omvat alle ruimten, meubels en circulatieruimte. Het wordt ook wel de ‛carpetable’ oppervlakte genoemd.",
  sfRadioInputRentableAreaTooltipText:
    "Dit zijn normaliter de bruikbare vierkante {{unitUppercase}} plus een deel van de algemene ruimten van een gebouw. Hoewel je die oppervlakte niet volledig kunt gebruiken, zijn dat de vierkante {{unit}} waarvoor je huur betaalt.",
  sfRadioInputUsableSquareText:
    "Functioneel Nuttig Oppervlak {{unitUppercase}}",
  sfRadioInputRentableSquareText:
    "Verhuurbaar Vloeroppervlak {{unitUppercase}}",

  //floored plan request block
  flooredTitle: "Je bent er bijna.",
  flooredSubTitle:
    "Bepaal hoe klanten je ruimten zien.",
  flooredSubText:
    "Loop door je Spacer-resultaten heen in een 3D-plattegrond!",
  flooredParagraphText1:
    "Duik in je nieuwe werkplek. Zo zie je de mogelijkheden voor je, kun je experimenteren met de ruimte en zet je ideeën om in werkelijkheid.",
  flooredParagraphText2:
    "Tastbare, persoonlijke visie voor elke mogelijke ruimte",
  flooredVideoHeading: "Bekijk een voorbeeld",
  flooredFreeText: "En het is ook nog gratis!",
  flooredIconText1: "Beschikbare plattegronden delen",
  flooredIconSubText1: "AutoCAD-bestanden voor pdf’s",
  flooredIconText2: "Houd rekening met verwerkingstijd",
  flooredIconSubText2: "Eén à twee weken",
  flooredIconText3: "Ontvang een ervaring die je kunt delen",
  flooredIconSubText3: "2D- en 3D-ervaringen op maat",

  // MeasurementSystemRadioInput
  measurementSystemSqFeetText: "Square feet",
  measurementSystemSqMetersText: "Vierkante Meter",
  measurementSystemTsuboText: "Tsubo",

  // ProfileHero
  resultsProfileHeroAnnouncementTitle: "Jouw kantoor is",
  resultsProfileHeroImgAlt:
    "3D-plattegrond van de kantoorindeling van het profiel {{profileName}} van Spacer by CBRE",

  // ProfileComparison
  profileCompModalTitle: "Profiel wijzigen?",
  profileCompModalText:
    "Eventuele niet-opgeslagen wijzigingen in je huidige ruimteprogramma gaan verloren.",
  profileCompModalConfirmText: "Ja, profiel wijzigen",
  profileCompModalCancelText: "Nee, huidig profiel behouden",
  profileCompNavbarCancelButtonText: "Annuleren",
  profileCompNavbarUseButtonText: "Dit profiel gebruiken",
  profileCompCompareTitle:
    "Vergelijk de profielen met de meeste overeenkomsten",

  // ProfileComparisonColumn
  profileCompColumnSelectedText: "Geselecteerd profiel",
  profileCompColumnSelectText: "Selecteren",
  profileCompColumnRecommendedText: " (Aanbevolen)",
  profileCompColumnImgAltText:
    "3D-plattegrond van de kantoorindeling van het profiel {{profileName}} van Spacer by CBRE",

  // Test Fit Form
  testFitTitle:
    "Bekijk je resultaten in een plattegrond en loop er in 3D doorheen",
  testFitSubtitle:
    "Wil je een indruk krijgen van hoe jouw ruimteprogramma eruitziet? We zetten de resultaten om naar een aangepaste plattegrond die je kunt verkennen en delen.",
  requestTestFitButtonText: "Een plattegrond op maat aanvragen",
  company: "Bedrijfsnaam",
  emptyCompanyError: "Voer een bedrijfsnaam in",
  radioQ2yes:
    "Geweldig! We nemen contact op via e-mail om het bestand op te vragen.",
  radioQ2no:
    "Geen probleem. We nemen contact op om de mogelijkheden te bespreken.",
  testFitFormTitle: "Plattegrond op maat aanvragen",
  testFitFormSubtitle:
    "Als je ons wat informatie geeft, gaan we aan de slag. Ons team levert de plattegronden doorgaans binnen drie werkdagen.",
  testFitFormCompleted:
    "Je verzoek is ingediend. Je ontvangt een e-mail van een van onze vertegenwoordigers.",
  testFitFormYes: "ja",
  testFitFormNo: "nee",
  testFitFormQ1: "Heb je een plattegrond om mee te werken?",
  testFitFormQ1No:
    "Geen probleem! Wij kunnen er een verstrekken die je kunt gebruiken.",
  testFitFormQ2: "Heb je een tekening van het vloerplan (pdf, AutoCAD)?",
  testFitTermsConditions:
    "Door dit vakje aan te vinken, ga je akkoord met deelname aan ons bètaprogramma. Je ontvangt één gratis plattegrond van ons. In ruil daarvoor vul je een korte enquête in over je ervaringen.",
  testFitSubmitButtonText: "Verzoek plattegrond indienen",
  backendError: "Oeps! Er is iets misgegaan. Probeer het nog eens.",
};
