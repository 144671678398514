const React                        = require('react');
const PropTypes                    = require('prop-types');
const { withTranslation }           = require('react-i18next');
const Link                         = require('react-router-dom').Link;
const { getReferralLinkFromEmail } = require('../../util/dbServices');

function RetrieveLinkForm(props) {
  const [emailNotAssociated, setEmailNotAssociated] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [triedSubmit, setTriedSubmit] = React.useState(false);
  const [dbError, setDbError] = React.useState(false);
  const emailInputEl = React.useRef(null);

  // focus email input field on load of this form
  React.useEffect(() => emailInputEl.current.focus(), []);

  function emailEmptyOrInvalid() {
    return email === '' || email.substring(email.lastIndexOf('@') + 1, email.lastIndexOf('.')).toLowerCase() !== 'cbre';
  }

  function getLinkFromEmail() {
    getReferralLinkFromEmail(
      email,
      (link) => {
        setEmailNotAssociated(false);
        setDbError(false);
        props.setLink(link);
      },
      (err) => {
        if (err.message === 'We couldn\'t find a referral link associated with that email address.') {
          if (!emailEmptyOrInvalid()) setEmailNotAssociated(true);
        } else {
          setDbError(true);
        }
      }
    );
  }

  let error;
  if (emailNotAssociated) {
    error = (
      <div className="form-error-text" id="email">
        {`${props.t('emailNotAssociated')}`} <Link className="link-in-referral-pages" to="/referral">{props.t('createLink')}</Link>
      </div>
    );
  }
  if (dbError) {
    error = (
      <div className="form-error-text" id="email">
        {`${props.t('somethingWrong')}`}
      </div>
    );
  }
  if (emailEmptyOrInvalid() && triedSubmit) {
    error = (
      <div className="form-error-text" id="email">
        {`${props.t('emptyEmailError')}`}
      </div>
    );
  }

  return (
    <form>
      <h1 className="title has-text-primary flex flex-justify-center">{props.t('enterYourInformation')}</h1>
      <div className="flex flex-col referral-instructions-and-form">
        <label className="flex flex-col">
          {props.t('email')}
          <input
            ref={emailInputEl}
            type="email"
            placeholder={props.t('emailExample')}
            value={email}
            onChange={(evt) => {
              setEmail(evt.target.value);
              setEmailNotAssociated(false);
              setDbError(false);
              setTriedSubmit(false);
            }}
            aria-describedby="email"
            aria-invalid={emailNotAssociated || (emailEmptyOrInvalid() && triedSubmit)}
          />
        </label>
        {error}
      </div>
      <button
        className="button is-primary-filled force-flex submitBtn"
        onClick={(e) => {
          e.preventDefault();
          getLinkFromEmail();
          setTriedSubmit(true);
        }}
      >
        {props.t('next')}
      </button>
    </form>
  );
}

RetrieveLinkForm.propTypes = {
  t: PropTypes.func.isRequired,
  setLink: PropTypes.func.isRequired
};

module.exports = withTranslation('referrals')(RetrieveLinkForm);
