const { connect }          = require('react-redux');
const ExamplePlans         = require('../../../results/program/ExamplePlans.jsx');
const programDataRetriever = require('../../../../util/programDataRetriever');
const regionSFCalculator   = require('../../../../../shared/calculators/regionSFCalculator');
const constants            = require('wp-constants').spacerFree;
const profileCalculator    = require('../../../../calculators/profileCalculator.js');


// Map state to props
function mapStateToProps(state) {
  const calculatedProfileType = profileCalculator.calculateProfileType(
    state.userData.officeLocation,
    state.spaceData.assumptions.headcount,
    state.profileQuiz.userQuizAnswers
  );
  return {
    sfType: state.spaceData.sfType,
    profileId: state.spaceData.profileId,
    measurementSystem: state.spaceData.measurementSystem,
    profileType: calculatedProfileType,
    officeLocation: state.userData.officeLocation,
    totalRSF: regionSFCalculator.getAreaForContext(
      programDataRetriever.getTotalSF(state.spaceData.program.areaTotals),
      constants.calculator.SF_TYPE.RSF,
      state.userData.officeLocation,
      state.spaceData.lossFactor
    )
  };
}

module.exports = connect(mapStateToProps)(ExamplePlans);
