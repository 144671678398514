module.exports = {
  blogHomeTitle: 'Iroda Tervezési Tippek',

  // blog tags
  blogTagAllArticles: 'Minden cikk',
  blogTagSizeAndFit: 'Méret és Megfelelőség',
  blogTagOfficeCulture: 'Irodai kultúra',
  blogTagSpaceAndDesign: 'Tér és Dizájn',
  blogTagWorkplaceTrends: 'Irodaberendezési trendek'
};
