module.exports = {

  // TermsOfService
  TermsTitle: 'Terms Of Service',
  TermsIntroText: 'This is a legal agreement between you and CBRE, Inc. (“CBRE”) regarding access to and use of the application known as Spacer®.  By using or accessing any of the information or services on this web-based application, you acknowledge that you have read, understood and agree to the terms and conditions set forth in this Terms of Use (the “Agreement” or “TOU”). The terms “you”, “your” or “User” refers, collectively, to the person and any entity on whose behalf such person may be acting.',

  TermsLimitationsTitle: 'Limitations.',
  TermsLimitationsA: 'User understands and agrees that User’s access to Spacer® is non-exclusive, non-sublicensable, non-transferable, freely revocable and can be suspended or terminated at any time for any reason by CBRE.',
  TermsLimitationsB: 'User further understands and agrees that the grant of access to Spacer® shall not confer in User any rights of ownership in Spacer® or any related documentation (including in any new releases, modifications or enhancements), all of which shall be and remain the exclusive property of CBRE, including without limitation, the copyrights thereto, associated patents, design patents, trademarks, trade dress, trade secrets and other proprietary rights arising under applicable law or international conventions (collectively, “Proprietary Rights”).  See Section 2 (Ownership) below. CBRE hereby expressly reserves all rights in Spacer® which are not expressly granted to User hereunder.',
  TermsLimitationsC: 'Except as provided herein or as expressly authorized by CBRE to User in writing, User will not: (i) modify, translate, reverse engineer, decompile, disassemble, attempt to reconstruct, identify, or discover any source code, underlying ideas, underlying user interface techniques or algorithms, or create derivative works of or copy Spacer®; (ii) remove, alter, or cover any copyright or trademark notices or other proprietary rights notices contained in Spacer®; (iii) assign, sublicense, or otherwise transfer its access, use or other  rights or delegate its obligations under this Agreement; or (iv) without limiting any of the foregoing, use Spacer® for the purpose of building a competitive product or service, or for any purpose other than legitimate business purposes relating to User’s business.',
  TermsLimitationsD: 'User agrees to notify CBRE promptly in writing if any unauthorized use of Spacer®, or any possible infringement of Proprietary Rights, comes to its attention.',
  TermsLimitationsE: 'At no time during or after the term of this Agreement shall User apply for any registration of any copyright, trademark or other designation which would affect the ownership of Proprietary Rights nor file any documents with any governmental authority to take any action which would affect the ownership of Proprietary Rights, including CBRE’s ownership of Spacer®, the mark Spacer®, and other application components. Spacer® is a trademark and service mark of CBRE.',
  TermsLimitationsF: 'User represents and agrees that User has the right to provide all materials posted on, or information used in connection with, Spacer®, and hereby grants to CBRE a non-exclusive, royalty-free, worldwide, irrevocable, perpetual right and license to use, reproduce, modify, distribute, create derivative works of, and display such materials and information in CBRE’s discretion.  User acknowledges that User is responsible for whatever material and information is submitted by User, including its legality, reliability, appropriateness, originality, and copyright. The license grants by User to CBRE hereunder will survive any termination of User’s use of Spacer®.  Any material and information submitted by User will be considered non-confidential and non-proprietary, and treated as such by CBRE, and may be used by CBRE in accordance with this Agreement without notice to User and without any liability to CBRE.',

  TermsOwnershipTitle: 'Ownership.',
  TermsOwnershipText: 'Spacer®, programming language, software and documentation and all other material or content made available on Spacer® or any improvements, modifications, derivative works, enhancements or changes to Spacer® and all copies thereof are proprietary to CBRE and title thereto remains solely in CBRE under all circumstances, including without limitation all rights to patents, copyrights, trademarks and trade secrets in Spacer® and the improvements, modifications, derivative works, and changes thereto. User understands that the foregoing includes trade secrets and information that is confidential and proprietary to CBRE and agrees to take all necessary actions to protect the confidentiality of such information. User shall not sell, transfer, publish, disclose, display or otherwise make available Spacer® or create or make available any improvements, modifications, enhancements, derivative works, or changes thereto or copies thereof to others.  Termination of this Agreement shall be in addition to and not in lieu of any remedies available to CBRE both in law and equity. The obligations hereunder shall survive the termination of this Agreement.',

  TermsTerminationTitle: 'Termination; Effect of Termination; Remedies.',
  TermsTerminationA: 'Without limiting any other remedies, CBRE may modify, limit, suspend, discontinue or terminate this Agreement and/or User’s use of all or any part of Spacer®, with immediate effect, automatically, with or without notice and without recourse to the courts or other tribunals, for any reason or for no reason.  User agrees that CBRE is under no obligation to provide Spacer® and that CBRE shall not be liable to User or to any other party for any limitation, suspension, discontinuance, termination or modification of Spacer®.',
  TermsTerminationB: 'User acknowledges that the obligations made hereunder to CBRE are of a unique and irreplaceable nature, the loss of which shall irreparably harm CBRE and which cannot be replaced by monetary damages alone so that CBRE shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety) in the event of any breach or anticipatory breach by User. User irrevocably waives all rights to seek injunctive or other equitable relief.',

  TermsDisclaimerTitle: 'Disclaimers and Limitation of Liability.',
  TermsDisclaimerA: 'Spacer® is provided to User “AS IS” and “AS AVAILABLE” with no warranties.  CBRE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. CBRE DOES NOT WARRANT IN ANY WAY THAT Spacer® WILL MEET USER’S REQUIREMENTS OR ANY PARTICULAR STANDARD. SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO USER. In such event the liability of cbre will be limited to the maximum extent possible under applicable law. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY CBRE, ITS AGENTS OR EMPLOYEES, SHALL CREATE A WARRANTY IN ANY WAY WHATSOEVER. NO WARRANTY IS GIVEN THAT Spacer® OR ANY INFORMATION PROVIDED TO USER ARE ERROR FREE OR THAT THE USE OF Spacer® WILL BE UNINTERRUPTED, UP-TO-DATE, COMPLETE OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, AND NO WARRANTY IS GIVEN THAT ANY OF THE FOREGOING WILL BE CORRECTED. CBRE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OF, OR THE RESULTS FROM THE USE OF, Spacer®.  CBRE SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LOST REVENUE, LOST SAVINGS, COMPUTER INTERRUPTION, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES), WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF CBRE OR ITS REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.',
  TermsDisclaimerB: 'CBRE’S TOTAL LIABILITY FOR DAMAGES FOR ANY CAUSE WHATSOEVER SHALL NOT EXCEED THE FEE PAID BY USER FOR Spacer® DURING THE TWELVE (12) MONTH PERIOD PRIOR TO THE EVENT THAT ALLEGEDLY CAUSED SUCH DAMAGE.  USER AGREES THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO Spacer® OR OTHERWISE RELATED TO THIS AGREEMENT MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.',
  TermsDisclaimerC: 'SOME JURISDICTIONS MAY NOT ALLOW THE LIMITATION ON LIABILITY OR EXCLUSIONS OF LIABILITY SET FORTH ABOVE, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO USER. In such event the liability of CBRE will be limited to the maximum extent possible under applicable law.  The waiver of warranty, exclusive remedies and limited liability set forth above are fundamental elements of the basis for this Agreement between CBRE and USER. CBRE would not be able to provide Spacer® on an economic basis, and would not have entered into this Agreement, without such limitations. These limitations shall survive any termination of this Agreement.',

  TermsIndemnificationTitle: 'User Indemnification.',
  TermsIndemnificationText: 'At CBRE’s option and request, User shall, at its own expense, indemnify and hold harmless CBRE and its subsidiaries, affiliates, officers, employees, agents, co-branders, associations, and other partners harmless from and against any losses, costs, damages, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees), arising out of or related to any third-party claim, action or allegation related to or arising from: (a) any transaction or dispute between User and any third party; (b) facts or alleged facts that would constitute a breach of any of User’s representations, warranties, or covenants under this Agreement or of any applicable law, rule or regulation, whether or not referenced herein; (c) any User materials, information or content submitted in or on Spacer®; (d) User’s violation of any rights of any third party, (e) User’s use or misuse of Spacer®; or (f) the negligence or willful misconduct of User.  CBRE shall have the right to exclusively direct and control its defense and hire counsel of its choice, at User’s expense.',

  TermsGeneralTitle: 'General.',
  TermsGeneralA: '<0>Entire Agreement/No Unintentional Waiver.</0> This Agreement constitutes the entire agreement between CBRE and User with respect to Spacer® and all subject matter of this Agreement, and supersedes all prior agreements between CBRE and User related to Spacer® if any. CBRE’s failure to enforce any provision of this Agreement will not be construed as a waiver of any provision or right.',
  TermsGeneralB: '<0>Notices.</0> Notices to you shall be in writing and may be made via email or posting such notices on Spacer® or at www.cbre.com/spacer. You will provide notices to us via email to spacersupport@cbre.com, with a copy sent via certified U.S. mail to CBRE at 400 S. Hope Street, 25th Floor, Los Angeles, CA 90071, Attention:  General Counsel’s Office. Notices will be deemed given one (1) business day after being sent via email (unless the sender receives a response indicating that the message was undeliverable) or three business days after being posted on the CBRE website (in the case of CBRE), whether or not received.',
  TermsGeneralC: '<0>Governing Law.</0> This Agreement shall be governed by and construed in accordance with the laws of the State of California without regard to the conflicts of laws provisions thereof.  CBRE and User hereby subject themselves exclusively to venues within the State of California.  The United Nations Convention on Contracts for the International Sale of Goods (1980) is hereby excluded in its entirety from application to this Agreement. User hereby agrees that Spacer® shall be deemed solely based in California and a passive service that does not give rise to personal jurisdiction over CBRE, either specific or general, in jurisdictions other than the State of California.',
  TermsGeneralD: '<0>Dispute Resolution.</0> Any dispute, claim or controversy arising out of or relating to this Agreement, or the breach, enforcement, interpretation or validity thereof, including the determination of the scope or applicability of arbitration with respect to this Agreement, shall be determined by arbitration.  The arbitration shall be administered by the Judicial Arbitration and Mediation Service (JAMS), and the arbitration shall be conducted with a single arbitrator.  Furthermore, User agrees that neither User nor CBRE will join any claim with the claim of any other person or entity in arbitration; that no claim will be resolved on a class-wide basis; and that neither User nor CBRE will assert any claim in a representative capacity on behalf of anyone else.  Notwithstanding the foregoing, User agrees that CBRE shall be allowed to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.  If User files an arbitration complaint against CBRE, CBRE agrees to pay for any portion of the initial filing fee that exceeds $250; after the initial filing fees, the parties will share the costs of arbitration equally.  User agrees not to commence or prosecute any action against CBRE other than by filing an arbitration complaint in accordance with this paragraph.  IMPORTANTLY, BY AGREEING TO BE BOUND BY THIS AGREEMENT, USER IS WAIVING ANY RIGHT USER HAS TO SEEK RELIEF IN A COURT OF LAW AND ANY RIGHT USER HAS TO A TRIAL BY JURY.',
  TermsGeneralE: '<0>Severability.</0> In the event that a portion of this Agreement is held unenforceable, the unenforceable portion shall be modified only to the extent necessary to make it enforceable, and the remainder of the provisions will remain in full force and effect.',
  TermsGeneralF: '<0>Assignment.</0> This Agreement may not be assigned by User without CBRE’s prior written consent. Any attempted assignment without CBRE’s consent shall be void. CBRE reserves the right to assign this Agreement to any third party. This Agreement and the rights and obligations of each of us under it will be binding on our respective successors and assigns.',
  TermsGeneralG: '<0>Modification.</0> CBRE may modify any of the terms and conditions contained in this Agreement, at any time and in CBRE’s sole and exclusive discretion, by posting a change notice or a new agreement on Spacer®’s site at the url www.cbre.com/spacer or by providing an alert or similar notification in Spacer®.  If any modification is unacceptable to User, User’s only recourse is to terminate User’s use of Spacer®.',
  TermsGeneralH: '<0>Conflicts.</0> In the event there is any conflict between the terms of this Agreement and any fully executed license agreement between User and CBRE with respect to Spacer®, the terms of such license agreement shall control with respect to such conflicting terms.'

};
