const React                    = require('react');
const PropTypes                = require('prop-types');
const _                        = require('lodash');
const HeroSection              = require('../../common/HeroSection.jsx');
const { sendTestFitFormEmail } = require('../../../util/dbServices');
const { withTranslation }       = require('react-i18next');

function TestFitForm(props) {
  // we have companyNameEmpty so that we don't show the empty company name error as soon as form opened
  const [companyName, setCompanyName] = React.useState('');
  const [companyNameEmpty, setCompanyNameEmpty] = React.useState(false);
  const [hasFloorPlate, setHasFloorPlate] = React.useState(null);
  const [hasDrawingFile, setHasDrawingFile] = React.useState(null);
  const [termsAndConditionsAgreed, setTermsAndConditionsAgreed] = React.useState(false);
  const [emailSuccess, setEmailSuccess] = React.useState(true);

  let secondRadioQuestionSpanText;
  if (_.isNull(hasDrawingFile)) {
    secondRadioQuestionSpanText = null;
  } else if (hasDrawingFile) {
    secondRadioQuestionSpanText = (<span id="question2responseText">{props.t('radioQ2yes')}</span>);
  } else {
    secondRadioQuestionSpanText = (<span id="question2responseText">{props.t('radioQ2no')}</span>);
  }

  function submitTestFitForm(testFitFormData) {
    props.submitTestFitForm(testFitFormData);
    const testFitEmailObject = {
      company: testFitFormData.companyName,
      hasFloorPlate: testFitFormData.hasFloorPlate,
      hasDrawingFile: !!testFitFormData.hasDrawingFile,
      id: props.id
    };
    sendTestFitFormEmail(
      testFitEmailObject,
      () => {
        setEmailSuccess(true);
        props.completeTestFitForm();
      },
      () => setEmailSuccess(false)
    );
  }

  return (
    <div className="test-fit has-margin-top-3 has-margin-bottom-3">
      <HeroSection>
        <div className="columns">
          <div className="column is-4 is-offset-1">
            <div className="test-fit-info">
              <h3 className="title">{props.t('testFitFormTitle')}</h3>
              <h4 className="subtitle">{props.t('testFitFormSubtitle')}</h4>
              <img src="/png/test-fit.png" alt="" className="is-hidden-mobile" />
            </div>
          </div>
          <div className="column is-one-half flex">
            <form className={`flex flex-col ${props.completed && emailSuccess ? 'flex-center-all completed-test-fit-form' : ''}`}>
              {
                props.completed && emailSuccess ?
                  (
                    <div className="flex completed-test-fit-form-content">
                      <img src="/svg/icons/checkmark-white-icon-in-green-circle-large-spacer-by-cbre.svg" alt="" />
                      <span>{props.t('testFitFormCompleted')}</span>
                    </div>
                  ) :
                  (
                    <React.Fragment>
                      <h2 className="form-title">{props.t('common:formLabel')}</h2>
                      <input
                        type="text"
                        aria-label="Company name"
                        placeholder={props.t('company')}
                        value={companyName}
                        onChange={(evt) => {
                          setCompanyNameEmpty(false);
                          setCompanyName(evt.target.value);
                        }}
                        aria-describedby="companyName"
                      />
                      <div className={`form-error-text ${!companyNameEmpty ? 'is-invisible' : ''}`} id="companyName">
                        {props.t('emptyCompanyError')}
                      </div>
                      <div className="has-margin-bottom-8">
                        <div id="question1">{props.t('testFitFormQ1')}</div>
                        <div role="radiogroup" aria-labelledby="question1" className="flex test-fit-radio-pair">
                          <label>
                            <input
                              type="radio"
                              checked={!_.isNull(hasFloorPlate) && hasFloorPlate}
                              onChange={() => setHasFloorPlate(true)}
                              aria-describedby="question1responseText"
                              name="question1"
                            />
                            {props.t('testFitFormYes')}
                          </label>
                          <label>
                            <input
                              type="radio"
                              checked={!_.isNull(hasFloorPlate) && !hasFloorPlate}
                              onChange={() => {
                                setHasFloorPlate(false);
                                setHasDrawingFile(false);
                              }}
                              aria-describedby="question1responseText"
                              name="question1"
                            />
                            {props.t('testFitFormNo')}
                          </label>
                        </div>
                      </div>
                      {
                        hasFloorPlate === false &&
                        <span id="question1responseText">{props.t('testFitFormQ1No')}</span>
                      }
                      {
                        hasFloorPlate &&
                        <React.Fragment>
                          <div id="question2">{props.t('testFitFormQ2')}</div>
                          <div role="radiogroup" aria-labelledby="question2" className="flex test-fit-radio-pair">
                            <label>
                              <input
                                type="radio"
                                checked={!_.isNull(hasDrawingFile) && hasDrawingFile}
                                onChange={() => setHasDrawingFile(true)}
                                aria-describedby="question2responseText"
                                name="question2"
                              />
                              {props.t('testFitFormYes')}
                            </label>
                            <label>
                              <input
                                type="radio"
                                checked={!_.isNull(hasDrawingFile) && !hasDrawingFile}
                                onChange={() => setHasDrawingFile(false)}
                                aria-describedby="question2responseText"
                                name="question2"
                              />
                              {props.t('testFitFormNo')}
                            </label>
                          </div>
                          {secondRadioQuestionSpanText}
                        </React.Fragment>
                      }
                      <div className="test-fit-submit-section">
                        {
                          !_.isNull(hasFloorPlate) &&
                          <label className={`test-fit-terms-conditions checkbox in-results ${termsAndConditionsAgreed ? 'is-active' : ''}`}>
                            <input
                              className="visually-hidden"
                              type="checkbox"
                              checked={termsAndConditionsAgreed}
                              onChange={() => setTermsAndConditionsAgreed(prevTermsAndConditionsAgreed => !prevTermsAndConditionsAgreed)}
                            />
                            <span>{props.t('testFitTermsConditions')}</span>
                          </label>
                        }
                        <button
                          className="button cta-button is-primary-filled submit-test-fit-form-button"
                          onClick={(evt) => {
                            evt.preventDefault();
                            if (companyName === '') {
                              setCompanyNameEmpty(true);
                            } else {
                              submitTestFitForm({ companyName, hasFloorPlate, hasDrawingFile });
                            }
                          }}
                          disabled={!termsAndConditionsAgreed}
                          aria-describedby="backendError"
                        >
                          {props.t('testFitSubmitButtonText')}
                        </button>
                        <div className={`form-error-text email-success-error-text ${emailSuccess ? 'is-invisible' : ''}`} id="backendError">
                          {props.t('backendError')}
                        </div>
                      </div>
                    </React.Fragment>
                  )
              }
            </form>
            {
              !props.completed &&
              <button
                onClick={props.closeTestFitForm}
                className="close-test-fit-form-button"
                aria-label="Close test fit request form"
              />
            }
          </div>
        </div>
      </HeroSection>
    </div>
  );
}

TestFitForm.propTypes = {
  submitTestFitForm: PropTypes.func.isRequired,
  completeTestFitForm: PropTypes.func.isRequired,
  closeTestFitForm: PropTypes.func.isRequired,
  completed: PropTypes.bool,
  id: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

TestFitForm.defaultProps = {
  completed: false
};

module.exports = withTranslation('resultsPage')(TestFitForm);
