const { connect }   = require('react-redux');
const ProgramStats  = require('../../../results/program/ProgramStats.jsx');


// Map state to props
function mapStateToProps(state) {
  return {
    location: state.userData.officeLocation,
    sfType: state.spaceData.sfType,
    lossFactor: state.spaceData.lossFactor
  };
}

module.exports = connect(mapStateToProps, null)(ProgramStats);
