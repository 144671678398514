const React        = require('react');
const { Route, Navigate } = require('react-router-dom');

function createRedirectComponent(path) {
  return <Route key={path.to} path={path.from} element={ <Navigate replace to={path.to} /> } />;
}

function createRedirectComponents(paths) {
  return paths.map(createRedirectComponent);
}

module.exports = {
  createRedirectComponents
};
