module.exports = {

  // Results
  resultsWarningMessage: 'Naozaj chcete opustiť túto stránku? Môžete stratiť neuložené údaje.',

  // ResultsHeader
  resultsHeaderExportPDFButtonText: 'Exportovať do PDF',
  resultsHeaderSendButtonText: 'Poslať výsledky',
  resultsHeaderClipboardBoxDescription: 'Výsledky môžu prezerať a upravovať používatelia s odkazom',
  resultsHeaderContactButtonText: 'Spojte sa s poradcom',

  // EmailWallSection
  emailWallTitle: 'Máme pre vás viac',
  emailWallSubtitle: 'Uveďte svoje meno a e-mail, aby ste mohli prezerať kompletné odporúčanie o navrhovanom priestore a upravovať svoje výsledky.',
  emailWallFirstNamePlaceholder: 'Krstné meno',
  emailWallLastNamePlaceholder: 'Priezvisko',
  emailWallEmailPlaceholder: 'Váš e-mail',
  emailWallCBREEmailPlaceholder: 'E-mail vášho experta CBRE (dobrovoľné)',
  emailWallLeasingProfessionalPlaceholder: 'Váš CBRE leasing expert',
  emailWallConnectMeText: 'Spojte ma s mojím osobným realitným poradcom. Žiadne newsletter ani spam, sľubujeme!',
  emailWallContactNoticeText: 'Spacer tím vás môže kontaktovať, aby vám pomohol vyťažiť z vášho programu maximum. Žiadny spam, sľubujeme!',
  emailWallResultsButtonText: 'Zobraziť úplné výsledky',
  emailWallImgAltText: 'Ukážka výsledkov space programu z e-mailovej schránky Spacer od CBRE.',
  emailWallFirstNameInvalid: 'Zadajte svoje krstné meno, prosím.',
  emailWallLastNameInvalid: 'Zadajte svoje priezvisko, prosím.',
  emailWallEmailInvalid: 'Zadajte svoju platnú e-mailovú adresu, prosím.',

  // CopyToClipboardBox
  clipboardBoxCopyText: 'Kopírovať',
  clipboardBoxCopiedText: 'Skopírované',

  // FormModal
  formModalFindSpaceHeader: 'Začnite hľadať kanceláriu',
  formModalFindSpaceBody: 'Spacer je rýchly a ľahký nástroj na analýzu pracoviska, no ak potrebujete niečo presnejšie, nechajte si pomôcť tímom CBRE Workplace. Vyplňte tento formulár a expert z oddelenia Workplace sa s vami spojí do 48 hodín a dohodne si s vami konzultáciu.',
  formModalFindSpaceSuccessMessage: 'Vaša požiadavka bola odoslaná.',

  // SuccessModal
  successModalTitle: 'Podarilo sa!',

  // ContactForm
  contactFormFirstNameLabel: 'Krstné meno',
  contactFormLastNameLabel: 'Priezvisko',
  contactFormEmailLabel: 'Pracovný e-mail',
  contactFormPhoneLabel: 'Telefónne číslo',
  contactFormMessageLabel: 'Povedzte nám viac o tom, čo hľadáte',
  contactFormSubmitText: 'Podvrdiť',

  // AcceptTermsText
  acceptTermsText: 'Prijímam <1> zmluvné podmienky </1>. Ďalšie informácie o tom, ako budú vaše údaje použité, nájdete v <3> pravidlách ochrany osobných údajov CBRE </3>.',
  privacyPolicyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Form
  formErrorText: 'Ups, vaša požiadavka neprešla. Prosím, skúste znova!',
  formEmailInvalid: 'Prosím zadajte platnú emailovú adresu.',

  // FormInput
  formInputOptionalText: ' (dobrovoľné)',

  // LegalForm
  legalFormCheckboxText: 'Spoločnosť CBRE ma môže kontaktovať s ponukami a informáciami súvisiacimi s mojím užívaním programu Spacer.',
  legalFormContactWarningText: 'Tím Spacer vás môže kontaktovať, aby vám pomohol vyťažiť z vášho programu maximum. Žiadny spam, sľubujeme!',

  // NextStepsSection
  nextStepsSectionSubtitle: 'Ste pripravení urobiť ďalší krok?',
  nextStepsSectionTitle: 'Nájdite dokonalú kanceláriu.',
  nextStepsSectionButtonText: 'Spojte sa s poradcom',
  nextStepsSectionImgAlt: 'Ilustrácia ľudí, ktorí komunikujú na stránke Výsledky Spacer od spoločnosti CBRE',

  // Profile
  resultsProfileSummaryTitle: 'Vaša kancelária -',
  resultsProfileTooltipText: 'Každý profil je zložený zo skutočných organizácií a pracovísk. Podrobnosti boli skontrolované podľa technických pokynov a znalostí trhu, ktoré sú jedinečné pre CBRE Workplace.',

  // Program
  programSaveConfirmationBoxText: 'Máte to tam. Odkaz na vaše výsledky sme poslali e-mailom. Enjoy!',
  programExamplePlansTitle: 'Vzorové plány',
  programExamplePlansSubtitle: 'Pohrajte sa s interaktívnymi pôdorysmi, ktoré zodpovedajú vášmu profilu. Môžete ich preskúmať aj v 3D!',
  programTooMuchTinkerWarning: 'Fíha! Na základe vášho profilu to vyzerá, že ste mimo odporúčaného rozsahu. Zvážte <1> opätovné absolvovanie kvízu </1> alebo kontaktujte podporu na čísle <3> {{email}} </3>.',
  programDisclaimer: 'Spacer od CBRE je poskytovaný bez akýchkoľvek záruk. Všetky výsledky generované z vášho používania programu Spacer majú iba ilustračný charakter a neodrážajú skutočné odporúčania týkajúce sa priestoru alebo navrhované podmienky prenájmu. Skutočné odporúčania sa môžu výrazne líšiť a budú závisieť od mnohých faktorov, ktoré Spacer nezohľadňuje. Používaním programu Spacer alebo ľubovoľného hlásenia vygenerovaného pri používaní programu Spacer vyjadrujete súhlas s našimi podmienkami služby a zásadami ochrany osobných údajov, ktoré sa nachádzajú na webových adresách https://workplace.cbre.com/termsOfService a https://www.cbre.us/about /zásady ochrany osobných údajov. © 2018 CBRE, Inc. Všetky práva vyhradené.',
  programMetricsTitle: 'Programové metriky',
  programMetricsSubtitle: 'Tieto čísla sumarizujú vaše pracovné prostredie',
  programDetailsSectionTitle: 'Prispôsobte si svoj program',
  programDetailsSectionSubtitle: 'Upravte polia nižšie a dolaďte svoje priestorové požiadavky.',
  programRevertButtonText: 'Zrušiť zmeny',
  programSaveButtonText: 'Uložiť zmeny',
  programSavedButtonText: 'Uložené',

  // ProgramAssumptions
  programAssumptionsRecommendedOfficeSizeText: 'Odporúčaná veľkosť kancelárie',
  programAssumptionsProfileText: 'Profil',
  programAssumptionsHeadcountText: 'Počet zamestnancov',
  programAssumptionsHeadcountFollowingText: 'ľudí',
  programAssumptionsCollabText: 'Spolupráca',
  programAssumptionsCirculationText: 'Cirkulácia',
  programAssumptionsAvgDaysText: 'Priemerný počet dní v kancelárii',
  programAssumptionsAvgDaysFollowingText: 'týždenne',
  programAssumptionsAvgDaysPerWeekText: 'Priemerný počet dní v kancelárii za týždeň',

  // ProgramHeroForPrint
  printNIAText: 'Celková veľkosť interiéru',
  printUSFText: 'Úžitkové m²',
  printRSFText: 'Prenajímateľné m²',
  printDescText: 'Na základe vašich podrobností a {{lossFactorPercentage}}% {{factorType}} v {{city}}, odporúčame celkom-',
  printFitFactorText: 'stratový faktor',
  printLossFactorText: 'faktor straty',

  // BarChart
  barChartIndivSubtitle: 'Individuálny priestor',
  barChartGroupSubtitle: 'Skupinový priestor',
  barChartAmenitySubtitle: 'Priestor na vybavenie',

  // TabbedProgramBarChart
  tabbedBarChartMeSubtitle: 'Osobný',
  tabbedBarChartGroupSubtitle: 'Skupinový',
  tabbedBarChartAmenitySubtitle: 'Vybavenie',

  // SpaceCategoryBreakdown
  spaceCatBreakdownSubtitle: 'Vyberte typ priestoru, ktorý chcete prispôsobiť',

  // SpaceCategoryBreakdownMobile
  spaceCatBreakdownMobileMeTitle: 'Individuálny priestor',
  spaceCatBreakdownMobileWeTitle: 'Skupinový priestor',
  spaceCatBreakdownMobileAmenityTitle: 'Priestor na vybavenie',
  spaceCatBreakdownMobileMeSubtitle: 'na samostatnú prácu',
  spaceCatBreakdownMobileWeSubtitle: 'na skupinovú prácu',
  spaceCatBreakdownMobileAmenitySubtitle: 'na všetko ostatné',
  spaceCatBreakdownMobileNIATotalTitle: 'Celková výmera priestoru v interiéri',
  spaceCatBreakdownMobileTotalTitle: 'Celkovo {{sfType}} Metrov štvorcových {{unitCapitalized}}',
  spaceCatBreakdownMobileCirculationText: 'Cirkulácia',

  // SpaceCategoryDropdown
  spaceCatDropdownMeText: 'Individuálny priestor',
  spaceCatDropdownWeText: 'Skupinový priestor',
  spaceCatDropdownAmenityText: 'Priestor na vybavenie',

  // SidebarInfo
  sidebarInfoMeTitle: 'Osobný',
  sidebarInfoWeTitle: 'Skupinový',
  sidebarInfoAmenityTitle: 'Vybavenie',

  // Space Category Descriptions (spaceCategoryDescription.js)
  spaceCatMeDesc: 'Priestor na samostatnú prácu. Ponúka rôzne možnosti, kde a ako môžu vaši zamestnanci pracovať, čo vedie k ich väčšej spokojnosti.',
  spaceCatWeDesc: 'Priestor pre spoluprácu a tímovú prácu. V dobe, keď sa dá pracovať kdekoľvek, je dôležité disponovať rôznym typom priestorov, vhodným na rôzne úlohy.',
  spaceCatAmenityDesc: 'Priestor, ktorý uľahčuje prácu. Vďaka skvelému vybaveniu sa vaša kancelária stane miestom, kam sa vaši zamestnanci - aj tí novopečení - budú tešiť.',

  // Suggested Custom Amenities (suggestedCustomAmenityUnitMap.js)
  audioVisualCustom: 'Audiovizuálne zariadenia',
  dataCenterCustom: 'Dátové centrum',
  buildingSupportCustom: 'Podpora stavebníctva',
  foodServiceCustom: 'Kompletné stravovacie služby',
  kitchenCustom: 'Kuchyňa',
  serveryCustom: 'Obsluha',
  execDiningCustom: 'Korporátne večere',
  grabNGoCustom: 'Grab-N-Go',
  juiceCoffeeBarCustom: 'Džúsový bar / kaviareň',
  childCareCustom: 'Child CareStarostlivosť o deti',
  fitnessCenterCustom: 'Fitness centrum',
  healthCenterCustom: 'Zdravotné centrum',
  mailCenterCustom: 'Poštové centrum',
  commercialPrintCustom: 'Komerčná tlač',
  centralCafeCustom: 'Centrálna kaviareň',
  securityCtrlCustom: 'Bezpečnostná miestnosť',
  auditoriumCustom: 'Auditórium',
  dryCleaningLaundryCustom: 'Čistiareň / práčovňa',
  confCenterCustom: 'Konferenčné centrum',
  multiPurpTrainingCustom: 'Viacúčelová miestnosť / Školenia',
  informalCollabCustom: 'Neformálna spolupráca',
  touchdownTeamCustom: 'Priestor pre tímovú prácu',

  // Suggested Custom Amenity Units (spaceUnit.js)
  seats: 'pracovné miesto',
  seats_plural: 'pracovných miest',
  rooms: 'miestnosť',
  rooms_plural: 'miestnosti',
  areas: 'priestor',
  areas_plural: 'priesstory',
  units: 'časť',
  units_plural: 'časti',

  // Predefined Amenities (spaceUnitMap.js) - Shared Space Units
  focusRoomDefined: 'Miestnosť na sústredenie',
  fileRoomDefined: 'Archív / miestnosť na dokumenty',
  centralReceptionHubDefined: 'Ústredná recepcia',
  smOfficeDefined: 'Malá kancelária',
  regOfficeDefined: 'Bežná kancelária',
  benchDesk5Defined: 'pracovný stôl - 1,6 m',
  benchDesk6Defined: 'pracovný stôl - 1,8 m',
  meetingRoom4Defined: 'Konferenčná miestnosť (4 osoby)',
  meetingRoom6Defined: 'Konferenčná miestnosť (6 osôb)',
  meetingRoom8Defined: 'Konferenčná miestnosť (8 osôb)',
  meetingRoom12Defined: 'Konferenčná miestnosť (12 osôb)',
  meetingRoom15Defined: 'Konferenčná miestnosť (15 osôb)',
  semiEnclosedMeetingBooth4Defined: 'Polouzavretá konferenčná miestnosť (4 osoby)',
  openCollabSpace4to6Defined: 'Otvorený priestor na spoluprácu (4 až 6 osôb)',
  contemplationSpaceDefined: 'Priestor na rozjímanie',
  mainITEquipRoomDefined: 'Hlavná serverovňa',
  receptionAreaDefined: 'Recepcia',
  cafeFoodServiceDefined: 'Kaviareň / reštaurácia',
  teamStorageDefined: 'Tímový sklad',
  coatClosetsDefined: 'Skrinky na kabáty',
  genStorageDefined: 'Skladovanie všeobecných položiek (vstupy a výstupy tovaru)',
  centralisedCopyPrintMailDefined: 'Centralizované kopírovanie, tlač a pošta',
  specialResourceAreaDefined: 'Špeciálne zdroje',
  personalStorageDefined: 'Osobné úložisko (skrinky)',
  storageITSpaceDefined: 'Úložný priestor pre IT',
  secondaryServerRoomDefined: 'Sekundárny server / telekomunikačná miestnosť',
  mothersRoomDefined: 'Materská izba',
  buildITSpaceDefined: 'IT Build Space',
  workerCouncilOffice2Defined: 'Kancelária pracovnej rady (2 osoby)',
  securityCtrlRoomDefined: 'Bezpečnostná miestnosť',
  centralisedMailDefined: 'Ústredná pošta',
  visitorCoatLuggageRoomDefined: 'Šatňa / úložisko batožiny pre návštevníkov',
  wellnessRoomDefined: 'Wellness miestnosť',
  internalStairDefined: 'Vnútorné schodisko',
  commercialPrintRoomDefined: 'Komerčná tlačiareň',
  buildingSupportServicesAreaDefined: 'Stavebná údržba',

  // Predefined Amenities (spaceUnitMap.js) - US_SMALL and US_REGULAR
  officeDefined: 'Kancelária',
  lgOfficeDefined: 'Kancelária (veľká)',
  smWorkstationDefined: 'Pracovná stanica (malá)',
  mdWorkstationDefined: 'Pracovná stanica (stredná)',
  lgWorkstationDefined: 'Pracovná stanica (veľká)',
  huddleRoomDefined: 'Malá pracovná miestnosť',
  dispersedCopyPrintAreaDefined: 'Voľná tlačiareň / tlačiarne',
  smMeetingRoomDefined: 'Malá konferenčná miestnosť',
  mdMeetingRoomDefined: 'Stredná konferenčná miestnosť',
  lgMeetingRoomDefined: 'Veľká konferenčná miestnosť',
  xlMeetingRoomDefined: 'X-Large konferenčná miestnosť',
  multiPurpTrainingDefined: 'Viacúčelová miestnosť / školenia',
  informalCollabDefined: 'Priestor na neformálnu spoluprácu',
  touchdownTeamDefined: 'Touchdown / priestor pre tím',
  pantryVendingCoffeeAreaDefined: 'Špajza / Automat na jedlo / Automat na kávu',
  workplaceStorageRoomDefined: 'Pracovná úschovňa',
  employeeCoatAreaDefined: 'Šatňa na odkladanie kabátov zamestnancov',
  dispersedMailAreaDefined: 'Poštový kútik',
  lockerUnitDefined: 'Skrinky',
  serverRoomDefined: 'Serverovňa',
  frontDeskAreaDefined: 'Recepcia',
  centralCafeAreaDefined: 'Priestor na pitie kávy',
  mailCenterAreaDefined: 'Mailové centrum',
  kitchenAreaDefined: 'Kuchyňa',
  drawerLateralFileUnitDefined: 'Trojšuflíková bočná skriňa na dokumenty',
  serveryAreaDefined: 'Kuchynka',
  juiceBarCoffeeShopAreaDefined: 'Džúsový bar / kaviareň',
  diningSupportAreaDefined: 'Prídavný priestor pre stravovanie',
  diningAreaDefined: 'Jedáleň',
  grabNGoAreaDefined: 'Grab-N-Go Area',
  focusRoomSharedFocus: 'Miestnosť na sústredenie',

  // Predefined Amenities (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDefined: 'Kompletné stravovacie služby',

  // Predefined Amenities (spaceUnitMap.js) - APAC_REGULAR
  officeWorkMeetDefined: 'Kancelária (práca a stretnutia)',
  lgOfficeWorkMeetDefined: 'Veľká kancelária (práca a stretnutia)',
  focusDefined: 'Focus',
  bench1pt6Defined: 'pracovný stôl - 1,6 m',
  bench1pt8Defined: 'pracovný stôl - 1,8 m',
  bench1pt8LDefined: 'pracovný stôl v tvare L - 1,8 m',
  banquetWorkMeetDefined: 'Banquet - práca a stretnutie (2 osoby)',
  cafeTable4Defined: 'Kaviarenský stôl (4 osoby)',
  kitchenTable8to10Defined: 'Kuchynský stôl (8-10 osôb)',
  standingWhiteboardDefined: 'Stojaca tabuľa',
  huddleRoom3Defined: 'Malá pracovňa (3 osoby)',
  smMeetingRoom5Defined: 'Malá konferenčný miestnosť (5 osôb)',
  mdMeetingRoom8Defined: 'Stredná konferenčná miestnosť (8 osôb)',
  lgMeetingRoom20Defined: 'Veľká konferenčná miestnosť (20 osôb)',
  xlBoardroom: 'Konferenčná miestnosť veľkosti L',
  wellnessRoomsDefined: 'Wellness miestnosti',
  employeeCoatsGymLockersDefined: 'Skrinky pre zamestnancov',
  lockersDefined: 'Skrinky',
  dispersedCopyPrintScanAreaDefined: 'Kopírka, tlačiareň, skener',
  hydrationPointDefined: 'Nápoje (voda / čaj / káva)',
  centralHighDensityStorageDefined: 'Centrálny sklad',
  wellnessMothersRoomDefined: 'Wellness / Materská izba',
  serverHubRoomsDefined: 'Serverovňa',
  buildITStoreRoomDefined: 'IT vybavenie / úložisko',
  simpleReceptionWaitDefined: 'Jednoduchá recepcia + čakacia zóna',
  centralCafeSocialHubDefined: 'Centrálny spoločenský kútik / káva',
  mailCentreDefined: 'Poštové centrum',
  utilityStoreRoomDefined: 'Skladovanie spotrebného tovaru',
  drawerLateralFileDefined: 'Trojšuflíková bočná skriňa na dokumenty',
  juiceBarCoffeeShopDefined: 'Džúsový bar / kaviareň',
  buildingSupportServicesDefined: 'Služby budovy',

  // Space Unit Map Descriptions (spaceUnitMap.js) - Shared Descriptions
  focusRoomDesc: 'Focus miestnosti sú uzavreté priestory určené na stimuláciu, sústredenie sa na prácu a riešenie dôverných pracovných záležitostí, pre jednu alebo dve osoby. Súkromie a odhlučnenie miestnosti je v týchto priestoroch prvoradé. Focusové miestnosti sú zvyčajne pre všetkých a je možné rezervovať si ich podľa potreby.',
  fileRoomDesc: 'Toto je sklad dokumentov pre zamestnancov a tímy. V dôsledku úsilia o digitalizáciu väčšiny kancelárií sa potreba ukladania individuálnych aj tímových súborov výrazne znižuje. No stále je to dôležitý priestor pre uskladnenie zákonom povinných kópií dokumentov alebo materiálov dlhodobých projektov, najmä ak väčšina open officov disponuje stolmi s malým úložným priestorom.',
  centralReceptionHubDesc: 'Recepcia vo vašej firme je prvým priestorom, ktorý má šancu urobiť dojem na zamestnancov, klientov a návštevníkov. Mal by dôsledne reprezentovať značku spoločnosti a prezentovať požadovaný vzhľad. Na recepcii by malo byť k dispozícii pre návštevníkov komfortné sedenie.',
  officeDesc: 'Kancelárie sú ​​uzavreté individuálne pracovné priestory pre zamestnancov. Sú skvelé na sústredenú prácu bez rozptýlení. Kancelárie sú zvyčajne vybavené pracovným stolom a pracovným sedadlom pre hostí. V kanceláriách nájdete množstvo osobných údajov a dokumentov. Veľkosť priestoru sa môže líšiť v závislosti od aktivít, ktoré sa v miestnosti vykonávajú (napr. pravidelné stretnutia), alebo od postavenia / hierarchie zamestnancov.',
  benchDeskDesc: 'Bench-desky sú jednotlivé pracovné miesta. Menšie pracovné stoly môžu zamestnanci používať buď dočasne (v zdieľanom priestore) alebo trvalo. Tieto stoly sú vynikajúce aj pre zamestnancov alebo dodávateľov, ktorí nie sú v kancelárii stále.  Výhody otvorených pracovných stolov zahŕňajú dobrú viditeľnosť kancelárie a možnosť spolupráce. Jednou z nevýhod môže byť zvýšený hluk, a preto sa odporúča, aby kancelárie s otvorenými pracovnými stolmi mali dosť priestoru na sústredenú prácu v súkromí.',
  meetingRoomDesc: 'Konferenčné miestnosti sú uzavreté priestory určené na malé skupinové stretnutia 2-4 osôb. Mali by byť vybavené audiovizuálnym zariadením a bielymi tabuľami, ktoré uľahčujú komunikáciu. Mali by byť tiež odhlučnené ​​(ľudia sediaci bezprostredne mimo miestnosti by nemali počuť rozhovory, ktoré sa vedú vo vnútri).',
  meetingRoomDesc6: 'Konferenčné miestnosti sú uzavreté priestory určené na malé skupinové stretnutia 2-4 osôb. Mali by byť vybavené audiovizuálnym zariadením a bielymi tabuľami, ktoré uľahčujú komunikáciu. Mali by byť tiež odhlučnené ​​(ľudia sediaci bezprostredne mimo miestnosti by nemali počuť rozhovory, ktoré sa vedú vo vnútri).',
  meetingRoomDesc8: 'Konferenčné miestnosti sú uzavreté priestory určené na malé skupinové stretnutia 2-4 osôb. Mali by byť vybavené audiovizuálnym zariadením a bielymi tabuľami, ktoré uľahčujú komunikáciu. Mali by byť tiež odhlučnené ​​(ľudia sediaci bezprostredne mimo miestnosti by nemali počuť rozhovory, ktoré sa vedú vo vnútri).',
  meetingRoomDesc12: 'Konferenčné miestnosti sú uzavreté priestory určené na malé skupinové stretnutia 2-4 osôb. Mali by byť vybavené audiovizuálnym zariadením a bielymi tabuľami, ktoré uľahčujú komunikáciu. Mali by byť tiež odhlučnené ​​(ľudia sediaci bezprostredne mimo miestnosti by nemali počuť rozhovory, ktoré sa vedú vo vnútri).',
  meetingRoomDesc15: 'Konferenčné miestnosti sú uzavreté priestory určené na malé skupinové stretnutia 2-4 osôb. Mali by byť vybavené audiovizuálnym zariadením a bielymi tabuľami, ktoré uľahčujú komunikáciu. Mali by byť tiež odhlučnené ​​(ľudia sediaci bezprostredne mimo miestnosti by nemali počuť rozhovory, ktoré sa vedú vo vnútri).',
  openCollabSpace4to6Desc: 'Tieto priestory sú určené pre malé skupiny (4 až 6 osôb) a spoluprácu, napríklad na brainstorming a neformálne rozhovory medzi kolegami a s klientmi. ',
  cafeFoodServiceDesc: 'Centrálna kaviareň má byť priestorom na stravovanie, osobnú interakciu, individuálnu prácu a malé stretnutia. Pri dizajne tohto priestoru by malo byť zohľadnené funkčné stravovacie zariadenie a paleta farieb značky.',
  coatClosetsDesc: 'Zamestnanci potrebujú miesto, kde si odložia bundy a iné objemné predmety, najmä ak má kancelária otvorené usporiadanie s obmedzeným osobným priestorom pri stoloch. Skrine by mali byť umiestnené po celej kancelárii, najlepšie pri východoch a pozdĺž hlavných chodieb. Na spodnej časti skrinky je zvyčajne umiestnená polica s vešiakom na čiapky a úložný priestor na tašky a kufre.',
  copyPrintDesc: 'Kútik s kopírkami a tlačiarňami rozmiestnený v otvorenom pracovnom priestore poskytuje zamestnancom rýchly prístup k multifunkčným zariadeniam zo všetkých častí kancelárie. Koše na recykláciu by mali byť vždy pri tlačiarňach a miestach, kde sa ukladá papier a pomôcky. Tieto priestory slúžia iba na krátke návštevy a nie sú definované ako pracovný/spoločenský priestor.',
  specialResourceBuildingSupportAreaDesc: 'Toto je univerzálny priestor, ktorý sa dá prispôsobiť pre potreby kancelárie. Jedna miestnosť by mohla mať bezpečnostný pult, zatiaľ čo iným by mohol poslúžiť priestor technickej služby. Ich využitie sa v jednotlivých firmách líši, ale s pribúdajúcimi kanceláriami sú takéto priestory čoraz populárnejšie a potrebnejšie.',
  secondaryServerRoomDesc: 'Sekundárna serverovňa (technológie) na umiestnenie IT zariadení a nízkonapäťovej kabeláže. V miestnostiach musí byť zabezpečený nepretržitý prívod vzduchu, ktorý musí byť udržiavaný v konštantnej teplote 22 stupňov Celzia s relatívnou vlhkosťou 50%.',
  mothersRoomDesc: 'Materské izby poskytujú súkromie  - či už pre dojčiace matky alebo pre jednotlivcov hľadajúcich krátkodobý oddych. Materské izby sú väčšinou potrebné vo všetkých nových kanceláriách, bez ohľadu na veľkosť alebo počet zamestnancov.',
  visitorCoatLuggageRoomDesc: 'Návštevníci si potrebujú odložiť bundy a iné objemné predmety. Šatníky by mali by byť rozmiestnené po celej kancelárii, najlepšie pri východoch a pozdĺž hlavných chodieb. V šatníkoch by mal byť aj priestor na čiapky a kufre.',

  wellnessRoomDesc: 'Účelom wellness miestností (predtým tiež označovaných ako Materské izby) je trávenie mimoriadneho individuálneho času - či už pre dojčiace matky alebo pre jednotlivcov, ktorí hľadajú krátkodobý odpočinok v kancelárii. Wellness miestnosti sú zvyčajne nutnou súčasťou všetkých nových kancelárií bez ohľadu na veľkosť a počet zamestnancov. Mali by obsahovať kreslo, chladničku a aj umývadlo.',

  internalStairDesc: 'Vnútorné schodisko môže byť v centre priestoru, poskytujúc prechod medzi poschodiami, alebo to môžu byť iné prvky, ktoré spájajú poschodia kancelárie. Môže to byť spoločenský priestor alebo miesto pre kaviarne, recepcie alebo konferenčné priestory.',
  commercialPrintRoomDesc: 'Často kombinované s podateľňou, interné tlačiarne poskytujú možnosť tlače, väzby a kompilácie dokumentov. Existuje niekoľko typov, napríklad aj veľkoformátové tlačiarne. Priestory budú obsahovať úložný priestor pre spotrebný materiál a pulty pre menšie zariadenia, takisto aj miesto na rozloženie výtlačkov.',
  pantryVendingCoffeeDesc: 'Na rozdiel od centrálnej kaviarne je miesto špajze / predajných automatov / kávových automatov určený na prípravu rýchlych jedál a nápojov. Rozhovory a konverzácie sa prirodzene sústreďujú v priestoroch s jedlom, no zvyčajne neobsahujú miesta na sedenie, čo spôsobuje, že ľudia rýchlo odchádzajú a netrávia dlhý čas socializáciou.',
  storageDesc: 'Skladové priestory sú určené na uskladnenie rôznych predmetov, ako napríklad doplnkový nábytok, balíky, rekreačné vybavenie, atď. Môžu sa tiež použiť na uschovanie kabátov návštevníkov a ich batožiny. Malý sklad by mal byť pre vaše pohodlie v blízkosti recepcie.',
  centralisedDispersedMailAreaDesc: 'Pre väčšie spoločnosti je pre evidovanie prichádzajúcej a odchádzajúcej pošty potrebné poštové stredisko. Tieto priestory obsahujú špeciálne vyrobené poštové zariadenia. Existuje tiež veľa pomôcok na usporiadanie papiera a iných materiálov. Spravidla je priestor spravovaný zamestnancom na plný úväzok.',
  lockerDesc: 'Skrinky sú vo forme osobného úložiska. Poskytujú zamestnancom priestor na uzamknutie cenností, osobných vecí a pracovných dokumentov. Skrinky môžu byť pridelené jednotlivcom alebo použité na základe princípu „kto prv príde, berie“.',
  mainITandServerRoomDesc: 'Hlavná serverovňa (technologická) je miestnosť na umiestnenie, zabezpečenie IT vybavenia a na vyústenie nízkonapäťovej kabeláže. V miestnostiach musí byť zabezpečené nepretržité vetranie vzduchu a musí byť udržiavaná teplota na konštantnej úrovni 22 stupňov Celzia, s relatívnou vlhkosťou 50%.',
  drawerLateralFileDesc: 'Hlavná serverovňa (technická) je miestnosť na umiestnenie, zabezpečenie IT vybavenia a na vyústenie nízkonapäťovej kabeláže. V miestnostiach musí byť zabezpečené nepretržité vetranie vzduchu a musí byť udržiavaná teplota na konštantnej úrovni 22 stupňov Celzia, s relatívnou vlhkosťou 50%.',
  juiceBarCoffeeShopDesc: 'Aj keď spoločnosť nemusí mať samostatnú kuchyňu, môže disponovať kaviarňou alebo džúsovým barom. Firma môže mať tento typ priestoru ako dodatok k svojim hlavným stravovacím službám, najmä ak je kancelária dostatočne veľká. Tento priestor bude pravdepodobne vyzerať ako miestny Starbucks, s pracovným priestorom pre zamestnanca oddeleným obslužným pultom.',
  grabNGoDesc: 'Malý pohodlný priestor s chladničkami, policami na sladkosti a občerstvenie, platobný automat. Priestory Grab-N-Go sú zvyčajne zabezpečené kamerou. Tieto miesta sú často kombinované s malou špajzou s chladničkami a mrazničkami, ako aj priestorom na prípravu jedla.',
  smMeetingConferenceRoomDesc: 'Malá konferenčná miestnosť je uzavretý  priestor určený na pohodlné stretnutie 2 až 6 osôb pri jednom stole. Tieto miestnosti môžu byť použité na interné aj klientske stretnutia, a preto by mali byť užívateľsky veľmi príjemné, s ľahkým prístupom k napájaniu a audiovizuálnym funkciám.',
  mdMeetingConferenceRoomDesc: 'Stredná konferenčná miestnosť je uzavretý priestor určený na pohodlné stretnutie 6 až 10 osôb pri jednom stole. Tieto miestnosti sa používajú na interné aj klientske stretnutia, a preto by mali byť užívateľsky veľmi príjemné s ľahkým prístupom k napájaniu a audiovizuálnym funkciám.',
  lgMeetingConferenceRoomDesc: 'Veľká konferenčná miestnosť je uzavretý konferenčný priestor určený na pohodlné stretnutie 12 až 14 osôb pri jednom stole, so sedením po obvode miestnosti. Tieto miestnosti môžu byť použité na interné aj klientske stretnutia a na prezentácie. Nábytok by mal byť presúvateľný, aby sa mohol priestor využiť aj na iné účely, napríklad na kurzy jogy pre zamestnancov.',
  xlMeetingRoomDesc: 'Zasadacia miestnosť je určená na pohodlné stretnutia 14 až 18 osôb pri jednom stole. Sedenie je umiestnené po obvode miestnosti pre najmenej 10 ďalších osôb. Tieto miestnosti budú s najväčšou pravdepodobnosťou použité na veľké interné a klientske stretnutia. Preto by mali byť vybavené robustnou audiovizuálnou technológiou a mali by byť orientované na potreby klientov.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - US_SMALL and US_REGULAR
  lgOfficeDesc: 'Kancelárie sú ​​uzavreté pracovné priestory pre zamestnancov. Sú skvelými miestami na sústredenú prácu bez rozptýlení. Kancelárie sú zvyčajne vybavené pracovným stolom a sedením pre hostí. V kanceláriách nájdete rôzne množstvo osobných údajov a dokumentov. Veľkosť priestoru sa tiež môže líšiť v závislosti od aktivít, ktoré sa v miestnosti vykonávajú (napr. stretnutia), alebo od postavenia / hierarchie zamestnancov. Táto o niečo väčšia kancelária môže byť partnerská kancelária alebo sa môže veľkosťou zdvojnásobiť a slúžiť ako miestnosť na zhromaždenie.',
  smWorkstationDesc: 'Pracovné stanice sú otvorené pracovné miesta. Menšie pracovné stanice môžu zamestnanci používať buď dočasne (vo voľnom priestore) alebo trvalo. Tieto stoly sú vynikajúce aj na návštevu zamestnancov alebo dodávateľov, ktorí nie sú v kancelárii permanentne. Výhody otvorených pracovných staníc sú dobrá viditeľnosť kancelárie a možnosť spolupráce. Jednou z nevýhod môže byť nadmerný hluk, a preto sa odporúča, aby kancelárie s otvorenými pracovnými stanicami disponovali aj tichými miestami na sústredenú prácu.',
  mdWorkstationDesc: 'Pracovné stanice sú otvorené jednotlivé pracovné miesta. Medzi výhody o niečo väčšieho priestoru patrí viac úložného priestoru na stole a súkromie. To znamená, že čím je stôl väčší, tým je pravdepodobnejšie je, že bude niekomu pridelený. Výhody otvorených pracovných staníc sú dobrá viditeľnosť kancelárie a možnosť spolupráce. Jednou z nevýhod môže byť nadmerný hluk, a preto sa odporúča, aby kancelárie s otvorenými pracovnými stanicami disponovali aj tichými miestami na sústredenú prácu.',
  lgWorkstationDesc: 'Pracovné stanice sú otvorené jednotlivé pracovné miesta. Medzi výhody o niečo väčšieho priestoru patrí viac úložného priestoru na stole a súkromie. To znamená, že čím je stôl väčší, tým je pravdepodobnejšie je, že bude niekomu pridelený. Výhody otvorených pracovných staníc sú dobrá viditeľnosť kancelárie a možnosť spolupráce. Jednou z nevýhod môže byť nadmerný hluk, a preto sa odporúča, aby kancelárie s otvorenými pracovnými stanicami disponovali aj tichými miestami na sústredenú prácu.',
  huddleRoomDesc: 'Huddle Rooms sú uzavreté priestory určené na malé skupinové stretnutia 2 až 4 osôb. Mali by byť vybavené audiovizuálnym zariadením a bielymi tabuľami, ktoré uľahčujú komunikáciu. Mali by byť odhlučnené ​​(ľudia sediaci mimo miestnosti by nemali počuť rozhovory, ktoré sa vedú vo vnútri).',
  multiPurpTrainingDesc: 'Viacúčelové miestnosti sú určené pre veľký počet ľudí (20 a viac). V tomto pristore sa môže sústreďovať veľa rôznych aktivít, vrátane školení, firemných akcií a veľkých prezentácií. Dizajn a povrchová úprava tohto priestoru je funkčná. Mnoho ľudí môže prichádzať a odchádzať, no aj sedieť čelom ku klientom. Tento priestor musí mať zodpovedajúcu audiovizuálnu výbavu, zvyčajne oveľa väčšiu ako priemerná konferenčná miestnosť.',
  informalCollabDesc: 'Tieto miestnosti sú určené pre malé skupiny (4 až 6 osôb) a spoluprácu, napríklad brainstorming a neformálne rozhovory medzi kolegami a s klientmi. ',
  touchdownTeamDesc: 'Touchdowny sú malé tímové priestory, ktoré sú rozmiestnené po celej kancelárii pre potreby zamestnancov a návštevníkov. Touchdowny môžu mať rôzne výšky, tvary a veľkosti, no majú sa líšiť od jednotlivých pracovných staníc ako jedinečné miesto na krátkodobú prácu. Mali by to byť priestory typu plug-and-play.',
  frontDeskAreaDesc: 'Toto je prvá zastávka vo vašej firme. Pri stole by malo byť možné umiestniť najmenej jednu osobu so svojím pracovným a úložným priestorom. V blízkosti by mal byť aj odkladací priestor pre návštevníkov. Recepcia je umiestnená vo väčšom prijímacom priestore, kde je viac miesta na sedenie.',
  mailCenterAreaDesc: 'Pre väčšie firmy je pre evidenciu všetkých poštových služieb potrebné poštové stredisko. To bude obsahovať veľký triediaci a organizačný priestor spolu s priestorom pre mapy. Tento priestor sa líši s každou firmou, pretože sa často zaraďuje medzi iné položky.',
  kitchenAreaDesc: 'V prípade priestorov, kde bude k dispozícii stravovanie, je potrebná kuchyňa. Požiadavky na kuchyňu sa budú líšiť od firmy k firme, ale predpokladom je, že tu nájdete kuchynské spotrebiče a chladničky. Nebudú chýbať ani sušené potraviny a miesto na odkladanie kuchynského náčinia. Kuchyňa bude susediť s výdajňou jedál a pravdepodobne s konferenčným centrom / centrom vybavenia klientov.',
  serveryAreaDesc: 'Výdajňa je miestnosť, kde si ľudia berú jedlo. Zvyčajne je spojená s kuchyňou a jedálenským kútom so stolmi a sedením. Plánovanie výdajne je veľmi citlivé na presnosť. Veľkosť a tvar tohto priestoru sa bude v rozdielnych firmách líšiť.',
  diningSupportAreaDesc: 'Firma, ktorá má širokú ponuku stravovacích služieb, bude pravdepodobne mať ďalšie pridružené priestory, ako napríklad sklady v iných častiach budovy. Tieto je potrebné zohľadniť v celkovom programe. Charakter priestoru sa bude líšiť od firmy k firme.',
  diningAreaDesc: 'Stolovanie v blízkosti kuchyne má ľuďom sprostredkovať možnosti sedenia a obedovania. Rôzne typy sedenia  umožňujú využiť priestor na stretnutia a spoluprácu mimo obedného času. Návštevníci si môžu vybrať z mäkkého a tvrdého sedenia, s možnosťou prispôsobiť si výšku sedadla.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDesc: 'Stravovacie služby vo firme zahŕňajú niekoľko rôznych typov priestorov, vrátane kuchyne, výdajne, jedálne (sedenie) a stolovacích zásob (skladovanie). Kuchyňa a výdajňa pracujú koordinovane na príprave a servírovaní domácich jedál. Jedáleň je miestom, kde môžu zamestnanci a hostia sedieť a jesť, no tento priestor často funguje aj ako centrum socializácie a spolupráce. Rovnako ako doma, aj pri stravovacích službách vo firme je potrebné množstvo vybavenia. Potrebný je aj sklad potravín, preto je do priestoru typu Full Food Service zahrnutá aj táto jednotka.',

  // SpaceUnitRow
  spaceUnitRowDeleteTitle: 'Odstrániť typ priestoru',
  spaceUnitRowDeleteText: 'Ste si istý, že chcete odstrániť tento typ priestoru?',
  spaceUnitRowConfirmDeleteText: 'Áno, odstrániť',
  spaceUnitRowCancelDeleteText: 'Nie, ponechať',

  // CustomAmenityAdder
  customAmenityAddText: 'Pridať vybavenie',
  customAmenityPlaceholder: 'Typ vybavenia',

  // SpaceUnitTotals
  spaceUnitTotalsTitleNIA: 'Celkový vnútorný priestor',
  spaceUnitTotalsTitle: 'Celkovo {{sfTypeAdj}} m2 {{unitCapitalized}}',
  spaceUnitTotalsCirculation: 'Cirkulácia',
  spaceUnitTotalsCirculationTooltip: '„Priestor medzi priestormi“. Chodby, priestor za stoličkami, priestor okolo skriniek, dverí a okolo rohov. Bez neho by ste nemali kadiaľ kráčať! Spravidla predstavuje okolo 35% z celkového objemu štvorcových metrov {{unitCapitalized}} vo vašom priestore, ale líši sa v závislosti od vášho profilu a konečného výberu budovy.',
  spaceUnitTotalsREBNY: 'REBNY faktor',
  spaceUnitTotalsNYCText: 'NYC ',
  spaceUnitTotalsREBNYTooltip: 'V prípade programov v New Yorku zvyšujeme {{sfType}} o 15%, aby sme zohľadnili štandard merania REBNY. Priestor meraný podľa štandardu  REBNY zahŕňa okrem iného kúpeľne, predsiene výťahov a hrúbku vonkajších stien.',
  spaceUnitTotalsLossFactor: 'Stratový faktor',
  spaceUnitTotalsLossFactorTooltip: 'Použiteľný objem metrov štvorcových {{unit}} predstavuje koľko miesta máte v kancelárii. Prenajímateľný meter štvorcový {{unit}} je priestor, za ktorý platíte. Môže obsahovať stĺpy, lobby a ďalšie spoločné priestory. Rozdiel medzi použiteľným a prenajímateľným sa nazýva stratový faktor a spôsob jeho výpočtu sa líši podľa miesta.',

  // CollaborationDropdown
  collabDropdownLevelLowText: 'málo (0-25%)',
  collabDropdownLevelMedText: 'časť (26-50%)',
  collabDropdownLevelHighText: 'väčšinu (51-100%)',

  //DensityDropdown
  sameDensityText: 'Rovnaká hustota ako pred pandémiou Covid-19',
  lowerDensityText: 'Nižšia hustota (viac metrov štvorcových na osobu)',
  notSureDensityText: 'Neurčité',

  //CirculationDropdown
  preCovidText: 'Pred pandémiou Covid-19 (45% otvorený / 25% uzavretý)',
  higherText: 'Vyššia cirkulácia (50% otvorený / 30% uzavretý)',
  notSureText: 'Neurčité (45% otvorený / 25% uzavretý)',

  // ExamplePlans
  examplePlanButtonText: 'Zobraziť pôdorys navrhnutých priestorov',
  examplePlanFullFloorText: 'celé poschodie',
  examplePlanPartialFloorText: 'časť poschodia',
  examplePlanFullImgAlt: 'Pôdorys {{size}} štvorec {{unit}} zobrazujúci rozloženie profilu {{profileName}} Spacer od CBRE',
  examplePlanPartialImgAlt: 'Čiastočný pôdorys ({{size}} štvorec) {{unit}} zobrazujúci rozloženie profilu {{profileName}} Spacer od CBRE',
  examplePlanTooltipText: 'Ak ste presiahli {{size}} {{unit}}, možno budete potrebovať viac ako jedno poschodie.',

  // ProgramStats
  programStatsDensitySubtitleTsubo: 'Net Tsubo',
  programStatsDensitySubtitle: '{{adjective}} Štvorec {{unitUppercase}}',
  programStatsIndivTitle: 'Individuálne pracovné miesta',
  programStatsWorkstationsSubtitle: 'Pracovné miesta',
  programStatsOfficesSubtitle: 'Kancelárie',
  programStatsCollabTitle: 'Spolupráca',
  programStatsEnclosedSubtitle: 'Uzatvorené skupinové sedenie',
  programStatsIndivSeatSubtitle: 'Individuálne pracovné miesto',
  programStatsSeatsTitle: 'Pracovné miesto na osobu',
  programStatsIndivSeatsSubtitle: 'Individuálne pracovné miesta',
  programStatsPersonSubtitle: 'Osoba',
  programStatsDensityTitle: 'Hustota',
  programStatsFullDensitySubtitle: '{{density}} na individuálne pracovné miesto',

  // Profile Display Names (profileNames.js)
  efficientName: 'The Optimizer',
  whiteCollarName: 'The Accommodator',
  whiteGloveName: 'The Enhancer',
  openOfficeName: 'The Producer',
  workplace360Name: 'The Connector',
  campusName: 'The Differentiator',
  startupName: 'The Incubator',
  engCreativeName: 'The Creator',

  // Profile Descriptions (profileDescriptions.js)
  efficientDesc: 'Kancelária typu Optimizer nemá čas na nezmysly, s mentálnym nastavením „dosiahni svoj cieľ“. Priestor umožňuje jednotlivcom mať pri stole všetky potrebné nástroje. K spolupráci a tímovo orientovanej práci dochádza zriedkavejšie. Pracovné stoly sú štandardizované a majú pridelený iba základný priestor v kancelárii. Názov tohto prostredia je takmer vždy otvorený, pre čo najväčšiu efektivitu.',
  whiteCollarDesc: 'V kancelárii typu Accommodator nie ste nikdy sami - je jedným z najpopulárnejších profilov. Kancelárie typu Accommodator dávajú prednosť neradikálnym zmenám, ale často investujú do novej technológie a nábytku, aby bol priestor produktívny a pútavý. Vďaka rovnováhe medzi priestormi na spoluprácu sa zamestnanci ocitnú väčšinou pri svojich stoloch s príležitostnými stretnutiami v tímoch. Ideálne na 9 to 5.',
  whiteGloveDesc: 'Vstúpte do lobby kancelárie typu Enhancer a budete mať pocit, že ste prišli na výslnie. Toto pracovisko pokrýva potreby zamestnancov, ako aj klientov, ktorí sú častými návštevníkmi pracoviska. Viac ako v ktoromkoľvek inom profile  sú bežné kancelárie a uzavreté priestory. Väčšina zamestnancov si zaslúži svoj kancelársky priestor na výkon alebo zotrvanie vo firme, hierarchia pravdepodobne hrá dôležitú úlohu pri jej fungovaní. Rýchlo hore po kariérnom rebríku!',
  openOfficeDesc: 'Kancelária typu Producer vie, čo potrebujete k úspechu. Všetko je to o tom, že ľuďom dáte potrebný základ podľa typu činnosti. Nielenže sú tieto rozmanité typy priestorov k dispozícii, ale zisťujeme, že sú aj veľmi využívané ako spôsob, ako zostať efektívny! Medzi individuálnou a tímovou prácou je rovnomerný pomer, čo pravdepodobne znamená, že sa počas dňa veľa presúvate po kancelárii. Ideálne pre mobilnú kanceláriu.',
  workplace360Desc: 'Rovnako ako vaša obľúbená pozícia na joge, aj kancelária typu Connector prioritizuje rovnováhu. So správnou kombináciou priestoru zameraného na produktivitu a priestoru na socializáciu obsahuje kancelária Connector všetko potrebné pre sústredenie a podporuje možnosť výberu medzi ďalšími typmi priestorov. Kancelária Connector, verná svojmu názvu, umiestňuje spoločne priestory, ako sú kuchyňa a konferenčné priestory do stredu kancelárie. To pomáha ľudí spájať a vytvára pocit spoločenstva.',
  campusDesc: 'Z kancelárie typu Differentiator je na konci dňa ťažké odísť. Je to preto, lebo práca v nej je úplne inou skúsenosťou, prevyšujúcou tie ostatné. Nájdete tu vylepšené možnosti wellnessu a stravy, ktoré zamestnancom ušetria čas a poskytnú im pohodlie. Spomínal niekto občerstvenie ?! Zamestnancom odporúčame, aby si na pracovisku robili prestávky, socializovali sa a relaxovali.',
  startupDesc: 'Rýchlosť a nápady sú cieľom hry, teda pokiaľ ide o kanceláriu typu Incubator. Zamestnanci pracujú v tímoch a myslia ako vlastníci firmy. Toto kancelárske prostredie sa neustále mení, čo znamená, že väčšina priestorov má v sebe navrhnutú flexibilitu. Sťahovanie nábytku a ďalšie zmeny sú bežné. Kamarátstva vznikajú v kombinácii zábavných spoločenských aktivít a intenzívnych pracovných stretnutí.',
  engCreativeDesc: 'Kanceláriu typu Creator považujeme za miesto plné odborníkov - nie za typickú kombináciu oddelení. Rovnako ako všetky tvorivé snahy, aj kancelária typu Creator dáva priestor tímovej práci. Špecializované vybavenie a materiály sú zvyčajne súčasťou každodennej pracovnej činnosti. Zamestnancom je daný priestor, ktorý vyžadujú na efektívne vykonávanie svojej práce, a hoci je tento kancelársky typ  vzácny, jedinečná firma potrebuje jedinečnú kanceláriu.',


  // Profile Key Traits: Shared Names (profileTraitMap.js)
  social: 'Spoločenský',
  efficient: 'Efektívny',
  flexible: 'Flexibilný',
  enabled: 'Umožnujúci',
  productive: 'Produktívny',
  inclusive: 'Inkluzívny',
  clientFocused: 'Orientovaný na klienta',
  standardized: 'Štandardizovaný',

  // Profile Key Traits (profileTraitMap.js)
  social1Desc: 'Vzťahy v práci a mimo nej sú dôležité',
  social2Desc: 'Mnoho podujatí zameraných na budovanie komunity',
  social3Desc: 'Zamestnanci tu budú radi spolu tráviť čas',
  social4Desc: 'Otvorenejší priestor s dôrazom na komunitu',
  efficient1Desc: 'Spoločnosť zostáva šetrnejšia, s dôrazom na rozpočet',
  efficient2Desc: 'Spoločnosť zostáva šetrnejšia a produktívna',
  flexible1Desc: 'Zamestnanci majú na výber, ako budú pracovať',
  flexible2Desc: 'Zamestnanci majú veľkú slobodu',
  flexible3Desc: 'Vybudujte si priestor, aký potrebujete',
  enabled1Desc: 'Zamestnanci majú pri svojich pracovných stoloch to, čo potrebujú',
  enabled2Desc: 'Zamestnanci majú rozsiahle služby a vybavenie',
  enabled3Desc: 'Zamestnanci majú to, čo potrebujú na výkon svojej práce',
  enabled4Desc: 'Prístup k správnej technike a vybaveniu',
  enabled5Desc: 'K dispozícii je špecializované vybavenie',
  productive1Desc: 'Funkčný a intuitívny kancelársky dizajn',
  productive2Desc: 'Biznisový a finančne uvedomelý',
  productive3Desc: 'Sústredený na jedinečnú ponuku produktov / služieb',
  inclusive1Desc: 'K dispozícii je viac druhov priestoru',
  inclusive2Desc: 'V kancelárii nájdete všetko, čo potrebujete',
  inclusive3Desc: 'Veľa rôznych štýlov práce je prijateľných',
  clientFocused1Desc: 'Prvý dojem na klienta je najdôležitejší',
  clientFocused2Desc: 'Kancelária je prívetivá pre klientov a nových zamestnancov',
  clientFocused3Desc: 'Kancláriou sa blisnete pred klientmi a novými zamestnancami',
  standardized1Desc: 'Procesy a systémy sú zjednodušené',
  standardized2Desc: 'Organizačné normy týkajúce sa prideľovania priestoru',
  standardized3Desc: 'Modulárne veľkosti pre flexibilitu',
  standardized4Desc: 'Zjednodušené procesy a výstupy',

  // SpaceSummary
  spaceSummaryBarHeader: 'Odporúčaná veľkosť kancelárie',
  spaceSummaryMeasurementTypeText: 'Typ výmery',
  spaceSummaryUnitTypeText: 'Jednotky',
  spaceSummaryLossFactorSectionLabel: '{{factor}} Detaily',
  spaceSummaryFitFactorText: 'Faktor zhody',
  spaceSummaryLossFactorText: 'Faktor straty',
  spaceSummaryRSFLossFactorHelperText: 'Na základe {{percentage}}% stratového faktora pre nehnuteľnosti v meste {{place}} by ste mali hľadať priestory medzi {{min}} a {{max}} prenajímateľným štvorcovými metrami {{units}}.',

  spaceSummaryNIAFitFactorHelperText: 'Na základe {{percentage}}% faktora zhody pre nehnuteľnosť v {{place}}, by ste mali hľadať priestory s {{amt}} NIA priestoru.',
  spaceSummaryNIATooltipText: 'Čistá vnútorná plocha (Net Internal Area - NIA) je využiteľná plocha v budove meraná k vnútornej stene obvodových stien na každom podlaží, s výnimkou určitých špecifikovaných plôch.',

  // SFTypeRadioInput
  sfRadioInputUsableAreaTooltipText: 'Táto výmera zahŕňa všetky miestnosti, nábytok a obehový priestor.',
  sfRadioInputRentableAreaTooltipText: 'Toto je zvyčajne úžitková plocha {{unitUppercase}} plus časť spoločných priestorov budovy. Aj keď nemôžete využiť celý priestor, je to plocha {{unit}}, za ktorú platíte pri prenájme.',
  sfRadioInputUsableSquareText: 'Užitková plocha',
  sfRadioInputRentableSquareText: 'Prenajímateľná plocha',

  //floored plan request block
  flooredTitle: 'Ešte neodchádzajte/ Ešte nás neopúštajte',
  flooredSubTitle: 'Zmeňte ako klienti vnímajú priestor',
  flooredSubText: 'Pozrite si svoje výsledky z aplikácie Spacer v 3D virtuálnej prehliadke',
  flooredParagraphText1: 'Pohlcujúci zážitok umožní nájomcom predstaviť si všetky dostupné možnosti, experimentovať s priestorom a premeniť nápady na skutočnosť.',
  flooredParagraphText2: 'Hmatateľná, osobná vízia pre každý priestor',
  flooredVideoHeading: 'Sledujte ukážku',
  flooredFreeText: 'Čo?',
  flooredIconText1: 'Zdieľať dostupné pôdorysy podlaží',
  flooredIconSubText1: 'Súbory AutoCAD pre súbory PDF',
  flooredIconText2: 'Nechajte nám čas na spracovanie',
  flooredIconSubText2: 'Jeden až dva týždne',
  flooredIconText3: 'Získajte zdieľateľný zážitok',
  flooredIconSubText3: 'Prispôsobené zážitky 2D a 3D',


  // MeasurementSystemRadioInput
  measurementSystemSqFeetText: 'ft²',
  measurementSystemSqMetersText: 'm²',
  measurementSystemTsuboText: 'Tsubo',

  // ProfileHero
  resultsProfileHeroAnnouncementTitle: 'Vaša kancelária je',
  resultsProfileHeroImgAlt: '3D pôdorys zobrazujúci návrh kancelárského priestoru {{profileName}} Spacer od CBRE',

  // ProfileComparison
  profileCompModalTitle: 'Chcete zmeniť svoj profil?',
  profileCompModalText: 'Stratíte všetky neuložené zmeny v tomto programe.',
  profileCompModalConfirmText: 'Áno, zmeniť profil',
  profileCompModalCancelText: 'Nie, ponechať tento profil',
  profileCompNavbarCancelButtonText: 'Zrušiť',
  profileCompNavbarUseButtonText: 'Použiť tento profil',
  profileCompCompareTitle: 'Porovnajte svoje najbližšie profilové zhody',

  // ProfileComparisonColumn
  profileCompColumnSelectedText: 'Vybraný profil',
  profileCompColumnSelectText: 'Vybrať',
  profileCompColumnRecommendedText: ' (Odporúčané)',
  profileCompColumnImgAltText: '3D pôdorys zobrazujúci rozloženie kancelárie profilu {{profileName}} na Spacer od CBRE',

  // Test Fit Form
  testFitTitle: 'Zobrazte svoje výsledky ako pôdorys / plán a zobrazte ich v 3D',
  testFitSubtitle: 'Chcete mať predstavu ako Váš program bude vyzerať a pôsobiť? Premeníme Vaše výsledky na pôdoris, ktorý môžete preskúmavať a zdielať',
  requestTestFitButtonText: 'Vyžiadajte si vlastný pôdorys',
  company: 'Názov spoločnosti',
  emptyCompanyError: 'Prosím, uveďte názov spoločnosti',
  radioQ2yes: 'Výborne! Budeme Vás kontaktovať emailom pre obdržanie dokumentácie',
  radioQ2no: 'Žiadny problém, budeme Vás kontaktovať ohladom ďalších možností.',
  testFitFormTitle: 'Vyžiadajte si svoj vlastný \nPôdorys',
  testFitFormSubtitle: 'Uveďte zopár informácií a my sa do toho pustíme (my začneme). Náš tím zvyčajne dodáva hotové pôdorisy do troch pracovných dní.',
  testFitFormCompleted: 'Vaša žiadosť bola odoslaná. Náš expert vás bude kontaktovať e-mailom.',
  testFitFormYes: 'áno',
  testFitFormNo: 'nie',
  testFitFormQ1: 'Máte pôdorys, ktorý môžete použiť?',
  testFitFormQ1No: 'Žiadny problém! Dáme vám ho my.',
  testFitFormQ2: 'Máte nákres pôdorysu (PDF, AutoCAD)?',
  testFitTermsConditions: 'Začiarknutím tohto políčka vyjadrujete súhlas s účasťou v tomto beta programe.',
  testFitSubmitButtonText: 'Odošlite žiadosť o pôdorys',
  backendError: 'Ups! Niekde sa stala chyba - prosím, skúste ešte raz.'
};
