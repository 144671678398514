const React              = require('react');
const PropTypes          = require('prop-types');
const { withTranslation } = require('react-i18next');


function BasicNavbar(props) {
  let classes = `navbar ${props.isDark ? 'is-tertiary' : 'is-light-gray'}`;
  let logoUrl = props.isDark ? '/svg/logos/spacer_logo_white.svg' : '/svg/logos/spacer_logo.svg'
  if (props.isInQuiz) classes += ' in-quiz';

  return (
    <header className={classes}>
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item cbre-spacer-logo" href="/">
             <img src={logoUrl} alt="CBRE Spacer" />
          </a>
        </div>
        <nav aria-label="Main" className="navbar-menu">
          <ul className="navbar-start">{props.navBarStartElements}</ul>
          <ul className="navbar-end">
            {props.children}
          </ul>
        </nav>
      </div>
    </header>
  );
}

BasicNavbar.propTypes = {
  children: PropTypes.node,
  isDark: PropTypes.bool,
  isInQuiz: PropTypes.bool,
  navBarStartElements: PropTypes.arrayOf(PropTypes.element),
  t: PropTypes.func.isRequired
};

BasicNavbar.defaultProps = {
  children: null,
  isDark: false,
  isInQuiz: false,
  navBarStartElements: []
};

module.exports = withTranslation('common')(BasicNavbar);
