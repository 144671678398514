const React               = require('react');
const PropTypes           = require('prop-types');
const spacerFreePropTypes = require('../spacerFreePropTypes');
const util                = require('wp-util');

function QuestionResponse(props) {
  let outerDivClasses = 'question-response';
  outerDivClasses += props.showResponse ? ' fade-in' : ' is-invisible-to-be-faded-in';

  // for rank questions, don't hold space because the response will only appear once tags have disappeared
  // and while the tags are still visible, their list might be too long and push this placeholder off screen
  if (util.questionUnit.isRankQuestion(props.questionUnit) && !props.showResponse) {
    outerDivClasses += ' hide';
  }

  const questionResponse = util.questionUnit.getQuestionResponse(props.questionUnit, props.questionAnswer);

  // preloading all possible response illustrations to avoid waiting time after response is picked
  const allResponseIllustrationNames = util.questionUnit.getAllResponseIllustrationNames(props.questionUnit);
  const illustrations = allResponseIllustrationNames.map(name =>
    (<img
      src={`/svg/illustrations/quiz/${name}`}
      alt=""
      key={name}
      className={((name === questionResponse.illustrationName) && props.showResponse) ? 'fade-in' : 'is-invisible-to-be-faded-in'}
    />)
  );

  return (
    <div className={outerDivClasses}>
      <div className="title is-4 is-5-mobile question-response-title">
        {questionResponse.text}
      </div>
      <div className="question-response-img">
        {illustrations}
      </div>
    </div>
  );
}

QuestionResponse.propTypes = {
  showResponse: PropTypes.bool,
  questionUnit: spacerFreePropTypes.questionUnitShape.isRequired,
  questionAnswer: spacerFreePropTypes.questionAnswerTypes
};

QuestionResponse.defaultProps = {
  showResponse: false,
  questionAnswer: ''
};

module.exports = QuestionResponse;
