const React               = require('react');
const PropTypes           = require('prop-types');
const ProfileHero         = require('./ProfileHero.jsx');
const ProfileTrait        = require('./ProfileTrait.jsx');
const ContentSection      = require('../ContentSection.jsx');
const SpaceSummary        = require('../../containers/results/profile/SpaceSummary.jsx');
const Tooltip             = require('../../common/Tooltip.jsx');
const { withTranslation }  = require('react-i18next');

function Profile(props) {
  const profileDescriptionParagraphs = props.description.split('\n');

  return (
    <div className="results-profile">
      <ProfileHero profileName={props.displayName} profileHeroImagePath={props.profileHeroImagePath} />

      <ContentSection>
        <div className="columns">
          <div className="space-summary-section column is-two-fifths-desktop is-half-tablet is-hidden-mobile">
            <SpaceSummary />
          </div>

          <div className="column is-three-fifths-desktop is-half-tablet profile-description-section">
            <h2 className="has-text-primary is-sans-semibold">{props.t('resultsProfileSummaryTitle')}</h2>
            <br />
            {
              profileDescriptionParagraphs.map((paragraph, i) => (
                <div key={paragraph.substring(0, 25)}>
                  <div>
                    {paragraph}
                    {(i === (profileDescriptionParagraphs.length - 1)) &&
                      <Tooltip position="bottom" text={props.t('resultsProfileTooltipText')} />
                    }
                  </div>
                  <br />
                </div>
              ))
            }
            <div className="columns is-mobile is-multiline has-padding-top-5 profile-traits-container">
              {
                props.keyTraitIds.map(traitId => (
                  <div className="column is-half-mobile" key={traitId}>
                    <ProfileTrait traitId={traitId} profileType={props.profileType} />
                  </div>
                ))
              }
            </div>
          </div>
          <div className="mobile-space-summary-section column is-two-fifths is-hidden-desktop is-hidden-tablet has-padding-top-5-mobile">
            <SpaceSummary />
          </div>
        </div>
      </ContentSection>
    </div>
  );
}

Profile.propTypes = {
  displayName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keyTraitIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  profileType: PropTypes.string.isRequired,
  profileHeroImagePath: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(Profile);
