const React = require("react");
const PropTypes = require("prop-types");
const KEY_CODES = require("../../../shared/constants/keyCodes.js");
const domUtil = require("../../../shared/util/dom");
const { withTranslation } = require("react-i18next");
const { supportedLanguages } = require("../../util/i18nLanguages");
const {
  updateCurrentLanguage,
  getCurrentLanguage,
} = require("../../util/numericDisplay");
const { connect } = require("react-redux");
const { bindActionCreators } = require("redux");
// const globalActions = require("../../redux/actions/global");
const GALabels = require("../../../spacerFree/optionsConfig/googleAnalytics.labels.json");
const ga = require("../../../shared/tracking");
import globalActions from "../../redux/actions/global.js";

const DROPDOWN_PADDING = 25;
const optionOrder = Object.keys(supportedLanguages);
const isInactive = false;

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.i18n.language,
      highlightedOptionId: null,
      dropdownActive: false,
    };
    this.props.onLanguageChange(props.i18n.language);

    this.optionElements = {};
    this.registerKeyPress = this.registerKeyPress.bind(this);
    this.mouseMoveOnOptionField = this.mouseMoveOnOptionField.bind(this);
  }

  onValueChange(value) {
    this.props.onLanguageChange(value);
    this.props.i18n.changeLanguage(value);
    updateCurrentLanguage(value);

    this.setState({ value });

    window.scroll(0, 0);
  }

  setValue(value) {
    return (evt) => {
      evt.preventDefault();
      this.onValueChange(value);
      ga.GAEvent(
        supportedLanguages[value],
        supportedLanguages[value],
        supportedLanguages[value]
      );

      this.toggleDropdown(false);
    };
  }

  scrollOptionIntoView() {
    if (
      this.state.highlightedOptionId &&
      this.optionElements[this.state.highlightedOptionId]
    ) {
      this.optionElements[this.state.highlightedOptionId].scrollIntoView({
        block: "nearest",
      });
    }
  }

  shiftHighlightedOptionDown() {
    this.setState((prevState) => {
      const oldIndex = optionOrder.indexOf(prevState.highlightedOptionId);
      if (oldIndex === optionOrder.length - 1) return null;
      return { highlightedOptionId: optionOrder[oldIndex + 1] };
    }, this.scrollOptionIntoView);
  }

  shiftHighlightedOptionUp() {
    this.setState((prevState) => {
      const oldIndex = optionOrder.indexOf(prevState.highlightedOptionId);
      if (oldIndex === 0) return null;
      const newIndex = oldIndex === -1 ? optionOrder.length - 1 : oldIndex - 1;
      return { highlightedOptionId: optionOrder[newIndex] };
    }, this.scrollOptionIntoView);
  }

  registerKeyPress(evt) {
    switch (evt.which) {
      case KEY_CODES.ENTER:
        if (!this.state.dropdownActive) return this.toggleDropdown(true);
        evt.target.blur();
        evt.stopPropagation();
        if (this.state.highlightedOptionId) {
          this.setValue(this.state.highlightedOptionId)(evt);
        } else {
          this.toggleDropdown(false);
        }
        return null;
      case KEY_CODES.DOWN_ARROW:
        evt.preventDefault();
        if (!this.state.dropdownActive) return this.toggleDropdown(true);
        return this.shiftHighlightedOptionDown();
      case KEY_CODES.UP_ARROW:
        evt.preventDefault();
        return this.shiftHighlightedOptionUp(true);
      default:
        this.setState({ dropdownActive: true });
        return null;
    }
  }

  mouseMoveOnOptionField(value) {
    return () => {
      if (this.state.highlightedOptionId !== value) {
        this.setState({ highlightedOptionId: value });
      }
    };
  }

  toggleDropdown(showDropdown) {
    this.setState(
      {
        dropdownActive: showDropdown,
        highlightedOptionId: this.state.value,
      },
      () => {
        if (showDropdown) this.scrollOptionIntoView();
      }
    );
  }

  render() {
    let dropdownMenuStyles;
    if (this.dropdownInput) {
      dropdownMenuStyles = {
        maxHeight: domUtil.getSpaceBelow(this.dropdownInput) - DROPDOWN_PADDING,
        overflow: "auto",
      };
    }

    let dropdownWrapperClasses = "dropdown";
    if (this.state.dropdownActive) dropdownWrapperClasses += " is-active";

    let dropdownSelectionClasses = "underline-input";

    if (isInactive) dropdownSelectionClasses += " is-inactive";

    const dropdownElements = optionOrder.map((optionId) => {
      const key = `option-${optionId}`;
      const selected = this.state.value === optionId;
      let dropdownItemClasses = "dropdown-item";
      if (selected) dropdownItemClasses += " is-active";
      if (this.state.highlightedOptionId === optionId)
        dropdownItemClasses += " hover";

      return (
        <div
          role="option"
          aria-selected={selected}
          key={key}
          tabIndex={optionId}
          className={dropdownItemClasses}
          onMouseDown={this.setValue(optionId)}
          onMouseMove={this.mouseMoveOnOptionField(optionId)}
          ref={(option) => {
            this.optionElements[optionId] = option;
          }}
        >
          {supportedLanguages[optionId]}
        </div>
      );
    });

    const dropdownContentClasses = "dropdown-content";
    const { currentLanguage } = this.props;
    return (
      <div className={dropdownWrapperClasses}>
        <div
          className="dropdown-button-wrapper"
          role="button"
          tabIndex="0"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onKeyDown={this.registerKeyPress}
          onBlur={() => this.toggleDropdown(false)}
        >
          <div
            className={dropdownSelectionClasses}
            role="button"
            tabIndex="-1"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onMouseDown={() => this.toggleDropdown(true)}
            ref={(node) => {
              this.dropdownInput = node;
            }}
          >
            <span>{supportedLanguages[currentLanguage]}</span>
            {this.state.dropdownActive ? (
              <div className="dropdown-triangle-up" />
            ) : (
              <div className="dropdown-triangle" />
            )}
          </div>
        </div>

        <div
          className="dropdown-menu is-primary"
          role="button"
          tabIndex="-1"
          id="dropdown-menu"
          onKeyDown={this.registerKeyPress}
          onMouseLeave={() => this.setState({ highlightedOptionId: null })}
          style={dropdownMenuStyles}
        >
          <div className={dropdownContentClasses} role="listbox">
            {dropdownElements}
          </div>
        </div>
      </div>
    );
  }
}

LanguageSelector.propTypes = {
  i18n: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentLanguage: state.userData.language,
  };
}

function mapDispatchToProps(dispatch) {
  const changeSLanguage = bindActionCreators(
    globalActions.changeSLanguage,
    dispatch
  );
  return {
    onLanguageChange: (val) => changeSLanguage(val),
  };
}
module.exports = withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
);
