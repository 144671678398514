module.exports = {

  // Quiz
  // exitQuizButtonTitle: no translation yet

  // QuizProgressLabel
  progressLabelText: 'vollständig',

  // QuizNavigationButton
  quizNavBackButtonShortenedText: 'Vorherige',
  quizNavBackButtonFullLengthText: 'Vorherige Frage',
  quizNavNextButtonShortenedText: 'Nächste',
  quizNavNextButtonFullLengthText: 'Nächste Frage',

  // QuizExitSlide
  quizExitSubtitle: 'Mithilfe Ihrer Angaben entwerfen wir jetzt das perfekte Büro für Ihr Unternehmen.',
  // quizExitImgAlt: no translation yet

  // Question Unit Text (questionUnitMap.js)
  question1Text: 'Wie würden Sie ihr zukünftiges Büro beschreiben?',
  question2Text: 'Was an Ihrem Unternehmen ist für Bewerber am attraktivsten?',
  question3Text: 'Worauf achten Sie beim Einstellen neuer Mitarbeiter besonders?',
  question4Text: 'Wie wird sich Ihr Unternehmen in den nächsten Jahren entwickeln?',
  question5Text: 'Wie sind die Arbeitszeiten Ihrer Mitarbeiter?',
  question6Text: 'Was halten Sie von individuellen Arbeitsplätzen?',
  question7Text: 'Wie soll im neuen Büro gearbeitet werden?',
  question8Text: 'Wie ist der Dresscode in Ihrem Büro?',
  question9Text: 'Von wo aus arbeiten Sie?',
  question10Text: 'Welche Arbeitsatmosphäre im Büro würden sie sich wünschen?',
  question11Text: 'Neben den klassischen Arbeitsplätzen, was würden Sie sich in der zukünftigen Arbeitsumgebung wünschen?',
  question12Text: 'Wer sind Sie?',

  // Quiz Answers (questionUnitMap.js)
  pickOnePlaceholder: 'auswählen...',
  selectFromListPlaceholder: 'Aus der Liste wählen',
  firstResponseTooltipText: 'Wählen Sie Ihre Antwort aus',
  locatedSomewhereElseLabel: 'Ich befinde mich derzeit an einem anderen Ort.',

  q1AnswerText1: 'Wir betrachten unser Büro erstens als',
  q1AnswerText2: 'zweitens als',
  q1AnswerText3: 'drittens als',
  q1Option1Text: 'ein zweites Zuhause für unsere Mitarbeiter',
  q1Option2Text: 'einen Treiber des organisatorischen Wandels',
  q1Option3Text: 'einen Ort für konzentriertes Arbeiten',
  q1Option4Text: 'den Entstehungsort für die nächste großartige Idee',
  q1Option5Text: 'ein Tool, um Talente anzuwerben',
  q1Option6Text: 'ein Showcase für unser Unternehmen',
  q1Option7Text: 'einen Ort der Weiterentwicklung und des Lernens',
  q1Option8Text: 'einen Ort für die Gemeinschaft',
  q1Option9Text: 'einen Kostenpunkt, den wir minimieren wollen',

  q2AnswerText1: 'Bewerber kommen zu uns erstens aufgrund',
  q2AnswerText2: 'zweitens aufgrund',
  q2AnswerText3: 'drittens aufgrund',
  q2Option1Text: 'der Unternehmenskultur',
  q2Option2Text: 'der wettbewerbsfähigen Gehälter',
  q2Option3Text: 'des Ansehens des Unternehmens',
  q2Option4Text: 'der Vorteile und Vergünstigungen',
  q2Option5Text: 'der flexiblen Arbeitszeiten',
  q2Option6Text: 'der Mitarbeiter und Führung',
  q2Option7Text: 'der Arbeitswelt',
  q2Option8Text: 'der Ziele und der Vision des Unternehmens',

  q3AnswerText1: 'Wir stellen Mitarbeiter ein erstens aufgrund ihrer',
  q3AnswerText2: 'zweitens aufgrund ihrer',
  q3AnswerText3: 'drittens aufgrund ihrer',
  q3Option1Text: 'Fähigkeiten',
  q3Option2Text: 'Erfahrung',
  q3Option3Text: 'Unternehmenskultur',
  q3Option4Text: 'Referenzen',
  q3Option5Text: 'Vernetzung',
  q3Option6Text: 'Neugier/Wissbegierde',

  q4AnswerText: 'Wir werden',
  q4Option1Text: 'jedes Jahr ein wenig wachsen',
  q4Option2Text: 'jedes Jahr erheblich wachsen',
  q4Option3Text: 'in etwa unsere Größe beibehalten',
  q4Option4Text: 'jedes Jahr ein wenig kleiner werden',
  q4Option5Text: 'schwer zu sagen - das ist nicht abzusehen',

  q5AnswerText: 'Normalerweise arbeiten wir',
  q5Option1Text: 'zu fest definierten Zeiten, z.B. „from 9 to 5',
  q5Option2Text: 'viel, von früh bis spät',
  q5Option3Text: 'die vorgegeben Stunden, allerdings zu flexiblen Zeiten',
  q5Option4Text: 'wann und so oft wir wollen',

  q6AnswerText: 'Wir finden',
  q6Option1Text: 'dass jede/r einen persönlich zugewiesenen Arbeitsplatz haben sollte',
  q6Option2Text: 'es kann von Vorteil sein, Arbeitsplätze zu teilen',

  q7Option1Text: 'Immer an einem persönlich zugewiesenen Arbeitsplatz',
  q7Option2Text: 'Meist am persönlich zugewiesenen Arbeitsplatz, gelegentlich in Meeting- oder anderen Räumen',
  q7Option3Text: 'Gleichermaßen am Schreibtisch und in anderen Bereichen',
  q7Option4Text: 'Meist in anderen Bereichen, gelegentlich am Schreibtisch',
  q7Option5Text: 'An ständig wechselnden Arbeitsorten',

  q8Option1Text: 'Immer Business professional',
  q8Option2Text: 'Hauptsächlich professional, ab und zu Business casual',
  q8Option3Text: 'Business casual, an bestimmten Tagen professional',
  q8Option4Text: 'Hauptsächlich casual, mit gelegentlichen Ausnahmen',
  q8Option5Text: 'Casual',

  q9Option1Text: 'Immer vom Büro aus',
  q9Option2Text: 'Hauptsächlich vom Büro aus',
  q9Option3Text: 'Teils vom Büro aus und teils von außerhalb',
  q9Option4Text: 'Hauptsächlich von außerhalb des Büros',
  q9Option5Text: 'Immer von außerhalb des Büros',

  q10Option1Text: 'Dynamisch und voller Energie',
  q10Option2Text: 'Voller Energie mit einzelnen ruhigen Bereichen',
  q10Option3Text: 'Es kommt darauf an - eine Mischung aus beidem',
  q10Option4Text: 'Ruhig, mit einzelnen dynamischen Phasen',
  q10Option5Text: 'Leise und ruhig',

  q11Option1Text: 'nur das Nötigste, genug um arbeiten zu können',
  q11Option2Text: 'das Nötigste mit ein paar Extras',
  q11Option3Text: 'das Nötigste und einen Ort zum sozialen Netzwerken',
  q11Option4Text: 'eine wirklich attraktive Fläche',
  q11Option5Text: 'einen echten Hingucker - einen Ort, den man Besuchern zeigen möchte',

  q12AnswerText: 'Ich bin',
  q12OptionClientText: 'ein Kunde von CBRE',
  q12OptionProfessionalText: 'ein CBRE Mitarbeiter',
  q12OptionLeadText: 'kein Kunde von CBRE',

  q13AnswerText1: 'In unserem Büro werden',
  q13AnswerText2: 'Mitarbeiter arbeiten.',
  q13AnswerHelperText: 'Hinweis: Dies ist normalerweise zur Hälfte Ihrer Mietlaufzeit oder der Zeitpunkt in 2-5 Jahren ab heute.',

  q14AnswerText1: 'Wir verbringen',
  q14AnswerText2: 'Zeit mit Teamarbeit.',

  q15AnswerText1: 'Wir gehen davon aus, dass wir zukünftig',
  q15AnswerText2: 'Tage pro Woche im Büro arbeiten werden.',

  q16AnswerText1: 'Unser Büro soll in',
  q16AnswerText2: 'sein.',

  q17AnswerText1: 'Wir sind hauptsächlich im Bereich',
  q17AnswerText2: 'tätig.',

  q18AnswerText1: 'Ich befinde mich derzeit in',
  q18AnswerText2: '.',


  q19AnswerText1: 'Wir denken, dass die zukünftige Belegungsdichte in unserem Büro',
  q19AnswerText2: '',




  // Quiz Responses (questionUnitMap.js)
  q1ResponseText: 'Wir entwerfen ein Büro, das Ihren Vorstellungen entspricht.',
  q2ResponseText: 'Eine gute Auswahl!',
  q3ResponseText: 'Das klingt super! Wo dürfen wir uns bewerben?',
  q4ResponseText: 'Alles klar! Wir sorgen dafür, dass das Büro zu Ihnen passt.',
  q5Response1Text: 'Das klingt gut!',
  q5Response2Text: 'Wir machen auch oft Überstunden.',
  q5Response3Text: 'Das klingt sehr gut!',
  q5Response4Text: 'Hört sich toll an!',
  q6Response1Text: 'Wir schaffen eine Fläche, die persönliche Arbeitsbereiche priorisiert.',
  q6Response2Text: 'Wir entwerfen ein flexibles Büro für Sie.',
  q12ResponseLeadText: 'Bei uns sind Sie an der richtigen Adresse.',
  q12ResponseClientText: 'Perfekt!',
  q12ResponseProfessionalText: 'Vielen Dank!',

  // Industry Codes (industryCodes.js)
  animalProd: 'Tierproduktion und Aquakultur',
  forestry: 'Forst- und Holzeinschlagwirtschaft',
  fishingHunting: 'Fischfang, Jagd und Fangen',
  agForestSupport: 'Unterstützungsaktivitäten für Land- und Forstwirtschaft',
  oilGas: 'Öl- und Gasförderung',
  energy: 'Energie (Gas, Öl, etc.)',
  mining: 'Bergbau außgenommen Öl und Gas',
  supportMining: 'Unterstützungsaktivitäten für den Bergbau',
  utilities: 'Dienstprogramme',
  buildingConstruct: 'Gebäudekonstruktionen',
  heavyCivilConstruct: 'Schwer- und Tiefbau',
  specialtyContract: 'Fachhandel',
  foodManufac: 'Lebensmittelherstellung',
  beverageTobaccoManufac: 'Herstellung von Getränken und Tabakwaren',
  textile: 'Textilunternehmen',
  textileProduct: 'Textilproduktionsunternehmen',
  apparelManufac: 'Bekleidungsherstellung',
  leatherManufac: 'Herstellung von Leder und Verbundprodukten',
  woodManufac: 'Herstellung von Holzprodukten',
  paperManufac: 'Papierherstellung',
  printingSupport: 'Drucken und damit verbundene Unterstützungsaktivitäten',
  printingReproduc: 'Druck / Reproduktionen',
  petroleumCoal: 'Herstellung von Erdöl- und Kohleprodukten',
  chemicalManufac: 'Chemische Industrie',
  chemicals: 'Chemieindustrie',
  pharma: 'Pharmazeutika',
  cleaningManufac: 'Reinigungsindustrie',
  plasticsRubberManufac: 'Herstellung von Kunststoff- und Gummiprodukten',
  nonmetallicManufac: 'Herstellung von nichtmetallischen Mineralprodukten',
  primaryMetalManufac: 'Primärmetallherstellung',
  fabricatedMetalManufac: 'Herstellung von Metallerzeugnissen',
  machineryManufac: 'Maschinenbau',
  manufac: 'Herstellung',
  computerElectronicManufac: 'Herstellung von Computern und elektronischen Produkten',
  electricalApplianceManufac: 'Elektrische Ausrüstung sowie Geräte',
  transportEquipManufac: 'Herstellung von Transportmitteln',
  aerospaceDefense: 'Luft- und Raumfahrt / Verteidigung',
  furnitureManufac: 'Herstellung von Möbeln und zusammenhängenden Produkten',
  miscManufac: 'Sonstige Herstellung',
  merchantWholesaleDurable: 'Großhändler, langlebige Güter',
  merchantWholesaleNondurable: 'Großhändler, Verbrauchsgüter',
  electronicMarket: 'Elektronische Märkte, Vermttler und Makler',
  motorVehiclePartsDealers: 'KFZ- und Ersatzteilhändler',
  furnitureFurnishingStores: 'Möbel- und Einrichtungsgeschäfte',
  electronicApplianceStores: 'Elektronik und Haushaltsgeräte',
  electronics: 'Elektronik',
  buildingGardenSupplyStores: 'Baumaterial- und Gartenbedarfsläden',
  foodBeverageStores: 'Lebensmittel- und Getränkehändler',
  groceryStores: 'Lebensmittelgeschäfte',
  beerWineLiquorStores: 'Bier, Wein und Spirituosengeschäfte',
  healthCareStores: 'Geschäfte für Gesundheit und Körperpflege',
  gasStations: 'Tankstellen',
  clothingAccessoriesStores: 'Bekleidungsgeschäfte und Bekleidungszubehör',
  clothingTextiles: 'Kleidung / Textilien',
  jewelryFurs: 'Schmuck &amp; Pelze',
  sportsHobbyMusicBookStores: 'Sport, Hobby, Musikinstrument, Buchhandlungen',
  genMerchandiseStores: 'Allgemeine Warenhäuser',
  miscStoreRetailers: 'Sonstiges Einzelhandel',
  officeProducts: 'Büroprodukte',
  retailSales: 'Einzelhandelsumsätze',
  nonstoreRetails: 'Nicht-Filialisten',
  airTransport: 'Lufttransport',
  travelRelated: 'Reisebezogen (Fluggesellschaften, Reisebüros, Nahverkehr)',
  railTransport: 'Bahntransport',
  waterTransport: 'Wassertransport',
  truckTransport: 'LKW-Transport',
  transitTransport: 'Transit- und Bodenverkehr',
  pipelineTransport: 'Rohrleitungstansport',
  scenicTransport: 'Verkehrs- und Tourismustransport',
  supportTransport: 'Transportunterstützung',
  postalService: 'Postdienst',
  courierMessenger: 'Kuriere und Boten',
  shippingDelivery: 'Versand &amp; Lieferung',
  warehousingStorage: 'Lagerung',
  warehouseDistrib: 'Lager / Vertrieb',
  publishingNoInternet: 'Verlagsbranche (papierbasiert)',
  publishing: 'Verlagswesen',
  motionPicSoundRecordingIndustries: 'Film- und Tonträgerindustrie',
  entertainment: 'Unterhaltung (Film, Fernsehen, Musik usw.)',
  infoServicesMarketResearch: 'Informationsdienste / Marktforschung',
  broadcastingNoInternet: 'Rundfunk',
  telecom: 'Telekommunikation',
  tech: 'Technologie',
  dataProcessingHostingServices: 'Datenverarbeitung, Hosting und damit verbundene Dienstleistungen',
  otherInfoServices: 'Andere Informationsdienste',
  financeInsurance: 'Finanz- und Versicherungswesen',
  monetaryAuthorities: 'Währungsbehörden - Zentralbank',
  creditIntermediation: 'Kreditvermittlung und damit verbundene Tätigkeiten',
  banking: 'Bankgeschäfte',
  securitiesContractsInvestments: 'Wertpapiere, Warenverträge, Investitionen',
  financialServicesNoBanking: 'Finanzdienstleistungen (keine Bankgeschäfte)',
  insuranceCarriersRelated: 'Versicherungsträger und damit verbundene Tätigkeiten',
  insurance: 'Versicherung',
  fundsTrustsFinancialVehicles: 'Fonds, Treuhand und andere Finanzinstrumente',
  realEstate: 'Immobilien',
  rentalLeasingServices: 'Vermietung und Leasing',
  automotive: 'Automobilindustrie',
  consumerGoods: 'Konsumgüter',
  lessorsNonfinancialIntangible: 'Leasinggeber von nicht finanziellen immateriellen Vermögenswerten',
  professionalTechnicalServices: 'Professionelle und technische Dienstleistungen',
  legalServices: 'Rechtsberatung',
  accounting: 'Buchhaltung',
  architectureEngineering: 'Architektur / Ingenieurwesen',
  specializedDesignServices: 'Spezialisierte Konstruktions-Services',
  compRelatedServices: 'Computerbezogene Dienstleistungen',
  mgmtConsulting: 'Unternehmensberatung',
  scientificResearch: 'Wissenschaftliche Forschung',
  adsMarketingPR: 'Werbung / Marketing / Öffentlichkeitsarbeit',
  otherProfSciTechServices: 'Andere professionelle, wissenschaftliche und technische Dienstleistungen',
  companyEnterpriseMgmt: 'Management von Unternehmen und Konzernen',
  adminSupportServices: 'Verwaltungs- und Unterstützungsdienste',
  employmentAgencyRecruiters: 'Personalvermittler / Recruiter',
  securityPrivateInvestigation: 'Sicherheit / Privatuntersuchung',
  wasteMgmtRemediationServices: 'Abfallbewirtschaftung und Sanierung',
  educationServices: 'Bildungsservice',
  education: 'Bildung',
  ambulatoryHealthCareServices: 'Ambulante Gesundheitsdienste',
  healthCareRelated: 'Gesundheitswesen',
  hospitals: 'Krankenhäuser',
  nursingResidentialCareFacilities: 'Pflege- und Wohneinrichtungen',
  socialAssistance: 'Soziale Unterstützung',
  performingArtsSpectatorSports: 'Darstellende Kunst und Zuschauersport',
  museumsHistoricalSitesZoosParks: 'Museen, historische Stätten, Zoos und Parks',
  fineArtsGalleriesMuseums: 'Bildende Kunst (Galerien, Museen)',
  amusementsGamblingRec: 'Vergnügungen, Glücksspiele und Erholung',
  accommodation: 'Unterkunft',
  foodDrinkingServices: 'Verpflegungsdienstleistungen',
  restaurantHotel: 'Restaurant / Hotel',
  repairMaintenance: 'Reparatur und Wartung',
  industrialProductsHeavy: 'Industrieprodukte (Schwerindustrie)',
  personalLaundryServices: 'Personen- und Wäscheservice',
  membershipAssocOrgs: 'Mitgliedsverbände und -organisationen',
  nonprofit: 'Soziale Einrichtung',
  privateHouseholds: 'Private Haushalte',
  execLegisGenGvt: 'Exekutive, Legislative und staatliche Verwaltung',
  gvt: 'Regierung',
  justicePublicOrderSafetyActivities: 'Justiz, öffentliche Ordnung und Sicherheitsaktivitäten',
  hrAdminPrograms: 'Personalverwaltung',
  environmentAdminPrograms: 'Verwaltung von Umweltprogrammen',
  environmental: 'Umwelt',
  communityHousingProgramAdmin: 'Gemeinschafts- und Wohnungsprogrammverwaltung',
  econProgramAdmin: 'Verwaltung von Wirtschaftsprogrammen',
  spaceResearchTech: 'Weltraumforschung und Technologie',
  natlSecurityIntlAffairs: 'Nationale Sicherheit und internationale Angelegenheiten'

};
