const React = require('react');
const PropTypes = require('prop-types');
const sharedPropTypes = require('../../../shared/components/sharedPropTypes');
const constants = require('wp-constants').spacerFree;
const DropdownSelectionInput = require('../form/DropdownSelectionInput.jsx');
const { withTranslation } = require('react-i18next');

const optionOrder = [
  constants.calculator.DENSITY_LEVEL.SAME,
  constants.calculator.DENSITY_LEVEL.LOW,
  constants.calculator.DENSITY_LEVEL.NOT_SURE
];

function CirculationDropdown(props) {
  const dropdownOptions = {
    [constants.calculator.DENSITY_LEVEL.SAME]: { text: props.t('preCovidText') },
    [constants.calculator.DENSITY_LEVEL.LOW]: { text: props.t('higherText') },
    [constants.calculator.DENSITY_LEVEL.NOT_SURE]: { text: props.t('notSureText') }
  };

  return (
    <DropdownSelectionInput
      options={dropdownOptions}
      optionOrder={optionOrder}
      {...props}
    />
  );
}

CirculationDropdown.propTypes = {
  value: sharedPropTypes.stringOrNumber.isRequired,
  onValueChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLarge: PropTypes.bool,
  t: PropTypes.func.isRequired
};

CirculationDropdown.defaultProps = {
  disabled: false,
  isLarge: false
};

module.exports = withTranslation('resultsPage')(CirculationDropdown);

