const React = require('react');
const PropTypes = require('prop-types');
const { withTranslation } = require('react-i18next');
const GALabels = require('../../../optionsConfig/googleAnalytics.labels.json');
const ga = require('../../../../shared/tracking');

function FlooredPlanReqButton(props) {
    return (

        <div>
            <a style={{ display: "table-cell" }} href="https://cbre.co/PlansRequest" target="_blank"
                onClick={() => {
                    ga.GAEvent(GALabels.categories.result, GALabels.actions.requestPlanBtn, GALabels.labels.requestPlanBtn);
                }}
            >
                <span className="button cta-button is-primary-filled has-min-width-narrow heap-size-my-office-button flooredPlanButton is-calibre">{props.t('flooredPlanRequestLinkText')}</span>
            </a>
        </div>
    );
}

FlooredPlanReqButton.propTypes = {
    t: PropTypes.func.isRequired
};

module.exports = withTranslation('common')(FlooredPlanReqButton);

