const { connect }                         = require('react-redux');
const { bindActionCreators }              = require('redux');
const _                                   = require('lodash');
const spaceDataActions                    = require('../../../../redux/actions/spaceData');
const programDataRetriever                = require('../../../../util/programDataRetriever');
const suggestedCustomAmenityUnitMap       = require('wp-data').suggestedCustomAmenityUnitMap;
const DEFAULT_CUSTOM_AMENITY_DISPLAY_UNIT = require('wp-constants').shared.spaceUnit.UNIT_LABEL.DEFAULT_CUSTOM_AMENITY_DISPLAY_UNIT;


const SpaceUnitRow           = require('../../../results/program/SpaceCategoryBreakdown/SpaceUnitRow.jsx');

// Map state to props
function mapStateToProps(state, ownProps) {
  const suggestedAmenity = _.find(suggestedCustomAmenityUnitMap, ['dataKey', ownProps.unit.dataKey]);
  const quantity = programDataRetriever.getQuantity(ownProps.unit);

  return {
    sfType: state.spaceData.sfType,
    measurementSystem: state.spaceData.measurementSystem,

    spaceUnitId: ownProps.unit.id,
    name: ownProps.unit.displayName,
    area: ownProps.unit.customSF,
    quantity,
    totalArea: programDataRetriever.getNSF(ownProps.unit),
    // Pass the quantity as "count", and i18next can pluralize! See i18n.js for more explanation.
    displayUnit: ownProps.t((suggestedAmenity && suggestedAmenity.displayUnit) || DEFAULT_CUSTOM_AMENITY_DISPLAY_UNIT, { count: quantity }),

    areaIsEditable: true,
    quantityIsEditable: true,

    delta: programDataRetriever.getNSFDelta(ownProps.unit),
    showDeltaPercentage: false
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    onQuantityUpdate: bindActionCreators(spaceDataActions.updateCustomAmenityQuantity, dispatch),
    onAreaUpdate: bindActionCreators(spaceDataActions.updateCustomAmenitySF, dispatch),
    deleteFunction: bindActionCreators(spaceDataActions.deleteSpaceUnit, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(SpaceUnitRow);

