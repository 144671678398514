const React              = require('react');
const PropTypes          = require('prop-types');
const profileMap         = require('wp-data').profileMap;
const ProfileTrait       = require('./ProfileTrait.jsx');
const { withTranslation } = require('react-i18next');

function ProfileComparisonColumn(props) {
  const profile = profileMap[props.profileId];
  const profileType = props.profileType;
  const profileDescriptionFirstParagraph = props.t(profile.descriptionToken).split('\n')[0]; // only show first paragraph, user will see full description if they choose the profile

  let columnClasses = 'profile-column no-outline';
  if (props.isSelected) columnClasses += ' is-active';

  let buttonText = (props.isSelected) ? props.t('profileCompColumnSelectedText') : props.t('profileCompColumnSelectText');
  if (props.isRecommended) buttonText += props.t('profileCompColumnRecommendedText');

  const buttonClasses = `button no-focus-style has-margin-bottom-6 ${props.isSelected ? 'is-tertiary-filled' : 'is-tertiary-outlined has-tertiary-text is-filled-on-hover'}`;

  const profileName = props.t(profile.displayNameToken);

  return (
    <div className={columnClasses} role="button" tabIndex="0" onClick={props.onClick} key={`profile-${props.profileId}`}>
      <button className={buttonClasses}>
        {buttonText}
      </button>
      <div className="profile-summary-and-traits">
        <div className="profile-summary">
          <img
            src={profileMap[props.profileId].profileHeroImage}
            alt={props.t('profileCompColumnImgAltText', { profileName })}
          />
          <h2 className="title is-5 has-padding-top-7 has-margin-bottom-7">{profileName}</h2>
          <div className="profile-description">{profileDescriptionFirstParagraph}</div>
        </div>

        <div className="profile-traits">
          {profile.keyTraitIds.map(traitId => <ProfileTrait traitId={traitId} key={traitId} profileType={profileType} isDark />)}
        </div>
      </div>

    </div>

  );
}

ProfileComparisonColumn.propTypes = {
  profileType: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isRecommended: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(ProfileComparisonColumn);
