const React = require('react');
const PropTypes = require('prop-types');
const _ = require('lodash');
const constants = require('wp-constants').spacerFree;
const SPACE_UNIT_CATEGORY = require('wp-constants').shared.spaceUnit.CATEGORY;
const spacerFreePropTypes = require('../../spacerFreePropTypes');
const ProgramBarChart = require('../../containers/results/program/ProgramBarChart.jsx');
const ProgramStats = require('../../containers/results/program/ProgramStats.jsx');
const ProgramAssumptions = require('../../containers/results/program/ProgramAssumptions.jsx');
const TestFit = require('./TestFit.jsx');
const TestFitForm = require('../../containers/forms/TestFitForm.jsx');
const SpaceCategoryBreakdown = require('../../containers/results/program/SpaceCategoryBreakdown.jsx');
const programDataRetriever = require('../../../util/programDataRetriever');
const ContentSection = require('../ContentSection.jsx');
const ExamplePlans = require('../../containers/results/profile/ExamplePlans.jsx');
const HighlightedContentSection = require('../HighlightedContentSection.jsx');
const EmailWallSection = require('../../containers/results/program/EmailWallSection.jsx');
const SectionTitle = require('../../text/SectionTitle.jsx');
const NextStepsSection = require('../../containers/common/NextStepsSection.jsx');
const domUtil = require('../../../../shared/util/dom.js');
const { withTranslation } = require('react-i18next');
const { Trans } = require('react-i18next');
const FlooredPlanReqSection = require('./FlooredPlanReqSection.jsx');
const GALabels = require('../../../optionsConfig/googleAnalytics.labels.json');
const ga = require('../../../../shared/tracking');

const TOO_MUCH_TINKERING_BOUNDARY = 0.2;

class Program extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeCategory: SPACE_UNIT_CATEGORY.AMENITY,
      isTinkerWarningVisible: false,
      haveClosedTinkerWarning: false,
      changesToResultsSaved: false,
      programSavedFromEmailWall: false,
      testFitFormClosed: !this.props.testFitFormCompleted
    };

    // Taking these bits of state off of React state because don't want to subscribe to the
    // React setState->render loop

    this.updateActiveCategory = this.updateActiveCategory.bind(this);
    this.closeTinkerWarning = this.closeTinkerWarning.bind(this);
    this.saveChangesOnSubmit = this.saveChangesOnSubmit.bind(this);
    this.revertChanges = this.revertChanges.bind(this);
    this.openTestFitForm = this.openTestFitForm.bind(this);
    this.closeTestFitForm = this.closeTestFitForm.bind(this);
  }

  // updating tinker warning
  componentDidUpdate(prevProps, prevState) {
    if (this.props.program.areaTotals !== prevProps.program.areaTotals || this.props.lossFactor !== prevProps.lossFactor) {
      this.props.detectChangeToSaveOnResults();
      this.props.onTinker();
    }

    const totalAreaDelta = programDataRetriever.getTotalSFDelta(this.props.program.areaTotals);

    const outsideTinkerBoundary = totalAreaDelta && (totalAreaDelta.percentDelta > TOO_MUCH_TINKERING_BOUNDARY || totalAreaDelta.percentDelta < -TOO_MUCH_TINKERING_BOUNDARY);

    // If we tinkered too far but haven't closed the warning, show the warning (prevents showing the warning every tinker after they close it)
    if (outsideTinkerBoundary && !prevState.haveClosedTinkerWarning) {
      if (!prevState.isTinkerWarningVisible) this.setState({ isTinkerWarningVisible: true });
    } else if (!outsideTinkerBoundary) {
      // Reset the closed flag so that if they go outside the bounds, close, go back in bounds, then back outside, we re-warn them
      if (prevState.isTinkerWarningVisible || prevState.haveClosedTinkerWarning) this.setState({ isTinkerWarningVisible: false, haveClosedTinkerWarning: false });
    }
    if (!this.props.showEmailWall && prevProps.showEmailWall) {
      this.setState({ programSavedFromEmailWall: true });
      domUtil.scrollToWithNavbar(this.programElement);
    }
  }

  closeTinkerWarning() {
    this.setState({ isTinkerWarningVisible: false, haveClosedTinkerWarning: true });
  }

  updateActiveCategory(activeCategory) {
    this.setState({
      activeCategory
    });
  }

  saveChangesOnSubmit() {
    this.props.updateProgram(this.props.programId);
    ga.GAEvent(GALabels.categories.result, GALabels.actions.saveChangesBtn, GALabels.labels.saveChangesBtn);

    this.setState({
      programSavedFromEmailWall: false,
      changesToResultsSaved: true
    });
    this.props.changeToResultsSaved();
    setTimeout(() => {
      this.setState({ changesToResultsSaved: false });
    }, 3000);
  }

  revertChanges() {
    this.props.fetchProgramIfNeeded(this.props.programId);
    domUtil.scrollToWithNavbar(this.programElement);
    this.props.changeToResultsSaved();
  }

  openTestFitForm() {
    this.setState({ testFitFormClosed: false });
  }

  closeTestFitForm() {
    this.setState({ testFitFormClosed: true });
  }

  render() {
    let visibleBreakDown;

    const meBreakdown = <SpaceCategoryBreakdown spaceUnitCategory={SPACE_UNIT_CATEGORY.ME} updateCategory={this.updateActiveCategory} />;
    const weBreakdown = <SpaceCategoryBreakdown spaceUnitCategory={SPACE_UNIT_CATEGORY.WE} updateCategory={this.updateActiveCategory} />;
    const amenityBreakdown = (
      <SpaceCategoryBreakdown
        spaceUnitCategory={SPACE_UNIT_CATEGORY.AMENITY}
        updateCategory={this.updateActiveCategory}
        programForCategory={
          [...this.props.program[SPACE_UNIT_CATEGORY.AMENITY],
          ...this.props.program[SPACE_UNIT_CATEGORY.SUPPORT],
          ...(_.map(this.props.program[SPACE_UNIT_CATEGORY.CUSTOM_AMENITY], (amenity) => {
            const amenityWithCustomFlag = _.cloneDeep(amenity);
            amenityWithCustomFlag.isCustomAmenity = true;
            amenityWithCustomFlag.displayName = this.props.t(amenityWithCustomFlag.displayName);
            return amenityWithCustomFlag;
          })) // add custom amenity flag to all custom amenities
          ]}
      />
    );

    let featureBanner = null;
    if (window.SERVER_DATA.featureFlags.planIntegrationEnabled) {
      featureBanner = (this.state.testFitFormClosed ?
        (<TestFit openTestFitForm={this.openTestFitForm} />) :
        (
          <TestFitForm
            closeTestFitForm={this.closeTestFitForm}
            completed={this.props.testFitFormCompleted}
          />
        ));
    } else {
      featureBanner = (
        <ContentSection hideOnMobile hideOnPrint>
          <SectionTitle
            title={this.props.t('programExamplePlansTitle')}
            subtitle={this.props.t('programExamplePlansSubtitle')}
          />
          <ExamplePlans />
        </ContentSection>
      );
    }

    if (this.state.activeCategory === SPACE_UNIT_CATEGORY.ME) {
      visibleBreakDown = meBreakdown;
    } else if (this.state.activeCategory === SPACE_UNIT_CATEGORY.WE) {
      visibleBreakDown = weBreakdown;
    } else {
      visibleBreakDown = amenityBreakdown;
    }

    const email = this.props.t('common:spacerSupportEmail');
    const tooMuchTinkerWarning = (this.state.isTinkerWarningVisible &&
      <div className="notification is-danger program-tinker-warning">
        <button className="delete" onClick={this.closeTinkerWarning} />
        {/* See Trans usage note in assets/js/spacerFree. */}
        <Trans i18nKey="programTooMuchTinkerWarning" email={email}>
          0<a href="/quiz-spacer-by-cbre">1</a>2<a href={`mailto:${constants.contact.SPACER_SUPPORT_EMAIL}`}>{{ email }}</a>.
        </Trans>
      </div>
    );

    let savedChangesClass = '';
    let saveButtonText = this.props.t('programSaveButtonText');
    if (this.state.changesToResultsSaved) {
      savedChangesClass = 'has-checkmark-icon-before';
      saveButtonText = this.props.t('programSavedButtonText');
    }
    let workspaceClasses = ' is-hidden-mobile';
    if (domUtil.isScreenMobileSize()) workspaceClasses = ' is-mobile-input mobile-buttons';

    return (
      <div ref={(node) => { this.programElement = node; }}>
        {
          this.props.showEmailWall ?
            <EmailWallSection />
            :
            (<React.Fragment>
              {this.state.programSavedFromEmailWall &&
                (<ContentSection hideOnPrint key="programBox">
                  <div className="program-save-confirmation-box">
                    <div className="program-save-confirmation-box-text">
                      {this.props.t('programSaveConfirmationBoxText')}
                    </div>
                  </div>
                </ContentSection>)}

              <HighlightedContentSection>
                <SectionTitle
                  title={this.props.t('programMetricsTitle')}
                  subtitle={this.props.t('programMetricsSubtitle')}
                />
                <ProgramStats programStats={this.props.program.finalStats} measurementSystem={this.props.measurementSystem} />
              </HighlightedContentSection>

              <div className="is-print-only print-disclaimer">
                {this.props.t('programDisclaimer')}
              </div>

              <ContentSection>
                <div className="your-details">
                  <SectionTitle
                    title={this.props.t('programDetailsSectionTitle')}
                    subtitle={this.props.t('programDetailsSectionSubtitle')}
                  />
                  {tooMuchTinkerWarning}
                  <div className="your-details-border">
                    <ProgramAssumptions
                      detectChangeToSaveOnResults={this.props.detectChangeToSaveOnResults}
                    />
                    <div className="your-details-workspace">
                      <div className={`flex flex-row flex-justify-end ${workspaceClasses}`}>
                        <button
                          className="button cta-button is-primary-outlined has-min-width-narrow program-reset-button"
                          onClick={this.revertChanges}
                          disabled={!this.props.changesMadeToResultsDetails || this.props.isSubmittingForm}
                        >
                          {this.props.t('programRevertButtonText')}
                        </button>
                        <button
                          className={`button cta-button is-primary-filled has-dark-success-color ${savedChangesClass} has-min-width-narrow save-changes-button`}
                          onClick={this.saveChangesOnSubmit}
                          disabled={!this.props.changesMadeToResultsDetails || this.props.incompleteAssumptions}
                        >
                          {saveButtonText}
                        </button>
                      </div>
                      <ProgramBarChart />
                      <div className="visible-breakdown">{visibleBreakDown}</div>
                      <div className="is-print-only all-breakdowns-for-print is-hidden-mobile">{meBreakdown}{weBreakdown}{amenityBreakdown}</div>
                    </div>
                  </div>
                </div>
              </ContentSection>

              {featureBanner}
              {this.props.userData.role === "cbreProfessional" ? <FlooredPlanReqSection /> : null}
              <NextStepsSection />
            </React.Fragment>)
        }
      </div>
    );
  }
}

Program.propTypes = {
  userData: spacerFreePropTypes.userDataForQuizShape.isRequired,
  program: spacerFreePropTypes.programShape.isRequired,
  incompleteAssumptions: PropTypes.bool.isRequired,
  lossFactor: PropTypes.number.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  showEmailWall: PropTypes.bool.isRequired,
  updateProgram: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  onTinker: PropTypes.func.isRequired,
  detectChangeToSaveOnResults: PropTypes.func.isRequired,
  changeToResultsSaved: PropTypes.func.isRequired,
  changesMadeToResultsDetails: PropTypes.bool.isRequired,
  fetchProgramIfNeeded: PropTypes.func.isRequired,
  isSubmittingForm: PropTypes.bool.isRequired,
  testFitFormCompleted: PropTypes.bool,
  t: PropTypes.func.isRequired
};

Program.defaultProps = {
  testFitFormCompleted: false
};

module.exports = withTranslation('resultsPage')(Program);

