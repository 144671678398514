const React     = require('react');
const PropTypes = require('prop-types');


function IllustrationWithText(props) {
  return (
    <div className="has-padding-left-4 has-padding-right-4 has-padding-top-7 has-padding-bottom-7">
      <figure className="image">
        <img src={props.imgSrc} alt={props.imgAlt} />
      </figure>
      <h3 className="has-margin-bottom-4">
        <div className="is-sans-semibold has-margin-bottom-7 has-margin-top-7 has-text-link">{props.subtitle}</div>
        <div className="title2 is-4">{props.title}</div>
      </h3>
      <div>{props.text}</div>
    </div>
  );
}

IllustrationWithText.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired
};


module.exports = IllustrationWithText;
