const actions   = require('../actions/modal');
const constants = require('wp-constants').spacerFree;


module.exports = function modals(state = {}, action) {
  switch (action.type) {
    case actions.OPEN_MODAL: {
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    }

    case actions.CLOSE_MODAL: {
      return {
        modalType: constants.modal.TYPES.NONE,
        modalProps: null
      };
    }

    default: {
      return state;
    }
  }
};
