module.exports = {

  // LandingPageHero
  landingPageHeroTitle: 'Dovoľte nám naplánovať vaše pracovisko budúcnosti a nájsť ten správny priestor pre vašu kanceláriu.',
  landingPageHeroSubtitle: 'Svet kancelárskych priestorov sa v dôsledku pandémie mení. Získajte odporúčania týkajúce sa priestoru pomocou nášho bezplatného nástroja.',
  landingPageHeroImgAlt: 'Ukážka výsledkov Space programu z domovskej stránky programu Spacer od CBRE',
  landingPageImage: '/png/homepage-results-page-in-browser-spacer-by-cbre.png',
  // LandingPageContent
  landingPageContentTitle: 'Ako to funguje?',

  // StepsSummary
  stepsSummarySubtitle1: 'Krok 1 —',
  stepsSummaryTitle1: 'Povedzte nám viac o svojej spoločnosti',
  stepsSummaryText1: 'Pomôžte nám spoznať vaše ciele a zámery, aby sme mohli vytvoriť odporúčania priamo pre vás.',
  stepsSummaryImgAlt1: 'Ilustrácia ľudí stojacich na obrovskom notebooku na domovskej stránke programu Spacer od spoločnosti CBRE',

  stepsSummarySubtitle2: 'Krok 2 —',
  stepsSummaryTitle2: 'Získajte svoje výsledky',
  stepsSummaryText2: 'Na základe vašich odpovedí dostanete odporúčanú veľkosť kancelárie a zoznam jednotlivých typov priestorov. Môžete si dokonca pozrieť ukážky pôdorysov.',
  stepsSummaryImgAlt2: 'Ilustrácia susedskej štvrte na domovskej stránke programu Spacer od spoločnosti CBRE',

  stepsSummarySubtitle3: 'Krok 3 —',
  stepsSummaryTitle3: 'Nájdite si svoju dokonalú kanceláriu',
  stepsSummaryText3: 'Uložte svoje výsledky, ku ktorým sa môžete vrátiť počas hľadania kancelárie, alebo kontaktujte tím Spacer a spojte sa s jedným z našich leasingových profesionálov, ktorí vám môžu pomôcť nájsť vašu dokonalú kanceláriu.',
  stepsSummaryImgAlt3: 'Ilustrácia ženy pracujúcej za stolom na domovskej stránke Spacer od spoločnosti CBRE',

  // LandingPageSecondaryHero
  landingPageSecondaryHeroImgAlt: 'Ilustrácia ľudí navrhujúcich priestorové odporúčania na domovskej stránke programu Spacer od spoločnosti CBRE',
  landingPageSecondaryHeroTitle: 'Povedzte nám niečo o svojej spoločnosti a my vám povieme, koľko kancelárskeho priestoru potrebujete.'

};
