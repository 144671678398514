const React                = require('react');
const PropTypes            = require('prop-types');
const IllustrationWithText = require('./IllustrationWithText.jsx');
const { withTranslation }   = require('react-i18next');

function StepsSummary(props) {
  return (
    <div className="columns steps-summary has-padding-left-6-mobile has-padding-right-6-mobile has-padding-bottom-3">
      <div className="column">
        <IllustrationWithText
          subtitle={props.t('stepsSummarySubtitle1')}
          title={props.t('stepsSummaryTitle1')}
          text={props.t('stepsSummaryText1')}
          imgSrc="/svg/illustrations/landing-page/homepage-people-on-laptop-spacer-by-cbre.svg"
          imgAlt={props.t('stepsSummaryImgAlt1')}
        />
      </div>
      <div className="column">
        <IllustrationWithText
          subtitle={props.t('stepsSummarySubtitle2')}
          title={props.t('stepsSummaryTitle2')}
          text={props.t('stepsSummaryText2')}
          imgSrc="/svg/illustrations/landing-page/homepage-neighborhood-block-spacer-by-cbre.svg"
          imgAlt={props.t('stepsSummaryImgAlt2')}
        />
      </div>
      <div className="column">
        <IllustrationWithText
          subtitle={props.t('stepsSummarySubtitle3')}
          title={props.t('stepsSummaryTitle3')}
          text={props.t('stepsSummaryText3')}
          imgSrc="/svg/illustrations/landing-page/homepage-girl-working-at-desk-spacer-by-cbre.svg"
          imgAlt={props.t('stepsSummaryImgAlt3')}
        />
      </div>
    </div>
  );
}

StepsSummary.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('landingPage')(StepsSummary);
