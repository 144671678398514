const React     = require('react');
const PropTypes = require('prop-types');
const Tooltip   = require('../../components/common/Tooltip.jsx');

function RadioInput(props) {
  let isActive;
  const radioElements = props.options.map((option) => {
    isActive = (props.value === option.value);

    return (
      <div className="control radio-control" key={option.value}>
        <label className={isActive ? 'is-active' : ''}>
          <input
            type="radio"
            name={props.name}
            value={isActive ? 'on' : ''}
            onClick={() => props.onChange(option.value)}
          />
          {option.text}
          {option.tooltipText && <Tooltip text={option.tooltipText} />}
        </label>
      </div>
    );
  });

  return (
    <div className="radio-control-container">
      {radioElements}
    </div>
  );
}

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired
    })
  ).isRequired
};

module.exports = RadioInput;
