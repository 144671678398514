module.exports = {
  blogHomeTitle: 'Pohľad do plánovania kancelárie',

  // blog tags
  blogTagAllArticles: 'Všetký články',
  blogTagSizeAndFit: 'Veľkosť a prispôsobenie',
  blogTagOfficeCulture: 'Kultúra pracoviska',
  blogTagSpaceAndDesign: 'Kancelárska kultúra',
  blogTagWorkplaceTrends: 'Workplace trendy'
};
