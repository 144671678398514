const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const programDataRetriever   = require('../../../../util/programDataRetriever');
const regionSFCalculator     = require('../../../../../shared/calculators/regionSFCalculator');
const spaceDataActions       = require('../../../../redux/actions/spaceData');

const SpaceCategoryBreakdown = require('../../../results/program/SpaceCategoryBreakdown/SpaceCategoryBreakdown.jsx');

// Map state to props
function mapStateToProps(state, ownProps) {
  const location = state.userData.officeLocation;
  const sfType = state.spaceData.sfType;
  const lossFactor = state.spaceData.lossFactor;
  const areaTotals = state.spaceData.program.areaTotals;
  const areaPercentage = state.spaceData.program.areaPercentage;
  const categoryArea = programDataRetriever.getTotalSFForCategory(areaTotals, ownProps.spaceUnitCategory);
  const categoryAreaDelta = programDataRetriever.getTotalDeltaForCategoryAndContext(areaTotals, ownProps.spaceUnitCategory, sfType, location, lossFactor);
  const totalArea = programDataRetriever.getTotalSF(areaTotals);

  const programForCategory = ownProps.programForCategory || state.spaceData.program[ownProps.spaceUnitCategory];

  return {
    areaPercentage,
    location,
    sfType,
    lossFactor,
    areaTotals,
    categoryArea,
    categoryAreaDelta,
    programForCategory,
    measurementSystem: state.spaceData.measurementSystem,
    totalAreaForContext: regionSFCalculator.getAreaForContext(totalArea, sfType, location, lossFactor),
    categoryAreaForContext: regionSFCalculator.getAreaForContext(categoryArea, sfType, location, lossFactor)
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    updateLossFactor: bindActionCreators(spaceDataActions.updateLossFactor, dispatch)
  };
}


module.exports = connect(mapStateToProps, mapDispatchToProps)(SpaceCategoryBreakdown);
