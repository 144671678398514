const React              = require('react');
const PropTypes          = require('prop-types');
const { withTranslation } = require('react-i18next');


class FixedNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false
    };

    this.updateScrollState = this.updateScrollState.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateScrollState);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state.isScrolled !== nextState.isScrolled) || 
           (this.props.navItems !== nextProps.navItems);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScrollState);
  }

  updateScrollState() {
    this.setState({
      isScrolled: window.pageYOffset !== 0
    });
  }


  render() {
    let navbarClasses = 'navbar is-fixed-top';
    if (this.state.isScrolled) {
      navbarClasses += ' has-shadow';
    }

    return (
      <nav className={navbarClasses} ref={(navbar) => { this.navbar = navbar; }} >
        <div className="container">
          <div className="navbar-brand">
            <a className="navbar-item cbre-spacer-logo" href="/">
              <img src="/svg/logos/spacer_logo.svg" alt="CBRE Spacer" />
            </a>
          </div>
          <div className="navbar-menu">
            <div className="navbar-end">
              {this.props.navItems}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

FixedNavbar.propTypes = {
  navItems: PropTypes.node,
  hasTransparentNavbarAtTop: PropTypes.bool,
  t: PropTypes.func.isRequired
};

FixedNavbar.defaultProps = {
  navItems: null,
  hasTransparentNavbarAtTop: false
};

module.exports = withTranslation('common')(FixedNavbar);
