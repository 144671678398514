function isPlansIntegrationEnabled() {
  return process.env.ENABLE_PLANS_INTEGRATION === "ON";
}

function isFrenchLanguageSupportEnabled() {
  return process.env.ENABLE_FRENCH_LANGUAGE_SUPPORT === "ON";
}

// feature flags passed to front end via window.SERVER_DATA object, can be accessed via window.SERVER_DATA.featureFlags
const flags = {
  planIntegrationEnabled: isPlansIntegrationEnabled(),
  frenchLanguageSupportEnabled: isFrenchLanguageSupportEnabled(),
  isGoogleAnalyticsEnabled: process.env.IS_GOOGLE_ANALYTICS_ENABLED,
  isHeapAnalyticsEnabled: process.env.IS_HEAP_ANALYTICS_ENABLED,
  googleId: process.env.GOOGLE_ANALYTICS_ID,
};

module.exports = {
  flags,
  isPlansIntegrationEnabled,
  isFrenchLanguageSupportEnabled,
};
