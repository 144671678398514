module.exports = {
  title: 'Why Defining Your Perfect Office Matters',
  pageTitle: 'Why Defining Your Perfect Office Matters — Spacer by CBRE',
  metaTagText: 'Having the appropriate office space can impact employee satisfaction, productivity, growth, culture, and company success. Learn more here.',
  preview: `
    If you have moved offices before, then you know just how challenging it can be.
    A move is one reason (but not the only reason) you may need help understanding
    how much space your company needs. Perhaps you are looking to reduce your office
    footprint or open up an office in a new location. Any real estate change you
    make will require you to answer this one question: how much space do you
    need? We realize that question is not an easy one to answer, and we want to
    help.
  `,
  content: `
    <p>
    If you have moved offices before, then you know just how challenging it can be.
    A move is one reason (but not the only reason) you may need help understanding
    how much space your company needs. Perhaps you are looking to reduce your office
    footprint or open up an office in a new location. Any real estate change you
    make will require you to answer this one question: <em>how much space do you
    need?</em> We realize that question is not an easy one to answer, and we want to
    help.
    </p>
    <p>
    If you’ve never thought about space before, then your instinct might be to think
    about your apartment. You might know that your studio is about 500 square feet,
    and you know about how much furniture can fit in there. You have probably
    learned that there is a difference between having enough space to get by and
    having enough space to support the lifestyle you want. Once your significant
    other moves in, the amount of space you need changes because the people and
    activities in the space have also changed.
    </p>
    <p>
    Your office can be thought of the same way. You might be able to fit 50 people
    in 5,000 square feet, but it doesn’t mean it will meet your needs or feel like a
    place you want to work. New research now shows that the workplace is more than
    just a place to keep desks. The environment can have a big impact on your
    employee’s satisfaction, which impacts productivity and health. We know that
    successful companies have great culture, which we think about as a combination
    of two things: 1. The policies that dictate behavior in the office, and 2. The
    environment the people work in. With that in mind, companies should be paying
    attention to the kind of office environments they want to create for their
    employees.
    </p>
    <p>
    Which leads us back to square footage. You see, every decision you make about
    your office environment has a direct impact on how much space you need to
    support that decision. For example, if you want to create an environment that
    promotes health and wellness, you might decide to create an onsite gym facility
    or provide healthy snacks in the kitchen. By adding a gym and room for snacks,
    you’ve just increased your square footage. if you want to create an environment
    that is efficient, you might decide to implement a seat sharing model and reduce
    square footage while increasing utilization. Every decision will impact the size
    in a big or small way.
    </p>
    <p>
    Now you might be thinking, I know the kind of office environment I want, but I
    don’t know how those decisions will affect the amount of space I need. It is
    here that we arrive at the goal of Spacer. In the past, in order to get help
    with your office, you would have had to hire a Workplace Strategist - an expert
    in the area of offices - to do this analysis for you. The process involves
    visioning sessions, utilization studies, interviews, surveys, and focus groups
    in order to understand the needs of your company and to make a customized
    recommendation about the space. It can take months and is usually only
    affordable for large organizations.
    </p>
    <p>
    Don’t get us wrong, Workplace Strategy is an incredibly useful service,
    especially for complex companies. It has just been inaccessible to many
    companies because of time and cost. That is, until Spacer came along.
    </p>
    <p>
    We took the process perfected by CBRE’s Workplace Strategy team and packaged it
    into an online tool that is free for anyone. Instead of being interviewed over
    many months, you simply answer a series of targeted questions online. We then
    analyze your responses and assign you a profile. With the profile, you have the
    ability to see an exact square footage recommendation, along with a list of
    every space type you should include in your office. This space program includes
    sizes and quantities of every desk, office, and conference room.
    </p>
    <p>
    With this program, you can hand it over to your broker and start searching for
    the perfect office space that will fit your requirements. Architects use
    programs to build out offices as well, so you can give this to your design team
    for a quicker turnaround once you move in.
    </p>
    <p>
    In the end, you spent 15 minutes defining an office environment that meets your
    goals and shortened your office search process by weeks. Impressive. Have you
    considered asking for a raise?
    </p>
    <p>
    Learn more about how CBRE is helping companies define office space with Spacer
    by emailing us at <a href="mailto:spacersupport@cbre.com">spacersupport@cbre.com</a>.
    </p>
  `
};
