module.exports = {

  // Navbar
  spacerLogoAlt: 'Spacer by CBRE logo',
  linkToAboutPageText: 'About',
  linkToBlogPageText: 'Insights',


  // TakeOfficeSpaceQuizButton
  sizeMyOfficeButtonDefaultLinkText: 'Take office space quiz',
  flooredPlanRequestLinkText: 'Request a Plan',

  // Footer
  footerCBRELogoAlt: 'CBRE logo for Spacer by CBRE',
  footerFacebookIconAlt: 'Facebook’s social icon for Spacer by CBRE',
  footerTwitterIconAlt: 'Twitter’s social icon for Spacer by CBRE',
  footerLinkedinIconAlt: 'Linkedin’s social icon for Spacer by CBRE',
  footerHeaderQuestionsText: 'Questions?',
  footerHeaderAboutText: 'Resources',
  footerHeaderMoreText: 'More',
  footerLinkWhatIsSpacerText: 'About',
  footerLinkBlogText: 'Insights',
  footerLinkWorkplaceText: 'Who is CBRE Workplace?',
  footerLinkTermsText: 'Terms of Service',
  footerLinkPrivacyText: 'Privacy Policy',
  footerLinkedinLink: 'https://www.linkedin.com/showcase/cbre-workplace-strategy/',
  footerWorkplaceLink: 'http://www.cbre.us/real-estate-services/occupier/workplace',
  footerPrivacyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Contact (contact.js)
  spacerSupportEmail: 'spacersupport@cbre.com',

  // ErrorPage
  errorOopsText: 'Oops!',
  error404Text: 'We can’t find the page you’re looking for.',
  error500Text: 'Something went wrong on our end.',
  errorText: 'Error',
  errorReturnText: 'Return to Spacer',
  errorAltText: 'Illustration of a spilled coffee cup for Spacer by CBRE',

  // Measurement Units (units.js)
  SF: 'SF',
  SM: 'SM',
  USF: 'USF',
  USM: 'USM',
  RSF: 'RSF',
  RSM: 'RSM',
  NSF: 'NSF',
  NSM: 'NSM',
  NIA: 'NIA',
  foot: 'foot',
  feet: 'feet',
  footage: 'footage',
  'sqft.': 'sqft.',
  meter: 'meter',
  meters: 'meters',
  'sqm.': 'sqm.',
  Tsubo: 'tsubo',

  // SF Types Adjectives (calculator.js)
  Usable: 'Usable',
  Rentable: 'Rentable',
  Net: 'Net'

  // documentDataCopy needs translation: page titles and metatag texts
};
