const React              = require('react');
const _                  = require('lodash');
const PropTypes          = require('prop-types');
const StatWithSubtitle   = require('./StatWithSubtitle.jsx');
const numericDisplay     = require('../../../util/numericDisplay');
const unitsUtil          = require('../../../../shared/util/units');
const { dashOrValue }    = require('../../../../shared/util/display');
const constants          = require('wp-constants').spacerFree;
const regionSFCalculator = require('../../../../shared/calculators/regionSFCalculator.js');
const { withTranslation } = require('react-i18next');

const SF_TYPE = constants.calculator.SF_TYPE;
const SF_TYPE_ADJECTIVES = constants.calculator.SF_TYPE_ADJECTIVES;

function ProgramStats(props) {
  const [workstationPercentage, officePercentage] = numericDisplay.decimalTo100PercentageRatioArrayFormat(props.programStats.openPercentage);
  let collaborationRatioDecimal = dashOrValue(!props.programStats.collabSeatRatio, numericDisplay.numberWithLocaleFormat((Math.round(props.programStats.collabSeatRatio * 100, 10) / 100)));
  let seatsToPeopleRatioDecimal = dashOrValue(!props.programStats.sharingRatio, numericDisplay.numberWithLocaleFormat((Math.round(props.programStats.sharingRatio * 100, 10) / 100)));
  const unit = props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('feet', props.measurementSystem)}`);
  const unitUppercase = _.capitalize(unit);

  // Cut leading 0
  // consulted with Lena, don't cut off leading 0 in German
  if (collaborationRatioDecimal.indexOf('0.') === 0) {
    collaborationRatioDecimal = collaborationRatioDecimal.substr(1);
  }
  if (seatsToPeopleRatioDecimal.indexOf('0.') === 0) {
    seatsToPeopleRatioDecimal = seatsToPeopleRatioDecimal.substr(1);
  }

  const usfPerSeat = props.programStats.usfPerSeat;
  const density = (props.sfType === SF_TYPE.USF || props.sfType === SF_TYPE.NIA) ? usfPerSeat : regionSFCalculator.getRSFForLocation(props.location, usfPerSeat, props.lossFactor);
  const densityForMeasurementSystem = dashOrValue((!density || !_.isFinite(density)), numericDisplay.numberWithLocaleFormat(unitsUtil.getNumberForMeasurementSystem(density, props.measurementSystem)));
  let densitySubtitle;
  if (unitsUtil.isTsubo(props.measurementSystem)) {
    densitySubtitle = props.t('programStatsDensitySubtitleTsubo');
  } else {
    densitySubtitle = props.t('programStatsDensitySubtitle', { adjective: props.t(`common:${SF_TYPE_ADJECTIVES[props.sfType]}`), unitUppercase });
  }

  const statData = [
    {
      statTitle: props.t('programStatsIndivTitle'),
      stat: [workstationPercentage, officePercentage],
      statSubtitle: [props.t('programStatsWorkstationsSubtitle'), props.t('programStatsOfficesSubtitle')],
      isRatio: true
    },
    {
      statTitle: props.t('programStatsCollabTitle'),
      stat: [collaborationRatioDecimal, '1'],
      statSubtitle: [props.t('programStatsEnclosedSubtitle'), props.t('programStatsIndivSeatSubtitle')],
      isRatio: true
    },
    {
      statTitle: props.t('programStatsSeatsTitle'),
      stat: [seatsToPeopleRatioDecimal, '1'],
      statSubtitle: [props.t('programStatsIndivSeatsSubtitle'), props.t('programStatsPersonSubtitle')],
      isRatio: true
    },
    {
      statTitle: props.t('programStatsDensityTitle'),
      stat: densityForMeasurementSystem.toString(),
      statSubtitle: props.t('programStatsFullDensitySubtitle', { density: densitySubtitle }),
      isRatio: false
    }
  ];

  const statItems = statData.map(item => <StatWithSubtitle key={item.statTitle} {...item} />);

  return <div className="columns program-stats">{statItems}</div>;
}

ProgramStats.propTypes = {
  programStats: PropTypes.shape({
    openPercentage: PropTypes.number,
    collabSeatRatio: PropTypes.number,
    sharingRatio: PropTypes.number,
    usfPerSeat: PropTypes.number
  }).isRequired,
  measurementSystem: PropTypes.string.isRequired,
  sfType: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  lossFactor: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(ProgramStats);
