const React               = require('react');
const PropTypes           = require('prop-types');
const _                   = require('lodash');
const spacerFreePropTypes = require('../../../spacerFreePropTypes');
const constants           = require('wp-constants').spacerFree;
const numericDisplay      = require('../../../../util/numericDisplay');
const unitsUtil           = require('../../../../../shared/util/units');
const { dashOrValue }     = require('../../../../../shared/util/display');
const DeltaLabel          = require('../DeltaLabel.jsx');
const regionSFCalculator  = require('../../../../../shared/calculators/regionSFCalculator');
const Tooltip             = require('../../../common/Tooltip.jsx');
const NumberInput         = require('../../../form/NumberInput.jsx');
const { withTranslation }  = require('react-i18next');


function SpaceUnitTotals(props) {
  const circulationTotal = unitsUtil.getNumberForMeasurementSystem(props.circulationTotal, props.measurementSystem, { precisionForMeter: 1, precisionForTsubo: 1 });
  const circulationTotalWithCommas = dashOrValue(!circulationTotal, numericDisplay.numberWithLocaleFormat(circulationTotal));

  const totalAreaForContext = unitsUtil.getNumberForMeasurementSystem(props.totalAreaForContext, props.measurementSystem, { precisionForMeter: 1, precisionForTsubo: 1 });
  const totalAreaWithCommas = dashOrValue(!totalAreaForContext, numericDisplay.numberWithLocaleFormat(totalAreaForContext));

  const unit = props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('feet', props.measurementSystem)}`);
  const unitCapitalized = _.capitalize(unit);
  const sfType = props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('USF', props.measurementSystem)}`);
  const sfTypeAdj = props.sfType === constants.calculator.SF_TYPE.USF ? props.t('common:Usable') : props.t('common:Rentable');

  let totalTitleText;
  if (props.sfType === constants.calculator.SF_TYPE.NIA) {
    totalTitleText = props.t('spaceUnitTotalsTitleNIA');
  } else {
    totalTitleText = props.t('spaceUnitTotalsTitle', { sfTypeAdj, unitCapitalized });
  }

  const unitCopyBasis = constants.calculator.SF_TYPE_TEXT[props.sfType];
  const measurementLabelForTotal = props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem(unitCopyBasis, props.measurementSystem)}`);

  const isNYC = regionSFCalculator.getRegion(props.location) === constants.calculator.RSF_REGIONS.NYC;

  return (
    <div className="columns is-mobile is-multiline has-margin-0-auto space-unit-breakdown-totals is-sans-medium">

      <div className="column is-3-desktop is-3-tablet is-half-mobile has-text-light space-category-breakdown-row-name">
        <span className="name">{props.t('spaceUnitTotalsCirculation')}</span>
        <Tooltip text={props.t('spaceUnitTotalsCirculationTooltip', { unitCapitalized })} />
      </div>

      <div className="column is-3-desktop is-3-tablet is-offset-6-desktop is-half-mobile has-text-light total-metric-number">
        {circulationTotalWithCommas} {sfType}
      </div>

      {/* Include REBNY factor for NYC */}
      {isNYC &&
        <React.Fragment>
          <div className="column is-3-desktop is-3-tablet is-half-mobile has-text-light space-category-breakdown-row-name">
            <span className="name">{props.t('spaceUnitTotalsREBNY')}</span>
            <Tooltip text={props.t('spaceUnitTotalsREBNYTooltip', { sfType })} />
          </div>
          <div className="column is-3-desktop is-3-tablet is-offset-6-desktop is-half-mobile has-text-light total-metric-number">
            {Math.round((regionSFCalculator.nycUSFFactor - 1) * 100)}%
          </div>
        </React.Fragment>
      }

      {props.sfType === constants.calculator.SF_TYPE.RSF &&
        <React.Fragment>
          <div className="column is-3-desktop is-3-tablet is-half-mobile has-text-light space-category-breakdown-row-name">
            <span className="name">{isNYC && props.t('spaceUnitTotalsNYCText')}{props.t('spaceUnitTotalsLossFactor')}{/* NYC Loss Factor Calculation works a little different, thus gets extra title */}</span>
            <Tooltip text={props.t('spaceUnitTotalsLossFactorTooltip', { unit })} />
          </div>
          <div className="column is-3-desktop is-3-tablet is-offset-6-desktop is-half-mobile has-text-light flex flex-row loss-factor-container total-metric-number">
            <NumberInput
              min={0}
              max={constants.calculator.MAX_LOSS_FACTOR}
              value={Math.round(props.lossFactor * 100)}
              onChange={value => props.updateLossFactor(value / 100)}
              hasShortUnderline
              hasSpaceTypeColor
              isLossFactor
            />
          </div>
        </React.Fragment>
      }

      <div className="column is-9-desktop is-3-tablet is-half-mobile">
        {totalTitleText}
      </div>
      <div className="column is-3-desktop is-3-tablet is-half-mobile total-metric-number">
        <span className="grand-total">{totalAreaWithCommas} {measurementLabelForTotal}</span>
        <DeltaLabel delta={props.totalAreaDelta} measurementSystem={props.measurementSystem} />
      </div>
    </div>
  );
}

SpaceUnitTotals.propTypes = {
  totalAreaForContext: PropTypes.number.isRequired,
  totalAreaDelta: spacerFreePropTypes.deltaShape,
  circulationTotal: PropTypes.number.isRequired,

  location: PropTypes.string.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  sfType: PropTypes.number.isRequired,
  lossFactor: PropTypes.number.isRequired,
  updateLossFactor: PropTypes.func.isRequired,

  t: PropTypes.func.isRequired
};

SpaceUnitTotals.defaultProps = {
  totalAreaDelta: null
};

module.exports = withTranslation('resultsPage')(SpaceUnitTotals);

