const React                        = require('react');
const PropTypes                    = require('prop-types');
const SPACE_UNIT_CATEGORY          = require('wp-constants').shared.spaceUnit.CATEGORY;
const SpaceCategoryBreakdownMobile = require('./SpaceCategoryBreakdownMobile.jsx');
const spacerFreePropTypes          = require('../../../spacerFreePropTypes');
const sharedPropTypes              = require('../../../../../shared/components/sharedPropTypes');

function SpaceCategoryBreakdownMobileContainer(props) {
  return (
    <div>
      <SpaceCategoryBreakdownMobile
        spaceUnitCategory={SPACE_UNIT_CATEGORY.ME}
        categoryAreaForContext={props.meCategoryAreaForContext}
        measurementSystem={props.measurementSystem}
        programForCategory={props.meProgramForCategory}
        sfType={props.sfType}
        categoryArea={props.areaTotals.totalMeSF}
        totalAreaDelta={props.meCategoryAreaDelta}
        totalAreaForContext={props.totalAreaForContext}
      />
      <SpaceCategoryBreakdownMobile
        spaceUnitCategory={SPACE_UNIT_CATEGORY.WE}
        categoryAreaForContext={props.weCategoryAreaForContext}
        measurementSystem={props.measurementSystem}
        programForCategory={props.weProgramForCategory}
        sfType={props.sfType}
        categoryArea={props.areaTotals.totalWeSF}
        totalAreaDelta={props.weCategoryAreaDelta}
        totalAreaForContext={props.totalAreaForContext}
      />
      <SpaceCategoryBreakdownMobile
        spaceUnitCategory={SPACE_UNIT_CATEGORY.AMENITY}
        categoryAreaForContext={props.amenityCategoryAreaForContext}
        measurementSystem={props.measurementSystem}
        programForCategory={props.amenityProgramForCategory}
        sfType={props.sfType}
        categoryArea={props.areaTotals.totalAmenitySF}
        totalAreaDelta={props.amenityCategoryAreaDelta}
        totalAreaForContext={props.totalAreaForContext}
      />
    </div>
  );
}

SpaceCategoryBreakdownMobileContainer.propTypes = {
  measurementSystem: PropTypes.string.isRequired,
  meCategoryAreaForContext: PropTypes.number.isRequired,
  weCategoryAreaForContext: PropTypes.number.isRequired,
  amenityCategoryAreaForContext: PropTypes.number.isRequired,
  meProgramForCategory: PropTypes.arrayOf(PropTypes.object).isRequired,
  weProgramForCategory: PropTypes.arrayOf(PropTypes.object).isRequired,
  amenityProgramForCategory: PropTypes.arrayOf(PropTypes.object).isRequired,
  meCategoryAreaDelta: spacerFreePropTypes.deltaShape,
  weCategoryAreaDelta: spacerFreePropTypes.deltaShape,
  amenityCategoryAreaDelta: spacerFreePropTypes.deltaShape,
  sfType: PropTypes.number.isRequired,
  areaTotals: sharedPropTypes.areaTotalsShape.isRequired,
  totalAreaForContext: PropTypes.number.isRequired
};

SpaceCategoryBreakdownMobileContainer.defaultProps = {
  meCategoryAreaDelta: null,
  weCategoryAreaDelta: null,
  amenityCategoryAreaDelta: null
};

module.exports = SpaceCategoryBreakdownMobileContainer;

