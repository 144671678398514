const { connect }              = require('react-redux');
const { bindActionCreators }   = require('redux');
// const globalActions            = require('../../../redux/actions/global');
import globalActions from '../../../redux/actions/global.js';
const BrokerReferralSignUpForm = require('../../referrals/BrokerReferralSignUpForm.jsx');

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    sendHeapEventWithoutProps: bindActionCreators(globalActions.sendHeapEventWithoutProps, dispatch)
  };
}

module.exports = connect(null, mapDispatchToProps)(BrokerReferralSignUpForm);
