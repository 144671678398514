const React               = require('react');
const PropTypes           = require('prop-types');
const ContentSection      = require('../ContentSection.jsx');
const AcceptTermsText     = require('../../common/AcceptTermsText.jsx');
const { fetchSourceData } = require('../../../util/dbServices');
const { withTranslation }  = require('react-i18next');
const GALabels = require('../../../optionsConfig/googleAnalytics.labels.json');
const ga = require('../../../../shared/tracking');
class EmailWallSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      termsChecked: false,
      firstName: '',
      lastName: ''
    };

    if (this.props.isUserInEEA && !this.props.isCBREEmployee) {
      this.state.contactOptIn = false;
    }

    if (this.props.isUserInCalifornia && !this.props.isCBREEmployee) {
      this.state.contactOptIn = true;
    }

    if (!this.props.isCBREEmployee) {
      this.state.cbreContact = this.props.cbreContact || '';
    }

    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.cbreEmailRef = React.createRef();
  }

  componentDidMount() {
    // if a sourceId was on the store, populate role: 'client' on the store, and broker email locally
    // (it's happening here instead of earlier in the quiz to not accidentally clear out the fetched
    // broker data when navigating in and out of the quiz)
    if (this.props.sourceId) {
      this.props.addBulkUserData({ role: 'cbreClient' }); // if there's a sourceId present, we know it's a client
      fetchSourceData(
        this.props.sourceId,
        source => this.setState({ cbreContact: source.email }),
        () => this.setState({ troubleFetchingSource: true })
      );
    }
  }

  onValueChange(key, value) {
    this.setState({ [key]: value });

    // Custom Form Input Validation
    const checkValidation = (ref, validationMessageToken) => {
      if (ref === null) return;
      ref.setCustomValidity('');
      if (!ref.checkValidity()) ref.setCustomValidity(this.props.t(validationMessageToken));
    };

    checkValidation(this.firstNameRef.current, 'emailWallFirstNameInvalid');
    checkValidation(this.lastNameRef.current, 'emailWallLastNameInvalid');
    checkValidation(this.emailRef.current, 'emailWallEmailInvalid');
    checkValidation(this.cbreEmailRef.current, 'emailWallEmailInvalid');
  }

  onSubmit(evt) {
    evt.preventDefault();
    this.props.addBulkUserData({ email: this.state.email, firstName: this.state.firstName, lastName: this.state.lastName });
    this.props.saveProgram(this.state);
    ga.GAEvent(GALabels.categories.result, GALabels.actions.completeResultBtn, GALabels.labels.completeResultBtn);
    if (this.props.sourceId && !this.state.troubleFetchingSource) this.props.sendHeapEventWithoutProps('View Results Page (Program Id Page) from Referral Link');
  }

  render() {
    const isEEANonEmployeeUser = this.props.isUserInEEA && !this.props.isCBREEmployee;
    const isCaliforniaNonEmployeeUser = this.props.isUserInCalifornia && !this.props.isCBREEmployee;
    return (
      <ContentSection hideOnPrint isEmailWallSection>
        <div className="columns level">
          <form onSubmit={this.onSubmit} className="column">
            <div className="email-wall-section-title">
              {this.props.t('emailWallTitle')}
            </div>
            <div className="email-wall-text email-wall-section-subtitle">
              {this.props.t('emailWallSubtitle')}
            </div>
            <div className="email-wall-container">
              <div className="email-wall-name-input-container">
                <label className="email-wall-name">
                  <div className="email-wall-text email-wall-label">{this.props.t('emailWallFirstNamePlaceholder')}</div>
                  <input
                    required
                    ref={this.firstNameRef}
                    className="email-wall-text email-wall-section-input"
                    type="text"
                    onChange={evt => this.onValueChange('firstName', evt.target.value)}
                    value={this.state.firstName}
                  />
                </label>
                <label className="email-wall-name">
                  <div className="email-wall-text email-wall-label">{this.props.t('emailWallLastNamePlaceholder')}</div>
                  <input
                    required
                    ref={this.lastNameRef}
                    className="email-wall-text email-wall-section-input"
                    type="text"
                    onChange={evt => this.onValueChange('lastName', evt.target.value)}
                    value={this.state.lastName}
                  />
                </label>
              </div>
              <label>
                <div className="email-wall-text email-wall-label">{this.props.t('emailWallEmailPlaceholder')}</div>
                <div className="flex flex-row">
                  <input
                    required
                    ref={this.emailRef}
                    className="email-wall-text email-wall-section-input"
                    type="email"
                    onChange={evt => this.onValueChange('email', evt.target.value)}
                    value={this.state.email}
                  />
                  <div className="email-wall-envelope-icon" />
                </div>
              </label>
              {
                this.props.isCBREClient &&
                <label>
                  <div className="email-wall-text email-wall-label">{this.props.sourceId && !this.state.troubleFetchingSource ? this.props.t('emailWallLeasingProfessionalPlaceholder') : this.props.t('emailWallCBREEmailPlaceholder')}</div>
                  <div className="flex flex-row">
                    <input
                      ref={this.cbreEmailRef}
                      className="email-wall-text email-wall-section-input"
                      type="email"
                      onChange={evt => this.onValueChange('cbreContact', evt.target.value)}
                      value={this.state.cbreContact}
                      readOnly={this.props.sourceId && !this.state.troubleFetchingSource}
                      autoComplete="nope"
                    />
                    <div className="email-wall-envelope-icon" />
                  </div>
                </label>
              }
              <div className="email-wall-checkbox-container">
                <input
                  id="acceptTermsCheckbox"
                  className="email-wall-checkbox"
                  type="checkbox"
                  value={this.state.termsChecked}
                  onClick={evt => this.onValueChange('termsChecked', evt.target.checked)}
                />
                <label className="email-wall-checkbox-label email-wall-text" htmlFor="acceptTermsCheckbox">
                  <AcceptTermsText />
                </label>
              </div>
              {
                (isEEANonEmployeeUser || isCaliforniaNonEmployeeUser) ?
                  (<div className="email-wall-checkbox-container">
                    <input
                      id="allowContactCheckbox"
                      className="email-wall-checkbox"
                      type="checkbox"
                      value={this.state.contactOptIn}
                      checked={this.state.contactOptIn}
                      onClick={evt => this.onValueChange('contactOptIn', evt.target.checked)}
                    />
                    <label className="email-wall-text email-wall-checkbox-label" htmlFor="allowContactCheckbox">
                      {this.props.t('emailWallConnectMeText')}
                    </label>
                  </div>)
                  : (
                    <div className="email-wall-text email-wall-contact-notice">
                      {this.props.t('emailWallContactNoticeText')}
                    </div>
                  )
              }
              <button
                type="submit"
                className="button email-wall-submit-button"
                disabled={!(this.state.termsChecked && this.state.email)}
              >
                {this.props.t('emailWallResultsButtonText')}
              </button>
            </div>
          </form>
          <div className="column is-hidden-mobile">
            <img
              src="/png/results-page-email-wall-spacer-by-cbre.png"
              alt={this.props.t('emailWallImgAltText')}
            />
          </div>
        </div>
      </ContentSection>
    );
  }
}

EmailWallSection.propTypes = {
  isUserInEEA: PropTypes.bool.isRequired,
  isUserInCalifornia: PropTypes.bool.isRequired,
  isCBREEmployee: PropTypes.bool.isRequired,
  isCBREClient: PropTypes.bool.isRequired,
  saveProgram: PropTypes.func.isRequired,
  cbreContact: PropTypes.string,
  sourceId: PropTypes.string,
  t: PropTypes.func.isRequired,
  addBulkUserData: PropTypes.func.isRequired,
  sendHeapEventWithoutProps: PropTypes.func.isRequired
};

EmailWallSection.defaultProps = {
  cbreContact: '',
  sourceId: null
};

module.exports = withTranslation('resultsPage')(EmailWallSection);
