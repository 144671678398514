const React              = require('react');
const PropTypes          = require('prop-types');
const HeroSection        = require('../../common/HeroSection.jsx');
const { withTranslation } = require('react-i18next');

function ProfileHero(props) {
  return (
    <HeroSection addTopNavbarSpace sizeClass="is-small">
      <div className="columns is-gapless level profile-hero">
        <h1 className="column is-half level-item">
          <div className="subtitle is-half-spaced is-5 has-text-weight-medium profile-hero-subtitle is-calibre">{props.t('resultsProfileHeroAnnouncementTitle')}</div>
          <div className="title has-text-primary profile-hero-name">{props.profileName}</div>
        </h1>
        <div className="column is-half is-hidden-print">
          <figure className="image is-4by3">
            <img
              src={props.profileHeroImagePath}
              alt={props.t('resultsProfileHeroImgAlt', { profileName: props.profileName })}
            />
          </figure>
        </div>
      </div>
    </HeroSection>
  );
}

ProfileHero.propTypes = {
  profileName: PropTypes.string.isRequired,
  profileHeroImagePath: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(ProfileHero);
