const React               = require('react');
const PropTypes           = require('prop-types');
const spacerFreePropTypes = require('../spacerFreePropTypes');
const { Navigate }        = require('react-router-dom');
const _                   = require('lodash');

const constants           = require('wp-constants').spacerFree;
const questionUnitUtil    = require('wp-util').questionUnit;
const navigationUtil      = require('../../util/navigation');

const BasicNavbar         = require('../common/BasicNavbar.jsx');
const QuestionSlide       = require('./QuestionSlide.jsx');
const QuizExitSlide       = require('./QuizExitSlide.jsx');
const domUtil             = require('../../../shared/util/dom.js');
const DocumentData        = require('../common/DocumentData.jsx');
const { withTranslation }  = require('react-i18next');
const GALabels = require('../../optionsConfig/googleAnalytics.labels.json');
const ga = require('../../../shared/tracking');

const QUESTION_TYPE_TO_ANSWER_PROPS_MAP = {
  [constants.questionUnit.QUESTION_TYPE.PROFILE]: {
    executeAnswerFn: 'answerProfileQuestion',
    answerObject: 'userQuizAnswers',
    answerKeyProp: 'id'
  },
  [constants.questionUnit.QUESTION_TYPE.USER_DATA]: {
    executeAnswerFn: 'addUserData',
    answerObject: 'userData',
    answerKeyProp: 'userDataProperty'
  },
  [constants.questionUnit.QUESTION_TYPE.ASSUMPTION]: {
    executeAnswerFn: 'addKeyAssumption',
    answerObject: 'assumptions',
    answerKeyProp: 'assumptionType'
  }
};

class Quiz extends React.Component {
  constructor(props) {
    super(props);

    const skippableQuestions = Object.keys(props.userQuizAnswers);
    skippableQuestions.push(constants.quiz.currentLocationQuestionId);
    // skip the usersTypeQuestion if we came from a referral link (we'll auto populate it later)
    if (props.userData.sourceId) skippableQuestions.push(constants.quiz.userTypeQuestionId);

    this.state = {
      questionIndex: 0,
      isSameOfficeAndPersonalLocation: true,
      skippableQuestionIds: skippableQuestions
    };

    this.endQuiz = this.endQuiz.bind(this);
    this.getExitOrQuestionSlide = this.getExitOrQuestionSlide.bind(this);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.getQuestionAnswer = this.getQuestionAnswer.bind(this);
    this.updateCurrentQuestion = this.updateCurrentQuestion.bind(this);
    this.toggleSameOfficeAndPersonalLocation = this.toggleSameOfficeAndPersonalLocation.bind(this);
    this.addOrRemoveSkippableQuestion = this.addOrRemoveSkippableQuestion.bind(this);
  }

  getQuestionAnswer(questionIndex, excludeInitial) {
    const questionUnit = questionUnitUtil.getQuestionUnit(questionIndex);
    const answerProps = QUESTION_TYPE_TO_ANSWER_PROPS_MAP[questionUnit.type];
    const answerKey = questionUnit[answerProps.answerKeyProp];
    const questionAnswer = this.props[answerProps.answerObject][answerKey] || (!excludeInitial && questionUnitUtil.getInitialAnswer(questionUnit));
    return questionAnswer;
  }

  updateCurrentQuestion(questionIndex) {
    this.setState({ questionIndex });
    domUtil.scrollY(0);
  }

  updateAnswer(questionAnswer, passedQuestionIndex) {
    passedQuestionIndex = passedQuestionIndex || this.state.questionIndex;
    const questionUnit = questionUnitUtil.getQuestionUnit(passedQuestionIndex);
    const answerProps = QUESTION_TYPE_TO_ANSWER_PROPS_MAP[questionUnit.type];
    const answerKeyProp = questionUnit[answerProps.answerKeyProp];
    this.props[answerProps.executeAnswerFn](answerKeyProp, questionAnswer, this.state.isSameOfficeAndPersonalLocation);
  }

  endQuiz() {
    this.setState({ showExitSlide: true });
    domUtil.scrollY(0);

    this.props.calculateProfileScores();
    this.props.setProfileAndCalculateProgram();
    ga.GAEvent(GALabels.categories.quiz, GALabels.actions.finishQuiz, GALabels.labels.finishQuiz);
    this.props.sendHeapEvent('Finish Quiz');

    setTimeout(() => this.setState({ redirectToResults: true }), 3000);
  }

  toggleSameOfficeAndPersonalLocation(isChecked) {
    this.setState(({
      isSameOfficeAndPersonalLocation: !isChecked
    }), () => {
      // if (this.state.isSameOfficeAndPersonalLocation) {
        this.updateAnswer(this.props.userData.officeLocation, constants.quiz.currentLocationQuestionIndex);
      // } else {
      //   this.updateAnswer('', constants.quiz.currentLocationQuestionIndex);
      // }
    });
    this.addOrRemoveSkippableQuestion(constants.quiz.currentLocationQuestionId, !isChecked);
  }

  addOrRemoveSkippableQuestion(questionId, addId) {
    const newSkippableQuestionIds = addId ?
      _.union(this.state.skippableQuestionIds, [questionId]) : // add questionId
      _.without(this.state.skippableQuestionIds, questionId); // remove questionId
    this.setState({ skippableQuestionIds: newSkippableQuestionIds });
  }

  getExitOrQuestionSlide() {
    if (this.state.showExitSlide) {
      return <QuizExitSlide />;
    }

    return (<QuestionSlide
      questionIndex={this.state.questionIndex}
      updateCurrentQuestion={this.updateCurrentQuestion}
      afterFinalQuestionSlide={this.endQuiz}
      updateAnswer={this.updateAnswer}
      getQuestionAnswer={this.getQuestionAnswer}
      isSameOfficeAndPersonalLocation={this.state.isSameOfficeAndPersonalLocation}
      toggleSameOfficeAndPersonalLocation={this.toggleSameOfficeAndPersonalLocation}
      skippableQuestionIds={this.state.skippableQuestionIds}
    />);
  }

  render() {
    if (this.state.redirectToResults) return <Navigate to="/program-your-results-spacer-by-cbre" />;

    return (
      <div className="quiz is-fullheight">
        <DocumentData
          siteLocation={constants.siteLocation.PAGE.QUIZ}
        />
        <BasicNavbar isInQuiz isDark>
          <div className="navbar-item is-mobile" key="closeTag">
            <button role="link" className="delete is-large" aria-label={this.props.t('exitQuizButtonTitle')} title={this.props.t('exitQuizButtonTitle')} onClick={navigationUtil.backOrLandingPage} />
          </div>
        </BasicNavbar>
        <main className="container">
          <div className="quiz-content columns is-centered has-emphasis-background">
            <div className="column align-center">
              {this.getExitOrQuestionSlide()}
            </div>
          </div>
        </main>
      </div>
    );
  }
}

Quiz.propTypes = {
  // previous answer props
  userQuizAnswers: spacerFreePropTypes.userQuizAnswersObject.isRequired,
  userData: spacerFreePropTypes.userDataForQuizShape.isRequired,
  assumptions: spacerFreePropTypes.assumptionsForQuizShape.isRequired,

  // answer question functions
  answerProfileQuestion: PropTypes.func.isRequired,
  addUserData: PropTypes.func.isRequired,
  addKeyAssumption: PropTypes.func.isRequired,

  // calculate results functions
  calculateProfileScores: PropTypes.func.isRequired,
  setProfileAndCalculateProgram: PropTypes.func.isRequired,

  sendHeapEvent: PropTypes.func.isRequired,

  t: PropTypes.func.isRequired
};

module.exports = withTranslation('quizPage')(Quiz);

