const React              = require('react');
const PropTypes          = require('prop-types');
const domUtil            = require('../../../shared/util/dom');
const { withTranslation } = require('react-i18next');

function QuizNavigationButton(props) {
  let classes = 'button is-sans-medium is-size-7 quiz-navigation-btn';
  classes += props.isBack ? ' is-tertiary-outlined' : ' is-tertiary-filled';
  if (props.hidden) classes += ' is-hidden';
  if (props.isPulledLeft) classes += ' is-pulled-left';

  let buttonText;
  if (props.isBack) {
    buttonText = domUtil.isScreenMobileSize() ? props.t('quizNavBackButtonShortenedText') : props.t('quizNavBackButtonFullLengthText');
  } else {
    buttonText = domUtil.isScreenMobileSize() ? props.t('quizNavNextButtonShortenedText') : props.t('quizNavNextButtonFullLengthText');
  }

  return (
    <button
      className={classes}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {buttonText}
    </button>
  );
}

QuizNavigationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isBack: PropTypes.bool,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  isPulledLeft: PropTypes.bool,
  t: PropTypes.func.isRequired
};

QuizNavigationButton.defaultProps = {
  isBack: false,
  hidden: false,
  disabled: false,
  isPulledLeft: false
};

module.exports = withTranslation('quizPage')(QuizNavigationButton);

