const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const Program = require('../../../results/program/Program.jsx');
// const globalActions = require('../../../../redux/actions/global');
import globalActions from '../../../../redux/actions/global.js';
const changeDetectedActions = require('../../../../redux/actions/changeDetected');

// Map state to props
function mapStateToProps(state) {
  return {
    userData: state.userData,
    program: state.spaceData.program,
    // this is used to disable the save button when there's no headcount and all areas are NaN
    incompleteAssumptions: !state.spaceData.assumptions.headcount,
    measurementSystem: state.spaceData.measurementSystem,
    lossFactor: state.spaceData.lossFactor,
    changesMadeToResultsDetails: state.changeDetected.changesMadeToResultsDetails,
    wasProgramSaved: state.requests.wasProgramSaved,
    programId: state.spaceData._id,
    isSubmittingForm: state.requests.isSubmittingForm,
    testFitFormCompleted: state.userData.testFitFormCompleted
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProgram: bindActionCreators(globalActions.updateProgram, dispatch),
    fetchProgramIfNeeded: bindActionCreators(globalActions.fetchProgramIfNeeded, dispatch),
    onTinker: bindActionCreators(globalActions.removeProgramSavedStatus, dispatch),
    detectChangeToSaveOnResults: bindActionCreators(changeDetectedActions.detectChangeToSaveOnResults, dispatch),
    changeToResultsSaved: bindActionCreators(changeDetectedActions.changeToResultsSaved, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(Program);
