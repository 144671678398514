module.exports = {

  // Results
  resultsWarningMessage: 'Czy na pewno chcesz opuścić tę stronę? W ten sposób możesz utracić niezapisane dane.',

  // ResultsHeader
  resultsHeaderExportPDFButtonText: 'Eksportuj PDF',
  resultsHeaderSendButtonText: 'Prześlij podsumowanie odpowiedzi',
  resultsHeaderClipboardBoxDescription: 'Każdy użytkownik, dla którego został wygenerowany link, ma możliwość przeglądania i wprowadzania zmian w odpowiedziach',
  resultsHeaderContactButtonText: 'Nawiąż kontakt z doradcą',

  // EmailWallSection
  emailWallTitle: 'To jeszcze nie wszystko! Mamy dla Ciebie więcej informacji',
  emailWallSubtitle: 'Wprowadź poniżej swoje imię i nazwisko oraz adres e-mail, aby wyświetlić pełne zalecenia dotyczące Twojej przestrzeni biurowej i dostosować wyniki do Twoich potrzeb.',
  emailWallFirstNamePlaceholder: 'Imię',
  emailWallLastNamePlaceholder: 'Nazwisko',
  emailWallEmailPlaceholder: 'Twój adres e-mail',
  emailWallCBREEmailPlaceholder: 'Adres e-mail przypisany Twojemu doradcy w CBRE (opcjonalnie)',
  emailWallLeasingProfessionalPlaceholder: 'Twój doradca w CBRE ds. najmu',
  emailWallConnectMeText: 'Chcę nawiązać kontakt z osobistym doradcą ds. nieruchomości. Żadnych e-biuletynów ani spamu, obiecujemy!',
  emailWallContactNoticeText: 'Zespół Spacer może się z Tobą skontaktować, aby umożliwić Ci korzystanie z Twojego programu projektowego w jak najpełniejszym zakresie. Bez spamu, obiecujemy!',
  emailWallResultsButtonText: 'Podgląd podsumowania odpowiedzi',
  emailWallImgAltText: 'Podgląd wyników kwestionariusza dotyczącego projektu przestrzeni wygenerowanych w aplikacji CBRE Spacer i przesłanych za pośrednictwem poczty elektronicznej',
  emailWallFirstNameInvalid: 'Proszę wprowadzić swoje imię.',
  emailWallLastNameInvalid: 'Proszę wprowadzić swoje nazwisko.',
  emailWallEmailInvalid: 'Proszę wprowadzić aktualny adres e-mail.',

  // CopyToClipboardBox
  clipboardBoxCopyText: 'Kopiuj',
  clipboardBoxCopiedText: 'Skopiowane',

  // FormModal
  formModalFindSpaceHeader: 'Rozpocznij wyszukiwanie przestrzeni biurowej',
  formModalFindSpaceBody: 'Aplikacja Spacer to szybkie i łatwe w obsłudze narzędzie stworzone do analizy potrzeb w zakresie środowiska pracy, jeżeli jednak poszukujesz bardziej spersonalizowanej usługi, skorzystaj z pomocy zespołu CBRE Workplace. Wypełnij niniejszy formularz, a jeden z naszych ekspertów skontaktuje się z Tobą w przeciągu 48 godzin, aby umówić się na rozmowę.',
  formModalFindSpaceSuccessMessage: 'Twoja prośba została wysłana.',

  // SuccessModal
  successModalTitle: 'Udało się!',

  // ContactForm
  contactFormFirstNameLabel: 'Imię',
  contactFormLastNameLabel: 'Nazwisko',
  contactFormEmailLabel: 'Służbowy adres e-mail',
  contactFormPhoneLabel: 'Numer telefonu',
  contactFormMessageLabel: 'Opowiedz nam trochę więcej o przestrzeni, której szukasz',
  contactFormSubmitText: 'Zatwierdź',

  // AcceptTermsText
  acceptTermsText: 'Akceptuję <1>Regulamin</1>. Aby uzyskać więcej informacji na temat sposobu wykorzystywania Twoich danych, zapoznaj się z <3>Polityką prywatności CBRE</3>.',
  privacyPolicyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Form
  formErrorText: 'Ups, nie udało się. Proszę spróbować ponownie!',
  formEmailInvalid: 'Proszę wprowadzić aktualny adres e-mail.',

  // FormInput
  formInputOptionalText: ' (opcjonalnie)',

  // LegalForm
  legalFormCheckboxText: 'Wyrażam zgodę na kontakt ze strony CBRE w sprawie ofert i informacji związanych z korzystaniem przeze mnie z aplikacji Spacer.',
  legalFormContactWarningText: 'Zespół Spacer może się z Tobą skontaktować, aby umożliwić Ci korzystanie z Twojego programu projektowego w jak najpełniejszym zakresie. Bez spamu, obiecujemy!',

  // NextStepsSection
  nextStepsSectionSubtitle: 'Gotowy na kolejny krok?',
  nextStepsSectionTitle: 'Znajdź idealne biuro.',
  nextStepsSectionButtonText: 'Nawiąż kontakt z doradcą',
  nextStepsSectionImgAlt: 'Grafika przedstawiająca rozmawiających ze sobą ludzi znajdująca się na stronie Informacje o aplikacji CBRE Spacer',

  // Profile
  resultsProfileSummaryTitle: 'Twoje biuro',
  resultsProfileTooltipText: 'Każdy profil generowany jest w oparciu o dane pochodzące z prawdziwych i funkcjonujących na rynku organizacji i miejsc pracy. Zostały one zweryfikowane zgodnie z obowiązującymi dla naszej branży wytycznymi i unikalną wiedzą rynkową zespołu CBRE Workplace.',

  // Program
  programSaveConfirmationBoxText: 'Gotowe. Wysłaliśmy link do Twoich wyników. Miłej lektury!',
  programExamplePlansTitle: 'Przykładowe rzuty',
  programExamplePlansSubtitle: 'Przeglądaj interaktywne rzuty powierzchni, które pasują do Twojego profilu. Możesz je też przeglądać w wersji 3D!',
  programTooMuchTinkerWarning: 'Zwolnij! Wygląda na to, że wyszedłeś poza wyliczone w oparciu o Twój profil wielkości. Rozważ <1>ponowne wypełnienie kwestionariusza</1> lub skontaktuj się z działem obsługi klienta pod adresem <3>{{email}}</3>.',
  programDisclaimer: 'Aplikacja CBRE Spacer jest udostępniana użytkownikom na zasadzie „tak jak jest” i „w miarę dostępności” bez udzielania żadnych gwarancji. Wszelkie zalecenia wygenerowane w wyniku skorzystania z aplikacji Spacer mają charakter wyłącznie poglądowy i nie stanowią faktycznych zaleceń dotyczących powierzchni lub warunków najmu. Rzeczywiste zalecenia dotyczące powierzchni mogą się znacznie różnić, jako że zależeć będą od wielu czynników, których aplikacja Spacer może nie uwzględniać. Korzystanie z aplikacji Spacer i wykorzystanie jakiegokolwiek zestawienia wygenerowanego przy jej użyciu oznacza akceptację naszego Regulaminu oraz Polityki Prywatności, które znajdują się odpowiednio pod: https://workplace.cbre.com/termsOfService i https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy. © 2018 CBRE, Inc. Wszelkie prawa zastrzeżone.',
  programMetricsTitle: 'Parametry Projektu',
  programMetricsSubtitle: 'Przedstawione liczby są parametrami wyliczonymi dla Twojego środowiska pracy',
  programDetailsSectionTitle: 'Spersonalizuj Swój Projekt',
  programDetailsSectionSubtitle: 'Dostosuj poniższe pola, aby dokładnie określić swoje potrzeby w zakresie powierzchni biurowej.',
  programRevertButtonText: 'Przywróć',
  programSaveButtonText: 'Zapisz zmiany',
  programSavedButtonText: 'Zapisano',

  // ProgramAssumptions
  programAssumptionsRecommendedOfficeSizeText: 'Zalecana Wielkość Biura',
  programAssumptionsProfileText: 'Profil',
  programAssumptionsHeadcountText: 'Liczba pracowników',
  programAssumptionsHeadcountFollowingText: 'osób',
  programAssumptionsCollabText: 'Przestrzeń do pracy zespołowej',
  programAssumptionsCirculationText: 'Przestrzeń komunikacyjna',
  programAssumptionsAvgDaysText: 'Średnia liczba dni w biurze',
  programAssumptionsAvgDaysFollowingText: 'tygodniowo',
  programAssumptionsAvgDaysPerWeekText: 'Średnia liczba dni w biurze tygodniowo',

  // ProgramHeroForPrint
  printNIAText: 'Powierzchnia wewnętrzna netto',
  printUSFText: 'Powierzchnia użytkowa w metrach kwadratowych',
  printRSFText: 'Powierzchnia najmu w metrach kwadratowych',
  printDescText: 'W oparciu o podane przez Ciebie informacje i {{lossFactorPercentage}}% {{factorType}} w {{city}}, zalecamy powierzchnię o łącznej wielkości – …',

  printFitFactorText: 'współczynnik dopasowania',
  printLossFactorText: 'współczynnik strat',

  // BarChart
  barChartIndivSubtitle: 'Strefa pracy indywidualnej',
  barChartGroupSubtitle: 'Strefa pracy zespołowej',
  barChartAmenitySubtitle: 'Strefa udogodnień socjalnych',

  // TabbedProgramBarChart
  tabbedBarChartMeSubtitle: 'Praca indywidualna',
  tabbedBarChartGroupSubtitle: 'Praca zespołowa',
  tabbedBarChartAmenitySubtitle: 'Udogodnienia socjalne',

  // SpaceCategoryBreakdown
  spaceCatBreakdownSubtitle: 'Wybierz strefę, którą chcesz spersonalizować',

  // SpaceCategoryBreakdownMobile
  spaceCatBreakdownMobileMeTitle: 'Strefa pracy indywidualnej',
  spaceCatBreakdownMobileWeTitle: 'Strefa pracy zespołowej',
  spaceCatBreakdownMobileAmenityTitle: 'Strefa udogodnień socjalnych',
  spaceCatBreakdownMobileMeSubtitle: 'do pracy indywidualnej',
  spaceCatBreakdownMobileWeSubtitle: 'do pracy zespołowej',
  spaceCatBreakdownMobileAmenitySubtitle: 'do wszystkiego innego',
  spaceCatBreakdownMobileNIATotalTitle: 'Całkowita powierzchnia wewnętrzna netto',
  spaceCatBreakdownMobileTotalTitle: 'Całkowita {{sfType}} w {{unitCapitalized}} wynosi',
  spaceCatBreakdownMobileCirculationText: 'Przestrzeń komunikacyjna',

  // SpaceCategoryDropdown
  spaceCatDropdownMeText: 'Strefa pracy indywidualnej',
  spaceCatDropdownWeText: 'Strefa pracy zespołowej',
  spaceCatDropdownAmenityText: 'Strefa udogodnień socjalnych',

  // SidebarInfo
  sidebarInfoMeTitle: 'Praca indywidualna',
  sidebarInfoWeTitle: 'Praca zespołowa',
  sidebarInfoAmenityTitle: 'Udogodnienia socjalne',

  // Space Category Descriptions (spaceCategoryDescription.js)
  spaceCatMeDesc: 'Przestrzeń do pracy indywidualnej. Zapewnienie pracownikom możliwości wyboru miejsca i sposobu pracy zwiększa poziom ich zadowolenia.',
  spaceCatWeDesc: 'Przestrzeń do współpracy i pracy zespołowej. W czasach, kiedy pracować można z dowolnego miejsca, ważne jest, aby zapewnić pracownikom zróżnicowaną przestrzeń do wykonywania różnych zadań.',
  spaceCatAmenityDesc: 'Przestrzeń wspomagająca pracę. Odpowiednie udogodnienia socjalne sprawią, że Twoje biuro będzie miejscem, w którym czas będą chcieli spędzać zarówno Twoi pracownicy, jak i kandydaci do pracy w Twojej organizacji.',

  // Suggested Custom Amenities (suggestedCustomAmenityUnitMap.js)
  audioVisualCustom: 'Audio / Wizualne',
  dataCenterCustom: 'Centrum danych',
  buildingSupportCustom: 'Obsługa techniczna budynku',
  foodServiceCustom: 'Restauracja',
  kitchenCustom: 'Kuchnia',
  serveryCustom: 'Lada wydawcza',
  execDiningCustom: 'Jadalnia dla kadry kierowniczej',
  grabNGoCustom: 'Grab-N-Go',
  juiceCoffeeBarCustom: 'Bar z sokami / Kawiarnia',
  childCareCustom: 'Przedszkole',
  fitnessCenterCustom: 'Centrum fitness',
  healthCenterCustom: 'Przychodnia',
  mailCenterCustom: 'Sortownia poczty',
  commercialPrintCustom: 'Punkt świadczący usługi poligraficzne',
  centralCafeCustom: 'Główna kafeteria',
  securityCtrlCustom: 'Pomieszczenie sterowania systemem bezpieczeństwa i ochrony budynku',
  auditoriumCustom: 'Aula',
  dryCleaningLaundryCustom: 'Pralnia chemiczna / Pralnia',
  confCenterCustom: 'Centrum konferencyjne',
  multiPurpTrainingCustom: 'Pomieszczenie wielofunkcyjne / Sala szkoleniowa',
  informalCollabCustom: 'Strefa współpracy nieformalnej',
  touchdownTeamCustom: 'Strefa spotkań / pracy zespołowej',

  // Suggested Custom Amenity Units (spaceUnit.js)
  seats: 'miejsce',
  seats_plural: 'miejsce',
  rooms: 'pomieszczenie',
  rooms_plural: 'pomieszczenie',
  areas: 'strefa',
  areas_plural: 'strefa',
  units: 'jednostka miary',
  units_plural: 'jednostki',

  // Predefined Amenities (spaceUnitMap.js) - Shared Space Units
  focusRoomDefined: 'Focus room / Sala do pracy w ciszy i skupieniu',
  fileRoomDefined: 'Archiwum',
  centralReceptionHubDefined: 'Główna recepcja',
  smOfficeDefined: 'Mały gabinet',
  regOfficeDefined: 'Standardowy gabinet',
  benchDesk5Defined: 'Biurko o długości 60 cali',
  benchDesk6Defined: 'Biurko o długości 72 cali',
  meetingRoom4Defined: 'Sala konferencyjna (4 os.)',
  meetingRoom6Defined: 'Sala konferencyjna (6 os.)',
  meetingRoom8Defined: 'Sala konferencyjna (8 os.)',
  meetingRoom12Defined: 'Sala konferencyjna (12 os.)',
  meetingRoom15Defined: 'Sala konferencyjna (15 os.)',
  semiEnclosedMeetingBooth4Defined: 'Półzamknięta kabina do spotkań (4 os.)',
  openCollabSpace4to6Defined: 'Otwarta przestrzeń do pracy zespołowej (4-6 os.)',
  contemplationSpaceDefined: 'Pokój wyciszeń',
  mainITEquipRoomDefined: 'Główna serwerownia / Pomieszczenie technologiczne',
  receptionAreaDefined: 'Strefa Recepcji',
  cafeFoodServiceDefined: 'Kafeteria / Kantyna',
  teamStorageDefined: 'Magazynek dla zespołu',
  coatClosetsDefined: 'Szafy na ubrania',
  genStorageDefined: 'Magazyn ogólny',
  centralisedCopyPrintMailDefined: 'Główna strefa ze sprzętem ksero, drukarkami i sortownią poczty',
  specialResourceAreaDefined: 'Strefa zasobów specjalnych',
  personalStorageDefined: 'Szafki pracownicze (lockers)',
  storageITSpaceDefined: 'Magazyn IT',
  secondaryServerRoomDefined: 'Zapasowa serwerownia / pomieszczenie telekomunikacyjne',
  mothersRoomDefined: 'Pokój matki z dzieckiem',
  buildITSpaceDefined: 'Pomieszczenie mieszczące sprzęt IT',
  workerCouncilOffice2Defined: 'Biuro rady pracowników (2 os.)',
  securityCtrlRoomDefined: 'Dyżurka ochrony',
  centralisedMailDefined: 'Główna sortownia poczty',
  visitorCoatLuggageRoomDefined: 'Szafy dla gości',
  wellnessRoomDefined: 'Pokój wellness',
  internalStairDefined: 'Schody wewnętrzne',
  commercialPrintRoomDefined: 'Punkt świadczący usługi ksero',
  buildingSupportServicesAreaDefined: 'Strefa obsługi technicznej budynku',

  // Predefined Amenities (spaceUnitMap.js) - US_SMALL and US_REGULAR
  officeDefined: 'Gabinet',
  lgOfficeDefined: 'Gabinet (duży)',
  smWorkstationDefined: 'Stanowisko pracy (małe)',
  mdWorkstationDefined: 'Stanowisko pracy (średnie)',
  lgWorkstationDefined: 'Stanowisko pracy (duże)',
  huddleRoomDefined: 'Salka do spotkań ad hoc',
  dispersedCopyPrintAreaDefined: 'Jedna z przestrzeni ze sprzętem ksero / drukarkami',
  smMeetingRoomDefined: 'Mała sala konferencyjna',
  mdMeetingRoomDefined: 'Średnia sala konferencyjna',
  lgMeetingRoomDefined: 'Duża sala konferencyjna',
  xlMeetingRoomDefined: 'Bardzo duża sala konferencyjna / Sala posiedzeń',
  multiPurpTrainingDefined: 'Pomieszczenie wielofunkcyjne / Sala szkoleniowa',
  informalCollabDefined: 'Strefa współpracy nieformalnej',
  touchdownTeamDefined: 'Strefa spotkań / pracy zespołowej',
  pantryVendingCoffeeAreaDefined: 'Automaty z przekąskami i napojami / Kącik kawowy',
  workplaceStorageRoomDefined: 'Pomieszczenie magazynowe',
  employeeCoatAreaDefined: 'Garderoba pracowników',
  dispersedMailAreaDefined: 'Strefa odbioru poczty',
  lockerUnitDefined: 'Szafki pracownicze (lockers)',
  serverRoomDefined: 'Serwerownia',
  frontDeskAreaDefined: 'Strefa recepcyjna',
  centralCafeAreaDefined: 'Główna kafeteria',
  mailCenterAreaDefined: 'Sortownia poczty',
  kitchenAreaDefined: 'Strefa kuchenna',
  drawerLateralFileUnitDefined: 'Szafa kartotekowa z 3 szufladami na dokumenty układane poziomo',
  serveryAreaDefined: 'Lada wydawcza',
  juiceBarCoffeeShopAreaDefined: 'Bar z sokami / Kawiarnia',
  diningSupportAreaDefined: 'Strefa przygotowywania posiłków',
  diningAreaDefined: 'Strefa jadalna',
  grabNGoAreaDefined: 'Strefa Grab-N-Go',
  focusRoomSharedFocus: 'Focus room / Sala do pracy w ciszy i skupieniu',

  // Predefined Amenities (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDefined: 'Restauracja',

  // Predefined Amenities (spaceUnitMap.js) - APAC_REGULAR
  officeWorkMeetDefined: 'Biuro (praca/spotkania)',
  lgOfficeWorkMeetDefined: 'Duże biuro (praca/spotkania)',
  focusDefined: 'Praca w ciszy i skupieniu',
  bench1pt6Defined: 'Biurko o długości 1,6 m',
  bench1pt8Defined: 'Biurko o długości 1,8 m',
  bench1pt8LDefined: 'Stanowisko pracy o długości 1,8 m (rozmiar L)',
  banquetWorkMeetDefined: 'Stół / Sala spotkań (2os)',
  cafeTable4Defined: 'Stolik kawowy (4 os.)',
  kitchenTable8to10Defined: 'Stół kuchenny (8-10 os.)',
  standingWhiteboardDefined: 'Biała tablica stojąca (whiteboard)',
  huddleRoom3Defined: 'Salka konferencyjna (3 os.)',
  smMeetingRoom5Defined: 'Mała sala konferencyjna (5 os.)',
  mdMeetingRoom8Defined: 'Średnia sala konferencyjna (8 os.)',
  lgMeetingRoom20Defined: 'Duża sala konferencyjna (20 os.)',
  xlBoardroom: 'Bardzo duża sala konferencyjna / Sala posiedzeń',
  wellnessRoomsDefined: 'Pokoje wellness',
  employeeCoatsGymLockersDefined: 'Płaszcze pracownicze / Szafki na akcesoria gimnastyczne',
  lockersDefined: 'Szafki pracownicze (lockersy)',
  dispersedCopyPrintScanAreaDefined: 'Jedna z przestrzeni ze sprzętem ksero / drukarkami / skanerami',
  hydrationPointDefined: 'Punkt nawadniania (woda / herbata / kawa)',
  centralHighDensityStorageDefined: 'Główny magazyn o dużej pojemności',
  wellnessMothersRoomDefined: 'Pokój wellness / Pokój matki z dzieckiem',
  serverHubRoomsDefined: 'Serwerownia / Centrala',
  buildITStoreRoomDefined: 'Pomieszczenie mieszczące sprzęt IT / Składzik sprzętu IT',
  simpleReceptionWaitDefined: 'Podstawowa recepcja + poczekalnia',
  centralCafeSocialHubDefined: 'Główna kafeteria i miejsce spotkań towarzyskich',
  mailCentreDefined: 'Sortownia poczty',
  utilityStoreRoomDefined: 'Pomieszczenie gospodarcze',
  drawerLateralFileDefined: 'Szafa kartotekowa z 3 szufladami na dokumenty układane poziomo',
  juiceBarCoffeeShopDefined: 'Bar z sokami / Kawiarnia',
  buildingSupportServicesDefined: 'Obsługa techniczna budynku',

  // Space Unit Map Descriptions (spaceUnitMap.js) - Shared Descriptions
  focusRoomDesc: 'Focus room to zamknięta przestrzeń przeznaczona do pracy w skupieniu lub nad projektami wymagającymi poufności. Zazwyczaj zaprojektowana tak, aby pomieścić jedną lub dwie osoby. Odpowiednia adaptacja akustyczna, czyli wyciszenie umożliwiające zachowanie prywatności, jest tu kluczowa. Salki te najczęściej nie są przypisane konkretnym pracownikom i mogą być rezerwowane czasowo w zależności od potrzeb.',
  fileRoomDesc: 'Jest to miejsce przechowywania papierowych wersji dokumentów, z którego korzystają zarówno pojedynczy pracownicy, jak i całe zespoły. W wyniku digitalizacji procesów w większości biur zapotrzebowanie na taką przestrzeń znacznie się zmniejszyła w ostatnim czasie. Zgodnie z przepisami prawa organizacje nadal zobowiązane są do przechowywania kopii dokumentów i materiałów, dotyczących długoterminowych projektów. Taką właśnie funkcję spełnia ta przestrzeń, zwłaszcza że biurka w przestrzeniach typu open space, nie oferują zbyt wiele miejsca na przechowywanie dokumentów.',
  centralReceptionHubDesc: 'Recepcja jest pierwszym miejscem, dzięki któremu organizacja może wywrzeć wrażenie na pracownikach, klientach i innych odwiedzających. Powinna konsekwentnie odzwierciedlać tożsamość marki, a tym samym odpowiednio wyglądać. Lada recepcyjna musi zapewnić wygodne stanowisko pracy dla recepcjonisty/-ki, a gości powinny przyciągać miękkie siedziska/sofy.',
  officeDesc: 'Gabinety są prywatnymi, zamkniętymi, indywidualnymi przestrzeniami do pracy dla pracowników. Świetnie nadają się do koncentracji uwagi na pracy bez niepotrzebnych rozpraszaczy. Zazwyczaj wyposażone w biurko i krzesło z dodatkowymi krzesłami dla gości. Wielkość tej przestrzeni może się różnić w zależności od zadań, które są tutaj realizowane (np. regularne spotkania) lub statusu/pozycji pracownika w hierarchii organizacji.',
  benchDeskDesc: 'Biurka to indywidualne, otwarte miejsca do pracy. Mniejsze z nich mogą być wykorzystywane przez pracowników etatowych zarówno okresowo (w ogólnodostępnej przestrzeni), jak i na stałe. Takie biurka są również doskonałym rozwiązaniem dla pracowników czasowo oddelegowanych z innych oddziałów i wykonawców, którzy nie przebywają w biurze przez cały czas. Zalety takich otwartych biurek to m.in. widoczność biura i integralna współpraca. Jedną z wad może być rozpraszający hałas, dlatego też zaleca się, aby biura, w których pracuje się przy tego rodzaju biurkach oferowały też wystarczającą liczbę pomieszczeń do pracy w ciszy i skupieniu i salek konferencyjnych. Przy biurkach tego rodzaju mamy do czynienia z ograniczonymi możliwościami archiwizacji dokumentów, dlatego też na terenie biura najprawdopodobniej znajdować będzie się kilka przeznaczonych do tego stref.',
  meetingRoomDesc: 'Sale konferencyjne to zamknięte przestrzenie przeznaczone do spotkań w małych grupach liczących od 2 do 4 osób. Dla usprawnienia procesu wymiany informacji powinny być wyposażone w sprzęt audio-wideo i białe tablice. Powinny być też wyciszone (osoby przebywające w bezpośrednim sąsiedztwie na zewnątrz takiego pomieszczenia nie powinny słyszeć rozmów toczących się w środku).',
  meetingRoomDesc6: 'Sale konferencyjne to zamknięte przestrzenie przeznaczone do spotkań w małych grupach liczących od 2 do 4 osób. Dla usprawnienia procesu wymiany informacji powinny być wyposażone w sprzęt audio-wideo i białe tablice. Powinny być też wyciszone (osoby przebywające w bezpośrednim sąsiedztwie na zewnątrz takiego pomieszczenia nie powinny słyszeć rozmów toczących się w środku).',
  meetingRoomDesc8: 'Sale konferencyjne to zamknięte przestrzenie przeznaczone do spotkań w małych grupach liczących od 2 do 4 osób. Dla usprawnienia procesu wymiany informacji powinny być wyposażone w sprzęt audio-wideo i białe tablice. Powinny być też wyciszone (osoby przebywające w bezpośrednim sąsiedztwie na zewnątrz takiego pomieszczenia nie powinny słyszeć rozmów toczących się w środku).',
  meetingRoomDesc12: 'Sale konferencyjne to zamknięte przestrzenie przeznaczone do spotkań w małych grupach liczących od 2 do 4 osób. Dla usprawnienia procesu wymiany informacji powinny być wyposażone w sprzęt audio-wideo i białe tablice. Powinny być też wyciszone (osoby przebywające w bezpośrednim sąsiedztwie na zewnątrz takiego pomieszczenia nie powinny słyszeć rozmów toczących się w środku).',
  meetingRoomDesc15: 'Sale konferencyjne to zamknięte przestrzenie przeznaczone do spotkań w małych grupach liczących od 2 do 4 osób. Dla usprawnienia procesu wymiany informacji powinny być wyposażone w sprzęt audio-wideo i białe tablice. Powinny być też wyciszone (osoby przebywające w bezpośrednim sąsiedztwie na zewnątrz takiego pomieszczenia nie powinny słyszeć rozmów toczących się w środku).',
  openCollabSpace4to6Desc: 'Powierzchnie te mają na celu wspieranie małych grup (od 4 do 6 osób) we wspólnych działaniach, takich jak burze mózgów i nieformalne rozmowy między współpracownikami oraz z klientami.',
  cafeFoodServiceDesc: 'Główna kafeteria ma być miejscem spożywania posiłków, interakcji społecznych, pracy indywidualnej i spotkań w małych grupach. W projekcie tej przestrzeni należy uwzględnić funkcjonalne meble do spożywania posiłków oraz kolorystykę marki.',
  coatClosetsDesc: 'Pracownicy potrzebują miejsca do przechowywania swoich okryć wierzchnich i innych większych przedmiotów, zwłaszcza gdy biuro ma otwarty układ z ograniczoną liczbą osobistych schowków przy biurkach. Garderoby powinny być rozmieszczone na terenie całego biura np. przy wejściach/wyjściach i w głównych korytarzach. Zazwyczaj nad drążkiem z wieszakami znajduje się półka na nakrycia głowy, a na dole miejsce do przechowywania innych przedmiotów i walizek.',
  copyPrintDesc: 'Usytuowane w kilku miejscach w otwartej przestrzeni biurowej kąciki ze sprzętem ksero/drukarkami zapewniają pracownikom szybki dostęp do tych wielofunkcyjnych urządzeń z każdej części biura. W strefach tych zawsze powinny znajdować się pojemniki do segregacji śmieci oraz małe szafki na zapasowy papier i materiały biurowe. Przestrzenie te są przeznaczone wyłącznie do użytku wewnętrznego i nie powinny być wykorzystywane jako miejsca spotkań/pracy.',
  specialResourceBuildingSupportAreaDesc: 'To uniwersalna nazwa dla wszelkich przestrzeni pełniących funkcje wspomagające, które mogą znajdować się w biurze. Może to być np. dyżurka ochrony lub zaplecze techniczne, a nawet różnorakie składziki i schowki. Ich wielkość i dokładne przeznaczenie będą się różnić w zależności od organizacji. Jednakże czym większe biuro, tym występowanie takich przestrzeni wspomagających będzie częstsze, a nawet konieczne.',
  secondaryServerRoomDesc: 'Pomieszczenie mieszczące i zabezpieczające serwer zapasowy, urządzenia IT oraz końcówki okablowania niskonapięciowego (pomieszczenie technologiczne). W pomieszczeniach tego rodzaju konieczna jest 24-godzinna cyrkulacja powietrza oraz stała temperatura na poziomie 22,3° C przy wilgotności względnej 50%.',
  mothersRoomDesc: 'Pokój matki z dzieckiem jest przeznaczony dla osób potrzebujących dostępu do odosobnionej, prywatnej przestrzeni – czy to dla matek karmiących, czy dla pracowników szukających chwili wytchnienia od zgiełku biura. Pokój matki z dzieckiem to obecnie już element konieczny we wszystkich nowych biurach, niezależnie od ich wielkości, czy liczby osób zatrudnionych w organizacji.',
  visitorCoatLuggageRoomDesc: 'Goście potrzebują miejsca do przechowywania swoich okryć wierzchnich i innych większych przedmiotów. Garderoby powinny być rozmieszczone na terenie całego biura np. przy wejściach/wyjściach i w głównych korytarzach. Zazwyczaj nad drążkiem z wieszakami znajduje się półka na nakrycia głowy, a na dole miejsce do przechowywania innych przedmiotów i walizek.',

  wellnessRoomDesc: 'Pokoje wellness (dawniej nazywane pokojami matki z dzieckiem) są przeznaczone dla osób potrzebujących dostępu do odosobnionej, prywatnej przestrzeni – czy to dla matek karmiących, czy dla pracowników szukających chwili wytchnienia od zgiełku biura. Pokój wellness to obecnie już element konieczny we wszystkich nowych biurach, niezależnie od ich wielkości, czy liczby osób zatrudnionych w organizacji. Powinny się w nim znajdować fotel wypoczynkowy, lodówka, a także (jeśli to możliwe) umywalka.',

  internalStairDesc: 'Na potrzebę schodów wewnętrznych można adaptować klatkę schodową w trzonie budynku, w ten sposób zapewniając komunikację między piętrami. Schody te mogą być również dodatkowym elementem aranżacji przestrzeni łączącym piętra biura. Często są wykorzystywane jako miejsce spotkań, a wokół nich urządza się kafeterię, recepcję lub kąciki wypoczynkowe.',
  commercialPrintRoomDesc: 'Organizacje często udostępniają pracownikom pomieszczenia połączone z sortownią poczty, w których istnieje możliwość skorzystania z urządzeń ksero i drukarek, bindowania czy też oprawy dokumentów bez potrzeby korzystania z usług podmiotów zewnętrznych. Najczęściej znajdować będzie się tam kilka drukarek, a być może i drukarek wielkoformatowych. Przestrzeń ta powinna mieścić też miejsce do przechowywania materiałów biurowych, a także ladę na mniejszy sprzęt i do układania wydruków.',
  pantryVendingCoffeeDesc: 'W przeciwieństwie do głównej kafeterii strefa mieszcząca automaty z przekąskami i napojami oraz kącik kawowy jest przeznaczona dla pracowników, którzy chcą zjeść i wypić coś na szybko. Naturalnie w strefach przeznaczonych do konsumpcji żywności i napojów panuje gwar, ale te przestrzenie zazwyczaj nie oferują miejsc siedzących, co powoduje, że ludzie szybko przychodzą i wychodzą, nie spędzając zbyt wiele czasu na rozmowach towarzyskich.',
  storageDesc: 'Pomieszczenia magazynowe są przeznaczone do przechowywania różnych przedmiotów takich jak: duży sprzęt, dodatkowe meble, ozdoby świąteczne itp. Mogą być również wykorzystywane do przechowywania okryć wierzchnich i bagaży gości. Dla wygody mały schowek powinien być umiejscowiony także w pobliżu recepcji.',
  centralisedDispersedMailAreaDesc: 'W przypadku większych firm sortownia poczty jest niezbędna do sprawnego zarządzania pocztą przychodzącą i wychodzącą. W takich pomieszczeniach znajdują się specjalistyczne urządzenia służące do przygotowywania i wysyłania przesyłek. Najczęściej mieszczą się tam również różnego rodzaju lady i blaty umożliwiające sortowanie dokumentów i innych materiałów. Sortownia poczty jest zazwyczaj obsługiwana przez pełnoetatowego pracownika.',
  lockerDesc: 'Szafki są dodatkowym miejscem, w którym pracownicy mogą przechowywać swoje rzeczy osobiste, wartościowe, a także dokumenty. Mogą być one przypisane konkretnej osobie lub używane na zasadzie „kto pierwszy, ten lepszy”.',
  mainITandServerRoomDesc: 'Pomieszczenie mieszczące i zabezpieczające serwer główny i urządzenia IT oraz końcówki okablowania niskonapięciowego (pomieszczenie technologiczne). W pomieszczeniach tego rodzaju konieczna jest 24-godzinny cyrkulacja powietrza oraz stała temperatura na poziomie 22,3° C przy wilgotności względnej 50%.',
  drawerLateralFileDesc: 'Osobisty schowek dla poszczególnych pracowników umiejscowiony przy biurku lub w gabinecie. Jego zawartość to zazwyczaj przedmioty osobiste lub materiały dotyczące bieżących projektów.',
  juiceBarCoffeeShopDesc: 'Nawet gdy w biurze nie znajdzie się miejsce na pełną strefę kuchenną lub ladę wydawczą, organizacja –  w ramach udogodnień gastronomicznych – może zapewnić pracownikom dostęp do kawiarni lub baru sokowego. Tego rodzaju przestrzeń może być dodatkiem do głównej strefy kuchennej/jadalnej, zwłaszcza jeśli mamy do czynienia z biurem o dużej powierzchni. Przestrzeń ta będzie prawdopodobnie wyglądać jak lokalny Starbucks z miejscem dla obsługi, oddzielonym od głównej strefy ladą.',
  grabNGoDesc: 'Niewielka przestrzeń z kilkoma lodówkami, ladami z chipsami i przekąskami oraz kasą. Strefy typu Grab-N-Go są zazwyczaj monitorowane za pomocą kamer. Często połączone z małą przestrzenią z lodówkami i zamrażarkami oraz miejscem do przygotowywania żywności.',
  smMeetingConferenceRoomDesc: 'Mała sala konferencyjna jest zamkniętą przestrzenią przeznaczoną do spotkań, w której przy stole może wygodnie usiąść od 2 do 6 osób. Pomieszczenia tego rodzaju mogą być wykorzystywane zarówno do spotkań wewnętrznych, jak i do takich z klientami, dlatego powinny być zaaranżowane w sposób przyjazny dla użytkownika i zapewniać łatwy dostęp do gniazd zasilających i wyposażenia audio-wideo.',
  mdMeetingConferenceRoomDesc: 'Średnia sala konferencyjna jest zamkniętą przestrzenią przeznaczoną do spotkań, w której przy stole może wygodnie usiąść od 6 do 10 osób. Pomieszczenia tego rodzaju mogą być wykorzystywane zarówno do spotkań wewnętrznych, jak i do takich z klientami, dlatego powinny być zaaranżowane w sposób przyjazny dla użytkownika i zapewniać łatwy dostęp do gniazd zasilających oraz wyposażenia audio-wideo.',
  lgMeetingConferenceRoomDesc: 'Duża sala konferencyjna jest zamkniętą przestrzenią, w której przy stole może wygodnie usiąść od 12 do 14 osób, gdzie pożądane będzie też zapewnienie miejsc siedzących dla większej liczby osób pod ścianami. Pomieszczenia tego rodzaju mogą być wykorzystywane zarówno do spotkań wewnętrznych, jak i do takich z klientami i najprawdopodobniej będą wykorzystywane do przeprowadzania prezentacji. Meble powinny być ruchome, aby umożliwić wykorzystanie przestrzeni do innych celów, takich jak zajęcia jogi dla pracowników.',
  xlMeetingRoomDesc: 'Sala konferencyjna dla zarządu ma wygodnie pomieścić od 14 do 18 osób przy stole i posiadać dodatkowe miejsca siedzące pod ścianami dla co najmniej 10 osób. Pomieszczenia tego rodzaju będą najprawdopodobniej wykorzystywane na potrzeby dużych i oficjalnych spotkań wewnętrznych oraz dużych spotkań z klientami. Powinny więc być wyposażone w niezawodny sprzęt audio-wideo i traktowane jako przestrzeń przeznaczona na spotkania z klientami.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - US_SMALL and US_REGULAR
  lgOfficeDesc: 'Gabinety są prywatnymi, zamkniętymi, indywidualnymi przestrzeniami do pracy dla pracowników. Świetnie nadają się do koncentracji uwagi na pracy bez niepotrzebnych rozpraszaczy. Zazwyczaj wyposażone w biurko i krzesło z dodatkowymi krzesłami dla gości. Gabinet będzie też mieścił różną liczbę szafek przeznaczonych do przechowywania rzeczy osobistych i dokumentów. Wielkość tej przestrzeni może się różnić w zależności od zadań, które są tutaj realizowane (np. regularne spotkania) lub statusu/pozycji pracownika w hierarchii organizacji. Nieco większe z nich będą zajmowane przez kadrę zarządzającą lub pełnić funkcję salki konferencyjnej.',
  smWorkstationDesc: 'Stanowiska pracy to indywidualne, otwarte miejsca do pracy. Mniejsze z nich mogą być wykorzystywane przez pracowników etatowych zarówno okresowo (w ogólnodostępnej przestrzeni), jak i na stałe. Są one również doskonałym rozwiązaniem dla pracowników czasowo oddelegowanych z innych oddziałów i wykonawców, którzy nie przebywają w biurze przez cały czas. Ich zalety to m.in. widoczność biura i integralna współpraca. Jedną z wad może być rozpraszający hałas, dlatego też zaleca się, aby biura, w których pracuje się przy tego rodzaju stanowiskach oferowały też wystarczającą liczbę pomieszczeń do pracy w ciszy i skupieniu i salek konferencyjnych. Przy stanowiskach tego rodzaju mamy do czynienia z ograniczonymi możliwościami archiwizacji dokumentów, dlatego też na terenie biura najprawdopodobniej znajdować będzie się kilka przeznaczonych do tego stref.',
  mdWorkstationDesc: 'Stanowiska pracy to indywidualne, otwarte miejsca do pracy. Korzyści płynące z nieco większej przestrzeni obejmują więcej miejsca na przechowywanie rzeczy osobistych i zapewnienie większej prywatności. Oznacza to prawdopodobnie, że im większe biurko, tym większe prawdopodobieństwo, że zostanie ono przydzielone konkretnej osobie. Ich zalety to m.in. widoczność biura i integralna współpraca. Jedną z wad może być rozpraszający hałas, dlatego też zaleca się, aby biura, w których pracuje się przy tego rodzaju stanowiskach oferowały też wystarczającą liczbę pomieszczeń do pracy w ciszy i skupieniu i salek konferencyjnych. Na terenie biura najprawdopodobniej znajdować będzie się kilka wspólnych stref przeznaczonych do przechowywania rzeczy osobistych i dokumentów.',
  lgWorkstationDesc: 'Stanowiska pracy to indywidualne, otwarte miejsca do pracy. Korzyści płynące z nieco większej przestrzeni obejmują więcej miejsca na przechowywanie rzeczy osobistych i zapewnienie większej prywatności. Biurko tej wielkości będzie najprawdopodobniej przydzielone konkretnej osobie. Ich zalety to m.in. widoczność biura i integralna współpraca. Jedną z wad może być rozpraszający hałas, dlatego też zaleca się, aby biura, w których pracuje się przy tego rodzaju stanowiskach oferowały też wystarczającą liczbę pomieszczeń do pracy w ciszy i skupieniu i salek konferencyjnych. Na terenie biura najprawdopodobniej znajdować będzie się kilka wspólnych stref przeznaczonych do przechowywania rzeczy osobistych i dokumentów.',
  huddleRoomDesc: 'Salki konferencyjne to zamknięte przestrzenie przeznaczone do spotkań w małych grupach liczących od 2 do 4 osób. Dla usprawnienia procesu wymiany informacji powinny być wyposażone w sprzęt audio-wideo i białe tablice. Powinny być też wyciszone (osoby przebywające w bezpośrednim sąsiedztwie na zewnątrz takiego pomieszczenia nie powinny słyszeć rozmów toczących się w środku).',
  multiPurpTrainingDesc: 'Sale wielofunkcyjne przeznaczone są dla dużej liczby osób (20+). Przestrzenie tego rodzaju zostały tak nazwane ze względu na zróżnicowany charakter działań, które można w nich realizować, np. szkolenia, imprezy firmowe i prowadzenie prezentacji. Sposób aranżacji tej przestrzeni jest funkcjonalny i trwały zarówno z perspektywy potrzeb użytkowników wewnątrz organizacji, jak i klientów. Sale te muszą być wyposażone w odpowiedni sprzęt audio-wideo, zazwyczaj w znacznie większej mierze niż przeciętna sala konferencyjna.',
  informalCollabDesc: 'Powierzchnie te mają na celu wspieranie małych grup (od 4 do 6 osób) we wspólnych działaniach, takich jak burze mózgów i nieformalne rozmowy między współpracownikami oraz z klientami.',
  touchdownTeamDesc: 'Indywidualne lub małe przestrzenie przeznaczone do wewnętrznej pracy zespołowej rozmieszczone na terenie całego biura do użytku pracowników i gości. Przestrzenie te mogą mieć różne wysokości, kształty i rozmiary, jednakże mają wyróżniać się na tle indywidualnych stanowisk pracy jako wyjątkowe miejsca do pracy przez krótki czas. Przestrzeń taka powinna być zagospodarowana na zasadzie „podłącz i używaj”.',
  frontDeskAreaDesc: 'To pierwsze miejsce, w którym pracownicy i goście mają kontakt z biurem. Lada recepcyjna powinna być w stanie pomieścić co najmniej jedną osobę zapewniając jej wygodne stanowisko pracy i miejsce na przechowywanie rzeczy osobistych i dokumentów. Prawdopodobnie w pobliżu będzie znajdowało się miejsce do przechowywania okryć wierzchnich gości. Recepcja umiejscowiona jest w większej strefie recepcyjnej z miejscami siedzącymi i innymi udogodnieniami.',
  mailCenterAreaDesc: 'W przypadku większych firm sortownia poczty jest niezbędna dla scentralizowanego zarządzania wszystkimi procesami związanymi z przesyłkami. W takich pomieszczeniach znajdować będą się różnego rodzaju obszerne lady i blaty umożliwiające sortowanie dokumentów i innych materiałów, a także miejsce na tablice i inny sprzęt. Ta przestrzeń będzie najprawdopodobniej wyglądać odmiennie w zależności od organizacji, jako że najczęściej będzie ona spełniać też inne funkcje w ramach jej działalności.',
  kitchenAreaDesc: 'W przypadku powierzchni oferujących usługi gastronomiczne na terenie biura strefa kuchenna będzie niezbędnym elementem je wspomagającym. Wymagania co do wielkości, aranżacji i sprzętu będą się różnić w zależności od organizacji, ale na pewno znajdą się tam urządzenia do gotowania oraz lodówki. Nie może tam również zabraknąć miejsca do przechowywania produktów sypkich i sprzętu. Kuchnia będzie sąsiadować z ladą wydawczą i najprawdopodobniej strefą konferencyjną/udogodnień.',
  serveryAreaDesc: 'Lada wydawcza to miejsce zamawiania i wydawania posiłków. Będzie ona prawdopodobnie połączona z kuchnią i jadalnią ze stołami i miejscami do siedzenia. Aranżacja tej przestrzeni musi być niezwykle precyzyjna, tak aby sprawnie zarządzać kolejkami i ruchem. Jej rozmiar i kształt będą się różnić w zależności od organizacji.',
  diningSupportAreaDesc: 'Organizacja oferująca pełnowymiarowe usługi gastronomiczne prawdopodobnie będzie musiała uwzględnić też dodatkowe przestrzenie wspomagające, takie jak magazyny/spiżarnie w innych częściach budynku. Należy to zrobić na etapie planowania całości powierzchni. Przestrzeń ta będzie się różnić w zależności od organizacji.',
  diningAreaDesc: 'Jadalnia w pobliżu kuchni powinna zapewniać pracownikom różnorodne opcje w zakresie wyboru miejsc do siedzenia i spożywania posiłków. Zapewnienie kilku różnych rodzajów miejsc siedzących umożliwia również wykorzystanie przestrzeni do spotkań i współpracy poza porą lunchu. W przestrzeni tej powinny znaleźć się krzesła i fotele (z miękkimi i twardymi obiciami), a także hokery z możliwością regulacji wysokości siedziska.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDesc: 'Oferowanie pełnowymiarowych usług restauracyjnych w przestrzeni biurowej wymaga zaplanowania wielu innych funkcji, które łącznie umożliwią sprawne funkcjonowanie tej strefy, w tym kuchnię, ladę wydawczą, jadalnię (z miejscami siedzącymi) i przestrzeń wspomagającą (przechowywanie). Kuchnia i lada wydawcza współpracują ze sobą, aby przygotować i podać jedzenie przygotowywane na miejscu. Jadalnia to miejsce, w którym pracownicy i goście mogą usiąść i zjeść posiłek, ale często przestrzeń ta pełni też rolę miejsca spotkań towarzyskich i współpracy. Podobnie jak w domu do świadczenia usług gastronomicznych potrzebna jest duża ilość sprzętu i miejsca na jego przechowywanie, a także przechowywanie żywności, dlatego przestrzeń ta przynależeć będzie do kategorii powierzchni restauracyjnej.',

  // SpaceUnitRow
  spaceUnitRowDeleteTitle: 'Usuń typ przestrzeni',
  spaceUnitRowDeleteText: 'Czy na pewno chcesz usunąć ten typ przestrzeni?',
  spaceUnitRowConfirmDeleteText: 'Tak, usuń',
  spaceUnitRowCancelDeleteText: 'Nie, zachowaj',

  // CustomAmenityAdder
  customAmenityAddText: 'Dodaj udogodnienie socjalne',
  customAmenityPlaceholder: 'Rodzaj udogodnień socjalnych',

  // SpaceUnitTotals
  spaceUnitTotalsTitleNIA: 'Całkowita Powierzchnia Wewnętrzna Netto',
  spaceUnitTotalsTitle: 'Całkowita {{sfTypeAdj}} w {{unitCapitalized}} wynosi  ',
  spaceUnitTotalsCirculation: 'Przestrzeń komunikacyjna',
  spaceUnitTotalsCirculationTooltip: '„Przestrzeń pomiędzy przestrzeniami”. Korytarze, przestrzeń za krzesłem, przestrzeń wokół szafek i drzwi oraz w rogach pomieszczeń. Bez przestrzeni komunikacyjnej nie mógłbyś poruszać się po biurze! Zwykle stanowi około 35% całkowitej powierzchni użytkowej wyrażonej w {{unitCapitalized}}  wyliczonej dla Twojego projektu – jednakże ostateczna wartość zależeć będzie od Twojego profilu i budynku, na który się zdecydujesz.',
  spaceUnitTotalsREBNY: 'Współczynnik REBNY',
  spaceUnitTotalsNYCText: 'Nowy Jork',
  spaceUnitTotalsREBNYTooltip: 'W przypadku projektów przygotowywanych dla powierzchni biurowych w Nowym Jorku dodajemy {{sfType}} 15%, czyli tzw. współczynnik REBNY. Powierzchnia mierzona z uwzględnieniem współczynnika REBNY obejmuje m.in. łazienki, hole windowe oraz grubość ścian zewnętrznych.',
  spaceUnitTotalsLossFactor: 'Współczynnik strat ',
  spaceUnitTotalsLossFactorTooltip: 'Powierzchnia użytkowa wyrażona w {{unit}} to ilość miejsca, które masz w swoim biurze. Powierzchnia najmu wyrażona w {{unit}} to powierzchnia, za którą płacisz, która może obejmować filary, hole i inne części wspólne w budynku. Różnica między powierzchnią użytkową a najmu nazywana jest współczynnikiem strat, a sposób jej obliczania różni się w zależności od lokalizacji.',

  // CollaborationDropdown
  collabDropdownLevelLowText: 'niewiele (0-25%)',
  collabDropdownLevelMedText: 'część (26-50%)',
  collabDropdownLevelHighText: 'większość (51-100%)',

  //DensityDropdown
  sameDensityText: 'takie samo jak przed Covid-19',
  lowerDensityText: 'mniejsze (większa liczba mkw./os.)',
  notSureDensityText: 'nie jesteśmy pewni',

  //CirculationDropdown
  preCovidText: 'Na poziomie sprzed Covid-19 (45% przestrzeń otwarta / 25% przestrzeń zamknięta)',
  higherText: 'Zwiększona przestrzeń komunikacyjna (50% przestrzeń otwarta / 30% przestrzeń zamknięta)',
  notSureText: 'Nie jesteśmy pewni (45% przestrzeń otwarta / 25% przestrzeń zamknięta)',

  // ExamplePlans
  examplePlanButtonText: 'Podgląd przykładowego rzutu',
  examplePlanFullFloorText: 'całe piętro',
  examplePlanPartialFloorText: 'część piętra',
  examplePlanFullImgAlt: 'Rzut przedstawiający przestrzeń biurową o wielkości {{size}} {{unit}} kwadratowych obejmującą całe piętro wygenerowany dla profilu {{profileName}} w aplikacji CBRE Spacer',
  examplePlanPartialImgAlt: 'Rzut przedstawiający przestrzeń biurową o wielkości {{size}} {{unit}} kwadratowych obejmującą część piętra wygenerowany dla profilu {{profileName}} w aplikacji CBRE Spacer',
  examplePlanTooltipText: 'Jeżeli powierzchnia Twojego biura przekracza wielkość {{size}} {{unit}} kwadratowych, może okazać się, że będziesz musiał zająć więcej niż jedno piętro.',

  // ProgramStats
  programStatsDensitySubtitleTsubo: 'Tsubo netto',
  programStatsDensitySubtitle: '{{adjective}} w {{unitUppercase}}',
  programStatsIndivTitle: 'Stanowiska do pracy indywidualnej',
  programStatsWorkstationsSubtitle: 'Stanowiska pracy',
  programStatsOfficesSubtitle: 'Gabinety',
  programStatsCollabTitle: 'Przestrzeń do pracy zespołowej',
  programStatsEnclosedSubtitle: 'Zamknięte stanowiska do pracy zespołowej',
  programStatsIndivSeatSubtitle: 'Stanowisko do pracy indywidualnej',
  programStatsSeatsTitle: 'Liczba miejsc na osobę',
  programStatsIndivSeatsSubtitle: 'Stanowiska do pracy indywidualnej',
  programStatsPersonSubtitle: 'Osoba',
  programStatsDensityTitle: 'Zagęszczenie',
  programStatsFullDensitySubtitle: '{{density}} na stanowisko do pracy indywidualnej',

  // Profile Display Names (profileNames.js)
  efficientName: '„Optymalizator”',
  whiteCollarName: '„Gospodarz”',
  whiteGloveName: '„Mobilizator”',
  openOfficeName: '„Realizator”',
  workplace360Name: '„Integrator”',
  campusName: '„Wyróżnik”',
  startupName: '„Inkubator”',
  engCreativeName: '„Kreator”',

  // Profile Descriptions (profileDescriptions.js)
  efficientDesc: 'Biuro „Optymalizator” to konkretne i rzeczowe podejście do kwestii środowiska pracy z czystym nastawieniem na jej wykonanie. W tej przestrzeni chodzi o zaopatrzenie pracowników we wszystkie wymagane narzędzia przy własnych biurkach. Współpraca i praca zespołowa nie zdarzają się tutaj często. Biurka są ustandaryzowane i przyporządkowane konkretnym pracownikom, a w obrębie biura dostępne są tylko podstawowe udogodnienia i przestrzenie wspomagające. Zgodnie ze swoją nazwą biuro to prawie zawsze funkcjonuje w przestrzeni otwartej w celu maksymalizacji wydajności.',
  whiteCollarDesc: 'Jeżeli Twoim biurem jest „Gospodarz”, to nie jesteś sam. To jeden z najpopularniejszych profili wybieranych przez organizacje. Biura tego typu raczej nie wprowadzają zbędnego zamieszania za pomocą rewolucyjnych rozwiązań w zakresie aranżacji przestrzeni i wystroju, jednakże często inwestują w nowe technologie i meble, tak aby stworzyć przyjazną i produktywną przestrzeń. Dzięki zachowaniu równowagi pomiędzy przestrzenią indywidualną a wspólną pracownicy spędzają większą część swojego dnia pracy przy biurkach, okazjonalnie biorąc udział spotkaniach lub sesjach zespołowych. Idealne miejsce pracy od 9.00 do 17.00.',
  whiteGloveDesc: 'Wejdź do lobby biura „Mobilizator”, a poczujesz się, jakbyś naprawdę osiągnął w życiu sukces. To miejsce pracy zaspokaja wszystkie potrzeby zarówno pracowników, jak i klientów, którzy są częstymi gośćmi. Indywidualne gabinety i zamknięte przestrzenie do pracy są tu zjawiskiem bardziej powszechnym niż w jakimkolwiek innym środowisku pracy. Dla większości pracowników ich własna przestrzeń do pracy jest nagrodą za wydajność lub staż, a hierarchia prawdopodobnie odgrywa ważną rolę w funkcjonowaniu całej organizacji.',
  openOfficeDesc: 'Biuro „Realizator” dokładnie wie, czego potrzebujesz, aby odnieść sukces. Chodzi o to, aby zapewnić pracownikom wszystkie rodzaje przestrzeni, konieczne do realizacji wszelkich działań podejmowanych w biurze. Te różnorodne typy przestrzeni są tu nie tylko dostępne, ale też skutecznie wykorzystywane w pełnym zakresie. To najlepszy sposób na wydajność! Ten profil biura to idealna równowaga pomiędzy pracą indywidualną a zespołową, co prawdopodobnie oznacza, że ​​w ciągu dnia pracownicy często przemieszczają się w ramach biura. To najlepsze rozwiązanie dla dynamicznej organizacji!',
  workplace360Desc: 'Podobnie jak w jodze, w biurze „Integrator” najważniejsza jest równowaga. Dzięki odpowiedniemu połączeniu – przestrzeni ukierunkowanej na produktywność i dbającej o relacje społeczne – ten profil zapewnia wszystkie niezbędne elementy do skupienia i pracy w ciszy. Jednocześnie zachęca do korzystania z wielu innych przestrzeni. Jak sama nazwa wskazuje, w biurze „Integrator” przestrzenie wspólne, takie jak kuchnie i otwarte miejsca spotkań, są niezbędne dla odpowiedniego funkcjonowania całości. Zbliża pracowników i pomaga tworzyć poczucie wspólnoty.',
  campusDesc: 'Biuro „Wyróżnik” sprawia, że na koniec dnia nie chce się z niego wychodzić. Tutaj nadrzędnym celem jest stworzenie wyjątkowej atmosfery. Znajdziesz w nim niepowtarzalne przestrzenie wellness i udogodnienia kuchenno-jadalne, które pozwalają pracownikom zaoszczędzić czas i sprawiają, że czują się  komfortowo. Czy ktoś wspomniał o przekąskach?! Pracowników wręcz zachęca się do przerw, spotkań towarzyskich i relaksu w miejscu pracy.',
  startupDesc: 'Jeśli chodzi o biuro „Integrator”, liczy się szybkość i pomysłowość. Ludzie pracują w ściśle współpracujących zespołach, a ich postawę wyróżnia odpowiedzialność za wynik i dbałość o każdy szczegół procesu.To środowisko biurowe nieustannie się zmienia, co oznacza, że większość przestrzeni funkcjonuje w oparciu o wbudowaną w nie elastyczność. Przenoszenie mebli oraz tworzenie tymczasowych i prostych rozwiązań to zjawisko powszechne. Połączenie rozrywki i intensywnych sesji pracowniczych, takich jak ping-pong oraz hackathony, pozwala na zbudowanie prawdziwej wspólnoty i odpowiedzialność zbiorową za wykonywaną pracę.',
  engCreativeDesc: 'Dla nas „Kreator” jest biurem pełnym prawdziwych ekspertów – to nie jest typowe połączenie pojedynczych działów. Podobnie jak w przypadku wszystkich wybitnych działań twórczych w tym biurze współpraca i praca zespołowa są normą. Specjalistyczny sprzęt i materiały są prawdopodobnie częścią codziennej pracy. Pracownicy otrzymują przestrzeń, której potrzebują do efektywnego realizacji swoich zadań, i chociaż jest to rzadszy typ biura, to przecież wyjątkowa praca wymaga wyjątkowego miejsca.',


  // Profile Key Traits: Shared Names (profileTraitMap.js)
  social: 'Społeczne',
  efficient: 'Wydajne',
  flexible: 'Elastyczne',
  enabled: 'Postępowe',
  productive: 'Produktywne',
  inclusive: 'Integracyjne',
  clientFocused: 'Ukierunkowane na klienta',
  standardized: 'Ustandaryzowane',

  // Profile Key Traits (profileTraitMap.js)
  social1Desc: 'Relacje, zarówno te w biurze, jak i poza nim, są ważne',
  social2Desc: 'Organizowane jest wiele wydarzeń budujących więzi społeczne',
  social3Desc: 'Pracownicy lubią wspólnie spędzać czas',
  social4Desc: 'Bardziej otwarte, tu nacisk kładziony jest na społeczność',
  efficient1Desc: 'Nacisk na oszczędności i realizację budżetu',
  efficient2Desc: 'Nacisk na oszczędności i produktywność',
  flexible1Desc: 'Pracownicy mają wybór co do stylu pracy',
  flexible2Desc: 'Pracownicy mają dużą swobodę',
  flexible3Desc: 'Stwórz przestrzeń spełniającą Twoje wymagania',
  enabled1Desc: 'Pracownicy mają przy swoich biurkach wszystko, czego mogą potrzebować',
  enabled2Desc: 'Pracownicy mają dostęp do szerokiej gamy usług i udogodnień',
  enabled3Desc: 'Pracownicy mają wszystko, co jest im potrzebne do wykonywania swojej pracy',
  enabled4Desc: 'Zapewniony dostęp do odpowiedniej technologii i sprzętu',
  enabled5Desc: 'Dostęp do specjalistycznego sprzętu',
  productive1Desc: 'Funkcjonalna i intuicyjna aranżacja biura',
  productive2Desc: 'Zorientowanie na potrzeby organizacji i kwestie finansowe',
  productive3Desc: 'Wyjątkowa oferta produktów/usług jest tu najważniejsza',
  inclusive1Desc: 'Dostępność wielu typów przestrzeni w ramach jednej powierzchni',
  inclusive2Desc: 'To biuro oferuje wszystko, czego pracownik może potrzebować',
  inclusive3Desc: 'Tu akceptuje się różne style pracy',
  clientFocused1Desc: 'Pierwsze wrażenie klienta jest najważniejsze',
  clientFocused2Desc: 'Biuro jest przyjazne dla klientów i nowych pracowników',
  clientFocused3Desc: 'Biuro jest dopracowane w każdym calu pod kątem potrzeb klientów i nowych pracowników ',
  standardized1Desc: 'Usprawnione procesy i systemy',
  standardized2Desc: 'Normy organizacyjne dotyczące przeznaczenia przestrzeni',
  standardized3Desc: 'Modułowa elastyczność',
  standardized4Desc: 'Usprawnione procesy i wyniki',

  // SpaceSummary
  spaceSummaryBarHeader: 'Zalecana wielkość biura',
  spaceSummaryMeasurementTypeText: 'rodzaj pomiaru',
  spaceSummaryUnitTypeText: 'jednostki',
  spaceSummaryLossFactorSectionLabel: '{{factor}}',
  spaceSummaryFitFactorText: 'Współczynnik Dopasowania',
  spaceSummaryLossFactorText: 'Współczynnik Strat',
  spaceSummaryRSFLossFactorHelperText: 'W oparciu o współczynnik strat, który dla nieruchomości w miejscowości {{place}} wynosi {{percentage}}%, zalecamy poszukiwanie biura o powierzchni najmu od {{min}} do {{max}} {{units}}.',
  spaceSummaryNIAFitFactorHelperText: 'W oparciu o współczynnik dopasowania, który dla nieruchomości w {{place}} wynosi {{percentage}}%, zalecamy poszukiwanie biura o powierzchni wewnętrznej netto wynoszącej {{amt}}.',
  spaceSummaryNIATooltipText: 'Powierzchnia wewnętrzna netto (PWN, ang. Net Internal Area) to powierzchnia użytkowa w budynku mierzona po wewnętrznym obwodzie ścian zewnętrznych dla każdej kondygnacji, z wyłączeniem pewnych określnych elementów w niej zawartych.',

  // SFTypeRadioInput
  sfRadioInputUsableAreaTooltipText: 'Powierzchnia ta obejmuje wszystkie pomieszczenia, meble i przestrzeń komunikacyjną. Czasami określa się ją mianem powierzchni dywanowej.',
  sfRadioInputRentableAreaTooltipText: 'Jest to powierzchnia użytkowa wyrażona w {{unitUppercase}} oraz część powierzchni wspólnych budynku. Chociaż nie masz możliwości korzystania z niej w całości, jest to przestrzeń, za którą płacisz poczas najmu.',
  sfRadioInputUsableSquareText: 'Powierzchnia Użytkowa (PU)',
  sfRadioInputRentableSquareText: 'Powierzchnia Najmu (PN)',

  //floored plan request block
  flooredTitle: 'Nie opuszczaj nas jeszcze!',
  flooredSubTitle: 'Spraw, aby klienci na nowo odkryli przestrzeń.',
  flooredSubText: 'Sprawdź uzyskane wyniki w formie rzutu powierzchni i obejrzyj je w 3D!',
  flooredParagraphText1: 'Spacer pokazuje najemcom różne możliwości i pozwala eksperymentować z przestrzenią. Pomaga przekształcić pomysły w rzeczywistość.',
  flooredParagraphText2: 'Konkretna wizja dostosowana do potrzeb klienta i każdego rodzaju przestrzeni.',
  flooredVideoHeading: 'Zobacz przykładową prezentację',
  flooredFreeText: 'Nic za to nie płacisz!',
  flooredIconText1: 'Udostępnij rzuty',
  flooredIconSubText1: 'Konwersja plików AutoCAD do pdf',
  flooredIconText2: 'Uwzględnij czas realizacji',
  flooredIconSubText2: 'Od jednego do dwóch tygodni',
  flooredIconText3: 'Podziel się doświadczeniem z innymi',
  flooredIconSubText3: 'Spersonalizowane rzuty w wersji 2D i 3D',


  // MeasurementSystemRadioInput
  measurementSystemSqFeetText: 'ft²',
  measurementSystemSqMetersText: 'm²',
  measurementSystemTsuboText: 'Tsubo',

  // ProfileHero
  resultsProfileHeroAnnouncementTitle: 'Twoje biuro to',
  resultsProfileHeroImgAlt: 'Rzut w wersji 3D przedstawiający układ przestrzeni biurowej wygenerowany dla profilu {{profileName}} w aplikacji CBRE Spacer',

  // ProfileComparison
  profileCompModalTitle: 'Chcesz wprowadzić zmiany w swoim profilu?',
  profileCompModalText: 'Utracisz wszystkie zmiany niezapisane jeszcze w aktualnym projekcie przestrzeni.',
  profileCompModalConfirmText: 'Tak, chcę wprowadzić zmiany w moim profilu',
  profileCompModalCancelText: 'Nie, zachowaj mój aktualny profil',
  profileCompNavbarCancelButtonText: 'Anuluj',
  profileCompNavbarUseButtonText: 'Użyj tego profilu',
  profileCompCompareTitle: 'Porównaj profile, które w największym stopniu spełniają określone przez Ciebie kryteria',

  // ProfileComparisonColumn
  profileCompColumnSelectedText: 'Wybrany profil',
  profileCompColumnSelectText: 'Wybierz',
  profileCompColumnRecommendedText: ' (Zalecane)',
  profileCompColumnImgAltText: 'Rzut w wersji 3D przedstawiający układ przestrzeni biurowej wygenerowany dla profilu {{profileName}} w aplikacji CBRE Spacer',

  // Test Fit Form
  testFitTitle: 'Wygeneruj zestawienie Twoich wyników w formie rzutu i obejrzyj je w wersji 3D',
  testFitSubtitle: 'Chcesz się dowiedzieć, co dla Ciebie zrobimy w ramach tego programu? Przekształcimy Twoje wyniki w niestandardowy plan piętra, który możesz przeglądać i udostępniać.',
  requestTestFitButtonText: 'Wygeneruj spersonalizowany rzut',
  company: 'Nazwa firmy',
  emptyCompanyError: 'Proszę wprowadzić nazwę firmy',
  radioQ2yes: 'Świetnie! Plik wyślemy na otrzymany od Ciebie adres mailowy.',
  radioQ2no: 'Żaden problem, spotkajmy się i porozmawiajmy o Twoich opcjach.',
  testFitFormTitle: 'Wygeneruj spersonalizowany rzut',
  testFitFormSubtitle: 'Podziel się z nami kilkoma informacjami, a my zaczniemy. Nasz zespół zazwyczaj dostarcza gotowe plany pięter w ciągu 3 dni roboczych.',
  testFitFormCompleted: 'Twoja prośba została wysłana. Jeden z naszych przedstawicieli skontaktuje się z Tobą za pośrednictwem poczty elektronicznej.',
  testFitFormYes: 'tak',
  testFitFormNo: 'nie',
  testFitFormQ1: 'Czy dysponujesz rzutem powierzchni, na którym moglibyśmy oprzeć Twój projekt?',
  testFitFormQ1No: 'Nie ma problemu! Udostępnimy Ci go.',
  testFitFormQ2: 'Czy dysponujesz rzutem powierzchni w formacie PDF, AutoCAD lub innym?',
  testFitTermsConditions: 'Zaznaczając to pole, wyrażasz zgodę na udział w tym programie wersji beta. W zamian za udział otrzymasz jeden bezpłatny plan piętra w krótkiej ankiecie na temat Twoich wrażeń.',
  testFitSubmitButtonText: 'Wyślij prośbę o rzut powierzchni',
  backendError: 'Ups! Coś poszło nie tak. Proszę spróbować ponownie.'
};
