const React              = require('react');
const PropTypes          = require('prop-types');
const HeroSection        = require('../../common/HeroSection.jsx');
const { withTranslation } = require('react-i18next');

function TestFit(props) {
  return (
    <div className="test-fit">
      <HeroSection>
        <div className="columns">
          <div className="column is-one-half">
            <div className="test-fit-info">
              <h2 className="title">{props.t('testFitTitle')}</h2>
              <div className="subtitle">{props.t('testFitSubtitle')}</div>
              <button className="button cta-button is-primary-filled" onClick={props.openTestFitForm}>{props.t('requestTestFitButtonText')}</button>
            </div>
          </div>
          <div className="column">
            <img src="/png/test-fit-example.png" alt="" className="test-fit-example-pair is-hidden-mobile" />
          </div>
        </div>
      </HeroSection>
    </div>
  );
}

TestFit.propTypes = {
  openTestFitForm: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(TestFit);
