const actions             = require('../../actions/spaceData');
const programCalculator   = require('../../../calculators/programCalculator');
const profileCalculator   = require('../../../calculators/profileCalculator');
const SPACE_UNIT_CATEGORY = require('wp-constants').shared.spaceUnit.CATEGORY;
const _                   = require('lodash');

module.exports = function fullStateReducer(state = {}, action) {
  switch (action.type) {
    case actions.SET_PROFILE_AND_CALCULATE_PROGRAM: {
      const profileScores = state.profileQuiz.profileScores;
      const profileId = action.profileId || _.maxBy(Object.keys(profileScores), profId => profileScores[profId]);
      const profileType = profileCalculator.calculateProfileType(state.userData.officeLocation, state.spaceData.assumptions.headcount, state.profileQuiz.userQuizAnswers);
      const program = programCalculator.calculateProgram(profileId, profileType, state.spaceData.assumptions, [], state.userData.officeLocation);

      const spaceData = _.assign({}, state.spaceData, {
        profileId,
        profileType,
        program
      });
      return Object.assign({}, state, { spaceData });
    }

    case actions.UPDATE_KEY_ASSUMPTION: {
      const assumptions = _.assign({}, state.spaceData.assumptions, { [action.assumptionType]: action.value });
      const profileType = profileCalculator.calculateProfileType(state.userData.officeLocation, assumptions.headcount, state.profileQuiz.userQuizAnswers);

      // if there were custom Amenities, add those back in after
      const customAmenitiesAdded = _.cloneDeep(state.spaceData.program[SPACE_UNIT_CATEGORY.CUSTOM_AMENITY]);
      const program = programCalculator.calculateProgram(state.spaceData.profileId, profileType, assumptions, customAmenitiesAdded, state.userData.officeLocation);
      const spaceData = _.assign({}, state.spaceData, {
        assumptions,
        profileType,
        program
      });
      return Object.assign({}, state, { spaceData });
    }

    default: {
      return state;
    }
  }
};
