/* global fetch */

const React                           = require('react');
const PropTypes                       = require('prop-types');
const AutocompleteInput               = require('../form/AutocompleteInput.jsx');


// ArcGIS Geocoding Suggest Documentation: https://developers.arcgis.com/rest/geocode/api-reference/geocoding-suggest.htm
// the following URL includes params to
//   - narrow search to City
//   - return results in JSON format
const GEOCODE_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?category=City&f=json';

function getGeocodeUrlWithUserInput(input) {
  const encodedInput = encodeURI(input);
  return `${GEOCODE_URL}&text=${encodedInput}`;
}

function LocationAutocompleteInput(props) {
  function getAutocompleteOptions(value, callback) {
    const urlWithUserInput = getGeocodeUrlWithUserInput(value);
    fetch(urlWithUserInput, {
      cors: true,
      headers: {
        Accept: 'application/json'
      }
    }).then(
      (response) => {
        response.json().then(
          (jsonResponse) => {
            const dropdownOptions = {};
            const optionOrder = [];
            jsonResponse.suggestions.forEach((locationSuggestion) => {
              const locationName = locationSuggestion.text;
              dropdownOptions[locationName] = { text: locationName };
              optionOrder.push(locationName);
            });
            callback({ dropdownOptions, optionOrder });
          });
      },
      (error) => {
        // if there is an error reaching the geocode API, set the location to whatever the user has input
        console.log('Error reaching ArcGis API:', error);
        props.onValueChange(value);
      }
    );
  }

  return (
    <AutocompleteInput
      {...props}
      getAutocompleteOptions={getAutocompleteOptions}
      minSearchLength={3}
    />
  );
}

LocationAutocompleteInput.propTypes = {
  onValueChange: PropTypes.func.isRequired
};

module.exports = LocationAutocompleteInput;
