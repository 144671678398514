const React                    = require('react');
const PropTypes                = require('prop-types');
const constants                = require('wp-constants').spacerFree;
const SPACE_UNIT_CATEGORY      = require('wp-constants').shared.spaceUnit.CATEGORY;
const SpaceUnits               = require('./SpaceUnits.jsx');
const numericDisplay           = require('../../../../util/numericDisplay');
const unitsUtil                = require('../../../../../shared/util/units');
const { dashOrValue }          = require('../../../../../shared/util/display');
const spaceCategoryDescription = require('../../../../../../../copy/spaceCategoryDescription');
const _                        = require('lodash');
const programDataRetriever     = require('../../../../util/programDataRetriever');
const DeltaLabel               = require('../DeltaLabel.jsx');
const spacerFreePropTypes      = require('../../../spacerFreePropTypes');
const { withTranslation }       = require('react-i18next');

class SpaceCategoryBreakdownMobile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      spaceCategorySectionOpen: false
    };

    this.toggleSpaceCategorySection = this.toggleSpaceCategorySection.bind(this);
  }

  toggleSpaceCategorySection() {
    this.setState(prevState => ({
      spaceCategorySectionOpen: !prevState.spaceCategorySectionOpen
    }));
  }

  render() {
    const sectionTitle = {
      [SPACE_UNIT_CATEGORY.ME]: this.props.t('spaceCatBreakdownMobileMeTitle'),
      [SPACE_UNIT_CATEGORY.WE]: this.props.t('spaceCatBreakdownMobileWeTitle'),
      [SPACE_UNIT_CATEGORY.AMENITY]: this.props.t('spaceCatBreakdownMobileAmenityTitle')
    };

    const sectionSubtitle = {
      [SPACE_UNIT_CATEGORY.ME]: this.props.t('spaceCatBreakdownMobileMeSubtitle'),
      [SPACE_UNIT_CATEGORY.WE]: this.props.t('spaceCatBreakdownMobileWeSubtitle'),
      [SPACE_UNIT_CATEGORY.AMENITY]: this.props.t('spaceCatBreakdownMobileAmenitySubtitle')
    };

    const sectionContent = {
      [SPACE_UNIT_CATEGORY.ME]: this.props.t(spaceCategoryDescription.ME),
      [SPACE_UNIT_CATEGORY.WE]: this.props.t(spaceCategoryDescription.WE),
      [SPACE_UNIT_CATEGORY.AMENITY]: this.props.t(spaceCategoryDescription.AMENITY)
    };


    const sectionTitleClasses = `is-sans-semibold is-half-spaced has-text-${this.props.spaceUnitCategory} has-padding-7-mobile has-no-padding-bottom-mobile`;
    const areaPercentage = dashOrValue(!(this.props.categoryAreaForContext && this.props.totalAreaForContext), numericDisplay.decimalToPercentage(this.props.categoryAreaForContext / this.props.totalAreaForContext));

    const formattedCategoryArea = dashOrValue(!this.props.categoryAreaForContext, unitsUtil.getNumberForMeasurementSystem(this.props.categoryAreaForContext, this.props.measurementSystem));

    const categoryNSF = _.reduce(this.props.programForCategory, (runningTotal, unit) => runningTotal + (programDataRetriever.getNSF(unit)), 0);
    const circulationTotal = this.props.categoryArea - categoryNSF;
    const circulationTotalWithCommas = dashOrValue(!circulationTotal, numericDisplay.numberWithLocaleFormat(circulationTotal));

    let totalTitleText;
    const unitCapitalized = _.capitalize(this.props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('feet', this.props.measurementSystem)}`));
    if (this.props.sfType === constants.calculator.SF_TYPE.NIA) {
      totalTitleText = this.props.t('spaceCatBreakdownMobileNIATotalTitle');
    } else {
      totalTitleText = this.props.t('spaceCatBreakdownMobileTotalTitle', { sfType: this.props.sfType === constants.calculator.SF_TYPE.USF ? this.props.t('common:Usable') : this.props.t('common:Rentable'), unitCapitalized });
    }

    const categoryTotalAreaForContext = unitsUtil.getNumberForMeasurementSystem(this.props.categoryAreaForContext, this.props.measurementSystem, { precisionForMeter: 1, precisionForTsubo: 1 });
    const totalAreaWithCommas = dashOrValue(!categoryTotalAreaForContext, numericDisplay.numberWithLocaleFormat(categoryTotalAreaForContext));

    const unitCopyBasis = constants.calculator.SF_TYPE_TEXT[this.props.sfType];
    const measurementLabelForTotal = this.props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem(unitCopyBasis, this.props.measurementSystem)}`);

    return (
      <div className="space-category-mobile-summary has-margin-top-6 is-hidden-print">
        <div className="flex flex-row">
          <div className="pull-start">
            <div className={sectionTitleClasses}>{areaPercentage} {sectionTitle[this.props.spaceUnitCategory]}</div>
            <div className="is-sans-regular has-padding-7-mobile has-no-padding-top-mobile">{sectionSubtitle[this.props.spaceUnitCategory]} - {numericDisplay.numberWithLocaleFormat(formattedCategoryArea)} {this.props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('sqft.', this.props.measurementSystem)}`)}</div>
          </div>
          <button
            className={`space-category-button space-category-button-${this.props.spaceUnitCategory}`}
            onClick={this.toggleSpaceCategorySection}
          />
        </div>
        {
          this.state.spaceCategorySectionOpen &&
          <div>
            <div className="is-sans-regular has-padding-7-mobile space-category-section-content">
              {sectionContent[this.props.spaceUnitCategory]}
            </div>
            <SpaceUnits
              units={this.props.programForCategory}
              spaceUnitCategory={this.props.spaceUnitCategory}
            />
            <div className="columns is-mobile is-multiline has-margin-0-auto space-category-breakdown-row">
              <div className="mobile-assumption-separator is-mobile-input has-margin-top-6" />
              <div className="column is-8 has-no-padding-bottom space-category-breakdown-row-name">
                <span className="name small-title">{this.props.t('spaceCatBreakdownMobileCirculationText')}</span>
              </div>

              <div className={`column is-1 has-text-${this.props.spaceUnitCategory} has-text-centered space-category-quantity is-sans-regular small-title total-unit`}>=</div>
              <div className={`column is-sans-regular small-title has-no-padding-left has-no-padding-right has-text-${this.props.spaceUnitCategory}`}>
                {circulationTotalWithCommas} {this.props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('SF', this.props.measurementSystem)}`)}
              </div>
            </div>
            <div className="columns is-mobile is-multiline has-margin-0-auto space-category-breakdown-row">
              <div className="mobile-assumption-separator is-mobile-input has-margin-top-6" />
              <div className="column is-8 has-no-padding-bottom space-category-breakdown-row-name">
                <span className="name small-title">{totalTitleText}</span>
              </div>
              <div className={`column is-1 has-text-${this.props.spaceUnitCategory} has-text-centered space-category-quantity is-sans-regular small-title total-unit`}>=</div>
              <div className="column">
                <div className={`is-sans-regular small-title has-no-padding-left has-no-padding-right has-text-${this.props.spaceUnitCategory}`}>
                  {totalAreaWithCommas} {measurementLabelForTotal}
                </div>
                <DeltaLabel delta={this.props.totalAreaDelta} measurementSystem={this.props.measurementSystem} />
              </div>
              <div className="mobile-assumption-separator is-invisible is-mobile-input has-margin-top-6" />
            </div>
          </div>
        }
      </div>
    );
  }
}

SpaceCategoryBreakdownMobile.propTypes = {
  spaceUnitCategory: PropTypes.string.isRequired,
  categoryArea: PropTypes.number.isRequired,
  categoryAreaForContext: PropTypes.number.isRequired,
  totalAreaForContext: PropTypes.number.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  programForCategory: PropTypes.arrayOf(PropTypes.object).isRequired,
  sfType: PropTypes.number.isRequired,
  totalAreaDelta: spacerFreePropTypes.deltaShape,
  t: PropTypes.func.isRequired
};

SpaceCategoryBreakdownMobile.defaultProps = {
  totalAreaDelta: null
};

module.exports = withTranslation('resultsPage')(SpaceCategoryBreakdownMobile);
