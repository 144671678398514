module.exports = {
  referralStep: 'Krok {{step}} z 4',
  forgotReferralLink: 'Zapomniałeś swojego linku polecającego?',
  howReferralLinksWorkTitle: 'Jak działają linki polecające?',
  enterYourInformation: 'Wprowadź swoje dane',
  confirmYourInformation: 'Potwierdź swoje dane',
  referralLinkStep1: 'Do wygenerowania linku polecającego potrzebujemy:',
  referralLinkReq1: '- Twojego imienia i nazwiska',
  referralLinkReq2: '- Twojego adresu e-mail przypisanego Ci przez CBRE',
  referralLinkStep2: 'Po wypełnieniu kwestionariusza przez potencjalnego klienta przy wykorzystaniu Twojego linku polecającego prześlemy Ci podsumowanie jego odpowiedzi na podany adres e-mail.',
  next: 'Dalej',
  firstName: 'Imię',
  lastName: 'Nazwisko',
  emptyNameError: 'Proszę wprowadzić imię / Proszę wprowadzić nazwisko',
  email: 'Twój adres e-mail przypisany Ci przez CBRE',
  emptyEmailError: 'Proszę wprowadzić adres e-mail przypisany Ci przez CBRE',
  emailTakenError: 'Podany adres e-mail jest już powiązany z {{takenLink}}',
  emailExample: 'np.: jane.smith@cbre.com',
  editReferralInfo: 'Edytuj wprowadzone imię i nazwisko oraz adres e-mail',
  noLaterChanges: 'Wprowadzonych danych nie będzie można później już zmienić.',
  somethingWrong: 'Ups, coś poszło nie tak po naszej stronie. Proszę spróbować ponownie.',
  contentLoading: 'Ładowanie treści',
  linkReady: 'Twój link jest gotowy',
  storeLinkInstructions: 'Przechowuj wygenerowany link w bezpiecznym miejscu, będziesz z niego korzystać za każdym razem, gdy będziesz chciał udostępnić aplikację Spacer klientom. Kopię linku wysłaliśmy również na podany przez Ciebie adres e-mail.',
  yourLink: 'Twój link polecający',
  copyLink: 'Skopiuj link polecający',
  copiedLink: 'Link został skopiowany do schowka',
  linkNeverExpires: 'Przypomnienie: wygenerowany link nigdy nie wygaśnie.',
  linkFound: 'Znaleźliśmy Twój link',
  emailNotAssociated: 'Nie mogliśmy znaleźć linku polecającego, powiązanego z podanym adresem e-mail.',
  createLink: 'Czy wygenerować link?'
};
