module.exports = {

  // Navbar
  spacerLogoAlt: 'Spacer by CBRE',
  linkToAboutPageText: 'Over',
  linkToBlogPageText: 'Inzichten',
  referrals: 'Doorverwijzingen',

  // TakeOfficeSpaceQuizButton
  sizeMyOfficeButtonDefaultLinkText: 'Beantwoord de vragen over kantoorruimte',
  flooredPlanRequestLinkText: 'Een plattegrond aanvragen',

  // Footer
  footerCBRELogoAlt: 'CBRE-logo voor Spacer by CBRE',
  footerFacebookIconAlt: 'Pictogram Facebook voor Spacer by CBRE',
  footerTwitterIconAlt: 'Pictogram Twitter voor Spacer by CBRE',
  footerLinkedinIconAlt: 'Pictogram LinkedIn voor Spacer by CBRE',
  footerHeaderQuestionsText: 'Vragen?',
  footerHeaderAboutText: 'Informatiemateriaal',
  footerHeaderMoreText: 'Meer',
  footerHeaderBrokersText: 'Voor makelaars',
  footerLinkWhatIsSpacerText: 'Over',
  footerLinkBlogText: 'Inzichten',
  footerLinkWorkplaceText: 'Het team van CBRE Workplace',
  footerLinkTermsText: 'Servicevoorwaarden',
  footerLinkPrivacyText: 'Privacybeleid',
  footerLinkedinLink: 'https://www.linkedin.com/showcase/cbre-workplace-strategy/',
  footerLinkReferralText: 'Doorverwijzingen',
  footerWorkplaceLink: 'https://www.cbre.nl/diensten/huisvesting-en-locatiekeuze/werkplek-concept-ontwikkelen',
  footerPrivacyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Contact (contact.js)
  spacerSupportEmail: 'spacersupport@cbre.com',

  // ErrorPage
  errorOopsText: 'Oeps!',
  error404Text: 'We kunnen de gezochte pagina niet vinden.',
  error500Text: 'Er is bij ons iets misgegaan.',
  errorText: 'Fout',
  errorReturnText: 'Terug naar Spacer',
  errorAltText: 'Illustratie van een kopje met gemorste koffie voor Spacer by CBRE',

  // Measurement Units (units.js)
  SF: 'SF',
  SM: 'm2',
  USF: 'USF',
  USM: 'FNO',
  RSF: 'RSF',
  RSM: 'VVO',
  NSF: 'NSF',
  NSM: 'NVM',
  NIA: 'NIA',
  foot: 'foot',
  feet: 'feet',
  footage: 'lengte in feet',
  'sqft.': 'sqft.',
  meter: 'meter',
  meters: 'meter',
  'sqm.': 'm2',
  Tsubo: 'tsubo',

  // SF Types Adjectives (calculator.js)
  Usable: 'Bruikbaar',
  Rentable: 'Verhuurbaar',
  Net: 'Netto',

  // Page Titles (documentDataCopy.js)
  landingPageTitle: 'Plan je perfecte kantoor in 15 minuten – Spacer by CBRE',
  aboutPageTitle: 'Ontdek hoe onze meettool voor kantoren werkt – Spacer by CBRE',
  termsPageTitle: 'Servicevoorwaarden – Spacer by CBRE',
  quizPageTitle: 'Meet je kantoor in 15 minuten – Spacer by CBRE',
  resultsPageTitle: 'Bekijk onze kantooraanbevelingen op maat – Spacer by CBRE',
  profileComparisonPageTitle: 'Vergelijk je kantoorbehoeften – Spacer by CBRE',
  blogHomePageTitle: 'Inzichten kantoorplanning – Spacer by CBRE',
  referralPageTitle: 'Doorverwijzingslink voor CBRE-makelaars – Spacer by CBRE',
  referralRetrievePageTitle: 'Doorverwijzingslink voor CBRE-makelaars ophalen – Spacer by CBRE',

  // Meta Tags (documentDataCopy.js)
  landingPageMetaTagText: 'Aan de slag met het perfecte kantoor of een toekomstbestendige hybride werkplekstrategie? Met Spacer van CBRE maak je in nog geen 15 minuten een vliegende start!',
  aboutPageMetaTagText: 'Je kantoor is een belangrijke voorwaarde voor talenten werven, kosten besparen en effectief hybride werken. Met Spacer ontdek je hoe jouw ideale kantoor eruitziet – en hier ontdek je hoe dat werkt.',
  termsPageMetaTagText: 'Meer dan tien jaar expertise en ervaring in de branche komen samen in Spacer by CBRE, een service die in een paar minuten deskundige aanbevelingen doet voor de grootte van je kantoor.',
  quizPageMetaTagText: 'Beantwoord de vragen van Spacer, dan weet je in 15 minuten hoeveel kantoorruimte je nodig hebt om de doelen en prioriteiten van je onderneming te ondersteunen. Een programma op maat was nog nooit zo snel.',
  resultsPageMetaTagText: 'De ruimteadviezen op maat van Spacer besparen je tijd bij het zoeken naar kantoren, want ze vertellen je precies hoeveel kantoorruimte je bedrijf nodig heeft, compleet met voorstellen voor typen meubels en indelingen.',
  profileComparisonPageMetaTagText: 'Krijg inzicht in hoe jouw unieke kantoorbehoeften zich verhouden tot die van anderen door Spacer by CBRE te gebruiken',
  blogHomePageMetaTagText: 'Berekenen hoeveel kantoorruimte een onderneming nodig heeft is geen exacte wetenschap, maar deze inzichten en informatiematerialen over kantoorplanning zullen zeker helpen. Meer informatie vind je hier.',
  referralPageMetaTagText: 'Gebruik deze doorverwijzingslink om Spacer naar klanten te sturen. Je ontvangt dan hun ruimteadviezen rechtstreeks via e-mail. Meer informatie vind je hier.',
  referralRetrieveMetaTagText: 'Haal je doorverwijzingslink voor Spacer op.',

  // Form title
  formLabel: 'Jouw informatie'
};
