module.exports = {

  // AboutPageHero
  aboutPageHeroImgAlt: 'Illustration of people moving into an office on the About page of Spacer by CBRE',
  aboutPageHeroTitle: 'What can Spacer do for you?',

  // AboutPageContent
  aboutPageContentEndSectionTitle: 'Ready to get started?',
  aboutPageProgramPreviewImgAlt: 'Preview of a results page space program from the homepage of Spacer by CBRE',
  aboutPageVideoTitle: 'How it works',


  // About Page Content (aboutPageContent.js)
  aboutPageContent1Title: 'Define the perfect office',
  aboutPageContent1Text: 'The perfect office space can help you hire competitive talent, keep your employees healthy, reduce operating costs, or create conditions for the next big idea. Spacer is the perfect place to begin any real estate decision because it helps you prioritize your goals as an organization, and see space recommendations based on those goals.\n Spacer was created by taking the expertise of CBRE’s Workplace team, a group with over twenty years of experience helping companies plan the perfect office, and combining it with a free, internet-based technology that is available to anyone.',
  aboutPageContent1ImgAlt: 'Illustration of people talking on the About page of Spacer by CBRE',
  aboutPageSpacerSupportLink: 'spacersupport@cbre.com',

  aboutPageContent2Title: 'Spacer is easy to use:',
  aboutPageContent2List1: 'Start by answering a 17-question quiz about your organizational goals and values. These answers will inform the office profile we categorize you as.',
  aboutPageContent2List2: 'Receive a recommended list of space types and sizes that meet your unique needs, also known as a space program.',
  aboutPageContent2List3: 'Visualize a space similar to yours in 2D and 3D with Floored Plans.',
  aboutPageContent2List4: 'Save your program to use as you search for the perfect office on the market.',
  aboutPageContent2List5: 'Let a CBRE professional hunt down an office space that matches your program, a service that is free of charge!',
  aboutPageContent2Text: 'For more questions about how Spacer can jump start your office search process, contact us at ',
  aboutPageContent2ImgAlt: 'Illustration of a girl working at a desk on the About page of Spacer by CBRE'
};
