const React              = require('react');
const PropTypes          = require('prop-types');
const { withTranslation } = require('react-i18next');
const clientConstants    = require('../../../shared/constants/clientConstants');
const keyCodes           = require('../../../shared/constants/keyCodes');

const inputTypesToValidate = ['email'];

class FormInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false
    };

    this.onChange = this.onChange.bind(this);
    this.inputRef = React.createRef();
  }

  onChange(evt) {
    this.props.onChange(evt.target.value);

    if (inputTypesToValidate.includes(this.props.type)) {
      this.inputRef.current.setCustomValidity('');
      if (!this.inputRef.current.checkValidity()) this.inputRef.current.setCustomValidity(this.props.t('formEmailInvalid'));
    }
  }

  render() {
    let containerClasses = 'underline-input-with-label';
    if (this.props.autoComplete === 'given-name') {
      containerClasses += ' first-name';
    } else if (this.props.autoComplete === 'family-name') {
      containerClasses += ' last-name';
    }

    let labelClasses = 'underline-input-label';
    // on IE, an input's placeholder gets hidden on focus, so we want to make sure the label is visible if the input has focus
    if (!this.props.value && !(clientConstants.IS_IE && this.state.isFocused)) labelClasses += ' is-invisible';

    let placeholder;
    if (this.props.placeholder) {
      placeholder = this.props.placeholder;
    } else {
      placeholder = this.props.label;
      placeholder += (this.props.required) ? ' *' : this.props.t('formInputOptionalText');
    }

    let onKeyDown;
    if (this.props.multipleAllowed) {
      onKeyDown = (evt) => {
        // when entering multiple comma separated things, by default hitting space returns the cursor to the input's start
        if (evt.which === keyCodes.SPACE) evt.preventDefault();
      };
    }

    return (
      <div className={containerClasses}>

        <label className={labelClasses}>{this.props.label}</label>

        <div className="underline-input">
          <input
            type={this.props.type}
            ref={this.inputRef}
            multiple={this.props.multipleAllowed}
            className="input"
            required={this.props.required}
            placeholder={placeholder}
            autoComplete={this.props.autoComplete}
            value={this.props.value}
            onChange={this.onChange}
            onKeyDown={onKeyDown}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => this.setState({ isFocused: false })}
          />
        </div>

      </div>
    );
  }
}

FormInput.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  multipleAllowed: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

FormInput.defaultProps = {
  type: 'text',
  multipleAllowed: false,
  value: '',
  required: false,
  placeholder: '',
  autoComplete: 'off'
};

module.exports = withTranslation('resultsPage')(FormInput);
