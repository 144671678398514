const React              = require('react');
const PropTypes          = require('prop-types');
const sharedPropTypes    = require('../../../shared/components/sharedPropTypes');
const Form               = require('./Form.jsx');
const AcceptTermsText    = require('../common/AcceptTermsText.jsx');
const { withTranslation } = require('react-i18next');

function LegalForm(props) {
  const fields = props.fields.slice(); // don't mutate props
  fields.push({
    type: 'checkbox',
    name: 'termsOfService',
    required: true,
    label: (
      <div className="form-checkbox is-small">
        <AcceptTermsText />
      </div>
    ),
    defaultValue: false
  });

  let contactWarning;
  if (props.isLeadGenForm) {
    if (!props.isCBREEmployee) {
      fields.push({
        name: 'contactOptIn',
        type: 'checkbox',
        label: (
          <div className="form-checkbox is-small">
            {this.props.t('legalFormCheckboxText')}
          </div>
        ),
        defaultValue: false
      });
    } else {
      contactWarning = this.props.t('legalFormContactWarningText');
    }
  }

  return (
    <Form
      {...props}
      fields={fields}
      additionalInfoText={contactWarning}
    />
  );
}

LegalForm.propTypes = {
  fields: sharedPropTypes.formFields.isRequired,
  isLeadGenForm: PropTypes.bool,
  isCBREEmployee: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

LegalForm.defaultProps = {
  isLeadGenForm: false
};

module.exports = withTranslation('resultsPage')(LegalForm);
