const React              = require('react');
const PropTypes          = require('prop-types');
const classnames         = require('classnames');
const BarChartSegment    = require('../../../../shared/components/common/BarChartSegment.jsx');
const domUtil            = require('../../../../shared/util/dom.js');
const { withTranslation } = require('react-i18next');

function BarChart(props) {
  function parsePercentage(percentage) {
    return parseInt(percentage.substring(0, percentage.length - 1), 10);
  }

  const isMobile = domUtil.isScreenMobileSize();
  const isEmpty = !parsePercentage(props.mePercentage) &&
    !parsePercentage(props.wePercentage) &&
    !parsePercentage(props.amenityPercentage);
  const classes = classnames('flex program-bar-chart office-breakdown-barchart', {
    'is-mobile-input-flex': isMobile,
    'is-hidden-mobile': !isMobile,
    'program-bar-chart-empty': isEmpty
  });

  if (isEmpty) {
    return (
      <div className={classes}>
        <p className="small-text">
          Add a headcount to see a space breakdown.
        </p>
      </div>
    );
  }

  const barChartSegmentData = [
    {
      colorClass: 'me',
      percentage: props.mePercentage,
      percentageSubtitle: props.t('barChartIndivSubtitle'),
      isMobileScreen: isMobile
    },
    {
      colorClass: 'we',
      percentage: props.wePercentage,
      percentageSubtitle: props.t('barChartGroupSubtitle'),
      isMobileScreen: isMobile,
      isGroupSpace: true
    },
    {
      colorClass: 'amenity',
      percentage: props.amenityPercentage,
      percentageSubtitle: props.t('barChartAmenitySubtitle'),
      isMobileScreen: isMobile
    }
  ];

  const barChartItems = barChartSegmentData.map(item => (
    <BarChartSegment
      key={item.percentageSubtitle}
      {...item}
    />
  ));

  return <div className={classes}>{barChartItems}</div>;
}

BarChart.propTypes = {
  mePercentage: PropTypes.string.isRequired,
  wePercentage: PropTypes.string.isRequired,
  amenityPercentage: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(BarChart);
