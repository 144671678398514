const actions   = require('../actions/changeDetected');
const _         = require('lodash');

module.exports = function changesMadeToResultsDetails(state = {}, action) {
  switch (action.type) {
    case actions.DETECT_CHANGE_ON_RESULTS: {
      return _.assign({}, state, {
        changesMadeToResultsDetails: true
      });
    }

    case actions.CHANGE_TO_RESULTS_SAVED: {
      return _.assign({}, state, {
        changesMadeToResultsDetails: false
      });
    }

    default: {
      return state;
    }
  }
};
