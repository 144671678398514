module.exports = {
  referralStep: ' Paso {{step}} de 4',
  forgotReferralLink: '¿Olvido su enlace de referencia?',
  howReferralLinksWorkTitle: 'Cómo funcionan los enlaces de referencia',
  enterYourInformation: 'Ingrese su información',
  confirmYourInformation: 'Confirme su información',
  referralLinkStep1: 'Para crear un enlace de referencia necesitaremos:',
  referralLinkReq1: '- Su nombre',
  referralLinkReq2: '- Su dirección de correo electrónico asociada a CBRE',
  referralLinkStep2: 'Una vez un prospecto complete el formulario usando su enlace de referencia, enviaremos una copia de sus resultados a su bandeja de entrada.',
  next: 'Siguiente',
  firstName: 'Nombre',
  lastName: 'Apellido',
  emptyNameError: 'Por favor ingrese un nombre',
  email: 'Su correo electrónico de CBRE',
  emptyEmailError: 'Por favor ingrese un correo de CBRE',
  emailTakenError: 'La cuenta de correo ya está asociada a {{takenLink}}',
  emailExample: 'ej.: jane.smith@cbre.com',
  editReferralInfo: 'Edite la información de nombre y correo ingresada',
  noLaterChanges: 'No podrá cambiar esto después.',
  somethingWrong: 'Ups, ocurrió un error. Por favor trate nuevamente.',
  contentLoading: 'Cargando contenido',
  linkReady: 'Su enlace está listo',
  storeLinkInstructions: 'Guarde este enlace en un lugar seguro, necesitará usarlo cada vez que comparta Spacer con cliente. También hemos enviado una copia de este enlace al correo electrónico registrado.',
  yourLink: 'Su enlace de referencia',
  copyLink: 'Copiar enlace de referencia',
  copiedLink: 'Copiado al portapapeles',
  linkNeverExpires: 'Recordatorio: este enlace no expira nunca.',
  linkFound: 'Encontramos su enlace',
  emailNotAssociated: 'No encontramos un enlace de referencia asociado a esa dirección de correo.',
  createLink: '¿Crear uno?'
};
