const { connect }            = require('react-redux');
const constants              = require('wp-constants').spacerFree;
const programDataRetriever   = require('../../../../util/programDataRetriever');
const regionSFCalculator     = require('../../../../../shared/calculators/regionSFCalculator');

const SpaceSummary            = require('../../../results/profile/SpaceSummary.jsx');


// Map state to props
function mapStateToProps(state) {
  const location = state.userData.officeLocation;
  const sfType = state.spaceData.sfType;
  const lossFactor = state.spaceData.lossFactor;
  const totalArea = programDataRetriever.getTotalSF(state.spaceData.program.areaTotals);

  return {
    location,
    sfType,
    lossFactor,
    measurementSystem: state.spaceData.measurementSystem,
    totalUSF: regionSFCalculator.getAreaForContext(totalArea, constants.calculator.SF_TYPE.USF, location, lossFactor),
    totalRSF: regionSFCalculator.getAreaForContext(totalArea, constants.calculator.SF_TYPE.RSF, location, lossFactor)
  };
}

module.exports = connect(mapStateToProps)(SpaceSummary);
