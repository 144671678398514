const React              = require('react');
const PropTypes          = require('prop-types');
const documentDataCopy   = require('../../../../../copy/documentDataCopy');
const { withTranslation } = require('react-i18next');

class DocumentData extends React.Component {
  componentDidMount() {
    const { titleToken, metaTagToken } = documentDataCopy[this.props.siteLocation];
    document.title = this.props.t(titleToken);
    document.getElementById('dynamic-description').content = this.props.t(metaTagToken);
  }

  render() {
    return null;
  }
}

DocumentData.propTypes = {
  siteLocation: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('common')(DocumentData);
