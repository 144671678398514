module.exports = {
  // referralStep: no translation yet
  // forgotReferralLink: no translation yet
  // howReferralLinksWorkTitle: no translation yet
  // enterYourInformation: no translation yet
  // confirmYourInformation: no translation yet
  // referralLinkStep1: no translation yet
  // referralLinkReq1: no translation yet
  // referralLinkReq2: no translation yet
  // referralLinkStep2: no translation yet
  // next: no translation yet
  // firstName: no translation yet
  // lastName: no translation yet
  // emptyNameError: no translation yet
  // email: no translation yet
  // emptyEmailError: no translation yet
  // emailTakenError: no translation yet
  // emailExample: no translation yet
  // editReferralInfo: no translation yet
  // noLaterChanges: no translation yet
  // somethingWrong: no translation yet
  // contentLoading: no translation yet
  // linkReady: no translation yet
  // storeLinkInstructions: no translation yet
  // linkReady: no translation yet
  // copyLink: no translation yet
  // copiedLink: no translation yet
  // linkNeverExpires: no translation yet
  // linkFound: no translation yet
  // emailNotAssociated: no translation yet
  // createLink: no translation yet
};
