const React              = require('react');
const PropTypes          = require('prop-types');
const { withTranslation } = require('react-i18next');

const constants = require('wp-constants').spacerFree;

function ProgramHeroForPrint(props) {
  const isNIA = props.sfType === constants.calculator.SF_TYPE.NIA;

  const niaContent = (
    <div className="column nsf">
      <div className="nsf-amount title is-4 has-text-primary is-sans-semibold">{props.nsf}</div>
      <div className="label is-sans-regular">{props.t('printNIAText')}</div>
    </div>
  );

  const rsfUsfContent = (
    <div className="columns">
      <div className="column usf">
        <div className="usf-amount title is-4 has-text-primary is-sans-semibold">{props.usf}</div>
        <div className="label is-sans-regular">{props.t('printUSFText')}</div>
      </div>
      <div className="column rsf">
        <div className="rsf-amount title is-4 has-text-primary is-sans-semibold">{props.rsf}</div>
        <div className="label is-sans-regular">{props.t('printRSFText')}</div>
      </div>
    </div>
  );

  const description = props.t('printDescText', { lossFactorPercentage: props.lossFactor, factorType: props.t(isNIA ? 'printFitFactorText' : 'printLossFactorText'), city: props.city });

  return (
    <div className="is-print-only program-hero-for-print section">
      <div className="container">
        <div className="columns">
          <div className="column is-3 loss-factor">
            {description}
          </div>
          {isNIA ? niaContent : rsfUsfContent}
        </div>
      </div>
    </div>
  );
}

ProgramHeroForPrint.propTypes = {
  lossFactor: PropTypes.number.isRequired,
  city: PropTypes.string.isRequired,
  usf: PropTypes.string.isRequired,
  rsf: PropTypes.string.isRequired,
  nsf: PropTypes.string.isRequired,
  sfType: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(ProgramHeroForPrint);
