module.exports = {
  referralStep: ' Krok {{step}} 4',
  forgotReferralLink: 'Stratili ste svoj referenčný odkaz?',
  howReferralLinksWorkTitle: 'Ako funguje referenčný odkaz?',
  enterYourInformation: 'Uveďte svoje údaje',
  confirmYourInformation: 'Potvrďte svoje údaje',
  referralLinkStep1: 'Na vytvorenie referenčného odkazu je potrebné:',
  referralLinkReq1: '- Vaše meno',
  referralLinkReq2: '- Vašu e-mailovu adresu od CBRE',
  referralLinkStep2: 'Keď záujemca vyplní dotazník prostredníctvom referenčného odkazu, pošleme vám emailom kópiu jeho výsledkov.',
  next: 'Ďalej',
  firstName: 'Krstné meno',
  lastName: 'Priezvisko',
  emptyNameError: 'Prosím, uveďte meno',
  email: 'Vaša e-mailová adresa CBRE',
  emptyEmailError: 'Zadajte e-mail CBRE',
  emailTakenError: 'Táto e-mailová adresa je už prepojená s {{takenLink}}',
  emailExample: 'ex: jane.smith@cbre.com',
  editReferralInfo: 'Upravte zadané meno a e-mailové informácie',
  noLaterChanges: 'Neskôr to už nebudete môcť upravovať.',
  somethingWrong: 'Ups, na našej strane nastala chyba. Skúste to, prosím, neskôr.',
  contentLoading: 'Obsah sa načítava',
  linkReady: 'Váš odkaz je pripravený',
  storeLinkInstructions: 'Uložte si tento odkaz na bezpečné miesto. Odkaz môžete používať pri každom zdieľaní aplikácie Spacer klientom. Kópiu tohto odkazu sme tiež poslali e-mailom na adresu, ktorú ste uviedli.',
  yourLink: 'Vás referenčný odkaz',
  copyLink: 'Kopírovať referenčný odkaz',
  copiedLink: 'Skopírované do schránky',
  linkNeverExpires: 'Pripomienka: Platnosť tohto odkazu nikdy nevyprší.',
  linkFound: 'Našli sme váš odkaz',
  emailNotAssociated: 'Nenašli sme referenčný odkaz spojený s touto e-mailovou adresou.',
  createLink: 'Chcete vytvoriť nový?'
};
