const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const constants              = require('wp-constants').shared;
const spaceDataActions       = require('../../../../redux/actions/spaceData');
const changeDetectedActions  = require('../../../../redux/actions/changeDetected');
const RadioInput             = require('../../../form/RadioInput.jsx');
const locationUtils          = require('../../../../../shared/util/location.js');

// Map state to props
function mapStateToProps(state, ownProps) {
  const optionsList = [
    { text: ownProps.t('measurementSystemSqFeetText'), value: constants.calculator.MEASUREMENT_SYSTEM.IMPERIAL },
    { text: ownProps.t('measurementSystemSqMetersText'), value: constants.calculator.MEASUREMENT_SYSTEM.METRIC }
  ];
  const { country } = locationUtils.getCityStateCountry(state.userData.officeLocation);
  if (locationUtils.isJapan(country)) {
    optionsList.push({ text: ownProps.t('measurementSystemTsuboText'), value: constants.calculator.MEASUREMENT_SYSTEM.TSUBO });
  }
  return {
    value: state.spaceData.measurementSystem,
    name: 'measurementSystem',
    options: optionsList
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  const updateMeasurementSystem = bindActionCreators(spaceDataActions.updateMeasurementSystem, dispatch);
  const detectChangeToSaveOnResults = bindActionCreators(changeDetectedActions.detectChangeToSaveOnResults, dispatch);

  return {
    onChange: (system) => {
      updateMeasurementSystem(system);
      detectChangeToSaveOnResults();
    }
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(RadioInput);

