const React                = require('react');
const PropTypes            = require('prop-types');
const spacerFreePropTypes  = require('../../spacerFreePropTypes.js');

const Question             = require('../../quiz/Question.jsx');

function HeroProfileQuestion(props) {
  return (
    <div className="hero-question">
      <Question
        questionUnit={props.questionUnit}
        questionAnswer={props.questionAnswer}
        onAnswerChange={props.onAnswerChange}
      />
    </div>
  );
}

HeroProfileQuestion.propTypes = {
  questionUnit: spacerFreePropTypes.questionUnitShape.isRequired,
  questionAnswer: spacerFreePropTypes.questionAnswerTypes,
  onAnswerChange: PropTypes.func.isRequired
};

module.exports = HeroProfileQuestion;
