const React                = require('react');
const PropTypes            = require('prop-types');
const KEY_CODES            = require('../../../shared/constants/keyCodes');

const QuizProgressLabel    = require('./QuizProgressLabel.jsx');
const Question             = require('./Question.jsx');
const QuestionGroup        = require('./QuestionGroup.jsx');
const QuizStickyFooter     = require('./QuizStickyFooter.jsx');
const QuizNavigationButton = require('./QuizNavigationButton.jsx');

const quizOrderInfo        = require('wp-data').quizOrderInfo;
const util                 = require('wp-util');
const domUtil              = require('../../../shared/util/dom');

const quizOrder = quizOrderInfo.quizOrder;
const finalQuestionId = quizOrder[quizOrder.length - 1];

function getQuestionAnswerAndIsComplete(props) {
  const questionAnswer = props.getQuestionAnswer(props.questionIndex);
  const isAnswerComplete = util.questionUnit.isQuestionAnswerComplete(props.questionIndex, questionAnswer);
  return { questionAnswer, isAnswerComplete };
}

class QuestionSlide extends React.Component {
  constructor(props) {
    super(props);

    this.onClickInactiveQuestion = this.onClickInactiveQuestion.bind(this);
    this.previousQuestion = this.previousQuestion.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.nextOnEnter = this.nextOnEnter.bind(this);
    this.getQuestionComponent = this.getQuestionComponent.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.nextOnEnter);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.nextOnEnter);
  }

  onClickInactiveQuestion(questionId) {
    this.props.updateCurrentQuestion(quizOrder.indexOf(questionId));
  }

  previousQuestion() {
    const previousQuestionId = util.questionUnit.getQuestionUnit(this.props.questionIndex - 1).id;
    let previousQuestionIndex;
    if ((this.props.skippableQuestionIds.includes(previousQuestionId)) && (this.props.questionIndex >= 2)) {
      previousQuestionIndex = this.props.questionIndex - 2;
    } else {
      previousQuestionIndex = this.props.questionIndex - 1;
    }
    this.props.updateCurrentQuestion(previousQuestionIndex);
  }

  nextOnEnter(evt) {
    if (evt.which === KEY_CODES.ENTER) {
      const { isAnswerComplete } = getQuestionAnswerAndIsComplete(this.props);
      if (isAnswerComplete) {
        this.nextQuestion();
      }
    }
  }

  nextQuestion() {
    if (util.questionUnit.getQuestionUnit(this.props.questionIndex).id === finalQuestionId) {
      this.props.afterFinalQuestionSlide();
    } else {
      const nextQuestionId = util.questionUnit.getQuestionUnit(this.props.questionIndex + 1).id;
      let newQuestionIndex;
      if (this.props.skippableQuestionIds.includes(nextQuestionId)) {
        newQuestionIndex = this.props.questionIndex + 2;
      } else {
        newQuestionIndex = this.props.questionIndex + 1;
      }
      this.props.updateCurrentQuestion(newQuestionIndex);
    }
  }

  getQuestionComponent(questionAnswer, isAnswerComplete) {
    const activeQuestionUnit = util.questionUnit.getQuestionUnit(this.props.questionIndex);
    const shouldGroupQuestions = !domUtil.isScreenMobileSize() && !domUtil.isScreenTabletSize() && util.questionUnit.isGroupedQuestion(activeQuestionUnit);
    if (shouldGroupQuestions) {
      const groupedQuestionUnits = [];
      const groupedQuestionAnswers = {};
      let groupedQuestionUnit;
      let groupedQuestionAnswer;
      const groupedQuestions = quizOrderInfo.groupedQuestions;
      groupedQuestions.forEach((questionUnitId) => {
        if (!(this.props.skippableQuestionIds.indexOf(questionUnitId) > -1)) {
          groupedQuestionUnit = util.questionUnit.getQuestionUnit(quizOrder.indexOf(questionUnitId));
          groupedQuestionUnits.push(groupedQuestionUnit);
          groupedQuestionAnswer = this.props.getQuestionAnswer(quizOrder.indexOf(questionUnitId), true);
          if (groupedQuestionAnswer) groupedQuestionAnswers[groupedQuestionUnit.id] = groupedQuestionAnswer;
        }
      });
      return (
        <QuestionGroup
          questionUnits={groupedQuestionUnits}
          questionAnswers={groupedQuestionAnswers}
          activeQuestionUnitId={activeQuestionUnit.id}
          onAnswerChange={this.props.updateAnswer}
          onClickInactiveQuestion={this.onClickInactiveQuestion}
          isSameOfficeAndPersonalLocation={this.props.isSameOfficeAndPersonalLocation}
          toggleSameOfficeAndPersonalLocation={this.props.toggleSameOfficeAndPersonalLocation}
        />
      );
    }

    return (
      <Question
        questionUnit={activeQuestionUnit}
        questionAnswer={questionAnswer}
        onAnswerChange={this.props.updateAnswer}
        showResponse={isAnswerComplete}
        isSameOfficeAndPersonalLocation={this.props.isSameOfficeAndPersonalLocation}
        toggleSameOfficeAndPersonalLocation={this.props.toggleSameOfficeAndPersonalLocation}
      />
    );
  }

  render() {
    const { questionAnswer, isAnswerComplete } = getQuestionAnswerAndIsComplete(this.props);
    const isFirstQuestion =  this.props.questionIndex === 0;

    return (
      <React.Fragment key={`question${this.props.questionIndex}`}>
        <div className="quiz-question-slide">
          <div className="quiz-question-and-progress-label">
            <QuizProgressLabel
              questionIndex={this.props.questionIndex}
            />
            {this.getQuestionComponent(questionAnswer, isAnswerComplete)}
          </div>
        </div>
        <div className="quiz-navigation-btns-container">
          <QuizNavigationButton
            isBack
            onClick={this.previousQuestion}
            hidden={isFirstQuestion}
          />

          <QuizNavigationButton
            onClick={this.nextQuestion}
            disabled={!isAnswerComplete}
            isPulledLeft={isFirstQuestion}
          />
        </div>
        <QuizStickyFooter
          previousQuestion={this.previousQuestion}
          nextQuestion={this.nextQuestion}
          isFirstQuestion={isFirstQuestion}
          isAnswerComplete={isAnswerComplete}
        />
      </React.Fragment>
    );
  }
}

QuestionSlide.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  updateCurrentQuestion: PropTypes.func.isRequired,
  afterFinalQuestionSlide: PropTypes.func.isRequired,
  updateAnswer: PropTypes.func.isRequired,
  getQuestionAnswer: PropTypes.func.isRequired,
  isSameOfficeAndPersonalLocation: PropTypes.bool.isRequired,
  toggleSameOfficeAndPersonalLocation: PropTypes.func.isRequired,
  skippableQuestionIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

QuestionSlide.defaultProps = {
  skippableQuestionIds: []
};

module.exports = QuestionSlide;
