const React = require("react");
const PropTypes = require("prop-types");
const unitsUtil = require("../../../../shared/util/units");
const EXAMPLE_PLAN_MAP = require("wp-data").examplePlanMap;
const numericDisplay = require("../../../util/numericDisplay");
const Tooltip = require("../../common/Tooltip.jsx");
const ExternalLink = require("../../links/ExternalLink.jsx");
const constants = require("wp-constants").spacerFree;
const profileMap = require("wp-data").profileMap;
const { withTranslation } = require("react-i18next");
const GALabels = require("../../../optionsConfig/googleAnalytics.labels.json");
const ga = require("../../../../shared/tracking");
const locationUtil = require('../../../../shared/util/location.js');

function getLinkButtonToExamplePlan(href, t) {
  return (
    <ExternalLink
      href={href}
      onClick={() => {
        ga.GAEvent(
          GALabels.categories.result,
          GALabels.actions.examplePlansBtn,
          GALabels.labels.examplePlansBtn
        );
      }}
    >
      <span className="button is-primary-outlined is-small has-min-width-narrow example-plan-button heap-view-example-plan-button">
        {t("examplePlanButtonText")}
      </span>
    </ExternalLink>
  );
}

function getExampleRoute(profileId, size, isPartialFloor, measurementSystem, is2Dlink) {
  // if isPartialFloor is false, return for full floor
  const sizeString = `${
    isPartialFloor ? "partial" : "full"
  }_${size.toString()}`;
  let LinkType = is2Dlink ? "link2D" : "link3D";
  const fitId = EXAMPLE_PLAN_MAP[LinkType][profileId][sizeString];

  let url = `https://plans.cbre.com/examples#/testFits/${fitId}`;

  // Plans only has imperial and metric (no Tsubo)
  // default to metric URL for any non-imperial
  if (!unitsUtil.isImperial(measurementSystem)) url += "?units=metric";

  return url;
}

function getPreviewImageFileName(size, props) {
  const profileName = profileMap[props.profileId].displayNameToken;
  let fullPlanType;
  let partialPlanType;
  if (size === 5000) {
    fullPlanType = constants.profileInfo.FLOORPLAN_TYPE.FULL_5K;
    partialPlanType = constants.profileInfo.FLOORPLAN_TYPE.PARTIAL_5K;
  } else if (size === 10000) {
    fullPlanType = constants.profileInfo.FLOORPLAN_TYPE.FULL_10K;
    partialPlanType = constants.profileInfo.FLOORPLAN_TYPE.PARTIAL_10K;
  } else if (size === 15000) {
    fullPlanType = constants.profileInfo.FLOORPLAN_TYPE.FULL_15K;
    partialPlanType = constants.profileInfo.FLOORPLAN_TYPE.PARTIAL_15K;
  } else {
    fullPlanType = constants.profileInfo.FLOORPLAN_TYPE.FULL_20K;
    partialPlanType = constants.profileInfo.FLOORPLAN_TYPE.PARTIAL_20K;
  }
  const fullPlanUrl =
    profileMap[props.profileId].examplePreviewImageNames[fullPlanType];
  const partialPlanUrl =
    profileMap[props.profileId].examplePreviewImageNames[partialPlanType];
  return { fullPlanUrl, partialPlanUrl, profileName };
}

function ExamplePlans(props) {
  const is2Dlink  = locationUtil.is2DExampleLinkLocation(props.officeLocation);
  
  let examplePlanSize;
  if (props.totalRSF <= 7500) {
    examplePlanSize = 5000;
  } else if (props.totalRSF <= 12500) {
    examplePlanSize = 10000;
  } else if (props.totalRSF <= 17500) {
    examplePlanSize = 15000;
  } else {
    examplePlanSize = 20000;
  }

  const { fullPlanUrl, partialPlanUrl, profileName } = getPreviewImageFileName(
    examplePlanSize,
    props
  );

  const measurementLabel = props.t(
    `common:${unitsUtil.getTokenKeyForMeasurementSystem(
      props.sfType === constants.calculator.SF_TYPE.NIA ? "NIA" : "RSF",
      props.measurementSystem
    )}`
  );

  const size = unitsUtil.getNumberForMeasurementSystem(
    examplePlanSize,
    props.measurementSystem
  );
  const sizeWithCommas = numericDisplay.numberWithLocaleFormat(size);

  const fullLabel = `${sizeWithCommas} ${measurementLabel} ${props.t(
    "examplePlanFullFloorText"
  )}`;
  const partialLabel = `${sizeWithCommas} ${measurementLabel} ${props.t(
    "examplePlanPartialFloorText"
  )}`;

  const fullPlanLink = getExampleRoute(
    props.profileId,
    examplePlanSize,
    false,
    props.measurementSystem,
    is2Dlink
  );
  const partialPlanLink = getExampleRoute(
    props.profileId,
    examplePlanSize,
    true,
    props.measurementSystem,
    is2Dlink
  );

  return (
    <div className="columns example-plans-preview">
      <div className="column example-plan-column">
        <figure className="image example-plan-image has-margin-0-auto">
          <img
            src={fullPlanUrl}
            alt={props.t("examplePlanFullImgAlt", {
              size: sizeWithCommas,
              unit: unitsUtil.getTokenKeyForMeasurementSystem(
                "foot",
                props.measurementSystem
              ),
              profileName,
            })}
          />
        </figure>
        <div className="example-plan-label is-sans-semibold">{fullLabel}</div>
        {getLinkButtonToExamplePlan(fullPlanLink, props.t)}
      </div>

      <div className="column example-plan-column">
        <figure className="image example-plan-image has-margin-0-auto">
          <img
            src={partialPlanUrl}
            alt={props.t("examplePlanPartialImgAlt", {
              size: sizeWithCommas,
              unit: unitsUtil.getTokenKeyForMeasurementSystem(
                "foot",
                props.measurementSystem
              ),
              profileName,
            })}
          />
        </figure>
        <div className="example-plan-label is-sans-semibold">
          {partialLabel}
        </div>
        {getLinkButtonToExamplePlan(partialPlanLink, props.t)}
        {examplePlanSize === 20000 && (
          <Tooltip
            position="bottom"
            text={props.t("examplePlanTooltipText", {
              size: sizeWithCommas,
              unit: measurementLabel,
            })}
          />
        )}
      </div>
    </div>
  );
}

ExamplePlans.propTypes = {
  sfType: PropTypes.number.isRequired,
  profileId: PropTypes.string.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  profileType: PropTypes.string.isRequired,
  totalRSF: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

module.exports = withTranslation("resultsPage")(ExamplePlans);
