const React                = require('react');
const PropTypes            = require('prop-types');

const QuizNavigationButton = require('./QuizNavigationButton.jsx');

function QuizStickyFooter(props) {
  return (
    <div className="navbar is-fixed-bottom is-green-forest-darker flex quiz-sticky-footer">
      <QuizNavigationButton
        isBack
        onClick={props.previousQuestion}
        hidden={props.isFirstQuestion}
      />

      <QuizNavigationButton
        onClick={props.nextQuestion}
        disabled={!props.isAnswerComplete}
        isPulledLeft={props.isFirstQuestion}
      />
    </div>
  );
}

QuizStickyFooter.propTypes = {
  previousQuestion: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired,
  isFirstQuestion: PropTypes.bool.isRequired,
  isAnswerComplete: PropTypes.bool.isRequired
};

module.exports = QuizStickyFooter;
