// Action Types

const SET_PROFILE_AND_CALCULATE_PROGRAM = 'SET_PROFILE_AND_CALCULATE_PROGRAM';
const ADD_KEY_ASSUMPTION                = 'ADD_KEY_ASSUMPTION';
const UPDATE_KEY_ASSUMPTION             = 'UPDATE_KEY_ASSUMPTION';
const UPDATE_MEASUREMENT_SYSTEM         = 'UPDATE_MEASUREMENT_SYSTEM';
const UPDATE_SF_TYPE                    = 'UPDATE_SF_TYPE';
const UPDATE_LOSS_FACTOR                = 'UPDATE_LOSS_FACTOR';
const UPDATE_SPACE_UNIT_QUANTITY        = 'UPDATE_SPACE_UNIT_QUANTITY';
const UPDATE_SPACE_UNIT_AMENITY_SF      = 'UPDATE_SPACE_UNIT_AMENITY_SF';
const ADD_CUSTOM_AMENITY                = 'ADD_CUSTOM_AMENITY';
const UPDATE_CUSTOM_AMENITY_QUANTITY    = 'UPDATE_CUSTOM_AMENITY_QUANTITY';
const UPDATE_CUSTOM_AMENITY_SF          = 'UPDATE_CUSTOM_AMENITY_SF';
const DELETE_SPACE_UNIT                 = 'DELETE_SPACE_UNIT';
const SET_PROGRAM_ID                    = 'SET_PROGRAM_ID';

module.exports = {

  // Action Types

  SET_PROFILE_AND_CALCULATE_PROGRAM,
  ADD_KEY_ASSUMPTION,
  UPDATE_KEY_ASSUMPTION,
  UPDATE_MEASUREMENT_SYSTEM,
  UPDATE_SF_TYPE,
  UPDATE_LOSS_FACTOR,
  UPDATE_SPACE_UNIT_QUANTITY,
  UPDATE_SPACE_UNIT_AMENITY_SF,
  ADD_CUSTOM_AMENITY,
  UPDATE_CUSTOM_AMENITY_QUANTITY,
  UPDATE_CUSTOM_AMENITY_SF,
  DELETE_SPACE_UNIT,
  SET_PROGRAM_ID,


  // Action Creators

  setProfileAndCalculateProgram(profileId) {
    return { type: SET_PROFILE_AND_CALCULATE_PROGRAM, profileId };
  },

  addKeyAssumption(assumptionType, value) {
    return { type: ADD_KEY_ASSUMPTION, assumptionType, value };
  },

  updateKeyAssumption(assumptionType, value) {
    return { type: UPDATE_KEY_ASSUMPTION, assumptionType, value };
  },

  updateMeasurementSystem(measurementSystem) {
    return { type: UPDATE_MEASUREMENT_SYSTEM, measurementSystem };
  },

  updateSFType(sfType) {
    return { type: UPDATE_SF_TYPE, sfType };
  },

  updateLossFactor(lossFactor) {
    return { type: UPDATE_LOSS_FACTOR, lossFactor };
  },

  updateSpaceUnitQuantity(spaceUnitId, value, officeLocation) {
    return { type: UPDATE_SPACE_UNIT_QUANTITY, spaceUnitId, value, officeLocation };
  },

  updateSpaceUnitAmenitySF(spaceUnitId, value) {
    return { type: UPDATE_SPACE_UNIT_AMENITY_SF, spaceUnitId, value };
  },

  addCustomAmenity(displayName, customQuantity, customSF, dataKey) {
    return { type: ADD_CUSTOM_AMENITY, displayName, customQuantity, customSF, dataKey };
  },

  updateCustomAmenityQuantity(id, quantity) {
    return { type: UPDATE_CUSTOM_AMENITY_QUANTITY, id, quantity };
  },

  updateCustomAmenitySF(id, customSF) {
    return { type: UPDATE_CUSTOM_AMENITY_SF, id, customSF };
  },

  deleteSpaceUnit(id) {
    return { type: DELETE_SPACE_UNIT, id };
  },

  setProgramId(id) {
    return { type: SET_PROGRAM_ID, id };
  }

};
