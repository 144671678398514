const React                    = require('react');
const LandingPageHero          = require('./LandingPageHero.jsx');
const LandingPageContent       = require('./LandingPageContent.jsx');
const LandingPageSecondaryHero = require('./LandingPageSecondaryHero.jsx');
const Footer                   = require('../../common/Footer.jsx');
const BasicNavbarWithLinks     = require('../../common/BasicNavbarWithLinks.jsx');
const DocumentData             = require('../../common/DocumentData.jsx');
const constants                = require('wp-constants').spacerFree;
const { withTranslation }        = require('react-i18next');
import VideoPlayer from "./VideoPlayer.jsx";

function LandingPage(props) {
  return (
    <div className="landing-page">
      <DocumentData
        siteLocation={constants.siteLocation.PAGE.LANDING}
      />
      <BasicNavbarWithLinks />
      <main>
        <LandingPageHero />
        <LandingPageContent />
        {props.i18n.language === "nld" && ( <VideoPlayer />)}
        <LandingPageSecondaryHero />
      </main>

      <Footer />
    </div>
  );
}


module.exports = withTranslation('common')(LandingPage);
