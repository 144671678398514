const SPACE_UNIT_CATEGORY = require('wp-constants').shared.spaceUnit.CATEGORY;
const regionSFCalculator  = require('../../shared/calculators/regionSFCalculator');
const numericDisplay      = require('./numericDisplay');

// Module that will retrieve the desired (either current or original) data from parts of the program

const categoryToAreaTotal = {
  [SPACE_UNIT_CATEGORY.ME]: {
    custom: 'totalCustomMeSF',
    original: 'totalMeSF'
  },
  [SPACE_UNIT_CATEGORY.WE]: {
    custom: 'totalCustomWeSF',
    original: 'totalWeSF'
  },
  [SPACE_UNIT_CATEGORY.AMENITY]: {
    custom: 'totalCustomAmenitySF',
    original: 'totalAmenitySF'
  }
};

function getDeltaHelper(customVal, originalVal) {
  const numericDelta = customVal - originalVal;
  if (!numericDelta) return null;

  const percentDelta = numericDelta / originalVal;
  const displayPercentDelta = numericDisplay.decimalToPercentage(percentDelta);
  return { numericDelta, percentDelta, displayPercentDelta };
}


module.exports = {

  // Current Value Getters

  getTotalSFForCategory(areaTotals, category) {
    return areaTotals[categoryToAreaTotal[category].custom] || areaTotals[categoryToAreaTotal[category].original];
  },

  getTotalSF(areaTotals) {
    return areaTotals.totalCustomSF || areaTotals.totalSF;
  },

  getNSF(programUnit) {
    return programUnit.customNSF || programUnit.nsf;
  },

  getQuantity(programUnit) {
    return programUnit.customQuantity || programUnit.quantity;
  },


  // Delta Getters
  // Delta in the format of:
  // {
  //   numericDelta: (number)
  //   displayPercentDelta: (string)
  // }
  //

  getNSFDelta(programUnit) {
    return getDeltaHelper(programUnit.customNSF, programUnit.nsf);
  },

  getQuantityDelta(programUnit) {
    return getDeltaHelper(programUnit.customQuantity, programUnit.quantity);
  },

  getTotalSFDelta(areaTotals) {
    return getDeltaHelper(areaTotals.totalCustomSF, areaTotals.totalSF);
  },

  getTotalDeltaForCategoryAndContext(areaTotals, category, sfType, location, lossFactor) {
    const customTotal = areaTotals[categoryToAreaTotal[category].custom];
    const originalTotal = areaTotals[categoryToAreaTotal[category].original];

    const customTotalForContext = regionSFCalculator.getAreaForContext(customTotal, sfType, location, lossFactor);
    const originalTotalForContext = regionSFCalculator.getAreaForContext(originalTotal, sfType, location, lossFactor);
    return getDeltaHelper(customTotalForContext, originalTotalForContext);
  }

};
