module.exports = {

  // Results
  resultsWarningMessage: 'Sind Sie sicher, dass Sie die Seite verlassen möchten ? Sie können Daten verlieren, die Sie nicht gespeichert haben.',

  // ResultsHeader
  resultsHeaderExportPDFButtonText: 'PDF exportieren',
  resultsHeaderSendButtonText: 'Ergebnisse zusenden',
  resultsHeaderClipboardBoxDescription: 'Ergebnisse können per Link angesehen und bearbeiten werden',
  resultsHeaderContactButtonText: 'Einen Workplace-Experten kontaktieren',

  // EmailWallSection
  emailWallTitle: 'Wir haben noch mehr für Sie.',
  emailWallSubtitle: 'Teilen Sie uns Ihre Kontaktdaten mit, damit wir Ihnen eine vollständige Flächenberechnung erstellen können.',
  emailWallFirstNamePlaceholder: 'Vorname',
  emailWallLastNamePlaceholder: 'Nachname',
  emailWallEmailPlaceholder: 'E-Mail',
  emailWallCBREEmailPlaceholder: 'E-Mail Ihres CBRE-Experten (wahlweise)',
  // emailWallLeasingProfessionalPlaceholder: no translation yet,
  emailWallConnectMeText: 'Nehmen Sie Kontakt mit einem CBRE Workplace Spezialisten auf. Keine E-Mail-Newsletter oder Spam, versprochen!',
  emailWallContactNoticeText: 'Das Spacer Team wird Sie vielleicht kontaktieren, damit Sie unseren Service bestmöglich nutzen können. Kein Spam, versprochen!',
  emailWallResultsButtonText: 'Vollständige Ergebnisse ansehen',
  // emailWallImgAltText: no translation yet
  emailWallFirstNameInvalid: 'Bitte geben Sie Ihren Vornamen ein.',
  emailWallLastNameInvalid: 'Bitte geben Sie Ihren Nachnamen ein.',
  emailWallEmailInvalid: 'Bitte geben Sie Ihre E-Mail Adresse ein.',

  // CopyToClipboardBox
  clipboardBoxCopyText: 'Kopieren',
  clipboardBoxCopiedText: 'Kopiert',

  // FormModal
  formModalFindSpaceHeader: 'Mit der Bürosuche beginnen',
  formModalFindSpaceBody: 'Spacer ist ein schnelles und einfaches Tool, um Ihr Büroumfeld zu analysieren. Wenn Sie spezifischere Beratung wünschen, unterstützt Sie das CBRE Worklpace Team gerne. Füllen Sie das Formular aus und ein Workplace Experte wird innerhalb der nächsten 48 Stunden einen Termin mit Ihnen vereinbaren.',
  formModalFindSpaceSuccessMessage: 'Ihre Anfrage wurde versendet.',

  // SuccessModal
  successModalTitle: 'Erfolg',

  // ContactForm
  contactFormFirstNameLabel: 'Vorname',
  contactFormLastNameLabel: 'Nachname',
  contactFormEmailLabel: 'Email Büro',
  contactFormPhoneLabel: 'Telefonnummer',
  contactFormMessageLabel: 'Wonach suchen Sie genau?',
  contactFormSubmitText: 'Einreichen',

  // AcceptTermsText
  acceptTermsText: 'Ich akzeptiere die Nutzungsbedingungen. Für mehr Informationen über die Verwendung Ihrer Daten verweisen wir auf die CBRE Datenschutzbestimmungen.',
  privacyPolicyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Form
  formErrorText: 'Uups, das ist nicht durchgegangen. Bitte versuchen Sie es noch einmal.',
  formEmailInvalid: 'Bitte geben Sie Ihre E-Mail Adresse an.',

  // FormInput
  formInputOptionalText: ' (wahlweise)',

  // LegalForm
  legalFormCheckboxText: 'CBRE darf mich im Zusammenhang mit meiner Nutzung von Spacer kontaktieren.',
  legalFormContactWarningText: 'Das Spacer Team wird Sie vielleicht kontaktieren, damit Sie unseren Service bestmöglich nutzen können. Kein Spam, versprochen!',

  // NextStepsSection
  nextStepsSectionSubtitle: 'Sind Sie bereit für den nächsten Schritt?',
  nextStepsSectionTitle: 'Finden Sie Ihr perfektes Büro.',
  nextStepsSectionButtonText: 'Setzen Sie sich mit einem unserer Workplace-Experten in Verbindung',

  // Profile
  resultsProfileSummaryTitle: 'Ihr Büro ist...',
  resultsProfileTooltipText: 'Jedes Profil basiert auf Annahmen zu Unternehmensdaten und gängigen Branchenrichtlinien. Die Details müssen durch das CBRE Workplace Team an die jeweilige Unternehmensstruktur angepasst werden.',

  // Program
  programSaveConfirmationBoxText: 'Schon fertig! Wir haben Ihnen per Email einen Link zu Ihren Ergebnissen geschickt. Viel Spaß!',
  programExamplePlansTitle: 'Beispiel Grundriss',
  programExamplePlansSubtitle: 'Spielen Sie mit interaktiven Grundrissen, die Ihrem Profil entsprechen. Sie können sie sogar in 3D erkunden!',
  programTooMuchTinkerWarning: 'Uups! Es sieht so aus, als würden Sie sich außerhalb der Profilauswahl bewegen. Vielleicht sollten Sie <1>das Quiz wiederholen</1>, oder das CBRE Workplace Team kontaktieren <3>{{email}}</3>.',
  programDisclaimer: 'Spacer" von CBRE wird wie gesehen in der bereitgestellten Form („as is“ und „as available“) ohne Übernahme einer Gewähr zur Verfügung gestellt. Alle Ergebnisse, die durch Ihre Nutzung von "Spacer" erzeugt werden, dienen nur der Veranschaulichung und spiegeln keine individuellen Empfehlungen für Raumgestaltungen oder Mietvertragslaufzeiten wider. Die jeweiligen Empfehlungen für Raumgestaltungen können stark variieren und hängen von vielen Faktoren ab, die "Spacer" nicht berücksichtigt. Mit der Nutzung von "Spacer" oder eines Reports, der durch die Nutzung von "Spacer" generiert wird, akzeptieren Sie unsere Nutzungsbedingungen („Terms of Service“) sowie unsere Datenschutzerklärung („Privacy Policy“) , auf die sie unten folgenden Links abrufen können: https://workplace.cbre.com/termsOfService und https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy. 2018 CBRE, Inc. Alle Rechte bleiben CBRE vorbehalten.',
  programMetricsTitle: 'Programm-Messwerte',
  programMetricsSubtitle: 'Diese Zahlen beschreiben Ihr Arbeitsumfeld.',
  programDetailsSectionTitle: 'Passen Sie Ihren Bedarf an',
  programDetailsSectionSubtitle: 'Sie können Ihre Angaben manuell anpassen.',
  programRevertButtonText: 'Zurück',
  programSaveButtonText: 'Änderungen speichern',
  programSavedButtonText: 'Saved',

  // ProgramAssumptions
  programAssumptionsRecommendedOfficeSizeText: 'Indikative Bürogröße',
  programAssumptionsProfileText: 'Profil',
  programAssumptionsHeadcountText: 'Mitarbeiterzahl',
  programAssumptionsHeadcountFollowingText: 'Mitarbeiter',
  programAssumptionsCollabText: 'Kollaboration',
  programAssumptionsCirculationText: 'Verkehrsfläche',
  programAssumptionsAvgDaysText: 'Durchschnittliche Tage im Büro',
  programAssumptionsAvgDaysFollowingText: 'pro Woche',
  programAssumptionsAvgDaysPerWeekText: 'Durchschnittliche Tage im Büro pro Woche',

  // ProgramHeroForPrint
  printNIAText: 'Netto-Innenfläche',
  printUSFText: 'Nutzbare Quadratmeter',
  printRSFText: 'Vermietbare Quadratmeter',
  printDescText: 'Basierend auf Ihren Angaben {{lossFactorPercentage}}% {{factorType}} in {{city}}, empfehlen wir insgesamt-',
  printFitFactorText: 'Eignungsfaktor',
  printLossFactorText: 'Verlustfaktor',

  // BarChart
  barChartIndivSubtitle: 'Einzelarbeitsplatz',
  barChartGroupSubtitle: 'Gruppenarbeitsplatz',
  barChartAmenitySubtitle: 'Sonderfläche',

  // TabbedProgramBarChart
  tabbedBarChartMeSubtitle: 'Einzelarbeitsplätze',
  tabbedBarChartGroupSubtitle: 'Gruppenarbeitsplätze',
  tabbedBarChartAmenitySubtitle: 'Komfortbereich',

  // SpaceCategoryBreakdown
  spaceCatBreakdownSubtitle: 'Eine Flächenart zur Anpassung auswählen',

  // SpaceCategoryBreakdownMobile
  spaceCatBreakdownMobileMeTitle: 'Einzelarbeitsplatz',
  spaceCatBreakdownMobileWeTitle: 'Gruppenarbeitsplatz',
  spaceCatBreakdownMobileAmenityTitle: 'Sonderfläche',
  spaceCatBreakdownMobileMeSubtitle: 'für Einzelarbeit',
  spaceCatBreakdownMobileWeSubtitle: 'für die Zusammenarbeit',
  spaceCatBreakdownMobileAmenitySubtitle: 'für alles andere',
  spaceCatBreakdownMobileNIATotalTitle: 'Gesamt Netto-Innenfläche',
  spaceCatBreakdownMobileTotalTitle: 'Gesamtfläche',
  spaceCatBreakdownMobileCirculationText: 'Verkehrsfläche',

  // SpaceCategoryDropdown
  spaceCatDropdownMeText: 'Einzelarbeitsplatz',
  spaceCatDropdownWeText: 'Gruppenarbeitsplatz',
  spaceCatDropdownAmenityText: 'Sonderfläche',

  // SidebarInfo
  sidebarInfoMeTitle: 'Einzelarbeitsplätze',
  sidebarInfoWeTitle: 'Gruppenarbeitsplätze',
  sidebarInfoAmenityTitle: 'Komfortbereich',

  // Space Category Descriptions (spaceCategoryDescription.js)
  spaceCatMeDesc: 'Flächen für Einzelarbeit. Lassen Sie Ihre Mitarbeiter entscheiden, wo und wie sie arbeiten möchten und erhöhen Sie so die Mitarbeiterzufriedenheit.',
  spaceCatWeDesc: 'Raum für Kollaboration und Teamarbeit. In einer Zeit in der Arbeit überall stattfinden kann, ist es wichtig, verschiedene Bereiche für verschiedene Aufgaben anzubieten.',
  spaceCatAmenityDesc: 'Eine Fläche, die das Arbeiten unterstützt. Komfortbereiche sind Orte zur Entspannung und für den sozialen Austausch. Aber auch notwendige Raumelemente wie Lagerflächen sind unter dieser Kategorie zusammengefasst. Hochwertige Komfortbereiche verwandeln Ihr Büro in einen Ort, an dem Ihre Angestellten - sowohl heute als auch zukünftig - gerne ihre Zeit verbringen.',

  // Suggested Custom Amenities (suggestedCustomAmenityUnitMap.js)
  audioVisualCustom: 'Audio / Visuell',
  dataCenterCustom: 'Data Center',
  buildingSupportCustom: 'Gebäudedienste - Sicherheit und Versorgung',
  foodServiceCustom: 'Vollständiger Gastronomieservice',
  kitchenCustom: 'Küche',
  serveryCustom: 'Speisenausgabe',
  execDiningCustom: 'Casino',
  grabNGoCustom: '"To Go" - kleine Verkaufsfläche',
  juiceCoffeeBarCustom: 'Saftbar/Café',
  childCareCustom: 'Kinderbetreuung',
  fitnessCenterCustom: 'Fitnesscenter',
  healthCenterCustom: 'Gesundheitszentrum',
  mailCenterCustom: 'Poststelle',
  commercialPrintCustom: 'Druckerei',
  centralCafeCustom: 'Café',
  securityCtrlCustom: 'Security Raum',
  auditoriumCustom: 'Hörsaal',
  dryCleaningLaundryCustom: 'Chemische Reinigung / Wäscherei',
  confCenterCustom: 'Konferenzzentrum',
  multiPurpTrainingCustom: 'Mehrzweck-/Schulungsraum',
  informalCollabCustom: 'Informelle Kollaboration',
  touchdownTeamCustom: 'Touchdown/Teambereiche',

  // Suggested Custom Amenity Units (spaceUnit.js)
  seats: 'Arbeitsplatz',
  seats_plural: 'Arbeitsplätze',
  rooms: 'Raum',
  rooms_plural: 'Räume',
  areas: 'Bereich',
  areas_plural: 'Bereiche',
  units: 'Einheit',
  units_plural: 'Einheiten',

  // Predefined Amenities (spaceUnitMap.js) - Shared Space Units
  focusRoomDefined: 'Fokus-Raum',
  fileRoomDefined: 'Archiv/Aktenraum',
  centralReceptionHubDefined: 'Zentraler Empfangsbereich',
  smOfficeDefined: 'Büro klein',
  regOfficeDefined: 'Büro normal',
  benchDesk5Defined: '160 cm Schreibtisch',
  benchDesk6Defined: '180 cm Schreibtisch',
  meetingRoom4Defined: 'Besprechungsraum (4 Personen)',
  meetingRoom6Defined: 'Besprechungsraum (6 Personen)',
  meetingRoom8Defined: 'Besprechungsraum (8 Personen)',
  meetingRoom12Defined: 'Besprechungsraum (12 Personen)',
  meetingRoom15Defined: 'Besprechungsraum (15 Personen)',
  semiEnclosedMeetingBooth4Defined: 'Halboffene Besprechungszone (4 Personen)',
  openCollabSpace4to6Defined: 'Offene Kollaborationsfläche (4 - 6 Personen)',
  contemplationSpaceDefined: 'Ruhe-/Gebetsraum',
  mainITEquipRoomDefined: 'Hauptserverraum',
  receptionAreaDefined: 'Empfangsbereich',
  cafeFoodServiceDefined: 'Café / Gastronomie',
  teamStorageDefined: 'Lager / Archiv',
  coatClosetsDefined: 'Garderobe',
  genStorageDefined: 'Lagerraum allgemein',
  centralisedCopyPrintMailDefined: 'Kopierraum',
  specialResourceAreaDefined: 'Sonderfläche',
  personalStorageDefined: 'persönliche Schließfächer (Locker)',
  storageITSpaceDefined: 'IT Lagerraum',
  secondaryServerRoomDefined: 'Zweitraum Server/Telekommunikation',
  mothersRoomDefined: 'Eltern / Kind Raum',
  buildITSpaceDefined: 'IT Fläche',
  workerCouncilOffice2Defined: 'Betriebsrat-Büro (2 Personen)',
  securityCtrlRoomDefined: 'Security Raum',
  centralisedMailDefined: 'Postraum',
  visitorCoatLuggageRoomDefined: 'Besucher Garderobe / Gepäckraum',
  wellnessRoomDefined: 'Wellness Raum',
  internalStairDefined: 'Innentreppe',
  commercialPrintRoomDefined: 'Druckerei',
  buildingSupportServicesAreaDefined: 'Gebäudedienste - Sicherheit und Versorgung',

  // Predefined Amenities (spaceUnitMap.js) - US_SMALL and US_REGULAR
  officeDefined: 'Standardbüro',
  lgOfficeDefined: 'Größeres Büro',
  smWorkstationDefined: 'Bench klein',
  mdWorkstationDefined: 'Bench normal',
  lgWorkstationDefined: 'Bench groß',
  huddleRoomDefined: 'Huddle Raum',
  dispersedCopyPrintAreaDefined: 'Kopieren/Drucken',
  smMeetingRoomDefined: 'Kleiner Besprechungsraum',
  mdMeetingRoomDefined: 'Mittelgroßer Besprechungsraum',
  lgMeetingRoomDefined: 'Großer Besprechungsraum',
  xlMeetingRoomDefined: 'XL-Besprechungsraum/Boardroom',
  multiPurpTrainingDefined: 'Mehrzweck-/Schulungsraum',
  informalCollabDefined: 'Informelle Kollaboration',
  touchdownTeamDefined: 'Touchdown/Teambereiche',
  pantryVendingCoffeeAreaDefined: 'Vorratsraum/Vending/Kaffee',
  workplaceStorageRoomDefined: 'Lagerraum',
  employeeCoatAreaDefined: 'Mitarbeiter-Garderobe',
  dispersedMailAreaDefined: 'Postverteilerraum',
  lockerUnitDefined: 'Schließfächer/Lockerschränke',
  serverRoomDefined: 'Hauptserverraum',
  frontDeskAreaDefined: 'Empfangsbereich',
  centralCafeAreaDefined: 'Café',
  mailCenterAreaDefined: 'Poststelle',
  kitchenAreaDefined: 'Küche',
  drawerLateralFileUnitDefined: 'Rollcontainer/Apothekerschrank',
  serveryAreaDefined: 'Speisenausgabe',
  juiceBarCoffeeShopAreaDefined: 'Saftbar/Café',
  diningSupportAreaDefined: 'Vorbereitungsküche',
  diningAreaDefined: 'Essbereich',
  grabNGoAreaDefined: '"To Go" - kleine Verkaufsfläche',
  focusRoomSharedFocus: 'Fokus-Raum',

  // Predefined Amenities (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDefined: 'Vollständiger Gastronomieservice',

  // Predefined Amenities (spaceUnitMap.js) - APAC_REGULAR
  officeWorkMeetDefined: 'Standardbüro',
  lgOfficeWorkMeetDefined: 'Größeres Büro',
  focusDefined: 'Fokus Raum',
  bench1pt6Defined: 'Bench 160x80',
  bench1pt8Defined: 'Bench 180x80',
  bench1pt8LDefined: 'Tisch 180x80',
  banquetWorkMeetDefined: 'Bank - (2p)',
  cafeTable4Defined: 'Kaffeetisch (4p)',
  kitchenTable8to10Defined: 'Küchentisch (8-10p)',
  standingWhiteboardDefined: 'Whiteboard',
  huddleRoom3Defined: 'Aufenthaltsraum (3p)',
  smMeetingRoom5Defined: 'kleiner Besprechungsraum (5p)',
  mdMeetingRoom8Defined: 'mittlerer Besprechungsraum (8p)',
  lgMeetingRoom20Defined: 'großer Besprechungsraum (20p)',
  xlBoardroom: 'XL-Besprechungsraum/Boardroom',
  wellnessRoomsDefined: 'Wellness Raum',
  employeeCoatsGymLockersDefined: 'Mitarbeiter-Garderobe',
  lockersDefined: 'Schließfächer/Lockerschränke',
  dispersedCopyPrintScanAreaDefined: 'Kopieren/Drucken',
  hydrationPointDefined: 'Versorgungsspunkt (Wasser/Tee/Kaffee)',
  centralHighDensityStorageDefined: 'Zentraler Lagerraum',
  wellnessMothersRoomDefined: 'Wellness Raum',
  serverHubRoomsDefined: 'Server-/Patchräume',
  buildITStoreRoomDefined: 'IT-Gebäude/Lagerraum',
  simpleReceptionWaitDefined: 'Empfang und Wartebereich',
  centralCafeSocialHubDefined: 'Zentrales Café',
  mailCentreDefined: 'Poststelle',
  utilityStoreRoomDefined: 'Lagerraum allgemein.',
  drawerLateralFileDefined: 'Rollcontainer/Apothekerschrank',
  juiceBarCoffeeShopDefined: 'Saftbar/Café',
  buildingSupportServicesDefined: 'Gebäudedienste - Sicherheit und Versorgung',

  // Space Unit Map Descriptions (spaceUnitMap.js) - Shared Descriptions
  focusRoomDesc: 'Fokus Räume sind geschlossene Räume, die dazu bestimmt sind, konzentrierte und/oder vertrauliche Arbeit für ein bis zwei Personen zu ermöglichen. Ein akustischer Schutz für Privatsphäre ist in diesen Räumen von größter Bedeutung. Fokus-Räume sind keiner Person zugeordnet und können in der Regel bei Bedarf vorübergehend für relevante Arbeiten gebucht werden.',
  fileRoomDesc: 'Dieser Raum dient der Ablage von Papierakten für Einzelpersonen und Teams. Infolge der Digitalisierung der meisten Büros nimmt der Bedarf an Einzel- und Teamarchiven stark ab. Dennoch ist dies ein zentraler Ort für gesetzlich vorgeschriebene Dokumentenkopien oder langfristige Projektunterlagen, insbesondere bei den meisten offenen Büroarbeitsplätzen auf kleinem Raum.',
  centralReceptionHubDesc: 'Der Empfangsbereich beinhaltet nebem dem Empfangstresen auch den Wartebereich und eine Interaktionszone sowohl für Gäste als auch für Mitarbeiter des Unetrnehmens. Er sollte die Marke des Unternehmens einheitlich vermitteln und das gewünschte Erscheinungsbild projizieren.',
  officeDesc: 'Standardbüro sind private, geschlossene Arbeitsräume für Mitarbeiter. Sie sind ideal, um Ablenkungen zu reduzieren und den Fokus auf die Arbeit zu legen. Büros sind typischerweise mit einem Schreibtisch und einem Bürostuhl ausgestattet. Sie bieten unterschiedlich viel Platz für die Aufbewahrung und Ablage persönlicher Dinge. Die Größe des Raumes kann je nach den dort ausgeübten Tätigkeiten (z. B. regelmäßige Besprechungen) oder Mitarbeiterstatus/Hierarchien variieren.',
  benchDeskDesc: 'Benches sind offene Arbeitsplätze. Kleinere Schreibtische können von Vollzeitbeschäftigten entweder vorübergehend ( in einem freien Raum) oder dauerhaft genutzt werden. Diese Arbeitsplätze sind ideal für Mitarbeiter oder Freie Mitarbeiter geeignet, die nicht ständig im Büro sind. Einige Vorteile von diesen Arbeitsplätzen ist die offene Atmosphäre im Büro und die damit verbundene Zusammenarbeit. Ein Nachteil kann die Lärmabstrahlung sein, wehsalb es empfohlen wird, dass offenen Arbeitsbereiche über genügend Fokus- und Aufenthaltsräume verfügen. Die Ablage am Arbeitsplatz ist begrenzt, da sich die Stauraummöglichkeiten auf der Fläche verteilen.',
  meetingRoomDesc: 'Besprechungsräume sind geschlossene Räume, die für Besprechungen in Kleingruppen von 2-4 Personen vorgesehen sind. Sie sollten mit A/V-Geräten und Whiteboards ausgestattet sein, um den Austausch zu erleichtern und akustisch geschützt zu sein (Personen, die unmittelbar außerhalb des Raumes sitzen, sollten nicht in der Lage sein, Gespräche zu hören, die im Raum stattfinden).',
  meetingRoomDesc6: 'Besprechungsräume sind geschlossene Räume, die für Besprechungen in Kleingruppen von 2-4 Personen vorgesehen sind. Sie sollten mit A/V-Geräten und Whiteboards ausgestattet sein, um den Austausch zu erleichtern und akustisch geschützt zu sein (Personen, die unmittelbar außerhalb des Raumes sitzen, sollten nicht in der Lage sein, Gespräche zu hören, die im Raum stattfinden).',
  meetingRoomDesc8: 'Besprechungsräume sind geschlossene Räume, die für Besprechungen in Kleingruppen von 2-4 Personen vorgesehen sind. Sie sollten mit A/V-Geräten und Whiteboards ausgestattet sein, um den Austausch zu erleichtern und akustisch geschützt zu sein (Personen, die unmittelbar außerhalb des Raumes sitzen, sollten nicht in der Lage sein, Gespräche zu hören, die im Raum stattfinden).',
  meetingRoomDesc12: 'Besprechungsräume sind geschlossene Räume, die für Besprechungen in Kleingruppen von 2-4 Personen vorgesehen sind. Sie sollten mit A/V-Geräten und Whiteboards ausgestattet sein, um den Austausch zu erleichtern und akustisch geschützt zu sein (Personen, die unmittelbar außerhalb des Raumes sitzen, sollten nicht in der Lage sein, Gespräche zu hören, die im Raum stattfinden).',
  meetingRoomDesc15: 'Besprechungsräume sind geschlossene Räume, die für Besprechungen in Kleingruppen von 2-4 Personen vorgesehen sind. Sie sollten mit A/V-Geräten und Whiteboards ausgestattet sein, um den Austausch zu erleichtern und akustisch geschützt zu sein (Personen, die unmittelbar außerhalb des Raumes sitzen, sollten nicht in der Lage sein, Gespräche zu hören, die im Raum stattfinden).',
  openCollabSpace4to6Desc: 'Diese Bereiche sollen kleine Gruppen (4 - 6 Personen) bei gemeinsamen Aktivitäten wie Brainstorming und zwanglosen Gesprächen zwischen Kollegen und mit Kunden unterstützen.',
  cafeFoodServiceDesc: 'Das zentrale Café soll ein Bereich zum Essen, für soziale Interaktion, individuelles Arbeiten und kleine Meetings sein. Funktionelle Essmöbel und die Corporate Identity sollten bei der Gestaltung dieses Raumes berücksichtigt werden.',
  coatClosetsDesc: 'Mitarbeiter benötigen einen Platz, um ihre Mäntel und andere sperrige Gegenstände zu lagern, insbesondere wenn ein Büro offen gestaltet ist und persönlicher Stauraum am Schreibtisch nur begrenzt vorhanden ist. Die Garderoben sollten im gesamten Büro verteilt sein, etwa an den Ausgängen und entlang der Hauptgänge. Üblicherweise gibt es neben Haken zum Aufhängen von Jacken eine Ablage für Hüte etc. und eine Aufbewahrungsmöglichkeit für Koffer an der Unterseite des Wandschranks.',
  copyPrintDesc: 'In einem offenen Arbeitsbereich über die gesamte Etage verteilt, ermöglichen Kopier-/Druckbereiche den Mitarbeitern aus allen Bereichen einen schnellen Zugriff auf Multifunktionsgeräte. Die Recyclingbehälter sollten immer bei den Kopier-/Druckbereichen aufgestellt werden, wie auch kleine Schränke für zusätzliches Papier und Verbrauchsmaterial.',
  specialResourceBuildingSupportAreaDesc: 'Je nach Sicherheitsbedürfnisse des Unternehmens kann dies ein separierter Raum sein, in dem die Security Mitarbeiter untergebracht sind.',
  secondaryServerRoomDesc: 'Serverräume dienen zur Unterbringung der Hauptserver-Anlagen und sind unternehmensspezifisch zu bestimmen.',
  mothersRoomDesc: 'Wellnessräume (früher Mutter-Kind-Raum genannt) bieten private Rückzugsmöglichkeiten. Sei es für stillende Mütter oder für Personen, die sich für kurze Zeit aus der Arbeitsfläche zurückziehen möchten. Wellnessräume sind in der Regel eine Notwendigkeit für alle neuen Büros, unabhängig von Größe und Mitarbeiterzahl.',
  visitorCoatLuggageRoomDesc: 'Mitarbeiter benötigen einen Platz, um ihre Mäntel und andere sperrige Gegenstände zu lagern, insbesondere wenn ein Büro offen gestaltet ist und persönlicher Stauraum am Schreibtisch nur begrenzt vorhanden ist. Die Garderoben sollten im gesamten Büro verteilt sein, etwa an den Ausgängen und entlang der Hauptgänge. Üblicherweise gibt es neben Haken zum Aufhängen von Jacken eine Ablage für Hüte etc. und eine Aufbewahrungsmöglichkeit für Koffer an der Unterseite des Wandschranks.',
  wellnessRoomDesc: 'Wellnessräume (früher Mutter-Kind-Raum genannt) bieten private Rückzugsmöglichkeiten. Sei es für stillende Mütter oder für Personen, die sich für kurze Zeit aus der Arbeitsfläche zurückziehen möchten. Wellnessräume sind in der Regel eine Notwendigkeit für alle neuen Büros, unabhängig von Größe und Mitarbeiterzahl.',
  internalStairDesc: 'Interne Treppe als zusätzliche Verbindung zwischen mehreren Etagen.',
  commercialPrintRoomDesc: 'Oftmals ist die Druckerei angeschlossen an die Poststelle. Dieser Raum bietet Unternehmen die Möglichkeit, Präsentationen im Büro auszudrucken, zu binden und zusammenzustellen. Es gibt mehrere Drucker, darunter auch Großformatdrucker. Die Räumlichkeiten umfassen Lagerräume für Verbrauchsmaterial, aber auch Thekenflächen für kleinere Geräte und Platz für die Gestaltung von Drucken.',
  pantryVendingCoffeeDesc: 'Im Gegensatz zum zentralen Café ist der Vorrats-, Verkaufsautomaten-, Kaffee- und Getränkebereich für die Mitarbeiter gedacht, um die wichtigsten Dinge für den schnellen Essens- und Getränkebedarf bereitzustellen. Smalltalk und Konversationen werden natürlich in Bereichen, in denen es Essen gibt, immer geführt, aber diese Räume bieten in der Regel keine Sitzgelegenheiten, weshalb dort ein reges Kommen und Gehen herrscht und keine längeren Unterhaltungen stattfinden.',
  storageDesc: 'Lagerräume sind für verschiedene Gegenstände wie z. B. Großgeräte, Zusatzmöbel, Großraumlager, Feiertagsbedarf usw. vorgesehen. Sie können auch als Garderobe für Besucher und für die Gepäckaufbewahrung verwendet werden.',
  centralisedDispersedMailAreaDesc: 'Für größere Unternehmen ist ein Postverteilerraum für die Organisation der ein- und ausgehenden Post erforderlich. In diesen Räumen befinden sich spezialisierte Fertigungs- und Postgeräte. Diese Flächen bieten viel Platz für das Sortieren von Papier und anderen Materialien. In der Regel ist dieser Raum mit Vollzeitmitarbeitern besetzt.',
  lockerDesc: 'Schließfächer/Lockerschränke werden als zusätzliche bzw. einzige Form der individuellen Aufbewahrung angeboten. Sie geben den Mitarbeitern die Möglichkeit, ihre Wertsachen, persönliche Gegenstände und Arbeitsunterlagen zu sichern. Schließfächer können Einzelpersonen zugewiesen oder nach dem "Wer-zuerst-kommt"-Prinzip genutzt werden.',
  mainITandServerRoomDesc: 'Serverräume dienen zur Unterbringung der Hauptserver-Anlagen und sind unternehmensspezifisch zu bestimmen.',
  drawerLateralFileDesc: 'Der Rollcontainer/Apothekerschrank direkt am Arbeitsplatz dient der Aufbewahrung. In der Regel werden dort persönliche Gegenstände oder aktuelle Projektmaterialien aufbewahrt.',
  juiceBarCoffeeShopDesc: 'Während ein Unternehmen möglicherweise nicht über eine vollständige Küche und Bedienung verfügt, kann es hier ein Café oder eine Saftbar anbieten. Diese Art von Raum könnte auch in die Hauptgastronomieleistungen des Unternehmens integriert werden. Dieser Bereich könnte wie eine Café-Filiale aussehen, mit einem Arbeitsbereich für eine/n Mitarbeiter/in, der über einen Tresen die Bestellung aufnimmt und bedient.',
  grabNGoDesc: 'Eine kleine Verkaufsfläche mit mehreren Kühlschränken, Regalen für Chips und Snacks und einem Kassenautomaten. Diese Verkaufsflächen werden in der Regel von Sicherheitskameras überwacht. Die Flächen werden oft mit einer kleinen Speisekammer mit Kühl- und Gefrierschränken und einem Zubereitungsbereich für Speisen kombiniert.',
  smMeetingConferenceRoomDesc: 'Ein kleiner Besprechungsraum (2 - 4 Personen) ist ein geschlossener Raum, in dem ein kleiner Personenkreis bequem am selben Tisch arbeiten kann. Diese Räume können sowohl für interne als auch für externe Meetings genutzt werden und sollten daher sehr benutzerfreundlich sein wie auch über einen einfachen Zugang zu Strom und A/V-Funktionen verfügen.',
  mdMeetingConferenceRoomDesc: 'Ein mittelgroßer Besprechungsraum (4 - 8 Personen) ist ein geschlossener Raum, der einen Personengruppe von mittlerer Größe am Tisch Platz bietet. Diese Räume werden sowohl für interne Besprechungen als auch für Kundenmeetings genutzt und sind daher sehr benutzerfreundlich und verfügen wie über einen einfachen Zugang zu Strom und A/V-Funktionen.',
  lgMeetingConferenceRoomDesc: 'Ein großer Besprechungsraum (8 - 14 Personen) ist ein geschlossener Raum, der für eine größere Anzahl an Personen ausgelegt ist. Diese Räume können sowohl für interne Besprechungen als auch für Kundentermine genutzt werden.',
  xlMeetingRoomDesc: 'Ein XL-Besprechungsraum/Boardroom ist ein Raum, der für eine größere Anzahl an Personen (14+) ausgelegt ist. Diese Räume werden in der Regel für große interne formelle Meetings und große Kundentermine genutzt. In den Räumen befindet sich hochwertiges und modernes IT Equipment, welches diverse Möglichkeiten für digitales Arbeiten bietet.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - US_SMALL and US_REGULAR
  lgOfficeDesc: 'Standardbüro sind private, geschlossene Arbeitsräume für Mitarbeiter. Sie sind ideal, um Ablenkungen zu reduzieren und den Fokus auf die Arbeit zu legen. Büros sind typischerweise mit einem Schreibtisch und einem Bürostuhl ausgestattet. Sie bieten unterschiedlich viel Platz für die Aufbewahrung und Ablage persönlicher Dinge. Die Größe des Raumes kann je nach den dort ausgeübten Tätigkeiten (z. B. regelmäßige Besprechungen) oder Mitarbeiterstatus/Hierarchien variieren.',
  smWorkstationDesc: 'Diese Einzelarbeitsplätze bestehen aus kleinen Arbeitstischen, die für temporäre Arbeit geeignet sind. Der Arbeitsplatz wird keinem Mitarbeiter zugeordnet. Die Arbeitsplätze befinden sich meist in offenen Flächen.',
  mdWorkstationDesc: 'Hierbei handelt es sich um den standardisierten Arbeitsplatz. Dieser wird sowohl in der offenen Fläche als auch in geschlossenen Büros eingesetzt und kann sowohl fest zugeordnet sein oder zur freien Verfügung stehen.',
  lgWorkstationDesc: 'Hierbei handelt es sich um den standardisierten Arbeitsplatz. Dieser wird sowohl in der offenen Fläche als auch in geschlossenen Büros eingesetzt und kann sowohl fest zugeordnet sein oder zur freien Verfügung stehen.',
  huddleRoomDesc: 'Huddle Räume sind geschlossene Räume, die darauf ausgerichtet sind, Treffen eines kleinen Personenkreises zu ermöglichen. Im Fokus steht der Wissensaustausch. Die Besprechungen sind spontan, schnell, unbürokratisch und teamorientiert. Die Räume verfügen über gute Akustikwerte und einen einfachen Zugang zu Strom und A/V-Funktionen.',
  multiPurpTrainingDesc: 'Mehrzweck-/Schulungsräume sind für eine große Anzahl von Personen ausgelegt. Dieser Raum ist entsprechend benannt, da hier viele verschiedene Aktivitäten wie Schulungen, Firmenveranstaltungen und große Präsentationen stattfinden können. Der Schnitt der Flächen und die Ausstattung dieses Raumes ist funktionell/langlebig, da dieser auf unterschiedlichste Weise genutzt wird und somit diverse Anforderungen unterstützen muss. Dieser Bereich muss über die entsprechende A/V-Anlage und -Support verfügen – in der Regel weitaus mehr als der durchschnittliche Konferenzraum.',
  informalCollabDesc: 'Diese Bereiche sollen kleine Gruppen (4 - 6 Personen) bei gemeinsamen Aktivitäten wie Brainstorming und zwanglosen Gesprächen zwischen Kollegen und mit Kunden unterstützen.',
  touchdownTeamDesc: 'Touchdowns sind individuelle, kleine interne Teamarbeitsbereiche, die über die gesamte Bürofläche verteilt sind und von den Mitarbeitern genutzt werden. Touchdowns können in Höhe, Form und Größe variieren, sollen sich aber von einzelnen Arbeitsplätzen als gesonderter Arbeitsort für kurze Arbeiten abheben. Dies sollten Plug-and-Play-Räume sein: einfach zu finden und zu nutzen.',
  frontDeskAreaDesc: 'Empfangstresen für Gäste und Mitarbeiter. Gleichzeitig handelt es sich hierbei um die zentrale Anlauf- und Informationsstelle.',
  mailCenterAreaDesc: 'Größere Unternehmen benötigen eine Poststelle, um alle Postdienste zu bündeln. Dazu gehören große Sortier- und Organisationsflächen sowie Platz für Charts und andere Geräte. Dieser Bereich sieht bei jedem Unternehmen unterschiedlich aus, da er oft zwischen anderen Elemente eingepasst wird.',
  kitchenAreaDesc: 'Küchenzeile bzw. ein Küchenraum, je nach Anforderungen des Unternehmens. In Abhängigkeit von der Größe und der Ausstattung kann die Küche über die Getränkeversorgung bis hin zur Essensvorbereitung anbieten.',
  serveryAreaDesc: 'Die Ausgabe ist der Bereich, in dem die Menschen ihr Essen bekommen. Sie ist in der Regel mit der Küche und dem Essbereich mit Tischen und Sitzgelegenheiten verbunden. Die Planung einer Speisenausgabe muss sehr präzise sein, um Warteschlangen intelligent zu handhaben. Die Größe und Form dieses Raumes variieren in der Regel von Unternehmen zu Unternehmen.',
  diningSupportAreaDesc: 'Ein separierter Küchenraum für aufwärmen bzw. Vorbereitung von Speisen für eigene Mitarbeiter bzw. separate Lieferanten.',
  diningAreaDesc: 'Der Essbereich sollte mehrere Sitzgelegenheiten und Platz zum Mittagsessen bieten. Durch verschiedene Bestuhlungsvarianten kann der Raum auch außerhalb der Mittagspause für Besprechungen und Zusammenarbeit genutzt werden. Die Mischung von weichen und härteren Sitzmöglichkeiten sowie unterschiedlichen Theken- und Sitzhöheneinstellungen gibt den Nutzern die Wahl.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDesc: 'Wenn Sie einen Gastronomieservice anbieten, gibt es mehrere Räume, die nötig sind, einschließlich einer Küche, Ausgabe, Essbereich (Sitzgelegenheiten) und Lagerung. Die Küche und die Ausgabe sind miteinander verbunden, damit die im Haus zubereiteten Speisen serviert werden können. Der Essbereich ist der Ort, an dem Mitarbeiter und Gäste sitzen und essen können, meist fungiert dieser Raum vielmehr als Zentrum für soziale Kontakte und Zusammenarbeit. Genau wie in jeder Privatwohnung bedarf es einer Vielzahl an Geräten und Stauraum, die erforderlich sind, um Gastronomieservices anzubieten, weshalb die Vorbereitungs- und Zusatzräume bei diesem Raumtyp vorab eingeplant sind.',

  // SpaceUnitRow
  spaceUnitRowDeleteTitle: 'Flächentyp löschen',
  spaceUnitRowDeleteText: 'Möchten Sie diesen Flächentyp löschen?',
  spaceUnitRowConfirmDeleteText: 'Ja',
  spaceUnitRowCancelDeleteText: 'Nein',

  // CustomAmenityAdder
  customAmenityAddText: 'Service hinzufügen',
  customAmenityPlaceholder: 'Ausstattungsart',

  // SpaceUnitTotals
  spaceUnitTotalsTitleNIA: 'Gesamt Netto-Innenfläche',
  spaceUnitTotalsTitle: 'Nutzfläche in Quadratfuß/-metern',
  spaceUnitTotalsCirculation: 'Verkehrsflächen',
  spaceUnitTotalsCirculationTooltip: 'Flure, Aufzugsvorräume, Foyers, die Fläche hinter den Sitzplätzen oder die Flächen um Türen und Schränke. Ohne Verkehrflächen hätten Sie keine Bewegungsmöglichkeit. Diese Flächen machen in der Regel einen hohen Anteil der Gesamtnutzfläche aus und variieren je nach Profil und Gebäudearchitektur.',
  spaceUnitTotalsREBNY: 'Real Estate Board of NY',
  spaceUnitTotalsREBNYTooltip: 'Für Flächenermittlung in New York erhöhen wirden REBNY-Maßstab um 15 %. Die nach REBNY-Messnorm gemessene Fläche umfasst Toiletten, Aufzugshallen und die Außenwände.',
  spaceUnitTotalsLossFactor: 'Verlustfaktor',
  spaceUnitTotalsLossFactorTooltip: 'Differenz zwischen der Miet- und Nutzfläche, die je nach Flächenermittlungsart (z.B. GIF, NF, BGF) unterschiedlich ausfällt.',

  // CollaborationDropdown
  collabDropdownLevelLowText: 'wenig (0-25%)',
  collabDropdownLevelMedText: 'einige (26-50%)',
  collabDropdownLevelHighText: 'die meiste (51-100%)',

  //DensityDropdown
  sameDensityText: 'gleich wie vor der Covid-19 Zeit bleiben wird',
  lowerDensityText: 'niedriger wird mit mehr m2 pro Person',
  notSureDensityText: 'da sind wir uns noch nicht sicher',


  //CirculationDropdown
  preCovidText: 'Wie vor der Covid-19 Zeit',
  higherText: 'Mehr Fläche (d.h. +5% bei offenen Büroformen als auch geschlossenen Räumen)',
  notSureText: 'Noch nicht sicher',

  // ExamplePlans
  examplePlanButtonText: 'Ansicht Beispielplan',
  examplePlanFullFloorText: 'Vollgeschoss',
  examplePlanPartialFloorText: 'Teilgeschoss',
  // examplePlanFullImgAlt: no translation yet
  // examplePlanPartialImgAlt: no translation yet
  examplePlanTooltipText: 'Ab einer bestimmten Größenordnung, die auch von der Gebäudearchitektur abhängig ist, werden Sie ggf. mehr als ein Stockwerk benötigen.',

  // ProgramStats
  programStatsDensitySubtitleTsubo: 'Tsubo',
  programStatsDensitySubtitle: 'quadratisch',
  programStatsIndivTitle: 'Einzelsitzplätze',
  programStatsWorkstationsSubtitle: 'offen',
  programStatsOfficesSubtitle: 'geschlossen',
  programStatsCollabTitle: 'Kollaboration',
  programStatsEnclosedSubtitle: 'Kollaborationsmöglichkeiten',
  programStatsIndivSeatSubtitle: 'Einzelarbeitsplatz',
  programStatsSeatsTitle: 'Arbeitsplätze pro Person',
  programStatsIndivSeatsSubtitle: 'Einzelsitzplätze',
  programStatsPersonSubtitle: 'Person',
  programStatsDensityTitle: 'Effizienz',
  programStatsFullDensitySubtitle: 'pro Einzelarbeitsplatz',

  // Profile Display Names (profileNames.js)
  efficientName: 'The Optimizer',
  whiteCollarName: 'The Accommodator',
  whiteGloveName: 'The Enhace',
  openOfficeName: 'The Producer',
  workplace360Name: 'The Connector',
  campusName: 'The Differentiator',
  startupName: 'The Incubator',
  engCreativeName: 'The Creator',

  // Profile Descriptions (profileDescriptions.js)
  efficientDesc: 'Das "Optimiser-Büro" bietet eine sachliche, schlichte Arbeitsumgebung, die es ermöglicht, die Arbeit zu erledigen. Offen konzipierte Flächen unterstützen individuelles Arbeiten am Schreibtisch, ausgestattet mit allen notwendigen Tools. Das Büro verfügt über konventionelle Support-Bereiche. Die Gruppenarbeitsbereiche sind traditionell als separate Räume gestaltet. Wie der Name schon sagt, diese Arbeitsumgebung ist auf maximale Effizienz ausgelegt.',
  whiteCollarDesc: 'Als Accommodator Büro sind Sie nicht alleine - dies ist eines der beliebtesten Profile für Firmenarbeitsplätze. Accommodator Büros ziehen es vor, die Flächen nicht mit radikalem Raumdesign zu erschüttern, sondern investieren oft in neue Technologien und Möbeln, um den Raum produktiv und ansprechend zu gestalten. In einem ausgewogenen Verhältnis von individuellen und gemeinschaftlichen Räumen befinden sie die Mitarbeiter meist an ihren Schreibtischen mit gelegentlichen Meetings und Teambesprechungen.',
  whiteGloveDesc: 'Das "Enhancer-Büro" ist besonders stark auf die Bedürfnisse der Mitarbeiter und Kunden ausgelegt, welche häufig gesehene Besucher des Büros sind. Die Flächen bieten verschiedenste Arbeitsmodule, die Activity-Based-Working ermöglichen. Die technische Ausstattung der Mitarbeiter und Flächen fördert die Mobilität sowohl innerhalb als auch außerhalb des Büros. Unabhängig davon, ob formal ein "Agile Program" bereits implementiert ist oder nicht: Dieses Büromodell schafft die Grundlagen für flexibles Arbeiten - gegenwärtig und mit Blick in die Zukunft.',
  openOfficeDesc: 'Die Flächen des "Producer-Büros" sind optimal auf die verschiedenen Tätigkeiten der Mitarbeiter abgestimmt. Bei diesem Profil stehen mehrere Raumtypen zur Verfügung, darunter Einzel-, Gruppen- und Supportbereiche, die sowohl das konzentrierte wie auch das kollaborative Arbeiten unterstützen. Flexibles Arbeiten und Desksharing sorgen für eine höchst effiziente Nutzung der Fläche.',
  workplace360Desc: 'Genau wie Ihre Lieblings-Yogastellung, ist das Connector Büro stolz auf seine Ausgewogenheit. Mit der richtigen Mischung aus produktivitätsorientiertem Raum und sozialem Verbindungsraum bietet das Connector Büro alle wesentlichen Voraussetzungen für die Fokussierung und fördert gleichzeitig die Auswahl mit einer Vielzahl anderer Räume. Getreu seinem Namen befindet sich das Connector Büro in einer Zentralen Lage mit Gemeinschaftsräumen wie Küchen und offenen Besprechungsräumen. Das hilft, Menschen zusammenzubringen und schafft ein Gefühl der Gemeinschaft.',
  campusDesc: 'Das "Differentiator-Büro" möchte man bei Feierabend am liebsten gar nicht verlassen. Dieses Konzept bietet eine erstklassige Arbeitsumgebung und Atmosphäre an, die sich in jeder Hinsicht vom Standard abhebt. Es beinhaltet verschiedene Einrichtungen und Annehmlichkeiten rund um das Thema Wellness, Fitness und Ernährung, so dass den Mitarbeitern der höchstmögliche Komfort angeboten wird.',
  startupDesc: 'Schnelligkeit und Ideenfindung prägen das "Incubator-Büro". Die Mitarbeiter arbeiten in hoch kollaborativen Teams und denken unternehmerisch. Dieses Büroumfeld befindet sich in einem ständigen Wandel, deswegen ist bei einem Großteil der Flächen die Flexibilität gleich Teil des Designs. Die Einrichtung des Büros ist mobil und lässt schnelle Veränderungen zu. Durch ein Mix von geselligen Aktivitäten und intensiven Arbeitsphasen wird das Gemeinschaftsgefühl im Team gestärkt.',
  engCreativeDesc: 'Wir verstehen das "Creator-Büro" als eine Arbeitsumgebung der Experten ihrer Branche. Wie jedes großartige kreative Unterfangen, lebt das "Creator-Büro" von Zusammenarbeit und Teamwork. Teamwork kann dabei sowohl in dezitierten Räumen, als auch im Open-Space Bereich stattfinden. Interaktives Arbeiten, Aktivitäten zur Ideenfindung und das richtige Equipment sind mit dieser Art des Arbeitens fest verbunden. Mitarbeitern wird dafür der Raum geschaffen, den sie benötigen, um effektiv arbeiten zu können.',


  // Profile Key Traits: Shared Names (profileTraitMap.js)
  social: 'Sozial',
  efficient: 'Effizienz',
  flexible: 'Flexibel',
  enabled: 'Effektiv',
  productive: 'Produktiv',
  inclusive: 'Inkludiert',
  clientFocused: 'Kundenorientiert',
  standardized: 'Einheitlich',

  // Profile Key Traits (profileTraitMap.js)
  social1Desc: 'Zwischenmenschliche Beziehungen bei der Arbeit und auch außerhalb sind wichtig',
  social2Desc: 'Viele teambildende Maßnahmen',
  social3Desc: 'Die Mitarbeiter verbringen gerne Zeit miteinander',
  social4Desc: 'offen und gemeinschaftsorientiert',
  efficient1Desc: 'eine schlanke Organisationsstrukur mit einem kostenbewussten Budget',
  efficient2Desc: 'eine schlanke Organisationsstrukur mit einem kostenbewussten Budget',
  flexible1Desc: 'Mitarbeiter können ihre Arbeit aktiv gestalten',
  flexible2Desc: 'Mitarbeiter haben viel Freiraum',
  flexible3Desc: 'Schaffen Sie den Raum, den Sie brauchen',
  enabled1Desc: 'Mitarbeiter finden an ihren Arbeitsplätzen das Nötigste vor',
  enabled2Desc: 'Mitarbeitern stehen verlässliche Services und Komfort zur Verfügung',
  enabled3Desc: 'Mitarbeiter sind gut gut ausgestattet',
  enabled4Desc: 'Hochwertiges und gut abgestimmtes Equipment',
  enabled5Desc: 'Spezielles und vielfältiges Equipment ist vorhanden',
  productive1Desc: 'Funktionales und schlichtes Bürodesign',
  productive2Desc: 'Business orientiert und kostenbewusst',
  productive3Desc: 'Zugeschnitten auf ein spezielles Produkt/Serviceangebot',
  inclusive1Desc: 'Vielfältige Arbeitsmodule sind verfügbar',
  inclusive2Desc: 'Das Büro ist bestens ausgestattet',
  inclusive3Desc: 'Verschiedene Arbeitsstile sind umsetzbar',
  clientFocused1Desc: 'Der erste Eindruck zählt',
  clientFocused2Desc: 'Das Büro wirkt einladend für Kunden und neue Mitarbeiter',
  clientFocused3Desc: 'Das Büro wirkt hochwertig auf Kunden und Mitarbeiter',
  standardized1Desc: 'Systeme und Arbeitsprozesse sind optimiert',
  standardized2Desc: 'Feste Standards für die Büroflächen',
  standardized3Desc: 'Modulare Flächen sorgen für Flexibilität',
  standardized4Desc: 'Optimierte Prozesse und Arbeitsergebnisse',

  // SpaceSummary
  spaceSummaryBarHeader: 'Indikative Bürogröße',
  spaceSummaryMeasurementTypeText: 'Flächenart',
  spaceSummaryUnitTypeText: 'Einheiten',
  spaceSummaryLossFactorSectionLabel: '{{factor}} Details über',
  spaceSummaryFitFactorText: 'Anpassungsfaktor',
  spaceSummaryLossFactorText: 'Verlustfaktoren',
  spaceSummaryRSFLossFactorHelperText: 'Basierend auf einem {{percentage}}% Eignungsfaktor für Immobilien, sollten Sie nach Büroflächen mit{{amt}} NIA Fläche suchen.',
  spaceSummaryNIAFitFactorHelperText: 'Die Netto-Innenfläche (NIA) ist die nutzbare Fläche innerhalb eines Gebäudes, gemessen an der Innenfläche der Außenwände in jeder Etage, wobei bestimmte spezifizierte Bereiche ausgeschlossen sind.',
  spaceSummaryNIATooltipText: 'Diese Messmethode umfasst alle Räume, Möbel und Verkehrsflächen.',

  // SFTypeRadioInput
  sfRadioInputUsableAreaTooltipText: 'reine Büronutzfläche',
  sfRadioInputRentableAreaTooltipText: 'Gesamtnutzfläche inkl. sonstigen Nutzflächen, Verkehrs- und Gemeinschaftsflächen',
  sfRadioInputUsableSquareText: 'Nutzfläche {{unitUppercase}}',
  sfRadioInputRentableSquareText: 'Mietfläche {{unitUppercase}}',

  //floored plan request block
  flooredTitle: 'Bitte geh‘ noch nicht!',
  flooredSubTitle: 'Erfinde die Art, Raum zu erkunden, völlig neu !',
  flooredSubText: 'Lass‘ Dir die Ergebnisse in einem Grundriss und in 3D anzeigen',
  flooredParagraphText1: 'Realitätsnahe Visualisierungen machen es möglich, verschiedene Varianten durchzuspielen, mit Raum zu experimentieren und Ideen in Realität umzusetzen.',
  flooredParagraphText2: 'Erlebe konkrete, maßgeschneiderte Vorschläge für deine künftige Bürofläche.',
  flooredVideoHeading: 'She’ Dir ein Beispiel an.',
  flooredFreeText: 'Und noch dazu … es kostet nichts!',
  flooredIconText1: 'Teile Deine verfügbaren Grundrisse',
  flooredIconSubText1: 'AutoCAD-Dateien oder PDFs',
  flooredIconText2: 'Erlaube uns etwas Verarbeitungszeit',
  flooredIconSubText2: 'Ein bis zwei Wochen',
  flooredIconText3: 'Teile Deine virtuelle Planung mit anderen',
  flooredIconSubText3: 'Individuelle 2D- und 3D-Pläne',


  // MeasurementSystemRadioInput
  measurementSystemSqFeetText: 'Quadratfuß',
  measurementSystemSqMetersText: 'Quadratmeter',
  measurementSystemTsuboText: 'Tsubo',

  // ProfileHero
  resultsProfileHeroAnnouncementTitle: 'Ihr Büro ist',
  resultsProfileHeroImgAlt: '3D floor plan showing the office layout of the {{profileName}} profile from Spacer by CBRE',

  // ProfileComparison
  profileCompModalTitle: 'Ihr Profil ändern ?',
  profileCompModalText: 'Sie verlieren alle nicht gespeicherten Änderungen in Ihrem aktuellen Raumfahrtprogramm',
  profileCompModalConfirmText: 'Ja, Profil ändern',
  profileCompModalCancelText: 'Nein, aktuelles Profil beibehalten',
  profileCompNavbarCancelButtonText: 'Abbrechen',
  profileCompNavbarUseButtonText: 'Dieses Profil verwenden',
  profileCompCompareTitle: 'Die für Sie am nächsten passenden errechneten Profile vergleichen',

  // ProfileComparisonColumn
  profileCompColumnSelectedText: 'Ausgewähltes Profil',
  profileCompColumnSelectText: 'Auswählen',
  profileCompColumnRecommendedText: ' (empfohlen)',
  profileCompColumnImgAltText: ''

};
