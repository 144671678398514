const _                             = require('lodash');
const React                         = require('react');
const PropTypes                     = require('prop-types');
const AutocompleteInput             = require('../form/AutocompleteInput.jsx');


class AlphabetizedAutocompleteInput extends React.Component {
  constructor(props) {
    super(props);

    this.allDropdownOptions = {};
    this.allOptionOrder = [];
    props.dropdownOptions.forEach((option) => {
      this.allDropdownOptions[option] = { text: option };
      this.allOptionOrder.push(option);
    });

    this.getAutocompleteOptions = this.getAutocompleteOptions.bind(this);
  }

  getAutocompleteOptions(value, callback) {
    let dropdownOptions = {};
    let optionOrder = [];
    this.props.dropdownOptions.forEach((option) => { // some optimization possible but this is fine
      if (_.includes(option.toLowerCase(), value.toLowerCase())) {
        dropdownOptions[option] = { text: option };
        optionOrder.push(option);
      }
    });

    if (optionOrder.length === 0) { // If there are no matches, show everything
      dropdownOptions = this.allDropdownOptions;
      optionOrder = this.allOptionOrder;
    }

    optionOrder.sort();

    this.props.onValueChange(value); // user is allowed to type whatever they want
    callback({ dropdownOptions, optionOrder });
  }

  render() {
    return (
      <AutocompleteInput
        {...this.props}
        getAutocompleteOptions={this.getAutocompleteOptions}
        allowValueNotInDropdown={this.props.allowValueNotInDropdown}
        isInactive={this.props.isInactive}
        placeholder={this.props.placeholder}
      />
    );
  }
}

AlphabetizedAutocompleteInput.propTypes = {
  dropdownOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  allowValueNotInDropdown: PropTypes.bool,
  isInactive: PropTypes.bool
};

AlphabetizedAutocompleteInput.defaultProps = {
  allowValueNotInDropdown: false,
  placeholder: '',
  isInactive: false
};

module.exports = AlphabetizedAutocompleteInput;
