const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const spaceDataActions       = require('../../../../redux/actions/spaceData');
const ProgramAssumptions     = require('../../../results/program/ProgramAssumptions.jsx');
const programDataRetriever   = require('../../../../util/programDataRetriever');
const regionSFCalculator     = require('../../../../../shared/calculators/regionSFCalculator');
const constants              = require('wp-constants').spacerFree;

// Map state to props
function mapStateToProps(state) {
  const totalArea = programDataRetriever.getTotalSF(state.spaceData.program.areaTotals);

  return {
    assumptions: state.spaceData.assumptions,
    measurementSystem: state.spaceData.measurementSystem,
    sfType: state.spaceData.sfType,
    totalRSF: regionSFCalculator.getAreaForContext(
      totalArea,
      constants.calculator.SF_TYPE.RSF,
      state.userData.officeLocation,
      state.spaceData.lossFactor
    ),
    profileType: state.spaceData.profileType,
    currentProfileId: state.spaceData.profileId,
    programId: state.spaceData._id
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    updateKeyAssumption: bindActionCreators(spaceDataActions.updateKeyAssumption, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(ProgramAssumptions);

