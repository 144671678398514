module.exports = {

  // Results
  resultsWarningMessage: '¿Está seguro de que quiere abandonar esta página? Puede perder la información que no haya guardado.',

  // ResultsHeader
  resultsHeaderExportPDFButtonText: 'Exportar a PDF',
  resultsHeaderSendButtonText: 'Enviar resultados',
  resultsHeaderClipboardBoxDescription: 'Cualquiera con este enlace puede ver y editar los resultados',
  resultsHeaderContactButtonText: 'Contactar a un consultor',

  // EmailWallSection
  emailWallTitle: 'Hay más esperando por usted',
  emailWallSubtitle: 'Ingrese su nombre y correo electrónico abajo para ver una recomendación completa de espacio y personalizar sus resultados',
  emailWallFirstNamePlaceholder: 'Nombre',
  emailWallLastNamePlaceholder: 'Apellido',
  emailWallEmailPlaceholder: 'Su correo electrónico',
  emailWallCBREEmailPlaceholder: 'Su correo corporativo de CBRE (opcional)',
  emailWallLeasingProfessionalPlaceholder: 'Su profesional de CBRE',
  emailWallConnectMeText: 'Contactar me con un consultor inmobiliario personal.  Si newsletters ni spam, ¡lo prometemos!',
  emailWallContactNoticeText: 'El equipo de Spacer se contactará con usted para obtener lo mejor de su programa. Sin spam, ¡lo prometemos!',
  emailWallResultsButtonText: 'Ver resultados completos',
  emailWallImgAltText: 'Vista previa de una página de resultados de programa del muro de Spacer de CBRE',
  emailWallFirstNameInvalid: 'Por favor ingrese su nombre.',
  emailWallLastNameInvalid: 'Por favor ingrese su apellido.',
  emailWallEmailInvalid: 'Por favor ingrese una dirección de correo válida.',

  // CopyToClipboardBox
  clipboardBoxCopyText: 'Copiar',
  clipboardBoxCopiedText: 'Copiado',

  // FormModal
  formModalFindSpaceHeader: 'Empezar la búsqueda de oficina',
  formModalFindSpaceBody: 'Spacer es una herramienta rápida y fácil para el análisis del espacio de trabajo, sin embargo, si usted requiere algo más personalizado, permita que CBRE Workplace le ayude. Diligencie este formato y un profesional de Workplace lo contactará dentro de las próximas 48 horas para hablar.',
  formModalFindSpaceSuccessMessage: 'Su solicitud ha sido enviada.',

  // SuccessModal
  successModalTitle: '¡Éxito!',

  // ContactForm
  contactFormFirstNameLabel: 'Nombre',
  contactFormLastNameLabel: 'Apellido',
  contactFormEmailLabel: 'Correo electrónico corporativo',
  contactFormPhoneLabel: 'Número de teléfono',
  contactFormMessageLabel: 'Cuéntenos más sobre lo que está buscando',
  contactFormSubmitText: 'Enviar',

  // AcceptTermsText
  acceptTermsText: 'Yo acepto el <1> Términos y condiciones . </1>. Para mayor información en el uso de sus datos, por favor refiérase a la <3>Política de Privacidad de CBRE.</3>.',
  privacyPolicyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Form
  formErrorText: 'Ups, ocurrió un error. ¡Por favor trate nuevamente!',
  formEmailInvalid: 'Por favor ingrese una dirección de correo válida.',

  // FormInput
  formInputOptionalText: '  (opcional)',

  // LegalForm
  legalFormCheckboxText: 'CBRE puede ponerse en contacto con usted para ofertas e información relacionada con el uso de Spacer.',
  legalFormContactWarningText: 'El equipo de Spacer se contactará con usted para obtener lo mejor de su programa. Sin spam, ¡lo prometemos!',

  // NextStepsSection
  nextStepsSectionSubtitle: '¿Listo para dar el siguiente paso?',
  nextStepsSectionTitle: 'Encuentre la oficina perfecta.',
  nextStepsSectionButtonText: 'Contáctese con un consultor',
  nextStepsSectionImgAlt: 'Ilustración de personas conversando en la página de resultado de Spacer de CBRE',

  // Profile
  resultsProfileSummaryTitle: 'Su oficina —',
  resultsProfileTooltipText: 'Cada perfil es una combinación de organizaciones y lugares de trabajo reales; los detalles se compararon con los parámetros de la industria y el conocimiento del mercado exclusivo de CBRE Workplace.',

  // Program
  programSaveConfirmationBoxText: 'Está listo. Vía correo electrónico le enviamos un enlace a sus resultados. ¡Disfrute!',
  programExamplePlansTitle: 'Planos ejemplo',
  programExamplePlansSubtitle: 'Disfrute los planos de planta interactivos que coinciden con su perfil. ¡Incluso puede explorarlos en 3D!',
  programTooMuchTinkerWarning: '¡Alto ahí! Parece que está fuera del rango recomendado según su perfil. Considere la posibilidad de <1>volver a realizar el cuestionario</1> o ponerse en contacto con el servicio de asistencia técnica en <3>{{email}}</3>..',
  programDisclaimer: 'Spacer de CBRE se proporciona "tal cual" y "según disponibilidad" sin garantías. Todos los resultados generados a partir de su uso de Spacer son solo para fines ilustrativos y no reflejan recomendaciones de espacio reales ni términos de arrendamiento sugeridos. Las recomendaciones de espacio reales pueden variar mucho y dependerán de muchos factores que Spacer no tiene en cuenta. Al usar Spacer, o cualquier informe generado por el uso de Spacer, usted acepta nuestros Términos de servicio y Política de privacidad ubicados respectivamente en las URL https://workplace.cbre.com/termsOfService y https://www.cbre.us/about /política de privacidad. © 2018 CBRE, Inc. Todos los derechos reservados.',
  programMetricsTitle: 'Métricas del programa',
  programMetricsSubtitle: 'Estos números resumen su entorno de trabajo',
  programDetailsSectionTitle: 'Personalice su programa',
  programDetailsSectionSubtitle: 'Ajuste los campos a continuación para perfeccionar sus necesidades de espacio.',
  programRevertButtonText: 'Revierta',
  programSaveButtonText: 'Guardar cambios',
  programSavedButtonText: 'Guardado',

  // ProgramAssumptions
  programAssumptionsRecommendedOfficeSizeText: 'Tamaño de oficina recomendado',
  programAssumptionsProfileText: 'Perfil',
  programAssumptionsHeadcountText: 'Número de empleados',
  programAssumptionsHeadcountFollowingText: 'personas',
  programAssumptionsCollabText: 'Colaboración',
  programAssumptionsCirculationText: 'Circulación',
  programAssumptionsAvgDaysText: 'Promedio de días en la oficina',
  programAssumptionsAvgDaysFollowingText: 'Por semana',
  programAssumptionsAvgDaysPerWeekText: 'Promedio de días en la oficina por semana',

  // ProgramHeroForPrint
  printNIAText: 'Área Interna Neta',
  printUSFText: 'Pies Cuadrados usables',
  printRSFText: 'Pies Cuadrados rentables',
  printDescText: 'Según sus datos y un {{lossFactorPercentage}}% {{factorType}} en {{city}}, recomendamos un total de:',
  printFitFactorText: 'Factor de ajuste',
  printLossFactorText: 'Factor de pérdida',

  // BarChart
  barChartIndivSubtitle: 'Espacio individual',
  barChartGroupSubtitle: 'Espacio grupal',
  barChartAmenitySubtitle: 'Espacio de comodidad',

  // TabbedProgramBarChart
  tabbedBarChartMeSubtitle: 'Individual',
  tabbedBarChartGroupSubtitle: 'Grupo',
  tabbedBarChartAmenitySubtitle: 'Servicios',

  // SpaceCategoryBreakdown
  spaceCatBreakdownSubtitle: 'Seleccione un tipo de espacio para personalizar',

  // SpaceCategoryBreakdownMobile
  spaceCatBreakdownMobileMeTitle: 'Espacio individual',
  spaceCatBreakdownMobileWeTitle: 'Espacio grupal',
  spaceCatBreakdownMobileAmenityTitle: 'Espacio de comodidad',
  spaceCatBreakdownMobileMeSubtitle: 'Para trabajar solo',
  spaceCatBreakdownMobileWeSubtitle: 'Para trabajar juntos',
  spaceCatBreakdownMobileAmenitySubtitle: 'Para todo lo demás',
  spaceCatBreakdownMobileNIATotalTitle: 'Área Interna Neta Total',
  spaceCatBreakdownMobileTotalTitle: 'Total {{sfType}} Cuadrado {{unitCapitalized}}',
  spaceCatBreakdownMobileCirculationText: 'Circulación',

  // SpaceCategoryDropdown
  spaceCatDropdownMeText: 'Espacio individual',
  spaceCatDropdownWeText: 'Espacio grupal',
  spaceCatDropdownAmenityText: 'Espacio de servicios',

  // SidebarInfo
  sidebarInfoMeTitle: 'Individual',
  sidebarInfoWeTitle: 'Grupal',
  sidebarInfoAmenityTitle: 'Servicios',

  // Space Category Descriptions (spaceCategoryDescription.js)
  spaceCatMeDesc: 'Espacio para trabajo individual. Ofrecer opciones sobre dónde y cómo trabajan sus empleados conduce a una mayor satisfacción de los empleados.',
  spaceCatWeDesc: 'Espacio para colaboración y trabajo en equipo. En una era en la que el trabajo puede realizarse en cualquier lugar, es importante proporcionar diferentes espacios que se adapten a una variedad de tareas.',
  spaceCatAmenityDesc: 'Espacio que apoya el trabajo. Excelentes comodidades harán que su oficina sea un lugar en el que sus empleados (y reclutas) deseen pasar tiempo.',

  // Suggested Custom Amenities (suggestedCustomAmenityUnitMap.js)
  audioVisualCustom: 'Audio / Visual',
  dataCenterCustom: 'Data Center',
  buildingSupportCustom: 'Servicios de apoyo a la construcción',
  foodServiceCustom: 'Completo Servicio de comida',
  kitchenCustom: 'Cocina',
  serveryCustom: 'Servidor',
  execDiningCustom: 'Comedor ejecutivo',
  grabNGoCustom: 'Para llevar',
  juiceCoffeeBarCustom: 'Barra de jugos / Barra de café',
  childCareCustom: 'Cuidado de los niños',
  fitnessCenterCustom: 'Gimnasio',
  healthCenterCustom: 'Centro de salud',
  mailCenterCustom: 'Centro de correo',
  commercialPrintCustom: 'Sala de impresión comercial',
  centralCafeCustom: 'Cafetería central',
  securityCtrlCustom: 'Sala de control de seguridad',
  auditoriumCustom: 'Auditorio',
  dryCleaningLaundryCustom: 'Lavandería',
  confCenterCustom: 'Centro de conferencias',
  multiPurpTrainingCustom: 'Multiusos/Entrenamiento',
  informalCollabCustom: 'Colaboración Informal',
  touchdownTeamCustom: 'Anotación / Equipo',

  // Suggested Custom Amenity Units (spaceUnit.js)
  seats: 'silla',
  seats_plural: 'sillas',
  rooms: 'sala',
  rooms_plural: 'salas',
  areas: 'área',
  areas_plural: 'áreas',
  units: 'unidad',
  units_plural: 'unidades',

  // Predefined Amenities (spaceUnitMap.js) - Shared Space Units
  focusRoomDefined: 'Sala de concentración',
  fileRoomDefined: 'Cuarto de archivo',
  centralReceptionHubDefined: 'Centro de recepción central',
  smOfficeDefined: 'Oficina pequeña',
  regOfficeDefined: 'Oficina regular',
  benchDesk5Defined: '5’  Escritorio',
  benchDesk6Defined: '6’  Escritorio',
  meetingRoom4Defined: 'Sala de Reuniones (4 personas)',
  meetingRoom6Defined: 'Sala de Reuniones (6 personas)',
  meetingRoom8Defined: 'Sala de Reuniones (8 personas)',
  meetingRoom12Defined: 'Sala de Reuniones (12 personas)',
  meetingRoom15Defined: 'Sala de Reuniones (15 personas)',
  semiEnclosedMeetingBooth4Defined: 'Cabina de Reuniones semicerrada (4 personas)',
  openCollabSpace4to6Defined: 'Espacio abierto de colaboración (4-6 personas)',
  contemplationSpaceDefined: 'Espacio de contemplación',
  mainITEquipRoomDefined: 'Servidor principal de TI/Sala de equipos',
  receptionAreaDefined: 'Área de recepción',
  cafeFoodServiceDefined: 'Cafetería / Servicio de comida',
  teamStorageDefined: 'Almacenamiento de equipo',
  coatClosetsDefined: 'Armarios para abrigos',
  genStorageDefined: 'Almacenamiento general (almacenes y entrada/salida de mercancías)',
  centralisedCopyPrintMailDefined: 'Copia, impresión y correo centralizados',
  specialResourceAreaDefined: 'Área de recursos especiales',
  personalStorageDefined: 'Almacenamiento Personal (Lockers)',
  storageITSpaceDefined: 'Espacio de Almacenamiento TI',
  secondaryServerRoomDefined: 'Servidor Secundario / Cuarto de Telecomunicaciones',
  mothersRoomDefined: 'Sala de Lactancia',
  buildITSpaceDefined: 'Cuarto de TI',
  workerCouncilOffice2Defined: 'Oficina de Consejo a Trabajador (2 personas)',
  securityCtrlRoomDefined: 'Cuarto de Control de Seguridad',
  centralisedMailDefined: 'Centralización de Correo',
  visitorCoatLuggageRoomDefined: 'Armario de Abrigos / Equipaje Visitantes',
  wellnessRoomDefined: 'Cuarto de Bienestar',
  internalStairDefined: 'Escalera interna',
  commercialPrintRoomDefined: 'Cuarto de Impresión Comercial',
  buildingSupportServicesAreaDefined: 'Área de servicios de apoyo al edificio',

  // Predefined Amenities (spaceUnitMap.js) - US_SMALL and US_REGULAR
  officeDefined: 'Oficina',
  lgOfficeDefined: 'Oficina (grande)',
  smWorkstationDefined: 'Puesto de Trabajo (pequeño)',
  mdWorkstationDefined: 'Puesto de Trabajo (mediano)',
  lgWorkstationDefined: 'Puesto de Trabajo (grande)',
  huddleRoomDefined: 'Sala de reuniones',
  dispersedCopyPrintAreaDefined: 'Área de Copiado / Impresión',
  smMeetingRoomDefined: 'Sala de Reuniones Pequeña',
  mdMeetingRoomDefined: 'Sala de Reuniones Mediana',
  lgMeetingRoomDefined: 'Sala de Reuniones Grande',
  xlMeetingRoomDefined: 'Sala de Reuniones Extragrande / Sala de Juntas',
  multiPurpTrainingDefined: 'Multi propósito / Entrenamiento',
  informalCollabDefined: 'Área de Colaboración Informal',
  touchdownTeamDefined: 'Área de Equipo',
  pantryVendingCoffeeAreaDefined: 'Punto de Café',
  workplaceStorageRoomDefined: 'Sala de Almacenamiento en espacio de trabajo',
  employeeCoatAreaDefined: 'Área de Abrigos para Empleados',
  dispersedMailAreaDefined: 'Área de Correos',
  lockerUnitDefined: 'Unidad de Locker',
  serverRoomDefined: 'Cuarto de Servidor',
  frontDeskAreaDefined: 'Área de Recepción',
  centralCafeAreaDefined: 'Área Central de Café',
  mailCenterAreaDefined: 'Área de Centralización de Correos',
  kitchenAreaDefined: 'Área de Cocina',
  drawerLateralFileUnitDefined: 'Archivador Lateral de 3 Cajones',
  serveryAreaDefined: 'Área de Servicio',
  juiceBarCoffeeShopAreaDefined: 'Barra de Jugos / Café',
  diningSupportAreaDefined: 'Espacio Soporte de Comedor',
  diningAreaDefined: 'Área de Comedor',
  grabNGoAreaDefined: 'Área de Snacks',
  focusRoomSharedFocus: 'Cuarto de Enfoque / Concentración',

  // Predefined Amenities (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDefined: 'Servicio Completo de Comida',

  // Predefined Amenities (spaceUnitMap.js) - APAC_REGULAR
  officeWorkMeetDefined: 'Oficina (Trabajo/Reunión)',
  lgOfficeWorkMeetDefined: 'Oficina Grande (Trabajo/Reunión)',
  focusDefined: 'Concentración / Enfoque',
  bench1pt6Defined: 'Banca de 1.6',
  bench1pt8Defined: 'Banca de 1.8',
  bench1pt8LDefined: 'Puesto de Trabajo 1.8  en "L"',
  banquetWorkMeetDefined: 'Mesa Trabajo/Reunión (2p)',
  cafeTable4Defined: 'Mesa de Café (4p)',
  kitchenTable8to10Defined: 'Mesa de Cocina (8-10p)',
  standingWhiteboardDefined: 'Tablero / Pizarra',
  huddleRoom3Defined: 'Sala de Reuniones (3p)',
  smMeetingRoom5Defined: 'Sala de Reuniones Pequeña (5p)',
  mdMeetingRoom8Defined: 'Sala de Reuniones Mediana (8p)',
  lgMeetingRoom20Defined: 'Sala de Reuniones Grande (20p)',
  xlBoardroom: 'Extragrande / Sala de Juntas',
  wellnessRoomsDefined: 'Salas de Bienestar',
  employeeCoatsGymLockersDefined: 'Abrigos de Empleados / Lockers del Gimnasio',
  lockersDefined: 'Lockers',
  dispersedCopyPrintScanAreaDefined: 'Área de Copiado / Impresión / Escáner',
  hydrationPointDefined: 'Punto de Hidratación (Agua / Té / Café)',
  centralHighDensityStorageDefined: 'Almacenamiento Central de Alta Densidad',
  wellnessMothersRoomDefined: 'Bienestar / Sala de Lactancia',
  serverHubRoomsDefined: 'Servidor / Sala Central',
  buildITStoreRoomDefined: 'Cuarto de TI / Almacenamiento',
  simpleReceptionWaitDefined: 'Recepción + Sala de Espera',
  centralCafeSocialHubDefined: 'Café Central - Zona Social',
  mailCentreDefined: 'Centro de Correos',
  utilityStoreRoomDefined: 'Cuarto de Servicios',
  drawerLateralFileDefined: 'Archivador Lateral de 3 Cajones',
  juiceBarCoffeeShopDefined: 'Bar de Jugos / Café',
  buildingSupportServicesDefined: 'Servicios de Soporte del Edificio',

  // Space Unit Map Descriptions (spaceUnitMap.js) - Shared Descriptions
  focusRoomDesc: 'Las Salas de Enfoque son espacios cerrados destinados a apoyar el trabajo concentrado y/o confidencial normalmente para una o dos personas. La privacidad acústica es primordial en estos espacios. Las salas de enfoque generalmente no están asignadas y se pueden reservar temporalmente según sea necesario para el trabajo relevante.',
  fileRoomDesc: 'Este es el cuarto de almacenamiento de archivos para individuos y equipos. Como resultado de los esfuerzos de digitalización de la mayoría de las oficinas la necesidad de almacenamiento de archivos, tanto individuales como de equipo, está disminuyendo considerablemente. Aún así, este es un espacio central para copias de documentos requeridos legalmente o materiales de proyectos a largo plazo, especialmente con la mayoría de los escritorios de oficina abiertos que contienen poco espacio de almacenamiento.',
  centralReceptionHubDesc: 'El área de recepción es la primera oportunidad de su empresa para impresionar a los empleados, clientes y otros visitantes. Debe reflejar consistentemente la marca de la empresa y proyectar la apariencia deseada. El mostrador de la recepción debe acomodar cómodamente a un recepcionista; los asientos suaves deben invitar a los visitantes.',
  officeDesc: 'Las oficinas son espacios de trabajo individuales, cerrados y privados para los empleados. Son excelentes para reducir la distracción y permiten llevar a cabo el trabajo de concentración. Las oficinas suelen estar equipadas con un escritorio y un asiento de trabajo junto con asientos para invitados. Encontrará cantidades variables de almacenamiento personal y archivo en las oficinas. El tamaño del espacio también puede variar según las actividades que se realicen en la sala (p. ej., reuniones periódicas) o el estado/jerarquía de los empleados.',
  benchDeskDesc: 'Los "escritorios de banco" son asientos de trabajo individuales abiertos. Los empleados de tiempo completo pueden usar "escritorios de banco" más pequeños, ya sea temporalmente (en un espacio de dirección libre) o permanentemente. Estos escritorios también son excelentes para los empleados o contratistas visitantes que no están en la oficina todo el tiempo. Algunas ventajas de los escritorios abiertos son la visibilidad de la oficina y la colaboración inherente. Una desventaja puede ser la distracción del ruido, por lo que se recomienda que las oficinas con "escritorios de banco" abiertos tengan muchas salas de concentración y reunión. La presentación en el escritorio se limita a la presentación comunitaria probablemente ubicada en todo el piso.',
  meetingRoomDesc: 'Las salas de reuniones son espacios cerrados destinados a reuniones de grupos pequeños de 2 a 4 personas. Deben estar equipados con equipos de A/V y pizarras blancas para facilitar el intercambio de conocimientos y deben ser acústicamente privados (las personas que se sientan inmediatamente fuera de la sala no deben poder escuchar las conversaciones que se desarrollan en el interior).',
  meetingRoomDesc6: 'Las salas de reuniones son espacios cerrados destinados a reuniones de grupos pequeños de 2 a 4 personas. Deben estar equipados con equipos de A/V y pizarras blancas para facilitar el intercambio de conocimientos y deben ser acústicamente privados (las personas que se sientan inmediatamente fuera de la sala no deben poder escuchar las conversaciones que se desarrollan en el interior).',
  meetingRoomDesc8: 'Las salas de reuniones son espacios cerrados destinados a reuniones de grupos pequeños de 2 a 4 personas. Deben estar equipados con equipos de A/V y pizarras blancas para facilitar el intercambio de conocimientos y deben ser acústicamente privados (las personas que se sientan inmediatamente fuera de la sala no deben poder escuchar las conversaciones que se desarrollan en el interior).',
  meetingRoomDesc12: 'Las salas de reuniones son espacios cerrados destinados a reuniones de grupos pequeños de 2 a 4 personas. Deben estar equipados con equipos de A/V y pizarras blancas para facilitar el intercambio de conocimientos y deben ser acústicamente privados (las personas que se sientan inmediatamente fuera de la sala no deben poder escuchar las conversaciones que se desarrollan en el interior).',
  meetingRoomDesc15: 'Las salas de reuniones son espacios cerrados destinados a reuniones de grupos pequeños de 2 a 4 personas. Deben estar equipados con equipos de A/V y pizarras blancas para facilitar el intercambio de conocimientos y deben ser acústicamente privados (las personas que se sientan inmediatamente fuera de la sala no deben poder escuchar las conversaciones que se desarrollan en el interior).',
  openCollabSpace4to6Desc: 'Estas áreas están destinadas a apoyar actividades colaborativas de grupos pequeños (4 a 6 personas), como lluvias de ideas y conversaciones informales entre colegas y con clientes. ',
  cafeFoodServiceDesc: 'La cafetería central está destinado a ser un área para comer, interacción social, trabajo individual y pequeñas reuniones. Muebles funcionales para comer y una paleta de colores de marca deben considerarse en el diseño de este espacio.',
  coatClosetsDesc: 'Los empleados necesitan un lugar para guardar sus abrigos y otros artículos voluminosos, especialmente cuando una oficina tiene un diseño abierto con almacenamiento personal limitado en el escritorio. Estos deben estar dispersos por toda la oficina, tal vez ubicados junto a las salidas y a lo largo de los pasillos principales. Por lo general, hay un estante sobre la parte colgante para sombreros y otros equipos con almacenamiento o maletas en la base del armario.',
  copyPrintDesc: 'Repartidas por todo el piso en un espacio de trabajo abierto, los espacios de copia/impresión brindan a los empleados acceso rápido a dispositivos multifunción desde todas las áreas. Los contenedores para reciclar siempre deben ubicarse junto con las áreas de copia/impresión, así como pequeños gabinetes para almacenar papel y suministros adicionales. Estos espacios son solo para visitas y no están destinados a funcionar como áreas sociales/de trabajo.',
  specialResourceBuildingSupportAreaDesc: 'Este es un complemento para los espacios de apoyo adicionales que pueden ocurrir en una oficina. Uno podría ser un mostrador de seguridad o instalaciones, mientras que otro podría ser un espacio de servicios públicos. Esto varía según las organizaciones, pero a medida que aumenta el tamaño de la oficina, los espacios auxiliares como este se vuelven más probables y necesarios.',
  secondaryServerRoomDesc: 'Sala de servidor secundario (tecnología) para albergar y proteger el equipo de TI y para terminar el cableado de bajo voltaje. Las habitaciones deben tener aire las 24 horas y deben estar a una temperatura constante de 72 grados Fahrenheit con un 50% de humedad relativa.',
  mothersRoomDesc: 'Las salas de lactancia o amigas están destinadas a brindar tiempo individual extremadamente privado, ya sea para madres lactantes o cualquier persona que busque un respiro de la oficina por un período corto. Estas salas suelen ser un requisito para todas las oficinas nuevas, independientemente del tamaño o el número de empleados.',
  visitorCoatLuggageRoomDesc: 'Los visitantes necesitan un lugar para guardar sus abrigos y otros artículos voluminosos. Estos deben estar dispersos por toda la oficina, tal vez ubicados junto a las salidas y a lo largo de los pasillos principales. Por lo general, hay un estante sobre la parte colgante para sombreros y otros equipos con almacenamiento o maletas en la base del armario.',

  wellnessRoomDesc: 'Las salas de bienestar (anteriormente denominadas Salas de Maternidad) están destinadas a brindar tiempo individual extremadamente privado, ya sea para madres lactantes o cualquier persona que busque un respiro de la oficina por un breve intervalo. Las salas de bienestar suelen ser un requisito para todas las oficinas nuevas, independientemente del tamaño o el número de empleados, y deben contener un sillón, un refrigerador e, idealmente, un fregadero. ',

  internalStairDesc: 'Las escaleras internas pueden ser huecos de escalera activados en el núcleo que brindan acceso entre pisos, o pueden ser elementos adicionales que conectan los pisos de una oficina. A menudo puede ser un espacio de reunión dentro de la oficina y puede centralizarse para café, recepción o espacios de reunión.',
  commercialPrintRoomDesc: 'A menudo combinadas con la sala de correo, las empresas brindan capacidades internas para imprimir, encuadernar y compilar presentaciones en la sala de impresión. Habrá varias impresoras y podría incluir impresoras de gran formato. Los espacios incluirán espacio de almacenamiento para suministros y también incluirán espacio de mostrador para equipos más pequeños, pero también espacio para diseñar impresiones.',
  pantryVendingCoffeeDesc: 'A diferencia de la cafetería central, el área de despensa/venta/café está diseñada para que los empleados tomen la comida y bebida rápida que necesitan. El bullicio y la conversación se encuentran naturalmente en áreas con comida, pero estos espacios generalmente no contienen asientos, lo que hace que las personas entren y salgan rápidamente y pasen una gran cantidad de tiempo socializando.',
  storageDesc: 'Las salas de almacenamiento están destinadas a almacenar artículos diversos, como equipos grandes, muebles adicionales, almacenamiento a granel, suministros para las vacaciones, etc. También se pueden usar para el almacenamiento de abrigos y equipaje de visitantes. Debe haber un pequeño espacio de almacenamiento ubicado cerca del conserje para mayor comodidad.',
  centralisedDispersedMailAreaDesc: 'Para empresas más grandes, se requiere un centro de correo para organizar el correo entrante y saliente. Estos espacios albergan equipos especiales de producción y correo. También hay muchas superficies para organizar papel y otros materiales. Por lo general, está a cargo de un miembro del personal a tiempo completo.',
  lockerDesc: 'Los casilleros se proporcionan como otra forma de almacenamiento individual. Brindan a los empleados un espacio para guardar bajo llave sus objetos de valor, efectos personales y archivos de trabajo. Los casilleros pueden asignarse a individuos o usarse por orden de llegada.',
  mainITandServerRoomDesc: 'Sala de servidores primarios (tecnología) para albergar y proteger el equipo de TI y para terminar el cableado de bajo voltaje. Las habitaciones deben tener aire las 24 horas y deben estar a una temperatura constante de 72 grados Fahrenheit con un 50% de humedad relativa.',
  drawerLateralFileDesc: 'Almacenamiento personal para personas en escritorios u oficinas. Los contenidos suelen ser materiales de proyectos personales o activos.',
  juiceBarCoffeeShopDesc: 'Si bien es posible que una empresa no tenga una cocina y un servicio completos, puede proporcionar una cafetería o una barra de jugos como parte de los servicios de comidas y bebidas en el espacio. Una organización también puede tener este tipo de espacio además de sus principales servicios de comedor, especialmente si la oficina es grande. Es probable que este espacio se parezca a su Starbucks local con un área de trabajo para un miembro del personal separada por un mostrador de servicio.',
  grabNGoDesc: 'Una pequeña área de conveniencia con varios refrigeradores, estantes para papas fritas y bocadillos, y una máquina de pago. Estos espacios Grab-N-Go generalmente son monitoreados por seguridad por cámara. Estos espacios a menudo se combinan con una pequeña despensa con refrigeradores y congeladores y un área de preparación de alimentos.',
  smMeetingConferenceRoomDesc: 'Una sala de conferencias pequeña es un espacio de reunión cerrado destinado a acomodar cómodamente de 2 a 6 personas en la mesa. Estas salas se pueden utilizar tanto para reuniones internas como para reuniones de cara al cliente y, por lo tanto, deben ser muy fáciles de usar con fácil acceso a las capacidades de energía y A/V.',
  mdMeetingConferenceRoomDesc: 'Una sala de conferencias mediana es un espacio de reunión cerrado destinado a acomodar cómodamente de 6 a 10 personas en la mesa. Estas salas se utilizan tanto para reuniones internas como para reuniones de cara al cliente y, por lo tanto, deben ser muy fáciles de usar con fácil acceso a las capacidades de energía y A/V.',
  lgMeetingConferenceRoomDesc: 'Una sala de conferencias grande es un espacio de reunión cerrado destinado a acomodar cómodamente de 12 a 14 personas en la mesa; es deseable disponer de asientos adicionales a lo largo del perímetro de la sala. Estas salas se pueden utilizar tanto para reuniones internas como para reuniones de cara al cliente y es probable que se utilicen para presentaciones. Los muebles deben ser móviles para permitir que el espacio se utilice para otros fines, como clases de yoga para los empleados.',
  xlMeetingRoomDesc: 'Una sala de conferencias de la sala de juntas está diseñada para acomodar cómodamente de 14 a 18 personas en la mesa, con asientos adicionales a lo largo del perímetro de la sala para al menos 10 más. Lo más probable es que estas salas se utilicen para grandes reuniones formales internas y grandes reuniones de cara al cliente. Como tales, deben estar equipados con tecnología A/V robusta y deben tener la marca como espacio orientado al cliente.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - US_SMALL and US_REGULAR
  lgOfficeDesc: 'Las oficinas son espacios de trabajo individuales, cerrados y privados para los empleados. Son excelentes para reducir la distracción y donde se puede llevar a cabo el trabajo de concentración. Las oficinas suelen estar equipadas con un escritorio y un asiento de trabajo junto con asientos para invitados. Encontrará cantidades variables de almacenamiento personal y archivo en las oficinas. El tamaño del espacio también puede variar según las actividades que se realicen en la sala (p. ej., reuniones periódicas) o el estado/jerarquía de los empleados. Esta oficina un poco más grande podría ser una oficina asociada o podría funcionar también como una sala de reunión.',
  smWorkstationDesc: 'Las estaciones de trabajo son asientos de trabajo individuales abiertos. Las estaciones de trabajo más pequeñas pueden ser utilizadas por empleados a tiempo completo, ya sea de forma temporal (en un espacio de dirección libre) o de forma permanente. Estos escritorios también son excelentes para los empleados o contratistas visitantes que no están en la oficina todo el tiempo. Algunas ventajas de las estaciones de trabajo abiertas son la visibilidad de la oficina y la colaboración inherente. Una desventaja puede ser la distracción del ruido, por lo que se recomienda que las oficinas con estaciones de trabajo abiertas tengan muchas salas de concentración y reunión. La presentación en el escritorio se limita a la presentación comunitaria probablemente ubicada en todo el piso.',
  mdWorkstationDesc: 'Las estaciones de trabajo son asientos de trabajo individuales abiertos. Los beneficios de un espacio un poco más grande incluyen más almacenamiento en el escritorio y privacidad. Esto probablemente significa que cuanto más grande sea un escritorio, es más probable que se le asigne una dirección en lugar de libre. Algunas ventajas de las estaciones de trabajo abiertas son la visibilidad de la oficina y la colaboración inherente. Una desventaja puede ser la distracción del ruido, por lo que se recomienda que las oficinas con estaciones de trabajo abiertas tengan muchas salas de concentración y reunión. Es probable que encuentre almacenamiento y archivos comunes adicionales ubicados en todo el piso.',
  lgWorkstationDesc: 'Las estaciones de trabajo son asientos de trabajo individuales abiertos. Los beneficios de un espacio un poco más grande incluyen más almacenamiento en el escritorio y privacidad. Lo más probable es que los escritorios de este tamaño se asignen en lugar de una dirección libre. Algunas ventajas de las estaciones de trabajo abiertas son la visibilidad de la oficina y la colaboración inherente. Una desventaja puede ser la distracción del ruido, por lo que se recomienda que las oficinas con estaciones de trabajo abiertas tengan muchas salas de concentración y reunión. Es probable que encuentre almacenamiento y archivos comunes adicionales ubicados en todo el piso.',
  huddleRoomDesc: 'Las salas de reuniones pequeñas son espacios cerrados destinados a albergar reuniones de grupos pequeños de 2 a 4 personas. Deben estar equipados con equipos de A/V y pizarras blancas para facilitar el intercambio de conocimientos y deben ser acústicamente privados (las personas que se sientan inmediatamente fuera de la sala no deben poder escuchar las conversaciones que se desarrollan en el interior).',
  multiPurpTrainingDesc: 'Las salas de usos múltiples están diseñadas para acomodar a un gran número de personas (20+). Este espacio tiene un nombre apropiado, ya que aquí se pueden realizar muchas actividades diferentes, incluidas capacitaciones, eventos de la empresa y grandes presentaciones. El ajuste y acabado de este espacio es funcional/duradero con mucha gente yendo y viniendo pero también de cara al cliente. Este espacio debe tener el soporte A/V adecuado, generalmente mucho más que la sala de conferencias promedio.',
  informalCollabDesc: 'Estas áreas están destinadas a apoyar actividades colaborativas de grupos pequeños (4 a 6 personas), como lluvia de ideas y conversaciones informales entre colegas y con clientes. ',
  touchdownTeamDesc: 'Los touchdowns son espacios de trabajo internos individuales o de pequeños equipos que se distribuyen por toda la oficina para uso de empleados y visitantes. Los puntos de contacto pueden tener una variedad de alturas, formas y tamaños, pero están destinados a destacarse de las estaciones de trabajo individuales como un lugar único para trabajar durante un período corto de tiempo. Estos deben ser espacios plug-and-play',
  frontDeskAreaDesc: 'Esta es la primera parada cuando los empleados y visitantes llegan a la oficina. El escritorio debe poder acomodar al menos a una persona con espacio de trabajo y almacenamiento personal. Es probable que también haya almacenamiento de visitantes cerca. La recepción está ubicada dentro del centro de recepción más grande, donde probablemente habrá asientos y otros alojamientos.',
  mailCenterAreaDesc: 'Para organizaciones más grandes, se requiere un centro de correo para centralizar todos los servicios de correo. Esto incluirá un gran espacio de clasificación y organización, junto con espacio para gráficos y equipos. Es probable que este espacio se vea muy diferente para cada organización, ya que a menudo encaja entre otros elementos del programa.',
  kitchenAreaDesc: 'Para los espacios que tendrán un servicio de comedor en la oficina, se requiere una cocina para respaldar este servicio. Los requisitos de una cocina variarán de una organización a otra, pero puede esperar encontrar electrodomésticos para cocinar y almacenamiento en el refrigerador. También habrá almacenamiento de productos secos y equipos. La cocina estará junto al servidor y probablemente el centro de servicios para conferencias/clientes.',
  serveryAreaDesc: 'El servidor es el área donde la gente obtiene su comida. Es probable que esté conectado a la cocina y a un comedor con mesas y asientos. La planificación de un servidor es muy precisa para gestionar colas y tráfico. Es probable que el tamaño y la forma de este espacio varíen entre organizaciones.',
  diningSupportAreaDesc: 'Una organización que tiene servicios de comedor sólidos probablemente tendrá espacios de apoyo adicionales, como almacenamiento en otras partes del edificio, que deben tenerse en cuenta en el programa general. El espacio variará entre las organizaciones.',
  diningAreaDesc: 'Comer cerca de la cocina debe proporcionar una variedad de opciones para que las personas se sienten y almuercen. Proporcionar varios tipos diferentes de asientos también permite que el espacio se utilice para reuniones y colaboración fuera de la hora del almuerzo. Proporcionar asientos blandos y duros junto con la barra y la configuración de la altura del asiento brinda opciones a los usuarios.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDesc: 'Cuando se proporciona un servicio de comedor en la oficina, existen múltiples funciones que se unen para respaldar este servicio, que incluyen una cocina, un servicio, un comedor (asientos) y un comedor de apoyo (almacenamiento). La cocina y el servicio trabajan juntos para preparar y servir la comida hecha en casa. El comedor es el lugar donde los empleados y los invitados pueden sentarse y comer, pero a menudo este espacio actúa como un centro social y de colaboración. Al igual que en el hogar, se requiere una gran cantidad de equipos y almacenamiento de alimentos para brindar servicios de comedor, por lo que el soporte de comedor se incluye en el tipo de espacio de Servicio de Alimentos Completo.',

  // SpaceUnitRow
  spaceUnitRowDeleteTitle: 'Eliminar tipo de espacio',
  spaceUnitRowDeleteText: '¿Está seguro de que desea eliminar este tipo de espacio?',
  spaceUnitRowConfirmDeleteText: 'Sí, eliminar',
  spaceUnitRowCancelDeleteText: 'No, mantener',

  // CustomAmenityAdder
  customAmenityAddText: 'Añadir un servicio',
  customAmenityPlaceholder: 'Tipo de servicio',

  // SpaceUnitTotals
  spaceUnitTotalsTitleNIA: 'Área neta interna total',
  spaceUnitTotalsTitle: 'Total {{sfTypeAdj}} metros {{unitCapitalized}} cuadrados',
  spaceUnitTotalsCirculation: 'Circulación',
  spaceUnitTotalsCirculationTooltip: 'El "espacio entre espacios". Pasillos, el espacio detrás de su silla, el espacio alrededor de los gabinetes y las puertas, y alrededor de las esquinas. ¡Sin circulación, no tendrías adónde caminar! Por lo general, comprende alrededor del 35 % del total de metros cuadrados utilizables en su programa, pero esto varía según su perfil y la selección final de edificios.',
  spaceUnitTotalsREBNY: 'Factor REBNY',
  spaceUnitTotalsNYCText: 'NYC ',
  spaceUnitTotalsREBNYTooltip: 'Para los programas en NYC, aumentamos los metros cuadrados en un 15 % para tener en cuenta el estándar de medición REBNY. El espacio medido según el estándar de medición REBNY incluye baños, vestíbulos de ascensores y el grosor de las paredes exteriores (entre otras cosas).',
  spaceUnitTotalsLossFactor: 'Factor de pérdida ',
  spaceUnitTotalsLossFactorTooltip: 'La {{unit}} cuadrada utilizable es la cantidad de espacio que tiene en su oficina. Rentable square {{unit}} es el espacio por el que paga, que puede incluir columnas, vestíbulos y otras áreas comunes. La diferencia entre usable y rentable se llama factor de pérdida, y la forma de calcularlo varía según la ubicación.',

  // CollaborationDropdown
  collabDropdownLevelLowText: 'Pocos (0-25%)',
  collabDropdownLevelMedText: 'Algunos (26-50%)',
  collabDropdownLevelHighText: 'La mayoría (51-100%)',

  //DensityDropdown
  sameDensityText: 'Misma densidad antes de Covid-19',
  lowerDensityText: 'Menor densidad ( Más huella por persona)',
  notSureDensityText: 'No estoy seguro',

  //CirculationDropdown
  preCovidText: 'Pre-Covid-19 (45% abierto / 25% cerrado)',
  higherText: 'Mayor circulación (50% abierto / 30% cerrado)',
  notSureText: 'No estoy seguro (45% abierta / 25% cerrada)',

  // ExamplePlans
  examplePlanButtonText: 'Ver plan de ejemplo',
  examplePlanFullFloorText: 'Piso completo',
  examplePlanPartialFloorText: 'Piso parcial',
  examplePlanFullImgAlt: 'Plano de planta de {{size}} cuadrado {{unit}} que muestra un diseño de perfil de {{profileName}} de Spacer by CBRE',
  examplePlanPartialImgAlt: '{{size}} square {{unit}} partial floor plan showing a {{profileName}} profile layout from Spacer by CBRE',
  examplePlanTooltipText: 'Si tiene más de {{size}} {{unit}}, es posible que necesite más de un piso.',

  // ProgramStats
  programStatsDensitySubtitleTsubo: 'Tsubo neto',
  programStatsDensitySubtitle: '{{adjetive}} Cuadrado {{unitUppercase}}',
  programStatsIndivTitle: 'Asientos individuales',
  programStatsWorkstationsSubtitle: 'Estaciones de trabajo',
  programStatsOfficesSubtitle: 'Oficinas',
  programStatsCollabTitle: 'Colaboración',
  programStatsEnclosedSubtitle: 'Asientos de grupo cerrados',
  programStatsIndivSeatSubtitle: 'Silla individual',
  programStatsSeatsTitle: 'Sillas por persona',
  programStatsIndivSeatsSubtitle: 'Sillas individuales',
  programStatsPersonSubtitle: 'Persona',
  programStatsDensityTitle: 'Densidad',
  programStatsFullDensitySubtitle: '{{density}} por asiento individual',

  // Profile Display Names (profileNames.js)
  efficientName: 'El optimizador',
  whiteCollarName: 'El acomodador',
  whiteGloveName: 'El catalizador',
  openOfficeName: 'El productor',
  workplace360Name: 'El conector',
  campusName: 'El diferenciador',
  startupName: 'El incubador',
  engCreativeName: 'El creador',

  // Profile Descriptions (profileDescriptions.js)
  efficientDesc: 'La oficina de The Optimizer es un entorno sencillo con una mentalidad de "hacer el trabajo". El espacio se centra en permitir que las personas tengan todas las herramientas necesarias en sus escritorios. La colaboración y el trabajo en equipo ocurren con menos frecuencia. Los escritorios de los empleados están estandarizados y asignados con solo espacios de apoyo básicos proporcionados dentro de la oficina. Fiel a su nombre, estos entornos casi siempre están abiertos para maximizar la eficiencia.',
  whiteCollarDesc: 'Como una oficina de The Accommodator, no está sola: este es uno de los perfiles más populares para los lugares de trabajo corporativos. Las oficinas de The Accommodator prefieren no sacudir el barco con un diseño de espacio radical sino que a menudo invierten en nueva tecnología y mobiliario para hacer que el espacio sea productivo y atractivo. Con un equilibrio de espacios individuales y colaborativos, los empleados se encontrarán principalmente en sus escritorios con reuniones ocasionales y sesiones de equipo. Perfecto para ponerse de 9 a 5.',
  whiteGloveDesc: 'Ingrese al vestíbulo de una oficina de The Enhancer y es posible que sienta que ha triunfado. Este lugar de trabajo satisface profundamente las necesidades de los empleados, así como de los clientes, que son visitantes frecuentes del lugar de trabajo. Más que cualquier otro perfil de lugar de trabajo, las oficinas y los espacios cerrados son comunes. La mayoría de los empleados ganan su espacio de oficina a través del desempeño o la antigüedad, y la jerarquía probablemente juega un papel importante en el funcionamiento de la organización. Sube la escalera corporativa con estilo.',
  openOfficeDesc: 'La oficina de The Producer sabe lo que necesita para tener éxito. Se trata de brindar a las personas los espacios básicos necesarios en función del tipo de actividad que se lleva a cabo en la oficina. Estos diversos tipos de espacio no solo están disponibles, sino que también encontramos que son muy utilizados, ¡una forma de mantenerse eficiente! Hay una combinación uniforme entre el trabajo individual y el trabajo en equipo, lo que probablemente signifique que te mueves mucho por la oficina durante el día. ¡Perfecto para una oficina en movimiento!',
  workplace360Desc: 'Al igual que su postura de yoga favorita, la oficina de The Connector se caracteriza del equilibrio. Con la combinación correcta de espacio orientado a la productividad y espacio de conexión social, la oficina de The Connector proporciona todos los elementos esenciales para el enfoque al tiempo que fomenta la elección con una variedad de otros espacios. Fiel a su nombre, la oficina de The Connector ubica sus áreas comunes como cocinas y áreas de reunión abiertas en el centro. Esto ayuda a unir a las personas y crea un sentido de comunidad.',
  campusDesc: 'La oficina The Differentiator hace que sea difícil irse al final del día. Esto se debe a que se esfuerza por brindar una experiencia laboral que está por encima y más allá del resto. Encontrará comodidades mejoradas en torno al bienestar o la comida para ayudar a los empleados a ahorrar tiempo y sentirse cómodos. ¿Alguien dijo bocadillos? Se alienta a los empleados a tomar descansos, socializar y relajarse en el lugar de trabajo.',
  startupDesc: 'La velocidad y la ideación son el nombre del juego cuando se trata de la oficina de The Incubator. Los empleados trabajan en equipos altamente colaborativos y piensan como propietarios. Este entorno de oficina cambia constantemente, lo que significa que la mayoría de los espacios tienen flexibilidad incorporada. Mover muebles y hacer turnos son comunes. Se utiliza una combinación de actividades sociales divertidas y sesiones de trabajo intensas, como ping-pong y hackáthones, para crear camaradería como equipo.',
  engCreativeDesc: 'Pensamos en la oficina de The Creator como un lugar lleno de especialistas, no la combinación típica de tipos de departamentos. Al igual que todos los grandes esfuerzos creativos, la oficina de The Creator presenta con frecuencia el trabajo conjunto y en equipo. Es probable que el equipo especializado o los materiales físicos formen parte de las actividades laborales diarias. A los empleados se les da el espacio que necesitan para hacer su trabajo de manera efectiva y, aunque es un tipo de oficina poco común, un trabajo único requiere un lugar de trabajo único.',


  // Profile Key Traits: Shared Names (profileTraitMap.js)
  social: 'Social',
  efficient: 'Eficiente',
  flexible: 'Flexible',
  enabled: 'Habilitado',
  productive: 'Productivo',
  inclusive: 'Inclusivo',
  clientFocused: 'Enfocado en el cliente',
  standardized: 'Estandarizado ',

  // Profile Key Traits (profileTraitMap.js)
  social1Desc: 'Las relaciones dentro y fuera de la oficina son importantes',
  social2Desc: 'Muchos eventos de construcción de comunidad',
  social3Desc: 'A los empleados les gusta pasar tiempo juntos',
  social4Desc: 'Más abierto y enfocado en la comunidad',
  efficient1Desc: 'La empresa cumple y es consciente de su presupuesto',
  efficient2Desc: 'La empresa cumple y es productiva ',
  flexible1Desc: 'Las personas pueden decidir cómo trabajan',
  flexible2Desc: 'Los empleados tienen mucha libertad',
  flexible3Desc: 'Construye el espacio que necesita',
  enabled1Desc: 'Los empleados tienen lo que requieren en sus escritorios',
  enabled2Desc: 'Los empleados cuentan con servicios robustos y amenidades',
  enabled3Desc: 'Las personas pueden obtener lo que necesitan para ejecutar sus trabajos',
  enabled4Desc: 'Tienen acceso a la tecnologia correcta y a los equipos',
  enabled5Desc: 'Equipos especializados están disponibles',
  productive1Desc: 'Diseño de oficina funcional e intuitivo',
  productive2Desc: 'Orientado al negocio y financieramente consciente ',
  productive3Desc: 'Centrado alrededor de productos únicos/oferta de servicios',
  inclusive1Desc: 'Diferentes tipos de espacios disponibles',
  inclusive2Desc: 'Puede encontrar lo que necesite en la oficina',
  inclusive3Desc: 'Muchos estilos de trabajo son aceptados',
  clientFocused1Desc: 'Las primeras impresiones con clientes significan todo',
  clientFocused2Desc: 'La oficina es acogedora para clientes y nuevos empleados',
  clientFocused3Desc: 'La oficina esta impecable para clientes y nuevos empleados',
  standardized1Desc: 'Procesos y sistemas son aerodinámicos ',
  standardized2Desc: 'Reglas organizacionales sobre el espacio',
  standardized3Desc: 'Tamaños modulares para flexibilidad',
  standardized4Desc: 'Procesos y entregables simplificados',

  // SpaceSummary
  spaceSummaryBarHeader: 'Área recomendada de oficina',
  spaceSummaryMeasurementTypeText: 'Tipo de medida',
  spaceSummaryUnitTypeText: 'Unidades',
  spaceSummaryLossFactorSectionLabel: '{{factor}} Detalles',
  spaceSummaryFitFactorText: 'Factor de ajuste',
  spaceSummaryLossFactorText: 'Factor de pérdida',
  spaceSummaryRSFLossFactorHelperText: 'Basados en un {{percentage}}% de factor de pérdida para propiedad raíz en {{place}}, usted deberá buscar espacios entre {{min}} y {{mas}} metros cuadrados rentables {{units}}',
  spaceSummaryNIAFitFactorHelperText: 'Basados en un ({{percentage}}% de factor de ajuste para propiedad raíz en {{place}}, usted deberá buscar espacios con {{amt}} de espacio de Área Interna Neta (AIN).',
  spaceSummaryNIATooltipText: 'Área Interna Neta (AIN) es el área usable dentro de un edificio, medida desde la cara externa del perímetro de las paredes de cada piso, con algunas áreas especificas excluidas.',

  // SFTypeRadioInput
  sfRadioInputUsableAreaTooltipText: 'Esta medida incluye todos los cuartos, mobiliario y circulación del espacio. Muchas veces es referida como área de alfombra.',
  sfRadioInputRentableAreaTooltipText: 'Por lo general, se trata del Cuadrado utilizable {{unitUppercase}} más una parte de los espacios comunes del edificio. Aunque no puedes usarlo todo, es la {{unit}} cuadrada que pagas cuando alquilas.',
  sfRadioInputUsableSquareText: 'Metros cuadrados usables {{unitUppercase}}',
  sfRadioInputRentableSquareText: 'Metros cuadrados rentables {{unitUppercase}}',

  //floored plan request block
  flooredTitle: '¡No nos deje todavía!',
  flooredSubTitle: 'Redefina cómo sus clientes exploran el espacio',
  flooredSubText: '¡Mire sus resultados en Spacer en un plano y haga un tour en 3D!',
  flooredParagraphText1: 'Experiencia inmersiva que permite a los arrendatarios imaginar posibilidades, experimentar el espacio y convertir ideas en realidades.',
  flooredParagraphText2: 'Tangible, visión personalizada para cada espacio posible ',
  flooredVideoHeading: 'Mire una experiencia de ejemplo',
  flooredFreeText: 'Aun más, ¡es gratis!',
  flooredIconText1: 'Compartir planos disponibles ',
  flooredIconSubText1: 'Archivos AutoCAD o PDF',
  flooredIconText2: 'Permite un tiempo de procesamiento',
  flooredIconSubText2: 'Una o dos semanas',
  flooredIconText3: 'Recibe ',
  flooredIconSubText3: 'Experiencias 2D y 3D personalizadas',


  // MeasurementSystemRadioInput
  measurementSystemSqFeetText: 'Pie cuadrado ',
  measurementSystemSqMetersText: 'Metros cuadrados',
  measurementSystemTsuboText: 'Tsubo',

  // ProfileHero
  resultsProfileHeroAnnouncementTitle: 'Su oficina es ',
  resultsProfileHeroImgAlt: 'Plano 3D mostrando el diseño de la oficina del perfil de {{profileName}}} de Spacer de CBRE',

  // ProfileComparison
  profileCompModalTitle: '¿Cambiar el perfil?',
  profileCompModalText: 'Usted perderá los cambios no guardados de su actual trabajo en el espacio.',
  profileCompModalConfirmText: 'Sí, cambiar el perfil',
  profileCompModalCancelText: 'No, conservar perfil actual',
  profileCompNavbarCancelButtonText: 'Cancelar',
  profileCompNavbarUseButtonText: 'Usar este perfil',
  profileCompCompareTitle: 'Compare los perfiles cercanos que coinciden',

  // ProfileComparisonColumn
  profileCompColumnSelectedText: 'Seleccionar perfil',
  profileCompColumnSelectText: 'Seleccionar',
  profileCompColumnRecommendedText: ' (Recomendado)',
  profileCompColumnImgAltText: 'Plano 3D mostrando el diseño de la oficina del perfil de {{profileName}} de Spacer de CBRE',

  // Test Fit Form
  testFitTitle: 'Mira los resultados de tu plano y haz un tour en 3D',
  testFitSubtitle: '¿Quiere tener una idea de cómo luciría su espacio diseñado? Nosotros convertiremos sus requerimientos en un plano personalizado el cual podrá explorar y compartir. ',
  requestTestFitButtonText: 'Solicitar un plano personalizado',
  company: 'Nombre de la Compañía',
  emptyCompanyError: 'Por favor ingrese el nombre de la compañía',
  radioQ2yes: '¡Genial! Haremos seguimiento vía correo electrónico para solicitar su plano.',
  radioQ2no: 'No hay problema. Haremos seguimiento para discutir sus opciones.',
  testFitFormTitle: 'Solicitar su plano personalizado',
  testFitFormSubtitle: 'Compártanos alguna información y empezaremos el proceso. Nuestro equipos típicamente realiza entrega de planos terminado en 3 días hábiles.',
  testFitFormCompleted: 'Su solicitud ha sido enviada. Uno de nuestros representantes se pondrá en contacto con usted vía correo electrónico.',
  testFitFormYes: 'sí',
  testFitFormNo: 'no',
  testFitFormQ1: '¿Tiene un plano sobre el cual trabajar?',
  testFitFormQ1No: '¡No hay problema! Podemos proveerle uno para su uso.',
  testFitFormQ2: '¿Tiene un plano en PDF o AutoCAD?',
  testFitTermsConditions: 'Al hacer click en el siguiente cuadro, usted acepta a participar en este programa Beta. Usted recibirá un plano gratis a cambio de su participación en una encuesta sobre su experiencia',
  testFitSubmitButtonText: 'Enviar el plano solicitado ',
  backendError: '¡Ups! Algo salió mal, por favor intente de nuevo.'
};
