const ADD_USER_DATA          = 'ADD_USER_DATA';
const ADD_USER_DATA_BULK     = 'ADD_USER_DATA_BULK';
const COMPLETE_TEST_FIT_FORM = 'COMPLETE_TEST_FIT_FORM';

module.exports = {

  // Action Types

  ADD_USER_DATA,
  ADD_USER_DATA_BULK,
  COMPLETE_TEST_FIT_FORM,


  // Action Creators

  addUserData(dataType, value, isUpdatingCurrentPersonalLocation) {
    return { type: ADD_USER_DATA, dataType, value, isUpdatingCurrentPersonalLocation };
  },

  addBulkUserData(dataTypeValueCollection) {
    return { type: ADD_USER_DATA_BULK, dataTypeValueCollection };
  },

  completeTestFitForm() {
    return { type: COMPLETE_TEST_FIT_FORM };
  }
};
