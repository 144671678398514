const constants = require('wp-constants').spacerFree;

module.exports = {
  [constants.siteLocation.PAGE.LANDING]: {
    titleToken: 'landingPageTitle',
    metaTagToken: 'landingPageMetaTagText'
  },
  [constants.siteLocation.PAGE.ABOUT]: {
    titleToken: 'aboutPageTitle',
    metaTagToken: 'aboutPageMetaTagText'
  },
  [constants.siteLocation.PAGE.TERMS]: {
    titleToken: 'termsPageTitle',
    metaTagToken: 'termsPageMetaTagText'
  },
  [constants.siteLocation.PAGE.QUIZ]: {
    titleToken: 'quizPageTitle',
    metaTagToken: 'quizPageMetaTagText'
  },
  [constants.siteLocation.PAGE.RESULTS]: {
    titleToken: 'resultsPageTitle',
    metaTagToken: 'resultsPageMetaTagText'
  },
  [constants.siteLocation.PAGE.PROFILE_COMPARISON]: {
    titleToken: 'profileComparisonPageTitle',
    metaTagToken: 'profileComparisonPageMetaTagText'
  },
  [constants.siteLocation.PAGE.BLOG_HOME]: {
    titleToken: 'blogHomePageTitle',
    metaTagToken: 'blogHomePageMetaTagText'
  },
  [constants.siteLocation.PAGE.REFERRAL]: {
    titleToken: 'referralPageTitle',
    metaTagToken: 'referralPageMetaTagText'
  },
  [constants.siteLocation.PAGE.REFERRAL_RETRIEVE]: {
    titleToken: 'referralRetrievePageTitle',
    metaTagToken: 'referralRetrieveMetaTagText'
  },
  [constants.siteLocation.PAGE.BLOG_ARTICLE_20190621]: {
    titleToken: `${constants.siteLocation.PAGE.BLOG_ARTICLE_20190621}:pageTitle`,
    metaTagToken: `${constants.siteLocation.PAGE.BLOG_ARTICLE_20190621}:metaTagText`
  },
  [constants.siteLocation.PAGE.BLOG_ARTICLE_20190625]: {
    titleToken: `${constants.siteLocation.PAGE.BLOG_ARTICLE_20190625}:pageTitle`,
    metaTagToken: `${constants.siteLocation.PAGE.BLOG_ARTICLE_20190625}:metaTagText`
  },
  [constants.siteLocation.PAGE.BLOG_ARTICLE_20190627]: {
    titleToken: `${constants.siteLocation.PAGE.BLOG_ARTICLE_20190627}:pageTitle`,
    metaTagToken: `${constants.siteLocation.PAGE.BLOG_ARTICLE_20190627}:metaTagText`
  }
};
