module.exports = {
  referralStep: ' Stap {{step}} van 4',
  forgotReferralLink: 'Doorverwijzingslink vergeten?',
  howReferralLinksWorkTitle: 'Zo werken doorverwijzingslinks',
  enterYourInformation: 'Vul je gegevens in',
  confirmYourInformation: 'Bevestig je gegevens',
  referralLinkStep1: 'Om een doorverwijzingslink te maken, hebben we het volgende nodig:',
  referralLinkReq1: '- Je naam',
  referralLinkReq2: ' - Het mailadres dat je van CBRE hebt gehad',
  referralLinkStep2: 'Als een opdrachtgever de vragenlijst via jouw doorverwijzingslink invult, mailen we jou een kopie van de resultaten.',
  next: 'Volgende',
  firstName: 'Voornaam',
  lastName: 'Achternaam',
  emptyNameError: 'Voer een naam in',
  email: 'Je CBRE-e-mailadres',
  emptyEmailError: 'Voer een CBRE-e-mailadres in',
  emailTakenError: 'Dat e-mailadres is al gekoppeld aan {{takenLink}}',
  emailExample: 'bijv. jane.smith@cbre.com',
  editReferralInfo: 'Bewerk de opgegeven naam- en e-mailgegevens',
  noLaterChanges: 'Je kunt dit later niet meer wijzigen.',
  somethingWrong: 'Oeps. Er is bij ons iets misgegaan. Probeer het nog eens.',
  contentLoading: 'Inhoud wordt geladen',
  linkReady: 'Je link is klaar',
  storeLinkInstructions: 'Bewaar deze link op een veilige plek, zodat je hem steeds kunt gebruiken om Spacer met klanten te delen. We hebben deze link ook naar het door jou opgegeven e-mailadres gestuurd.',
  yourLink: 'Jouw doorverwijzingslink',
  copyLink: 'Doorverwijzingslink kopiëren',
  copiedLink: 'Gekopieerd naar klembord',
  linkNeverExpires: 'Denk eraan: deze link verloopt nooit.',
  linkFound: 'We hebben je link gevonden',
  emailNotAssociated: 'We konden geen doorverwijzingslink vinden die aan dat e-mailadres is gekoppeld.',
  createLink: 'Wil je er een maken?'
};
