module.exports = {

  // Navbar
  spacerLogoAlt: 'Aplikacja CBRE Spacer',
  linkToAboutPageText: 'Więcej o aplikacji Spacer',
  linkToBlogPageText: 'Aktualności',
  referrals: 'Polecenia',

  // TakeOfficeSpaceQuizButton
  sizeMyOfficeButtonDefaultLinkText: 'Wypełnij kwestionariusz',
  flooredPlanRequestLinkText: 'Wygeneruj Projekt',

  // Footer
  footerCBRELogoAlt: 'Logo CBRE dla aplikacji CBRE Spacer',
  footerFacebookIconAlt: 'Ikona Facebook™ dla aplikacji CBRE Spacer',
  footerTwitterIconAlt: 'Ikona Twitter™ dla aplikacji CBRE Spacer',
  footerLinkedinIconAlt: 'Ikona Linkedin™ dla aplikacji CBRE Spacer',
  footerLinkedinLink: 'https://www.linkedin.com/showcase/cbre-workplace-strategy/',
  footerHeaderQuestionsText: 'Masz jakieś pytania?',
  footerHeaderAboutText: 'Materiały',
  footerHeaderMoreText: 'Dodatkowe informacje',
  footerHeaderBrokersText: 'Dla agentów',
  footerLinkWhatIsSpacerText: 'Informacje o aplikacji Spacer',
  footerLinkBlogText: 'Aktualności',
  footerLinkWorkplaceText: 'Zespół CBRE Workplace?',
  footerLinkTermsText: 'Regulamin',
  footerLinkPrivacyText: 'Polityka prywatności',
  footerLinkReferralText: 'Polecenia',
  footerWorkplaceLink: 'http://www.cbre.us/real-estate-services/occupier/workplace',
  footerPrivacyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Contact (contact.js)
  spacerSupportEmail: 'spacersupport@cbre.com',

  // ErrorPage
  errorOopsText: 'Ups!',
  error404Text: 'Nie możemy znaleźć strony, której szukasz.',
  error500Text: 'Coś poszło nie tak po naszej stronie.',
  errorText: 'Błąd',
  errorReturnText: 'Wróć do aplikacji Spacer',
  errorAltText: 'Grafika przedstawiająca rozlaną kawę dla aplikacji CBRE Spacer',

  // Measurement Units (units.js)
  SF: 'ft²',
  SM: 'm²',
  USF: 'ft² PU',
  USM: 'm² PU',
  RSF: 'ft² PN',
  RSM: 'm² PN',
  NSF: 'powierzchnia netto (ft²)',
  NSM: 'powierzchnia netto (m²)',
  NIA: 'PWN',
  foot: 'stopa',
  feet: 'ft²',
  footage: 'powierzchnia',
  'sqft.': 'stopa kwadratowa',
  meter: 'metr',
  meters: 'm²',
  'sqm.': 'm kw.',
  Tsubo: 'tsubo',

  // SF Types Adjectives (calculator.js)
  Usable: 'Powierzchnia Użytkowa',
  Rentable: 'Powierzchnia Najmu',
  Net: 'Netto',

  // Page Titles (documentDataCopy.js)
  landingPageTitle: 'Zaplanuj swoje idealne biuro w zaledwie 15 minut – aplikacja CBRE Spacer',
  aboutPageTitle: 'Dowiedz się, jak działa nasze narzędzie, które pozwoli Ci na obliczenie, jak dużej powierzchni biura potrzebujesz – aplikacja CBRE Spacer',
  termsPageTitle: 'Regulamin – aplikacja CBRE Spacer',
  quizPageTitle: 'Określ wymiary swojego biura w zaledwie 15 minut – aplikacja CBRE Spacer',
  resultsPageTitle: 'Pobierz zalecenia co do typu i wielkości przestrzeni biurowej dostosowanej do Twoich potrzeb – aplikacja CBRE Spacer',
  profileComparisonPageTitle: 'Porównaj swoje potrzeby w zakresie przestrzeni biurowej – aplikacja CBRE Spacer',
  blogHomePageTitle: 'Aktualności z obszaru planowania przestrzeni biurowej – aplikacja CBRE Spacer',
  referralPageTitle: 'Link polecający dla agentów CBRE – aplikacja CBRE Spacer',
  referralRetrievePageTitle: 'Pobierz link polecający dla agentów CBRE – aplikacja CBRE Spacer',

  // Meta Tags (documentDataCopy.js)
  landingPageMetaTagText: 'Planujesz swoje nowe biuro? Aplikacja CBRE Spacer pomoże Ci szybko i łatwo określić wielkość powierzchni biurowej niezbędną do realizacji Twoich celów biznesowych. To zajmie tylko 15 minut!',
  aboutPageMetaTagText: 'Biuro odpowiedniej wielkości może pomóc Ci przyciągnąć talenty, obniżyć koszty, a nawet sprawić, że Twoi pracownicy będą cieszyć się dobrym zdrowiem. Sprawdź, jak dzięki aplikacji Spacer możesz szybko i sprawnie uzyskać zalecenia co do wielkości biura, która umożliwi Ci realizację celów Twojej organizacji.',
  termsPageMetaTagText: 'Aplikacja CBRE Spacer powstała w oparciu o wiedzę branżową i ponad dziesięcioletnie doświadczenie rynkowe, w efekcie czego w ciągu zaledwie kilku minut możliwe jest wygenerowanie rzetelnych zaleceń w zakresie wymaganej wielkości biura.',
  quizPageMetaTagText: 'Wypełnij 15-minutowy kwestionariusz aplikacji Spacer i dowiedz się, jak dużej powierzchni biurowej potrzebujesz, aby skutecznie wspierać cele i priorytety Twojej organizacji. To najszybsze na rynku oprogramowanie oferujące spersonalizowane usługi.',
  resultsPageMetaTagText: 'Dostosowane do Twoich potrzeb zalecenia dotyczące przestrzeni biurowej wygenerowane przez aplikację Spacer to ogromna oszczędność czasu poświęconego na poszukiwania odpowiedniego biura – dzięki niej dowiesz się dokładnie, jak dużego biura potrzebuje Twoja organizacja i jaki rodzaj mebli będzie dla Ciebie najodpowiedniejszy, a także uzyskasz rzuty zalecanej powierzchni.',
  profileComparisonPageMetaTagText: 'Dowiedz się, jak Twoje indywidualne potrzeby biurowe prezentują się na tle wymagań określonych przez innych użytkowników aplikacji CBRE Spacer',
  blogHomePageMetaTagText: 'Wyliczenie, jak dużej powierzchni biurowej potrzebuje dana organizacja, nie jest wyższą matematyką, jednakże informacje i materiały związane z planowaniem biura udostępniane w ramach aplikacji Spacer mogą okazać się naprawdę pomocne. Aby dowiedzieć się więcej, kliknij tutaj.',
  referralPageMetaTagText: 'Użyj linku polecającego, aby udostępnić aplikację Spacer klientom. Uzyskasz w ten sposób pewność, że wyniki wypełnionego przez nich kwestionariusza dotyczącego projektu przestrzeni zostaną wysłane bezpośrednio na Twój adres e-mail. Aby dowiedzieć się więcej, kliknij tutaj.',
  referralRetrieveMetaTagText: 'Pobierz swój link polecający do aplikacji CBRE Spacer.',

  // Form title
  formLabel: 'Twoje dane'
};
