const React               = require('react');
const PropTypes           = require('prop-types');
const constants           = require('wp-constants').spacerFree;
const CallToActionBanner  = require('../common/CallToActionBanner.jsx');
const aboutPageContent    = require('../../../../../../copy/aboutPageContent');
const { withTranslation }  = require('react-i18next');

function getTextSection(pageSection, t) {
  const titleText = t(pageSection.title);

  let listSection;
  let paragraphSection;

  if (pageSection.list) {
    listSection = (
      <ol className="has-margin-bottom-5" key={`${titleText}_list`}>
        {pageSection.list.map(listItem => (
          <li className="has-margin-bottom-7 has-margin-left-6 has-padding-left-7" key={listItem.substring(0, 25)}>
            {t(listItem)}
          </li>
        ))}
      </ol>
    );
  }

  if (pageSection.text) {
    const paragraphs = pageSection.text.split('\n');
    const numParagraphs = paragraphs.length - 1;
    let paragraphText;

    paragraphSection = paragraphs.map((paragraph, i) => (
      paragraphText = t(paragraph).split('\n'),
      <div className="has-margin-bottom-7" key={paragraph.substring(0, 25)}>
        {/* {t(paragraph)} */}
        {paragraphText.map((val, index) =>
          <div key={index}>{val}</div>
        )
        }
        {pageSection.addEmailLink && i === numParagraphs &&
          <span>
            <a href={`mailto:${constants.contact.SPACER_SUPPORT_EMAIL}`}>
              {t('aboutPageSpacerSupportLink')}
            </a>
            .
          </span>
        }
      </div>
    ));
  }

  return [
    <h2 className="subtitle has-margin-bottom-4" key={titleText.substring(0, 25)}><strong>{titleText}</strong></h2>,
    listSection,
    paragraphSection
  ];
}

function wrapGetPageSectionWithT(t) {
  return function getPageSection(pageSection, pageSectionIndex) {
    const isEven = pageSectionIndex % 2 === 0;
    const textColumn = (
      <div className="column is-half level-item" key={`${pageSectionIndex}_${pageSection.title.substring(0, 10)}`}>
        { getTextSection(pageSection, t) }
      </div>);

    let imageClasses = 'column is-half level-item';
    if (!isEven) imageClasses += ' is-hidden-mobile';
    const imageColumn = (
      <div className={imageClasses} key={pageSection.imageAlt}>
        <figure className="image">
          <img src={pageSection.image} alt={pageSection.imageAlt} className="about-page-content-illustration" />
        </figure>
      </div>
    );

    return (
      <div className={isEven ? '' : 'is-white'} key={pageSectionIndex}>
        <div className="container has-padding-left-1 has-padding-right-1 has-no-padding-mobile">
          <section className="section">
            <div className="columns level">
              {/* alternate whether it's text-left/image-right or image-left/text-right */}
              {isEven ? [textColumn, imageColumn] : [imageColumn, textColumn]}
            </div>
          </section>
        </div>
      </div>
    );
  };
}

function AboutPageContent(props) {
  const pageSections = aboutPageContent.getPageSections();

  const getPageSectionWithT = wrapGetPageSectionWithT(props.t);

  return (
    <div className="about-page">
      {/* Video Section */}
      <div className="is-white">
        <div className="container has-padding-left-1 has-padding-right-1 has-no-padding-mobile">
          <section className="section flex flex-justify-center">
            <div className="about-page-video">
              <h2 className="subtitle has-margin-bottom-4">{props.t('aboutPageVideoTitle')}</h2>
              <div className="iframe-container" lang="en">
                <iframe
                  title="Video: Determine the Right Amount of Space for Your Next Office With Spacer"
                  src="https://www.youtube.com/embed/UFEcTJr4QyA?modestbranding=1&rel=0&mute=1"
                  // url params: modestbranding to take away youtube logos
                  // rel to take away video recommendations at end of video
                  // mute to default to a muted video
                  width="100%"
                  height="100%"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* Page Sections */}
      {pageSections.map(getPageSectionWithT)}

      <CallToActionBanner />
    </div>
  );
}

AboutPageContent.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('aboutPage')(AboutPageContent);
