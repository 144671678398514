/* eslint-env browser */
// require('isomorphic-fetch');
// require('es6-promise').polyfill();
// require('@babel/polyfill');
const i18n = require('./i18n');

const React = require('react');
const { createRoot } = require('react-dom/client')
const {
  RouterProvider
} = require('react-router-dom');
const {
  createStore,
  applyMiddleware
} = require('redux');
const { Provider } = require('react-redux');
const thunk = require('redux-thunk').default;
const mainReducer = require('./redux/reducers/mainReducer');
const constants = require('wp-constants').spacerFree;
const localStorage = require('../../../lib/localStorage');
const pageManager = require('./util/pageManager');
const _ = require('lodash');


const Modal = require('./components/containers/modals/Modal.jsx');

const clientConstants = require('../shared/constants/clientConstants');
const numericDisplay = require('./util/numericDisplay');
const ga = require('../shared/tracking');
const router = require('./router.jsx');

numericDisplay.updateCurrentLanguage(i18n.language);

function getSourceIdFromUrl() {
  const r = 'referral/';
  const url = window.location.href;
  if (url.indexOf(r) > -1) {
    // knowing that a referral link looks e.g. like workplace.cbre.com/referral/helenforzano - grab all after the `referral/` bit
    const potentialSourceId = url.substring(url.indexOf(r) + r.length);
    if (potentialSourceId !== 'retrieve') return potentialSourceId;
  }
  return null;
}

/*  Whenever we hit the home page, we want to make sure we clear local storage so we can restart the quiz.
 *  Similarly, if the quiz page is refreshed after question 1 has been answered we want to restart the quiz,
 *  since it has been started, but not when we first hit the quiz because we want the first question to persist.
*/
let persistedState = {};
const tentativeState = localStorage.loadState();
const startedQuiz = tentativeState && tentativeState.profileQuiz.userQuizAnswers['1'];
if (pageManager.onHomePage() || (pageManager.onQuizPage() && startedQuiz)) {
  localStorage.clear();
} else {
  persistedState = tentativeState;
}
// Keep sourceId around even when we cleared all quiz answers. Just being agressive with keeping sourceId around.
_.set(persistedState, 'userData.sourceId', getSourceIdFromUrl() || _.get(tentativeState, 'userData.sourceId'));

const initialStateToBeOverriddenIfPersisted = {
  profileQuiz: {
    userQuizAnswers: {},
    profileScores: {}
  },
  spaceData: {
    profileId: '',
    assumptions: {},
    program: {},
    sfType: constants.calculator.SF_TYPE.RSF,
    _id: ''
  },
  userData: {
    role: '',
    industryCode: '',
    officeLocation: '',
    currentPersonalLocation: ''
  }
};

const initialStateDefaults = {
  requests: {
    isFetchingProgram: false,
    fetchProgramError: null,
    isSubmittingForm: false,
    submitFormSuccess: false,
    submitFormError: null,
    wasProgramSaved: false
  },
  modal: {
    modalType: constants.modal.TYPES.NONE,
    modalProps: null
  },
  changeDetected: {
    changesMadeToResultsDetails: false
  }
};

const initialState = _.merge({}, initialStateToBeOverriddenIfPersisted, persistedState, initialStateDefaults);
// put initial state on local storage (so sourceId is saved right away on the home page,
// instead of getting lost when navigating to e.g. About Page and back
localStorage.saveState(initialState);

// This is a little hacky. Basically there's lots of conditions surrounding whether we should fetch
// a program on the results page, with one of them being that the space program is empty. if there is
// persisted state, then the space program isn't empty. This just force clears it if you hit the path
// with an id.
if (pageManager.onResultsForIdPage()) {
  initialState.spaceData.program = {};
}

const store = createStore(
  mainReducer,
  initialState,
  applyMiddleware(thunk)
);

store.subscribe(_.throttle(() => {
  // Currently saving on local storage even when loaded from id, so we can go to square footage page and return from saved result.
  // TODO: we might want to rethink that in the future and not override local storage. Instead we could work with React Links.
  // if (pageManager.onResultsForIdPage()) return;
  localStorage.saveState(store.getState());
}), 1000);

let browserClass = '';
if (clientConstants.IS_FIREFOX) browserClass = 'is-firefox';
if (clientConstants.IS_IE) browserClass = 'is-ie';

//Initialize Google Analytics 
ga.GAInitialize();

const container = document.getElementById('mainContent');
const root = createRoot(container);

router.subscribe(function(state, options) {
  const { historyAction, location } = state
  const { pathname } = location
  if (historyAction === 'PUSH') {
    window.scrollTo(0, 0);
    ga.GATrackRoute(pathname, pathname)
  }
})

root.render(
  <Provider store={store}>
    <div className={browserClass}>
      <Modal />
      <RouterProvider router={router} />
    </div>
  </Provider>
);
