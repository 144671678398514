const React               = require('react');
const PropTypes           = require('prop-types');
const sharedPropTypes     = require('../../../shared/components/sharedPropTypes');
const spacerFreePropTypes = require('../spacerFreePropTypes');

const Question        = require('./Question.jsx');

class InactiveQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.questionUnit.id);
  }

  render() {
    return (
      <button
        onClick={this.onClick}
        className="inactive-quiz-question-container"
      >
        <Question
          questionUnit={this.props.questionUnit}
          questionAnswer={this.props.questionAnswer}
          isInactive
        />
      </button>
    );
  }
}

InactiveQuestion.propTypes = {
  questionUnit: spacerFreePropTypes.questionUnitShape.isRequired,
  questionAnswer: sharedPropTypes.stringOrNumber.isRequired,
  onClick: PropTypes.func.isRequired
};

module.exports = InactiveQuestion;
