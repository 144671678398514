const {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
  } = require('react-router-dom');

const LandingPage = require('./components/infoPages/landing/LandingPage.jsx');
const AboutPage = require('./components/infoPages/about/AboutPage.jsx');
const BlogHome = require('./components/blog/BlogHome.jsx');
const BlogArticle = require('./components/blog/BlogArticle.jsx');
const BrokerReferralSignUpForm = require('./components/containers/referrals/BrokerReferralSignUpForm.jsx');
const BrokerRetrieveLinkPage = require('./components/referrals/BrokerRetrieveLinkPage.jsx');
const Quiz = require('./components/containers/quiz/Quiz.jsx');
const Results = require('./components/containers/results/Results.jsx');
const TermsOfService = require('./components/infoPages/TermsOfService.jsx');
const ErrorPage = require('../spacerFree/components/common/ErrorPage.jsx');
const TopProfilesComparison = require('./components/containers/results/profile/TopProfilesComparison.jsx');

const redirectModule = require('../shared/util/redirectModule.js');
const redirectPaths = require('wp-data').redirectPaths;

const router = createBrowserRouter(
    createRoutesFromElements(
        <>

            {/* REDIRECT */}
            {redirectModule.createRedirectComponents(redirectPaths.spacerFreeRedirectPaths)}

            {/* MAIN PAGES */}
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/quiz-spacer-by-cbre" element={<Quiz />} />
            <Route exact path="/program-your-results-spacer-by-cbre" element={<Results />} />
            {/* Inluding the old url to not break old links and rerouting won't help SEO anyway */}
            <Route exact path="/results/:id" element={<Results />} />
            <Route exact path="/program-your-results-spacer-by-cbre/:id" element={<Results />} />

            {/* BLOG PAGES */}
            <Route exact path="/insights-spacer-by-cbre" element={<BlogHome />} />
            <Route exact path="/insights-spacer-by-cbre/:slug" element={<BlogArticle />} />

            {/* BROKER REFERRAL PAGES */}
            <Route exact path="/referral" element={<BrokerReferralSignUpForm />} />
            <Route exact path="/referral/retrieve" element={<BrokerRetrieveLinkPage />} />
            <Route exact path="/referral/:sourceId" element={<LandingPage />} />

            {/* INFO PAGES */}
            <Route exact path="/about-spacer-by-cbre" element={<AboutPage />} />
            <Route exact path="/profile-comparison-spacer-by-cbre" element={<TopProfilesComparison />} />
            <Route exact path="/terms-spacer-by-cbre" element={<TermsOfService />} />

            <Route render={() => <ErrorPage errorCode={404} />} />
        </>
    )
);

module.exports = router;