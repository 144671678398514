module.exports = {

  // TermsOfService
  TermsTitle: 'Términos del servicio ',
  TermsIntroText: 'Estos es un acuerdo legal entre usted y CBRE Inc. ("CBRE") con respecto al acceso y uso de la aplicación conocida como Spacer. Por usar o acceder a alguna información o servicios en esta página de internet, usted reconoce que ha leído, entendido y acordado los términos y condiciones presentados en estos términos de uso. Los términos a los que "usted" "ustedes" o "Usuarios" se refiere colectivamente, o la persona o entidad en nombre de la que usted se encuentre representando.',

  TermsLimitationsTitle: 'Limitaciones',
  TermsLimitationsA: 'El usuario comprende y acepta que el acceso del usuario a Spacer® no es exclusivo, no se puede sublicenciar, no es transferible, es revocable libremente y CBRE puede suspenderlo o cancelarlo en cualquier momento y por cualquier motivo.',
  TermsLimitationsB: 'El usuario además comprende y acepta que la concesión de acceso a Spacer® no le conferirá al usuario ningún derecho de propiedad sobre Spacer® o cualquier documentación relacionada (incluidos los nuevos lanzamientos, modificaciones o mejoras), todo lo cual será y seguirá siendo propiedad exclusiva de CBRE, incluidos, entre otros, los derechos de autor, las patentes asociadas, las patentes de diseño, las marcas registradas, la imagen comercial, los secretos comerciales y otros derechos de propiedad derivados de la ley aplicable o las convenciones internacionales (colectivamente, "Derechos de propiedad"). Consulte la Sección 2 (Propiedad) a continuación. CBRE se reserva expresamente todos los derechos sobre Spacer® que no se conceden expresamente al Usuario en virtud del presente.',
  TermsLimitationsC: 'Salvo lo dispuesto en el presente o lo autorizado expresamente por CBRE al Usuario por escrito, el Usuario no: (i) modificará, traducirá, realizará ingeniería inversa, descompilará, desensamblará, intentará reconstruir, identificará ni descubrirá ningún código fuente, ideas subyacentes, técnicas de interfaz o algoritmos, o crear trabajos derivados o copiar Spacer®; (ii) eliminará, alterará o cubrirá cualquier aviso de derechos de autor o marca registrada u otros avisos de derechos de propiedad contenidos en Spacer®; (iii) cederá, sublicenciará o transferirá su acceso, uso u otros derechos o delegará sus obligaciones bajo este Acuerdo; o (iv) sin limitar ninguno de los anteriores, usará Spacer® con el propósito de crear un producto o servicio competitivo, o para cualquier otro propósito que no sea un propósito comercial legítimo relacionado con el negocio del Usuario.',
  TermsLimitationsD: 'El usuario se compromete a notificar a CBRE de inmediato por escrito si llega a ser de su conocimiento cualquier uso no autorizado de Spacer® o cualquier posible infracción de los derechos de propiedad.',
  TermsLimitationsE: 'En ningún momento durante o después de la vigencia de este Acuerdo, el Usuario deberá solicitar el registro de cualquier derecho de autor, marca comercial u otra designación que pueda afectar la propiedad de los derechos de propiedad ni presentar ningún documento ante ninguna autoridad gubernamental para tomar medidas que puedan afectar la propiedad de derechos de propiedad intelectual, incluida la propiedad de CBRE de Spacer®, la marca Spacer® y otros componentes de la aplicación. Spacer® es una marca comercial y de servicio de CBRE.',
  TermsLimitationsF: 'El usuario declara y acepta que tiene derecho a proporcionar todos los materiales publicados o la información utilizada en relación con Spacer®, y por el presente otorga a CBRE un derecho y una licencia de uso no exclusivos, libres de regalías, mundiales, irrevocables y perpetuos para usar, reproducir, modificar, distribuir, crear trabajos derivados y mostrar dichos materiales e información a discreción de CBRE. El usuario reconoce que el usuario es responsable de cualquier material e información que envíe, incluida su legalidad, confiabilidad, idoneidad, originalidad y derechos de autor. La licencia otorgada por el Usuario a CBRE en virtud del presente sobrevivirá a cualquier terminación del uso de Spacer® por parte del Usuario. Cualquier material e información enviados por el Usuario se considerarán no confidenciales y no patentados, y CBRE los tratará como tales, y CBRE podrá utilizarlos de conformidad con este Acuerdo sin previo aviso al Usuario y sin responsabilidad alguna para CBRE.',

  TermsOwnershipTitle: 'Propiedad',
  TermsOwnershipText: 'Spacer®, el lenguaje de programación, el software y la documentación y todo otro material o contenido disponible en Spacer® o cualquier mejora, modificación, trabajo derivado, mejoras o cambios en Spacer® y todas las copias de los mismos son propiedad de CBRE y la titularidad de los mismos permanece únicamente en CBRE. bajo todas las circunstancias, incluidos, entre otros, todos los derechos de patentes, derechos de autor, marcas registradas y secretos comerciales en Spacer® y las mejoras, modificaciones, trabajos derivados y cambios a los mismos. El usuario comprende que lo anterior incluye secretos comerciales e información que es confidencial y propiedad de CBRE y acepta tomar todas las medidas necesarias para proteger la confidencialidad de dicha información. El usuario no venderá, transferirá, publicará, divulgará, exhibirá ni pondrá a disposición Spacer® ni creará o pondrá a disposición mejoras, modificaciones, mejoras, trabajos derivados o cambios en el mismo o copias del mismo para otros. La rescisión de este Acuerdo será adicional y no en lugar de cualquier recurso disponible para CBRE tanto en derecho como en equidad. Las obligaciones en virtud del presente sobrevivirán a la terminación de este Acuerdo',

  TermsTerminationTitle: 'Terminación; efecto de la rescisión; Remedios.',
  TermsTerminationA: 'Sin limitar ningún otro recurso, CBRE puede modificar, limitar, suspender, descontinuar o rescindir este Acuerdo y/o el uso del Usuario de la totalidad o parte de Spacer®, con efecto inmediato, automáticamente, con o sin notificación y sin recurrir a los tribunales o otros tribunales, por cualquier motivo o sin motivo alguno. El usuario acepta que CBRE no tiene la obligación de proporcionar Spacer® y que CBRE no será responsable ante el usuario ni ante ninguna otra parte por ninguna limitación, suspensión, interrupción, rescisión o modificación de Spacer®.',
  TermsTerminationB: 'El usuario reconoce que las obligaciones contraídas con CBRE en virtud del presente son de naturaleza única e irremplazable, cuya pérdida dañará irremediablemente a CBRE y que no puede ser reemplazada únicamente por daños monetarios, por lo que CBRE tendrá derecho a medidas cautelares u otras medidas equitativas (sin las obligaciones de prestar cualquier fianza o fianza) en caso de incumplimiento o incumplimiento anticipado por parte del Usuario. El usuario renuncia irrevocablemente a todos los derechos de buscar medidas cautelares u otras medidas equitativas.',

  TermsDisclaimerTitle: 'Exenciones de responsabilidad y limitación de responsabilidad.',
  TermsDisclaimerA: 'Spacer® se proporciona al usuario "TAL CUAL" y "SEGÚN DISPONIBILIDAD" sin garantías. CBRE RENUNCIA A TODAS LAS GARANTÍAS, EXPRESAS O IMPLÍCITAS, INCLUIDAS, ENTRE OTRAS, LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN FIN DETERMINADO Y NO VIOLACIÓN. CBRE NO GARANTIZA DE NINGUNA MANERA QUE Spacer® CUMPLA CON LOS REQUISITOS DEL USUARIO O CUALQUIER ESTÁNDAR EN PARTICULAR. ALGUNOS ESTADOS NO PERMITEN LA EXCLUSIÓN DE GARANTÍAS IMPLÍCITAS, POR LO QUE LA EXCLUSIÓN ANTERIOR PUEDE NO APLICAR AL USUARIO. En tal caso, la responsabilidad de cbre se limitará al máximo posible según la ley aplicable. NINGUNA INFORMACIÓN ORAL O ESCRITA O CONSEJO DADO POR CBRE, SUS AGENTES O EMPLEADOS CONSTITUIRÁ UNA GARANTÍA DE NINGUNA MANERA. NO SE OTORGA NINGUNA GARANTÍA DE QUE Spacer® O CUALQUIER INFORMACIÓN PROPORCIONADA AL USUARIO ESTÉ LIBRE DE ERRORES O QUE EL USO DE Spacer® SERÁ ININTERRUMPIDO, ACTUALIZADO, COMPLETO O LIBRE DE VIRUS U OTROS COMPONENTES DAÑINOS, Y NO SE OTORGA NINGUNA GARANTÍA DE QUE CUALQUIER DE LO ANTERIOR SERÁ CORREGIDO. CBRE NO GARANTIZA NI HACE NINGUNA DECLARACIÓN CON RESPECTO AL USO O LOS RESULTADOS DEL USO DE Spacer®. CBRE NO SERÁ RESPONSABLE DE NINGÚN DAÑO INDIRECTO, ESPECIAL, INCIDENTAL, CONSECUENTE, PUNITIVO O EJEMPLAR (INCLUYENDO, ENTRE OTROS, DAÑOS POR PÉRDIDA DE NEGOCIOS, PÉRDIDA DE BENEFICIOS, PÉRDIDA DE INGRESOS, PÉRDIDA DE AHORROS, INTERRUPCIÓN INFORMÁTICA, FONDO DE COMERCIO, USO, DATOS U OTROS INTANGIBLES PÉRDIDAS), YA SEA POR INCUMPLIMIENTO DEL CONTRATO, AGRAVIO (INCLUIDA LA NEGLIGENCIA), RESPONSABILIDAD DEL PRODUCTO O DE CUALQUIER OTRO TIPO, INCLUSO SI CBRE O SUS REPRESENTANTES HAYAN SIDO ADVERTIDO DE LA POSIBILIDAD DE DICHOS DAÑOS E INCLUSO SI UNA SOLUCIÓN ESTABLECIDA EN EL PRESENTE DOCUMENTO NO HAYA SIDO SU PROPÓSITO ESENCIAL.',
  TermsDisclaimerB: 'LA RESPONSABILIDAD TOTAL DE CBRE POR DAÑOS POR CUALQUIER CAUSA NO EXCEDERÁ LA TARIFA PAGADA POR EL USUARIO POR Spacer® DURANTE EL PERÍODO DE DOCE (12) MESES ANTERIOR AL EVENTO QUE SUPUESTAMENTE CAUSÓ DICHO DAÑO. EL USUARIO ACEPTA QUE CUALQUIER CAUSA DE ACCIÓN QUE SURJA O ESTÉ RELACIONADA CON Spacer® O DE OTRO MODO RELACIONADA CON ESTE ACUERDO DEBE COMENZAR EN EL PLAZO DE UN (1) AÑO DESPUÉS DE QUE SE ACUMULA LA CAUSA DE LA ACCIÓN. DE LO CONTRARIO, DICHA CAUSA DE ACCIÓN SE PROHIBE PERMANENTEMENTE.',
  TermsDisclaimerC: 'ALGUNAS JURISDICCIONES PUEDEN NO PERMITIR LA LIMITACIÓN DE RESPONSABILIDAD O LAS EXCLUSIONES DE RESPONSABILIDAD ESTABLECIDAS ANTERIORMENTE, POR LO QUE LA LIMITACIÓN O EXCLUSIÓN ANTERIOR PUEDE NO APLICAR AL USUARIO. En tal caso, la responsabilidad de CBRE se limitará al máximo posible según la ley aplicable. La renuncia a la garantía, los recursos exclusivos y la responsabilidad limitada establecidos anteriormente son elementos fundamentales de la base de este Acuerdo entre CBRE y el USUARIO. CBRE no podría proporcionar Spacer® sobre una base económica y no habría celebrado este Acuerdo sin tales limitaciones. Estas limitaciones sobrevivirán a cualquier rescisión de este Acuerdo.',

  TermsIndemnificationTitle: 'Identificación de usuario',
  TermsIndemnificationText: 'A opción y solicitud de CBRE, el Usuario deberá, a su cargo, indemnizar y mantener indemne a CBRE y sus subsidiarias, afiliadas, funcionarios, empleados, agentes, marcas compartidas, asociaciones y otros socios de y contra cualquier pérdida, costo, daño , responsabilidades, liquidaciones y gastos (incluidos, entre otros, costos y honorarios de abogados), que surjan o estén relacionados con cualquier reclamo, acción o alegación de terceros relacionados con o que surjan de: (a) cualquier transacción o disputa entre el Usuario y cualquier tercero fiesta; (b) hechos o presuntos hechos que constituirían un incumplimiento de cualquiera de las representaciones, garantías o convenios del Usuario en virtud de este Acuerdo o de cualquier ley, regla o regulación aplicable, ya sea que se haga referencia o no en este documento; (c) cualquier material, información o contenido del Usuario enviado en o sobre Spacer®; (d) la violación del usuario de cualquier derecho de cualquier tercero, (e) el uso o mal uso de Spacer® por parte del usuario; o (f) la negligencia o dolo del Usuario. CBRE tendrá derecho a dirigir y controlar exclusivamente su defensa y contratar abogado de su elección, a costa del Usuario.',

  TermsGeneralTitle: 'General.',
  TermsGeneralA: '<0>Acuerdo completo/sin renuncia involuntaria.</0> Este Acuerdo constituye el acuerdo completo entre CBRE y el Usuario con respecto a Spacer® y todo el contenido de este Acuerdo, y reemplaza todos los acuerdos anteriores entre CBRE y el Usuario relacionados con Spacer® Si alguna. El hecho de que CBRE no haga cumplir alguna disposición de este Acuerdo no se interpretará como una renuncia a ninguna disposición o derecho.',
  TermsGeneralB: '<0>Avisos.</0> Los avisos para usted se harán por escrito y se pueden enviar por correo electrónico o publicar dichos avisos en Spacer® o en www.cbre.com/spacer. Nos enviará avisos por correo electrónico a spacersupport@cbre.com, con una copia enviada por correo postal certificado de EE. UU. a CBRE en 400 S. Hope Street, 25th Floor, Los Ángeles, CA 90071, Atención: Oficina del Asesor Jurídico. Las notificaciones se considerarán entregadas un (1) día hábil después de su envío por correo electrónico (a menos que el remitente reciba una respuesta que indique que el mensaje no se pudo entregar) o tres días hábiles después de su publicación en el sitio web de CBRE (en el caso de CBRE), ya sea o no recibido.',
  TermsGeneralC: '<0>Ley aplicable.</0> Este Acuerdo se regirá e interpretará de conformidad con las leyes del Estado de California sin tener en cuenta los conflictos de las disposiciones legales del mismo. CBRE y el Usuario se sujetan exclusivamente a lugares dentro del Estado de California. La Convención de las Naciones Unidas sobre Contratos para la Venta Internacional de Mercaderías (1980) queda excluida en su totalidad de la aplicación de este Acuerdo. El usuario acepta que Spacer® se considerará únicamente con sede en California y un servicio pasivo que no da lugar a jurisdicción personal sobre CBRE, ya sea específica o general, en jurisdicciones distintas del estado de California.',
  TermsGeneralD: '<0>Resolución de disputas.</0> Cualquier disputa, reclamo o controversia que surja de o se relacione con este Acuerdo, o el incumplimiento, cumplimiento, interpretación o validez del mismo, incluida la determinación del alcance o la aplicabilidad del arbitraje con respecto a este Acuerdo, se determinará mediante arbitraje. El arbitraje será administrado por el Servicio de Arbitraje y Mediación Judicial (JAMS), y el arbitraje se llevará a cabo con un solo árbitro. Además, el Usuario acepta que ni el Usuario ni CBRE unirán ningún reclamo con el reclamo de cualquier otra persona o entidad en el arbitraje; que ningún reclamo se resolverá a nivel colectivo; y que ni el Usuario ni CBRE harán valer ningún reclamo en calidad de representante en nombre de otra persona. No obstante lo anterior, el Usuario acepta que CBRE podrá solicitar medidas cautelares (o un tipo equivalente de medida legal urgente) en cualquier jurisdicción. Si el Usuario presenta una queja de arbitraje contra CBRE, CBRE acepta pagar cualquier parte de la tarifa de presentación inicial que supere los $250; después de las tarifas iniciales de presentación, las partes compartirán los costos del arbitraje en partes iguales. El usuario acepta no iniciar ni enjuiciar ninguna acción contra CBRE que no sea mediante la presentación de una demanda de arbitraje de conformidad con este párrafo. IMPORTANTE, AL ACEPTAR ESTAR OBLIGADO POR ESTE ACUERDO, EL USUARIO RENUNCIA A CUALQUIER DERECHO QUE TIENE DE SOLICITAR COMPENSACIÓN EN UN TRIBUNAL Y A CUALQUIER DERECHO QUE TIENE A UN JUICIO POR JURADO.',
  TermsGeneralE: '<0>Divisibilidad.</0> En el caso de que una parte de este Acuerdo se considere inaplicable, la parte no exigible se modificará solo en la medida necesaria para hacerla exigible, y el resto de las disposiciones permanecerán en pleno vigor y efecto.',
  TermsGeneralF: '<0>Cesión.</0> Este Acuerdo no puede ser cedido por el Usuario sin el consentimiento previo por escrito de CBRE. Cualquier intento de cesión sin el consentimiento de CBRE será nulo. CBRE se reserva el derecho de ceder este Acuerdo a cualquier tercero. Este Acuerdo y los derechos y obligaciones de cada uno de nosotros en',
  TermsGeneralG: '<0>Modificación.</0> CBRE puede modificar cualquiera de los términos y condiciones contenidos en este Acuerdo, en cualquier momento y a su sola y exclusiva discreción, mediante la publicación de un aviso de cambio o un nuevo acuerdo en el sitio de Spacer® en la url www.cbre.com/spacer o proporcionando una alerta o notificación similar en Spacer®. Si alguna modificación es inaceptable para el Usuario, el único recurso del Usuario es cancelar el uso de Spacer® por parte del Usuario.',
  TermsGeneralH: '<0>Conflictos.</0> En caso de que exista algún conflicto entre los términos de este Acuerdo y cualquier acuerdo de licencia totalmente ejecutado entre el Usuario y CBRE con respecto a Spacer®, los términos de dicho acuerdo de licencia prevalecerán con respecto a dicho términos contradictorios.'

};
