const React                     = require('react');
const Link                      = require('react-router-dom').Link;
const PropTypes                 = require('prop-types');
const { withTranslation }        = require('react-i18next');
const domUtil                   = require('../../../shared/util/dom');
const BasicNavbar               = require('./BasicNavbar.jsx');
const TakeOfficeSpaceQuizButton = require('../infoPages/common/TakeOfficeSpaceQuizButton.jsx');
const LanguageSelector          = require('./LanguageSelector.jsx');

function BasicNavbarWithLinks(props) {
  const [hamburgerBarOpen, setHamburgerBarOpen] = React.useState(false);
  return (
    <BasicNavbar {...props}>
      <li className="navbar-item has-margin-right-4 language-dropdown-top">
        <LanguageSelector />
      </li>
      <li className="navbar-item has-margin-right-4">
        <Link to="/about-spacer-by-cbre">{props.t('linkToAboutPageText')}</Link>
      </li>

      <li className="navbar-item has-margin-right-4">
        <Link to="/insights-spacer-by-cbre">{props.t('linkToBlogPageText')}</Link>
      </li>

      <li className="navbar-item is-mobile">
        <TakeOfficeSpaceQuizButton />
      </li>

      {
        domUtil.isScreenMobileSize() &&
        <li className="navbar-item is-mobile">
          <button
            className={`${hamburgerBarOpen ? 'hamburger-bar-open' : 'hamburger-bar'}`}
            onClick={() => setHamburgerBarOpen(!hamburgerBarOpen)}
          />
          <div className={`flex flex-col hamburger-bar-menu ${hamburgerBarOpen ? '' : 'is-hidden'}`}>
            <Link to="/about-spacer-by-cbre">{props.t('linkToAboutPageText')}</Link>
            <Link to="/insights-spacer-by-cbre">{props.t('linkToBlogPageText')}</Link>
          </div>
        </li>
      }
    </BasicNavbar>
  );
}

BasicNavbarWithLinks.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('common')(BasicNavbarWithLinks);
