const React              = require('react');
const PropTypes          = require('prop-types');
const Link               = require('react-router-dom').Link;
const { withTranslation } = require('react-i18next');
const GALabels = require('../../../optionsConfig/googleAnalytics.labels.json');
const ga = require('../../../../shared/tracking');

function TakeOfficeSpaceQuizButton(props) {
  return (
    <Link to="/quiz-spacer-by-cbre" onClick={()=>{
      ga.GAEvent(GALabels.categories.quiz, GALabels.actions.startQuiz, GALabels.labels.startQuiz);
    }}>
      <span className="button cta-button is-primary-filled has-min-width-narrow heap-size-my-office-button">{props.t('sizeMyOfficeButtonDefaultLinkText')}</span>
    </Link>
  );
}

TakeOfficeSpaceQuizButton.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('common')(TakeOfficeSpaceQuizButton);

