const actions           = require('../actions/profileQuiz');
// const globalActions     = require('../actions/global');
import globalActions from '../actions/global';

const profileCalculator = require('../../calculators/profileCalculator');
const _                 = require('lodash');

module.exports = function profileQuiz(state = {}, action) {
  let userQuizAnswers;

  switch (action.type) {
    case actions.ANSWER_PROFILE_QUESTION: {
      userQuizAnswers = _.assign({}, state.userQuizAnswers, { [action.questionUnitId]: action.selectedOption });
      return _.assign({}, state, {
        userQuizAnswers
      });
    }

    case actions.CALCULATE_PROFILE_SCORES: {
      return _.assign({}, state, {
        profileScores: profileCalculator.calculateProfileScores(state.userQuizAnswers)
      });
    }

    case globalActions.RECEIVE_PROGRAM: {
      return action.program.profileQuiz;
    }

    default: {
      return state;
    }
  }
};
