module.exports = {
  // AboutPageHero
  aboutPageHeroImgAlt: 'Illustration d\'une personne entrant dans un bureau sur la page A Propos de Spacer par CBRE',
  aboutPageHeroTitle: 'Que peut faire Spacer pour vous?',

  // AboutPageContent
  aboutPageContentEndSectionTitle: 'Prêt à démarrer?',
  // aboutPageProgramPreviewImgAlt:
  aboutPageVideoTitle: 'Comment cela fonctionne',

  // About Page Content (aboutPageContent.js)
  aboutPageContent1Title: 'Définissez votre espace de travail idéal',
  aboutPageContent1Text: 'Un espace de travail idéal vous aide à recruter les meilleurs talents, préserve le bien-être de vos collaborateurs, réduit les coûts fixes, crée des conditions pour la création de nouvelles grandes idées. Spacer est l\'outil parfait pour démarrer le process de décision en vous aidant à prioriser vos objectifs et en proposant des recommandations conformément à vos objectifs.\n Spacer a été conçu grâce à l\'expertise des équipes Design & Project de CBRE avec plus de 20 ans d\'expérience à aider les entreprises pour concevoir leurs espaces au mieux, et met à disposition ce savoir-faire via internet au travers d\'un outil gratuit accessible à tous.',
  aboutPageContent1ImgAlt: 'Illustration d\'une personne discutant sur la page A Propos de Spacer par CBRE',
  aboutPageSpacerSupportLink: 'spacersupport@cbre.com',

  aboutPageContent2Title: 'Spacer est très simple à utiliser.',
  aboutPageContent2List1: 'Démarrez en commençant par répondre à ce quizz de 17 questions concernant vos objectifs et les valeurs qui sont les vôtres. Les réponses permettront de définir le type d\'espace qui vous définit.',
  aboutPageContent2List2: 'Recevez une liste de natures d\'espaces et tailles d\'espaces correspondant à votre besoin (appelé "Programme")',
  aboutPageContent2List3: 'Visualisez un espace similaire au vôtre en 2D et 3D avec Floored Plans.',
  aboutPageContent2List4: 'Sauvegardez votre Programme pour la recherche de votre espace de travail idéal disponible sur le marché.',
  aboutPageContent2List5: 'Laissez un professionnel de CBRE rechercher un espace correspondant à votre programme. Un service gratuit !',
  aboutPageContent2Text: 'Pour plus d\'informations sur la façon dont Spacer peut vous aider sur votre recherche d\'espace, contactez-nous sur ',
  aboutPageContent2ImgAlt: 'Illustration d\'une femme travaillant à son bureau sur la page A Propos de Spacer par CBRE'
};
