module.exports = {

  // LandingPageHero
  landingPageHeroTitle: 'Permítanos ayudarle a planificar el lugar de trabajo del futuro y a encontrar el espacio adecuado para su próxima oficina',
  landingPageHeroSubtitle: 'El mundo del trabajo está cambiando debido a la reciente pandemia- reciba su recomendación de espacios personalizados con nuestra herramienta gratuita',
  landingPageHeroImgAlt: 'Vista previa de un programa espacial de la página de resultados de Spacer por CBRE',
  landingPageImage: '/png/homepage-results-page-in-browser-spacer-by-cbre.png',
  // LandingPageContent
  landingPageContentTitle: '¿Cómo funciona?',

  // StepsSummary
  stepsSummarySubtitle1: 'Paso 1 -',
  stepsSummaryTitle1: 'Cuéntenos sobre su empresa',
  stepsSummaryText1: 'Ayúdenos a conocer sus metas y objetivos como empresa para que podamos hacer recomendaciones específicas para usted.',
  stepsSummaryImgAlt1: 'Ilustración de personas de pie sobre un portátil gigante en la página de inicio de Spacer por CBRE',

  stepsSummarySubtitle2: 'Paso 2 -',
  stepsSummaryTitle2: 'Obtenga sus resultados',
  stepsSummaryText2: 'En función de sus respuestas, recibirá un tamaño de oficina recomendado y una lista de tipos de espacios individuales con cantidades de cada uno. Incluso puede ver planos del diseño de la planta como ejemplo.',
  stepsSummaryImgAlt2: 'Ilustración de un bloque de un barrio en la página de inicio de Spacer por CBRE',

  stepsSummarySubtitle3: 'Paso 3 -',
  stepsSummaryTitle3: 'Encuentre su oficina perfecta',
  stepsSummaryText3: 'Guarde sus resultados para consultarlos durante la búsqueda de oficinas o contacte el equipo de Spacer para que uno de nuestros consultores lo pueda ayudar a encontrar su oficina perfecta',
  stepsSummaryImgAlt3: 'Ilustración de una mujer trabajando en un escritorio en la página de inicio de Spacer de CBRE',

  // LandingPageSecondaryHero
  landingPageSecondaryHeroImgAlt: 'Ilustración de personas construyendo recomendaciones de espacio en la página de inicio de Spacer de CBRE',
  landingPageSecondaryHeroTitle: 'Cuéntenos un poco más de su compañía. Nosotros le contaremos cuánto espacio de oficinas necesita.'

};
