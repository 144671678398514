module.exports = {

  // LandingPageHero
  landingPageHeroTitle: 'Segítünk megtervezni és megtalálni a következő irodád megfelelő tér igényét.',
  landingPageHeroSubtitle: 'Az irodatér változóban van a pandémiás helyzet következtében. Szerezz be testre szabott javaslatokat a Spacer segítségével.',
  landingPageHeroImgAlt: 'Az eredmények oldal előnézete a Spacer a CBRE-tól holnapján',
  landingPageImage: '/png/homepage-results-page-in-browser-spacer-by-cbre.png',
  // LandingPageContent
  landingPageContentTitle: 'Hogyan működik',

  // StepsSummary
  stepsSummarySubtitle1: 'Első lépés —',
  stepsSummaryTitle1: 'Mesélj a cégedről',
  stepsSummaryText1: 'Mondd el, hogy milyen irodát képzeltél el céged számára, és mi testreszabott javaslattal segítünk.',
  stepsSummaryImgAlt1: 'Illusztráció emberekről akik egy hatalmas laptopon állnad a Spacer a CBRE-tól Honlapján',

  stepsSummarySubtitle2: 'Második lépés —',
  stepsSummaryTitle2: 'Kapd kézhez az eredményeidet',
  stepsSummaryText2: 'A válaszaidat alapul véve készítettünk neked egy iroda méret javaslatot és egy listát a tér típusokről és azok javasolt méretéről. Még példa alaprajzokat is megnézhetsz.',
  stepsSummaryImgAlt2: 'Illusztráció egy épületegyüttesről a Space a CBRE-tól honlapján',

  stepsSummarySubtitle3: 'Harmadik lépés —',
  stepsSummaryTitle3: 'Találd meg tökéletes irodád',
  stepsSummaryText3: 'Mentsd el az eredményeket amelyekre hivatkozhatsz majd irodakeresésed során vagy lépj kapcsolatba a Spacer csapatával, akik összekötnek egy tanácsadó szakértővel, hogy együtt megtaláljátok a tökéletes irodátokat.',
  stepsSummaryImgAlt3: 'Illusztráció egy hölgyről akik egy irodaasztalnál dolgozik a Spacer a CBRE-tól honlapján',

  // LandingPageSecondaryHero
  landingPageSecondaryHeroImgAlt: 'Illusztráció emberekről akik irodatérjavaslaton dolgoznak a Spacer a CBRE-tól honlapján',
  landingPageSecondaryHeroTitle: 'Mesélj a cégedről. Mi pedig megmondjuk mekkora irodatérre van szükséged.'

};
