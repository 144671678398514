const actions       = require('../actions/userData');
// const globalActions = require('../actions/global');
import globalActions from '../actions/global';

const _             = require('lodash');

module.exports = function userData(state = {}, action) {
  switch (action.type) {
    case actions.ADD_USER_DATA: {
      if (action.dataType === 'officeLocation' && action.isUpdatingCurrentPersonalLocation) {
        return _.assign({}, state, {
          officeLocation: action.value,
          currentPersonalLocation: action.value
        });
      }
      return _.assign({}, state, { [action.dataType]: action.value });
    }

    case actions.ADD_USER_DATA_BULK: {
      return _.assign({}, state, action.dataTypeValueCollection);
    }

    case globalActions.CHANGE_LANGUAGE: {
      return _.assign({}, state, {language:action.value});
    }
    
    case actions.COMPLETE_TEST_FIT_FORM: {
      return _.assign({}, state, { testFitFormCompleted: true });
    }

    case globalActions.RECEIVE_PROGRAM: {
      return action.program.userData;
    }

    default: {
      return state;
    }
  }
};
