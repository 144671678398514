module.exports = {
  // Quiz
  exitQuizButtonTitle: "Vragenlijst afsluiten",

  // QuizProgressLabel
  progressLabelText: "voltooid",

  // QuizNavigationButton
  quizNavBackButtonShortenedText: "Vorige",
  quizNavBackButtonFullLengthText: "Vorige vraag",
  quizNavNextButtonShortenedText: "Volgende",
  quizNavNextButtonFullLengthText: "Volgende vraag",

  // QuizExitSlide
  quizExitSubtitle:
    "Wij gebruiken nu jouw antwoorden om de ideale werkplek voor jouw bedrijf te bepalen.",
  quizExitImgAlt:
    "Illustratie van twee mensen die elkaar een high five geven op de pagina Over ons van Spacer by CBRE",

  // Question Unit Text (questionUnitMap.js)
  question1Text:
    "Eerste vraag: wat is de belangrijkste functie van het kantoor?",
  question2Text:
    "Wat waarderen sollicitanten volgens jou het meeste aan je organisatie?",
  question3Text:
    "Waar let je het meeste op als je nieuwe teamleden aanneemt?",
  question4Text:
    "Wat verwacht je dat er de komende vijf jaar met de omvang van je organisatie gebeurt?",
  question5Text: "Wanneer werken je medewerkers doorgaans?",
  question6Text: "Wat vind je van eigen bureaus?",
  question7Text: "Waar werken mensen als ze op kantoor zijn?",
  question8Text: "Hoe zijn medewerkers gekleed op kantoor?",
  question9Text: "Waar werken je medewerkers?",
  question10Text: "Wat voor sfeer wil je op kantoor?",
  question11Text: "Wat voor faciliteiten moet je kantoor bieden?",
  question12Text: "Welke omschrijving past het beste bij jou?",

  // Quiz Answers (questionUnitMap.js)
  pickOnePlaceholder: "Maak je keuze",
  selectFromListPlaceholder: "Kies uit de lijst",
  firstResponseTooltipText: "Kies je eerste antwoord",
  locatedSomewhereElseLabel: "We zitten op dit moment ergens anders.",

  q1AnswerText1: "We zien ons kantoor vooral als",
  q1AnswerText2: "maar ook als",
  q1AnswerText3: "en tenslotte als",
  q1Option1Text: "een tweede thuis voor onze mensen",
  q1Option2Text: "een katalysator van verandering",
  q1Option3Text: "een plek om geconcentreerd te werken",
  q1Option4Text: "een creatieve broedplaats voor grote ideeën",
  q1Option5Text: "een hulpmiddel om toptalent binnen te halen",
  q1Option6Text: "een visitekaartje voor ons merk",
  q1Option7Text: "een ruimte om te groeien en te leren",
  q1Option8Text: "een plek waar mensen elkaar ontmoeten",
  q1Option9Text:
    "een plek waarvan de kosten vooral zo laag mogelijk moeten blijven",

  q2AnswerText1: "Mensen komen met name bij ons werken vanwege",
  q2AnswerText2: "ook waarderen ze",
  q2AnswerText3: "net als",
  q2Option1Text: "onze bedrijfscultuur",
  q2Option2Text: "het salaris",
  q2Option3Text: "onze reputatie",
  q2Option4Text: "de secundaire arbeidsvoorwaarden",
  q2Option5Text: "de flexibele werktijden",
  q2Option6Text: "onze mensen en leidinggevenden",
  q2Option7Text: "de kantooromgeving",
  q2Option8Text: "het doel en de missie",

  q3AnswerText1: "We selecteren kandidaten vooral op basis van",
  q3AnswerText2: "maar kijken ook naar",
  q3AnswerText3: "en letten op",
  q3Option1Text: "vaardigheden",
  q3Option2Text: "ervaring",
  q3Option3Text: "match met onze bedrijfscultuur",
  q3Option4Text: "referenties",
  q3Option5Text: "relaties",
  q3Option6Text: "nieuws- en leergierigheid",

  q4AnswerText: "Waarschijnlijk zullen we",
  q4Option1Text: "elk jaar een beetje groeien",
  q4Option2Text: "elk jaar aanzienlijk groeien",
  q4Option3Text: "ongeveer even groot blijven",
  q4Option4Text: "elk jaar een beetje krimpen",
  q4Option5Text: "moeilijk te zeggen, dat is onvoorspelbaar",

  q5AnswerText: "We werken meestal",
  q5Option1Text: "van 9 tot 5",
  q5Option2Text: "van 's ochtends tot laat",
  q5Option3Text: "wanneer we willen, zolang het werk maar af komt",
  q5Option4Text: "wanneer we willen, zo vaak als we willen",

  q6AnswerText: "Wij denken dat",
  q6Option1Text: "iedereen een eigen bureau moet hebben",
  q6Option2Text: "we prima bureaus kunnen delen",

  q7Option1Text: "Altijd aan een bureau",
  q7Option2Text:
    "Vooral aan een bureau, behalve tijdens vergadering of andere activiteiten",
  q7Option3Text:
    "De ene helft van de tijd aan een bureau, de andere helft ergens anders",
  q7Option4Text:
    "Dat wisselt een groot deel van de tijd, maar af en toe aan een bureau",
  q7Option5Text: "Dat wisselt voortdurend, ze zijn altijd ergens anders",

  q8Option1Text: "Professioneel en zakelijk",
  q8Option2Text:
    "Vooral professioneel, behalve bij uitzonderingen",
  q8Option3Text: "Casual zakelijk; soms formeel, soms wat informeler",
  q8Option4Text: "Vooral casual, behalve bij speciale gelegenheden",
  q8Option5Text: "Casual",

  q9Option1Text: "Altijd op kantoor",
  q9Option2Text: "Meestal op kantoor",
  q9Option3Text: "We verdelen onze tijd tussen kantoor en elders",
  q9Option4Text: "We werken voornamelijk op andere plekken dan het kantoor",
  q9Option5Text: "We werken nooit op kantoor",

  q10Option1Text: "Dynamisch en energiek",
  q10Option2Text: "Energiek, met rustige momenten",
  q10Option3Text: "Dat verschilt; een combinatie van beide",
  q10Option4Text: "Rustig, met af en toe dynamische momenten",
  q10Option5Text: "Rustig en kalm",

  q11Option1Text:
    "Alleen de basisvoorzieningen, genoeg om alles draaiende te houden",
  q11Option2Text: "De basisvoorzieningen plus een paar upgrades",
  q11Option3Text:
    "De basisvoorzieningen en een plek waar mensen elkaar kunnen ontmoeten",
  q11Option4Text:
    "Iets heel prettigs waar je vanuit het kantoor graag naartoe gaat",
  q11Option5Text:
    "Iets cools en onderscheidends; het eerste wat je een bezoeker laat zien",

  q12AnswerText: "Ik ben",
  q12OptionClientText: "een bestaande klant van CBRE",
  q12OptionProfessionalText: "een deskundige van CBRE",
  q12OptionLeadText: "iemand anders",

  q13AnswerText1: "Wij verwachten",
  q13AnswerText2: "medewerkers in ons kantoor te hebben.",
  q13AnswerHelperText:
    "Tip, om groei of krimp mee te wegen: gebruik het verwachte aantal medewerkers tijdens het middelpunt  van je huurovereenkomst, of verwachte aantal medewerkers over 2-5 jaar",

  q14AnswerText1: "Wij verwachten",
  q14AnswerText2: "van onze tijd in teams te werken.",

  q15AnswerText1: "We verwachten gemiddeld",
  q15AnswerText2: "dagen per week op kantoor te werken.",

  q16AnswerText1: "Ons kantoor komt in de stad",
  q16AnswerText2: ".",

  q17AnswerText1: "Wij zijn vooral actief in de sector",
  q17AnswerText2: "sector.",

  q18AnswerText1: "We zitten op dit moment in",
  q18AnswerText2: ".",

  q19AnswerText1:
    "Wij verwachten dat onze kantoordichtheid (m2 per persoon) in de toekomst",
  q19AnswerText2: "zal zijn.",

  // Quiz Responses (questionUnitMap.js)
  q1ResponseText: "Duidelijk, dankjewel!",
  q2ResponseText: "Dat klinkt als een mooi bedrijf.",
  q3ResponseText: "Nemen we mee in het ontwerp voor je kantoor!",
  q4ResponseText:
    "Wij zorgen ervoor dat het kantoor daarbij past.",
  q5Response1Text: "Klinkt goed!",
  q5Response2Text: "Kan natuurlijk gebeuren.",
  q5Response3Text: "Top!",
  q5Response4Text: "Super!",
  q6Response1Text:
    "Oké, houden we rekening mee.",
  q6Response2Text: "Oké, houden we rekening mee.",
  q12ResponseLeadText: "Je bent bij ons aan het juiste adres.",
  q12ResponseClientText: "Fijn!",
  q12ResponseProfessionalText: "Dag collega!",

  // Industry Codes (industryCodes.js)
  animalProd: "Dierhouderij en visteelt",
  forestry: "Bosbouw en houtproductie",
  fishingHunting: "Visserij en jacht",
  agForestSupport: "Land- en bosbouw ondersteunende activiteiten",
  oilGas: "Olie- en gaswinning",
  energy: "Energie (gas, olie enz.)",
  mining: "Mijnbouw, met uitzondering van olie- en gaswinning",
  supportMining: "Mijnbouw ondersteunende activiteiten",
  utilities: "Nutsvoorzieningen",
  buildingConstruct: "Bouw",
  heavyCivilConstruct: "Weg- en waterbouw",
  specialtyContract: "Gespecialiseerde aannemers",
  foodManufac: "Levensmiddelenproductie",
  beverageTobaccoManufac: "Productie van dranken en tabaksproducten",
  textile: "Textielfabrieken",
  textileProduct: "Textielproductfabrieken",
  apparelManufac: "Kledingproductie",
  leatherManufac: "Productie van leer en aanverwante producten",
  woodManufac: "Fabricage van houtproducten",
  paperManufac: "Papierproductie",
  printingSupport: "Drukwerk en bijbehorende ondersteunende activiteiten",
  printingReproduc: "Drukwerk/reproducties",
  petroleumCoal: "Fabricage van petrochemische producten",
  chemicalManufac: "Chemische productie",
  chemicals: "Chemische stoffen",
  pharma: "Farmaceutische producten",
  cleaningManufac: "Vervaardiging van schoonmaakproducten",
  plasticsRubberManufac: "Vervaardiging van kunststof- en rubberproducten",
  nonmetallicManufac:
    "Vervaardiging van niet-metaalhoudende minerale producten",
  primaryMetalManufac: "Productie van primaire metalen",
  fabricatedMetalManufac: "Vervaardiging van bewerkte metaalproducten",
  machineryManufac: "Vervaardiging van machines",
  manufac: "Productie",
  computerElectronicManufac:
    "Vervaardiging van computer- en elektronicaproducten",
  electricalApplianceManufac: "Productie van elektronische apparaten",
  transportEquipManufac: "Productie van transportmiddelen",
  aerospaceDefense: "Ruimtevaart/defensie",
  furnitureManufac: "Vervaardiging van meubels en aanverwante producten",
  miscManufac: "Diverse productie",
  merchantWholesaleDurable: "Groothandel, duurzame goederen",
  merchantWholesaleNondurable: "Groothandel, niet-duurzame goederen",
  electronicMarket: "Elektronische markten en agenten en makelaars",
  motorVehiclePartsDealers: "Dealers van motorvoertuigen en onderdelen",
  furnitureFurnishingStores: "Winkels voor meubels en woninginrichting",
  electronicApplianceStores: "Elektronica- en witgoedwinkels",
  electronics: "Elektronica",
  buildingGardenSupplyStores: "Verkoop van bouw- en tuinmaterialen",
  foodBeverageStores: "Levensmiddelen- en drankenhandel",
  groceryStores: "Levensmiddelenhandel",
  beerWineLiquorStores: "Bier, wijn en sterkedrank",
  healthCareStores: "Winkels voor gezondheid en persoonlijke verzorging",
  gasStations: "Tankstations",
  clothingAccessoriesStores: "Kleding- en accessoirewinkels",
  clothingTextiles: "Kleding/textiel",
  jewelryFurs: "Sieraden en bont",
  sportsHobbyMusicBookStores:
    "Sport-, hobby-, muziekinstrument- en boekwinkels",
  genMerchandiseStores: "Algemene winkels",
  miscStoreRetailers: "Diverse winkels",
  officeProducts: "Kantoorartikelen",
  retailSales: "Detailhandel",
  nonstoreRetails: "Detailhandel zonder winkel",
  airTransport: "Luchtvervoer",
  travelRelated:
    "Reisbranche (luchtvaartmaatschappijen, agenten, openbaar vervoer)",
  railTransport: "Spoorvervoer",
  waterTransport: "Vervoer over water",
  truckTransport: "Vrachtwagentransport",
  transitTransport: "Openbaar personenvervoer",
  pipelineTransport: "Pijplijntransport",
  scenicTransport: "Toeristenvervoer",
  supportTransport: "Transport ondersteunende activiteiten",
  postalService: "Postdiensten",
  courierMessenger: "Koeriersdiensten",
  shippingDelivery: "Goederenvervoer en -bezorging",
  warehousingStorage: "Opslag",
  warehouseDistrib: "Opslag en distributie",
  publishingNoInternet: "Uitgeverijen, behalve internet",
  publishing: "Uitgeverij",
  motionPicSoundRecordingIndustries: "Film en geluidsopnamen",
  entertainment: "Entertainment (film, tv, muziek enz.)",
  infoServicesMarketResearch: "Informatiediensten/marktonderzoek",
  broadcastingNoInternet: "Televisie en radio (geen internet)",
  telecom: "Telecommunicatie",
  tech: "Technologie",
  dataProcessingHostingServices:
    "Dataverwerking, hosting en aanverwante diensten",
  otherInfoServices: "Overige informatiediensten",
  financeInsurance: "Financiën en verzekeringen",
  monetaryAuthorities: "Monetaire autoriteiten - centrale bank",
  creditIntermediation: "Kredietbemiddeling en aanverwante activiteiten",
  banking: "Bankwezen",
  securitiesContractsInvestments:
    "Effecten, grondstoffenovereenkomsten, investeringen",
  financialServicesNoBanking: "Financiële diensten (geen banken)",
  insuranceCarriersRelated: "Verzekeraars en aanverwante activiteiten",
  insurance: "Verzekering",
  fundsTrustsFinancialVehicles:
    "Fondsen, trusts en andere financiële instrumenten",
  realEstate: "Vastgoed",
  rentalLeasingServices: "Verhuurdiensten",
  automotive: "Automotive",
  consumerGoods: "Consumentengoederen",
  lessorsNonfinancialIntangible:
    "Verpachters van niet-financiële immateriële activa",
  professionalTechnicalServices: "Professionele en technische diensten",
  legalServices: "Juridische dienstverlening",
  accounting: "Accounting",
  architectureEngineering: "Architectuur/techniek",
  specializedDesignServices: "Gespecialiseerde ontwerpdiensten",
  compRelatedServices: "Computerdienstverlening",
  mgmtConsulting: "Managementconsulting",
  scientificResearch: "Wetenschappelijk onderzoek",
  adsMarketingPR: "Reclame/marketing/public relations",
  otherProfSciTechServices:
    "Overige professionele, wetenschappelijke en technische diensten",
  companyEnterpriseMgmt: "Management van ondernemingen",
  adminSupportServices: "Administratieve en ondersteunende dienstverlening",
  employmentAgencyRecruiters: "Uitzendbureaus/recruiters",
  securityPrivateInvestigation: "Beveiliging/privédetectives",
  wasteMgmtRemediationServices: "Afvalverwerking en sanering",
  educationServices: "Onderwijsdiensten",
  education: "Onderwijs",
  ambulatoryHealthCareServices: "Ambulante gezondheidszorg",
  healthCareRelated: "Zorggerelateerde diensten",
  hospitals: "Ziekenhuizen",
  nursingResidentialCareFacilities: "Verpleging en woonzorglocaties",
  socialAssistance: "Sociale bijstand",
  performingArtsSpectatorSports: "Uitvoerende kunsten en publiekssport",
  museumsHistoricalSitesZoosParks: "Musea, monumenten, dierentuinen en parken",
  fineArtsGalleriesMuseums: "Galerieën/musea",
  amusementsGamblingRec: "Amusement, kansspelen en recreatie",
  accommodation: "Huisvesting",
  foodDrinkingServices: "Gastronomie",
  restaurantHotel: "Restaurant/hotel",
  repairMaintenance: "Reparatie en onderhoud",
  industrialProductsHeavy: "Industriële producten (zware industrie)",
  personalLaundryServices: "Wasserijen",
  membershipAssocOrgs: "Ledenorganisaties",
  nonprofit: "Non-profit",
  privateHouseholds: "Particuliere huishoudens",
  execLegisGenGvt: "Uitvoerende, wetgevende en algemene overheid",
  gvt: "Overheid",
  justicePublicOrderSafetyActivities:
    "Justitie, openbare orde en veiligheidsactiviteiten",
  hrAdminPrograms: "Beheer van personeelsprogramma’s",
  environmentAdminPrograms: "Beheer van milieuprogramma’s",
  environmental: "Milieu",
  communityHousingProgramAdmin:
    "Beheer van gemeenschaps- en huisvestingsprogramma’s",
  econProgramAdmin: "Beheer van economische programma’s",
  spaceResearchTech: "Ruimteonderzoek en -technologie",
  natlSecurityIntlAffairs: "Nationale veiligheid en internationale zaken",
};
