const _                             = require('lodash');
const constants                     = require('wp-constants').shared;
const React                         = require('react');
const PropTypes                     = require('prop-types');
const unitsUtil                     = require('../../../../../shared/util/units');
const SpaceUnitRow                  = require('./SpaceUnitRow.jsx');
const AlphabetizedAutocompleteInput = require('../../../formSpecific/AlphabetizedAutocompleteInput.jsx');
const suggestedCustomAmenityUnitMap = require('wp-data').suggestedCustomAmenityUnitMap;
const { withTranslation }            = require('react-i18next');

const DEFAULT_CUSTOM_AMENITY_DISPLAY_UNIT = constants.spaceUnit.UNIT_LABEL.DEFAULT_CUSTOM_AMENITY_DISPLAY_UNIT;
const INITIAL_AREA = 1;

class CustomAmenityAdder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentlyAdding: false,
      displayName: '',
      dataKey: '',
      customSF: unitsUtil.getSqFeetForMeasurementSystem(INITIAL_AREA, props.measurementSystem),
      customQuantity: 1,
      displayUnit: DEFAULT_CUSTOM_AMENITY_DISPLAY_UNIT
    };

    this.onNameUpdate = this.onNameUpdate.bind(this);
    this.onAreaUpdate = this.onAreaUpdate.bind(this);
    this.onQuantityUpdate = this.onQuantityUpdate.bind(this);
    this.toggleAmenityAdder = this.toggleAmenityAdder.bind(this);
    this.addCustomAmenity = this.addCustomAmenity.bind(this);
  }

  onNameUpdate(value) {
    const amenityUnit = _.find(suggestedCustomAmenityUnitMap, amenity => value === this.props.t(amenity.displayName));
    let programPart;
    if (amenityUnit) {
      programPart = this.props.calculateCustomAmenityProgramPart(amenityUnit.dataKey);
    }

    if (programPart) {
      this.setState({
        dataKey: programPart.dataKey,
        displayName: value,
        customQuantity: programPart.customQuantity,
        customSF: programPart.customNSF,
        displayUnit: programPart.displayUnit
      });
    } else {
      this.setState({
        displayName: value,
        displayUnit: DEFAULT_CUSTOM_AMENITY_DISPLAY_UNIT
      });
    }
  }

  // id not needed, it's just passed up from SpaceUnitRow
  onQuantityUpdate(id, value) {
    if (!value || value < 1) value = 1;
    this.setState({ customQuantity: value });
  }

  onAreaUpdate(id, value) {
    if (!value || value < 1) value = 1;
    this.setState({ customSF: value });
  }

  toggleAmenityAdder(isOpen) {
    this.setState({
      currentlyAdding: isOpen,

      // clear all preset values
      displayName: '',
      customSF: unitsUtil.getSqFeetForMeasurementSystem(INITIAL_AREA, this.props.measurementSystem),
      customQuantity: 1,
      displayUnit: DEFAULT_CUSTOM_AMENITY_DISPLAY_UNIT
    });
  }

  // while the displayName can be edited, the amenity is not added to the program, adding it locks in the displayName
  addCustomAmenity() {
    if (this.state.displayName !== '') {
      this.props.addCustomAmenity(this.state.displayName, this.state.customQuantity, this.state.customSF, this.state.dataKey);
      this.toggleAmenityAdder(false);
    }
  }

  render() {
    // when not currently adding, show field to toggle visibility of adder row
    if (!this.state.currentlyAdding) {
      return (
        <div
          className="amenity-unit-adder-opener has-margin-5 has-text-amenity is-hoverable is-sans-medium is-selectable is-hidden-mobile no-outline"
          role="button"
          tabIndex={0}
          onClick={() => this.toggleAmenityAdder(true)}
        >
          <span className="open-amenity-adder" />
          <span>{this.props.t('customAmenityAddText')}</span>
        </div>
      );
    }

    // list of names for suggested custom amenities
    const CUSTOM_AMENITY_LIST = _.map(suggestedCustomAmenityUnitMap, unit => this.props.t(unit.displayName));

    const nameField = (<AlphabetizedAutocompleteInput
      allowValueNotInDropdown
      dropdownOptions={CUSTOM_AMENITY_LIST}
      value={this.state.displayName}
      onValueChange={this.onNameUpdate}
      placeholder={this.props.t('customAmenityPlaceholder')}
    />);

    return (
      <SpaceUnitRow
        isAmenityUnitAdder

        name={nameField}
        area={this.state.customSF}
        quantity={this.state.customQuantity}
        totalArea={this.state.customSF * this.state.customQuantity}
        displayUnit={this.props.t(this.state.displayUnit, { count: this.state.customQuantity })}

        areaIsEditable
        quantityIsEditable

        onAreaUpdate={this.onAreaUpdate}
        onQuantityUpdate={this.onQuantityUpdate}

        sfType={this.props.sfType}
        measurementSystem={this.props.measurementSystem}
        confirmFunction={this.addCustomAmenity}
        confirmIsActive={this.state.displayName !== ''}
        deleteFunction={() => this.toggleAmenityAdder(false)}
      />
    );
  }
}


CustomAmenityAdder.propTypes = {
  sfType: PropTypes.number.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  addCustomAmenity: PropTypes.func.isRequired,
  calculateCustomAmenityProgramPart: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(CustomAmenityAdder);
