module.exports = {
  blogHomeTitle: 'Inzichten kantoorplanning',

  // blog tags
  blogTagAllArticles: 'Alle artikelen',
  blogTagSizeAndFit: 'Passende grootte',
  blogTagOfficeCulture: 'Kantoorcultuur',
  blogTagSpaceAndDesign: 'Ruimte en ontwerp',
  blogTagWorkplaceTrends: 'Trends op de werkplek'
};
