function onQuizPage() {
  return window.location.pathname === '/quiz-spacer-by-cbre';
}

function onHomePage() {
  return window.location.pathname === '/' || window.location.pathname.indexOf('/referral') > -1;
}

function onResultsForIdPage() {
  const onOldResultsPageForIdRegex = /^\/results\/.+/;
  const onNewResultsPageForIdRegex = /^\/program-your-results-spacer-by-cbre\/.+/;
  return (onOldResultsPageForIdRegex.test(window.location.pathname) || onNewResultsPageForIdRegex.test(window.location.pathname));
}

module.exports = {
  onQuizPage,
  onHomePage,
  onResultsForIdPage
};
