const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const spaceDataActions       = require('../../../../redux/actions/spaceData');
const programCalculator      = require('../../../../calculators/programCalculator');
const CustomAmenityAdder     = require('../../../results/program/SpaceCategoryBreakdown/CustomAmenityAdder.jsx');


// Map state to props
function mapStateToProps(state) {
  return {
    sfType: state.spaceData.sfType,
    measurementSystem: state.spaceData.measurementSystem,
    calculateCustomAmenityProgramPart: programCalculator.calculateCustomAmenityProgramPart
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    addCustomAmenity: bindActionCreators(spaceDataActions.addCustomAmenity, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(CustomAmenityAdder);
