const React                     = require('react');
const PropTypes                 = require('prop-types');
const TakeOfficeSpaceQuizButton = require('./TakeOfficeSpaceQuizButton.jsx');
const { withTranslation }        = require('react-i18next');


function CallToActionBanner(props) {
  return (
    <div className="container">
      <section className="section">
        <div className="columns level">
          <div className="column is-half level-item">
            <div className="flex flex-col has-text-centered">
              <div className="title is-2 level-item is-calibre">{props.t('aboutPageContentEndSectionTitle')}</div>
              <TakeOfficeSpaceQuizButton />
            </div>
          </div>
          <div className="column is-half level-item is-hidden-mobile">
            <figure className="image">
              <img src="/png/homepage-results-page-in-browser-spacer-by-cbre.png" alt={props.t('aboutPageProgramPreviewImgAlt')} />
            </figure>
          </div>
        </div>
      </section>
    </div>
  );
}

CallToActionBanner.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('aboutPage')(CallToActionBanner);
