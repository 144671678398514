const React = require("react");
const HeroSection = require("../../common/HeroSection.jsx");
import ReactPlayer from "react-player";

function VideoPlayer(props) {
  const [vedioUrl, setVedioUrl] = React.useState(
    "https://cbre.qumucloud.com/asset/3PUdjadpXsaYoJzXQApkB2.mp4?delivery=CDN_DOWNLOAD&format=canonical"
  );
  const videos = [
    {
      url: "https://cbre.qumucloud.com/asset/3PUdjadpXsaYoJzXQApkB2.mp4?delivery=CDN_DOWNLOAD&format=canonical",
      thumb: "https://cbre.qumucloud.com/kulu/SKAhntaWNAGy84HoARNAaj/thumbnail",
      title: "Bij 88% van de organisaties werkt hybride werken niet",
    },
    {
      url: "https://cbre.qumucloud.com/asset/UbS5g1Dy1ujB8PE5afJdxA.mp4?delivery=CDN_DOWNLOAD&format=canonical",
      thumb: "https://cbre.qumucloud.com/kulu/RVsB4tZwJ5duAN04rfSwPZ/thumbnail",
      title:
        "Op maandag en vrijdag een leeg kantoor en op dinsdag en donderdag geen plek?",
    },
  ];

  return (
    <HeroSection sizeClass="is-small">
      <div className="columns">
        <div className="column is-two-thirds">
          <ReactPlayer
            url={vedioUrl}
            controls={true}
            width={"100%"}
            height={"100%"}
          />
        </div>

        <div className="column is-one-third">
          {videos.map((vedio, index) => {
            return (
              <div
                className="columns level has-padding-bottom-6"
                key={index}
                onClick={() => setVedioUrl(vedio.url)}
              >
                <div className="column is-half">
                  <img
                    src={vedio.thumb}
                    alt="Thumbnail"
                    className="video-thumbnail"
                  />
                </div>
                <div className="column is-half">
                  <p className="has-padding-left-6">{vedio.title}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </HeroSection>
  );
}

export default VideoPlayer;
