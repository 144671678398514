module.exports = {

  // LandingPageHero
  landingPageHeroTitle: 'Let us help you plan for the workplace of the future and find the right amount of space for your next office.',
  landingPageHeroSubtitle: 'The world of workplace is changing due to the recent pandemic - receive your custom space recommendation using our free tool',
  landingPageHeroImgAlt: 'Preview of a results page space program from the homepage of Spacer by CBRE',
  landingPageImage: '/png/homepage-results-page-in-browser-spacer-by-cbre.png',
  // LandingPageContent
  landingPageContentTitle: 'How it works',

  // StepsSummary
  stepsSummarySubtitle1: 'Step 1 —',
  stepsSummaryTitle1: 'Tell us about your company',
  stepsSummaryText1: 'Help us to get to know your goals and objectives as a company so we can make recommendations specific to you.',
  stepsSummaryImgAlt1: 'Illustration of people standing on a giant laptop on the Home page of Spacer by CBRE',

  stepsSummarySubtitle2: 'Step 2 —',
  stepsSummaryTitle2: 'Get your results',
  stepsSummaryText2: 'Based on your responses, you will receive a recommended office size and a list of individual space types with quantities of each. You can even view example floor plans.',
  stepsSummaryImgAlt2: 'Illustration of a neighborhood block on the Home page of Spacer by CBRE',

  stepsSummarySubtitle3: 'Step 3 —',
  stepsSummaryTitle3: 'Find your perfect office',
  stepsSummaryText3: 'Save your results to refer back to during the office search, or contact the Spacer team to connect with one of our leasing professionals who can assist in finding your perfect office.',
  stepsSummaryImgAlt3: 'Illustration of a girl working at a desk on the Home page of Spacer by CBRE',

  // LandingPageSecondaryHero
  landingPageSecondaryHeroImgAlt: 'Illustration of people building space recommendations on the Home page of Spacer by CBRE',
  landingPageSecondaryHeroTitle: 'Tell us a little about your company. We’ll tell you how much office space you need.'

};
