const React              = require('react');
const PropTypes          = require('prop-types');
const Link               = require('react-router-dom').Link;
const { withTranslation } = require('react-i18next');


function BlogArticlePreview(props) {
  const { date, image, author, namespace, slug } = props.article;

  // blogtodo: format date differently in different countries / prep for internationalization
  const dateString = (new Date(date)).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

  const articleHref = `/insights-spacer-by-cbre/${slug}`;

  return (
    <Link to={articleHref}>
      <div className="blog-article-preview" >
        <figure className="image">
          <img src={`/jpg/blogArticles/${image}`} alt="" />
        </figure>
        <div className="has-padding-7">
          <div className="content is-small has-text-grey is-sans-medium">{`${dateString} by ${author}`}</div>
          <h3 className="subtitle blog-link">{props.t(`${namespace}:title`)}</h3>
          <div className="blog-article-preview-content">{props.t(`${namespace}:preview`)}</div>
          <div className="blog-link is-sans-medium" href={articleHref}>Continue Reading</div>
        </div>
      </div>
    </Link>
  );
}

BlogArticlePreview.propTypes = {
  t: PropTypes.func.isRequired,
  article: PropTypes.shape({
    date: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    namespace: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  }).isRequired
};

module.exports = withTranslation('blogArticles')(BlogArticlePreview);
