const React              = require('react');
const PropTypes          = require('prop-types');
const constants          = require('wp-constants').spacerFree;
const ContactForm        = require('../containers/forms/ContactForm.jsx');
const SuccessModal       = require('./SuccessModal.jsx');
const { withTranslation } = require('react-i18next');

// NOTE: this component was written to be flexible and work with multiple types of forms,
// however, all modal forms except FIND_SPACE have been deprecated, so it currently only hooks up to that one form

function getContentAndFormForType(type, isSubmittingForm, submitFormError, t) {
  let header;
  let body;
  let form;
  let successMessage;

  switch (type) {
    case constants.modal.FORM_TYPES.FIND_SPACE: {
      header = t('formModalFindSpaceHeader');
      body = t('formModalFindSpaceBody');
      form = <ContactForm contactType={type} isSubmittingForm={isSubmittingForm} submitFormError={submitFormError} />;
      successMessage = t('formModalFindSpaceSuccessMessage');
      break;
    }

    default: {
      console.log('Invalid form type', type);
    }
  }

  return { header, body, form, successMessage };
}

class FormModal extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.submitFormSuccess && !nextProps.submitFormSuccess) this.props.closeModal();
  }

  render() {
    const { header, body, form, successMessage } = getContentAndFormForType(this.props.formType, this.props.isSubmittingForm, this.props.submitFormError, this.props.t);

    if (this.props.submitFormSuccess) return <SuccessModal text={successMessage} />;

    return (
      <div className="columns">

        <div className="column is-5">
          <div className="title is-4 has-text-primary has-margin-top-3 has-no-margin-top-mobile">{header}</div>
          <p>{body}</p>
        </div>

        <div className="column is-7">
          {form}
        </div>
      </div>
    );
  }
}

FormModal.propTypes = {
  formType: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  isSubmittingForm: PropTypes.bool.isRequired,
  submitFormSuccess: PropTypes.bool.isRequired,
  submitFormError: PropTypes.object,
  t: PropTypes.func.isRequired
};

FormModal.defaultProps = {
  submitFormError: null
};


module.exports = withTranslation('resultsPage')(FormModal);
