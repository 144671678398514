const React     = require('react');
const PropTypes = require('prop-types');

function Tooltip(props) {
  return (
    <span className={`tooltip has-icon is-tooltip-${props.position} ${props.noMultiline ? '' : 'is-tooltip-multiline'} is-hidden-mobile`} data-tooltip={props.text}>
      <img
        className="icon is-small"
        src={props.img}
        alt={props.altText || props.text}
      />
    </span>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.string,
  img: PropTypes.string.isRequired,
  noMultiline: PropTypes.bool,
  altText: PropTypes.string
};

Tooltip.defaultProps = {
  position: 'right',
  noMultiline: false,
  altText: null
};

module.exports = Tooltip;
