const React                  = require('react');
const PropTypes              = require('prop-types');
const DropdownSelectionInput = require('../../../form/DropdownSelectionInput.jsx');
const SPACE_UNIT_CATEGORY    = require('wp-constants').shared.spaceUnit.CATEGORY;
const { withTranslation }     = require('react-i18next');

const optionOrder = [
  SPACE_UNIT_CATEGORY.ME,
  SPACE_UNIT_CATEGORY.WE,
  SPACE_UNIT_CATEGORY.AMENITY
];

function SpaceCategoryDropdown(props) {
  const dropdownOptions = {
    [SPACE_UNIT_CATEGORY.ME]: { text: props.t('spaceCatDropdownMeText') },
    [SPACE_UNIT_CATEGORY.WE]: { text: props.t('spaceCatDropdownWeText') },
    [SPACE_UNIT_CATEGORY.AMENITY]: { text: props.t('spaceCatDropdownAmenityText') }
  };

  return (
    <DropdownSelectionInput
      options={dropdownOptions}
      optionOrder={optionOrder}
      {...props}
    />
  );
}

SpaceCategoryDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(SpaceCategoryDropdown);
