const React      = require('react');
const PropTypes  = require('prop-types');
const classnames = require('classnames');

function ButtonPair(props) {
  const leftButtonClasses = classnames('button no-focus-style is-tertiary-outlined-modal', {
    'has-tertiary-text': props.onLightBackground
  })

  const rightButtonClasses = 'button no-focus-style is-tertiary-filled-modal';

  return (
    <div className="button-pair">
      <button
        className={leftButtonClasses}
        onClick={props.leftButton.onClick}
      >
        {props.leftButton.text}
      </button>

      <button
        className={rightButtonClasses}
        onClick={props.rightButton.onClick}
      >
        {props.rightButton.text}
      </button>
    </div>
  );
}

const buttonProps = PropTypes.shape({
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
});

ButtonPair.propTypes = {
  leftButton: buttonProps.isRequired,
  rightButton: buttonProps.isRequired,
  onLightBackground: PropTypes.bool
};

ButtonPair.defaultProps = {
  onLightBackground: false
};

module.exports = ButtonPair;
