const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const modalActions           = require('../../../../redux/actions/modal');
const profileMap             = require('wp-data').profileMap;
const Profile                = require('../../../results/profile/Profile.jsx');


// Map state to props
function mapStateToProps(state, ownProps) {
  const profile = profileMap[state.spaceData.profileId];
  const profileType = state.spaceData.profileType;

  return {
    displayName: ownProps.t(profile.displayNameToken),
    description: ownProps.t(profile.descriptionToken),
    keyTraitIds: profile.keyTraitIds,
    profileType: state.spaceData.profileType,
    profileHeroImagePath: profile.profileHeroImage
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(modalActions.openModal, dispatch)
  };
}


module.exports = connect(mapStateToProps, mapDispatchToProps)(Profile);
