const React               = require('react');
const PropTypes           = require('prop-types');
const SPACE_UNIT_CATEGORY = require('wp-constants').shared.spaceUnit.CATEGORY;
const { withTranslation }  = require('react-i18next');
const BarChartSegment     = require('../../../../shared/components/common/BarChartSegment.jsx');

function TabbedProgramBarChart(props) {
  const onSelectSegment = function(spaceUnitCategory) {
    return () => props.updateActiveCategory(spaceUnitCategory);
  };

  const barChartSegmentData = [
    {
      colorClass: 'me',
      spaceUnitCategory: SPACE_UNIT_CATEGORY.ME,
      amount: props.meSF,
      percentage: props.mePercentage,
      percentageSubtitle: props.t('tabbedBarChartMeSubtitle')
    },
    {
      colorClass: 'we',
      spaceUnitCategory: SPACE_UNIT_CATEGORY.WE,
      amount: props.weSF,
      percentage: props.wePercentage,
      percentageSubtitle: props.t('tabbedBarChartGroupSubtitle'),
      isGroupSpace: true
    },
    {
      colorClass: 'amenity',
      spaceUnitCategory: SPACE_UNIT_CATEGORY.AMENITY,
      amount: props.amenitySF,
      percentage: props.amenityPercentage,
      percentageSubtitle: props.t('tabbedBarChartAmenitySubtitle')
    }
  ];

  const barChartItems = barChartSegmentData.map(item => (
    <BarChartSegment
      key={item.percentageSubtitle}
      isInactive={props.activeCategory !== item.spaceUnitCategory}
      onSelectSegment={onSelectSegment(item.spaceUnitCategory)}
      leadWithPercentage
      measurementSystem={props.measurementSystem}
      {...item}
    />
  ));

  return <div className="flex program-bar-chart has-padding-3 has-no-padding-mobile">{barChartItems}</div>;
}

TabbedProgramBarChart.propTypes = {
  meSF: PropTypes.number.isRequired,
  weSF: PropTypes.number.isRequired,
  amenitySF: PropTypes.number.isRequired,
  mePercentage: PropTypes.string.isRequired,
  wePercentage: PropTypes.string.isRequired,
  amenityPercentage: PropTypes.string.isRequired,
  activeCategory: PropTypes.string.isRequired,
  updateActiveCategory: PropTypes.func.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(TabbedProgramBarChart);
