module.exports = {
  // AboutPageHero
  aboutPageHeroImgAlt: 'Darstellung von Personen, die in ein Büro einziehen, auf der Infopage von Spacer by CBRE',
  aboutPageHeroTitle: 'Warum Spacer?',

  // AboutPageContent
  aboutPageContentEndSectionTitle: 'Sind Sie bereit loszulegen?',
  // aboutPageProgramPreviewImgAlt: no translation yet
  aboutPageVideoTitle: 'So funktioniert´s',

  // About Page Content (aboutPageContent.js)
  aboutPageContent1Title: 'Definieren Sie das perfekte Büro',
  aboutPageContent1Text: 'Das perfekte Büro hilft Ihnen dabei, Talente einzustellen, für die Gesundheit Ihrer Mitarbeiter zu sorgen, Betriebskosten zu senken und beste Voraussetzungen für großartige Ideen zu schaffen. Spacer ist das ideale Tool, um den Entscheidungsprozess rund um Ihre Immobilie zu starten. Denn Spacer hilft Ihnen, Ihre Ziele als Unternehmen zu priorisieren und Ihren Raumbedarf an diesen Zielen zu orientieren. Spacer entstand aus der Expertise des CBRE Workplace-Teams, einer weltweit agierenden Abteilung mit über zwanzig Jahren Erfahrung. Mit dieser kostenlosen, internetbasierten Technologie, die jedem zugänglich ist, unterstützen wir Sie bei der Planung Ihres Büros.',
  aboutPageContent1ImgAlt: 'Darstellung von sprechenden Personen auf der Infopage von Spacer by CBRE',
  aboutPageSpacerSupportLink: 'spacersupport@cbre.com',

  aboutPageContent2Title: 'Spacer ist einfach zu bedienen:',
  aboutPageContent2List1: 'Beginnen Sie mit der Beantwortung von 17 Fragen zu Ihren Unternehmenszielen und -werten. Ihre Antworten liefern die Informationen für das Büroprofil, in das wir Sie einstufen.',
  aboutPageContent2List2: 'Erhalten Sie eine Liste mit Indikationen zu Raumtypen und -größen, die Ihren individuellen Anforderungen entsprechen, auch bekannt als Space-Programm.',
  aboutPageContent2List3: 'Visualisieren Sie einen beispielhaften Raum in 2D und 3D mit Floored Plans.',
  aboutPageContent2List4: 'Speichern Sie Ihr Profil, um später darauf zurückgreifen zu können.',
  aboutPageContent2List5: 'Lassen Sie sich von einem CBRE Workplace Experten bei der Suche nach Ihrem perfekten Büro beraten.',
  aboutPageContent2Text: 'Für weitere Fragen zu Spacer kontaktieren Sie ',
  aboutPageContent2ImgAlt: 'Darstellung einer Frau, die an einem Schreibtisch arbeitet auf der Infopage von Spacer by CBRE.'
};
