// const globalActions = require('../actions/global');
import globalActions from '../actions/global';

const _             = require('lodash');

module.exports = function requests(state = {}, action) {
  switch (action.type) {
    case globalActions.REQUEST_PROGRAM: {
      return _.assign({}, state, {
        fetchProgramError: null,
        isFetchingProgram: true
      });
    }

    case globalActions.RECEIVE_PROGRAM: {
      return _.assign({}, state, {
        fetchProgramError: null,
        isFetchingProgram: false
      });
    }

    case globalActions.REQUEST_PROGRAM_ERROR: {
      console.log(action.error);
      return _.assign({}, state, {
        fetchProgramError: action.error,
        isFetchingProgram: false
      });
    }

    case globalActions.SUBMIT_FORM: {
      return _.assign({}, state, {
        isSubmittingForm: true,
        submitFormSuccess: false,
        submitFormError: null
      });
    }

    case globalActions.SUBMIT_FORM_SUCCESS: {
      return _.assign({}, state, {
        isSubmittingForm: false,
        submitFormSuccess: true,
        submitFormError: null,
        wasProgramSaved: action.isSaveForm || state.wasProgramSaved
      });
    }

    case globalActions.SUBMIT_FORM_ERROR: {
      console.log(action.error);
      return _.assign({}, state, {
        isSubmittingForm: false,
        submitFormSuccess: false,
        submitFormError: action.error
      });
    }

    case globalActions.RESET_SUBMIT_FORM_PROPERTIES: {
      return _.assign({}, state, {
        isSubmittingForm: false,
        submitFormSuccess: false,
        submitFormError: null
      });
    }

    case globalActions.REMOVE_PROGRAM_SAVED_STATUS: {
      return _.assign({}, state, {
        wasProgramSaved: false
      });
    }

    default: {
      return state;
    }
  }
};
