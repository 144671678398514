const React                  = require('react');
const PropTypes              = require('prop-types');
const sharedPropTypes        = require('../../../shared/components/sharedPropTypes');
const constants              = require('wp-constants').spacerFree;
const DropdownSelectionInput = require('../form/DropdownSelectionInput.jsx');
const { withTranslation }     = require('react-i18next');

const optionOrder = [
  constants.calculator.COLLABORATION_LEVEL.LOW,
  constants.calculator.COLLABORATION_LEVEL.MEDIUM,
  constants.calculator.COLLABORATION_LEVEL.HIGH
];

function CollaborationDropdown(props) {
  const dropdownOptions = {
    [constants.calculator.COLLABORATION_LEVEL.LOW]: { text: props.t('collabDropdownLevelLowText') },
    [constants.calculator.COLLABORATION_LEVEL.MEDIUM]: { text: props.t('collabDropdownLevelMedText') },
    [constants.calculator.COLLABORATION_LEVEL.HIGH]: { text: props.t('collabDropdownLevelHighText') }
  };

  return (
    <DropdownSelectionInput
      options={dropdownOptions}
      optionOrder={optionOrder}
      {...props}
    />
  );
}

CollaborationDropdown.propTypes = {
  value: sharedPropTypes.stringOrNumber.isRequired,
  onValueChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLarge: PropTypes.bool,
  t: PropTypes.func.isRequired
};

CollaborationDropdown.defaultProps = {
  disabled: false,
  isLarge: false
};

module.exports = withTranslation('resultsPage')(CollaborationDropdown);

