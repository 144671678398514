const React                = require('react');
const BasicNavbarWithLinks = require('../../common/BasicNavbarWithLinks.jsx');
const AboutPageHero        = require('./AboutPageHero.jsx');
const AboutPageContent     = require('./AboutPageContent.jsx');
const Footer               = require('../../common/Footer.jsx');
const DocumentData         = require('../../common/DocumentData.jsx');
const constants            = require('wp-constants').spacerFree;

function AboutPage() {
  return (
    <div>
      <DocumentData
        siteLocation={constants.siteLocation.PAGE.ABOUT}
      />
      <BasicNavbarWithLinks />
      <main>
        <AboutPageHero />
        <AboutPageContent />
      </main>
      <Footer />
    </div>
  );
}

module.exports = AboutPage;
