const React     = require('react');
const PropTypes = require('prop-types');
const ButtonPair = require('../common/ButtonPair.jsx');

function ConfirmationModal(props) {
  return (
    <div>
      <div className="title2 is-4">{props.title}</div>

      <div className="has-margin-top-4 has-margin-bottom-4">{props.text}</div>
      <ButtonPair
        onLightBackground
        leftButton={{ text: props.cancel.text, onClick: props.cancel.onClick }}
        rightButton={{ text: props.confirm.text, onClick: props.confirm.onClick }}
      />
    </div>
  );
}

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  confirm: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func
  }).isRequired,
  cancel: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func
  }).isRequired
};

module.exports = ConfirmationModal;
