const React                  = require('react');
const PropTypes              = require('prop-types');
const sharedPropTypes        = require('../../../shared/components/sharedPropTypes');
const DropdownSelectionInput = require('../form/DropdownSelectionInput.jsx');
const numericDisplay         = require('../../util/numericDisplay');

const dropdownOptionOrder = [5, 4.5, 4, 3.5, 3, 2.5, 2];

function DaysInOfficeDropdown(props) {
  const dropdownOptions = {};
  for (let i = 0, len = dropdownOptionOrder.length; i < len; i += 1) {
    const parsedOption = parseFloat(dropdownOptionOrder[i]);
    dropdownOptions[dropdownOptionOrder[i]] = { text: numericDisplay.numberWithLocaleFormat(parsedOption) };
  }
  return (
    <DropdownSelectionInput
      options={dropdownOptions}
      optionOrder={dropdownOptionOrder}
      {...props}
    />
  );
}

DaysInOfficeDropdown.propTypes = {
  value: sharedPropTypes.stringOrNumber.isRequired,
  onValueChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLarge: PropTypes.bool
};

DaysInOfficeDropdown.defaultProps = {
  disabled: false,
  isLarge: false
};

module.exports = DaysInOfficeDropdown;

