const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const constants              = require('wp-constants').spacerFree;
const modalActions           = require('../../../redux/actions/modal');
// const globalActions          = require('../../../redux/actions/global');
import globalActions from '../../../redux/actions/global';
const Modal                  = require('../../modals/Modal.jsx');

// Map state to props
function mapStateToProps(state) {
  return {
    modalType: state.modal.modalType,
    modalProps: state.modal.modalProps
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    closeModal: bindActionCreators(modalActions.closeModal, dispatch),
    afterClose: {
      [constants.modal.TYPES.FORM]: bindActionCreators(globalActions.resetSubmitFormProperties, dispatch)
    }
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(Modal);
