const React              = require('react');
const PropTypes          = require('prop-types');
const { withTranslation } = require('react-i18next');

function ReferralLinkConfirmInformation(props) {
  const editReferralButtonEl = React.useRef(null);

  // manage focus - move it to first input when getting to this step
  React.useEffect(() => {
    editReferralButtonEl.current.focus();
  }, []);

  return (
    <form>
      <h1 aria-live="polite" className="title has-text-primary flex flex-justify-center">{props.t('confirmYourInformation')}</h1>
      <div className="flex flex-col referral-instructions-and-form user-inputs">
        <div className="flex">
          <h2 className="form-title">{props.t('common:formLabel')}</h2>
          <button
            ref={editReferralButtonEl}
            className="edit-referral-info-button"
            onClick={props.goToPreviousStep}
            aria-label={props.t('editReferralInfo')}
          />
        </div>
        <div className="subtitle">{props.t('noLaterChanges')}</div>
        <div className="columns is-mobile">
          <div className="column is-half force-flex flex-col">
            <span>{props.t('firstName')}</span>
            <span className="bold-input">{props.firstName}</span>
          </div>
          <div className="column is-half force-flex flex-col">
            <span>{props.t('lastName')}</span>
            <span className="bold-input">{props.lastName}</span>
          </div>
        </div>
        <div className="flex flex-col">
          <span>{props.t('email')}</span>
          <span className="bold-input">{props.email}</span>
        </div>
      </div>
      <button
        className="button is-primary-filled force-flex submitBtn"
        onClick={(e) => {
          e.preventDefault();
          props.createSource();
        }}
        aria-describedby="serverError"
      >
        {props.t('next')}
      </button>
      <div className={`form-error-text server-error ${!props.submitError ? 'is-invisible' : ''}`} id="serverError">
        {props.t('somethingWrong')}
      </div>
    </form>
  );
}

ReferralLinkConfirmInformation.propTypes = {
  t: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  goToPreviousStep: PropTypes.func.isRequired,
  createSource: PropTypes.func.isRequired,
  submitError: PropTypes.bool.isRequired
};

module.exports = withTranslation('referrals')(ReferralLinkConfirmInformation);
