const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const userDataActions        = require('../../../redux/actions/userData');
const TestFitForm            = require('../../results/program/TestFitForm.jsx');

// Map state to props
function mapStateToProps(state) {
  return {
    id: state.spaceData._id
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    // submitTestFitForm submits all the data from the form and updates the store with it, but doesn't flip the completedTestFit flag
    submitTestFitForm: bindActionCreators(userDataActions.addBulkUserData, dispatch),
    // completeTestFitForm flips the completedTestFit flag to true and does nothing else
    completeTestFitForm: bindActionCreators(userDataActions.completeTestFitForm, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(TestFitForm);
