const _ = require('lodash');
const requestUtil = require('../../../shared/util/request');
const programCalculator = require('../../calculators/programCalculator');
const profileCalculator = require('../../calculators/profileCalculator');

const spaceDataActions = require('./spaceData');
const GALabels = require('../../optionsConfig/googleAnalytics.labels.json');
const ga = require('../../../shared/tracking');
const router = require('../../router.jsx');

// Action Types

const REQUEST_PROGRAM = 'REQUEST_PROGRAM';
const RECEIVE_PROGRAM = 'RECEIVE_PROGRAM';
const REQUEST_PROGRAM_ERROR = 'REQUEST_PROGRAM_ERROR';

const SUBMIT_FORM = 'SUBMIT_FORM';
const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS';
const SUBMIT_FORM_ERROR = 'SUBMIT_FORM_ERROR';
const RESET_SUBMIT_FORM_PROPERTIES = 'RESET_SUBMIT_FORM_PROPERTIES';
const REMOVE_PROGRAM_SAVED_STATUS = 'REMOVE_PROGRAM_SAVED_STATUS';

const SEND_HEAP_EVENT = 'SEND_HEAP_EVENT';
const SEND_HEAP_EVENT_WITHOUT_PROPS = 'SEND_HEAP_EVENT_WITHOUT_PROPS';

const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
const FORM_TO_URL_MAP = {
  SAVE: '/spacePrograms',
  CONTACT: '/contact'
};

// Private Action Helpers

function requestProgram() {
  return { type: REQUEST_PROGRAM };
}

function receiveProgram(json) {
  return { type: RECEIVE_PROGRAM, program: json };
}

function requestProgramError(error) {
  return { type: REQUEST_PROGRAM_ERROR, error };
}

function fetchProgram(id) {
  return (dispatch) => {
    dispatch(requestProgram());
    return fetch(`/spacePrograms/${id}`)
      .then(response => requestUtil.parseJSONResponse(response))
      .then(
        (json) => {
          const profileType = profileCalculator.calculateProfileType(json.userData.officeLocation, json.spaceData.assumptions.headcount, json.profileQuiz.userQuizAnswers);
          json.spaceData.profileType = profileType;
          json.spaceData._id = json._id;
          programCalculator.initSharedProgramCalculator(json.spaceData.program, json.spaceData.assumptions.headcount, json.userData.officeLocation, json.spaceData.assumptions.officeDensity);
          let areaTotals = json.spaceData.program.areaTotals;
          let obj={
            meSF: areaTotals.totalCustomMeSF? areaTotals.totalCustomMeSF : areaTotals.totalMeSF, 
            weSF: areaTotals.totalCustomWeSF? areaTotals.totalCustomWeSF : areaTotals.totalWeSF, 
            amenitySF: areaTotals.totalCustomAmenitySF? areaTotals.totalCustomAmenitySF : areaTotals.totalAmenitySF
            }
          json.spaceData.program.areaPercentage = programCalculator.roundPercentageTotals([obj.meSF, obj.weSF, 
            obj.amenitySF]);
          dispatch(receiveProgram(json));
        },
        error => dispatch(requestProgramError(error))
      );
  };
}

function shouldFetchProgram(state) {
  return (!state.requests.isFetchingProgram && (_.isEmpty(state.spaceData.program) || state.changeDetected.changesMadeToResultsDetails));
}

function initiateSubmitForm() {
  return { type: SUBMIT_FORM };
}

function submitFormSuccess(isSaveForm) {
  return { type: SUBMIT_FORM_SUCCESS, isSaveForm };
}

function submitFormError(error) {
  return { type: SUBMIT_FORM_ERROR, error };
}

function resetSubmitFormProperties() {
  return { type: RESET_SUBMIT_FORM_PROPERTIES };
}

function sendHeapEvent(eventName) {
  return { type: SEND_HEAP_EVENT, eventName };
}

function sendHeapEventWithoutProps(eventName) {
  return { type: SEND_HEAP_EVENT_WITHOUT_PROPS, eventName };
}

function changeSLanguage(val) {
  return { type: CHANGE_LANGUAGE, value: val };
}

function postRequestFromFormAndStore(url, userFormData, heapEventName) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.requests.isSubmittingForm) return Promise.resolve();

    dispatch(initiateSubmitForm());
    if (heapEventName) dispatch(sendHeapEvent(heapEventName));

    const dataToPost = _.cloneDeep({
      spaceData: state.spaceData,
      userData: state.userData,
      profileQuiz: state.profileQuiz
    });
    _.assign(dataToPost.userData, userFormData); // add user data that was input in the corresponding form (name, email, phone, etc.)

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(dataToPost),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => requestUtil.parseJSONResponse(response))
      .then(
        (parsedJson) => {
          const isSaveForm = (url.match(FORM_TO_URL_MAP.SAVE) !== null);
          if (isSaveForm && parsedJson._id) {
            // if this was an initial save, not an update to an exisiting program, redirect
            if (url.indexOf(parsedJson._id) === -1) {
              router.navigate(`/program-your-results-spacer-by-cbre/${parsedJson._id}`);
            }
            dispatch(spaceDataActions.setProgramId(parsedJson._id));
          }
          dispatch(submitFormSuccess(isSaveForm));
          setTimeout(() => {
            dispatch(resetSubmitFormProperties());
          }, 3000);
        },
        error => dispatch(submitFormError(error))
      );
  };
}


export default {

  // Action Types

  REQUEST_PROGRAM,
  RECEIVE_PROGRAM,
  REQUEST_PROGRAM_ERROR,
  SUBMIT_FORM,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_ERROR,
  RESET_SUBMIT_FORM_PROPERTIES,
  REMOVE_PROGRAM_SAVED_STATUS,
  SEND_HEAP_EVENT,
  SEND_HEAP_EVENT_WITHOUT_PROPS,
  CHANGE_LANGUAGE,

  // Action Creators

  fetchProgramIfNeeded(id) {
    return (dispatch, getState) => {
      if (shouldFetchProgram(getState())) {
        return dispatch(fetchProgram(id));
      }
      return Promise.resolve();
    };
  },

  saveProgram(userFormData) {
    return postRequestFromFormAndStore(FORM_TO_URL_MAP.SAVE, userFormData, 'Save Program');
  },

  updateProgram(id) {
    const url = `${FORM_TO_URL_MAP.SAVE}/${id}`;
    return postRequestFromFormAndStore(url);
  },

  contactSupport(userFormData) {
    ga.GAEvent(GALabels.categories.result, GALabels.actions.connectAdvisorFormSubmit, GALabels.labels.connectAdvisorFormSubmit);
    return postRequestFromFormAndStore(FORM_TO_URL_MAP.CONTACT, _.assign({ programLink: window.location.href }, userFormData));
  },

  resetSubmitFormProperties,

  removeProgramSavedStatus() {
    return { type: REMOVE_PROGRAM_SAVED_STATUS };
  },

  sendHeapEvent,

  sendHeapEventWithoutProps,
  changeSLanguage

};
