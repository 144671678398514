module.exports = {

  // TermsOfService
  TermsTitle: 'Conditions d\'utilisation',
  TermsIntroText: 'Il s\'agit d\'un accord entre vous et CBRE, Inc. («CBRE») concernant l\'accès à et l\'utilisation de l\'application connue sous le nom de Spacer®. En utilisant ou en accédant à l\'une des informations ou des services de cette application Web, vous reconnaissez avoir lu, compris et accepté les conditions générales énoncées dans les présentes conditions d\'utilisation (l \'«Accord» ou les «CGU»). Les termes «vous», «votre» ou «Utilisateur» désignent, collectivement, la personne et toute entité au nom de laquelle cette personne peut agir.',

  TermsLimitationsTitle: 'Limites',
  TermsLimitationsA: 'L\'utilisateur comprend et accepte que l\'accès de l\'utilisateur à Spacer® est non exclusif, non sous-licenciable, non transférable, librement révocable et peut être suspendu ou résilié à tout moment pour quelque raison que ce soit par CBRE.',
  TermsLimitationsB: 'L\'Utilisateur comprend et accepte en outre que l\'octroi de l\'accès à Spacer® ne confère à l\'Utilisateur aucun droit de propriété sur Spacer® ou toute documentation connexe (y compris dans les nouvelles versions, modifications ou améliorations), qui seront et resteront exclusivement la propriété de CBRE, y compris, sans s\'y limiter, les droits d\'auteur y afférents, les brevets associés, les brevets de conception, les marques, l\'habillage commercial, les secrets commerciaux et autres droits de propriété découlant de la loi applicable ou des conventions internationales (collectivement, les «droits de propriété»). Voir la section 2 (Propriété) ci-dessous. Par la présente, CBRE se réserve expressément tous les droits sur Spacer® qui ne sont pas expressément accordés à l\'utilisateur en vertu des présentes.',
  TermsLimitationsC: 'Sauf disposition contraire des présentes ou autorisation expresse par CBRE à l\'utilisateur par écrit, l\'utilisateur ne doit pas: (i) modifier, traduire, rétroconcevoir, décompiler, désassembler, tenter de reconstruire, identifier ou découvrir tout code source, idées sous-jacentes, utilisateur sous-jacent, interfaces utilisateur ou algorithmes, ou créer des œuvres dérivées ou copier Spacer®; (ii) supprimer, modifier ou reproduire tout avis de droit d\'auteur ou de marque ou tout autre droit de propriété contenus dans Spacer®; (iii) céder, sous-licencier ou autrement transférer son accès, son utilisation ou d\'autres droits ou déléguer ses obligations en vertu du présent Accord; ou (iv) sans limiter ce qui précède, utiliser Spacer® dans le but de créer un produit ou un service concurrent, ou à toute autre fin que les fins commerciales légitimes liées aux activités de l\'utilisateur.',
  TermsLimitationsD: 'L\'Utilisateur s\'engage à informer rapidement CBRE par écrit si toute utilisation non autorisée de Spacer®, ou toute violation éventuelle des Droits de Propriété, lui est signalée.',
  TermsLimitationsE: 'À aucun moment pendant ou après la durée du présent Accord, l\'Utilisateur ne doit demander un enregistrement de tout droit d\'auteur, marque de commerce ou autre désignation qui affecterait la propriété des Droits de Propriété ni déposer aucun document auprès d\'une autorité gouvernementale pour entreprendre une action qui affecterait les droits de propriété, y compris la propriété par CBRE de Spacer®, de la marque Spacer® et d\'autres composants d\'application. Spacer® est une marque commerciale et un service de CBRE.',
  TermsLimitationsF: 'L\'Utilisateur déclare et accepte que l\'Utilisateur a le droit de fournir tous les documents publiés ou les informations utilisées en relation avec Spacer®, et accorde par la présente à CBRE un droit et une licence non exclusifs, libres de droits, mondiaux, irrévocables, perpétuels et une licence d\'utilisation, de reproduction, modification, distribution, création des œuvres dérivées et affiche ces documents et informations à la discrétion de CBRE. L\'Utilisateur reconnaît que l\'Utilisateur est responsable de tout matériel et information soumis par l\'Utilisateur, y compris sa légalité, fiabilité, pertinence, originalité et copyright. Les licences accordées par l\'utilisateur à CBRE en vertu des présentes survivront à toute résiliation de l\'utilisation de Spacer® par l\'utilisateur. Tout matériel et information soumis par l\'utilisateur sera considéré comme non confidentiel et non exclusif, traité comme tel par CBRE, et pourra être utilisé par CBRE conformément au présent accord sans préavis à l\'utilisateur et sans aucune responsabilité envers CBRE.',

  TermsOwnershipTitle: 'Propriété',
  TermsOwnershipText: 'Spacer®, son langage de programmation, son logiciel et sa documentation et tout autre matériel ou contenu mis à disposition sur Spacer® ou toutes améliorations, modifications, travaux dérivés, améliorations ou changements apportés à Spacer® et toutes leurs copies sont la propriété de CBRE et leur titre reste uniquement propriété de CBRE en toutes circonstances, y compris, sans s\'y limiter, tous les droits sur les brevets, droits d\'auteur, marques et secrets commerciaux inclus dans Spacer® et les améliorations, modifications, œuvres dérivées et changements correspondants. L\'utilisateur comprend que ce qui précède inclut des secrets commerciaux et des informations confidentielles et exclusives à CBRE et s\'engage à prendre toutes les mesures nécessaires pour protéger la confidentialité de ces informations. L\'utilisateur ne doit pas vendre, transférer, publier, divulguer, afficher ou autrement rendre disponible Spacer® ou créer ou rendre disponible des améliorations, des modifications, des travaux dérivés, ou des changements à ceux-ci ou des copies de ceux-ci à des tiers. La résiliation du présent accord s\'ajoute et ne remplace pas les recours dont CBRE dispose à la fois en droit et en équité. Les obligations ci-après survivront à la résiliation du présent accord.',

  TermsTerminationTitle: 'Résiliation ; Effet de la résiliation ; Recours.',
  TermsTerminationA: 'Sans se limiter à d\'autre recours, CBRE peut modifier, limiter, suspendre, interrompre ou résilier le présent Accord et / ou l\'utilisation par l\'utilisateur de tout ou partie de Spacer®, avec effet immédiat, automatiquement, avec ou sans préavis et sans recours aux tribunaux, pour quelque raison que ce soit ou sans raison. L\'Utilisateur accepte que CBRE n\'a aucune obligation de fournir Spacer® et que CBRE ne sera pas responsable envers l\'Utilisateur ou envers toute autre partie de toute limitation, suspension, interruption, résiliation ou modification de Spacer®. ',
  TermsTerminationB: 'L\'utilisateur reconnaît que les obligations contractées aux termes de CBRE en vertu de la présente sont de nature unique et irremplaçable, dont la perte causera un préjudice irréparable à CBRE et qui ne peut pas être remplacée par des dommages-intérêts pécuniaires seulement, de sorte que CBRE aura droit à une injonction ou autre réparation équitable (sans les obligations de toute caution ou caution) en cas de violation ou de violation anticipée par l\'Utilisateur. L\'utilisateur renonce irrévocablement à tout droit de demander une injonction ou autre réparation équitable. ',

  TermsDisclaimerTitle: 'Clauses de non-responsabilité et limitation de responsabilité.',
  TermsDisclaimerA: 'Spacer® est fourni à l\'utilisateur «TEL QUEL» et «TEL QUE DISPONIBLE» sans aucune garantie. CBRE DÉCLINE TOUTES LES GARANTIES, EXPRESSES OU IMPLICITES, Y COMPRIS, MAIS SANS S\'Y LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ, D\'ADÉQUATION À UN USAGE PARTICULIER ET DE NON-CONTREFAÇON. CBRE NE GARANTIT EN AUCUNE MANIÈRE QUE Spacer® RÉPONDRA AUX EXIGENCES DE L\'UTILISATEUR OU À TOUTE NORME PARTICULIÈRE. CERTAINS ÉTATS N\'AUTORISENT PAS L\'EXCLUSION DES GARANTIES IMPLICITES, DONC L\'EXCLUSION CI-DESSUS PEUT NE PAS S\'APPLIQUER À L\'UTILISATEUR. Dans ce cas, la responsabilité de CBRE sera limitée dans toute la mesure possible en vertu de la loi applicable. AUCUNE INFORMATION OU CONSEIL ORAL OU ÉCRIT DONNÉ PAR CBRE, SES AGENTS OU SES EMPLOYÉS, NE CRÉERA UNE GARANTIE DE QUELQUE MANIÈRE QUE CE SOIT. AUCUNE GARANTIE N\'EST DONNÉE QUE Spacer® OU TOUTE INFORMATION FOURNIE À L\'UTILISATEUR SONT SANS ERREUR OU QUE L\'UTILISATION DE Spacer® SERA ININTERROMPUE, À JOUR, COMPLÈTE OU EXEMPT DE VIRUS OU D\'AUTRES COMPOSANTS NOCIFS, ET AUCUNE GARANTIE N\'EST DONNÉE QUE TOUT CE QUI PRÉCÈDE SERA CORRIGÉ. CBRE NE GARANTIT NI NE FAIT AUCUNE REPRÉSENTATION CONCERNANT L\'UTILISATION OU LES RÉSULTATS DE L\'UTILISATION DE Spacer®. CBRE NE SAURAIT ÊTRE TENU RESPONSABLE DES DOMMAGES INDIRECTS, SPÉCIAUX, ACCESSOIRES, CONSÉCUTIFS, PUNITIFS OU EXEMPLAIRES (Y COMPRIS SANS LIMITATION LES DOMMAGES LIÉS À LA PERTE D\'AFFAIRES, LA PERTE DE PROFITS, LA PERTE DE RECETTES, LES ÉCONOMIES PERDUES, L\'INTERRUPTION INFORMATIQUE, LA BONNE VOLONTÉ, L\'UTILISATION, LES DONNÉES OU AUTRES PERTES INCORPORELLES), QU\'ELLES SOIENT BASÉES SUR LA VIOLATION DU CONTRAT, LE TORT (Y COMPRIS LA NÉGLIGENCE), LA RESPONSABILITÉ DU PRODUIT OU AUTREMENT, MÊME SI CBRE OU SES REPRÉSENTANTS ONT ÉTÉ AVISÉS DE LA POSSIBILITÉ DE TELS DOMMAGES ET MÊME SI UN RECOURS DÉFINI PAR LES PRÉSENTES ECHOUE.',
  TermsDisclaimerB: 'LA RESPONSABILITÉ TOTALE DE CBRE POUR LES DOMMAGES POUR QUELQUE CAUSE QUE CE SOIT N\'EXCÉDERA PAS LES FRAIS PAYÉS PAR L\'UTILISATEUR POUR Spacer® PENDANT LES DOUZE (12) MOIS AVANT L\'ÉVÉNEMENT CENSE AVOIR CAUSE LES DOMMAGES. L\'UTILISATEUR CONVIENT QUE TOUTE CAUSE D\'UNE ACTION DÉCOULANT DE OU LIÉE À Spacer® OU AUTREMENT LIÉE AU PRÉSENT CONTRAT DOIT COMMENCER DANS UN (1) AN APRÈS LA CAUSE DES ACTIONS SURVENUES. AUTREMENT, UNE TELLE CAUSE D\'ACTION EST DEFINITIVEMENT INVALIDE. »',
  TermsDisclaimerC: 'CERTAINES JURIDICTIONS PEUVENT NE PAS PERMETTRE LA LIMITATION DE RESPONSABILITÉ OU LES EXCLUSIONS DE RESPONSABILITÉ ÉNONCÉES CI-DESSUS, DONC LA LIMITATION OU L\'EXCLUSION CI-DESSUS PEUT NE PAS S\'APPLIQUER À L\'UTILISATEUR. Dans un tel cas, la responsabilité de CBRE sera limitée dans toute la mesure possible en vertu de la loi applicable. La renonciation à la garantie, les recours exclusifs et la responsabilité limitée énoncés ci-dessus sont des éléments fondamentaux de la base de cet accord entre CBRE et l\'UTILISATEUR. CBRE ne serait pas en mesure de fournir Spacer® sur une base économique et n\'aurait pas conclu cet accord sans de telles limitations. Ces limitations survivront à toute résiliation du présent accord.',

  TermsIndemnificationTitle: 'Indemnisation de l\'utilisateur.',
  TermsIndemnificationText: 'Au choix et à la demande de CBRE, l\'Utilisateur doit, à ses frais, indemniser et dégager CBRE et ses filiales, sociétés affiliées, dirigeants, employés, agents, co-marques, associations et autres partenaires de toute responsabilité contre et contre toute perte, coût, dommage, responsabilités, règlements et dépenses (y compris, sans s\'y limiter, les frais et honoraires d\'avocat), découlant de ou liés à toute réclamation, action ou allégation de tiers liées ou résultant de: (a) toute transaction ou litige entre l\'Utilisateur et tout tiers; (b) des faits ou des faits allégués qui constitueraient une violation des déclarations, garanties ou engagements de l’Utilisateur en vertu du présent Contrat ou de toute loi, règle ou réglementation applicable, référencée ou non dans les présentes; (c) tout matériel, information ou contenu utilisateur soumis dans ou sur Spacer®; (d) violation par l’utilisateur de tous droits de tiers, (e) utilisation ou mauvaise utilisation de Spacer® par l’utilisateur; ou (f) la négligence ou la faute intentionnelle de l\'utilisateur. CBRE a le droit de diriger et de contrôler exclusivement sa défense et d\'engager un avocat de son choix, aux frais de l\'utilisateur.',

  TermsGeneralTitle: 'Général',
  TermsGeneralA: '<0> Intégralité de l\'accord / pas de renonciation involontaire. </0> Cet accord constitue l\'intégralité de l\'accord entre CBRE et l\'utilisateur en ce qui concerne Spacer® et tout l\'objet du présent accord, et remplace tous les accords antérieurs entre CBRE et l\'utilisateur liés à Spacer®. L\'incapacité de CBRE à appliquer une disposition du présent accord ne sera pas interprétée comme une renonciation à une disposition ou à un droit.',
  TermsGeneralB: '<0> Notifications. </0> Les notifications doivent être écrites et peuvent être faites par e-mail ou en affichant ces notifications sur Spacer® ou sur www.cbre.com/spacer. Vous nous enverrez des notifications par e-mail à spacersupport@cbre.com, avec une copie envoyée par courrier américain certifié à CBRE au 400 S. Hope Street, 25th Floor, Los Angeles, CA 90071, à l\'attention du General Counsel’s Office. Les avis seront réputés donnés un (1) jour ouvrable après leur envoi par e-mail (sauf si l\'expéditeur reçoit une réponse indiquant que le message n\'a pas été remis) ou trois jours ouvrables après avoir été publiés sur le site Web de CBRE (dans le cas de CBRE), que cela ait été reçu ou non. ',
  TermsGeneralC: '<0> Loi applicable. </0> Le présent Accord sera régi et interprété conformément aux lois de l\'État de Californie, sans égard aux conflits de lois. CBRE et l\'Utilisateur se soumettent par la présente exclusivement aux sites de l\'État de Californie. La Convention des Nations Unies sur les contrats de vente internationale de marchandises (1980) est par les présentes exclue dans son intégralité de l\'application du présent accord. L\'utilisateur accepte par les présentes que Spacer® sera réputé être basé uniquement en Californie et un service passif qui ne donne pas lieu à une juridiction personnelle sur CBRE, spécifique ou générale, dans des juridictions autres que l\'État de Californie.',
  TermsGeneralD: '<0> Règlement des différends. </0> Tout différend, réclamation ou controverse découlant du présent accord ou s\'y rapportant, ou de sa violation, de son application, de son interprétation ou de sa validité, y compris la détermination de la portée ou de l\'applicabilité de l\'arbitrage à cet égard sera déterminé par arbitrage. L\'arbitrage sera administré par le Service d\'arbitrage et de médiation judiciaire (JAMS), et l\'arbitrage sera mené avec un seul arbitre. En outre, l\'Utilisateur accepte que ni l\'Utilisateur ni CBRE ne joindra une réclamation à la réclamation d\'une autre personne ou entité en arbitrage; qu\'aucune réclamation ne sera résolue à l\'échelle de la classe; et que ni l\'Utilisateur ni CBRE ne fera valoir aucune réclamation à titre représentatif au nom de quelqu\'un d\'autre. Nonobstant ce qui précède, l\'utilisateur accepte que CBRE soit autorisé à demander des recours en injonction (ou un type équivalent de réparation judiciaire urgente) dans n\'importe quelle juridiction. Si l\'utilisateur dépose une plainte d\'arbitrage contre CBRE, CBRE accepte de payer toute partie des frais de dépôt initial qui dépasse 250 $; après les frais de dépôt initiaux, les parties partageront également les frais d\'arbitrage. L\'Utilisateur s\'engage à ne pas entreprendre ou poursuivre toute action contre CBRE autrement qu\'en déposant une plainte d\'arbitrage conformément au présent paragraphe. IMPORTANT, EN ACCEPTANT D\'ÊTRE LIÉ PAR LE PRÉSENT CONTRAT, L\'UTILISATEUR RENONCE À TOUT DROIT UTILISATEUR DOIT RECHERCHER UN RECOURS EN JUSTICE ET TOUT DROIT UTILISATEUR DOIT ÊTRE JUGÉ PAR LE JURY.',
  TermsGeneralE: '<0> Divisibilité. </0> Dans le cas où une partie du présent accord est jugée inapplicable, la partie inapplicable ne sera modifiée que dans la mesure nécessaire pour la rendre exécutoire, et le reste des dispositions restera en vigueur et effet.',
  TermsGeneralF: '<0> Cession. </0> Le présent Contrat ne peut être cédé par l\'Utilisateur sans l\'accord écrit préalable de CBRE. Toute tentative de cession sans le consentement de CBRE sera nulle. CBRE se réserve le droit de céder le présent accord à tout tiers. Le présent accord et les droits et obligations de chacun d\'entre nous en vertu de celui-ci lieront nos successeurs et ayants droit respectifs.',
  TermsGeneralG: '<0> Modification. </0> CBRE peut modifier les termes et conditions contenus dans le présent accord, à tout moment et à la seule et entière discrétion de CBRE, en affichant un avis de changement ou un nouvel accord sur le site de Spacer® à l\'adresse l\'url www.cbre.com/spacer ou en fournissant une alerte ou une notification similaire dans Spacer®. Si une modification est inacceptable pour l\'utilisateur, le seul recours de \'utilisateur est de mettre fin à l\'utilisation de Spacer® par l\'utilisateur.',
  TermsGeneralH: '<0> Conflits. </0> En cas de conflit entre les termes du présent Accord et tout accord de licence entièrement exécuté entre l\'Utilisateur et CBRE en ce qui concerne Spacer®, les termes de cet accord de licence prévaudront en ce qui concerne ces termes contradictoires.'

};
