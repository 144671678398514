const React                  = require('react');
const PropTypes              = require('prop-types');
const spacerFreePropTypes    = require('../../../spacerFreePropTypes');
const { withTranslation }     = require('react-i18next');

const PreDefinedSpaceUnitRow = require('../../../containers/results/program/PreDefinedSpaceUnitRow.jsx');
const CustomAmenityUnitRow   = require('../../../containers/results/program/CustomAmenityUnitRow.jsx');

function SpaceUnits(props) {
  const unitRows = [];

  props.units.forEach((programUnit) => {
    if (programUnit.isCustomAmenity) {
      unitRows.push(
        <CustomAmenityUnitRow
          key={programUnit.id}
          unit={programUnit}
          t={props.t}
        />
      );
    } else if (programUnit.quantity > 0) {
      unitRows.push(
        <PreDefinedSpaceUnitRow
          key={programUnit.spaceUnitId}
          unit={programUnit}
          spaceUnitCategory={props.spaceUnitCategory}
          t={props.t}
        />
      );
    }
  });


  return (
    <div className="is-sans-medium">
      {unitRows}
    </div>
  );
}

SpaceUnits.propTypes = {
  units: PropTypes.arrayOf(PropTypes.oneOfType([
    spacerFreePropTypes.spaceUnitShape,
    spacerFreePropTypes.customAmenityUnitShape
  ])).isRequired,
  spaceUnitCategory: PropTypes.string,
  t: PropTypes.func.isRequired
};

SpaceUnits.defaultProps = {
  spaceUnitCategory: ''
};

module.exports = withTranslation('resultsPage')(SpaceUnits);
