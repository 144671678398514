const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const SPACE_UNIT_CATEGORY    = require('wp-constants').shared.spaceUnit.CATEGORY;
const modalActions           = require('../../../../redux/actions/modal');
const spaceDataActions       = require('../../../../redux/actions/spaceData');
const spaceUnitMap           = require('wp-data').spaceUnitMap;
const programDataRetriever   = require('../../../../util/programDataRetriever');

const SpaceUnitRow    = require('../../../results/program/SpaceCategoryBreakdown/SpaceUnitRow.jsx');

// Map state to props
function mapStateToProps(state, ownProps) {
  const spaceUnit = spaceUnitMap[ownProps.unit.spaceUnitId];
  const isAmenitySpace = spaceUnit.category === SPACE_UNIT_CATEGORY.AMENITY;
  const area = isAmenitySpace ? programDataRetriever.getNSF(ownProps.unit) : spaceUnit.sf;
  const quantity = programDataRetriever.getQuantity(ownProps.unit);
  
  return {
    sfType: state.spaceData.sfType,
    measurementSystem: state.spaceData.measurementSystem,

    spaceUnitId: ownProps.unit.spaceUnitId,
    name: ownProps.t(spaceUnit.displayNameToken),
    area,
    quantity,
    totalArea: programDataRetriever.getNSF(ownProps.unit),
    // Pass the quantity as "count", and i18next can pluralize! See i18n.js for more explanation.
    displayUnit: ownProps.t(spaceUnit.displayUnit, { count: quantity }),
    description: ownProps.t(spaceUnit.descriptionToken),

    areaIsEditable: isAmenitySpace,
    quantityIsEditable: !isAmenitySpace,

    delta: programDataRetriever.getNSFDelta(ownProps.unit),
    officeLocation: state.userData.officeLocation
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    onQuantityUpdate: bindActionCreators(spaceDataActions.updateSpaceUnitQuantity, dispatch),
    onAreaUpdate: bindActionCreators(spaceDataActions.updateSpaceUnitAmenitySF, dispatch),
    deleteFunction: bindActionCreators(spaceDataActions.deleteSpaceUnit, dispatch),
    openModal: bindActionCreators(modalActions.openModal, dispatch),
    closeModal: bindActionCreators(modalActions.closeModal, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(SpaceUnitRow);

