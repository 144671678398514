module.exports = {
  blogHomeTitle: 'Aperçu de la disposition des bureaux',

  // blog tags
  blogTagAllArticles: 'Tous les articles',
  blogTagSizeAndFit: 'Dimensionner et adapter',
  blogTagOfficeCulture: 'Culture d\'entreprise',
  blogTagSpaceAndDesign: 'Espace & Design',
  blogTagWorkplaceTrends: 'Tendances pour les espaces de travail'
};
