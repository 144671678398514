module.exports = {
  referralStep: 'Step {{step}} of 4',
  forgotReferralLink: 'Forgot your referral link?',
  howReferralLinksWorkTitle: 'How Referral Links Work',
  enterYourInformation: 'Enter your Information',
  confirmYourInformation: 'Confirm your Information',
  referralLinkStep1: 'To create a referral link we’ll need:',
  referralLinkReq1: '- Your name',
  referralLinkReq2: '- Your CBRE-issued email address',
  referralLinkStep2: 'After a prospect completes the quiz using your referral link, we’ll send a copy of their results to your inbox.',
  next: 'Next',
  firstName: 'First Name',
  lastName: 'Last Name',
  emptyNameError: 'Please enter a name',
  email: 'Your CBRE email address',
  emptyEmailError: 'Please enter a CBRE email',
  emailTakenError: 'That email address is already linked to {{takenLink}}',
  emailExample: 'ex: jane.smith@cbre.com',
  editReferralInfo: 'Edit name and email information entered',
  noLaterChanges: 'You won’t be able to change this later.',
  somethingWrong: 'Oops, something went wrong on our end. Please try again.',
  contentLoading: 'Content is Loading',
  linkReady: 'Your Link is Ready',
  storeLinkInstructions: 'Store this link in a safe place, you’ll want to use it whenever you share Spacer with clients. We’ve also emailed a copy of this link to the address that you provided.',
  yourLink: 'Your referral link',
  copyLink: 'Copy referral link',
  copiedLink: 'Copied to clipboard',
  linkNeverExpires: 'Reminder: this link will never expire.',
  linkFound: 'We Found your Link',
  emailNotAssociated: 'We couldn’t find a referral link associated with that email address.',
  createLink: 'Create one?'
};
