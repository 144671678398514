function decimalTo100PercentageRatioHelper(decimal, returnArray = false) {
  const percentage = Math.round(decimal * 100, 10);
  const oppositePercentage = 100 - percentage;
  if (returnArray) {
    if (percentage) return [`${percentage}%`, `${oppositePercentage}%`];
    return ['—%', '—%'];
  }
  if (percentage) return `${percentage}%:${oppositePercentage}%`;
  return '—%:—%';
}

let CURRENT_LANGUAGE = 'en';

module.exports = {

  // adapted from http://jonisalonen.com/2012/converting-decimal-numbers-to-ratios/
  decimalToRatio(decimal) {
    // round decimal to nearest 100th for prettier ratios
    decimal = Math.round(decimal * 100, 10) / 100;

    const tolerance = 1.0E-6;
    let h1 = 1;
    let h2 = 0;
    let k1 = 0;
    let k2 = 1;
    let b = decimal;
    let a;
    let aux;
    do {
      a = Math.floor(b);
      aux = h1;
      h1 = (a * h1) + h2;
      h2 = aux;
      aux = k1;
      k1 = (a * k1) + k2;
      k2 = aux;
      b = 1 / (b - a);
    } while (Math.abs(decimal - (h1 / k1)) > decimal * tolerance);

    return `${h1}:${k1}`;
  },

  decimalTo100PercentageRatioStringFormat(decimal) {
    return decimalTo100PercentageRatioHelper(decimal);
  },

  decimalTo100PercentageRatioArrayFormat(decimal) {
    return decimalTo100PercentageRatioHelper(decimal, true);
  },

  decimalToPercentage(decimal) {
    return `${Math.round(decimal * 100, 10)}%`;
  },

  updateCurrentLanguage(lang) {
    if (lang) CURRENT_LANGUAGE = lang;
  },

  numberWithLocaleFormat(x) {
    return x.toLocaleString(CURRENT_LANGUAGE);
  }
};
