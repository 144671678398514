const React              = require('react');
const PropTypes          = require('prop-types');
const { withTranslation } = require('react-i18next');
const Clipboard          = require('react-clipboard.js').default;

function GetReferralLink(props) {
  const [copied, setCopied] = React.useState(false);
  const clipboardWrapperRef = React.useRef(null);

  // manage focus - move to clipboard button on load
  React.useEffect(() => clipboardWrapperRef.current.getElementsByTagName('button')[0].focus(), []);

  const copiedLink = (
    <div className="flex bold-input">
      <div className="copied-link-icon has-margin-right-8" />
      {props.t('copiedLink')}
    </div>
  );

  const clipboardText = copied ? copiedLink : props.t('copyLink');
  const title = props.getLinkFirstTime ? props.t('linkReady') : props.t('linkFound');

  return (
    <React.Fragment>
      <h1 aria-live="polite" className="title has-text-primary flex flex-justify-center">{title}</h1>
      <div className={`flex flex-col referral-instructions-and-form get-referral-link ${props.getLinkFirstTime ? '' : 'retrieve-link-form'}`}>
        {
          props.getLinkFirstTime &&
          <div className="has-margin-bottom-3">{props.t('storeLinkInstructions')}</div>
        }
        <input
          type="text"
          readOnly
          value={props.link}
          aria-label={props.t('yourLink')}
        />
      </div>
      <span aria-live="polite" ref={clipboardWrapperRef}>
        <Clipboard
          className="button is-primary-filled force-flex submitBtn clipboard-copy-btn"
          data-clipboard-text={props.link}
          onClick={
            () => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }
          }
        >
          {clipboardText}
        </Clipboard>
      </span>
      {
        props.getLinkFirstTime &&
        <div className="link-never-expires">{props.t('linkNeverExpires')}</div>
      }
    </React.Fragment>
  );
}

GetReferralLink.propTypes = {
  t: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  getLinkFirstTime: PropTypes.bool
};

GetReferralLink.defaultProps = {
  getLinkFirstTime: false
};

module.exports = withTranslation('referrals')(GetReferralLink);
