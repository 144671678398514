const React              = require('react');
const PropTypes          = require('prop-types');
const ExternalLink       = require('../links/ExternalLink.jsx');
const { withTranslation } = require('react-i18next');
const { Trans }          = require('react-i18next');

function AcceptTermsText(props) {
  return (
    <span>
      {/* See Trans usage note in assets/js/spacerFree. */ }
      <Trans i18nKey="resultsPage:acceptTermsText">
        0<ExternalLink href="/terms-spacer-by-cbre">1</ExternalLink>2<ExternalLink href={props.t('privacyPolicyLink')}>3</ExternalLink>.
      </Trans>
    </span>
  );
}

AcceptTermsText.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(AcceptTermsText);
