const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');

// const globalActions          = require('../../../redux/actions/global');
import globalActions from '../../../redux/actions/global';
const profileQuizActions     = require('../../../redux/actions/profileQuiz');
const spaceDataActions       = require('../../../redux/actions/spaceData');
const userDataActions        = require('../../../redux/actions/userData');

const Quiz                   = require('../../quiz/Quiz.jsx');

// Map state to props
function mapStateToProps(state) {
  return {
    // for determining previous answers
    userQuizAnswers: state.profileQuiz.userQuizAnswers,
    userData: state.userData,
    assumptions: state.spaceData.assumptions
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    // answer question functions
    answerProfileQuestion: bindActionCreators(profileQuizActions.answerProfileQuestion, dispatch),
    addKeyAssumption: bindActionCreators(spaceDataActions.addKeyAssumption, dispatch),
    addUserData: bindActionCreators(userDataActions.addUserData, dispatch),

    // calculate results functions
    calculateProfileScores: bindActionCreators(profileQuizActions.calculateProfileScores, dispatch),
    setProfileAndCalculateProgram: bindActionCreators(spaceDataActions.setProfileAndCalculateProgram, dispatch),

    sendHeapEvent: bindActionCreators(globalActions.sendHeapEvent, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(Quiz);

