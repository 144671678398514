module.exports = {

  // Results
  resultsWarningMessage: 'Etes-vous sûr que vous souhaitez quitter cette page? Toute donnée non sauvegardée sera perdue.',

  // ResultsHeader
  resultsHeaderExportPDFButtonText: 'Export PDF',
  resultsHeaderSendButtonText: 'Envoyer les résultats',
  resultsHeaderClipboardBoxDescription: 'Tout lien permet de voir et éditer les résultats',
  resultsHeaderContactButtonText: 'Prenez contact avec un consultant',

  // EmailWallSection
  emailWallTitle: 'Encore plus pour vous',
  emailWallSubtitle: 'Veuillez saisir votre nom et email ci-dessous pour consulter une préconisation complète de vos locaux et adapter les résultats',
  emailWallFirstNamePlaceholder: 'Prénom',
  emailWallLastNamePlaceholder: 'Nom',
  emailWallEmailPlaceholder: 'Votre email',
  emailWallCBREEmailPlaceholder: 'Votre email professionnel CBRE (optionnel)',
  // emailWallLeasingProfessionalPlaceholder: no translation yet,
  emailWallConnectMeText: 'Merci de me mettre en contact avec un consultant dédié. Promis, sans newsletter, mail ou spam!',
  emailWallContactNoticeText: 'L\'équipe Spacer pourra vous contacter pour profiter encore plus de votre projet. Promis, pas de spam!',
  emailWallResultsButtonText: 'Voir les résultats complets',
  emailWallImgAltText: 'Prévisualisation des résultats directement sur la page d\'email de Spacer by CBRE.',
  emailWallFirstNameInvalid: 'Merci de saisir votre prénom',
  emailWallLastNameInvalid: 'Merci de saisir votre nom',
  emailWallEmailInvalid: 'Merci d\'entrer une adresse email valide',

  // CopyToClipboardBox
  clipboardBoxCopyText: 'Copier',
  clipboardBoxCopiedText: 'Copié',

  // FormModal
  formModalFindSpaceHeader: 'Lancer la recherche de bureaux',
  formModalFindSpaceBody: 'Spacer est un outil rapide et simple pour une étude d\'espaces de travail, mais si vous avez un besoin plus complexe, CBRE Design & Projcet peut vous aider. Remplissez ce formulaire, et un consultant spécialisé vous rappellera dans les 48 heures pour convneir d\'un rendez-vous.',
  formModalFindSpaceSuccessMessage: 'Votre demande a été envoyée.',

  // SuccessModal
  successModalTitle: 'Terminé!',

  // ContactForm
  contactFormFirstNameLabel: 'Prénom',
  contactFormLastNameLabel: 'Nom',
  contactFormEmailLabel: 'Email professionnel',
  contactFormPhoneLabel: 'Numéro de téléphone',
  contactFormMessageLabel: 'Dites-nous plus sur votre recherche',
  contactFormSubmitText: 'Envoyer',

  // AcceptTermsText
  acceptTermsText: 'J\'accepte les <1>Conditions Générales d\'Utilisation</1>. Pour de plus amples informations sur la façon dont vos données sont utilisées, merci de vous référer à nos <3>Mentions Légales</3>.',
  privacyPolicyLink: 'https://www.cbre.fr/fr-fr/a-propos-de-cbre/mention-legales#policy',

  // Form
  formErrorText: 'Oups, nous avons rencontré un problème. Merci de réessayer!',
  formEmailInvalid: 'Merci de saisir une adresse mail valide.',

  // FormInput
  formInputOptionalText: '(optionnel)',

  // LegalForm
  legalFormCheckboxText: 'CBRE peut me contacter avec des informations et offres en relation avec mon usage de Spacer.',
  legalFormContactWarningText: 'Léquipe Spacer pourra vous contacter pour vous aider à obtenir le meilleur de votre projet. Pas de spam, promis!',

  // NextStepsSection
  nextStepsSectionSubtitle: 'Prêt à passer à l\'étape suivante?',
  nextStepsSectionTitle: 'Trouver le bureau idéal.',
  nextStepsSectionButtonText: 'Entrez en contact avec un consultant',
  nextStepsSectionImgAlt: 'Illustration de personnes discutant sur la page des résultats de Spacer by CBRE',

  // Profile
  resultsProfileSummaryTitle: 'Vos locaux —',
  resultsProfileTooltipText: 'Chaque profil est inspiré d\'environnements et d\'organisations réelles, les détails sont le résultats des bonnes pratiques et expertises de CBRE Design & Project.',

  // Program
  programSaveConfirmationBoxText: 'C\'est parti. Nous vous avons envoyé un lien vers vos résultats. Profitez-en dès maintenant!',
  programExamplePlansTitle: 'Exemples de plans',
  programExamplePlansSubtitle: 'Jouez avec des plans interactifs adaptés à votre demande. Vous pouvez même les manipuler en 3D!',
  programTooMuchTinkerWarning: 'Attention ! Il semble que vous êtes en dehors des marges recommandées pour vous. Envisagez de <1>refaire le Quiz</1>, or contacter notre support via <3>{{email}}</3>.',
  programDisclaimer: 'Spacer by CBRE fonctionne "en l\'état" et "tel que disponible", sans garantie de quelque nature que ce soit, ni expresse ni tacite. Tous les résultats sont fournis à titre illustratif et ne consittuent pas une recommandation de locaux ou de bail fermes.   Des recommandations fremes peuvent varier de façon importante, et dépendent de beaucoup de facteurs que Spacer ne peut prendre en compte. En utilisant Spacer ou tout rapport généré par l\'utilisation de Spacer, vous acceptez les Conditions de Service et Mentions Légales visibles sur les adresses internet https://workplace.cbre.com/termsOfService et https://www.cbre.fr/fr-fr/a-propos-de-cbre/mention-legales#policy. © 2018 CBRE, Inc. All rights reserved.',
  programMetricsTitle: 'Métriques du programme',
  programMetricsSubtitle: 'Ces chiffres résument votre environnement de travail',
  programDetailsSectionTitle: 'Customisez votre programme',
  programDetailsSectionSubtitle: 'Ajustez les variables ci-dessous pour affiner votre besoin.',
  programRevertButtonText: 'Reprendre',
  programSaveButtonText: 'Sauvegarder',
  programSavedButtonText: 'Enregistré',

  // ProgramAssumptions
  programAssumptionsRecommendedOfficeSizeText: 'Taille de bureau préconisée',
  programAssumptionsProfileText: 'Profil',
  programAssumptionsHeadcountText: 'Nombre de collaborateurs',
  programAssumptionsHeadcountFollowingText: 'Collaborateurs',
  programAssumptionsCollabText: 'Cooperation',
  programAssumptionsCirculationText: 'Densité de personnes',
  programAssumptionsAvgDaysText: 'Nombre de jours moyen dans les locaux',
  programAssumptionsAvgDaysFollowingText: 'par semaine',
  programAssumptionsAvgDaysPerWeekText: 'Nombre de jours moyen par semaine dans les locaux',

  // ProgramHeroForPrint
  printNIAText: 'Surface nette',
  printUSFText: 'Mètres carrés utiles',
  printRSFText: 'Mètres carrés loués',
  printDescText: 'Basé sur vos données, avec un {{lossFactorPercentage}}% {{factorType}} à {{city}}, nous préconisons un total de-',
  printFitFactorText: 'facteur d\'ajustement',
  printLossFactorText: 'coefficient de perte',

  // BarChart
  barChartIndivSubtitle: 'Espace Individuel',
  barChartGroupSubtitle: 'Espace Collectif',
  barChartAmenitySubtitle: 'Espace d\'agrément',

  // TabbedProgramBarChart
  tabbedBarChartMeSubtitle: 'Individuel',
  tabbedBarChartGroupSubtitle: 'Groupe',
  tabbedBarChartAmenitySubtitle: 'Equipements collectifs',

  // SpaceCategoryBreakdown
  spaceCatBreakdownSubtitle: 'Selectionnez un type d\'espace à adapter',

  // SpaceCategoryBreakdownMobile
  spaceCatBreakdownMobileMeTitle: 'Espace individuel',
  spaceCatBreakdownMobileWeTitle: 'Espace Collectif',
  spaceCatBreakdownMobileAmenityTitle: 'Espace d\'agrément',
  spaceCatBreakdownMobileMeSubtitle: 'pour un travail en solo',
  spaceCatBreakdownMobileWeSubtitle: 'pour un travail en groupe',
  spaceCatBreakdownMobileAmenitySubtitle: 'pour une autre activité',
  spaceCatBreakdownMobileNIATotalTitle: 'Surface nette totale',
  spaceCatBreakdownMobileTotalTitle: 'Total {{sfType}} Mètres {{unitCapitalized}}',
  spaceCatBreakdownMobileCirculationText: 'Circulation',

  // SpaceCategoryDropdown
  spaceCatDropdownMeText: 'Espace Individuel',
  spaceCatDropdownWeText: 'Espace Collectif',
  spaceCatDropdownAmenityText: 'Espace d\'agrément',

  // SidebarInfo
  sidebarInfoMeTitle: 'Individuel',
  sidebarInfoWeTitle: 'Group',
  sidebarInfoAmenityTitle: 'Equipements collectifs',

  // Space Category Descriptions (spaceCategoryDescription.js)
  spaceCatMeDesc: 'Espace pour un travail individuel. Offrant la possibilité de choisir où et comment vos collaborateurs travaillent pour leur amener plus de satisfaction.',
  spaceCatWeDesc: 'Espace de collaboration et travail d\'équipe. Maintenant que nous pouvons travailler n\'importe où, il est important de fournir des espaces différents selon les activités.',
  spaceCatAmenityDesc: 'Espace qui facilite le travail. De tels espaces de confort font de vos bureaux des endroits où vos collaborateurs - actuels et potentiels - apprécient passer du temps.',

  // Suggested Custom Amenities (suggestedCustomAmenityUnitMap.js)
  audioVisualCustom: 'Audiovisuel',
  dataCenterCustom: 'Data center',
  buildingSupportCustom: 'Services techniques',
  foodServiceCustom: 'Service de restauration',
  kitchenCustom: 'Cuisine',
  serveryCustom: 'Comptoirs',
  execDiningCustom: 'Salons de direction',
  grabNGoCustom: 'Vente à emporter',
  juiceCoffeeBarCustom: 'Bar à jus de fruits / Café',
  childCareCustom: 'Garderie',
  fitnessCenterCustom: 'Salle de fitness',
  healthCenterCustom: 'Centre médical',
  mailCenterCustom: 'Service courrier',
  commercialPrintCustom: 'Local d\'impression',
  centralCafeCustom: 'Cafétéria',
  securityCtrlCustom: 'PC Sécurité',
  auditoriumCustom: 'Auditorium',
  dryCleaningLaundryCustom: 'Pressing / Buanderie',
  confCenterCustom: 'Centre de conférence',
  multiPurpTrainingCustom: 'Salle multi-activités',
  informalCollabCustom: 'Collaboration informelle',
  touchdownTeamCustom: 'Point de rassemblement',

  // Suggested Custom Amenity Units (spaceUnit.js)
  seats: 'siège',
  seats_plural: 'sièges',
  rooms: 'salle',
  rooms_plural: 'salles',
  areas: 'surface',
  areas_plural: 'surfaces',
  units: 'unité',
  units_plural: 'unités',

  // Predefined Amenities (spaceUnitMap.js) - Shared Space Units
  focusRoomDefined: 'Salle Focus',
  fileRoomDefined: 'Archives',
  centralReceptionHubDefined: 'Réception',
  smOfficeDefined: 'Petit bureau',
  regOfficeDefined: 'Bureau standard',
  benchDesk5Defined: 'Bureau élargi',
  benchDesk6Defined: 'Très grand bureau',
  meetingRoom4Defined: 'Salle de réunion (4 personnes)',
  meetingRoom6Defined: 'Salle de réunion (6 personnes)',
  meetingRoom8Defined: 'Salle de réunion (8 personnes)',
  meetingRoom12Defined: 'Salle de réunion (12 personnes)',
  meetingRoom15Defined: 'Salle de réunion (15 personnes)',
  semiEnclosedMeetingBooth4Defined: 'Box semi-fermé (4 personnes)',
  openCollabSpace4to6Defined: 'Espace de collaboration ouvert (4-6 personnes)',
  contemplationSpaceDefined: 'Espace de repos',
  mainITEquipRoomDefined: 'Salle informatique principale',
  receptionAreaDefined: 'Salle de réception',
  cafeFoodServiceDefined: 'Café / restauration',
  teamStorageDefined: 'Stockage d\'équipe',
  coatClosetsDefined: 'Penderies',
  genStorageDefined: 'Espace de stockage/ déchargement',
  centralisedCopyPrintMailDefined: 'Salle courrier / impression centrale',
  specialResourceAreaDefined: 'Surface de stockage spéciale',
  personalStorageDefined: 'Casiers individuels',
  storageITSpaceDefined: 'Stockage informatique',
  secondaryServerRoomDefined: 'Salle informatique secondaire / Salle telecom',
  mothersRoomDefined: 'Chambre d\'allaitement',
  buildITSpaceDefined: 'Atelier informatique',
  workerCouncilOffice2Defined: 'Bureau dédié aux représentants du personnel',
  securityCtrlRoomDefined: 'PC Sécurité',
  centralisedMailDefined: 'Salle courrier',
  visitorCoatLuggageRoomDefined: 'Bagagerie',
  wellnessRoomDefined: 'Salle de bien-être',
  internalStairDefined: 'Escalier intérieur',
  commercialPrintRoomDefined: 'Local d\'impression',
  buildingSupportServicesAreaDefined: 'Espace pour services techniques',

  // Predefined Amenities (spaceUnitMap.js) - US_SMALL and US_REGULAR
  officeDefined: 'Bureau',
  lgOfficeDefined: 'Grand bureau',
  smWorkstationDefined: 'Workstation (petite)',
  mdWorkstationDefined: 'Workstation (moyenne)',
  lgWorkstationDefined: 'Workstation (grande)',
  huddleRoomDefined: 'Huddle room',
  dispersedCopyPrintAreaDefined: 'Local de reprographie / impression',
  smMeetingRoomDefined: 'Petite salle de réunion',
  mdMeetingRoomDefined: 'Salle de réunion moyenne',
  lgMeetingRoomDefined: 'Grande salle de réunion',
  xlMeetingRoomDefined: 'Très grande salle de réunion / Boardroom',
  multiPurpTrainingDefined: 'Salle multi-activités',
  informalCollabDefined: 'Espace de collaboration informelle',
  touchdownTeamDefined: 'Point de rassemblement',
  pantryVendingCoffeeAreaDefined: 'Espace café / office / distributeurs',
  workplaceStorageRoomDefined: 'Salle de stockage aménagement',
  employeeCoatAreaDefined: 'Vestiaire collaborateur',
  dispersedMailAreaDefined: 'Espace de tri courrier',
  lockerUnitDefined: 'Casier',
  serverRoomDefined: 'Salle serveur',
  frontDeskAreaDefined: 'Espace accueil',
  centralCafeAreaDefined: 'Cafétéria',
  mailCenterAreaDefined: 'Espace courrier',
  kitchenAreaDefined: 'Espace cuisine',
  drawerLateralFileUnitDefined: 'Classeur 3 tiroirs',
  serveryAreaDefined: 'Comptoir',
  juiceBarCoffeeShopAreaDefined: 'Bar à jus de fruits / Café',
  diningSupportAreaDefined: 'Espace de préparation repas',
  diningAreaDefined: 'Espace repas',
  grabNGoAreaDefined: 'Espace vente à emporter',

  // Predefined Amenities (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDefined: 'Service restauration',

  // Predefined Amenities (spaceUnitMap.js) - APAC_REGULAR
  officeWorkMeetDefined: 'Bureau de passage standard',
  lgOfficeWorkMeetDefined: 'Grand bureau de passage',
  focusDefined: 'Focus',
  bench1pt6Defined: 'Petit bureau',
  bench1pt8Defined: 'Bureau standard',
  bench1pt8LDefined: 'Grand bureau',
  banquetWorkMeetDefined: 'Banquette (2 places)',
  cafeTable4Defined: 'Table café (4 places)',
  kitchenTable8to10Defined: 'Table de repas (8-10 places)',
  standingWhiteboardDefined: 'Tableau blanc',
  huddleRoom3Defined: 'Huddle',
  smMeetingRoom5Defined: 'Petite salle de réunion (5 places)',
  mdMeetingRoom8Defined: 'Salle de réunion moyenne (8 places)',
  lgMeetingRoom20Defined: 'Grande salle de réunion (20 places)',
  xlBoardroom: 'Très grande salle de réunion / Boardroom',
  wellnessRoomsDefined: 'Salles bien-être',
  employeeCoatsGymLockersDefined: 'Vestiaires collaborateurs',
  lockersDefined: 'Casiers',
  dispersedCopyPrintScanAreaDefined: 'Local reprographie / impression',
  hydrationPointDefined: 'Espace boissons',
  centralHighDensityStorageDefined: 'Centre de stockage',
  wellnessMothersRoomDefined: 'Bien-être',
  serverHubRoomsDefined: 'Local serveur informatique / télécommunications',
  buildITStoreRoomDefined: 'Atelier / Stockage informatique',
  simpleReceptionWaitDefined: 'Salon d\'attente',
  centralCafeSocialHubDefined: 'Cafétéria',
  mailCentreDefined: 'Local courrier',
  utilityStoreRoomDefined: 'Stockage technique',
  drawerLateralFileDefined: 'Classeur 3 tiroirs',
  juiceBarCoffeeShopDefined: 'Bar à jus de fruits / Café',
  buildingSupportServicesDefined: 'Services techniques',

  // Space Unit Map Descriptions (spaceUnitMap.js) - Shared Descriptions
  focusRoomDesc: 'Les Focus sont des espaces clos conçus pour une à deux personnes et dédiés à des activités où confidentialité et/ou concentration sont indispensables. L\'isolement acoustique est un prérequis de ces espaces. Ils sont généralement non attribués et peuvent être réservés temporairement selon les besoins.',
  fileRoomDesc: 'C\'est un espace de stockage pour archives papier destiné à un collaborateur ou à une équipe. En raison des progrès de la digitalisation, ces espaces sont en forte diminution. Cependant, cela reste un espace indispensable pour des prérogatives légales ou des projets de longue haleine, particulièrement alors que les bureaux ouverts proposent peu de stockage.',
  centralReceptionHubDesc: 'L\'accueil et la réception participent de la première impression que vos collaborateurs, clients et visiteurs ont de votre société. C\'est un véhicule important de votre marque et de l\'image que vous souhaitez donner. Les personnels d\'accueil doivent être confortablement installés, des fauteuils accueillants doivent être disponibles pour vos visiteurs.',
  officeDesc: 'Les bureaux fermés sont des espaces individuels de travail clos destinés à vos collaborateurs. Ils sont parfaits pour diminuer les distractions et permettre un travail de concentration. Ils sont généralement équipés d\'un bureau, d\'une fauteuil ainsi que de sièges pour les visiteurs. Vous trouverez des volumes de stockages variables selon les bureaux. La taille de ces bureaux peut varier selon les activités qui y sont menées (par exemple des réunions régulières) ou selon le statut des collaborateurs.',
  benchDeskDesc: 'Les bureaux ouverts sont des bureaux ouverts individuels. Ils peuvent être utilisés par des collaborateurs soit temporairement (par exemple dans des espaces non occupés) soit de façon permanente. Ces bureaux sont aussi très adaptés pour des collaborateurs de passage ou des prestataires qui ne sont pas toujours présents. Collaboration et ouverture de l\'espace sont parmi les avantages de ces bureaux. Un inconvénient peut être le bruit induit par cette disposition, c\'est pourquoi il est recommandé de mixer ces bureaux avec des Focus ou Huddle. Le stockage dans ces bureaux est réduit et limité souvent aux abords des plateaux ou étages.',

  meetingRoomDesc: 'Ces salles de réunions sont des espaces clos prévus pour accueillir des réunions limitées entre 2 et 4 personnes. Elles contiennent des équipements audiovisuels et tableaux blancs afin de faciliter le partage d\'informations et doivent être acoustiquement isolées (les personnes proches hors de la salle ne doivent pas entendre les conversations se déroulant hors de la salle). Les grandes salles de réunions pourraient être équipés avec un mobilier flexible de façon à simplifier d’éventuels changements de destinations (exemple : Salle de Yoga).',
  meetingRoomDesc6: 'Ces salles de réunions sont des espaces clos prévus pour accueillir des réunions limitées entre 2 et 4 personnes. Elles contiennent des équipements audiovisuels et tableaux blancs afin de faciliter le partage d\'informations et doivent être acoustiquement isolées (les personnes proches hors de la salle ne doivent pas entendre les conversations se déroulant hors de la salle). Les grandes salles de réunions pourraient être équipés avec un mobilier flexible de façon à simplifier d’éventuels changements de destinations (exemple : Salle de Yoga).',
  meetingRoomDesc8: 'Ces salles de réunions sont des espaces clos prévus pour accueillir des réunions limitées entre 2 et 4 personnes. Elles contiennent des équipements audiovisuels et tableaux blancs afin de faciliter le partage d\'informations et doivent être acoustiquement isolées (les personnes proches hors de la salle ne doivent pas entendre les conversations se déroulant hors de la salle). Les grandes salles de réunions pourraient être équipés avec un mobilier flexible de façon à simplifier d’éventuels changements de destinations (exemple : Salle de Yoga).',
  meetingRoomDesc12: 'Ces salles de réunions sont des espaces clos prévus pour accueillir des réunions limitées entre 2 et 4 personnes. Elles contiennent des équipements audiovisuels et tableaux blancs afin de faciliter le partage d\'informations et doivent être acoustiquement isolées (les personnes proches hors de la salle ne doivent pas entendre les conversations se déroulant hors de la salle). Les grandes salles de réunions pourraient être équipés avec un mobilier flexible de façon à simplifier d’éventuels changements de destinations (exemple : Salle de Yoga).',
  meetingRoomDesc15: 'Ces salles de réunions sont des espaces clos prévus pour accueillir des réunions limitées entre 2 et 4 personnes. Elles contiennent des équipements audiovisuels et tableaux blancs afin de faciliter le partage d\'informations et doivent être acoustiquement isolées (les personnes proches hors de la salle ne doivent pas entendre les conversations se déroulant hors de la salle). Les grandes salles de réunions pourraient être équipés avec un mobilier flexible de façon à simplifier d’éventuels changements de destinations (exemple : Salle de Yoga).',

  openCollabSpace4to6Desc: 'Ces espaces sont prévus pour accueillir les activités de groupes entre 4 et 6 personnes telles que les brainstormings, conversations informelles entre collaborateurs ou collaborateurs et clients.',
  cafeFoodServiceDesc: 'La cafétéria est conçue comme un espace de restauration permettant aussi les échanges et interactions sociales, le travail individuel et les petites réunions. Le design de cet espace doit incorporer une palette de couleurs conforme à la marque de l\'entreprise et proposer des installations de restauration où la praticité est de mise.',
  coatClosetsDesc: 'Les collaborateurs ont besoin d\'endroits où ranger leurs manteaux et autres objets volumineux, particulièrement dans les cas d\'aménagements avec espaces ouverts où les stockages individuels au niveau des bureaux sont limités. Ils sont répartis sur les plateaux et près des sorties ou dans les travées principales.',
  copyPrintDesc: 'Répartis au sein des étages dans le cas d\'openspaces, les espaces d\'impression donnent aux collaborateurs un accès simple et rapide à des copieurs multifonctions. Ils sont toujours équipés de poubelles de recyclage et de petites armoires de rangement pour les consommables. Ces espaces ne sont pas destinés à servir d\'espace d\'échanges ou de réunions.',
  specialResourceBuildingSupportAreaDesc: 'Il s\'agit d\'un lieu secondaire qui peut être destiné à des fonctions diverses comme par exemple une annexe à un PC sécurité, un espace de stockage technique ou tout autre prestation de service au sein de l\'immeuble. Son utilisation varie selon les organisations, et à mesure que la taille des locaux grandit, ce type d\'espace auxiliaire devient plus que probable et nécessaire.',
  secondaryServerRoomDesc: 'Salle informatique secondaire assurant la haute disponibilité et hébergeant les terminaisons de câblages courant faibles. Ce type de salle nécessite une aération constante et une température stabilisée de 22 degrés Celsius avec 50% d\'humidité.',
  mothersRoomDesc: 'Les espaces de repos / locaux d\'allaitement sont destinés à celles et ceux qui recherchent un lieu de repos pour une courte période, ou un espace où allaiter dans des conditions d\'hygiène et de calme optimales. Les locaux d\'allaitement sont une recommandation forte pour toute entreprise de plus de 100 salariés.',
  visitorCoatLuggageRoomDesc: 'Les collaborateurs ont besoin d\'endroits où ranger leurs manteaux et autres objets volumineux, particulièrement dans les cas d\'aménagements avec espaces ouverts où les stockages individuels au niveau des bureaux sont limités. Ils sont répartis sur les plateaux et près des sorties ou dans les travées principales.',
  wellnessRoomDesc: 'Les espaces de bien-être sont destinés à celles et ceux qui recherchent un lieu de repos pour une courte période, ou un espace où allaiter dans des conditions d\'hygiène et de calme optimales. Les salles de bien-être et les locaux d\'allaitement sont une recommandation forte pour toute entreprise. Les salles de bien-être devront disposer d’un réfrigérateur pour les mères qui allaitent.',
  internalStairDesc: 'Les escaliers intérieurs donnent accès aux étages par des cages d\'escaliers centrales adaptées ou peuvent être disposés de façon autre pour connecter les étages. Ils peuvent proposer des espaces aménagés pour machines à café, réception ou échanges informels.',
  commercialPrintRoomDesc: 'Souvent associé à l\'espace courrier, il offre la capacité d\'imprimer, relier et assembler des documents. Il met à disposition plusieurs imprimantes et peur proposer des imprimantes grand format et traceurs. Cet espace comprend un espace de stockage pour les fournitures, un comptoir pour les équipements plus petits et un lieu pour la mise en page des impressions.',
  pantryVendingCoffeeDesc: 'Contrairement à la cafétéria, l\'espace café / office / distributeurs est utilisé par les collaborateurs pour rapidement récupérer nourriture et boissons. Les conversations étant monnaie courante dans ce type d\'espace, ils ne proposent pas de siège afin de permettre une circulation optimale tout en facilitant les échanges et discussions.',
  storageDesc: 'Les salles de stockage sont destinées à contenir divers articles tels que de gros équipements, des meubles supplémentaires, un stockage en vrac, des fournitures de vacances, etc. Elles peuvent également être utilisées pour le vestiaire des visiteurs et le stockage des bagages. Il est préférable de prévoir un petit stockage situé près du concierge pour plus de commodité.',
  centralisedDispersedMailAreaDesc: 'Pour les plus grandes sociétés, un service/espace courrier est nécessaire pour organiser le traitement des courriers entrants et sortants. Cet espace propose tout le matériel utile à la gestion et à la production du courrier, ainsi que de nombreuses surfaces de traitement du papier et autres supports. Il accueille très souvent un collaborateur à temps plein en charge de la gestion du courrier.',
  lockerDesc: 'Les casiers sont à disposition comme une autre forme de stockage individuel. Ils donnent aux employés un espace pour sécuriser leurs objets de valeur, leurs effets personnels et leurs documents professionnels. Les casiers peuvent être attribués à des collaborateurs ou utilisés selon le principe du premier arrivé, premier servi.',
  mainITandServerRoomDesc: 'Salle informatique principale assurant la haute disponibilité et hébergeant les terminaisons de câblages courant faibles. Ce type de salle nécessite une aération constante et une température stabilisée de 22 degrés Celsius avec 50% d\'humidité.',
  drawerLateralFileDesc: 'Bien qu\'une entreprise ne dispose peut-être pas d\'une cuisine et d\'un comptoir de service complets, elle peut fournir un café ou un bar à jus de fruits dans le cadre des équipements de restauration dans cet espace. Une entreprise peut également avoir ce genre d\'espace en plus de ses principaux services de restauration, surtout si les locaux sont grands. Cet espace ressemblera probablement à votre Starbucks local avec un espace de travail pour un membre du personnel de service, séparé par un comptoir.',
  grabNGoDesc: 'Une petite zone de commodité avec plusieurs réfrigérateurs, des étagères pour le snack et les collations, et une machine à commander. La sécurité de ces espaces Grab-N-Go est généralement surveillée par caméra. Ces espaces sont souvent combinés avec un petit garde-manger avec des réfrigérateurs, des congélateurs et une zone de préparation des aliments.',
  smMeetingConferenceRoomDesc: 'Une petite salle de conférence est un espace de réunion fermé destiné à accueillir confortablement 2 à 6 personnes assises à une table. Ces salles peuvent être utilisées à la fois pour des réunions internes et pour des réunions avec les clients. Elles doivent donc être très conviviales avec un accès facile à l\'alimentation électrique et aux équipements audiovisuels.',
  mdMeetingConferenceRoomDesc: 'Une salle de conférence moyenne est un espace de réunion fermé destiné à accueillir confortablement 6 à 10 personnes assises à une table. Ces salles sont utilisées à la fois pour des réunions internes et des réunions avec les clients. Elles doivent donc être très conviviales avec un accès facile à l\'alimentation électrique et aux équipements audiovisuels.',
  lgMeetingConferenceRoomDesc: 'Une grande salle de conférence est un espace de réunion fermé destiné à accueillir confortablement 12 à 14 personnes assises à une table. Ces salles sont utilisées à la fois pour des réunions internes et des réunions avec les clients. Elles doivent donc être très conviviales avec un accès facile à l\'alimentation électrique et aux équipements audiovisuels. Les grandes salles de réunions pourraient être équipés avec un mobilier flexible de façon à simplifier d’éventuels changements de destinations (exemple : Salle de Yoga).',
  xlMeetingRoomDesc: 'Une salle de conférence de type "boardroom" est prévue pour accueillir confortablement 14 à 18 personnes, avec la possibilité de recevoir au moins 10 personnes supplémentaires assises en périphérie de la pièce. Cette salle est prévue pour de grandes réunions formelles en interne et pour des réunions de grande ampleur avec des clients. Pour ce faire, elles sont équipées de technologies audiovisuelles de pointe et sont décorées selon des codes orientés clients.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - US_SMALL and US_REGULAR
  lgOfficeDesc: 'Les bureaux sont des espaces de travail individuels fermés destinés aux collaborateurs. Ils sont parfaits pour isoler des nuisances sonores et permettre un travail de concentration. Ils sont généralement équipés d\'un bureau et d\'un fauteuil ainsi que de sièges invités pour les visiteurs. Ils proposent différentes quantités de stockage personnel et de classement. La taille de l\'espace peut  varier en fonction des activités qui ont lieu dans la salle (par exemple, des réunions régulières) ou du statut des collaborateurs. Ce bureau légèrement plus grand peut être un bureau pour deux collaborateurs ou peut servir de salle de réunion.',
  smWorkstationDesc: 'Les workstations sont des postes de travail individuels ouverts. Des postes de travail plus petits peuvent être utilisés par des collaborateurs à temps plein soit temporairement (en accès libre) soit de façon permanente. Ces bureaux sont également parfaits pour collaborateurs de passage ou des prestataires qui ne sont pas au bureau de façon permanente. Collaboration et ouverture de l\'espace sont parmi les avantages de ces bureaux. Un inconvénient peut être le bruit induit par cette disposition, c\'est pourquoi il est recommandé de mixer ces bureaux avec des Focus ou Huddle. Le stockage dans ces bureaux est réduit et limité souvent aux abords des plateaux ou étages.',
  mdWorkstationDesc: 'Les workstations sont des postes de travail individuels ouverts. Ces espaces plus larges induisent une meilleure capacité de stockage de confidentialité. Ces bureaux sont de façon générale attribués à des collaborateurs plutôt qu\'en accès libre. Collaboration et ouverture de l\'espace sont parmi les avantages de ces bureaux. Un inconvénient peut être le bruit induit par cette disposition, c\'est pourquoi il est recommandé de mixer ces bureaux avec des Focus ou Huddle. Des stockages supplémentaires se situent souvent aux abords des plateaux ou étages.',
  lgWorkstationDesc: 'Les workstations sont des postes de travail individuels ouverts. Ces espaces plus larges induisent une meilleure capacité de stockage de confidentialité. Ces bureaux sont de façon générale attribués à des collaborateurs plutôt qu\'en accès libre. Collaboration et ouverture de l\'espace sont parmi les avantages de ces bureaux. Un inconvénient peut être le bruit induit par cette disposition, c\'est pourquoi il est recommandé de mixer ces bureaux avec des Focus ou Huddle. Des stockages supplémentaires se situent souvent aux abords des plateaux ou étages.',
  huddleRoomDesc: 'Les Huddles sont des espaces de travail fermés dédiés aux réunions de 2 à 4 personnes. Ils proposent des équipements audiovisuels et tableaux blancs afin de faciliter le partage d\'informations et de connaissances. Ils sont isolés acoustiquement de façon à ce que les personnes installées à proximité extérieure du Huddle ne soient pas capables d\'entendre les conversations qui s\'y déroulent.',
  multiPurpTrainingDesc: 'Les salles multi-activités sont conçues pour accueillir un grand nombre de personnes (plus de 20). Ces espace permettent de prendre en charge de multiples activités telles que des formations, des événements corporate, des présentations pour de larges auditoires. L\'aménagement se doit d\'être résistant vues les allées-venues en grand nombre, mais reste cependant décoré selon des codes orientés clients.',
  informalCollabDesc: 'Ces espaces sont prévus pour accueillir les activités de petits groupes entre 4 et 6 personnes, telles que des brainstormings, des échanges informels entre collaborateurs ou avec des clients.',
  touchdownTeamDesc: 'Les points de rassemblement ("Touchdowns") sont des espaces de travail individuels ou en petites équipes dispersés ça et là dans les locaux, utilisables par les collaborateurs et les visiteurs. Ils peuvent être de diverses hauteurs, tailles et formes et sont utilisés pour de courtes périodes de temps. Ils doivent être "plug and play", c\'est-à-dire faciles à trouver et à utiliser.',
  frontDeskAreaDesc: 'C\'est le premier arrêt possible quand un collaborateur ou un visiteur arrivent dans les locaux. Le bureau doit pourvoir accueillir une personne et son équipement, une capacité de rangement pour les visiteurs se trouvant à proximité autant que faire se peut. Le bureau se trouve non loin de l\'accueil, où des sièges et autres aménagements sont disponibles.',
  mailCenterAreaDesc: 'Pour des plus grandes entreprises, un service courrier est nécessaire pour centraliser les activités correspondantes. Cela inclut de grandes espaces de tri et de gestion, associés à des espaces pour le fret et les équipements. Cet espace varie souvent d\'une entreprise à l\'autre, étant inclus dans d\'autres éléments d\'organisation des locaux.',
  kitchenAreaDesc: 'Pour les locaux qui proposent un service de restauration, une cuisine est nécessaire pour soutenir cette activité. Les exigences d\'une cuisine varient d\'une entreprise à une autre, mais l\'on retrouve souvent des appareils de cuisson et un réfrigérateur. Elle propose également un stockage des marchandises sèches et du matériel. La cuisine sera adjacente au possible comptoire de service, et probablement au centre de conférence / services aux clients.',
  serveryAreaDesc: 'Le comptoir de service est l\'endroit où les personnes récupèrent leur nourriture. Il est  relié à la cuisine et à un coin repas avec des tables et des sièges. La planification est très précise pour gérer les files d\'attente et le trafic. La taille et la forme de cet espace varient d\'une organisation à l\'autre.',
  diningSupportAreaDesc: 'Une entreprise qui offre des services de restauration importants dispose d\'espaces techniques supplémentaires comme le stockage dans d\'autres parties du bâtiment qui doivent être pris en compte dans le programme global. L\'espace variera selon les entreprises.',
  diningAreaDesc: 'Manger à proximité de la cuisine offre une variété de choix aux personnes pour s\'asseoir et déjeuner. Fournir plusieurs types de sièges différents permet également d\'utiliser l\'espace pour les réunions et la collaboration en dehors de l\'heure du déjeuner. Fournir des sièges doux et durs ainsi que des réglages de barre et de hauteur assise donne aux utilisateurs une palette de choix intéressante.',

  // Space Unit Map Descriptions (spaceUnitMap.js) - UK_REGULAR
  fullFoodServiceDesc: 'Dans le cadre d\'une prestation d\'un service de restauration, plusieurs fonctions sont réunies pour soutenir cette activité, notamment une cuisine, un comptoir de service, une salle à manger (sièges) et un ou des espaces de stockage. La cuisine et le comptoir de service travaillent ensemble pour préparer et servir les plats faits maison. La salle à manger est l\'endroit où les collaborateurs et les invités peuvent s\'asseoir et manger, mais souvent cet espace agit comme un centre social et de collaboration. Comme à la maison, il y a beaucoup d\'équipements et de stockage de nourriture requis pour fournir des services de restauration, c\'est pourquoi les espaces correspondants sont inclus dans l\'espace Service de Restauration.',

  // SpaceUnitRow
  spaceUnitRowDeleteTitle: 'Supprimer le type d\'espace',
  spaceUnitRowDeleteText: 'Etes-vous certain que vous souhaitez supprimer ce type d\'espace ?',
  spaceUnitRowConfirmDeleteText: 'Oui, supprimer',
  spaceUnitRowCancelDeleteText: 'Non, conserver',

  // CustomAmenityAdder
  customAmenityAddText: 'Ajouter une commodité / aménagement',
  customAmenityPlaceholder: 'Type d\'aménagement',

  // SpaceUnitTotals
  spaceUnitTotalsTitleNIA: 'Surface nette totale',
  spaceUnitTotalsTitle: 'Total {{sfTypeAdj}} Carrés {{unitCapitalized}}',
  spaceUnitTotalsCirculation: 'Circulation',
  spaceUnitTotalsCirculationTooltip: 'L\' "espace entre les espaces". Couloirs, l\'espace derrière votre chaise, l\'espace autour des armoires et des portes, ainsi qu\'autour des coins. Sans la circulation, vous n\'auriez nulle part où marcher ! Le plus souvent autour de 35% du total de {{unitCapitalized}} carrés utiles dans votre programme, cependant variable en fonction de votre profil et de l\'immeuble sélectionné.',
  spaceUnitTotalsREBNY: 'Facteur REBNY',
  spaceUnitTotalsNYCText: 'New York City',
  spaceUnitTotalsREBNYTooltip: 'Pour les programmes à New York, nous augmentons le {{sfType}} de 15% pour prendre en compte les mesures REBNY standards. L\'espace mesuré selon les mesures REBNY standards comprennent les sanitaires, cages d\'ascenseurs, et l\'épaisseur des murs extérieurs (entre autres choses).',
  spaceUnitTotalsLossFactor: 'Facteur de perte',
  spaceUnitTotalsLossFactorTooltip: 'les {{unit}} carrés utiles représentent combien d\'espace vous avez dans vos locaux. Les {{unit}} carrés louables représentent la surface pour laquelle vous payez, incluant les pilonnes, halls, et autres parties communes. La différence entre surface utile et surface louable représente le facteur de perte, et son calcul varie selon les endroits.',

  // CollaborationDropdown
  collabDropdownLevelLowText: 'Minime (0-25%)',
  collabDropdownLevelMedText: 'Moyen (26-50%)',
  collabDropdownLevelHighText: 'Important (51-100%)',

  //DensityDropdown
  sameDensityText: 'Une densité identique à celle d’avant COVID-19',
  lowerDensityText: 'Une densité plus faible (soit davantage de mètres carrés par personne)',
  notSureDensityText: 'Je ne sais pas',


  //CirculationDropdown
  preCovidText: 'Pre-Covid-19 (45% bureaux ouverts / 25% fermés)',
  higherText: 'Densité diminuée (50% bureaux ouverts / 30% fermés)',
  notSureText: 'Je ne sais pas (45% bureaux ouverts / 25% fermés)',

  // ExamplePlans
  examplePlanButtonText: 'Visualisez un exemple de plan',
  examplePlanFullFloorText: 'Etage complet',
  examplePlanPartialFloorText: 'Etage partiel',
  examplePlanFullImgAlt: 'Plan d\'un étage de {{size}} {{unit}} carrés illustrant un profil {{profileName}} issu de Spacer by CBRE',
  examplePlanPartialImgAlt: 'Plan d\'un étage partiel de {{size}} {{unit}} carrés illustrant un profil {{profileName}} issu de Spacer by CBRE',
  examplePlanTooltipText: 'Si vous avez plus de {{size}} {{unit}}, vous aurez besoin de plus d\'un étage.',

  // ProgramStats
  programStatsDensitySubtitleTsubo: 'Tsubo net',
  programStatsDensitySubtitle: '{{adjective}} Carrés {{unitUppercase}}',
  programStatsIndivTitle: 'Postes de Travail',
  programStatsWorkstationsSubtitle: 'Workstations',
  programStatsOfficesSubtitle: 'Bureaux',
  programStatsCollabTitle: 'Places d’échanges',
  programStatsEnclosedSubtitle: 'Sièges de groupe',
  programStatsIndivSeatSubtitle: 'Siège individuel',
  programStatsSeatsTitle: 'Positions par personne',
  programStatsIndivSeatsSubtitle: 'Sièges individuels',
  programStatsPersonSubtitle: 'Personne',
  programStatsDensityTitle: 'Densité',
  programStatsFullDensitySubtitle: '{{density}} par Siège individuel',

  // Profile Display Names (profileNames.js)
  efficientName: 'L\'Optimiseur',
  whiteCollarName: 'L\'Accomodateur',
  whiteGloveName: 'L\'Amplificateur',
  openOfficeName: 'Le Producteur',
  workplace360Name: 'Le Connecteur',
  campusName: 'Le Différenciateur',
  startupName: 'L\'Incubateur',
  engCreativeName: 'Le Créateur',

  // Profile Descriptions (profileDescriptions.js)
  efficientDesc: 'Le résultat de type Optimizer est un environnement sans fioriture avec un état d\'esprit axé sur "faire le travail". L\'espace est axé sur la mise à disposition des collaborateurs avec tous les outils nécessaires disponibles à leur bureau. La collaboration et le travail en équipe sont moins fréquents. Les bureaux des collaborateurs sont normalisés et attribués avec seulement des espaces de support basiques fournis. Fidèle à son nom, ces environnements sont presque toujours ouverts afin de maximiser l\'efficacité.',
  whiteCollarDesc: 'Pour le type Accomodateur, vous n\'êtes pas seul - c\'est l\'un des profils les plus populaires pour les lieux de travail en entreprise. Les bureaux des Accomodateurs préfèrent ne pas bousculer les habitudes avec une conception d\'espace radicale, mais investissent souvent dans de nouvelles technologies et de nouveaux meubles pour rendre l\'espace productif et attrayant. Avec un équilibre des espaces individuels et collaboratifs, les employés se retrouveront principalement à leur bureau avec des réunions occasionnelles et des sessions d\'équipe. Parfait pour une activité traditionnelle.',
  whiteGloveDesc: 'Entrez dans le hall d\'un immeuble Enhancer et vous vous sentirez peut-être devenu grand. Ce lieu de travail répond profondément aux besoins des collaborateurs, ainsi que des clients, qui visitent fréquemment les locaux. Plus que tout autre profil de lieu de travail, les bureaux et les espaces clos sont communs. La plupart des employés gagnent leur espace de bureau grâce à la performance ou à l\'ancienneté, et la hiérarchie joue probablement un rôle important dans le fonctionnement de l\'organisation. Grimpez l\'échelle de l\'entreprise avec style.',
  openOfficeDesc: 'Le bureau de type Producteur sait ce dont vous avez besoin pour réussir. Il s\'agit de donner aux gens les espaces fondamentaux nécessaires en fonction du type d\'activité qui se déroule dans les locaux. Non seulement ces différents types d\'espace sont disponibles, mais nous constatons qu\'ils sont très utilisés - un moyen de rester efficace ! Il existe un mélange homogène entre le travail individuel et le travail en équipe, ce qui signifie probablement que vous vous déplacez beaucoup au bureau pendant la journée. Parfait pour un bureau en déplacement !',
  workplace360Desc: 'Tout comme votre pose de yoga préférée, le Connecteur est fier de l\'équilibre. Avec la bonne combinaison d\'espace axé sur la productivité et d\'espace de connexion sociale, le bureau Connector fournit tous les éléments essentiels pour la concentration tout en encourageant le choix avec une variété d\'autres espaces. Fidèle à son nom, le bureau du Connecteur localise ses zones communes comme les cuisines et les espaces de réunion ouverts de manière centralisée. Cela aide à rassembler les gens et crée un sentiment de communauté.',
  campusDesc: 'Le Différenciateur rend difficile le départ en fin de journée. C\'est parce qu\'il se caractérise par la fourniture d\'une expérience de travail qui est au-dessus et au-delà du reste. Vous trouverez des équipements améliorés autour du bien-être ou de la restauration pour aider les employés à gagner du temps et à se sentir à l\'aise. Quelqu\'un a-t-il dit des collations ? Les employés sont encouragés à prendre des pauses, à socialiser et à se détendre sur le lieu de travail.',
  startupDesc: 'La vitesse et l\'idéation sont les maîtres-mots en ce qui concerne l\'Incubateur. Les employés travaillent dans des équipes hautement collaboratives et pensent comme des propriétaires. Cet environnement de bureau est en constante évolution, ce qui signifie que la plupart des espaces a une flexibilité intégrée. Le déplacement de meubles et le changement sont courants. Un mélange d\'activités sociales amusantes et de sessions de travail intenses, comme le ping-pong et les hackathons, sont utilisés pour renforcer la camaraderie en équipe.',
  engCreativeDesc: 'Nous considérons le Createur comme un endroit rempli de spécialistes - pas votre mélange typique de services. Comme pour tous les grands efforts créatifs, l\'espace de type Createur propose souvent de travailler ensemble et de faire équipe. L\'équipement spécialisé ou les matériaux à manipuler font probablement partie des activités de travail quotidiennes. Les collaborateurs disposent de l\'espace dont ils ont besoin pour faire leur travail efficacement, et bien qu\'il s\'agisse plutôt d\'un type d\'espace rare, un job unique nécessite un lieu de travail unique.',


  // Profile Key Traits: Shared Names (profileTraitMap.js)
  social: 'Social',
  efficient: 'Efficace',
  flexible: 'Flexible',
  enabled: 'Activé',
  productive: 'Productif',
  inclusive: 'Inclusif',
  clientFocused: 'Orienté Client',
  standardized: 'Standardisé',

  // Profile Key Traits (profileTraitMap.js)
  social1Desc: 'Les relations en dehors et à l\'intérieur du bureau sont importantes',
  social2Desc: 'Beaucoup d\'événements de cohésion d\'équipe',
  social3Desc: 'Les collaborateurs apprécient passer du temps ensemble',
  social4Desc: 'Plus ouvert et collectif',
  efficient1Desc: 'L\'entreprise demeure économe et soucieuse de son budget',
  efficient2Desc: 'L\'entreprise demeure économe et productive',
  flexible1Desc: 'Les gens choisissent comment travailler',
  flexible2Desc: 'Les collaborateurs ont beaucoup de liberté',
  flexible3Desc: 'Construisez l\'espace dont vous avez besoin',
  enabled1Desc: 'Les collaborateurs ont ce dont ils ont besoin à leur bureau',
  enabled2Desc: 'Les collaborateurs disposent de services et commodités de qualité',
  enabled3Desc: 'Les collaborateurs peuvent avoir tout ce dont ils ont besoin pour travailler',
  enabled4Desc: 'Avoir accès au bon équipement et à la technologie adéquate',
  enabled5Desc: 'De l\'équipement spécialisé est disponible',
  productive1Desc: 'Design fonctionnel et intuitif',
  productive2Desc: 'Orienté Client et responsable financièrement',
  productive3Desc: 'Centré sur un produit unique ou sur une seule offre de service',
  inclusive1Desc: 'Différents type d\'espaces disponibles',
  inclusive2Desc: 'Vous pouvez trouver tout ce dont vous avez besoin dans les locaux',
  inclusive3Desc: 'Beaucoup de façons différentes de travailler sont possibles',
  clientFocused1Desc: 'La première impression des clients est primordiale',
  clientFocused2Desc: 'Les locaux sont accueillants pour les clients et les nouveaux collaborateurs',
  clientFocused3Desc: 'Les locaux sont soignés pour les clients et les nouveaux collaborateurs',
  standardized1Desc: 'Les processus et les systèmes sont rationnels',
  standardized2Desc: 'Normes organisationnelles en ce qui concerne l\'allocation d\'espaces',
  standardized3Desc: 'Tailles modulaires pour la flexibilité',
  standardized4Desc: 'Processus et livrables rationalisés',

  // SpaceSummary
  spaceSummaryBarHeader: 'Taille de locaux recommandée',
  spaceSummaryMeasurementTypeText: 'Type de mesures',
  spaceSummaryUnitTypeText: 'unités',
  spaceSummaryLossFactorSectionLabel: '{{factor}} Détails',
  spaceSummaryFitFactorText: 'Facteur d\'ajustement',
  spaceSummaryLossFactorText: 'Facteur de perte',
  spaceSummaryRSFLossFactorHelperText: 'Basé sur un facteur de perte de {{percentage}}% pour {{place}}, vous devriez rechercher des locaux entre {{min}} et {{max}} {{units}} de surface louable.',
  spaceSummaryNIAFitFactorHelperText: 'Basé sur un facteur de perte de {{percentage}}% pour {{place}}, vous devriez rechercher des locaux avec {{amt}} de surface nette utile.',
  spaceSummaryNIATooltipText: 'La Surface Nette Utile est la surface obtenue en ôtant la surface de certains espaces à la surface totale d\'un immeuble ou d\'un étage.',

  // SFTypeRadioInput
  sfRadioInputUsableAreaTooltipText: 'Cette mesure inclut toutes les salles, meubles et espaces de circulation.',
  sfRadioInputRentableAreaTooltipText: 'C\'est typiquement le {{unitUppercase}} Carré Utilisable plus un partie des espaces communs de l\'immeuble. Bien que vous ne puissiez tout utiliser, c\'est le {{unit}} Carré que vous payer quand vous louez.',
  sfRadioInputUsableSquareText: '{{unitUppercase}} Carré Utile',
  sfRadioInputRentableSquareText: '{{unitUppercase}} Carré Louable',

  //floored plan request block
  flooredTitle: 'Ne partez pas tout de suite !',
  flooredSubTitle: 'Revoir la manière dont les clients explorent l’espace',
  flooredSubText: 'Voir les résultats sur un plan d’étage et basculez en 3D !',
  flooredParagraphText1: 'Expérience immersive permettant aux utilisateurs d’imaginer des possibilités, d’expérimenter de nouveaux espaces et de transformer les idées en réalités',
  flooredParagraphText2: 'Vision concrète et personnalisée pour chaque espace potentiel',
  flooredVideoHeading: 'Voir un exemple d’expérience immersive',
  flooredFreeText: 'Quoi de plus, c’est gratuit !',
  flooredIconText1: 'Partagez les plans librement',
  flooredIconSubText1: 'Exportez les sur des Formats Autocad ou PDF',
  flooredIconText2: 'Temps de production estimé',
  flooredIconSubText2: 'Une à deux semaines',
  flooredIconText3: 'Recevez une expérience partageable',
  flooredIconSubText3: 'Expériences 2D et 3D personnalisées',

  // MeasurementSystemRadioInput
  measurementSystemSqFeetText: 'Pied carré',
  measurementSystemSqMetersText: 'Mètre carré',
  measurementSystemTsuboText: 'Tsubo',

  // ProfileHero
  resultsProfileHeroAnnouncementTitle: 'Votre espace est',
  resultsProfileHeroImgAlt: 'Plan 3D montrant un aperçu des bureaux pour le profil {{profileName}} généré par Spacer by CBRE',

  // ProfileComparison
  profileCompModalTitle: 'Changer votre Profil ?',
  profileCompModalText: 'Vous predrez toute modification non sauvegardée sur votre programme actuel.',
  profileCompModalConfirmText: 'Oui, changer mon Profil',
  profileCompModalCancelText: 'Non, garder mon Profil actuel',
  profileCompNavbarCancelButtonText: 'Annuler',
  profileCompNavbarUseButtonText: 'Utiliser ce Profil',
  profileCompCompareTitle: 'Comparez vos correspondances de Profils les plus proches',

  // ProfileComparisonColumn
  profileCompColumnSelectedText: 'Profil sélectionné',
  profileCompColumnSelectText: 'Selectionner',
  profileCompColumnRecommendedText: '(Recommandé)',
  profileCompColumnImgAltText: 'Plan 3D montrant un aperçu des bureaux pour le profil {{profileName}} généré par Spacer by CBRE'

};
