const React     = require('react');
const PropTypes = require('prop-types');
const _         = require('lodash');

function formatStatValue(stat) {
  if (_.endsWith(stat, '%')) {
    const newStat = stat.substring(0, stat.length - 1);
    return (
      <div>
        <span>{newStat}</span>
        <span className="title is-4 program-stat-value-percent">%</span>
      </div>
    );
  }
  return stat;
}

function StatWithSubtitle(props) {
  return (
    <div className="column">
      <div className="program-stat-content">
        <h3 className="title is-6 program-stat-title has-margin-bottom-4 is-sans-semibold">{props.statTitle}</h3>
        {
          props.isRatio ?
            <div className="flex flex-row flex-justify-around">
              <div className="program-stat-content-column has-text-centered">
                <div className="title is-2 has-text-primary program-stat-value is-sans-semibold">{formatStatValue(props.stat[0])}</div>
                <div className="subtitle ratio-width is-6 has-text-light is-sans-regular program-stat-subtitle">
                  {props.statSubtitle[0]}
                </div>
              </div>
              <div className="title is-2 program-stat-value is-sans-semibold has-text-grey-light has-text-centered">:</div>
              <div className="program-stat-content-column program-stat-right-col has-text-centered">
                <div className="title is-2 has-text-primary program-stat-value is-sans-semibold ">{formatStatValue(props.stat[1])}</div>
                <div className="subtitle is-6 has-text-light is-sans-regular program-stat-subtitle">
                  {props.statSubtitle[1]}
                </div>
              </div>
            </div>
            :
            <div>
              <div className="title is-2 has-text-primary program-stat-value is-sans-semibold has-text-centered">{props.stat}</div>
              <div className="subtitle is-6 has-text-light is-sans-regular has-text-centered program-stat-subtitle">
                {props.statSubtitle}
              </div>
            </div>
        }
      </div>
    </div>

  );
}

StatWithSubtitle.propTypes = {
  statTitle: PropTypes.string.isRequired,
  stat: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]).isRequired,
  statSubtitle: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]).isRequired,
  isRatio: PropTypes.bool.isRequired
};

StatWithSubtitle.defaultProps = {
  tooltipText: ''
};

module.exports = StatWithSubtitle;
