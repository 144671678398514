const React                = require('react');
const PropTypes            = require('prop-types');
const _                    = require('lodash');
const classnames           = require('classnames');
const { withTranslation }   = require('react-i18next');
const BasicNavbarWithLinks = require('../common/BasicNavbarWithLinks.jsx');
const HeroSection          = require('../common/HeroSection.jsx');
const Footer               = require('../common/Footer.jsx');
const DocumentData         = require('../common/DocumentData.jsx');
const blogCopy             = require('../../../../../copy/blogCopy');
const blogTags             = require('../constants/blogTags');
const constants            = require('wp-constants').spacerFree;
const BlogArticlePreview   = require('./BlogArticlePreview.jsx');

function BlogHome(props) {
  const [activeTag, setActiveTag] = React.useState('');


  const tabs = [
    { value: '', text: props.t('blogTagAllArticles') },
    { value: blogTags.SIZE_AND_FIT, text: props.t('blogTagSizeAndFit') },
    { value: blogTags.OFFICE_CULTURE, text: props.t('blogTagOfficeCulture') }
    // These don't have articles with those tags yet. Include again when those sections aren't empty
    // { value: blogTags.SPACE_AND_DESIGN, text: props.t('blogTagSpaceAndDesign') },
    // { value: blogTags.WORKPLACE_TRENDS, text: props.t('blogTagWorkplaceTrends') }
  ];


  const filteredBlogPosts = _.filter(blogCopy, article => (!activeTag || article.tags.indexOf(activeTag) > -1));
  const blogPostsSortedByDate = _.reverse(_.sortBy(filteredBlogPosts, ['date'])); // show latest dated posts first

  return (
    <div className="blog-home">
      <DocumentData
        siteLocation={constants.siteLocation.PAGE.BLOG_HOME}
      />
      <BasicNavbarWithLinks />
      <main>
        <HeroSection hasPrimaryBackground classes="blog-hero">
          <div className="has-text-centered">
            <h1 className="title2">{props.t('blogHomeTitle')}</h1>
          </div>
        </HeroSection>
        <div className="blog-tag-filter-background">
          <div className="blog-tag-filter">
            <div className="container">
              <div role="radiogroup" className="columns is-mobile is-variable is-0 is-multiline blog-tag-filter-options" aria-label="Filter Articles">
                {
                  tabs.map((tab, idx) => (
                    <div className={classnames('column', { 'is-one-third-mobile': idx !== tabs.length - 1 })} key={tab.value}>
                      <label>
                        <input
                          type="radio"
                          name="blog-tag-filter"
                          value={tab.value}
                          onChange={() => setActiveTag(tab.value)}
                          checked={tab.value === activeTag}
                        />
                        <span className={classnames('blog-tag-filter-option is-sans-medium', { 'active-tag': tab.value === activeTag })}>
                          {tab.text}
                        </span>
                      </label>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className="container blog-article-container section">
          <h2 className="subtitle">{_.find(tabs, ['value', activeTag]).text}</h2>
          <div className="columns is-multiline">
            {
              blogPostsSortedByDate.map(article => (
                <div className="column is-half" key={article.namespace}>
                  <BlogArticlePreview article={article} />
                </div>
              ))
            }
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

BlogHome.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('blogPages')(BlogHome);
