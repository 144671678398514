const React      = require('react');
const PropTypes  = require('prop-types');
const classnames = require('classnames');

function ContentSection(props) {
  const classes = classnames('content-section section', {
    'is-hidden-mobile': props.hideOnMobile,
    'is-hidden-print': props.hideOnPrint,
    'email-wall-section': props.isEmailWallSection
  });

  return (
    <div className={classes}>
      <div className="container">
        {props.children}
      </div>
    </div>
  );
}

ContentSection.propTypes = {
  children: PropTypes.node.isRequired,
  hideOnMobile: PropTypes.bool,
  hideOnPrint: PropTypes.bool,
  isEmailWallSection: PropTypes.bool
};

ContentSection.defaultProps = {
  hideOnMobile: false,
  hideOnPrint: false,
  isEmailWallSection: false
};

module.exports = ContentSection;

