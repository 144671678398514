const requestUtil = require('../../shared/util/request');

function sendTestFitFormEmail(testFitFormData, onSuccess = () => {}, onFailure = () => {}) {
  return requestUtil.jsonPostWithCredentials('/testFitForm', testFitFormData)
    .then(() => onSuccess())
    .catch(err => onFailure(err));
}

function createSource(sourceData, onSuccess = () => {}, onFailure = () => {}) {
  return requestUtil.jsonPostWithCredentials('/sources', sourceData)
    .then(onSuccess)
    .catch(onFailure);
}

function getReferralLinkFromEmail(email, onSuccess = () => {}, onFailure = () => {}) {
  if (email) {
    return requestUtil.fetchWithCredentials(`/sources/email/${email}`)
      .then(onSuccess)
      .catch(err => onFailure(err));
  }
  return null;
}

function fetchSourceData(sourceId, onSuccess = () => {}, onFailure = () => {}) {
  return requestUtil.fetchWithCredentials(`/sources/sourceId/${sourceId}`)
    .then(onSuccess)
    .catch(onFailure);
}

module.exports = {
  sendTestFitFormEmail,
  createSource,
  getReferralLinkFromEmail,
  fetchSourceData
};
