const React = require('react');
const PropTypes = require('prop-types');
const numericDisplay = require('../../../util/numericDisplay');
const unitsUtil = require('../../../../shared/util/units');
const regionSFCalculator = require('../../../../shared/calculators/regionSFCalculator');
const constants = require('wp-constants').spacerFree;
const MeasurementSystemRadioInput = require('../../containers/results/program/MeasurementSystemRadioInput.jsx');
const SFTypeRadioInput = require('../../containers/results/profile/SFTypeRadioInput.jsx');
const ProgramHeroForPrint = require('../../print/ProgramHeroForPrint.jsx');
const Tooltip = require('../../common/Tooltip.jsx');
const { withTranslation } = require('react-i18next');
const { dashOrValue } = require('../../../../shared/util/display');

const SF_TYPE = constants.calculator.SF_TYPE;

class SpaceSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showLossFactorHelper: false };

    this.toggleLossFactorHelper = this.toggleLossFactorHelper.bind(this);
  }

  toggleLossFactorHelper() {
    this.setState(prevState => ({
      showLossFactorHelper: !prevState.showLossFactorHelper
    }));
  }

  render() {
    // Space Section Logic
    const selectedLanguage = this.props.i18n.language;
    const city = this.props.location.split(',')[0];
    const lossFactorInteger = Math.round(this.props.lossFactor * 100);

    const isRSF = this.props.sfType === SF_TYPE.RSF;
    const isNIA = this.props.sfType === SF_TYPE.NIA;

    const usf = unitsUtil.getNumberForMeasurementSystem(this.props.totalUSF, this.props.measurementSystem);
    const rsf = unitsUtil.getNumberForMeasurementSystem(this.props.totalRSF, this.props.measurementSystem, { precisionForFeet: -2 });
    const rsfRange = regionSFCalculator.getRSFRange(this.props.totalRSF);

    if (selectedLanguage === 'fr') {
      rsfRange.min = this.props.totalRSF * 0.85
    }
    const rsfMin = unitsUtil.getNumberForMeasurementSystem(rsfRange.min, this.props.measurementSystem, { precisionForFeet: -2 });
    const rsfMax = unitsUtil.getNumberForMeasurementSystem(rsfRange.max, this.props.measurementSystem, { precisionForFeet: -2 });

    const rsfMinWithCommas = numericDisplay.numberWithLocaleFormat(rsfMin);
    const rsfMaxWithCommas = numericDisplay.numberWithLocaleFormat(rsfMax);


    let mainAreaCopy;
    const rsfOrRSM = this.props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('RSF', this.props.measurementSystem)}`);
    const usfOrUSM = this.props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('USF', this.props.measurementSystem)}`);
    const nia = this.props.t('common:NIA');

    const usfCopy = `${dashOrValue(!usf, numericDisplay.numberWithLocaleFormat(usf))} ${usfOrUSM}`;
    const nsfCopy = `${dashOrValue(!rsf, numericDisplay.numberWithLocaleFormat(rsf))} ${nia}`;
    const rsfMinCopy = `${dashOrValue(!rsfMin, numericDisplay.numberWithLocaleFormat(rsfMin))} ${rsfOrRSM}`;
    const rsfMaxCopy = `${dashOrValue(!rsfMax, numericDisplay.numberWithLocaleFormat(rsfMax))} ${rsfOrRSM}`;
    const rsfCopy = `${dashOrValue(!rsf, numericDisplay.numberWithLocaleFormat(rsf))} ${rsfOrRSM}`;

    if (isRSF) {
      mainAreaCopy = rsfCopy;
    } else if (isNIA) {
      mainAreaCopy = nsfCopy;
    } else {
      mainAreaCopy = usfCopy;
    }

    const rsfBar = (
      <div className="rsf-bar">
        <div className="bar is-sans-regular has-text-primary">
          <div className="columns is-mobile">
            <div className="column is-one-quarter" />
            <div className="bar-progress" />
          </div>
          <div className="min-max-labels flex flex-justify-between">
            <div className="min-rsf">{rsfMinCopy}</div>
            <div className="max-rsf">{rsfMaxCopy}</div>
          </div>
        </div>
      </div>
    );

    // Loss Factor Logic

    let lossFactorArrowClasses = 'arrow-image';
    if (this.state.showLossFactorHelper) lossFactorArrowClasses += ' is-up';

    const rsfLossFactorHelperText = this.props.t('spaceSummaryRSFLossFactorHelperText', { percentage: lossFactorInteger, place: city, min: rsfMinWithCommas, max: rsfMaxWithCommas, units: this.props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('feet', this.props.measurementSystem)}`) });
    const niaLossFactorHelperText = this.props.t('spaceSummaryNIAFitFactorHelperText', { percentage: lossFactorInteger, place: city, amt: usf });

    const lossFactorHelper = (
      <div className="loss-factor-helper-description is-sans-regular has-text-primary is-size-7">{isNIA ? niaLossFactorHelperText : rsfLossFactorHelperText}</div>
    );

    const lossFactorSectionLabel = this.props.t('spaceSummaryLossFactorSectionLabel', { factor: isNIA ? this.props.t('spaceSummaryFitFactorText') : this.props.t('spaceSummaryLossFactorText') });

    const lossFactorSection = (
      <div className="loss-factor-section">
        <div className="loss-factor-button flex no-outline" onClick={this.toggleLossFactorHelper} role="button" tabIndex="0">
          <div className={lossFactorArrowClasses} />
          <div className="loss-factor-text is-sans-regular has-text-primary">{lossFactorSectionLabel}</div>
        </div>
        {this.state.showLossFactorHelper && lossFactorHelper}
      </div>
    );

    // Radio Inputs
    const sfRadioInput = (
      <div>
        <div className="radio-button-name is-sans-regular is-grey-dark has-padding-left-7">{this.props.t('spaceSummaryMeasurementTypeText')}</div>
        <SFTypeRadioInput t={this.props.t} />
      </div>
    );

    // Return

    return (
      <div>
        <ProgramHeroForPrint lossFactor={lossFactorInteger} city={city} usf={usfCopy} rsf={`${rsfMinCopy} - ${rsfMaxCopy}`} nsf={nsfCopy} sfType={this.props.sfType} />
        <div className="space-summary">
          <div className="space-section has-padding-5">
            <h2 className="has-text-primary has-padding-bottom-6">{this.props.t('spaceSummaryBarHeader')}</h2>
            {isRSF && rsfBar}
            <div className="sf-label-container">
              <div className="title2 is-2 w-90">
                {mainAreaCopy}
              </div>
              {isNIA && <Tooltip text={this.props.t('spaceSummaryNIATooltipText')} />}
            </div>
            {!isNIA && sfRadioInput}
            <div className="radio-button-name is-sans-regular is-grey-dark has-padding-left-7">{this.props.t('spaceSummaryUnitTypeText')}</div>
            <MeasurementSystemRadioInput t={this.props.t} />
          </div>
          {(isRSF || isNIA) && lossFactorSection}
        </div>
      </div>
    );
  }
}

SpaceSummary.propTypes = {
  location: PropTypes.string.isRequired,
  sfType: PropTypes.number.isRequired,
  lossFactor: PropTypes.number.isRequired,
  measurementSystem: PropTypes.string.isRequired,

  totalUSF: PropTypes.number.isRequired,
  totalRSF: PropTypes.number.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(SpaceSummary);
