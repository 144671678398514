module.exports = {

  // Navbar
  spacerLogoAlt: 'Spacer de CBRE.',
  linkToAboutPageText: 'Acerca ',
  linkToBlogPageText: 'Perspectivas',
  referrals: 'Referencias',

  // TakeOfficeSpaceQuizButton
  sizeMyOfficeButtonDefaultLinkText: 'Realice el cuestionario sobre los espacios de la oficina',
  flooredPlanRequestLinkText: 'Solicite su plano',

  // Footer
  footerCBRELogoAlt: 'Logotipo de CBRE para Spacer de CBRE',
  footerFacebookIconAlt: 'Ícono social de Facebook para Spacer de CBRE',
  footerTwitterIconAlt: 'Ícono social de Twitter para Spacer de CBRE',
  footerLinkedinIconAlt: 'Ícono social de LinkedIn para Spacer de CBRE',
  footerHeaderQuestionsText: '¿Preguntas?',
  footerHeaderAboutText: 'Recursos ',
  footerHeaderMoreText: 'Más ',
  footerHeaderBrokersText: 'Para los corredores ',
  footerLinkWhatIsSpacerText: 'Acerca de',
  footerLinkBlogText: 'Perspectivas',
  footerLinkWorkplaceText: '¿Quién es el Centro de Trabajo de CBRE?',
  footerLinkTermsText: 'Condiciones de Servicio ',
  footerLinkPrivacyText: 'Política de privacidad ',
  footerLinkedinLink: 'https://www.linkedin.com/showcase/cbre-workplace-strategy/',
  footerLinkReferralText: 'Referencias ',
  footerWorkplaceLink: 'http://www.cbre.us/real-estate-services/occupier/workplace',
  footerPrivacyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Contact (contact.js)
  spacerSupportEmail: 'spacersupport@cbre.com',

  // ErrorPage
  errorOopsText: '¡Ups!',
  error404Text: '¡No podemos encontrar la página que está buscando!',
  error500Text: 'Algo salió mal por parte nuestra',
  errorText: 'Error',
  errorReturnText: 'Volver al Spacer',
  errorAltText: 'Ilustración de una taza de café derramada por Spacer de CBRE',

  // Measurement Units (units.js)
  SF: 'SF',
  SM: 'm²',
  USF: 'USF',
  USM: 'USM',
  RSF: 'RSF',
  RSM: 'RSM',
  NSF: 'NSF',
  NSM: 'NSM',
  NIA: 'NIA',
  foot: 'pie',
  feet: 'pies',
  footage: 'metraje',
  'sqft.': 'pies cuadrados (ft2).',
  meter: 'metro',
  meters: 'metros ',
  'sqm.': 'metros cuadrados (m²)',
  Tsubo: 'tsubo',

  // SF Types Adjectives (calculator.js)
  Usable: 'Usable',
  Rentable: 'Rentable',
  Net: 'Neta',

  // Page Titles (documentDataCopy.js)
  landingPageTitle: 'Planifique su oficina perfecta en 15 minutos - Spacer de CBRE',
  aboutPageTitle: 'Conozca cómo funciona nuestra herramienta de dimensionamiento de oficinas - Spacer de CBRE',
  termsPageTitle: 'Condiciones de servicio - Spacer de CBRE',
  quizPageTitle: 'Dimensione su oficina en 15 minutos - Spacer de CBRE',
  resultsPageTitle: 'Ver recomendaciones de oficina personalizada - Spacer de CBRE',
  profileComparisonPageTitle: 'Compare sus necesidades de oficina - Spacer por CBRE',
  blogHomePageTitle: 'Ideas para la planificación de oficinas - Spacer por CBRE',
  referralPageTitle: 'Enlace de referencia para corredores de CBRE - Spacer por CBRE',
  referralRetrievePageTitle: 'Recuperar enlace de referencia para los corredores de CBRE - Spacer por CBRE',

  // Meta Tags (documentDataCopy.js)
  landingPageMetaTagText: '¿Está planeando su próxima oficina? Deje que Spacer por CBRE le ayude a determinar de forma rápida y sencilla la cantidad de espacio de oficina que necesita para alcanzar sus objetivos empresariales. Sólo le llevará 15 minutos.',
  aboutPageMetaTagText: 'El tamaño de su oficina puede ayudarle a atraer talento, reducir costos e incluso mantener a sus empleados sanos. Conozca más sobre cómo Spacer puede recomendar rápidamente el tamaño de oficina adecuado para apoyar los objetivos de su empresa.',
  termsPageMetaTagText: 'Más de una década de conocimientos y experiencia en el sector se ha invertido en la creación de Spacer por CBRE, el cual hace recomendaciones informadas sobre el tamaño de las oficinas en cuestión de minutos.',
  quizPageMetaTagText: 'Realice el test de 15 minutos de Spacer para determinar cuánto espacio de oficina necesita para apoyar los objetivos y prioridades de su empresa. La programación personalizada nunca ha sido tan rápida.',
  resultsPageMetaTagText: 'Los resultados de los espacios personalizados de Spacer le ahorran tiempo en la búsqueda de oficinas, ya que le dan el tamaño exacto de oficina que su empresa necesita, junto con los tipos de muebles y diseños sugeridos para la distribución de la planta.',
  profileComparisonPageMetaTagText: 'Comprenda sus necesidades únicas de oficina en comparación con las de otros utilizando Spacer por CBRE',
  blogHomePageMetaTagText: 'Calcular el espacio de oficina que necesita una empresa no es una ciencia perfecta, pero estas ideas y recursos de planificación de oficinas pueden ayudar. Obtenga más información aquí.',
  referralPageMetaTagText: 'Utilice este link de enlace para enviar Spacer a los clientes y asegurarse de que los resultados de su espacio se envían directamente a su correo electrónico. Obtenga más información aquí.',
  referralRetrieveMetaTagText: 'Recupere su enlace de referencia para Spacer.',

  // Form title
  formLabel: 'Su información'
};
