module.exports = {
  blogHomeTitle: 'Office Planning Insights',

  // blog tags
  blogTagAllArticles: 'All Articles',
  blogTagSizeAndFit: 'Size & Fit',
  blogTagOfficeCulture: 'Office Culture',
  blogTagSpaceAndDesign: 'Space & Design',
  blogTagWorkplaceTrends: 'Workplace Trends'
};
