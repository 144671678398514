const React               = require('react');
const PropTypes           = require('prop-types');
const spacerFreePropTypes = require('../../spacerFreePropTypes');
const constants           = require('wp-constants').spacerFree;
const quizOrder           = require('wp-data').quizOrderInfo.quizOrder;
const { withTranslation }  = require('react-i18next');

class RankSentenceCompletionAnswer extends React.Component {
  constructor(props) {
    super(props);

    this.addToActiveRanking = this.addToActiveRanking.bind(this);
    this.removeFromRanking = this.removeFromRanking.bind(this);
  }

  addToActiveRanking(optionId) {
    return () => {
      const questionAnswer = this.props.questionAnswer.slice();
      const activeRankIndex = questionAnswer.indexOf(null);
      questionAnswer[activeRankIndex] = optionId;
      this.props.onAnswerChange(questionAnswer);
    };
  }

  removeFromRanking(rankIndex) {
    return () => {
      const questionAnswer = this.props.questionAnswer.slice();
      questionAnswer[rankIndex] = null;
      this.props.onAnswerChange(questionAnswer);
    };
  }

  render() {
    const activeRankIndex = this.props.questionAnswer.indexOf(null); // first null in array

    // for this type of question, answerText is an array of arrays
    //    - the outer array represents sentence parts at corresponding rank positions
    //    - the inner array represents the words / input placeholder that make up each sentence part

    const sentenceElements = [];

    let hasRankingAtIndex;
    let isActiveRankIndex;

    let sentencePart;
    let sentencePartClasses;
    let sentencePartElements;
    let wordsOrInputPlaceholder;

    let fillInTheBlankWrapperClasses;
    let fillInTheBlankClasses;
    let fillInTheBlankContent;

    for (let i = 0, len = this.props.questionUnit.answerText.length; i < len; i += 1) {
      hasRankingAtIndex = !!this.props.questionAnswer[i];
      isActiveRankIndex = activeRankIndex === i;

      sentencePart = this.props.questionUnit.answerText[i];
      sentencePartClasses = 'sentence-complete-answer';
      if (!hasRankingAtIndex && !isActiveRankIndex) sentencePartClasses += ' is-unselected';

      if (hasRankingAtIndex) {
        fillInTheBlankWrapperClasses = '';
        fillInTheBlankClasses = '';
        fillInTheBlankContent = (
          <div className="tag tag-btn-width is-medium has-border" title={this.props.questionUnit.options[this.props.questionAnswer[i]].text}>
            <span className="flex-btn-ellipsis">
            {this.props.questionUnit.options[this.props.questionAnswer[i]].text}
              </span>
              <span>
            <button className="delete is-medium" onClick={this.removeFromRanking(i)} aria-label="unselect this answer" />
            </span>
          </div>
        );
      } else {
        fillInTheBlankWrapperClasses = 'underline-input no-pointer is-primary has-dashed-border';
        if (!isActiveRankIndex) fillInTheBlankWrapperClasses += ' is-disabled';
        fillInTheBlankClasses = 'input';
        fillInTheBlankContent = this.props.questionUnit.emptyAnswerPlaceholder;
      }

      sentencePartElements = [];

      for (let j = 0, jLen = sentencePart.length; j < jLen; j += 1) {
        wordsOrInputPlaceholder = sentencePart[j];
        const keyForWordsOrInputPlaceholder = `${wordsOrInputPlaceholder}-${i}-${j}`;

        if (wordsOrInputPlaceholder === constants.questionUnit.INPUT_PLACEHOLDER) {
          sentencePartElements.push(
            <div className={fillInTheBlankWrapperClasses} key={keyForWordsOrInputPlaceholder}>
              <div className={fillInTheBlankClasses}>
                {fillInTheBlankContent}
              </div>
            </div>
          );
        } else {
          let classesForWords = 'sentence-complete-words';
          if (wordsOrInputPlaceholder === ',' || wordsOrInputPlaceholder === '.') classesForWords += ' is-hidden-mobile';
          sentencePartElements.push(
            <span key={keyForWordsOrInputPlaceholder} className={classesForWords}>
              {wordsOrInputPlaceholder}
            </span>
          );
        }
      }

      sentenceElements.push(
        <div className={sentencePartClasses} key={sentencePart.join().substring(0, 25)}>
          {sentencePartElements}
        </div>
      );
    }

    const optionElements = Object.keys(this.props.questionUnit.options).map(optionId => (
      <button
        key={optionId}
        className={`tag is-block is-medium btn-width ${this.props.questionAnswer.indexOf(optionId) > -1 ? 'is-disabled' : 'is-clickable'}`}
        disabled={this.props.questionAnswer.indexOf(optionId) > -1} // actually disable the button so it's also not selectable by keyboard
        onClick={this.addToActiveRanking(optionId)}
        title={this.props.questionUnit.options[optionId].text}
      >
        {this.props.questionUnit.options[optionId].text}
      </button>
    ));

    // show tooltip if on the first question, first rank is active, and other ranks are not yet filled out
    const showTooltip = this.props.questionUnit.id === quizOrder[0] && activeRankIndex === 0 && !this.props.questionAnswer[1] && !this.props.questionAnswer[2];

    return (
      <div>
        <div className="columns is-gapless has-padding-top-6">

          <div className="column is-8">
            {sentenceElements}
          </div>

          <div className="column is-4">
            {activeRankIndex > -1 &&
              <div>
                <div className={`rank-question-tooltip tooltip is-tooltip-top is-tooltip-active is-tooltip-info is-hidden-mobile ${showTooltip ? '' : 'is-invisible'}`} data-tooltip={this.props.t('firstResponseTooltipText')} />

                <div className="tags is-block">
                  {optionElements}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

RankSentenceCompletionAnswer.propTypes = {
  questionUnit: spacerFreePropTypes.questionUnitShape.isRequired,
  questionAnswer: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('quizPage')(RankSentenceCompletionAnswer);

