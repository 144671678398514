const React              = require('react');
const PropTypes          = require('prop-types');
const HeroSection        = require('./HeroSection.jsx');
const constants          = require('wp-constants').spacerFree;
const { withTranslation } = require('react-i18next');

function NextStepsSection(props) {
  return (
    <div className="next-steps">
      <HeroSection>
        <div className="columns level">

          <div className="column is-3 is-offset-1 level-item is-hidden-mobile">
            <figure className="image is-4by3">
              <img src="/svg/illustrations/results-page-people-conversing-spacer-by-cbre.svg" alt={props.t('nextStepsSectionImgAlt')} />
            </figure>
          </div>

          <div className="column is-6 is-offset-1">
            <h2 className="subtitle is-6">{props.t('nextStepsSectionSubtitle')}</h2>
            <div className="title is-3 next-steps-title is-calibre next-steps-size">{props.t('nextStepsSectionTitle')}</div>
            <button
              className="button is-primary-filled is-medium find-space-button next-steps-find-space-button"
              onClick={() => props.openModal(constants.modal.TYPES.FORM, { formType: constants.modal.FORM_TYPES.FIND_SPACE })}
            >
              {props.t('nextStepsSectionButtonText')}
            </button>
          </div>

        </div>
      </HeroSection>
    </div>
  );
}

NextStepsSection.propTypes = {
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(NextStepsSection);

