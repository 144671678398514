module.exports = {

  // AboutPageHero
  aboutPageHeroImgAlt: 'Grafika przedstawiająca ludzi wprowadzających się do biura znajdująca się na stronie Informacje o aplikacji CBRE Spacer',
  aboutPageHeroTitle: 'Jak aplikacja Spacer może Ci pomóc?',

  // AboutPageContent
  aboutPageContentEndSectionTitle: 'Zaczynamy?',
  aboutPageProgramPreviewImgAlt: 'Podgląd wyników kwestionariusza dotyczącego projektu przestrzeni znajdującego się na stronie głównej aplikacji CBRE Spacer',
  aboutPageVideoTitle: 'Jak to działa',


  // About Page Content (aboutPageContent.js)
  aboutPageContent1Title: 'Czym jest idealne biuro?',
  aboutPageContent1Text: 'Idealna przestrzeń biurowa przyciągnie i pomoże Ci w zatrudnieniu najlepszych talentów. Sprawi, że Twoi pracownicy będą cieszyć się dobrym zdrowiem, pozwoli obniżyć koszty operacyjne Twojej organizacji i umożliwi stworzenie warunków sprzyjających innowacyjnym pomysłom.\nAplikacja Spacer jest narzędziem, dzięki któremu skutecznie określisz hierarchię celów Twojej organizacji, a na ich podstawie uzyskasz konkretne zalecenia. To właśnie tutaj powinny rozpoczynać się wszelkie decyzje w kwestiach nieruchomości!\nAplikacja Spacer została stworzona z połączenia bezpłatnej, dostępnej dla każdego, nowoczesnej technologii internetowej oraz wiedzy zespołu CBRE Workplace, który może pochwalić się ponad dwudziestoletnim doświadczeniem w zakresie wspierania firm w planowaniu i projektowaniu idealnych biur. ',
  aboutPageContent1ImgAlt: 'Grafika przedstawiająca rozmawiających ze sobą ludzi znajdująca się na stronie Informacje o aplikacji CBRE Spacer',
  aboutPageSpacerSupportLink: 'spacersupport@cbre.com',

  aboutPageContent2Title: 'Aplikacja Spacer jest łatwa w użyciu:',
  aboutPageContent2List1: 'Zacznij od udzielenia odpowiedzi na 17 pytań dotyczących celów i wartości Twojej organizacji. Odpowiedzi te pozwolą nam na określenie charakteru Twojej idealnej przestrzeni biurowej.',
  aboutPageContent2List2: 'Dzięki funkcji Projekt Przestrzeni uzyskasz zalecenia co do typu i wielkości przestrzeni biurowej dostosowanej do Twoich potrzeb.',
  aboutPageContent2List3: 'Funkcja Rzuty da Ci możliwość stworzenia wizualizacji przestrzeni odpowiadającej Twoim potrzebom w wersji 2D oraz 3D.',
  aboutPageContent2List4: 'Zapisz swój projekt i wykorzystaj go podczas poszukiwań Twojego idealnego biura.',
  aboutPageContent2List5: 'Pozwól ekspertom CBRE znaleźć przestrzeń biurową spełniającą Twoje kryteria – tę usługę świadczymy nieodpłatnie!',
  aboutPageContent2Text: 'Jeżeli masz jakiekolwiek dodatkowe pytania co do tego, jak aplikacja Spacer może przyśpieszyć proces poszukiwania Twojego nowego biura, skontaktuj się z nami pod adresem',
  aboutPageContent2ImgAlt: 'Grafika przedstawiająca kobietę pracującą przy biurku znajdująca się na stronie Informacje o aplikacji CBRE Spacer'
};
