// Action Types

const ANSWER_PROFILE_QUESTION  = 'ANSWER_PROFILE_QUESTION';
const CALCULATE_PROFILE_SCORES = 'CALCULATE_PROFILE_SCORES';

module.exports = {

  // Action Types

  ANSWER_PROFILE_QUESTION,
  CALCULATE_PROFILE_SCORES,

  // Action Creators

  answerProfileQuestion(questionUnitId, selectedOption) {
    return { type: ANSWER_PROFILE_QUESTION, questionUnitId, selectedOption };
  },

  calculateProfileScores() {
    return { type: CALCULATE_PROFILE_SCORES };
  }

};
