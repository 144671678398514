{
    "categories": {
        "quiz": "Quiz",
        "result": "Result",
        "language": "language",
        "referals": "referals"
    },
    "actions": {
        "startQuiz": "Take office space quiz",
        "finishQuiz": "Finish Quiz",
        "completeResultBtn": "View Complete Result",
        "exportPDFBtn": "Export PDF",
        "sendResultBtn": "Send Results",
        "connectAdvisorFormSubmit": "Connect With an Advisor",
        "saveChangesBtn": "Save Changes",
        "requestPlanBtn": "Request a Plan",
        "examplePlansBtn": "View Example Plan",
       "referal": "referal",
       "de": "Deutsch",
       "en": "English (US)",
       "en-GB": "English (UK)",
       "svk": "Slovak",
       "pol": "Polish",
        "nld": "Dutch",
       "col":"Spanish"
      
    },
    "labels": {
        "startQuiz": "Take office space quiz",
        "finishQuiz": "Finish Quiz",
        "completeResultBtn": "View Complete Result",
        "exportPDFBtn": "Export PDF",
        "sendResultBtn": "Send Results",
        "connectAdvisorFormSubmit": "Connect With an Advisor",
        "saveChangesBtn": "Save Changes",
        "requestPlanBtn": "Request a Plan",
        "examplePlansBtn": "View Example Plan",
        "referal": "referal", 
        "de": "Deutsch",
       "en": "English (US)",
       "en-GB": "English (UK)",
       "svk": "Slovak",
       "pol": "Polish",
        "nld": "Dutch",
       "col":"Spanish"
    }
}