module.exports = {

  // AboutPageHero
  aboutPageHeroImgAlt: 'Illusztráció emberekről akik irodába költöznek a Spacer a CBRE-tól Rólunk oldalán',
  aboutPageHeroTitle: 'Mit tehet a Spacer érted?',

  // AboutPageContent
  aboutPageContentEndSectionTitle: 'Kezdhetjük?',
  aboutPageProgramPreviewImgAlt: 'Az eredmények oldal előnézete a Spacer a CBRE-tól holnapján',
  aboutPageVideoTitle: 'Hogyan működik',


  // About Page Content (aboutPageContent.js)
  aboutPageContent1Title: 'Határozd meg a tökéletes irodát',
  aboutPageContent1Text: 'A tökéletes irodatér segíthet a tehetségért folytatott versenyben, a munkavállalóid egészségének megőrzésében, az üzemeltetési költségek csökkentésében vagy olyan feltételek megteremtésében amelyben megszületik a következő nagy ötlet. A Spacer a legjobb eszköz arra, hogy megalapozd az ingatlannal kapcsolatos döntéseidet. Segít priorizálni a szervezeti céljaidat és segít a célok mentén javaslatokat megfogalmazni a szükséges terület iránt.\nA Spacer létrehozásában segített a CBRE mérnöki csapata, akik több mint 20 évnyi tapasztalattal a hátuk mögött számos cégnek segítettek a tökéletes irodájuk megtervezésében.',
  aboutPageContent1ImgAlt: 'Illusztráció beszélő emberekről a Space a CBRE-tól Rólunk oldalán',
  aboutPageSpacerSupportLink: 'spacersupport@cbre.com',

  aboutPageContent2Title: 'A Spacer könnyen használható:',
  aboutPageContent2List1: 'Kezd azzal, hogy egy 17 kérdéses kvízt töltessz ki a szervezeted céljairól és értékeiről. Ezek a válaszok határozzák meg majd a hozzátok illő iroda profilját.',
  aboutPageContent2List2: 'Juss hozzá az egyéni igényeidnek megfelelő javaslati listához, amely a terek típusát és méretét mutatja.',
  aboutPageContent2List3: 'A Floored Plans segítségével vizualizáld az igényeidhez hasonló irodatereket 2D-ben vagy 3D-ben.',
  aboutPageContent2List4: 'Mentsd el a javaslatokat, hogy felhasználhasd az iroda keresésed során.',
  aboutPageContent2List5: 'Vedd igénybe a CBRE szakértőinek ingyenes segítségét az irodakeresésed során!',
  aboutPageContent2Text: 'Írj nekünk az alábbi e-mail címre ha további kérdésed van azzal kapcsolatban, hogy a Spacer hogyan segíthet az irodakeresésedben:',
  aboutPageContent2ImgAlt: 'Illusztráció egy hölgyről akik egy irodaasztalnál dolgozik a Spacer a CBRE-tól Rólunk oldalán'
};
