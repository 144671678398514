const React              = require('react');
const PropTypes          = require('prop-types');
const { withTranslation } = require('react-i18next');

function ReferralLinkInformation(props) {
  return (
    <React.Fragment>
      <h1 aria-live="polite" className="title has-text-primary flex flex-justify-center">{props.t('howReferralLinksWorkTitle')}</h1>
      <ol className="flex flex-col referral-instructions-and-form">
        <li>
          {props.t('referralLinkStep1')}
          <ul>
            <li>{props.t('referralLinkReq1')}</li>
            <li>{props.t('referralLinkReq2')}</li>
          </ul>
        </li>
        <li>{props.t('referralLinkStep2')}</li>
      </ol>
      <button
        className="button is-primary-filled force-flex submitBtn"
        onClick={(e) => {
          e.preventDefault();
          props.goToNextStep();
        }}
      >
        {props.t('next')}
      </button>
    </React.Fragment>
  );
}

ReferralLinkInformation.propTypes = {
  t: PropTypes.func.isRequired,
  goToNextStep: PropTypes.func.isRequired
};

module.exports = withTranslation('referrals')(ReferralLinkInformation);
