const React               = require('react');
const PropTypes           = require('prop-types');
const numericDisplay      = require('../../../../util/numericDisplay');
const unitsUtil           = require('../../../../../shared/util/units');
const DeltaLabel          = require('../DeltaLabel.jsx');
const Tooltip             = require('../../../common/Tooltip.jsx');
const spacerFreePropTypes = require('../../../spacerFreePropTypes');
const { withTranslation }  = require('react-i18next');

function SpaceUnitRowMobile(props) {
  const totalArea = unitsUtil.getNumberForMeasurementSystem(props.totalArea, props.measurementSystem, { precisionForMeter: 1, precisionForTsubo: 1 });
  const totalAreaWithCommas = numericDisplay.numberWithLocaleFormat(totalArea);
  const measurementLabel = props.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('USF', props.measurementSystem)}`);

  return (
    <div className="columns is-mobile is-multiline has-margin-0-auto space-category-breakdown-row has-padding-botton-5-mobile">
      <div className="mobile-assumption-separator is-mobile-input has-margin-top-6" />
      <div className="column is-4-mobile has-no-padding-bottom-mobile space-category-breakdown-row-name">
        <span className="name small-title">{props.name}</span>
        {props.description && <Tooltip text={props.description} />}
      </div>
      <div className="column is-1-mobile space-category-breakdown-input-container">
        <span className="has-padding-right-8 is-sans-regular small-title">{unitsUtil.getNumberForMeasurementSystem(props.area, props.measurementSystem, { precisionForMeter: 1, precisionForTsubo: 1 })}</span>
        <span className="is-sans-regular small-title">{measurementLabel}</span>
      </div>
      <div className="column custom-x-style has-text-light has-text-centered is-sans-regular small-title space-category-x has-no-padding-left-mobile has-no-padding-right-mobile">x</div>
      <div className="column is-1-mobile space-category-breakdown-input-container">
        <div className="columns is-gapless space-category-units-container">
          <div className="column is-sans-regular small-title">
            {props.quantity}
          </div>
          <div className="column space-category-unit has-padding-right-8 is-sans-regular small-title">
            {props.displayUnit}
          </div>
        </div>
      </div>
      <div className={`column is-1-mobile has-text-${props.spaceUnitCategory} has-text-centered space-category-quantity is-sans-regular small-title`}>=</div>
      <div className={`column is-sans-regular small-title has-no-padding-left-mobile has-no-padding-right-mobile has-text-${props.spaceUnitCategory}`}>
        <div className="grand-total">{totalAreaWithCommas} {measurementLabel}</div>
        <DeltaLabel
          showDeltaPercentage={props.showDeltaPercentage}
          delta={props.delta}
          measurementSystem={props.measurementSystem}
        />
      </div>
    </div>
  );
}

SpaceUnitRowMobile.propTypes = {
  description: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  area: PropTypes.number.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  displayUnit: PropTypes.string.isRequired,
  totalArea: PropTypes.number.isRequired,
  showDeltaPercentage: PropTypes.bool,
  delta: spacerFreePropTypes.deltaShape,
  spaceUnitCategory: PropTypes.string,
  t: PropTypes.func.isRequired
};

SpaceUnitRowMobile.defaultProps = {
  description: '',
  delta: null,
  showDeltaPercentage: true,
  spaceUnitCategory: ''
};

module.exports = withTranslation('resultsPage')(SpaceUnitRowMobile);

