const React      = require('react');
const PropTypes  = require('prop-types');
const classnames = require('classnames');

function CheckboxInput(props) {
  const classes = classnames('checkbox', {
    'is-active': props.checked,
    'in-quiz': props.inQuiz
  });

  return (
    <label className={classes}>
      <input
        className="visually-hidden"
        type="checkbox"
        onChange={evt => props.onChange(evt.target.checked)}
        checked={props.checked}
        required={props.required}
      />
      {props.label}
    </label>
  );
}

CheckboxInput.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  inQuiz: PropTypes.bool
};

CheckboxInput.defaultProps = {
  required: false,
  inQuiz: false
};

module.exports = CheckboxInput;
