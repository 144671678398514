const React               = require('react');
const PropTypes           = require('prop-types');
const spacerFreePropTypes = require('../spacerFreePropTypes');
const _                   = require('lodash');
const Question            = require('./Question.jsx');
const InactiveQuestion    = require('./InactiveQuestion.jsx');

function QuestionGroup(props) {
  const questionComponents = [];

  const answeredQuestionIds = Object.keys(props.questionAnswers);
  props.questionUnits.forEach((questionUnit) => {
    const questionAnswer = props.questionAnswers[questionUnit.id];
    let questionComponent;

    if (questionUnit.id === props.activeQuestionUnitId) {
      questionComponent = (
        <div>
          <Question
            questionUnit={questionUnit}
            questionAnswer={questionAnswer}
            onAnswerChange={props.onAnswerChange}
            isSameOfficeAndPersonalLocation={props.isSameOfficeAndPersonalLocation}
            toggleSameOfficeAndPersonalLocation={props.toggleSameOfficeAndPersonalLocation}
          />
        </div>
      );
    } else if (answeredQuestionIds.indexOf(questionUnit.id) > -1) {
      questionComponent = (
        <InactiveQuestion
          questionUnit={questionUnit}
          questionAnswer={questionAnswer}
          onClick={props.onClickInactiveQuestion}
        />
      );
    }
    if (questionComponent) {
      questionComponents.push((
        <div key={`grouped-question-${questionUnit.id}`}>
          {questionComponent}
        </div>
      ));
    }
  });

  return (
    <div className="grouped-questions">
      {questionComponents}
    </div>
  );
}

QuestionGroup.propTypes = {
  questionUnits: PropTypes.arrayOf(spacerFreePropTypes.questionUnitShape).isRequired,
  questionAnswers: PropTypes.objectOf(spacerFreePropTypes.questionAnswerTypes).isRequired,
  onClickInactiveQuestion: PropTypes.func.isRequired,
  activeQuestionUnitId: PropTypes.string.isRequired,
  toggleSameOfficeAndPersonalLocation: PropTypes.func,
  isSameOfficeAndPersonalLocation: PropTypes.bool
};

QuestionGroup.defaultProps = {
  toggleSameOfficeAndPersonalLocation: _.noop,
  isSameOfficeAndPersonalLocation: true
};

module.exports = QuestionGroup;
