const { connect }             = require('react-redux');
const { bindActionCreators }  = require('redux');
const modalActions            = require('../../../redux/actions/modal');
const NextStepsSection        = require('../../common/NextStepsSection.jsx');

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(modalActions.openModal, dispatch)
  };
}

module.exports = connect(null, mapDispatchToProps)(NextStepsSection);

