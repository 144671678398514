const React                        = require('react');
const PropTypes                    = require('prop-types');
const { withTranslation }           = require('react-i18next');
const { getReferralLinkFromEmail } = require('../../util/dbServices');

function ReferralLinkSignUpForm(props) {
  const firstNameEmpty = (props.firstName === '');
  const lastNameEmpty = (props.lastName === '');
  const emailEmpty = (props.email === '');

  const [dbError, setDbError] = React.useState(false);
  const [takenLink, setTakenLink] = React.useState('');
  const [triedSubmit, setTriedSubmit] = React.useState(false);
  const [emailInvalid, setEmailInvalid] = React.useState(!emailEmpty);
  const nameInputEl = React.useRef(null);

  // manage focus - move it to first input when getting to this step
  React.useEffect(() => nameInputEl.current.focus(), []);

  function checkEmailTaken(userEmail) {
    getReferralLinkFromEmail(
      userEmail,
      referralLink => setTakenLink(referralLink),
      (err) => {
        switch (err.message) {
          case 'We couldn\'t find a referral link associated with that email address.': {
            if (!firstNameEmpty && !lastNameEmpty && !emailEmpty && !emailInvalid) props.goToNextStep();
            break;
          }

          case 'Not a cbre email': {
            setEmailInvalid(true);
            break;
          }

          default: {
            setDbError(true);
          }
        }
      }
    );
  }

  return (
    <form>
      <h1 aria-live="polite" className="title has-text-primary flex flex-justify-center">{props.t('enterYourInformation')}</h1>
      <div className="flex flex-col referral-instructions-and-form user-inputs">
        <h2 className="form-title">{props.t('common:formLabel')}</h2>
        <div className="columns is-mobile">
          <div className="column is-half is-half-mobile force-flex flex-col">
            <label className="flex flex-col">
              {props.t('firstName')}
              <input
                ref={nameInputEl}
                type="text"
                value={props.firstName}
                onChange={(evt) => {
                  props.setFirstName(evt.target.value);
                  setDbError(false);
                }}
                aria-describedby="firstName"
                aria-invalid={firstNameEmpty && triedSubmit}
              />
            </label>
            <div className={`form-error-text ${!(firstNameEmpty && triedSubmit) ? 'is-invisible' : ''}`} id="firstName">
              {props.t('emptyNameError')}
            </div>
          </div>
          <div className="column is-half is-half-mobile force-flex flex-col">
            <label className="flex flex-col">
              {props.t('lastName')}
              <input
                type="text"
                value={props.lastName}
                onChange={(evt) => {
                  props.setLastName(evt.target.value);
                  setDbError(false);
                }}
                aria-describedby="lastName"
                aria-invalid={lastNameEmpty && triedSubmit}
              />
            </label>
            <div className={`form-error-text ${!(lastNameEmpty && triedSubmit) ? 'is-invisible' : ''}`} id="lastName">
              {props.t('emptyNameError')}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <label className="flex flex-col">
            {props.t('email')}
            <input
              type="email"
              placeholder={props.t('emailExample')}
              value={props.email}
              onChange={(evt) => {
                props.setEmail(evt.target.value);
                setEmailInvalid(false);
                setDbError(false);
              }}
              aria-describedby="email"
              aria-invalid={(emailEmpty && triedSubmit) || (emailInvalid && triedSubmit) || takenLink || dbError}
            />
          </label>
          {
            ((emailEmpty && triedSubmit) || (emailInvalid && triedSubmit)) &&
            <div className="form-error-text" id="email">
              {props.t('emptyEmailError')}
            </div>
          }
          {
            takenLink &&
            <div className="form-error-text" id="email">
              {props.t('emailTakenError', { takenLink })}
            </div>
          }
          {
            dbError &&
            <div className="form-error-text" id="email">
              {props.t('somethingWrong')}
            </div>
          }
        </div>
      </div>
      <button
        className="button is-primary-filled force-flex submitBtn"
        onClick={(e) => {
          e.preventDefault();
          setTriedSubmit(true);
          checkEmailTaken(props.email);
        }}
      >
        {props.t('next')}
      </button>
    </form>
  );
}

ReferralLinkSignUpForm.propTypes = {
  t: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  setFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string,
  setLastName: PropTypes.func.isRequired,
  email: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  goToNextStep: PropTypes.func.isRequired
};

ReferralLinkSignUpForm.defaultProps = {
  firstName: '',
  lastName: '',
  email: ''
};

module.exports = withTranslation('referrals')(ReferralLinkSignUpForm);
