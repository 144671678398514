const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
// const globalActions          = require('../../../redux/actions/global');
import globalActions from '../../../redux/actions/global.js';
const LegalForm              = require('./LegalForm.jsx');
const { withTranslation }     = require('react-i18next');

// Map dispatch to props
function mapDispatchToProps(dispatch, ownProps) {
  return {
    fields: [
      {
        name: 'firstName',
        required: true,
        label: ownProps.t('contactFormFirstNameLabel'),
        autoComplete: 'given-name',
        defaultValue: ''
      },
      {
        name: 'lastName',
        required: true,
        label: ownProps.t('contactFormLastNameLabel'),
        autoComplete: 'family-name',
        defaultValue: ''
      },
      {
        name: 'email',
        type: 'email',
        required: true,
        label: ownProps.t('contactFormEmailLabel'),
        autoComplete: 'email',
        defaultValue: ''
      },
      {
        name: 'phone',
        label: ownProps.t('contactFormPhoneLabel'),
        autoComplete: 'tel',
        defaultValue: ''
      },
      {
        name: 'message',
        label: ownProps.t('contactFormMessageLabel'),
        required: true,
        defaultValue: ''
      },
      {
        name: 'contactType',
        defaultValue: ownProps.contactType,
        hidden: true
      }
    ],
    submitText: ownProps.t('contactFormSubmitText'),
    onSubmit: bindActionCreators(globalActions.contactSupport, dispatch)
  };
}

module.exports = withTranslation('resultsPage')(connect(null, mapDispatchToProps)(LegalForm));
