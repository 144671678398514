module.exports = {
  title: 'What’s the Difference Between Rentable and Usable Square Feet?',
  pageTitle: 'What’s the Difference Between Rentable and Usable Square Feet? — Spacer by CBRE',
  metaTagText: 'As a business, it is important to know the difference between rentable vs usable square feet. Learn more here',
  preview: `
    A foot is a foot, right?
    It is not so simple in commercial real estate. Several “players” in the real
    estate market have a stake in how space is measured. Architects and interior
    designs need to know how much space there is available for placing furniture.
    Engineers and regulatory bodies need to know how much space there is for code
    compliance and construction. And Landlords need to know how much space there is
    so they can charge rent for it.
  `,
  content: `
    <h2>A foot is a foot, right? </h2>
    <p>
    It is not so simple in commercial real estate. Several “players” in the real
    estate market have a stake in how space is measured. Architects and interior
    designs need to know how much space there is available for placing furniture.
    Engineers and regulatory bodies need to know how much space there is for code
    compliance and construction. And Landlords need to know how much space there is
    so they can charge rent for it.
    </p>
    <p>
    Measurement conventions have emerged to fulfill these different needs.
    </p>
    <h2>A few of them are easy:</h2>
    <p>
    SF – a square foot, 12 inches by 12 inches.
    </p>
    <p>
    NSF – Net Square Feet, the amount of space a thing itself occupies. A chair with
    a 2x2 footprint would be a 4 NSF item.
    </p>
    <p>
    Not too bad.
    </p>
    <p>
    Now, the trickier ones:
    </p>
    <h2>USF – Usable Square Feet</h2>
    <p>
    Each thing in an interior space has to have some area around it – think
    hallways, door swings, space for chairs to move away from a desk (and for you to
    spin around in them) – this space is called circulation. When you add up the NSF
    all of the things in an area (all of the desks, rooms, file cabinets, etc.) and
    all of the circulation that is needed to accommodate those items in an actual
    layout, you end up with USF. In simplest terms, USF = NSF + Circulation.
    </p>
    <p>
    USF is used in interior design and space planning, since it is a direct measure
    for how much space an architect or space planner has to design within. Although
    not a technical term, you might also hear the USF referred to as Carpetable
    Area, since this is the amount of space you could apply carpet to. For example,
    you couldn’t carpet the area that is occupied by a column or window sill, so
    even though you pay for this space in your rent, USF does not include these
    non-carpetable areas.
    </p>
    <h2>RSF – Rentable Square Feet</h2>
    <p>
    In office buildings, there are many areas that you use but aren’t necessarily
    “yours”. The building lobby, elevator lobbies, and fitness center in the
    basement, for example, aren’t a part of your office suite, but the landlord
    still wants you to pay for your portion of those spaces. In fact, when you rent
    space, you are paying for more “square feet” than just the USF you will be able
    to occupy on your floors. Rentable Square Feet is the metric that you pay for.
    It includes USF and a portion of building-common spaces. In some cities, RSF is
    just an inflated USF number, multiplied by a number called the building “load
    factor.” In a case where USF time 1.15 = RSF, the load factor would be 15%. In
    other cities, notably New York City, the calculation is slightly different, and
    market-driven. This means the RSF you pay for could shrink or expand in the long
    run, even if nothing in the actual building changes!
    </p>
    <p>
    At any rate, a Real Estate Professional can help you navigate the peculiarities
    of your market (we haven’t even mentioned International standards!). All you
    need to know is RSF is what you pay and search for in the market. USF is some
    portion of that and most helpful for your architect.
    </p>
    <p>
    Got more questions about your office space? Email us at <a
    href="mailto:spacersupport@cbre.com">spacersupport@cbre.com</a>.
    </p>
  `
};
