const React = require('react');
const PropTypes = require('prop-types');
const TakeOfficeSpaceQuizButton = require('../common/TakeOfficeSpaceQuizButton.jsx');
const HeroSection = require('../../common/HeroSection.jsx');
const { withTranslation } = require('react-i18next');

function LandingPageHero(props) {
  return (
    <HeroSection>
      <div className="secondary-hero has-padding-left-1 has-padding-right-1 has-padding-left-6-mobile has-padding-right-6-mobile">
        <div className="columns level">
          <div className="column is-half is-hidden-desktop is-hidden-tablet">
            <figure className="image is-4by3">
              <img src="/png/homepage-results-page-in-browser-spacer-by-cbre.png" alt={props.t('landingPageHeroImgAlt')} />
            </figure>
          </div>
          <div className="column is-two-fifths level-item">
            <div>
              <h1 className="title is-financier-regular is-size-4-mobile has-padding-bottom-4 has-text-primary">
                {props.t('landingPageHeroTitle')}
              </h1>
              <h2 className="subtitle is-size-6-mobile is-calibre has-text-grey-darker has-padding-top-4 has-padding-bottom-4">
                {props.t('landingPageHeroSubtitle')}
              </h2>
              <TakeOfficeSpaceQuizButton />
            </div>
          </div>
          <div className="column has-padding-left-3 is-three-fifths is-hidden-mobile">
            <figure className="image is-4by3">
              <img src={props.t('landingPageImage')} alt={props.t('landingPageHeroImgAlt')} />
            </figure>
          </div>
        </div>
      </div>
    </HeroSection>
  );
}

LandingPageHero.propTypes = {
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('landingPage')(LandingPageHero);
