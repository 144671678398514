module.exports = {

  // Quiz
  exitQuizButtonTitle: 'Kilépés a kvízből',

  // QuizProgressLabel
  progressLabelText: '',

  // QuizNavigationButton
  quizNavBackButtonShortenedText: 'Előző',
  quizNavBackButtonFullLengthText: 'Előző kérdés',
  quizNavNextButtonShortenedText: 'Következő',
  quizNavNextButtonFullLengthText: 'Következő kérdés',

  // QuizExitSlide
  quizExitSubtitle: 'Most a válaszaid alapján megadjuk a céged számára tökéletes irodateret.',
  quizExitImgAlt: 'Illusztráció két emberről akik pacsiznak a Spacer a CBRE-tól kvíz oldalán',

  // Question Unit Text (questionUnitMap.js)
  question1Text: 'Kezdetnek. Hogyan jellemeznéd a jövőbeni irodád célját?',
  question2Text: 'Szerinted mi az ami vonzó a szervezetedben az új jelöltek számára?',
  question3Text: 'Mik a legfontosabb szempontok amiket figyelembe veszel amikor új csapattagokat veszel fel?',
  question4Text: 'Előreláthatólag hogyan változik a cégedmérete a következő 5 évben?',
  question5Text: 'Milyen időbeosztásban dolgoztok?',
  question6Text: 'Mit gondolsz az egyéni, dedikált asztalokról?',
  question7Text: 'Amikor az irodában tartózkodnak a munkatársak, hol töltik a munkaidejük nagy részét?',
  question8Text: 'Mi a dresscode az irodában?',
  question9Text: 'Honnan dolgozol általában?',
  question10Text: 'Milyen irodai légkör uralkodik nálatok?',
  question11Text: 'A munkaállomásokon kívül még milyen területeket szeretnél biztosítani a munkavállalóknak?',
  question12Text: 'Ezek közül melyik ír le téged a legjobban?',

  // Quiz Answers (questionUnitMap.js)
  pickOnePlaceholder: 'válassz egyet...',
  selectFromListPlaceholder: 'válassz ebből a listából...',
  firstResponseTooltipText: 'Válaszd ki az első válaszod',
  locatedSomewhereElseLabel: 'Jelenleg más helyszínen dolgozom.',

  q1AnswerText1: 'Az irodánkról először ezek jutnak eszembe',
  q1AnswerText2: 'másodjára',
  q1AnswerText3: 'és harmadjára',
  q1Option1Text: 'a munkatarsak második otthona',
  q1Option2Text: 'a szervezeti változások katalizátora',
  q1Option3Text: 'öszpontosítást igénylő munka tere',
  q1Option4Text: 'a következő nagy ötlet inkubátora',
  q1Option5Text: 'eszköz a tehetségek bevonzására',
  q1Option6Text: 'a márka bemutatásának eszköze',
  q1Option7Text: 'a növekedés és a tanulás helyszíne',
  q1Option8Text: 'közösségi tér, ahol a kollegák találkozhatnak, összegyűlhetnek',
  q1Option9Text: 'szükséges költségtétel, amelyet minimalizálni kell',

  q2AnswerText1: 'Elsősorban azért csatlakoznak hozzánk, mert',
  q2AnswerText2: 'másodsorban',
  q2AnswerText3: 'és végül',
  q2Option1Text: 'céges kultúra',
  q2Option2Text: 'versenyképes fizetések',
  q2Option3Text: 'a cég hírneve',
  q2Option4Text: 'fizetésen kívüli juttatások',
  q2Option5Text: 'rugalmas munkaidő',
  q2Option6Text: 'emberek és vezetés',
  q2Option7Text: 'irodai környezet',
  q2Option8Text: 'célok és küldetés',

  q3AnswerText1: 'A legfontosabb szempont',
  q3AnswerText2: 'másodsorban',
  q3AnswerText3: 'végül pedig',
  q3Option1Text: 'készségek',
  q3Option2Text: 'tapasztalat',
  q3Option3Text: 'illeszkedés a cégkultúrába',
  q3Option4Text: 'referenciák',
  q3Option5Text: 'kapcsolatok',
  q3Option6Text: 'kíváncsiság',

  q4AnswerText: 'A jövőben',
  q4Option1Text: 'picit növekedünk minden évben',
  q4Option2Text: 'jelentősen növekedünk minden évben',
  q4Option3Text: 'nagyjából ugyanakkorák maradunk',
  q4Option4Text: 'kicsit csökkenünk minden évben',
  q4Option5Text: 'nehéz megmondani, megjósolhatatlan',

  q5AnswerText: 'Így dolgozunk általában:',
  q5Option1Text: '9-től 5-ig',
  q5Option2Text: 'reggeltől...sokáig',
  q5Option3Text: 'a munkaidő rugalmasan beosztható',
  q5Option4Text: 'amikor szeretnénk, amilyen gyakran szeretnénk',

  q6AnswerText: 'Úgy gondoljuk, hogy',
  q6Option1Text: 'mindenkinek saját jár',
  q6Option2Text: 'jobb megosztani az asztalokat',

  q7Option1Text: 'Mindig saját munkaállomáson',
  q7Option2Text: 'Elsősorban saját munkaállomáson, ahonnan lebonyolítanak néhány megbészélést és befejeznek néhány egyéb feladatot',
  q7Option3Text: 'Egyenlő eloszlásban a munkaállomáson és valahol máshol az irodában',
  q7Option4Text: 'Általában helységről helységre járva valahol leülve ahol hely van',
  q7Option5Text: 'Mindig helységről helységre járva',

  q8Option1Text: 'Üzleti megjelenés',
  q8Option2Text: 'Általában üzleti öltözet kivéve néhány különleges alkalmat',
  q8Option3Text: 'Általában lezser üzleti, de van néhány olyan nap amikor alkalmi, illetve néhány esetben hétköznapi öltözet is előfordul',
  q8Option4Text: 'Lezser öltözet néhány különleges alkalomtól eltekintve',
  q8Option5Text: 'Lezser',

  q9Option1Text: 'Mindig az irodából',
  q9Option2Text: 'Leginkább otthonról',
  q9Option3Text: 'Az irodából és otthonról is',
  q9Option4Text: 'Leginkább irodán kívül',
  q9Option5Text: 'Mindig irodán kívül',

  q10Option1Text: 'Dinamikus és energikus',
  q10Option2Text: 'Energikus, rövid csendesebb időszakokkal',
  q10Option3Text: 'Attól függ, a kettő egyvelege',
  q10Option4Text: 'Csendes, kevés dinamikus periódussal',
  q10Option5Text: 'Csendes és nyugalmas',

  q11Option1Text: 'Csak a feltétlenül szükséges helyiségeket',
  q11Option2Text: 'Az alapok és néhány extra',
  q11Option3Text: 'Az alapok és olyan helység ahol az emberek összegyűlhetnek',
  q11Option4Text: 'Valami nagyon jó célállomás az irodán belül',
  q11Option5Text: 'Valami szuper, kiemelkedő, amit szeretnél megmutatni egy látogatónak',

  q12AnswerText: 'Én egy',
  q12OptionClientText: 'jelenlegi CBRE ügyfél vagyok',
  q12OptionProfessionalText: 'CBRE kollega vagyok',
  q12OptionLeadText: 'valaki más',

  q13AnswerText1: 'Nálunk',
  q13AnswerText2: 'alkalmazott lesz az irodában',
  q13AnswerHelperText: '*Tipp: Ez általában a bérleti szerződésed középpontja vagy kb. 2-5 év mostantól számítva.',

  q14AnswerText1: 'Az időnk',
  q14AnswerText2: 'részét csapatmunkában dolgozzuk.',

  q15AnswerText1: 'Átlagosan',
  q15AnswerText2: 'napot tervezünk tölteni az irodában.',

  q16AnswerText1: 'Az irodánk itt lesz',
  q16AnswerText2: '.',

  q17AnswerText1: 'Elsősorban a',
  q17AnswerText2: 'szektorban dolgozunk.',

  q18AnswerText1: 'Most innen dolgozom',
  q18AnswerText2: '.',

  q19AnswerText1: 'Az ültetési sűrűséget (egy főre jutó nm)',
  q19AnswerText2: 'tervezzük.',

  // Quiz Responses (questionUnitMap.js)
  q1ResponseText: 'Meghatározunk egy irodát amely egyezik az elképzeléseddel.',
  q2ResponseText: 'Megnyerő összetétel!',
  q3ResponseText: 'Érdekesen hangzik hova férünk be?',
  q4ResponseText: 'Nagyszerű! Mi biztosítjuk, hogy az iroda mérete jó legyen.',
  q5Response1Text: 'Jól hangzik!',
  q5Response2Text: 'Mi is hajlamosak vagyunk túlórázni.',
  q5Response3Text: 'Nagyon jól hangzik!',
  q5Response4Text: 'Kiválóan hangzik!',
  q6Response1Text: 'Egy olyan teret határozunk meg amelyben az elsődleges a személyes tér.',
  q6Response2Text: 'Egy olyan teret határozunk meg amely rugalmas.',
  q12ResponseLeadText: 'A megfelelő helyre jöttél.',
  q12ResponseClientText: 'Kiváló!',
  q12ResponseProfessionalText: 'Köszönjük!',

  // Industry Codes (industryCodes.js)
  animalProd: 'Növénytermesztés, állattenyésztés',
  forestry: 'Erdőgazdálkodás',
  fishingHunting: 'Halászat, halgazdálkodás, vadgazdálkodás és kapcsolódó szolgáltatások',
  agForestSupport: 'Mezőgazdaság és kapcsolódó szolgáltatások',
  oilGas: 'Olaj és gáz kitermelés',
  energy: 'Energia ipar (Gáz, olaj stb.)',
  mining: 'Bányászat, kivéve olaj és gáz',
  supportMining: 'Bányászathoz kapcsolódó szolgáltatások',
  utilities: 'Közművek',
  buildingConstruct: 'Épületek építése',
  heavyCivilConstruct: 'Magas- és mély építészet',
  specialtyContract: 'Speciális szaképítés',
  foodManufac: 'Élelmiszer gyártás',
  beverageTobaccoManufac: 'Ital és dohányárú gyártás',
  textile: 'Textília gyártása',
  textileProduct: 'Textílárú gyártása',
  apparelManufac: 'Ruházatgyártása',
  leatherManufac: 'Bőr és bőrtermék gyártása',
  woodManufac: 'Faáru gyártása',
  paperManufac: 'Papírárú gyártása',
  printingSupport: 'Nyomtatás és kapcsolódó szolgáltatások',
  printingReproduc: 'Nyomdai és egyéb reprodukciós tevékenység',
  petroleumCoal: 'Kőolaj és szén termékek gyártása',
  chemicalManufac: 'Vegyi anyag, termék gyártása',
  chemicals: 'Vegyialapanyag gyártás',
  pharma: 'Gyógyszeripar',
  cleaningManufac: 'Tisztítóberendezések gyártása',
  plasticsRubberManufac: 'Gumi-, műanyag termék gyártása',
  nonmetallicManufac: 'Nemfém ásványi termék gyártása',
  primaryMetalManufac: 'Fémalapanyag gyártása',
  fabricatedMetalManufac: 'Fémfeldolgozási termék gyártása',
  machineryManufac: 'Gép, gépi berendezés gyártása',
  manufac: 'Gépgyártás',
  computerElectronicManufac: 'Számítógép, elektronikai, optikai termék gyártása',
  electricalApplianceManufac: 'Villamos berendezés gyártása',
  transportEquipManufac: 'Közúti jármű gyártása',
  aerospaceDefense: 'Légi és honvédelmi termék gyártása',
  furnitureManufac: 'Bútorgyártás',
  miscManufac: 'Egyéb gépgyártás',
  merchantWholesaleDurable: 'Nagykereskedelem, tartós árúk',
  merchantWholesaleNondurable: 'Nagykereskedelem, nem tartós áruk',
  electronicMarket: 'Elektronikai piac, ügynökök és brókerek',
  motorVehiclePartsDealers: 'Gépjármű, motorkerékpár kereskedelme, javítása',
  furnitureFurnishingStores: 'Bútor és lakberendezés kereskedés',
  electronicApplianceStores: 'Elektornikai és háztartási berendezés kereskedés',
  electronics: 'Elektronika',
  buildingGardenSupplyStores: 'Építőanyag és kertészeti cikk kereskedés',
  foodBeverageStores: 'Élelmiszer és ital kereskedés',
  groceryStores: 'Élelmiszer bolt',
  beerWineLiquorStores: 'Sör, bor és rövidital kereskedés',
  healthCareStores: 'Gyógyászati és illatszer kereskedés',
  gasStations: 'Benzinkutak',
  clothingAccessoriesStores: 'Ruházati és ruházati kiegészítő üzletek',
  clothingTextiles: 'Ruházat/textíliák',
  jewelryFurs: 'Ékszerek és szőrmék',
  sportsHobbyMusicBookStores: 'Sport, hobby, hangszer, könyv üzletek',
  genMerchandiseStores: 'Általános kiskereskedők',
  miscStoreRetailers: 'Egyéb kiskereskedők',
  officeProducts: 'Irodai termékek',
  retailSales: 'Kiskereskedelmi értékesítés',
  nonstoreRetails: 'Üzleti helység nélküli kereskedés',
  airTransport: 'Légi szállítás',
  travelRelated: 'Utazással kapcsolatos (légitársaságok, ügynökök, tömegközlekedés)',
  railTransport: 'Vasúti szállítás',
  waterTransport: 'Vízi szállítás',
  truckTransport: 'Közúti szállítás',
  transitTransport: 'Tranzit és földi személyszállítás',
  pipelineTransport: 'Csővezetékes szállítás',
  scenicTransport: 'Turisztikai jellegű szállítás',
  supportTransport: 'Szállítást támogató tevékenységek',
  postalService: 'Postai szolgáltatás',
  courierMessenger: 'Futárok',
  shippingDelivery: 'Szállítás',
  warehousingStorage: 'Rakzározás és tárolás',
  warehouseDistrib: 'Rakzározás/Forgalmazás',
  publishingNoInternet: 'Kiadói iparágak, az online kivételével',
  publishing: 'Kiadás',
  motionPicSoundRecordingIndustries: 'Mozgókép- és hangfelvételi ipar',
  entertainment: 'Szórakoztatás (Film, TV, Zene, stb)',
  infoServicesMarketResearch: 'Információs szolgáltatások/Piackutatás',
  broadcastingNoInternet: 'Műsorszórás, az online kivételével',
  telecom: 'Telekommunikáció',
  tech: 'Technológia',
  dataProcessingHostingServices: 'Adatfeldolgozás, tárhely és kapcsolódó szolgáltatások',
  otherInfoServices: 'Egyéb információs szolgáltatások',
  financeInsurance: 'Pénzügy és biztosítás',
  monetaryAuthorities: 'Pénzügyi hatóságok-központi bank',
  creditIntermediation: 'Hitelközvetítés és kapcsolódó tevékenységek',
  banking: 'Bank',
  securitiesContractsInvestments: 'Értékpapírok, áruszerződések, befektetések',
  financialServicesNoBanking: 'Pénzügyi szolgáltatások (bankon kívüli)',
  insuranceCarriersRelated: 'Biztosítási fuvarozók és a kapcsolódó tevékenységek',
  insurance: 'Biztosítás',
  fundsTrustsFinancialVehicles: 'Alapok, trösztök és egyéb pénzügyi eszközök',
  realEstate: 'Ingatlan',
  rentalLeasingServices: 'Bérleti és lízingszolgáltatások',
  automotive: 'Autóipar',
  consumerGoods: 'Fogyasztási cikkek',
  lessorsNonfinancialIntangible: 'Nem pénzügyi immateriális javak lízingbeadói',
  professionalTechnicalServices: 'Szakmai és műszaki szolgáltatások',
  legalServices: 'Jogi szolgáltatások',
  accounting: 'Könyvelés',
  architectureEngineering: 'Építészet / Gépipar',
  specializedDesignServices: 'Speciális dizájn szolgáltatások',
  compRelatedServices: 'Számítógéppel kapcsolatos szolgáltatások',
  mgmtConsulting: 'Vezetési tanácsadás',
  scientificResearch: 'Tudományos kutatás',
  adsMarketingPR: 'Hirdetés/Marketing/PR',
  otherProfSciTechServices: 'Egyéb szakmai, tudományos és műszaki szolgáltatások',
  companyEnterpriseMgmt: 'Vállalatok és vállalkozások irányítása, menedzsmentje',
  adminSupportServices: 'Adminisztratív és támogató szolgáltatások',
  employmentAgencyRecruiters: 'Munkaerő foglalkoztatási ügynökség/toborzók',
  securityPrivateInvestigation: 'Biztonság/Magán nyomozás',
  wasteMgmtRemediationServices: 'Hulladékkezelési és kármentesítési szolgáltatások',
  educationServices: 'Oktatási szolgáltatások',
  education: 'Oktatás',
  ambulatoryHealthCareServices: 'Ambuláns egészségügyi szolgáltatások',
  healthCareRelated: 'Egészségügyhöz kapcsolódó szolgáltatások',
  hospitals: 'Kórházak',
  nursingResidentialCareFacilities: 'Ápolási és bentlakásos gondozási intézmények',
  socialAssistance: 'Szociális támogatás',
  performingArtsSpectatorSports: 'Előadóművészet és látvány sportok',
  museumsHistoricalSitesZoosParks: 'Múzeumok, történelmi helyszínek, állatkertek és parkok',
  fineArtsGalleriesMuseums: 'Képzőművészet (galériák, múzeumok)',
  amusementsGamblingRec: 'Szórakoztatás, szerencsejáték és szabadidős tevékenységek',
  accommodation: 'Szállás',
  foodDrinkingServices: 'Élelmiszeri szolgáltatások és szórakozóhelyek',
  restaurantHotel: 'Étterem/Hotel',
  repairMaintenance: 'Javítás és karbantartás',
  industrialProductsHeavy: 'Ipari termékek (nehézipar)',
  personalLaundryServices: 'Személyes és mosodai szolgáltatások',
  membershipAssocOrgs: 'Tagsági egyesületek és szervezetek',
  nonprofit: 'Non-profit',
  privateHouseholds: 'Magánháztartások',
  execLegisGenGvt: 'Végrehajtó, törvényhozó és államháztartás',
  gvt: 'Kormány',
  justicePublicOrderSafetyActivities: 'Igazságszolgáltatási, közrendi és biztonsági tevékenységek',
  hrAdminPrograms: 'Humán erőforrás programok adminisztrációja',
  environmentAdminPrograms: 'Környezetvédelmi programok adminisztrációja',
  environmental: 'Környezeti',
  communityHousingProgramAdmin: 'Közösségi és lakhatási programok adminisztrációja',
  econProgramAdmin: 'Gazdasági programok igazgatása',
  spaceResearchTech: 'Űrkutatás és technológia',
  natlSecurityIntlAffairs: 'Nemzetbiztonsági és nemzetközi ügyek'

};
