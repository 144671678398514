module.exports = {

  // AboutPageHero
  aboutPageHeroImgAlt: 'Ilustrácia ľudí, ktorí sa sťahujú do kancelárie na stránke About Page of Spacer od CBRE',
  aboutPageHeroTitle: 'Čo pre vás môže urobiť Spacer?',

  // AboutPageContent
  aboutPageContentEndSectionTitle: 'Ste pripravení začať?',
  aboutPageProgramPreviewImgAlt: 'Ukážka programu stránky s výsledkami z domovskej stránky programu Spacer od CBRE',
  aboutPageVideoTitle: 'Ako to funguje',


  // About Page Content (aboutPageContent.js)
  aboutPageContent1Title: 'Definujte vašu dokonalú kanceláriu',
  aboutPageContent1Text: 'Perfektné kancelárske priestory vám môžu pomôcť zamestnať talentovaných ľudí, ochrániť zdravie vašich zamestnancov, znížiť prevádzkové náklady alebo vytvoriť podmienky pre ďalší veľký nápad. Spacer je dokonalým miestom na začapočatie každého rozhodnutia v oblasti nehnuteľností, pretože vám pomáha uprednostniť vaše ciele  a na základe týchto cieľov vytvorí odporúčania týkajúce sa pracovného priestoru. Spacer bol vytvorený na základe odborných znalostí tímu CBRE Workplace, skupiny s viac ako dvadsiatimi rokmi skúseností, ktorá pomáha firmám navrhovať dokonalé kancelárie a kombinuje ich s bezplatnou internetovou technológiou dostupnou pre všetkých.',
  aboutPageContent1ImgAlt: 'Ilustrácia ľudí rozprávajúcich sa v O stránke Spacer od CBRE',
  aboutPageSpacerSupportLink: 'spacersupport@cbre.com',

  aboutPageContent2Title: 'Je to veľmi jednoduché',
  aboutPageContent2List1: 'Začnite tým, že odpoviete na 17 dotazníkových otázok týkajúcich sa vašich organizačných cieľov a hodnôt. Tieto odpovede budú formovať profil vašej ideálnej kancelárie.',
  aboutPageContent2List2: 'Získate zoznam odporúčaných typov a veľkostí priestorov, ktoré zodpovedajú vašim vlastným potrebám.',
  aboutPageContent2List3: 'Vizualizujte si priestor podobný tomu vášmu v 2D a 3D pomocou aplikácie Plans.',
  aboutPageContent2List4: 'Uložte si svoj program na použitie pri hľadaní dokonalej kancelárie na trhu.',
  aboutPageContent2List5: 'Nechajte profesionálov z CBRE, aby vyhľadali kancelársky priestor zodpovedajúci vášmu programu - bezplatne!',
  aboutPageContent2Text: 'Ak máte ďalšie otázky ako urýchliť hľadanie kancelárskych priestorov prostredníctvom programu Spacer kontaktujte nás na adrese',
  aboutPageContent2ImgAlt: 'Ilustrácia dievčaťa pracujúceho za stolom na stránke Spacer od CBRE'
};
