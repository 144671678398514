const { connect }            = require('react-redux');
const { bindActionCreators } = require('redux');
const constants              = require('wp-constants').spacerFree;
const _                      = require('lodash');
const unitsUtil              = require('../../../../../shared/util/units');
const spaceDataActions       = require('../../../../redux/actions/spaceData');
const changeDetectedActions  = require('../../../../redux/actions/changeDetected');
const RadioInput             = require('../../../form/RadioInput.jsx');

const SF_TYPE = constants.calculator.SF_TYPE;

// Map state to props
function mapStateToProps(state, ownProps) {
  const unit = ownProps.t(`common:${unitsUtil.getTokenKeyForMeasurementSystem('feet', state.spaceData.measurementSystem)}`);
  const unitUppercase = _.capitalize(unit);

  const usableAreaTooltipText = ownProps.t('sfRadioInputUsableAreaTooltipText');
  const rentableAreaTooltipText = ownProps.t('sfRadioInputRentableAreaTooltipText', { unitUppercase, unit });

  return {
    value: state.spaceData.sfType,
    name: 'sftype',
    options: [
      { value: SF_TYPE.USF, text: ownProps.t('sfRadioInputUsableSquareText', { unitUppercase }), tooltipText: usableAreaTooltipText },
      { value: SF_TYPE.RSF, text: ownProps.t('sfRadioInputRentableSquareText', { unitUppercase }), tooltipText: rentableAreaTooltipText }
    ]
  };
}

// Map dispatch to props
function mapDispatchToProps(dispatch) {
  const updateSFType = bindActionCreators(spaceDataActions.updateSFType, dispatch);
  const detectChangeToSaveOnResults = bindActionCreators(changeDetectedActions.detectChangeToSaveOnResults, dispatch);

  return {
    onChange: (type) => {
      updateSFType(type);
      detectChangeToSaveOnResults();
    }
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(RadioInput);
