module.exports = {

  // LandingPageHero
  landingPageHeroTitle: 'Bestimmen Sie den Flächenbedarf Ihrer künftigen Arbeitsumgebung.',
  landingPageHeroSubtitle: 'Die Arbeitswelt verändert sich kontinuierlich. Mit unserem kostenlosen Tool ermitteln Sie in wenigen Minuten ihr individuelles Flächenprogramm.',
  // landingPageHeroImgAlt: no translation yet
  landingPageImage: '/png/homepage-results-page-in-browser-spacer-by-cbre.png',

  // LandingPageContent
  landingPageContentTitle: 'Und so funktioniert´s',

  // StepsSummary
  stepsSummarySubtitle1: 'Schritt 1 -',
  stepsSummaryTitle1: 'Erzählen Sie uns von Ihrem Unternehmen',
  stepsSummaryText1: 'Helfen Sie uns Aufgaben und Ziele Ihres Unternehmens zu verstehen, damit wir Sie optimal beraten können.',
  // stepsSummaryImgAlt1: no translation yet

  stepsSummarySubtitle2: 'Schritt 2 -',
  stepsSummaryTitle2: 'Zu Ihren Ergebnissen',
  stepsSummaryText2: 'Basierend auf Ihren Eingaben, erhalten Sie eine empfohlene Flächenangabe sowie eine Aufstellung passender Raumtypen.',
  // stepsSummaryImgAlt2: no translation yet

  stepsSummarySubtitle3: 'Schritt 3 -',
  stepsSummaryTitle3: 'Finden Sie Ihren perfekten Bürotyp',
  stepsSummaryText3: 'Speichern Sie Ihre Ergebnisse, um darauf zurückgreifen zu können. Gerne können Sie das CBRE-Team kontaktieren, damit unsere Workplace-Spezialisten Sie bei der Suche nach Ihrem perfekten Büro unterstützen können.',
  // stepsSummaryImgAlt3: no translation yet

  // LandingPageSecondaryHero
  // landingPageSecondaryHeroImgAlt: no translation yet
  landingPageSecondaryHeroTitle: 'Erzählen Sie uns ein wenig über Ihr Unternehmen und wir sagen Ihnen, wie viel Fläche Sie benötigen.'

};
