module.exports = {

  // Navbar
  spacerLogoAlt: 'Logo Spacer by CBRE',
  linkToAboutPageText: 'À propos',
  linkToBlogPageText: 'Idées',

  // TakeOfficeSpaceQuizButton
  sizeMyOfficeButtonDefaultLinkText: 'Quiz',
  flooredPlanRequestLinkText: 'Obtenez un Plan',

  // Footer
  footerCBRELogoAlt: 'Logo CBRE pour Spacer by CBRE',
  footerFacebookIconAlt: 'Icon Facebook pour Spacer by CBRE',
  footerTwitterIconAlt: 'Icon Twitter pour Spacer by CBRE',
  footerLinkedinIconAlt: 'Icon LinkedIn pour Spacer by CBRE',
  footerHeaderQuestionsText: 'Des questions ?',
  footerHeaderAboutText: 'A propos de Spacer',
  footerHeaderMoreText: 'Plus',
  footerLinkBlogText: 'Aperçu',
  footerLinkWhatIsSpacerText: 'A propos',
  footerLinkWorkplaceText: 'Qu\'est-ce que Design & Project?',
  footerLinkTermsText: 'Conditions d\'utilisation',
  footerLinkPrivacyText: 'Politique de confidentialité',
  footerLinkedinLink: 'https://www.linkedin.com/showcase/cbre-workplace-strategy/',
  footerWorkplaceLink: 'http://www.cbre.us/real-estate-services/occupier/workplace',
  footerPrivacyLink: 'https://www.cbre.fr/fr-fr/a-propos-de-cbre/mention-legales#policy',

  // Contact (contact.js)
  spacerSupportEmail: 'spacersupport@cbre.com',

  // ErrorPage
  errorOopsText: 'Ooups',
  error404Text: 'Cette page n\'existe pas ou n\'existe plus. Nous vous prions de nous excuser pour la gêne occasionnée.',
  error500Text: 'Une erreur s\'est produite…',
  errorText: 'Erreur',
  errorReturnText: 'Revenir à Spacer',
  errorAltText: 'Illustration d\'une tasse de café renversée pour Spacer by CBRE',

  // Measurement Units (units.js)
  SF: 'SF',
  SM: 'm²',
  USF: 'USF',
  USM: 'm² utiles',
  RSF: 'RSF',
  RSM: 'm² louables',
  NSF: 'NSF',
  NSM: 'm² nets',
  NIA: 'NIA',
  foot: 'pied',
  feet: 'pieds',
  footage: 'métrage',
  'sqft.': 'pieds carrés',
  meter: 'mètre',
  meters: 'mètres',
  'sqm.': 'm²',
  Tsubo: 'tsubo',

  // SF Types Adjectives (calculator.js)
  Usable: 'Utile',
  Rentable: 'Louable',
  Net: 'Net',

  // documentDataCopy needs translation: page titles and metatag texts
  landingPageTitle: 'Configurez votre espace en 15 minutes - Spacer by CBRE',
  aboutPageTitle: 'Comprenez comment notre outil fonctionne - Spacer by CBRE',
  termsPageTitle: 'Conditions d\'utilisation - Spacer by CBRE',
  quizPageTitle: 'Définissez la taille de votre espace en 15 minutes\' - Spacer by CBRE',
  resultsPageTitle: 'Visualisez nos recommandations pour votre espace - Spacer by CBRE',
  profileComparisonPageTitle: 'Comparez vos besoins d\'espace - Spacer by CBRE',
  // blogHomePageTitle: no translation yet,

  // Meta Tags (documentDataCopy.js)
  landingPageMetaTagText: 'Vous réfléchissez à vos prochains bureaux ? Laissez Spacer by CBRE vous aider à déterminer rapidement et facilement la surface dont vous avez besoin pour atteindre vos objectifs Business. Cela ne prend que 15 minutes !',
  aboutPageMetaTagText: 'La taille de vos bureaux peut vous aider à attirer les talents, réduire les coûts, et même maintenir vos salariés en bonne santé ! Voyez comment Spacer peut vous conseiller rapidement la taille de bureaux qui vous convient pour atteindre vos objectifs.',
  termsPageMetaTagText: 'Plus de 10 ans d\'expérience et d\'expertise industrielles ont été incorporées à Spacer by CBRE afin de produire de vraies recommandations de tailles de bureaux en quelques minutes.',
  quizPageMetaTagText: 'Déroulez le questionnaire Spacer en seulement 15 minutes pour connaître l\'espace de bureaux dont vous avez besoin conformément aux priorités et aux objectifs de votre entreprise. Obtenir une réponse personnalisée n\'a jamais été aussi rapide !',
  resultsPageMetaTagText: 'Les résultats personnalisés fournis par Spacer vous font économiser du temps en vous indiquant la taille exacte de bureaux dont votre société a besoin, agrémentée d\'une proposition de mobiliers et de plans d\'aménagements.',
  profileComparisonPageMetaTagText: 'Appréhendez comment vos propres besoins peuvent se comparer à d\'autres en utilisant Spacer by CBRE.'
  // blogHomePageMetaTagText: no translation yet

};
