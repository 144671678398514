module.exports = {

  // Quiz
  exitQuizButtonTitle: 'Salir del cuestionario',

  // QuizProgressLabel
  progressLabelText: 'completar',

  // QuizNavigationButton
  quizNavBackButtonShortenedText: 'Anterior',
  quizNavBackButtonFullLengthText: 'Pregunta anterior',
  quizNavNextButtonShortenedText: 'Siguiente',
  quizNavNextButtonFullLengthText: 'Siguiente pregunta',

  // QuizExitSlide
  quizExitSubtitle: 'Estamos usando sus respuestas para definir el espacio perfecto de trabajo para su compañía',
  quizExitImgAlt: 'Ilustración de dos personas chocando los cinco en la página del cuestionario de Spacer de CBRE',

  // Question Unit Text (questionUnitMap.js)
  question1Text: 'Para empezar, ¿cómo describiría el propósito de su futura oficina?',
  question2Text: '¿Qué puede hacer Spacer por usted?',
  question3Text: '¿Qué es lo más importante que buscan cuando contratan nuevos miembros del equipo?',
  question4Text: '¿Cómo se ve en 5 años?',
  question5Text: '¿Cómo es el horario para sus empleados?',
  question6Text: '¿Qué piensa de los escritorios individuales?',
  question7Text: '¿Cómo gasta el tiempo la gente cuándo está en la oficina?',
  question8Text: '¿Cómo se viste la gente en tu oficina?',
  question9Text: '¿Dónde pasará su trabajo?',
  question10Text: '¿Cómo debería ser la atmósfera en su oficina?',
  question11Text: '¿Qué provee en su oficina además de espacios de trabajo?',
  question12Text: '¿Cuál de estos lo describe mejor?',

  // Quiz Answers (questionUnitMap.js)
  pickOnePlaceholder: 'Seleccione uno',
  selectFromListPlaceholder: 'Seleccione de la lista',
  firstResponseTooltipText: 'Seleccione su primera respuesta',
  locatedSomewhereElseLabel: 'Actualmente estoy ubicado en otro lugar',

  q1AnswerText1: 'Pensamos en nuestra oficina primero cómo',
  q1AnswerText2: 'Segundo como',
  q1AnswerText3: 'y tercero como',
  q1Option1Text: 'Un segundo hogar para nuestra gente',
  q1Option2Text: 'Un impulsor de cambio organizacional',
  q1Option3Text: 'Un lugar para trabajo de concentración',
  q1Option4Text: 'Un incubador para la siguiente gran idea',
  q1Option5Text: 'Una herramienta para adquirir gran talento',
  q1Option6Text: 'Una vitrina para nuestra marca',
  q1Option7Text: 'Un espacio para crecer y aprender',
  q1Option8Text: 'Un lugar para que la gente se conozca y se reúna',
  q1Option9Text: 'Un costo necesario que intentamos minimizar',

  q2AnswerText1: 'La gente se une a nosotros primero por nuestra',
  q2AnswerText2: 'segundo por nuestra',
  q2AnswerText3: 'y tercero por nuestra',
  q2Option1Text: 'Cultura organizacional',
  q2Option2Text: 'Salarios competitivos',
  q2Option3Text: 'Reputación de compañía',
  q2Option4Text: 'Beneficios y ventajas',
  q2Option5Text: 'Horarios de trabajo flexibles',
  q2Option6Text: 'Gente y liderazgo',
  q2Option7Text: 'Ambiente de oficina',
  q2Option8Text: 'Propósito y misión',

  q3AnswerText1: 'Nosotros contratamos empleados primero por',
  q3AnswerText2: 'Segundo por',
  q3AnswerText3: 'y tercero por',
  q3Option1Text: 'Habilidades',
  q3Option2Text: 'Experiencia',
  q3Option3Text: 'Cultural Fit',
  q3Option4Text: 'Credenciales',
  q3Option5Text: 'Relaciones',
  q3Option6Text: 'Curiosidad',

  q4AnswerText: 'Nosotros',
  q4Option1Text: 'Creceremos un poco cada año',
  q4Option2Text: 'Creceremos significantemente cada año',
  q4Option3Text: 'permaneceremos igual',
  q4Option4Text: 'Encogeremos un poco cada año',
  q4Option5Text: 'Difícil de decir, impredecible',

  q5AnswerText: 'Nosotros estamos trabajando generalmente',
  q5Option1Text: 'De 9 a 5',
  q5Option2Text: 'Desde la mañana hasta … Tarde',
  q5Option3Text: 'Cuando quieran, siempre que sea cierta cantidad',
  q5Option4Text: 'Cuando quiera, tanto como quieran',

  q6AnswerText: 'Nosotros pensamos',
  q6Option1Text: 'Todo el mundo debería tener el suyo',
  q6Option2Text: 'Puede ser mejor compartir escritorios sin asignación fija',

  q7Option1Text: 'Siempre desde un escritorio individual',
  q7Option2Text: 'Principalmente desde un escritorio individual con algunas reuniones y otras actividades',
  q7Option3Text: 'Incluso dividido entre un escritorio y otro lugar de la oficina',
  q7Option4Text: 'Principalmente moviéndose de espacio en espacio con paradas en escritorios',
  q7Option5Text: 'Siempre moviéndose de espacio en espacio',

  q8Option1Text: 'Profesional de negocios',
  q8Option2Text: 'Principalmente profesional excepto por algunas ocasiones ',
  q8Option3Text: 'Informal de negocios, con algunos días elegante otros no',
  q8Option4Text: 'La mayoría casual con algunas ocasiones especiales',
  q8Option5Text: 'Casual',

  q9Option1Text: 'Siempre desde la oficina',
  q9Option2Text: 'Casi siempre desde la oficina',
  q9Option3Text: 'Divido entre la oficina y remoto',
  q9Option4Text: 'La mayoría remoto',
  q9Option5Text: 'Siempre remoto',

  q10Option1Text: 'Dinámico y energético',
  q10Option2Text: 'Energético con algunos momentos de silencios',
  q10Option3Text: 'Depende, una mezcla ambas',
  q10Option4Text: 'Tranquilo, con algunos momentos dinámicos',
  q10Option5Text: 'Tranquilo',

  q11Option1Text: 'Sólo lo básico, suficiente para que la gente continue',
  q11Option2Text: 'Lo básico con algunas mejoras',
  q11Option3Text: 'Lo básico y un lugar donde la gente pueda socializar',
  q11Option4Text: 'Algo muy lindo, una oficina como destino',
  q11Option5Text: 'Algo lindo y diferenciador, la primera cosa que le muestro a un visitante',

  q12AnswerText: 'I’m',
  q12OptionClientText: 'Un cliente existente de CBRE',
  q12OptionProfessionalText: 'Un profesional de CBRE',
  q12OptionLeadText: 'Alguien más',

  q13AnswerText1: 'Nosotros tendremos',
  q13AnswerText2: 'Empleados en nuestra oficina',
  q13AnswerHelperText: 'Este es usualmente el punto medio de tu contrato o más o menos 2 a 5 años desde hoy',

  q14AnswerText1: 'Nosotros gastaremos',
  q14AnswerText2: 'Nuestro tiempo trabajando en equipos',

  q15AnswerText1: 'En promedio planeamos gastar',
  q15AnswerText2: 'Días por semana en la oficina',

  q16AnswerText1: 'Nuestra oficina estará en la ciudad de ',
  q16AnswerText2: '.',

  q17AnswerText1: 'Nosotros trabajamos principalmente en el',
  q17AnswerText2: 'sector.',

  q18AnswerText1: 'Actualmente estoy ubicado en',
  q18AnswerText2: '.',

  q19AnswerText1: 'Imaginamos que nuestra densidad de oficina será (SF por persona)',
  q19AnswerText2: 'en el futuro.',

  // Quiz Responses (questionUnitMap.js)
  q1ResponseText: 'Definiremos una oficina que se ajuste a su visión.',
  q2ResponseText: '¡Combinación atractiva!',
  q3ResponseText: 'Suena bien, ¿dónde aplicamos?',
  q4ResponseText: '¡Genial! Nos aseguraremos de que la oficina se adapte.',
  q5Response1Text: '¡Suena bien!',
  q5Response2Text: 'Tendemos a trabajar horas extra también.',
  q5Response3Text: '¡Suena genial!',
  q5Response4Text: '¡Suena asombroso!',
  q6Response1Text: 'Definiremos un espacio que priorice el espacio personal.',
  q6Response2Text: 'Definiremos un espacio que sea flexible.',
  q12ResponseLeadText: 'Vino al lugar correcto.',
  q12ResponseClientText: '¡Perfecto!',
  q12ResponseProfessionalText: '¡Gracias!',

  // Industry Codes (industryCodes.js)
  animalProd: 'Producción animal y acuicultura',
  forestry: 'Silvicultura y explotación forestal',
  fishingHunting: 'Pesca, caza y captura',
  agForestSupport: 'Actividades de apoyo a la agricultura y la silvicultura',
  oilGas: 'Extracción de gas y aceite',
  energy: 'Energía (gas, aceite, etc.)',
  mining: 'Minería, excepto petróleo y gas',
  supportMining: 'Actividades de apoyo a la minería',
  utilities: 'Servicios públicos',
  buildingConstruct: 'Construcción de edificios',
  heavyCivilConstruct: 'Construcción pesada y de ingeniería civil',
  specialtyContract: 'Contratistas comerciales especializados',
  foodManufac: 'Fabricación de alimentos',
  beverageTobaccoManufac: 'Manufactura de bebidas y productos de tabaco',
  textile: 'Manifactura textil',
  textileProduct: 'Fábricas de productos textiles',
  apparelManufac: 'Manufactura de prendas de vestir',
  leatherManufac: 'Manufactura de cuero y productos afines',
  woodManufac: 'Manufactura de productos de madera',
  paperManufac: 'Manufactura de papel',
  printingSupport: 'Impresión y actividades de apoyo relacionadas',
  printingReproduc: 'Impresión/Reproducciones',
  petroleumCoal: 'Fabricación de productos derivados del petróleo y carbón',
  chemicalManufac: 'Fabricación de productos químicos',
  chemicals: 'Químicos',
  pharma: 'Farmacéuticas',
  cleaningManufac: 'Fabricación de limpieza',
  plasticsRubberManufac: 'Fabricación de plásticos y productos de caucho',
  nonmetallicManufac: 'Fabricación de productos minerales no metálicos',
  primaryMetalManufac: 'Fabricación de metales primarios',
  fabricatedMetalManufac: 'Fabricación de productos metálicos',
  machineryManufac: 'Fabricación de maquinaria',
  manufac: 'Fabricación',
  computerElectronicManufac: 'Fabricación de productos informáticos y electrónicos',
  electricalApplianceManufac: 'Equipos eléctricos y electrodomésticos mfg.',
  transportEquipManufac: 'Fabricación de equipos de transporte',
  aerospaceDefense: 'Aeroespacial/Defensa',
  furnitureManufac: 'Fabricación de muebles y productos relacionados',
  miscManufac: 'Fabricación diversa',
  merchantWholesaleDurable: 'Comerciantes mayoristas, bienes duraderos',
  merchantWholesaleNondurable: 'Comerciantes mayoristas, bienes no duraderos',
  electronicMarket: 'Mercados electrónicos y agentes y brókeres',
  motorVehiclePartsDealers: 'Concesionarios de vehículos de motor y piezas',
  furnitureFurnishingStores: 'Tiendas de muebles y muebles para el hogar',
  electronicApplianceStores: 'Tiendas de electrónicos y electrodomésticos',
  electronics: 'Electrónicos',
  buildingGardenSupplyStores: 'Tiendas de materiales de construcción y suministros de jardinería',
  foodBeverageStores: 'Tiendas de alimentos y bebidas',
  groceryStores: 'Supermercados',
  beerWineLiquorStores: 'Tiendas de cerveza, vino y licores',
  healthCareStores: 'Tiendas de salud y cuidado personal',
  gasStations: 'Estaciones de gasolina',
  clothingAccessoriesStores: 'Tiendas de ropa y accesorios de ropa',
  clothingTextiles: 'Prendas de vestir/Textiles',
  jewelryFurs: 'Joyería y pieles',
  sportsHobbyMusicBookStores: 'Deportes, hobby, instrumentos musicales, librerías',
  genMerchandiseStores: 'Tiendas de mercancía general',
  miscStoreRetailers: 'Tiendas misceláneas',
  officeProducts: 'Productos de Office',
  retailSales: 'Ventas minoristas',
  nonstoreRetails: 'Minoristas que no son tiendas',
  airTransport: 'Transporte aéreo',
  travelRelated: 'Relacionados con viajes (aerolíneas, agentes, transporte público)',
  railTransport: 'Transporte ferroviario',
  waterTransport: 'Transporte acuático',
  truckTransport: 'Transporte en camión',
  transitTransport: 'Tránsito y transporte terrestre de pasajeros',
  pipelineTransport: 'Transporte por tuberías',
  scenicTransport: 'Transporte panorámico y turístico',
  supportTransport: 'Actividades de apoyo para el transporte',
  postalService: 'Servicio postal',
  courierMessenger: 'Mensajeros y mensajeros',
  shippingDelivery: 'Envío/Entrega',
  warehousingStorage: 'Almacenamiento y almacenamiento',
  warehouseDistrib: 'Almacén/Distribución',
  publishingNoInternet: 'Industrias editoriales, excepto Internet',
  publishing: 'Editorial',
  motionPicSoundRecordingIndustries: 'Industrias cinematográficas y de grabación de sonido',
  entertainment: 'Entretenimiento (Cine, TV, Música, etc.)',
  infoServicesMarketResearch: 'Servicios de Información/Investigación de Mercado',
  broadcastingNoInternet: 'Radiodifusión, excepto Internet',
  telecom: 'Telecomunicaciones',
  tech: 'Tecnología',
  dataProcessingHostingServices: 'Procesamientos y alojamiento de datos, servicios relacionados',
  otherInfoServices: 'Otros servicios de información',
  financeInsurance: 'Financieros y seguros',
  monetaryAuthorities: 'Autoridades monetarias-bancos centrales',
  creditIntermediation: 'Intermediación financiera y actividades relacionada',
  banking: 'Bancos',
  securitiesContractsInvestments: 'Seguridad, contratos de bienes, inversiones',
  financialServicesNoBanking: 'Servicios Financieros (no bancarios)',
  insuranceCarriersRelated: 'Compañías de seguros y actividades relacionadas',
  insurance: 'Seguros',
  fundsTrustsFinancialVehicles: 'Fondos, fideicomisos y otros vehículos financieros',
  realEstate: 'Inmobiliario',
  rentalLeasingServices: 'Servicios de arrendamiento y leasing',
  automotive: 'Automotriz',
  consumerGoods: 'Bienes de consumo',
  lessorsNonfinancialIntangible: 'Arrendadores de bienes intangibles no financieros',
  professionalTechnicalServices: 'Servicios profesionales y técnicos',
  legalServices: 'Servicios legales',
  accounting: 'Contabilidad',
  architectureEngineering: 'Arquitectura/Ingeniería',
  specializedDesignServices: 'Servicios especializados de diseño',
  compRelatedServices: 'Servicios relacionados con la computación',
  mgmtConsulting: 'Consultoría de gestión',
  scientificResearch: 'Investigación científica',
  adsMarketingPR: 'Publicidad/Mercadeo/Relaciones Públicas',
  otherProfSciTechServices: 'Otros servicios profesionales, científicos y técnicos',
  companyEnterpriseMgmt: 'Gestión de empresas y corporaciones',
  adminSupportServices: 'Servicios de soporte administrativo',
  employmentAgencyRecruiters: 'Agencia de empleo/Reclutadores',
  securityPrivateInvestigation: 'Seguridad/Investigación privada',
  wasteMgmtRemediationServices: 'Servicios de gestión de residuos y remediación',
  educationServices: 'Servicios educativos',
  education: 'Educación',
  ambulatoryHealthCareServices: 'Servicios ambulatorios de salud',
  healthCareRelated: 'Salud',
  hospitals: 'Hospitales',
  nursingResidentialCareFacilities: 'Establecimientos de cuidados especializados de enfermería',
  socialAssistance: 'Asistencia social',
  performingArtsSpectatorSports: 'Artes escénicas y deportes para espectadores',
  museumsHistoricalSitesZoosParks: 'Museos, sitios históricos, zoológicos y parques',
  fineArtsGalleriesMuseums: 'Bellas artes (galerías, museos)',
  amusementsGamblingRec: 'Diversiones, juegos de azar y recreación',
  accommodation: 'Alojamiento',
  foodDrinkingServices: 'Servicios de comida y bares',
  restaurantHotel: 'Restaurante/Hotel',
  repairMaintenance: 'Reparación y mantenimientos',
  industrialProductsHeavy: 'Productos industriales (industria pesada)',
  personalLaundryServices: 'Servicios personales y de lavandería',
  membershipAssocOrgs: 'Miembros de asociaciones y organizaciones',
  nonprofit: 'Sin ánimo de lucro',
  privateHouseholds: 'Servicios domésticos',
  execLegisGenGvt: 'Ejecutivo, legislativo y gubernamental',
  gvt: 'Gobierno',
  justicePublicOrderSafetyActivities: 'Actividades de justicia, orden público y seguridad',
  hrAdminPrograms: 'Administración de programas de recursos humanos',
  environmentAdminPrograms: 'Administración de programas medioambientales',
  environmental: 'Medioambiente',
  communityHousingProgramAdmin: 'Administración de programas comunitarios y de vivienda',
  econProgramAdmin: 'Administración de programas económicos',
  spaceResearchTech: 'Investigación y tecnología espacial',
  natlSecurityIntlAffairs: 'Seguridad nacional y relaciones internacionales'

};
