const React                          = require('react');
const PropTypes                      = require('prop-types');
const { withTranslation }             = require('react-i18next');
const Link                           = require('react-router-dom').Link;
const HeroSection                    = require('../common/HeroSection.jsx');
const ReferralLinkInformation        = require('./ReferralLinkInformation.jsx');
const ReferralLinkSignUpForm         = require('./ReferralLinkSignUpForm.jsx');
const ReferralLinkConfirmInformation = require('./ReferralLinkConfirmInformation.jsx');
const GetReferralLink                = require('./GetReferralLink.jsx');
const { createSource }               = require('../../util/dbServices');
const constants                      = require('wp-constants').spacerFree;
const BrokerReferralWrapper          = require('./BrokerReferralWrapper.jsx');

const STEPS = {
  REFERRAL_LINK_INFORMATION: 1,
  SIGN_UP_FORM: 2,
  CONFIRM_INFORMATION: 3,
  GET_REFERRAL_LINK: 4
};

function BrokerReferralSignUpForm(props) {
  const [step, setStep] = React.useState(1);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [link, setLink] = React.useState('');
  const [submitError, setSubmitError] = React.useState(false);

  function goToNextStep() {
    setStep(step + 1);
  }

  function goToPreviousStep() {
    setStep(step - 1);
  }

  function createSourceAndGetReferralLink() {
    createSource(
      { firstName, lastName, email },
      (source) => {
        setLink(source.referralLink);
        props.sendHeapEventWithoutProps('Create referral link');
        setSubmitError(false);
        goToNextStep();
      },
      (err) => {
        console.log('err:', err);
        setSubmitError(true);
      }
    );
  }

  let currentFormView = null;
  switch (step) {
    case STEPS.REFERRAL_LINK_INFORMATION: {
      currentFormView = (
        <ReferralLinkInformation
          goToNextStep={goToNextStep}
        />
      );
      break;
    }

    case STEPS.SIGN_UP_FORM: {
      currentFormView = (
        <ReferralLinkSignUpForm
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          goToNextStep={goToNextStep}
        />
      );
      break;
    }

    case STEPS.CONFIRM_INFORMATION: {
      currentFormView = (
        <ReferralLinkConfirmInformation
          firstName={firstName}
          lastName={lastName}
          email={email}
          goToPreviousStep={goToPreviousStep}
          createSource={createSourceAndGetReferralLink}
          submitError={submitError}
        />
      );
      break;
    }

    case STEPS.GET_REFERRAL_LINK: {
      currentFormView = (<GetReferralLink link={link} getLinkFirstTime />);
      break;
    }

    default: {
      currentFormView = null;
    }
  }

  return (
    <BrokerReferralWrapper siteLocationConstant={constants.siteLocation.PAGE.REFERRAL}>
      <div className="referral-sign-up">
        <HeroSection isLightColor>
          <div className="flex flex-justify-center">
            <div className="referral-link-box">
              <div className="flex flex-justify-between form-header">
                <span className="broker-form-progress" aria-live="polite">{props.t('referralStep', { step })}</span>
                <Link className="link-in-referral-pages" to="/referral/retrieve">{props.t('forgotReferralLink')}</Link>
              </div>
              {currentFormView}
            </div>
          </div>
        </HeroSection>
      </div>
    </BrokerReferralWrapper>
  );
}

BrokerReferralSignUpForm.propTypes = {
  t: PropTypes.func.isRequired,
  sendHeapEventWithoutProps: PropTypes.func.isRequired
};

module.exports = withTranslation('referrals')(BrokerReferralSignUpForm);
