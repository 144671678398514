module.exports = {

  // Quiz
  exitQuizButtonTitle: 'Sortir du quiz',

  // QuizProgressLabel
  progressLabelText: 'Terminé',

  // QuizNavigationButton
  quizNavBackButtonShortenedText: 'Précédent',
  quizNavBackButtonFullLengthText: 'Questions précédente',
  quizNavNextButtonShortenedText: 'Suivant',
  quizNavNextButtonFullLengthText: 'Question suivante',

  // QuizExitSlide
  quizExitSubtitle: 'Nous utilisons maintenant vos réponses pour définir l\'espace idéal pour votre société.',
  quizExitImgAlt: 'Illustration de deux personnes se tapant dans la main sur la page Quiz de Spacer by CBRE',

  // Question Unit Text (questionUnitMap.js)
  question1Text: 'Pour commencer : Quel est le but de votre espace futur?',
  question2Text: 'Qu\'est-ce qui dans votre organisation vous semble le plus attractif pour des candidats?',
  question3Text: 'Qu\'est-ce qui attire le plus votre attention lorsque vous recrutez de nouveaux collaborateurs?',
  question4Text: 'Comment voyez-vous l\'évolution de la taille de votre organisation dans les 5 prochaines années?',
  question5Text: 'Quelle est l\'organisation du temps de travail de vos collaborateurs?',
  question6Text: 'Que pensez-vous des bureaux individuels?',
  question7Text: 'Une fois dans les bureaux, où vos collaborateurs travailleront-ils?',
  question8Text: 'Quel sera le code vestimentaire dans vos espaces de bureaux?',
  question9Text: ' Où votre activité se déroulera elle?',
  question10Text: 'A quoi ressemble l\'ambiance de vos bureaux?',
  question11Text: 'Mis à part les espaces de travail purs, que voulez-vous proposer dans vos locaux?',
  question12Text: 'Qu\'est-ce qui vous décrit le mieux?',

  // Quiz Answers (questionUnitMap.js)
  pickOnePlaceholder: 'Choissisez parmi…',
  selectFromListPlaceholder: 'Selectionnez dans la liste…',
  firstResponseTooltipText: 'Selectionnez votre première réponse',
  locatedSomewhereElseLabel: 'Je suis localisé ailleurs.',

  q1AnswerText1: 'Nous pensons votre espace d\'abord comme',
  q1AnswerText2: 'en deuxième approche comme',
  q1AnswerText3: 'et en troisième comme',
  q1Option1Text: 'un second foyer pour nos collaborateurs',
  q1Option2Text: 'un levier de changement organisationnel',
  q1Option3Text: 'un espace productif et de travail ciblé',
  q1Option4Text: 'un incubateur de votre prochaine grande idée',
  q1Option5Text: 'un moyen de recruter de grands talents',
  q1Option6Text: 'un espace de valorisation de notre marque',
  q1Option7Text: 'un espace d\'épanouissement et d\'apprentissage',
  q1Option8Text: ' un lieu de rencontres et d’échanges',
  q1Option9Text: 'un coût nécessaire que nous tentons de minimiser',

  q2AnswerText1: 'On nous rejoint d\'abord pour',
  q2AnswerText2: 'ensuite pour',
  q2AnswerText3: 'et enfin pour',
  q2Option1Text: 'culture d\'entreprise',
  q2Option2Text: 'salaires motivants',
  q2Option3Text: 'attractivité de l\'entreprise',
  q2Option4Text: 'avantages',
  q2Option5Text: 'temps de travail flexibles',
  q2Option6Text: 'collaborateurs et leadership',
  q2Option7Text: 'environnement de travail',
  q2Option8Text: 'but et mission',

  q3AnswerText1: 'Nous recrutons d\'abord pour',
  q3AnswerText2: 'puis pour',
  q3AnswerText3: 'et enfin pour',
  q3Option1Text: 'compétences',
  q3Option2Text: 'expérience',
  q3Option3Text: 'Adéquation à la culture d\'entreprise',
  q3Option4Text: 'qualifications',
  q3Option5Text: 'relations',
  q3Option6Text: 'curiosité',

  q4AnswerText: 'Nous',
  q4Option1Text: 'prévoyons de croître raisonnablement chaque année',
  q4Option2Text: 'prévoyons de croître significativement chaque année',
  q4Option3Text: 'prévoyons de rester globalement à l\'identique',
  q4Option4Text: 'prévoyons une légère diminution chaque année',
  q4Option5Text: 'Difficile à dire, c\'est incertain',

  q5AnswerText: 'Habituellement nous travaillons',
  q5Option1Text: 'de 9h00 à 17h00',
  q5Option2Text: 'dès le matin, jusque tard dans la journée',
  q5Option3Text: 'Quand ils le souhaitent, pour autant que cela atteigne un certain seuil',
  q5Option4Text: 'Quand ils le souhaitent, aussi souvent qu\'ils le souhaitent',

  q6AnswerText: 'Nous pensons',
  q6Option1Text: 'que chacun devrait avoir un bureau',
  q6Option2Text: 'qu\'il est préférable de fonctionner en bureau partagé',

  q7Option1Text: 'Toujours à un bureau individuel',
  q7Option2Text: 'Toujours à un bureau individuel, et minoritairement en réunion ou sur d\'autres activités',
  q7Option3Text: 'Partagé entre un bureau individuel et ailleurs dans les locaux',
  q7Option4Text: 'Mobile dans les locaux, avec quelques moments à un bureau individuel',
  q7Option5Text: 'Toujours mobile dans les locaux',

  q8Option1Text: 'Professionnel',
  q8Option2Text: 'Professionnel, sauf en certaines occasions',
  q8Option3Text: 'Business casual, parfois plus habillé',
  q8Option4Text: 'Majoritairement décontracté, sauf en certaines occasions',
  q8Option5Text: 'Décontracté',

  q9Option1Text: 'Toujours au bureau',
  q9Option2Text: 'La plupart du temps au bureau',
  q9Option3Text: 'Temps partagé entre bureua et télétravail',
  q9Option4Text: 'Majoritairement en télétravail',
  q9Option5Text: 'Toujours en télétravail',

  q10Option1Text: 'Dynamique et intense',
  q10Option2Text: 'Intense, parfois plus calme',
  q10Option3Text: 'Cela dépend',
  q10Option4Text: 'Calme, avec plus d\'intensité parfois',
  q10Option5Text: 'Calme et tranquille',

  q11Option1Text: 'Standard, suffisant pour le quotidien',
  q11Option2Text: 'Standard, avec quelques aménagements',
  q11Option3Text: 'Standard, avec un espace d\'échange convivial',
  q11Option4Text: 'Quelque chose de vraiment attractif, le lieu où travailler',
  q11Option5Text: 'Quelque chose de cool et différentiant, la première chose à montrer à un visiteur',

  q12AnswerText: 'Je suis',
  q12OptionClientText: 'un collègue de CBRE',
  q12OptionProfessionalText: 'un client de CBRE',
  q12OptionLeadText: 'quelqu\'un d\'autre',

  q13AnswerText1: 'Nous aurons',
  q13AnswerText2: 'des salariés dans nos bureaux.',
  q13AnswerHelperText: 'Astuce : Vous êtes à mi-chemin de la durée de votre bail, ou avec une expiration d\'ici 2 à 5 ans.',

  q14AnswerText1: 'Nous passerons',
  q14AnswerText2: 'de notre temps à travailler en équipe.',

  q15AnswerText1: 'En moyenne, nous prévoyons de passer',
  q15AnswerText2: 'jour(s) par semaine au bureau.',

  q16AnswerText1: 'Nos bureaux seront dans la ville de',
  q16AnswerText2: '.',

  q17AnswerText1: 'Nous travaillons en premier lieu dans',
  q17AnswerText2: 'secteur',

  q18AnswerText1: 'Je suis actuellement basé à',
  q18AnswerText2: '.',

  q19AnswerText1: 'Nous imaginons nos bureaux en termes de densité de personne (mètres carrés par personne) seront',

  q19AnswerText2: 'dans le futur',

  // Quiz Responses (questionUnitMap.js)
  q1ResponseText: 'Nous allons définir l\'espace qui correspond à votre vision.',
  q2ResponseText: 'Un mélange séduisant!',
  q3ResponseText: 'Intéressant - Comment fait-on pour signer?',
  q4ResponseText: 'Parfait ! Nous allons faire en sorte que l\'espace convienne.',
  q5Response1Text: 'Cela semble convenir!',
  q5Response2Text: 'Nous avons tendance à faire des heures supplémentaires.',
  q5Response3Text: 'Cela a l\'air très bien!',
  q5Response4Text: 'Cela a l\'air fantastique!',
  q6Response1Text: 'Nous allons définir un espace qui privilégie les bureaux individuels',
  q6Response2Text: 'Nous allons définir un espace de type Flex',
  q12ResponseLeadText: 'Vous êtes au bon endroit.',
  q12ResponseClientText: 'Parfait!',
  q12ResponseProfessionalText: 'Merci!',

  // Industry Codes (industryCodes.js)
  animalProd: 'Production animale',
  forestry: 'Sylviculture et exploitation forestière',
  fishingHunting: 'Pêche et aquaculture',
  agForestSupport: 'Sylviculture et autres activités forestières',
  oilGas: 'Extration d\'hydrocarbures',
  energy: 'Energie',
  mining: 'Industries extractives',
  supportMining: 'Services de soutien aux industries extractives',
  utilities: 'Services publics',
  buildingConstruct: 'Construction de bâtiments',
  heavyCivilConstruct: 'Génie civil',
  specialtyContract: 'Entrepreneurs spécialisés',
  foodManufac: 'Industries alimentaires',
  beverageTobaccoManufac: 'Fabrication de boissons et de produits à base de tabac',
  textile: 'Fabrication de textiles',
  textileProduct: 'Industrie de l\'habillement',
  apparelManufac: 'Fabrication de vêtements',
  leatherManufac: 'Fabrication de vêtements en cuir',
  woodManufac: 'Fabrication d\'articles en bois',
  paperManufac: 'Industrie du papier et du carton',
  printingSupport: 'Imprimerie',
  printingReproduc: 'Reproduction',
  petroleumCoal: 'Cokéfaction et raffinage',
  chemicalManufac: 'Industrie chimique',
  chemicals: 'Fabrication de produits chimiques',
  pharma: 'Industrie pharmaceutique',
  cleaningManufac: 'Activités de nettoyage',
  plasticsRubberManufac: 'Fabrication de produits en caoutchouc et en plastique',
  nonmetallicManufac: 'Fabrication d\'autres produits minéraux non métalliques',
  primaryMetalManufac: 'Fabrication d\'autres produits de première transformation de l\'acier',
  fabricatedMetalManufac: 'Fabrication de produits métalliques, à l’exception des machines et des équipements',
  machineryManufac: 'Fabrication de machines',
  manufac: 'Fabrication',
  computerElectronicManufac: 'Fabrication de produits informatiques, électroniques et optiques',
  electricalApplianceManufac: 'Fabrication d\'équipements électriques',
  transportEquipManufac: 'Fabrication de matériels de transport',
  aerospaceDefense: 'Aérospatial / Défense',
  furnitureManufac: 'Fabrication de meubles',
  miscManufac: 'Autres industries manufacturières',
  merchantWholesaleDurable: 'Commerces de gros',
  merchantWholesaleNondurable: 'Commerces de gros',
  electronicMarket: 'Commerce de gros d\'équipements de l\'information et de la communication ',
  motorVehiclePartsDealers: 'Commerce et réparation d\'automobiles et de motocycles',
  furnitureFurnishingStores: 'Commerce de détail de meubles, appareils d\'éclairage et autres articles de ménage en magasin spécialisé',
  electronicApplianceStores: 'Commerce de détail d\'équipements de l\'information et de la communication en magasin spécialisé',
  electronics: 'Electronique',
  buildingGardenSupplyStores: 'Commerce de gros de bois, de matériaux de construction et d\'appareils sanitaires / Jardinerie',
  foodBeverageStores: 'Commerce de détail alimentaire en magasin spécialisé',
  groceryStores: 'Epiceries',
  beerWineLiquorStores: 'Commerce de détail de boissons en magasin spécialisé',
  healthCareStores: 'Magasins de produits de santé et soins corporels',
  gasStations: 'Stations services',
  clothingAccessoriesStores: 'Commerce de détail d\'habillement en magasin spécialisé',
  clothingTextiles: 'Textile / Habillement',
  jewelryFurs: 'Joaillerie / Fourrures',
  sportsHobbyMusicBookStores: 'Sports, loisirs créatifs, instruments de musique, librairies',
  genMerchandiseStores: 'Magasins de marchandises diverses',
  miscStoreRetailers: 'Commerces de détail divers',
  officeProducts: 'Articles de bureau',
  retailSales: 'Ventes au détail',
  nonstoreRetails: 'Commerces de détail hors magasin, éventaires ou marchés',
  airTransport: 'Transports aériens',
  travelRelated: 'Transports (Compagnies aériennes, agences de voyages, transports en commun)',
  railTransport: 'Transports ferroviaires',
  waterTransport: 'Transports maritimes',
  truckTransport: 'Transports par camions',
  transitTransport: 'Transports en commun et transports terrestres de voyageurs',
  pipelineTransport: 'Transports par conduites',
  scenicTransport: 'Transports de tourisme et d\'agrément',
  supportTransport: 'Services auxiliaires des transports',
  postalService: 'Activités de poste et de courrier',
  courierMessenger: 'Autres activités de poste et de courrier',
  shippingDelivery: 'Expédition / Livraison',
  warehousingStorage: 'Entreposage et stockage',
  warehouseDistrib: 'Entreposage et services auxiliaires des transports',
  publishingNoInternet: 'Edition (hors internet)',
  publishing: 'Edition',
  motionPicSoundRecordingIndustries: 'Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale',
  entertainment: 'Divertissement (Cinéma, TV, musique, etc…)',
  infoServicesMarketResearch: 'Etudes de marché et sondages',
  broadcastingNoInternet: 'Programmation et diffusion',
  telecom: 'Télécommunications',
  tech: 'Technologies',
  dataProcessingHostingServices: 'Traitement de données, hébergement et activités connexes',
  otherInfoServices: 'Autres activités informatiques',
  financeInsurance: 'Activités financières et d\'assurances',
  monetaryAuthorities: 'Activités de banque centrale',
  creditIntermediation: 'Intermédiation monétaire',
  banking: 'Activité bancaire',
  securitiesContractsInvestments: 'Courtage de valeurs mobilières et de marchandises',
  financialServicesNoBanking: 'Autres activités des services financiers, hors assurance et caisses de retraite',
  insuranceCarriersRelated: 'Activités auxiliaires de services financiers et d\'assurance',
  insurance: 'Assurances',
  fundsTrustsFinancialVehicles: 'Fonds de placement et entités financières similaires',
  realEstate: 'Activités immobilières',
  rentalLeasingServices: 'Location et exploitation de biens immobiliers propres ou loués',
  automotive: 'Industrie automobile',
  consumerGoods: 'Biens de consommation',
  lessorsNonfinancialIntangible: 'Location-bail de propriété intellectuelle et de produits similaires, à l\'exception des œuvres soumises à copyright',
  professionalTechnicalServices: 'Activités spécialisées, scientifiques et techniques',
  legalServices: 'Activités juridiques',
  accounting: 'Activités comptables',
  architectureEngineering: 'Activités d\'architecture et d\'ingénierie',
  specializedDesignServices: 'Activités spécialisées de design',
  compRelatedServices: 'Programmation, conseil et autres activités informatiques',
  mgmtConsulting: 'Consulting en management',
  scientificResearch: 'Recherche-développement scientifique',
  adsMarketingPR: 'Conseil en relations publiques et communication / Publicité',
  otherProfSciTechServices: 'Autres activités spécialisées, scientifiques et techniques',
  companyEnterpriseMgmt: 'Gestion d\'entreprises',
  adminSupportServices: 'Administration publique',
  employmentAgencyRecruiters: 'Activités liées à l\'emploi',
  securityPrivateInvestigation: 'Enquêtes et sécurité',
  wasteMgmtRemediationServices: 'Collecte, traitement et élimination des déchets ; récupération',
  educationServices: 'Enseignement',
  education: 'Enseignement',
  ambulatoryHealthCareServices: 'Activités hospitalières ambulatoires',
  healthCareRelated: 'Autres activités pour la santé humaine',
  hospitals: 'Activités hospitalières',
  nursingResidentialCareFacilities: 'Hébergement médico-social et social',
  socialAssistance: 'Action sociale',
  performingArtsSpectatorSports: 'Arts, spectacles et activités récréatives',
  museumsHistoricalSitesZoosParks: 'Bibliothèques, archives, musées et autres activités culturelles',
  fineArtsGalleriesMuseums: 'Création artistique relevant des arts plastiques',
  amusementsGamblingRec: 'Organisation de jeux de hasard et d\'argent',
  accommodation: 'Hébergement',
  foodDrinkingServices: 'Restauration et débits de boissons',
  restaurantHotel: 'Hôtels et hébergement similaire',
  repairMaintenance: 'Réparation et maintenance',
  industrialProductsHeavy: 'Industrie lourde',
  personalLaundryServices: 'Autres services personnels / Blanchisserie-Teinturerie',
  membershipAssocOrgs: 'Activités des organisations économiques, patronales et professionnelles',
  nonprofit: 'Organisations à but non lucratif',
  privateHouseholds: 'Activités des ménages',
  execLegisGenGvt: 'Administration publique',
  gvt: 'Gouvernement',
  justicePublicOrderSafetyActivities: 'Services de prérogative publique',
  hrAdminPrograms: 'Administration générale, économique et sociale',
  environmentAdminPrograms: 'Administration de programmes environnementaux',
  environmental: 'Ecologie',
  communityHousingProgramAdmin: 'Administration de logements collectifs',
  econProgramAdmin: 'Administration publique (tutelle) des activités économiques',
  spaceResearchTech: 'Recherche spatiale',
  natlSecurityIntlAffairs: 'Sécurité nationale et affaires étrangères'

};
