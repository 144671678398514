module.exports = {

  // Navbar
  spacerLogoAlt: 'Spacer od CBRE',
  linkToAboutPageText: 'O projekte',
  linkToBlogPageText: 'Postrehy',
  referrals: 'Referencie',

  // TakeOfficeSpaceQuizButton
  sizeMyOfficeButtonDefaultLinkText: 'Vyplňte dotazník o kancelárskych priestoroch',
  flooredPlanRequestLinkText: 'Vyžiadať plán',

  // Footer
  footerCBRELogoAlt: 'CBRE logo pre Spacer od CBRE',
  footerFacebookIconAlt: 'Ikona Facebook pre Spacer od CBRE',
  footerTwitterIconAlt: 'TIkona Twitter pre Spacer od CBRE',
  footerLinkedinIconAlt: 'LIkona LinkedIn pre Spacer od CBRE',
  footerLinkedinLink: 'https://www.linkedin.com/showcase/cbre-workplace-strategy/',
  footerHeaderQuestionsText: 'Otázky?',
  footerHeaderAboutText: 'Zdroje',
  footerHeaderMoreText: 'Viac',
  footerHeaderBrokersText: 'Pre maklérov',
  footerLinkWhatIsSpacerText: 'Viac o projekte Spacer',
  footerLinkBlogText: 'Postrehy',
  footerLinkWorkplaceText: 'Čo je CBRE Workplace?',
  footerLinkTermsText: 'Podmienky využívania služieb',
  footerLinkPrivacyText: 'Zásady ochrany osobných údajov',
  footerLinkReferralText: 'Referencie',
  footerWorkplaceLink: 'http://www.cbre.us/real-estate-services/occupier/workplace',
  footerPrivacyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',

  // Contact (contact.js)
  spacerSupportEmail: 'spacersupport@cbre.com',

  // ErrorPage
  errorOopsText: 'Ups!',
  error404Text: 'Nevieme nájsť stránku, ktorú hľadáte.',
  error500Text: 'STáto chyba je na našej strane.',
  errorText: 'Chyba',
  errorReturnText: 'RVrátiť sa na Spacer',
  errorAltText: 'Ilustrácia rozliatej kávy na Spacer od CBRE',

  // Measurement Units (units.js)
  SF: 'ft²',
  SM: 'm²',
  USF: 'ft²',
  USM: 'm²',
  RSF: 'ft²',
  RSM: 'm²',
  NSF: 'Net ft²',
  NSM: 'Net m²',
  NIA: 'N/A',
  foot: 'stopa',
  feet: 'stopy',
  footage: 'ft',
  'sqft.': 'ft²',
  meter: 'm',
  meters: 'm',
  'sqm.': 'm²',
  Tsubo: 'tsubo',

  // SF Types Adjectives (calculator.js)
  Usable: 'Úžitkové',
  Rentable: 'Prenajímateľné',
  Net: 'Net',

  // Page Titles (documentDataCopy.js)
  landingPageTitle: 'Naplánuj si dokonalú kanceláriu za 15 minút - Spacer od CBRE',
  aboutPageTitle: 'Zistite, ako funguje náš nástroj na výpočet veľkosti kancelárie - Spacer od CBRE',
  termsPageTitle: 'Podmienky užívania - Spacer od CBRE',
  quizPageTitle: 'Zistite veľkosť svojej kancelárie za 15 minút - Spacer od CBRE',
  resultsPageTitle: 'Zobraziť návrh vašej novej kancelárie - Spacer od CBRE',
  profileComparisonPageTitle: 'Porovnajte svoje kancelárske potreby - Spacer od CBRE',
  blogHomePageTitle: 'Postrehy z plánovania priestorov - Spacer od CBRE',
  referralPageTitle: 'Referenčný odkaz na CBRE profesionálov - Spacer od CBRE',
  referralRetrievePageTitle: 'Plánujete svoju ďalšiu kanceláriu? Nechajte Spacer od CBRE, aby vám rýchlo a jednoducho určil správnu veľkosť kancelárskeho priestoru potrebného na realizáciu vašich obchodných cieľov. Iba za 15 minút!',

  // Meta Tags (documentDataCopy.js)
  landingPageMetaTagText: 'Plánujete svoju ďalšiu kanceláriu? Nechajte Spacer od CBRE, aby vám rýchlo a jednoducho určil správnu veľkosť kancelárskeho priestoru potrebného na realizáciu vašich obchodných cieľov. Iba za 15 minút!',
  aboutPageMetaTagText: 'Správna veľkosť vašej kancelárie vám môže pomôcť prilákať talentovaných ľudí, znížiť náklady a dokonca ochrániť zdravie vašich zamestnancov. Získajte viac informácií o tom, ako môže Spacer rýchlo odporučiť správnu veľkosť kancelárie na realizáciu cieľov vašej spoločnosti.',
  termsPageMetaTagText: 'Viac ako desať rokov odborných znalostí a skúseností v odbore je obsiahnutých v programe Spacer od CBRE, ktorý v priebehu niekoľkých minút vytvorí informatívne odporúčania týkajúce sa správnej veľkosti kancelárie.',
  quizPageMetaTagText: 'Vyplňte 15-minútový dotazník Spacer a určite, koľko kancelárskeho priestoru potrebujete na realizáciu cieľov a priorít vašej spoločnosti. Prispôsobiteľné programovanie nikdy nebolo také rýchle.',
  resultsPageMetaTagText: 'Výsledky programu Spacer vám ušetria čas pri hľadaní novej kancelárie tak, že vám vypočítajú orientačnú veľkosť kancelárie pre vašu firmu, spolu s navrhovanými typmi nábytku a usporiadaním pôdorysu.',
  profileComparisonPageMetaTagText: 'Vďaka programu Spacer od CBRE pochopíte jedinečnosť vašich kancelárskych potrieb v porovnaní s ostatnými kanceláriami.',
  blogHomePageMetaTagText: 'Výpočet toho, koľko kancelárskeho priestoru vaša spoločnosť potrebuje, nie je žiadna veda, ale tieto poznatky a postrehy môžu pomôcť. Viac informácií nájdete tu.',
  referralPageMetaTagText: 'Pomocou tohto referenčného odkazu môžete odoslať Spacer klientom a zabezpečiť, aby sa ich výsledky odosielali priamo na váš e-mail. Viac informácií nájdete tu.',
  referralRetrieveMetaTagText: 'Získajte svoj referenčný link na Spacer.',

  // Form title
  formLabel: 'Vaše informácie'
};
