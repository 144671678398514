const React = require('react');
const FlooredPlanReqButton = require('../../infoPages/common/FlooredPlanReqButton.jsx');
const { withTranslation } = require('react-i18next');
const PropTypes = require('prop-types');
const Video = require('./Video.jsx')

function FlooredPlanReqSection(props) {
    return (
        <div >
            <div className="flooredPlanrequestSection">
                <div >
                    <div className="flooredPlan-heading">
                        <h2>{props.t('flooredTitle')}</h2>
                    </div>
                    <div className="set-center">
                        <FlooredPlanReqButton />
                    </div>


                    <div className="columns">
                        <div className="column floored">
                            <p className="redefinedText">{props.t('flooredSubTitle')}</p>
                            <p className="subtext"> {props.t('flooredSubText')}</p>
                            <p className="paragraph">{props.t('flooredParagraphText1')} <br /><br /> {props.t('flooredParagraphText2')}
                            </p>
                        </div>

                        <div className="column floored">
                            <figure className="image is-hidden-mobile flooredImage">
                                <img src="/png/Floored_Plans_MacBook_2d-3d.png" alt={props.t('landingPageSecondaryHeroImgAlt')} />
                            </figure>
                        </div>

                    </div>

                    <div className="line-seperator"></div>
                    <div className="sampleText">
                        <p>{props.t('flooredVideoHeading')}</p>
                    </div>
                    <div className="set-center">
                        <Video />
                    </div>


                    <div className="line-seperator"></div>

                    <div className="flooredPlan-heading">
                        <h2>{props.t('flooredFreeText')}</h2>
                    </div>
                    <div className="icons-floored">
                        <div className="columns">
                            <div className="column icon">

                                <img src='/svg/icons/blueprint.svg' alt='' className="floored-icon" />
                                <p className="text-1">{props.t('flooredIconText1')}</p>
                                <p className="text-2">{props.t('flooredIconSubText1')}</p>
                            </div>
                            <div className="column icon">
                                <img src='/svg/icons/adaptation.svg' alt='' className="floored-icon" />
                                <p className="text-1">{props.t('flooredIconText2')}</p>
                                <p className="text-2">{props.t('flooredIconSubText2')}</p>
                            </div>
                            <div className="column icon">
                                <img src='/svg/icons/layers_icon.svg' alt='' className="floored-icon" />
                                <p className="text-1">{props.t('flooredIconText3')}</p>
                                <p className="text-2">{props.t('flooredIconSubText3')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="set-center">
                        <FlooredPlanReqButton />
                    </div>
                </div>
            </div>
        </div>
    );

}
FlooredPlanReqSection.propTypes = {
    t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(FlooredPlanReqSection);