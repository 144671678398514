const React               = require('react');
const PropTypes           = require('prop-types');
const sharedPropTypes     = require('../../../shared/components/sharedPropTypes');

class Slider extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
  }

  onChange(evt) {
    this.props.onChange(parseFloat(evt.target.value));
  }

  onDragStart(evt) {
    evt.preventDefault();
  }

  render() {
    const value = this.props.value || Math.floor((this.props.max + this.props.min) / 2);

    let additionalStyles = null;
    if (this.props.primarySliderColor && this.props.secondarySliderColor) {
      additionalStyles = {
        background: `linear-gradient(90deg, #003F2D 0% ${value * 100}%, var(${this.props.secondarySliderColor}) ${value * 100}% 100%)`
      };
    }

    return (
      <div className="slider-wrapper">
        <div className="slider-text slider-item has-text-right">
          {this.props.min}
        </div>
        <div className="slider-item">
          <input
            className="slider-custom"
            draggable
            onDragStart = {this.onDragStart}
            style={additionalStyles}
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            value={value}
            aria-valuemin={this.props.min}
            aria-valuemax={this.props.max}
            aria-valuenow={value}
            type="range"
            onChange={this.onChange}
          />
        </div>
        <div className="slider-text slider-item slider-right-text">
          {this.props.max}
        </div>
      </div>
    );
  }
}


Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
  value: sharedPropTypes.stringOrNumber.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  primarySliderColor: PropTypes.string,
  secondarySliderColor: PropTypes.string
};

Slider.defaultProps = {
  step: 1,
  min: 0,
  primarySliderColor: '',
  secondarySliderColor: ''
};

module.exports = Slider;
