const React          = require('react');
const PropTypes      = require('prop-types');
const ContentSection = require('./ContentSection.jsx');

function HighlightedContentSection(props) {
  return (
    <div className="light-gray-content-section">
      <ContentSection>
        {props.children}
      </ContentSection>
    </div>
  );
}

HighlightedContentSection.propTypes = {
  children: PropTypes.node.isRequired
};

module.exports = HighlightedContentSection;
