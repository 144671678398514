module.exports = {

  // LandingPageHero
  landingPageHeroTitle: 'Laissez-nous vous aider à planifier vos aménagements futurs et à trouver la surface correcte de vos prochains bureaux.',
  landingPageHeroSubtitle: 'Le monde du travail change avec la récente pandémie. Recevez votre recommandation personnalisée en utilisant notre outil gratuit.',
  landingPageHeroImgAlt: 'Prévisualisation des résultats directement sur la page d\'accueil de Spacer by CBRE.',
  landingPageImage: '/png/homepage-results-page-in-browser-spacer-by-cbre-Fr.png',


  // LandingPageContent
  landingPageContentTitle: 'Comment cela marche',

  // StepsSummary
  stepsSummarySubtitle1: 'Etape —1 ',
  stepsSummaryTitle1: 'Dites-nous en plus sur votre société',
  stepsSummaryText1: 'Aidez-nous à connaître vos objectifs afin que nous puissions vous faire des recommandations pertinentes.',
  stepsSummaryImgAlt1: 'Illustration d\'une personne postée sur un PC portable géant sur la page d\'accueil de Spacer by CBRE',

  stepsSummarySubtitle2: 'Illustration d\'un quartier sur la page d\'accueil de Spacer by CBRE',
  stepsSummaryTitle2: 'Obtenez vos résultats',
  stepsSummaryText2: 'Grâce aux réponses que vous nous avez fournies, vous allez recevoir notre recommandation quant à la taille de votre espace, et une liste de types d\'espace. Vous pourrez aussi avoir accès à des exemples de plans d\'aménagement.',
  stepsSummaryImgAlt2: 'Illustration d\'un quartier sur la page d\'accueil de Spacer by CBRE',

  stepsSummarySubtitle3: 'Etape —3 ',
  stepsSummaryTitle3: 'Trouvez votre espace idéal',
  stepsSummaryText3: 'Sauvegardez vos résultats afin de pouvoir y revenir pendant votre recherche de locaux, ou contactez l\'équipe Spacer afin d\'échanger avec nos experts pour qu\'ils vous accompagnent sur votre projet.',
  stepsSummaryImgAlt3: 'Illustration d\'une femme travaillant à son bureau sur la page d\'accueil de Spacer by CBRE',

  // LandingPageSecondaryHero
  landingPageSecondaryHeroImgAlt: 'Illustration de personnes travaillant à construire des recommandations d\'espace de bureaux sur la page d\'accueil de Spacer by CBRE',
  landingPageSecondaryHeroTitle: 'Dites-nous en plus sur votre société, nous vous dirons de quels espaces vous avez besoin.'

};
