module.exports = {
  title: 'How Does Spacer Work?',
  pageTitle: 'How Does Spacer Work? — Spacer by CBRE',
  metaTagText: 'Use this free tool to generate custom recommendations and start planning your new office space. Learn more here.',
  preview: `
    The workplace is the home of your organization. People meet, focus, socialize,
    strategize, sell, think, make, and do at the office. It is where your
    organization happens. It should accommodate you as you grow and as your work
    changes. It should be convenient. It should reflect your brand and the values
    that make your organization special.
  `,
  content: `
    <h2>The Philosophy</h2>
    <p>
    The workplace is the home of your organization. People meet, focus, socialize,
    strategize, sell, think, make, and do at the office. It is where your
    organization happens. It should accommodate you as you grow and as your work
    changes. It should be convenient. It should reflect your brand and the values
    that make your organization special.
    </p>
    <p>
    In the work-anywhere age, having an office asserts that being near is better
    than being far, and it must make good on that idea by providing real advantage
    to the people who use it.
    </p>
    <p>
    <strong>We know that planning a good workplace is not easy.</strong> That’s why
    we created Spacer.
    </p>
    <p>
    Spacer starts with a solid understanding of your organization, through a
    16-question quiz we call Profiler. We get to know you with a dialogue aimed at
    defining your priorities, your values, and the way you work. Think of it like a
    personality quiz for your workplace. This tool doesn’t base its logic on
    industry - there is no single “tech” or “banking” workplace – and you may find
    that you have a different profile than companies that are, in other ways, your
    peers. That is how it is supposed to be. We want to uncover the unique aspects
    of your organization and design your office around them.
    </p>
    <p>
    With a few more details, we are able to convert your profile into a list of the
    spaces you need in your future office. We provide spaces for the jobs people
    will be doing, the events and rituals that make your office special, and the
    diverse needs and preferences of your people. We also take care of all the
    particulars, like circulation, sizing, seat ratios, and modularity - meaning
    this is an architect-ready space program. This is YOUR space program. After
    selecting a building, we hope that it becomes your office.
    </p>
    <p>
    Ratios and metrics reflected in Spacer are the result of years of workplace
    consulting experience. Each profile is a composite of real organizations and
    workplaces, and the details were checked against industry guidelines and market
    knowledge sourced from the wide reach of CBRE. Our algorithms are built for the
    long haul and designed to be flexible. We can even work together to make your
    profile even more tailored and exclusively yours.
    </p>
    <p>
    Spacer is a great starting point for any workplace discussion, but if you need
    more customization, or help with the tool, please get in touch at <a
    href="mailto:spacersupport@cbre.com">spacersupport@cbre.com</a>. We would be
    happy to help.
    </p>
  `
};
