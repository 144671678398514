const React     = require('react');
const PropTypes = require('prop-types');

function ExternalLink(props) {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      onMouseDown={evt => evt.stopPropagation()}
    >
      {props.children}
    </a>
  );
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string, // link is text
    PropTypes.node // link is an element (e.g., <img>)
  ]).isRequired
};

module.exports = ExternalLink;
