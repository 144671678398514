const React           = require('react');
const PropTypes       = require('prop-types');
const NumberInput     = require('../form/NumberInput.jsx');
const sharedPropTypes = require('../../../shared/components/sharedPropTypes');

function HeadcountInput(props) {
  return (
    <NumberInput
      max={HeadcountInput.MAX_HEADCOUNT}
      onChange={props.onValueChange}
      hideNumberArrows={!props.value}
      hasXlargeArrows={props.hasXlargeArrows}
      value={props.value || ''}
      isDark={props.isDark}
    />
  );
}

HeadcountInput.MAX_HEADCOUNT = 5000;

HeadcountInput.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  value: sharedPropTypes.stringOrNumber.isRequired,
  hasXlargeArrows: PropTypes.bool,
  isDark: PropTypes.bool
};

HeadcountInput.defaultProps = {
  hasXlargeArrows: false,
  isDark: false
};

module.exports = HeadcountInput;

