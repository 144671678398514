module.exports = {

  // Navbar
  // spacerLogoAlt: no translation yet
  linkToAboutPageText: 'Über',
  linkToBlogPageText: 'Einblicke',

  // TakeOfficeSpaceQuizButton
  sizeMyOfficeButtonDefaultLinkText: 'Starten Sie hier',
  flooredPlanRequestLinkText: 'Grundriss anfordern',

  // Footer
  // footerCBRELogoAlt: no translation yet
  // footerFacebookIconAlt: no translation yet
  // footerTwitterIconAlt: no translation yet
  // footerLinkedinIconAlt: no translation yet
  footerHeaderQuestionsText: 'Haben Sie noch Fragen?',
  footerHeaderAboutText: 'Was ist Spacer?',
  footerHeaderMoreText: 'Mehr',
  footerLinkBlogText: 'Einblicke',
  footerLinkWhatIsSpacerText: 'Über',
  footerLinkWorkplaceText: 'Entdecken Sie unsere Workplace Services in Deutschland',
  footerLinkWorkplaceTextForDE: 'Entdecken Sie unsere Workplace Services in Österreich',
  footerLinkTermsText: 'Nutzungsbedingungen',
  footerLinkPrivacyText: 'Datenschutzbestimmungen',
  footerWorkplaceLink: 'https://www.cbre.de/de-de/dienstleistungen/nutzer/arbeitswelten-und-raumkonzepte',
  footerWorkplaceLinkForDE: 'https://www.cbre.at/de-at/dienstleistungen/sektoren/bueros',
  footerPrivacyLink: 'https://www.cbre.com/about-us/global-web-privacy-and-cookie-policy',
  footerLinkedinLink: 'https://www.linkedin.com/showcase/cbre-workplace-strategy/',
  // Contact (contact.js)
  spacerSupportEmail: 'spacersupport@cbre.com',

  // ErrorPage
  errorOopsText: 'Uups!',
  error404Text: 'Wir können die gesuchte Seite nicht finden.',
  error500Text: 'Es ist etwas schief gelaufen.',
  errorText: 'Error',
  errorReturnText: 'Zurück zu Spacer',
  errorAltText: '',

  // Measurement Units (units.js)
  SF: 'ft ²',
  SM: 'm²',
  USF: 'NFft²',
  USM: 'NFm²',
  RSF: 'MFft²',
  RSM: 'MFm²',
  NSF: 'Netto ft²',
  NSM: 'Netto m²',
  NIA: 'Netto Innenfläche',
  foot: 'fuß',
  feet: 'fuß',
  footage: 'Aufnahme',
  'sqft.': 'Quadratfuß',
  meter: 'meter',
  meters: 'meter',
  'sqm.': 'qm',
  Tsubo: 'Tsubo',

  // SF Types Adjectives (calculator.js)
  Usable: 'Differenz',
  Rentable: 'Mietfläche',
  Net: 'netto',

  // documentDataCopy needs translation: page titles and metatag texts
  landingPageTitle: 'Planen Sie Ihr perfektes Büro in 15 Minuten - Spacer von CBRE',
  aboutPageTitle: 'Erfahren Sie, wie unser Flächenermittlungstool fuktioniert - Spacer von CBRE',
  termsPageTitle: 'Allgemeine Geschäftsbedingungen - Spacer von CBRE',
  quizPageTitle: 'Bestimmen Sie die Größe Ihres Büros in 15 Minuten - Spacer von CBRE',
  resultsPageTitle: 'Schauen Sie sich die Empfehlung für Ihr Büro an - Spacer von CBRE',
  profileComparisonPageTitle: 'Vergleichen Sie Ihre Anforderungen - Spacer von CBRE'
  // blogHomePageTitle: no translation yet,

  // Meta Tags (documentDataCopy.js)
  // landingPageMetaTagText: no translation yet,
  // aboutPageMetaTagText: no translation yet,
  // termsPageMetaTagText: no translation yet,
  // quizPageMetaTagText: no translation yet,
  // resultsPageMetaTagText: no translation yet,
  // profileComparisonPageMetaTagText: no translation yet,
  // blogHomePageMetaTagText: no translation yet

};
