const React                   = require('react');
const PropTypes               = require('prop-types');
const { Navigate }            = require('react-router-dom');
const constants               = require('wp-constants').spacerFree;
const FixedNavbar             = require('../../common/FixedNavbar.jsx');
const ProfileComparisonColumn = require('./ProfileComparisonColumn.jsx');
const DocumentData            = require('../../common/DocumentData.jsx');
const { withTranslation }      = require('react-i18next');
const router = require('../../../router.jsx');
const { withRouter } = require('shared/components/common/withRouter.jsx');

class ProfileComparison extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProfileId: props.currentProfileId
    };

    this.selectProfileAndReturnToResults = this.selectProfileAndReturnToResults.bind(this);
  }

  selectProfileAndReturnToResults() {
    if (this.state.selectedProfileId === this.props.currentProfileId) return this.props.history(-1)

    return this.props.openModal(constants.modal.TYPES.CONFIRMATION, {
      title: this.props.t('profileCompModalTitle'),
      text: this.props.t('profileCompModalText'),
      confirm: {
        text: this.props.t('profileCompModalConfirmText'),
        onClick: () => {
          this.props.setProfileAndCalculateProgram(this.state.selectedProfileId);
          this.props.updateProgram(this.props.programId)
            .then(() => {
              this.props.closeModal();
              return this.props.history(`/program-your-results-spacer-by-cbre/${this.props.programId}`);
            });
        }
      },
      cancel: {
        text: this.props.t('profileCompModalCancelText'),
        onClick: () => {
          this.props.closeModal();
          return this.props.history(-1);
        }
      }
    });
  }

  render() {
    if (!this.props.currentProfileId) return <Navigate push to="/" />;

    // this.props.profileIds is in ranked order, swap the first and second ids
    // so the recommended profile gets displayed as the center column on the page
    const profileIds = [this.props.profileIds[1], this.props.profileIds[0], this.props.profileIds[2]];

    return (
      <div className="profile-comparison container has-navbar-fixed-top">
        <DocumentData
          siteLocation={constants.siteLocation.PAGE.PROFILE_COMPARISON}
        />
        <FixedNavbar
          navItems={
            <React.Fragment>
              <a
                tabIndex="0"
                role="button"
                className="navbar-item no-outline"
                onClick={() => { this.props.history(-1) }}
              >
                {this.props.t('profileCompNavbarCancelButtonText')}
              </a>

              <div className="navbar-item">
                <button className="button is-primary" onClick={this.selectProfileAndReturnToResults}>
                  {this.props.t('profileCompNavbarUseButtonText')}
                </button>
              </div>
            </React.Fragment>
          }
        />
        <main>
          <h1 className="title is-5 has-text-centered has-padding-top-4 has-padding-bottom-6">
            {this.props.t('profileCompCompareTitle')}
          </h1>

          <div className="columns">
            {
              profileIds.map(profileId => (
                <div className="column" key={`profile-${profileId}`}>
                  <ProfileComparisonColumn
                    profileType={this.props.profileType}
                    profileId={profileId}
                    isRecommended={profileId === this.props.profileIds[0]}
                    isSelected={profileId === this.state.selectedProfileId}
                    onClick={() => this.setState({ selectedProfileId: profileId })}
                  />
                </div>
              ))
            }
          </div>
        </main>
      </div>
    );
  }
}

ProfileComparison.propTypes = {
  location: PropTypes.shape({
    programId: PropTypes.string.isRequired
  }).isRequired,
  programId: PropTypes.string,
  profileType: PropTypes.string.isRequired,
  currentProfileId: PropTypes.string.isRequired,
  profileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  setProfileAndCalculateProgram: PropTypes.func.isRequired,
  updateProgram: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('resultsPage')(withRouter(ProfileComparison));
