module.exports = {

  // Quiz
  exitQuizButtonTitle: 'Ukončiť dotazník',

  // QuizProgressLabel
  progressLabelText: 'Hotovo',

  // QuizNavigationButton
  quizNavBackButtonShortenedText: 'Naspäť',
  quizNavBackButtonFullLengthText: 'Predchádzajúca otázka',
  quizNavNextButtonShortenedText: 'Ďalej',
  quizNavNextButtonFullLengthText: 'Ďalšia otázka',

  // QuizExitSlide
  quizExitSubtitle: 'Teraz použijeme vaše odpovede na definovanie dokonalého pracoviska pre vašu spoločnosť.',
  quizExitImgAlt: 'Ilustrácia dvoch ľudí, ktorí si dávajú "high-five" na stránke dotazníku Spacer od CBRE',

  // Question Unit Text (questionUnitMap.js)
  question1Text: 'Na začiatok - Ako by ste opísali účel svojej budúcej kancelárie?',
  question2Text: 'Čo je na vašej spoločnosti podľa vás najatraktívnejšie pre nových uchádzačov.',
  question3Text: 'Čo je pre vás najdôležitejšie pri prijímaní nových členov tímu?',
  question4Text: 'Aký rast predpovedáte vašej firme v nasledujúcich piatich rokoch?',
  question5Text: 'Aký je pracovný čas vašich zamestnancov?',
  question6Text: 'Čo si myslíte o zdieľaných pracovných miestach?',
  question7Text: 'Kde budú ľudia v kancelárii najčastejšie pracovať?',
  question8Text: 'Aký je dresscode vo vašej kancelárii?',
  question9Text: 'Odkiaľ budete pracovať?',
  question10Text: 'Aká by mala byť atmosféra vašej kancelárie?',
  question11Text: 'Čo by mala poskytovať vaša kancelária okrem pracovného priestoru?',
  question12Text: 'Čo vás najlepšie vystihuje?',

  // Quiz Answers (questionUnitMap.js)
  pickOnePlaceholder: 'Vyberte jedno...',
  selectFromListPlaceholder: 'Vyberte z možností...',
  firstResponseTooltipText: 'Vyberte svoju prvú odpoveď',
  locatedSomewhereElseLabel: 'Momentálne sídlime niekde inde.',

  q1AnswerText1: 'V prvom rade rozmýšľame o našej kancelárii ako o',
  q1AnswerText2: 'po druhé ako o',
  q1AnswerText3: 'po tretie ako o',
  q1Option1Text: 'druhom domove pre našich ľudí',
  q1Option2Text: 'hnacej sile organizačnej zmeny',
  q1Option3Text: 'mieste pre sústredenú prácu',
  q1Option4Text: 'inkubátore pre ďalší veľký nápad',
  q1Option5Text: 'nástroji na získanie veľkého talentu',
  q1Option6Text: 'výkladnej skrini pre našu značku',
  q1Option7Text: 'priestore pre rast a vzdelávanie',
  q1Option8Text: 'mieste, kde sa môžu ľudia stretávať',
  q1Option9Text: 'nevyhnutných nákladoch, ktoré sa snažíme minimalizovať',

  q2AnswerText1: 'Nový uchádzači sa k nám pridávajú predovšetkým kvôli',
  q2AnswerText2: 'po druhé kvôli',
  q2AnswerText3: 'po tretie kvôli',
  q2Option1Text: 'firemnej kultúre',
  q2Option2Text: 'konkurenčným platom',
  q2Option3Text: 'reputácii firmy',
  q2Option4Text: 'benefitom',
  q2Option5Text: 'flexibilnej pracovnej dobe',
  q2Option6Text: 'kolegom a vedeniu',
  q2Option7Text: 'kancelárskemu priestoru',
  q2Option8Text: 'účelu a poslaniu',

  q3AnswerText1: 'Nových členov príjmame v prvom rade pre ich',
  q3AnswerText2: 'po druhé pre ich',
  q3AnswerText3: 'po tretie pre ich',
  q3Option1Text: 'zručnosti',
  q3Option2Text: 'skúsenosti',
  q3Option3Text: 'kultúrnu adaptabilitu',
  q3Option4Text: 'spôsobilosť',
  q3Option5Text: 'vzťahy',
  q3Option6Text: 'zvedavosť',

  q4AnswerText: 'Vyberte možnosť',
  q4Option1Text: 'Mierny rast každý rok.',
  q4Option2Text: 'Značný rast každý rok.',
  q4Option3Text: 'Zostaneme rovnakí.',
  q4Option4Text: 'Mierny pokles každý rok.',
  q4Option5Text: 'Ťažko povedať - je to nepredvídateľné.',

  q5AnswerText: 'Zvyčajne pracujeme',
  q5Option1Text: 'od 9:00 do 17:00.',
  q5Option2Text: 'od rána do večera.',
  q5Option3Text: 'kedykoľvek chceme v určitú dobu.',
  q5Option4Text: 'kedykoľvek a akokoľvek často chceme.',

  q6AnswerText: 'Myslíme si, že',
  q6Option1Text: 'každý by mal mať svoje vlastné pracovné miesto.',
  q6Option2Text: 'je lepšie zdieľať pracovné miesta, ktoré nebudú priradené.',

  q7Option1Text: 'Vždy zo svojho pracovného miesta',
  q7Option2Text: 'Primárne sa pracuje zo svojho pracovného miesta okrem schôdzok a iných aktivít',
  q7Option3Text: 'Polovicu času sa pracuje zo svojho pracovného miesta a polovicu času z iného priestoru v kancelárii',
  q7Option4Text: 'Primárne sa pohybovať na rôznych pracovných miestach s občasnými zastávkami pri pracovných stoloch',
  q7Option5Text: 'Neustály pohyb medzi rôznymi pracovnými miestami',

  q8Option1Text: 'Business professional',
  q8Option2Text: 'Business professional s občasnými výnimkami',
  q8Option3Text: 'Business casual, podľa situácie',
  q8Option4Text: 'Casual, okrem špeciálnych príležitostí',
  q8Option5Text: 'Uvolnený',

  q9Option1Text: 'Vždy z kancelárie',
  q9Option2Text: 'Zvyčajne z kancelárie',
  q9Option3Text: 'Striedavo v kancelárii a diaľkovo',
  q9Option4Text: 'Prevažne diaľkovo',
  q9Option5Text: 'Vždy diaľkovo',

  q10Option1Text: 'Dynamická a energická',
  q10Option2Text: 'Energická s tichými miestami na sústredenie',
  q10Option3Text: 'Záleží od situácie - kombinácia ticha a dynamiky',
  q10Option4Text: 'Tichá, s občasnými dynamickými situáciami',
  q10Option5Text: 'Tichá a pokojná',

  q11Option1Text: 'Iba základné vybavenie, postačujúce urdžať ľudí v pracovnom chode',
  q11Option2Text: 'Základné vybavenie a niekoľko inovácií',
  q11Option3Text: 'Základné vybavenie a miesto, kde sa ľudia môžu spoločensky zhromažďovať',
  q11Option4Text: 'Niečo skutočne pekné, zaujímavý priestor v rámci kancelárie',
  q11Option5Text: 'Niečo cool a odlišné - prvá vec, ktorú ukážete návštevníkovi',

  q12AnswerText: 'Som',
  q12OptionClientText: 'existujúci klient CBRE',
  q12OptionProfessionalText: 'CBRE profesionál',
  q12OptionLeadText: 'niekto iný',

  q13AnswerText1: 'Budeme mať',
  q13AnswerText2: 'zamestnancov v našej kancelárii',
  q13AnswerHelperText: 'Pomôcka: Toto je zvyčajne stred vašeho prenájmu, alebo 2-5 rokov odteraz.',

  q14AnswerText1: 'Strávime',
  q14AnswerText2: 'nášho času prácou v tímoch.',

  q15AnswerText1: 'V priemere plánujeme stráviť',
  q15AnswerText2: 'dní prácou z kancelárie.',

  q16AnswerText1: 'Naša kancelária bude v meste...',
  q16AnswerText2: '.',

  q17AnswerText1: 'Podnikáme primárne v ',
  q17AnswerText2: 'sektore.',

  q18AnswerText1: 'Momentálne sídlime v ',
  q18AnswerText2: '.',

  q19AnswerText1: 'Hustotu využitia našej kancelárie odhadujeme na (osoba na m2)',
  q19AnswerText2: 'v budúcnosti.',

  // Quiz Responses (questionUnitMap.js)
  q1ResponseText: 'Zadefinujeme kanceláriu, ktorá zodpovie vašej vízii.',
  q2ResponseText: 'Atraktívna kombinácia!',
  q3ResponseText: 'Znie to dobre - kam sa môžeme hlásiť?',
  q4ResponseText: 'Skvelé! Zaistíme, aby kancelária splnila požiadavky.',
  q5Response1Text: 'Znie to dobre!',
  q5Response2Text: 'My tak isto zvykneme pracovať aj nadčasy.',
  q5Response3Text: 'Znie to výborne!',
  q5Response4Text: 'Znie to úžasne!',
  q6Response1Text: 'Definujeme miesto, ktoré uprednostňuje osobný priestor.',
  q6Response2Text: 'Definujeme miesto, ktoré je flexibilné',
  q12ResponseLeadText: 'Prišli ste na správne miesto!',
  q12ResponseClientText: 'Perfektné!',
  q12ResponseProfessionalText: 'Vďaka!',

  // Industry Codes (industryCodes.js)
  animalProd: 'Živočíšna výroba a akvakultúra',
  forestry: 'Lesníctvo a ťažba dreva',
  fishingHunting: 'Rybolov, lov a pasce',
  agForestSupport: 'Činnosti na podporu poľnohospodárstva a lesného hospodárstva',
  oilGas: 'Ťažba ropy a zemného plynu',
  energy: 'Energetika (plyn, ropa atď.)',
  mining: 'Ťažba, okrem ropy a plynu',
  supportMining: 'Podporné činnosti pre ťažbu',
  utilities: 'Verejné služby',
  buildingConstruct: 'Výstavba budov',
  heavyCivilConstruct: 'Ťažké a inžinierske stavby',
  specialtyContract: 'Špecializovaní obchodní dodávatelia',
  foodManufac: 'Výroba potravín',
  beverageTobaccoManufac: 'Výroba nápojov a tabakových výrobkov',
  textile: 'Textilný závod',
  textileProduct: 'Závod textilných výrobkov',
  apparelManufac: 'Výroba odevov',
  leatherManufac: 'Výroba kože a pridružených výrobkov',
  woodManufac: 'Výroba drevených výrobkov',
  paperManufac: 'Výroba papiera',
  printingSupport: 'Tlač a súvisiace podporné činnosti',
  printingReproduc: 'Tlač / reprodukcie',
  petroleumCoal: 'Výroba ropy a uhoľných výrobkov',
  chemicalManufac: 'Chemická výroba',
  chemicals: 'Chemikálie',
  pharma: 'Farmaceutiká',
  cleaningManufac: 'Výroba čistiacich prostriedkov',
  plasticsRubberManufac: 'Výroba plastov a gumových výrobkov',
  nonmetallicManufac: 'Výroba nekovových minerálnych výrobkov',
  primaryMetalManufac: 'Prvotné spracovanie kovov',
  fabricatedMetalManufac: 'Výroba kovových výrobkov',
  machineryManufac: 'Výroba strojov',
  manufac: 'Výroba',
  computerElectronicManufac: 'Výroba počítačov a elektronických výrobkov',
  electricalApplianceManufac: 'Výroba elektrických zariadení a prístrojov',
  transportEquipManufac: 'Výroba dopravných zariadení',
  aerospaceDefense: 'Letectvo a obrana',
  furnitureManufac: 'Výroba nábytku a súvisiacich výrobkov',
  miscManufac: 'Výroby rôznych produktov',
  merchantWholesaleDurable: 'Veľkoobchod - tovar dlhodobej spotreby',
  merchantWholesaleNondurable: 'Veľkoobchod - tovar krátkodobej spotreby',
  electronicMarket: 'Elektronické trhy, agenti a makléri',
  motorVehiclePartsDealers: 'Predajcovia motorových vozidiel a náhradných dielov',
  furnitureFurnishingStores: 'Obchody s nábytkom a bytovým zariadením',
  electronicApplianceStores: 'Obchody s elektronikou a spotrebičmi',
  electronics: 'Elektronika',
  buildingGardenSupplyStores: 'Predaj stavebného materiálu a záhradných potrieb',
  foodBeverageStores: 'Predaj potravín a alkoholických nápojov',
  groceryStores: 'Potraviny',
  beerWineLiquorStores: 'Predaj piva, vína a alkoholu',
  healthCareStores: 'Obchody so zdravotnou a osobnou starostlivosťou',
  gasStations: 'Čerpacie stanice',
  clothingAccessoriesStores: 'Obchody s odevmi a odevnými doplnkami',
  clothingTextiles: 'Oblečenie / textil',
  jewelryFurs: 'Šperky a kožušiny',
  sportsHobbyMusicBookStores: 'Šport, hobby, hudobné nástroje, kníhkupectvá',
  genMerchandiseStores: 'Obchody so zmiešaným tovarom',
  miscStoreRetailers: 'Maloobchodníci so zmiešaným tovarom',
  officeProducts: 'Kancelárske produkty',
  retailSales: 'Maloobchodný predaj',
  nonstoreRetails: 'Maloobchodný predaj mimo predajne',
  airTransport: 'Letecká preprava',
  travelRelated: 'Cestovanie (letecké spoločnosti, agenti, hromadná doprava)',
  railTransport: 'Železničná doprava',
  waterTransport: 'Vodná doprava',
  truckTransport: 'Kamiónová doprava',
  transitTransport: 'Tranzitná a pozemná osobná doprava',
  pipelineTransport: 'Potrubná preprava',
  scenicTransport: 'Výletná a zážitková doprava',
  supportTransport: 'Podporné činnosti v doprave',
  postalService: 'Poštové služby',
  courierMessenger: 'Kuriéri a doručovanie správ',
  shippingDelivery: 'Preprava a doručenie',
  warehousingStorage: 'Skladovanie',
  warehouseDistrib: 'Skladovanie/Distribúcia',
  publishingNoInternet: 'Vydavateľský priemysel, okrem internetu',
  publishing: 'Vydavateľstvo',
  motionPicSoundRecordingIndustries: 'Filmové a hudobné nahrávacie štúdiá',
  entertainment: 'Zábava (film, televízia, hudba atď.)',
  infoServicesMarketResearch: 'Informačné služby / Prieskum trhu',
  broadcastingNoInternet: 'Vysielanie, okrem internetu',
  telecom: 'Telekomunikácie',
  tech: 'Technológie',
  dataProcessingHostingServices: 'Spracovanie dát, hosting a súvisiace služby',
  otherInfoServices: 'Iné informačné služby',
  financeInsurance: 'Finančníctvo a poistenie',
  monetaryAuthorities: 'Menové orgány - centrálna banka',
  creditIntermediation: 'Sprostredkovanie úverov a súvisiace činnosti',
  banking: 'Bankovníctvo',
  securitiesContractsInvestments: 'Cenné papiere, komoditné zmluvy, investície',
  financialServicesNoBanking: 'Finančné služby (nebankové)',
  insuranceCarriersRelated: 'Poisťovníctvo a súvisiace činnosti',
  insurance: 'Poisťovníctvo',
  fundsTrustsFinancialVehicles: 'Fondy a ďalšie finančné kanály',
  realEstate: 'Nehnuteľnosti',
  rentalLeasingServices: 'Prenájom a lízing',
  automotive: 'Automobilový priemysel',
  consumerGoods: 'Spotrebný tovar',
  lessorsNonfinancialIntangible: 'Prenajímatelia nefinančných nehmotných aktív',
  professionalTechnicalServices: 'Profesionálne a technické služby',
  legalServices: 'Právne služby',
  accounting: 'Účtnovníctvo',
  architectureEngineering: 'Architektúra/Inžinierstvo',
  specializedDesignServices: 'Špecializované dizajnové služby',
  compRelatedServices: 'Počítačové služby',
  mgmtConsulting: 'Poradenstvo v oblasti manažmentu',
  scientificResearch: 'Vedecký výskum',
  adsMarketingPR: 'Reklama / Marketing / Vzťahy s verejnosťou',
  otherProfSciTechServices: 'Iné profesionálne, vedecké a technické služby',
  companyEnterpriseMgmt: 'Riadenie spoločností a podnikov',
  adminSupportServices: 'Administratívne a podporné služby',
  employmentAgencyRecruiters: 'Pracovná agentúra/nábor',
  securityPrivateInvestigation: 'Bezpečnosť / súkromné vyšetrovanie',
  wasteMgmtRemediationServices: 'Nakladanie s odpadom a sanačné služby',
  educationServices: 'Vzdelávacie služby',
  education: 'Vzdelávanie',
  ambulatoryHealthCareServices: 'Ambulantné služby zdravotnej starostlivosti',
  healthCareRelated: 'Služby súvisiace so zdravotnou starostlivosťou',
  hospitals: 'Nemocnice',
  nursingResidentialCareFacilities: 'Ošetrovateľské a ústavné zariadenia',
  socialAssistance: 'Sociálna pomoc',
  performingArtsSpectatorSports: 'Múzické umenie a divácke športy',
  museumsHistoricalSitesZoosParks: 'Múzeá, historické pamiatky, zoologické záhrady a parky',
  fineArtsGalleriesMuseums: 'Krásne umenia',
  amusementsGamblingRec: 'Zábava, hazard a rekreácia',
  accommodation: 'Ubytovanie',
  foodDrinkingServices: 'Stravovacie služby a pitie',
  restaurantHotel: 'Reštaurácia/hotel',
  repairMaintenance: 'Opravy a údržba',
  industrialProductsHeavy: 'Priemyselné výrobky (ťažký priemysel)',
  personalLaundryServices: 'Práčovne',
  membershipAssocOrgs: 'Členské združenia a organizácie',
  nonprofit: 'Neziskové',
  privateHouseholds: 'Domácnosti',
  execLegisGenGvt: 'Výkonná, legislatívna a verejná správa',
  gvt: 'Vláda',
  justicePublicOrderSafetyActivities: 'Činnosti v oblasti spravodlivosti, verejného poriadku a bezpečnosti',
  hrAdminPrograms: 'Správa programov ľudských zdrojov',
  environmentAdminPrograms: 'Správa environmentálnych programov',
  environmental: 'Životné prostredie',
  communityHousingProgramAdmin: 'Správa komunitárneho a bytového programu',
  econProgramAdmin: 'Správa ekonomických programov',
  spaceResearchTech: 'Vesmírny výskum a technológie',
  natlSecurityIntlAffairs: 'Národná bezpečnosť a medzinárodné záležitosti'

};
