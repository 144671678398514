const _               = require('lodash');
const constants       = require('wp-constants').spacerFree;
const questionUnitMap = require('wp-data').questionUnitMap;
const locationUtil    = require('../../shared/util/location.js');

// The Profile Calculator takes a user's quiz as input, and outputs a sorted array with profile ids and percentage "match"
// example output: [[1, 0.8], [2, 0.5], [3, 0.1], ...]

function incrementProfileScores(profileScores, profileIds, points) {
  profileIds.forEach((profileId) => {
    if (!profileScores[profileId]) profileScores[profileId] = 0;
    profileScores[profileId] += points;
  });
}

// PICK QUESTION:
// for the selected option, increment the associated profileIds by questionUnit.points

function addPickQuestionProfileScore(profileScores, selectedOptionId, questionUnit) {
  const selectedOption = questionUnit.options[selectedOptionId];
  incrementProfileScores(profileScores, selectedOption.profileIds, questionUnit.points);
}

// RANK QUESTION:
// for each ranked option, increment the associated profileIds by the value at questionUnit.points[indexOfRankedOption]

function addRankQuestionProfileScore(profileScores, rankedOptionIds, questionUnit) {
  let pointsForRank;
  let selectedOption;

  rankedOptionIds.forEach((rankedOptionId, index) => {
    pointsForRank = questionUnit.points[index];
    selectedOption = questionUnit.options[rankedOptionId];
    incrementProfileScores(profileScores, selectedOption.profileIds, pointsForRank);
  });
}

function calculateProfileScores(userQuizAnswers) {
  const profileScores = {};

  let questionUnit;
  let userResponse;
  let totalQuizPoints = 0;

  // USER QUIZ -> PROFILE POINTS
  Object.keys(userQuizAnswers).forEach((questionUnitId) => {
    questionUnit = questionUnitMap.getQuestionUnitById(questionUnitId);
    userResponse = userQuizAnswers[questionUnitId];
    totalQuizPoints += (Array.isArray(questionUnit.points) ? _.sum(questionUnit.points) : questionUnit.points);

    switch (questionUnit.answerType) {
      case constants.questionUnit.PROFILE_ANSWER_TYPE.PICK: {
        addPickQuestionProfileScore(profileScores, userResponse, questionUnit);
        break;
      }
      case constants.questionUnit.PROFILE_ANSWER_TYPE.RANK: {
        addRankQuestionProfileScore(profileScores, userResponse, questionUnit);
        break;
      }
      default: {
        console.log('What is this mystery question?', questionUnit);
        break;
      }
    }
  });

  // PROFILE POINTS -> PROFILE PERCENTAGES (out of total quiz points)
  Object.keys(profileScores).forEach((profileId) => {
    profileScores[profileId] /= totalQuizPoints;
  });

  return profileScores;
}

function calculateProfileType(officeLocation, headcount, userQuizAnswers) {
  const { country } = locationUtil.getCityStateCountry(officeLocation);

  if (locationUtil.isGermany(country)) {
    return constants.calculator.PROFILE_TYPE.GERMANY_REGULAR;
  }

  if (locationUtil.isSpain(country)) {
    return constants.calculator.PROFILE_TYPE.SPAIN_REGULAR;
  }

  if (locationUtil.isFrance(country)) {
    return constants.calculator.PROFILE_TYPE.FRANCE_REGULAR;
  }

  if (locationUtil.isSlovakia(country)) {
    return constants.calculator.PROFILE_TYPE.SLOVAKIA_REGULAR;
  }

  if (locationUtil.isPoland(country)) {
    return constants.calculator.PROFILE_TYPE.POLAND_REGULAR;
  }

  if (locationUtil.isHungary(country)) {
    return constants.calculator.PROFILE_TYPE.HUNGARY_REGULAR;
  }

  if (locationUtil.isColombia(country)) {
    return constants.calculator.PROFILE_TYPE.COLOMBIA_REGULAR;
  }

  if (locationUtil.isNetherland(country)) {
    return constants.calculator.PROFILE_TYPE.NETHERLAND_REGULAR;
  }
  // Currently the UK Profiles and KOPs are more accurate for all regions in EMEA, so we assign it if the country is any of these regions
  if (locationUtil.isEMEA(country)) {
    return constants.calculator.PROFILE_TYPE.UK_REGULAR;
  }

  if (locationUtil.isAPAC(country)) {
    return constants.calculator.PROFILE_TYPE.APAC_REGULAR;
  }
  // If we have a headcount of less than 60, and the user answers 'Besides space for work, what would you like to provide in your workspace?'
  // with one of the three answers involving basic offerings, the profile should be given the US_SMALL type
  const deterministicQuestionForUSSMallProfileType = 11;
  const validAnswersForUSSmallProfileType = ['1', '2', '3'];

  if (headcount < 60 &&
      _.includes(validAnswersForUSSmallProfileType, userQuizAnswers[deterministicQuestionForUSSMallProfileType])) {
    return constants.calculator.PROFILE_TYPE.US_SMALL;
  }

  return constants.calculator.PROFILE_TYPE.US_REGULAR;
}

module.exports = {
  calculateProfileScores,
  calculateProfileType
};
