const React                  = require('react');
const PropTypes              = require('prop-types');
const quizOrderInfo          = require('wp-data').quizOrderInfo;
const domUtil                = require('../../../shared/util/dom');
const { withTranslation }     = require('react-i18next');

function QuizProgressLabel(props) {
  const progressPercentage = quizOrderInfo.quizProgressAtQuestion[props.questionIndex];

  let baseClasses = 'title quiz-progress-label content is-small';
  const progressBarClasses = 'progress is-white is-block has-margin-top-7';

  const progressBar = (
    <progress
      className={progressBarClasses}
      max="100"
      value={progressPercentage}
      aria-valuemin="0"
      aria-valuemax="100"
      aria-valuenow={progressPercentage}
    >
      {progressPercentage}
    </progress>
  );

  if (domUtil.isScreenMobileSize()) {
    baseClasses += ' flex';
    return (
      <div className={baseClasses}>
        {progressBar}
        <p className="percentage">{progressPercentage}%</p>
      </div>
    );
  }

  return (
    <div className={baseClasses}>
      <div role="heading" aria-level="1">{`${progressPercentage}% ${props.t('progressLabelText')}`}</div>
      {progressBar}
    </div>
  );
}

QuizProgressLabel.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

module.exports = withTranslation('quizPage')(QuizProgressLabel);
